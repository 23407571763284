<template>
  <div class="home" id="top">
    <!-- 头部banner图 -->
    <img class="topImg" src="https://juntongoss.oss-cn-beijing.aliyuncs.com/beauty/position.png" />
    <!-- 最新资讯 -->
    <div style="
        background-color: #ffffff;
        width: 100%;
        height: auto;
        min-height: 800px;
        margin: 10px auto 100px;
      ">
      <div style="height: 100%; width: 1200px; margin: 0 auto">
        <div style="
            display: flex;
            justify-content: center;
            padding-bottom: 80px;
            padding-top: 60px;
            width: 1200px;
            margin: auto;
          ">
          <img style="width: 67px; height: 31px; padding-right: 10px"
            src="../../assets/InformationIgm/InformationLeft.png" />
          <div style="
              color: #000000;
              font-size: 46px;
              font-weight: bold;
              line-height: 27px;
              padding: 0 10px;
            ">
            精准筛选适合你的职位
          </div>
          <img style="width: 67px; height: 31px; padding-left: 10px"
            src="../../assets/InformationIgm/InformationRight.png" />
        </div>

        <div class="Copywriting">
          <el-select class="selectItem" v-model="select" clearable placeholder="请选择岗位类型">
            <el-option v-for="(item, index) in selectList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select class="selectItem" v-model="employer" clearable placeholder="请选择用人单位" filterable multiple>
            <el-option v-for="(item, index) in employerOptions" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select class="selectItem" v-model="positionName" clearable placeholder="请选择岗位名称" filterable multiple>
            <el-option v-for="(item, index) in positionNameOptions" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select class="selectItem" v-model="educational" clearable placeholder="请选择学历" filterable multiple>
            <el-option v-for="(item, index) in educationalOptions" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>

          <div style="margin-top: 80px;margin-bottom: 80px;">
            <el-select class="selectItem" v-model="major" clearable placeholder="请选择所学专业" filterable multiple>
              <el-option v-for="(item, index) in majorOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
            <el-select class="selectItem" v-model="workLocation" clearable placeholder="请选择工作地点" filterable multiple>
              <el-option v-for="(item, index) in workLocationOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>


            <el-select class="selectItem" v-model="marital" clearable placeholder="是否已婚">
              <el-option v-for="(item, index) in maritalStatus" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="selectItem" v-model="partymem" clearable placeholder="是否为党员">
              <el-option v-for="(item, index) in partyMember" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

          </div>
          <div>
            <el-select class="selectItem" v-model="sex" clearable placeholder="请选择性别">
              <el-option v-for="(item, index) in sexList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

          </div>






        </div>
        <button class="myBtn" @click="jobSeach">检索职位</button>
        <div class="jobSearch_box" style="display: flex; justify-content: space-around">
          <div class="jobSearch_Item">
            <div class="topTitle">职位库查询结果</div>
            <div class="DataTitle">已为您搜索出{{ this.total }}条数据</div>
          </div>
          <!-- <div class="jobSearch_Item">
                        <div class="topTitle">所属专业类查询结果</div>
                        <div class="DataTitle">已为您搜索出250条数据</div>
                    </div> -->
          <!-- <div class="jobSearch_Item">
                        <div class="topTitle">所属专业名称查询结果</div>
                        <div class="DataTitle">已为您搜索出250条数据</div>
                    </div> -->
        </div>
        <div class="jobTable">
          <el-table :data="jobData" style="width: 100%; overflow: auto" :header-cell-style="{
            'text-align': 'center',
            background: '#074DFE',
            color: '#fff',
          }" :cell-style="{
  'text-align': 'center',
  background: 'rgba(247, 247, 247, 1)',
  height: '75px',
}" empty-text="职位库已被您掏空，暂时未找到合适您的职位。" v-loading="loading">
            <el-table-column prop="work_address" label="省份" width="180">
            </el-table-column>
            <el-table-column prop="employer_name" label="单位名称" width="180">
            </el-table-column>
            <el-table-column prop="post_name" label="岗位名称">
            </el-table-column>
            <el-table-column prop="sn" label="岗位代码"> </el-table-column>
            <!-- <el-table-column prop="address" label="招考人数">
                        </el-table-column> -->
            <el-table-column prop="major" label="所学专业"> </el-table-column>
            <el-table-column prop="education" label="学历"> </el-table-column>
            <el-table-column prop="other" label="其他条件"> </el-table-column>
            <el-table-column prop="source_cate" label="来源类别"></el-table-column>


            <el-table-column prop="sex" label="性别"></el-table-column>
            <el-table-column prop="politics" label="政治面貌"></el-table-column>
            <el-table-column prop="marriage" label="婚姻状况"></el-table-column>
            <el-table-column prop="other" label="备注"></el-table-column>
          </el-table>
          <el-pagination class="spliePage" background @current-change="currentChange" :current-page="page1"
            :page-size="size" layout="prev, pager, next" :total="total">
          </el-pagination>
        </div>
        <div style="
            margin-top: 40px;
            text-align: center;
            color: rgba(93, 112, 74, 1);
          ">
          免责声明：精研学堂教育提示广大考生，请报考前仔细阅读对应职位招录要求或联系招录单位确认，本职位检索系统提供内容仅供参考。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  educationalOptions,
  employerOptions,
  majorOptions,
  positionNameOptions,
  workLocationOptions,
} from "@/assets/js/position";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      jobData: [],
      employer: [], //用人单位
      positionName: [], //岗位名称
      educational: [], //学历
      major: [], //专业
      workLocation: [], //工作地点
      workLocationOptions: [], //工作地点
      majorOptions: [], //所学专业
      employerOptions: [], //用人单位
      positionNameOptions: [], //岗位名称
      educationalOptions: [], //学历
      marital: "",//是否已婚
      partymem: "",//是否为党员
      sex: "",//男性还是女性
      select: "",//岗位类型
      total: 0, //总条数
      page: 1,
      page1:1,
      size: 8,
      loading: false,
      maritalStatus: [{
        value: '未婚',
        label: '未婚'
      }, {
        value: '已婚',
        label: '已婚'
      }],
      partyMember: [{
        value: '党员',
        label: '党员'
      }, {
        value: '非党员',
        label: '非党员'
      }],
      sexList: [{
        value: '男',
        label: '男'
      }, {
        value: '女',
        label: '女'
      }],
      selectList: [{
        value: '管理岗',
        label: '管理岗'
      }, {
        value: '技能岗',
        label: '技能岗'
      }],
    };
  },
  created() {
    this.getSelectListS();
  },
  // 封装一个查询所有下拉框数据的方法
  getSelectListS() {
    this.educationalOptions = educationalOptions;
    this.employerOptions = employerOptions;
    this.majorOptions = majorOptions;
    this.positionNameOptions = positionNameOptions;
    this.workLocationOptions = workLocationOptions;
    this.$axios({
      method: "GET",
      url: "/api/officialWebsite/get_post_search",
      // data: {
      //     type: 1,
      //     page: 1,
      //     list_rows: 10
      // },
    }).then((res) => {
      if (res.code == 200) {
        this.educationalOptions = res.data.search_education;
        this.employerOptions = res.data.search_employer_name;
        this.majorOptions = res.data.search_major;
        this.positionNameOptions = res.data.search_post_name;
        this.workLocationOptions = res.data.search_work_address;
      }
    });
  },
  mounted() {
    this.getSelectListS()
    this.jobSeach()
  },
  methods: {
    // 封装一个查询所有下拉框数据的方法
    getSelectListS() {
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/get_post_search",
        // data: {
        //     type: 1,
        //     page: 1,
        //     list_rows: 10
        // },
      }).then((res) => {
        if (res.code == 200) {
          this.educationalOptions = res.data.search_education
          this.employerOptions = res.data.search_employer_name
          this.majorOptions = res.data.search_major
          this.positionNameOptions = res.data.search_post_name
          this.workLocationOptions = res.data.search_work_address
        }
      })
    },

    // 封装一个点击检索职位按钮的方法
    jobSeach() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/get_post_data",
        params: {
          page: this.page,
          limit: this.size,
          search_work_address: this.workLocation,
          search_major: this.major,
          search_education: this.educational,
          search_post_name: this.positionName,
          search_employer_name: this.employer,
          sex: this.sex,
          party: this.partymem,
          marry: this.marital,
          select: this.select
        },
      }).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          this.page=1
          this.total = res.data.total;
          this.jobData = res.data.data;
          
        }
      });
    },
    // 封装一个切换页数的方法
    currentChange(val) {
      this.page = val;
      this.page1=val
      this.jobSeach();
    },
  },
}
</script>

<style scoped>
.home {
  text-align: center;
}

.myBtn {
  cursor: pointer;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  /* margin-top: 54px; */
  width: 200px;
  height: 80px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(93, 112, 74, 1);
  box-shadow: 0px 20px 13px 0px rgba(10, 192, 94, 0.03),
    0px 8.15px 6.52px 0px rgba(56, 56, 56, 0.04),
    0px 1.85px 3.15px 0px rgba(56, 56, 56, 0.02);
}

.myBtn:hover {
  background: #6b8255;
  color: #fff;
}

div/deep/ .el-carousel__arrow:hover {
  background-color: #074DFE;
}

.jiangshiBox ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 500px;
}

.jiangshiBox ul li {
  height: 25px;
}

.jiangshiBox .el-carousel__button {
  width: 4px !important;
  height: 4px !important;
  background: #3f3f44;
  border-radius: 50%;
  transition: 0s;
}

.jiangshiBox .is-active .el-carousel__button {
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #333333;
  border-radius: 50%;
  background: none;
}

.jiangshiBox .el-carousel__indicator--vertical {
  padding: 8px 12px;
}

.xueyuan_bg {
  width: 1200px;
  height: 760px;
  margin: 0 auto;
  background: linear-gradient(94deg, #074DFE, #6b8255);
  border-radius: 20px;
  padding: 53px 0 0 0;
  box-sizing: border-box;
}

.xueyuan_box {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 480px;
}

.bottom_box .el-input__inner {
  height: 52px;
  border-radius: 10px;
  padding: 0 15px !important;
  box-sizing: border-box;
}

.jiaru .el-input__inner {
  border-radius: 10px;
  padding: 0 15px !important;
  box-sizing: border-box;
}

.my-info-dialog .el-dialog,
.el-pager li {
  background: none;
}

.my-info-dialog .el-dialog {
  box-shadow: none;
}

.popupInput {
  width: 346px;
  border-radius: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0 auto;
}

.Copywriting {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
  font-family: PingFangSC-Regular, sans-serif;
  color: rgba(103, 106, 108, 1);
}

.topTitle {
  font-size: 18px;
  color: #fff;
  font-family: PingFangSC-Medium, sans-serif;
  line-height: 61px;
  background: rgba(93, 112, 74, 1);
  border-radius: 8px 8px 0px 0px;
}

.jobSearch_Item {
  border-radius: 8px;
  width: 454px;
  box-shadow: 0px 2px 8px 0px rgba(93, 112, 74, 0.2);
  margin-right: 60%;
}

.DataTitle {
  line-height: 61px;
  background: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 8px 8px;
}

.topImg {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.selectItem {
  width: 280px;
  margin-right: 10px;
}


div/deep/.el-input--suffix .el-input__inner {
  border-color: #074DFE;
  box-shadow: 0px 2px 8px 0px rgba(93, 112, 74, 0.2);
}

.jobSearch_box {
  margin-top: 80px;
}

.jobTable {
  margin-top: 40px;
}

.spliePage {
  margin-top: 20px;
}

@keyframes myfirst {
  from {
    width: 0px;
  }

  to {
    width: 50px;
  }
}
</style>
