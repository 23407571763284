<template>
  <div id="container">
			<div id="one"  :style="
      {
        background: `linear-gradient(${bgcA}, ${bgcB})`
      }">
			</div>
			<div id="two" :style="
      {
        background: `linear-gradient(${bgcA}, ${bgcB})`
      }">
			</div>
			<div id="three" :style="
      {
        background: `linear-gradient(${bgcA}, ${bgcB})`
      }">
			</div>
  </div>
</template>

<script>
export default {
	props: {
		bgcA: {
			type: String,
			default: '#074DFE'
		},
		bgcB:{
			type: String,
			default: '#338BFF'
		}
	}
}
</script>

<style lang="scss" scoped>
@keyframes yuying1{
				0%{
					height: 0%;
				}
				20%{
					height: 50%;
				}
				50%{
					height: 100%;
				}
				80%{
					height: 50%;
				}
				100%{
					height: 0%;
				}
			}	
	
			#container{
				width: 20px;
				height:24px;
				// margin: 200px auto;	
			}
			#container #one{
				animation:yuying1 0.6s infinite 0.1s;
			   -webkit-animation:yuying1 0.6s infinite 0.1s;
			}
			#container #two{
				animation:yuying1 0.6s infinite 0.2s;
			   -webkit-animation:yuying1 0.6s infinite 0.2s;
			}
			#container #three{
				animation:yuying1 0.6s infinite 0.3s;
			   -webkit-animation:yuying1 0.6s infinite 0.3s;
			}
			#one,#two,#three{
				width:3px;
				height: 100%;
				margin-left: 3px;
				border-radius: 10px;
				// background-color: #999;
				vertical-align: middle;
				display: inline-block;
      }
</style>