<template>
  <div>
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading="loading">
      <div @click="openFp" class="kfp">开发票</div>
      <div class="order" v-for="item in order_list" :key="item.id">
        <div class="order_top ">
          <p>订单编号: {{ item.code }}</p>
          <p>创建时间: {{ item.created_at }}</p>
          <div style="flex: .6; display: flex; justify-content: end;">
            <p v-if="item.status === 1" style="color: #999999">已完成</p>
            <p v-if="item.status === 2" style="color: #074DFE">待支付</p>
            <p v-if="item.status === 3" style="color: #999999">已退学</p>
            <p v-if="item.status === 4" style="color: #074DFE">还款中</p>
          </div>
        </div>
        <div class="order_bottom">
          <div class="order_bottom_left">
            <img style="object-fit: cover" :src="item.coursebase.filepath" class="order_img">
          </div>
          <div class="order_bottom_right">
            <div class="oeder_class_name">
              <div>{{ item.name }}</div>
              <!-- <div>
                <span style="font-size: 16px;">实付金额:</span>
                <span style="color: #C34C50; font-size: 16px;">&yen;{{ item.payable }}</span>
              </div> -->

              <div v-if="item.status != 2" style="display: flex; align-items: center;">
                <span style="font-size: 18px;">实付金额:</span>&nbsp;
                <span style="color: #C34C50; font-size: 23px;">&yen;{{ item.total_price.split('.')[0] }}.<span style="font-size: 16px;">{{ item.total_price.split('.')[1] }}</span></span>
              </div>
            </div>
            <div class="order_bottom_right_B">
              <div class="add_class">附加课: ({{ !item.otherlessons.length ?  '无附加课' : item.otherlessons }})</div>
              <div>
                <!-- <div v-if="item.status != 2">
                <span style="font-size: 20px;">实付金额:</span>
                <span style="color: #C34C50; font-size: 20px;">&yen;{{ item.total_price }}</span>
                </div> -->
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 18px;">应付金额:</span>&nbsp;&nbsp;
                  <span style="color: #C34C50; font-size: 23px;">&yen;{{ item.payable.split('.')[0] }}.<span style="font-size: 16px;">{{ item.payable.split('.')[1] }}</span></span>
                </div>
              </div>
            </div>
            <div class="order_bottom_right_c">
              <div style="font-size: 20px; font-family: Arial;">&yen;{{ item.payable }}</div>
              <!-- 待支付 -->
              <div v-if="item.status === 2">
                <button type="button" class="unpaid_btn quxiao" @click="cancel_order(item.id)">取消订单</button>
                <button type="button" class="unpaid_btn" @click="pay_click(item.code)">立即支付</button>
              </div>
              <!-- 还款中 -->
              <div v-if="item.status === 4">
                <button type="button" class="bujiao_btn"  @click="pay_click(item.code)">补缴</button>
              </div>
            </div>
          </div>
        </div>

        <!-- 只有在已退学的状态下显示 -->
        <div class="drop_out_img" v-if="item.status === 3">
         <img src="../../../../assets/image/组 5.png">
        </div>
        <div class="mask"  v-if="item.status === 3"></div>
      </div>

      <!-- 分页 -->
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        :page-size="order_params.list_rows"
        :current-page="order_params.page"
        @current-change="current_change"
      >
      </el-pagination>

      <no_data v-if="!order_list.length"></no_data>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      tabsList: [
        {
          tabs_title: '全部订单'
        },
        {
          tabs_title: '待支付'
        },
        {
          tabs_title: '还款中'
        },
        {
          tabs_title: '已完成'
        },
        {
          tabs_title: '已退学'
        },
      ],
      tabs_active: 0,
      order_list:[],
      // 订单参数
      order_params:{
        page: 1,
        list_rows: 2
      },
      total: null,
      order_status: ''
    }
  },
  methods: {
    // 判断
    status (val) {
      // 待支付
      if (this.tabs_active === 1) {
        this.order_params.status = 2
      } else if(this.tabs_active === 2) {
        // 已完成
        this.order_params.status = 4
      } else if(this.tabs_active === 3) {
        // 还款中
        this.order_params.status = 1
      } else if(this.tabs_active === 4) {
        // 还款中
        this.order_params.status = 3
      } else {
        this.order_params = {
          page: val ? val : 1,
          list_rows: 2
        }
        this.getOrderData()
        return
      }
       this.getOrderData()
    },
    // Tabs 切换
    tabsClick(index) {
      this.tabs_active = index
      this.loading = true
      this.order_params.page = 1
      this.status()
    },

    // 获取全部订单数据
    getOrderData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_order',
        data:{
          ...this.order_params
        }
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.order_list = res.data.data
          this.total = res.data.total
        }
        console.log(this.order_list);
      })
    },
    // 当前页变化
    current_change(val) {
      this.order_params.page = val
      this.status(val)
    },
    // 支付
    pay_click(code) {
      // console.log(`${window.location.origin}/index/pay/pay/code/${code}.html`);
      window.location.href = `${window.location.origin}/index/pay/pay/code/${code}.html`
    },
    // 开发票
    openFp(){
      this.$router.push('/personalCenter/myInvoice?state=2')
    },

    // 取消订单
    cancel_order(id) {
      console.log(id);
      let _this = this 
      _this.loading = true
      _this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/cancel_order',
        data: {
          id: id
        }
      }).then(res => {
        if (res.code === 200) {
          this.$notify({
            title: '成功',
            message: '取消订单成功',
            type: 'success'
          });
          _this.getOrderData()
        }else {
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
          }
        _this.loading = false
      })
    }
  },
  created () {
    this.getOrderData()
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 0px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  padding: 50px 27px 50px 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}
.tabs_info>.kfp{
  width: 88px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  border: 1px solid #f00;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  color: #f00;
  position: absolute;
  top: -39px;
  right: 40px;
  user-select: none;
  cursor: pointer;
}
.order{
  width: 980px;
  height: 273px;
  padding: 0 25px;
  margin: 25px 0;
  position: relative;
  border-radius: 17px 17px 17px 17px;
  background: #F6F6F6;
}
.order_top {
  display: flex;
  justify-content: space-between;
  padding: 26px 0;
  color: #898989;
  border-bottom: 1px solid #EEEEEE;
}
.order_bottom{
  padding: 26px 0;
  display: flex;
}

.order_bottom_left{
  width: 187px;
  height: 140px;
  border-radius: 10px 10px 10px 10px;
  background: rgba(214,235,233,1);
}

.order_bottom_left img {
  border-radius: 10px;
}
.order_bottom_right{
  padding: 0 0 0 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  // justify-content: space-between;
}  


// rder_bottom_right 右侧布局
.order_bottom_right_B,
.order_bottom_right_c{
  display: flex;
  justify-content: space-between
}
.order_bottom_right_c{
  flex: .7;
  display: flex;
  align-items: end;
  margin-top: 28px;
}
.oeder_class_name {
  font-size: 26px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.add_class {
  font-size: 16px;
  color: #7E7E7E;
}

.drop_out_img{
  position: absolute;
  top: 25px;
  right: 0px;
  z-index: 1001;
}

 .mask{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 17px;
    background: #F6F6F6;
    opacity: .7;
    z-index: 1000;
  }

  .text {
    color: #999999;
  }


  .bujiao_btn{
    width: 95px;
    color: #FFFFFF;
    font-weight: R;
    height: 37px;
    cursor: pointer;
    border-radius: 18px;
    background: rgba(15,111,237,1);
  }

  .unpaid_btn{
    width: 95px;
    height: 37px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    border-radius: 18px 18.5px 18.5px 18.5px;
    background: rgba(15,111,237,1);
  }

  .quxiao{
    color: #797979 !important;
    margin-right: 22px;
    background: rgba(220,220,220,1);
  }

  .order_img{
    height: 100%;
    display: block;
  }
</style>

<style lang="less" scoped>
/deep/ .el-pagination{
  display: flex;
  justify-content: end;
}

/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}


</style>