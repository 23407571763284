<template>
  <div v-loading="loadPage" :style="{backgroundColor:pageState==3?'#ffffff':'',padding:pageState==3?'100px 0':''}">
    <template v-if="pageState==1">
      <div class="tabs">
        <myTabs :ishonor="true" :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
      </div>
      <div class="tabs_info" v-loading="loading">
        <div v-for="(item,index) in invoiceData" :key="index" class="invoiceItem" @click="itemClick(item)">
          <div class="invoiceItem_title">
            <span>发票信息</span>
            <span class="btnTag" :class="item.status==2?'btnTag_hui':''">
              {{ item.status==1?'开票中':'已开票' }}
            </span>
          </div>
          <div class="invoiceItem_cont">
            <div v-show="item.company">
              <span style="vertical-align: middle;">公司名称:</span>
              <div class="gsNameClass">{{ item.company }}</div>
            </div>
            <div v-show="item.company"><span>公司税号:</span>{{ item.number }}</div>
            <div v-show="!item.company"><span>名称:</span>{{ item.student_name }}</div>
            <div>
              <span>开票金额:</span>
              <div style="color: #f00;display: inline-block;">￥{{ item.money }}</div>
            </div>
            <div v-show="item.status==1"><span>申请时间:</span>{{ item.creat_time | handlerTime }}</div>
            <div v-show="item.status==2"><span>开票时间:</span>{{ item.success_time | handlerTime }}</div>
          </div>
          <div v-show="item.status==2" class="kpXinxi">
            <i class="el-icon-warning"></i>请前往预留邮箱查看发票信息
          </div>
        </div>
        <div v-if="!loadPage&&config.empty" class="emptyClass">
          <img src="https://static.shanchuangjiaoyu.com/api/upload/crm/2023/03/29/v9adRBiW0s4XtAda6iKLr9OoP4hIdcO2MHq3QhYR.png" />
          <div>暂无发票</div>
        </div>
        <div v-else class="loadClass">
          <div v-if="loadPage"></div>
          <div v-else-if="config.isEnd">--暂无更多数据--</div>
          <div v-else style="color: #2782f9;user-select: none;cursor: pointer;" @click="loadNextPage">
            <i class="el-icon-refresh-right"></i>加载更多
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="pageState==2">
      <div class="openInvoice">
        开票金额：<span style="color: #f00;">{{ invoiceInitData.total_price }}元</span>
      </div>
      <div class="openInvoiceList">
        <!-- 个人企业的单选 -->
        <div class="openInvoiceList_item_top">
          <div @click="chooseItem(1)">
            <div class="openInvoiceList_item_top_circle">
              <span v-show="chooseIndex==1"></span>
            </div>
            <span>个人</span>
          </div>
          <div @click="chooseItem(2)">
            <div class="openInvoiceList_item_top_circle">
              <span v-show="chooseIndex==2"></span>
            </div>
            <span>企业</span>
          </div>
        </div>
        <div class="openInvoiceList_line"></div>
        <!-- 企业 -->
        <template v-if="chooseIndex==2">
          <div class="formItem" style="margin-top: 50px;">
            <span>公司名称<i style="color: #f00;">*</i></span>
            <el-input style="width: 630px;" v-model="gsData.gsname" placeholder="请输入公司名称"></el-input>
          </div>
          <div class="formItem" style="margin-bottom: 0;">
            <span>公司税号<i style="color: #f00;">*</i></span>
            <div style="width: 630px;height: 84px;">
              <el-input style="width: 630px;height: 60px;line-height: 60px;" @input="checkShuihao" v-model="gsData.shuihao" placeholder="请输入纳税人识别号"></el-input>
              <div class="checkSh" :style="{opacity:checkTeshu?'1':'0'}">不允许存在特殊字符</div>
            </div>
          </div>
          <div class="formItem" style="margin-top: 0;">
            <span>开户银行</span>
            <el-input style="width: 630px;" v-model="gsData.khYinhang" placeholder="收票单位开户银行"></el-input>
          </div>
          <div class="formItem">
            <span>银行账号</span>
            <el-input style="width: 630px;" v-model="gsData.khZhanghao" placeholder="收票单位银行账号"></el-input>
          </div>
          <div class="formItem">
            <span>手机号</span>
            <el-input style="width: 630px;" v-model="gsData.tel" placeholder="请输入收票人手机号"></el-input>
          </div>
          <div class="formItem">
            <span>邮箱<i style="color: #f00;">*</i></span>
            <el-input style="width: 630px;" v-model="gsData.email" placeholder="请输入收票人邮箱"></el-input>
          </div>
        </template>
        <!-- 个人 -->
        <template v-else>
          <div class="formItem" style="margin-top: 50px;">
            <span>名称<i style="color: #f00;">*</i></span>
            <el-input style="width: 660px;" v-model="grData.name" placeholder-style="font-size:40px" placeholder="请输入姓名"></el-input>
          </div>
          <div class="formItem">
            <span>手机号</span>
            <el-input style="width: 660px;" v-model="grData.tel" placeholder="请输入收票人手机号"></el-input>
          </div>
          <div class="formItem">
            <span>邮箱<i style="color: #f00;">*</i></span>
            <el-input style="width: 660px;" v-model="grData.email" placeholder="请输入收票人邮箱"></el-input>
          </div>
        </template>
        <div class="myBtns">
          <div class="btn1" @click="$router.go(-1)">返回</div>
          <div class="btn2" :class="gsData_check || grData_check?'btn2_check':''" @click="submitChange">确认提交</div>
        </div>
      </div>
    </template>
    <template v-else-if="pageState==3">
      <div style="font-size: 36px;text-align: center;line-height: 330px;">
        <img style="width: 287px;height: 269px;" src="https://static.shanchuangjiaoyu.com/api/upload/crm/2023/03/29/dSNKNE7spoxGwG53SOVNMo3ddLCErPoZJgWnTF9G.png">
      </div>
      <div class="myBtns">
          <div class="btn1" @click="$router.push('/Index')">返回首页</div>
          <div class="btn2 btn2_check" @click="confirm">确定</div>
        </div>
    </template>
  </div>
</template>

<script>
const add0 = function(val){
  return val>9?val:'0'+val
}
export default {
  data() {
    return {
      loading: false,
      tabsList: [
        {
          tabs_title: '我的发票'
        }
      ],
      tabs_active: 0,
      invoiceData:[],
      config:{page:1,size:3,isEnd:false,empty:false},
      pageState:1,//1发票列表，2开票，3提交成功
      chooseIndex:1,
      gsData:{
        gsname:'',
        shuihao:'',
        address:'',
        tel:'',
        khYinhang:'',
        khZhanghao:'',
        email:''
      },
      gsData_check:false,
      grData_check:false,
      grData:{
        name:'',
        tel:'',
        email:'',
      },
      invoiceInitData:{},
      loadPage:false,
      checkTeshu:false,
    }
  },
  methods: {
    tabsClick(index) {
      this.tabs_active = index
    },
    // -----------发-票-列-表-页--------------
    getList(){
      if(this.pageState!=1){
        return false;
      }
      this.loadPage=true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/get_invoice_list',
        data: {
          page:this.config.page,
          list_rows:this.config.size
        }
      }).then(res => {
        this.loadPage=false
        if (res.code === 200) {
          // 是否为空
          this.config.empty=Boolean(res.data.total==0)
          if(this.config.empty){
            this.invoiceData=[]
            return false;
          }
          // 是否是最后一页
          this.config.isEnd=Boolean(res.data.list.length<this.config.size)
          if(res.data.current==1){
            this.invoiceData=res.data.list
          }else{
            this.invoiceData.push(...res.data.list)
          }
        }else {
          this.$notify.error({
            title: '提示',
            message: `${res.msg?res.msg:'未知错误'}`,
            customClass:'dbmdbm'
          })
        }
      })
    },
    // 初始化列表页
    initList(){
      this.invoiceData=[]
      this.config={page:1,size:4,isEnd:false,empty:false}
      this.getList()
    },
    // 点击列表项
    itemClick(item){
    },
    // 加载下一页
    loadNextPage(){
      this.config.page++
      this.getList()
    },
    


    // -------开-票-页-方-法--------
    getInvoiceInit(){
      if(this.pageState!=2){
        return false;
      }
      this.initInvoice()
      let userinfo=window.localStorage.getItem('userInfo')
      this.loadPage=true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/get_info',
        data: {
          student_id: JSON.parse(userinfo).id
        }
      }).then(res => {
        if (res.code === 200) {
          this.loadPage=false
          this.invoiceInitData=res.data
        }else {
          this.$notify.error({
            title: '提示',
            message: `${res.msg?res.msg:'未知错误'}`,
            customClass:'dbmdbm'
          });
          setTimeout(() => {
            this.loadPage=false
            this.$router.go(-1)
          }, 1000);
        }
      })
    },
    chooseItem(e){
      this.initInvoice(true)
      this.chooseIndex=e
    },
    // 初始化开票form
    initInvoice(bool){
      this.gsData={
        gsname:'',
        shuihao:'',
        address:'',
        tel:'',
        khYinhang:'',
        khZhanghao:'',
        email:''
      }
      this.grData={
        name:'',
        tel:'',
        email:''
      }
      if(!bool){
        this.chooseIndex=1
      }
    },
    // 税号筛选特殊字符
    checkShuihao(e){
      console.log(e);
      let val=e
      let regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im
      let regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im
      if(regEn.test(val) || regCn.test(val)) {
        this.checkTeshu=true
      }else{
        this.checkTeshu=false
      }
    },
    // 开票提交
    submitChange(){
      this.checkItem(bool=>{
        if(bool){
          let obj={
            order_id:this.invoiceInitData.id,
            code:this.invoiceInitData.order_code,
            name:this.invoiceInitData.name,
            money:this.invoiceInitData.total_price,
            platform:this.invoiceInitData.platform,
            type:2,
          }
          if(this.chooseIndex==1){
            obj.student_name=this.grData.name
            if(this.grData.tel)obj.tel=this.grData.tel
            obj.email=this.grData.email
          }else{
            if(this.gsData.tel)obj.tel=this.gsData.tel
            obj.email=this.gsData.email
            obj.company=this.gsData.gsname
            obj.number=this.gsData.shuihao
          }
          this.loadPage=true
          this.$axios({
            method:'POST',
            url:'api/officialWebsite/computer/yuan_cheng/invoice_add',
            data:obj
          }).then(res => {
            this.loadPage=false
            if (res.code == 200){
              this.pageState=3
            }else {
              this.$notify.error({
                title: '提示',
                message: `${res.msg?res.msg:'未知错误'}`,
                customClass:'dbmdbm'
              });
            }
          })
        }else{
          this.$notify.error({
            title: '提示',
            message: '请将必填项补充完整！',
            customClass:'dbmdbm'
          });
        }
      })
    },
    // 验证必填项
    checkItem(callback){
      if(this.chooseIndex==1){
        if(!this.grData.name){
          callback(false)
        }else if(!this.grData.email){
          callback(false)
        }else{
          callback(true)
        }
      }else{
        if(!this.gsData.gsname){
          callback(false)
        }else if(!this.gsData.shuihao){
          callback(false)
        }else if(!this.gsData.email){
          callback(false)
        }else{
          callback(true)
        }
      }
    },
    // 提交成功后的确定按钮事件
    confirm(){
      this.pageState=1
      this.initList()
    },
  },
  filters: {
    handlerTime(val){
      if(!val){
        return '--'
      }
      let date=new Date(val*1000)
      let year=date.getFullYear()
      let month=date.getMonth()+1
      let day=date.getDate()
      let hour=date.getHours()
      let minute=date.getMinutes()
      let second=date.getSeconds()
      return `${year}-${add0(month)}-${add0(day)} ${add0(hour)}:${add0(minute)}:${add0(second)}`;
    },
  },
  watch: {
    '$route.query':{
      handler(newval){
        if(newval.state){
          this.pageState=Number(newval.state)
          this.getInvoiceInit()
        }else{
          this.pageState=1
          this.initList()
        }
      },
      immediate:true
    },
    "gsData":{
      handler(newval){
        if(this.chooseIndex==2){
          this.gsData_check=Boolean(newval.gsname&&newval.email&&newval.shuihao)
        }else{
          this.gsData_check=false
        }
      },
      deep:true
    },
    "grData":{
      handler(newval){
        if(this.chooseIndex==1){
          this.grData_check=Boolean(newval.name&&newval.email)
        }else{
          this.grData_check=false
        }
      },
      deep:true
    },
  },
}
</script>

<style scoped>
.tabs {
  display: flex;
}
.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  padding: 40px;
  background-color: #FFFFFF;
  position: relative;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}
.invoiceItem{
  width: 960px;
  background-color: #f4f4f4;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 20px;
}
.invoiceItem_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  line-height: 75px;
  border-bottom: 1px solid #e2e2e2;
  font-weight: bold;
  font-size: 22px;
  color: #333333;
}
.btnTag{
  width: 104px;
  height: 42px;
  line-height: 40px;
  border: 1px solid #e2acb1;
  text-align: center;
  display: inline-block;
  border-radius: 12px;
  color: #f54a51;
  letter-spacing: 2px;
  font-weight: normal;
}
.btnTag_hui{
  border-color: #999999;
  color: #999999;
}
.invoiceItem_cont{
  line-height: 56px;
  padding: 16px 22px;
  font-size: 22px;
}
.invoiceItem_cont>div>span{
  width: 110px;
  display: inline-block;
  margin-right: 30px;
  letter-spacing: 2px;
}
.kpXinxi{
  width: 918px;
  height: 48px;
  line-height: 48px;
  /* background-color: #FFFFFF; */
  margin: 0 auto;
  font-size: 20px;
  color: #FC4B4B;
  padding-left: 30px;
  box-sizing: border-box;
  letter-spacing: 3px;
}
.gsNameClass{
  width: 600px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}
.openInvoice{
  height: 100px;
  line-height: 100px;
  padding-left: 50px;
  border-radius: 20px;
  background-color: #B3D9FF;
  font-weight: 400;
  font-size: 32px;
  color: #333333;
}
.openInvoiceList_item_top{
  height: 90px;
  line-height: 90px;
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 32px;
  color: #333333;
}
.openInvoiceList{
  background-color: #FFFFFF;
  margin-top: 20px;
  border-radius: 4px;
  padding: 16px;
  position: relative;
}
.openInvoiceList_line{
  width: 100%;
  height: 2px;
  opacity: 0.1;
  background-color: #000000;
  position: absolute;
  top: 106px;
  left: 0;
  right: 0;
}
.openInvoiceList_item_top>div{
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin-right: 60px;
}
.openInvoiceList_item_top>div>span{
  vertical-align: middle;
  letter-spacing: 1px;
  margin-left: 16px;
}
.openInvoiceList_item_top_circle{
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #007DFC;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.openInvoiceList_item_top_circle>span{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #007DFC;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.formItem{
  width: 800px;
  line-height: 60px;
  margin: 24px auto;
  display: flex;
  justify-content: space-between;
  font-size: 28px;
}
.myBtns{
  text-align: center;
  margin:120px auto 40px;
}
.myBtns>div{
  width: 220px;
  height: 76px;
  text-align: center;
  line-height: 74px;
  border: 1px solid #B9B9B9;
  border-radius: 10px;
  display: inline-block;
  font-size: 28px;
  letter-spacing: 1px;
  user-select: none;
  cursor: pointer;
  font-weight: 400;
  color: #333333;
}
.myBtns>.btn2{
  background-color: #D5D5D5;
  border-color: #D5D5D5;
  color: #FFFFFF;
  margin-left: 100px;
}
.myBtns>.btn2_check{
  background-color: #007DFC;
  border-color: #007DFC;
}
.loadClass{
  text-align: center;
  line-height: 40px;
  height: 40px;
}
.loadClass>div{
  display: inline-block;
  letter-spacing: 2px;
  font-size: 16px;
  color: #767777;
}
.formItem /deep/ input{
  height: 60px;
  font-size: 28px;
  border-radius: 10px;
}
.emptyClass{
  text-align: center;
  padding: 100px 0;
  font-size: 28px;
  text-align: center;
  color: #333333;
  letter-spacing: 3px;
}
.emptyClass>img{
  width: 396px;
  height: 330px;
}
.checkSh{
  color: #f54a51;
  height: 24px;
  line-height: 20px;
  font-size: 12px;
  padding-left: 15px;
  box-sizing: border-box;
  padding-bottom: 4px;
}
</style>
<style>
.dbmdbm{
  font-size: 40px;
}
.dbmdbm>i{
  font-size: 36px !important;
  margin-right: 10px;
}
.dbmdbm>div>h2{
  font-size: 24px !important;
}
.dbmdbm>div>div>p{
  font-size: 20px;
}
</style>