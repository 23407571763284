export const jiangshiList = [{
		img: process.env.VUE_APP_OSS_URL + 'dongyu.png',
		name: '东宇老师',
		subtitle: '著名设计讲师',
		content: '毕业于南京艺术学院视觉传达专业，工信部认证高级视觉设计师、Adobe认证高级UI设计师，Adobe中国认证设计讲师。任教期间积极参与社会各类设计类论坛活动，曾斩获火凤凰优秀作品奖等。多年来一直投身数字艺术教培行业；长期奋战在教学一线，讲解知识点细腻，全方位的让学生认识理解、掌握每个知识点。',
		linian: '教学理念：对教学用心,对学生走心,让家长放心！'
	}, {
		img: process.env.VUE_APP_OSS_URL + 'liuyi.png',
		name: '六一老师',
		subtitle: '著名设计讲师',
		content: '毕业于中国传媒大学，电商设计讲师，精通PS、AI、PR、C4D等Adobe软件，有丰富的运营实战经验，曾获Oneshow中华创意奖-优秀奖；服务于英树、真珠美学等多家美妆；授课中注重理论与实际相结合，掌握视觉营销秘诀，持续打造职场优势。',
		linian: '教学理念：一个今天胜过两个明天'
	}, {
		img: process.env.VUE_APP_OSS_URL + 'xuanxuan.png',
		name: '轩轩老师',
		subtitle: '著名设计讲师',
		content: '毕业于天津美术学院，具有扎实的设计功底和独特的审美能力。作品多次参加全国、省、市设计类比赛，收录在设计类刊册并举办展览。坚持认真负责的授课态度，在培养学生的设计兴趣的同时，更善于激发他们对于生活的观察与热爱。',
		linian: '教学理念：一键开启你的艺术世界'
	}
	/* , {
		img: process.env.VUE_APP_OSS_URL + 'xinru.png',
		name: '歆儒老师',
		subtitle: '著名设计讲师',
		content: '毕业于南京艺术学院，获取了商业美术设计师证书，曾在学院获得过设计金奖并获得了高级设计师称号。精通本岗位的专业知识和业务技能，熟悉有关行业规范，关注行业的发展趋势，时刻保持强烈的创新意识，对学生富有耐心，会用各种方式丰富课堂。',
		linian: '教学理念：做一件好事并不难,难的是养成一种做好事的习惯。'
	} */
	, {
		img: process.env.VUE_APP_OSS_URL + 'liujuan.png',
		name: '泡芙老师',
		subtitle: '著名设计讲师',
		content: '毕业于齐鲁工业大学,具有较高的艺术素养,平时注重设计理论知识的积累,并将理论应用到作品中。了解当下设计的流行趋势,设计注重细节、重视用户体验,熟练掌握各种设计相关软件的应用。有丰富的实际项目工作经验和教学经验,授课深入浅出,善于激发学生的学习兴趣。',
		linian: '教学理念：做一切事情都应尽力而为,半途而废永远不行'
	}
]

export const youshiList = [{
		img: process.env.VUE_APP_OSS_URL + 'youhsi_duoyuanhua.png',
		title: '丰富的多元化课程',
		content: '拥有丰富的多元化课程，课程内容丰富，多种课程供学员选择，名师在线讲解，与学生互动学习！'
	},
	{
		img: process.env.VUE_APP_OSS_URL + 'youshi_zhibo.png',
		title: '在线直播教学',
		content: '直播讲师在线辅导，伴随式学习服务高清镜头直播教学，讲师线上演示全过程，学员在线互动提问。'
	},
	{
		img: process.env.VUE_APP_OSS_URL + 'youhsi_renyuan.png',
		title: '伴随式学习辅导',
		content: '课后班主任悉心辅导，伴随式学习服务，为学员在线答疑！ '
	},
	{
		img: process.env.VUE_APP_OSS_URL + 'youshi_xunhuan.png',
		title: '可循环听课',
		content: '课堂直播录课，学员可以根据自己的实际情况，随时随地，循环听课。'
	},
]

export const jingpinkeList = [{
	img: 'https://static.shanchuangjiaoyu.com/web/home/img01.jpg',
	renshu: '511',
	title: '平面设计精通进阶课',
	touxiang: 'https://static.shanchuangjiaoyu.com/ycpc/assets/xixi.png',
	name: '熙熙老师'
}, {
	img: 'https://static.shanchuangjiaoyu.com/web/home/img02.png',
	renshu: '412',
	title: '电商设计精通进阶课',
	touxiang: 'https://static.shanchuangjiaoyu.com/ycpc/assets/xiaorentou1.png',
	name: '泡芙老师'
}, {
	img: 'https://static.shanchuangjiaoyu.com/web/home/img03.png',
	renshu: '231',
	title: '字体设计精通进阶课',
	touxiang: 'https://static.shanchuangjiaoyu.com/ycpc/assets/xiaorentou2.png',
	name: '小乔老师'
}]

export const studentsList = [{
		id: 1,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/rongxu_touxiang.jpg",
		text: "荣旭",
		miaoshu: "来远程学习",
		num: 3,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/rongxu2.jpg",
		wenzi: "短短学习三个月，兼职收入已经达到1200+元，最想要感谢的就是远程教育老师认真负责的态度，他们深深地感染了我，那我到底有什么样的学习心得呢，快来点开看一下吧！",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/rongxu.mp4"
	},
	{
		id: 2,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/jiangchaochen_touxiang.jpg",
		text: "姜超晨",
		miaoshu: "来远程学习",
		num: 6,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/jiangchaochen2.jpg",
		wenzi: "从设计小白到独立完成设计项目到底有多顺利，我在远程教育老师们的专业指导之下，进步飞快，现在已经可以用兼职接单的收入，如果你也有这样的计划，那就赶快去联系老师报名学习吧！",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/jiangchaochen.mp4"
	},
	{
		id: 3,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/jiangxinyi_touxiang.jpg",
		text: "江馨怡",
		miaoshu: "来远程学习",
		num: 5,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/jiangxinyi.jpg",
		wenzi: "远程教育的在线教育学习模式，只要有网络的地方就可以学习，非常便捷。我现在不仅能够熟练的应用，还能积累一些财富，在我成长的道路上有远程教育的陪伴，相信我未来的学习和工作会更有趣，加油！",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/jiangxinyi.mp4"
	},
	{
		id: 4,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/liangyushan_touxiang.jpg",
		text: "梁宇珊",
		miaoshu: "来远程学习",
		num: 9,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/liangyushan.jpg",
		wenzi: "只有行动了才会有结果！学生时代除了要让家里放心以外，还要注重自我成长。我现在能有这样的兼职收入肯定是离不开老师们对我的辛勤指导的，接下里我依然会不骄不躁继续做好自己的事情。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/liangyushan.mp4"
	},
	{
		id: 5,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/shijinlu_touxiang.jpg",
		text: "史金璐",
		miaoshu: "来远程学习",
		num: 6,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/shijinlu2.jpg",
		wenzi: "我刚开始做兼职的时候，还无法达到客户的要求，老师对我的作品做了专业分析和指导之后，渐渐有了门路，同样我的设计功底也变得更加扎实，感谢老师！",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/shijinlu.mp4"
	},
	{
		id: 6,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/tangyingying_touxiang.jpg",
		text: "汤莹莹",
		miaoshu: "来远程学习",
		num: 5,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/tangbyingying.jpg",
		wenzi: "学习至关重要，懒惰一无所获。我是真正从小白一步步做起来的，PS上手比较快，但真要做好的话有些难度，接下来希望可以通过自己不断学习和努力，设计出更加优秀的作品。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/tangyingying.mp4"
	},
	{
		id: 7,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/xuyao_touxiang.jpg",
		text: "徐岩",
		miaoshu: "来远程学习",
		num: 3,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/xuyan.jpg",
		wenzi: "PS、Pr相信大家一定不陌生吧！那你知道它是怎样应用的吗？我在远程教育学习两个月的时间,老师讲解的时候很有耐心，我决定在这里扎实学习，让自己更进一步。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/xuyan.mp4"
	},
	{
		id: 8,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zahngrong_touxiang.jpg",
		text: "张容",
		miaoshu: "来远程学习",
		num: 7,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zhangrong.jpg",
		wenzi: "我是一名在校大学生,老师们的设计本领让我倍感钦佩，跟他们一起学习进步是一件特别有意义的事情！在这里诚邀大家一起来学习。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/zhangrong.mp4"
	},
	{
		id: 9,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yuhongbin_touxiang.jpg",
		text: "俞宏彬",
		miaoshu: "来远程学习",
		num: 6,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/xueyuan001.jpg",
		wenzi: "作为一名在校大学生，在远程教育已经学习一段时间了，总共学习了60节课，现在开始接触到兼职工作，我喜欢设计、喜欢兼职、喜欢这样高专业度的学习机构。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/yuhongbin.mp4"
	},
	{
		id: 10,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zahugnjialong_touxaing.jpg",
		text: "庄嘉隆",
		miaoshu: "来远程学习",
		num: 5,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zhuangjialong2.jpg",
		wenzi: "远程教育是一个一心一意做教育的学习平台，“伴随式”的学习模式，让我体验到了与老师成为良师益友的过程，面对困难时才会更加得心应手，感谢相遇，未来我们一起前行！",
		path: "https://static.shanchuangjiaoyu.com/ycpc/shipin/zhuangjialong.mp4"
	},
	{
		id: 11,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/donghengfen_touxiang.jpg",
		text: "董恒峰",
		miaoshu: "来远程学习",
		num: 3,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/donghengfeng_fengmian.jpg",
		wenzi: "在远程已经学习了一个学期，收获了很多新知识，其中对我的帮助最大的就是学管老师和授课老师，不仅仅在工作上，在生活上也提供了很多宝贵意见，祝愿远程教育蓬勃发展，日盛一日。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/donghenfeng.mp4"
	},
	{
		id: 12,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/fuyiwei_touxiang.jpg",
		text: "付艺薇",
		miaoshu: "来远程学习",
		num: 6,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/fuyiwei_fengmian.jpg",
		wenzi: "我是大一的学生，在远程教育学习了半年左右，刚开始什么都不懂，没有学习设计的相关经验，这里有专业的老师进行细心的指导教育，经过一段时间学习后，兼职接单是完全没有问题。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/fuyiwei.mp4"
	},
	{
		id: 13,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wangliu_touxiang.png",
		text: "王柳",
		miaoshu: "来远程学习",
		num: 5,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wangliu_fengmian.jpg",
		wenzi: "我是一名大二的学生，已经学习半年了，开始的时候也是什么都不懂的小白，慢慢经过老师的悉心教导和练习，开始步入了正轨，可以胜任兼职接单的工作，现在我的零花钱都是自己赚的。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wangliu.mp4"
	},
	{
		id: 14,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wangyanpeng_touxiang.png",
		text: "王燕鹏",
		miaoshu: "来远程学习",
		num: 9,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wangyanpeng_fengmian.jpg",
		wenzi: "我是王燕鹏，大二的学生，20年5月份开始学习，学习了大概30节课吧，让我有了一定的设计基础，并且顺利的接到了兼职单，已经赚了3000多了，非常感谢远程教育的老师们和平台。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wangyanpeng.mp4"
	},
	{
		id: 15,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wuqingyuan_touxiang.jpg",
		text: "吴清源",
		miaoshu: "来远程学习",
		num: 6,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wuqingyuan_fengmian.jpg",
		wenzi: "我在这里学习已经有一段时间了，收获了很多知识和专业技能，非常感谢各位老师，现在我可以通过自己的能力赚取零花钱，并且我相信我会更加努力，学会更多的新知识和技能。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/wuqingyuan.mp4"
	},
	{
		id: 16,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yeyuan_touxiang.jpg",
		text: "叶原",
		miaoshu: "来远程学习",
		num: 5,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yeyuan_fengmian.jpg",
		wenzi: "我是20年12月份开始报名学习的，学了4-5个月的时间，然后在学校接单赚钱，这期间感觉自己干劲十足，因为第一次赚到钱了，感谢兼职6组的组长蔡蔡，让我更快的成长。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yeyuan.mp4"
	},
	{
		id: 17,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yaoshan_touxiang.jpg",
		text: "姚珊",
		miaoshu: "来远程学习",
		num: 3,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yaoshan_fengmian.jpg",
		wenzi: "在远程教育大家庭，学到了很多东西，从懵懂无知的我到现在熟练掌握这些软件，并且能顺利的接单赚钱，也是很开心的，我得到了成长，也希望像我一样喜欢设计的朋友，一样可以得到。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yaoshan.mp4"
	},
	{
		id: 18,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yunzhijie_touxiang.jpg",
		text: "貟志杰",
		miaoshu: "来远程学习",
		num: 2,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zhenzhjie_fengmian.jpg",
		wenzi: "我是远程教育学员小杰，已经学习有两个月了，在这里有宛宛老师的专业指导，让我熟悉的掌握了PS和AI等软件的应用，也有南枫老师的指导，掌握了各种商业海报设计的理念与方法。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/yunzhijie.mp4"
	},
	{
		id: 19,
		img: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zhangjiakui_touxiang.jpg",
		text: "张家奎",
		miaoshu: "来远程学习",
		num: 6,
		month: '个月',
		bg: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zhangjiakui_fengmian.jpg",
		wenzi: "我是大二的学生张家奎，我是从去年6月份开始学习PR设计课程，因为学业原因落下一部分课程，后来在假期补回来了，也顺利的通过了兼职考核，接到了自己力所能及的单子。",
		path: "https://static.shanchuangjiaoyu.com/ycpc/new/xueyuan/zhangjiakui.mp4"
	},
]

export const zuopin_tagsList = [{
	name: '广告设计',
	id: 29
}, {
	name: 'UI设计',
	id: 25
}, {
	name: '电商设计',
	id: 26
}, {
	name: '网页设计',
	id: 27
}, {
	name: '影楼设计',
	id: 28
}, {
	name: '影视后期',
	id: 38
}, {
	name: '室内设计',
	id: 39
}, {
	name: '平面设计',
	id: 24
}]

export const yuanxiaoList = [{
	url: 'hbdldx.png',
	id: 1
}, {
	url: 'c.png',
	id: 2
}, {
	url: 'jndx.png',
	id: 3
}, {
	url: 'njdx.png',
	id: 4
}, {
	url: 'njlkdx.png',
	id: 5
}, {
	url: 'qdlgdx.png',
	id: 6
}, {
	url: 'qdnydx.png',
	id: 7
}, {
	url: 'qlgydx.png',
	id: 8
}, {
	url: 'sdcjdx.png',
	id: 9
}, {
	url: 'sddx.png',
	id: 10
}, {
	url: 'sdnydx.png',
	id: 11
}, {
	url: 'sdnzxy.png',
	id: 12
}, {
	url: 'sdsfdx.png',
	id: 13
}, {
	url: 'sdxhxy.png',
	id: 14
}, {
	url: 'wfxy.png',
	id: 15
}]
