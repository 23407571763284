<template>
	<div class="index">
		<!-- 兼职中心首页 -->
		<div style="height: 56px;">
			<Header-job id="nav"></Header-job>
		</div>
		

		<div class="content">
			<router-view />
		</div>
		
		<Bottom-tab :havSuspension="false"></Bottom-tab>
		<!-- <suspension></suspension> -->
	</div>
</template>

<script>
	import HeaderJob from '../components/HeaderJob.vue'
	import BottomTab from '../components/BottomTab.vue'
	import suspension from '../components/suspension.vue'
	
	export default {
		name: 'index',
		data() {
			return {

			}
		},
		created() {
      this.$on("danghanglan",() => {
        console.log("11111")
      })
		},
		methods: {

			
		},
		components: {
			HeaderJob,
			BottomTab,
			suspension
		}

	}
</script>

<style scoped>
.index {
	background-color: #F5F5F5;
}

</style>
