<template>
  <div style="overflow: hidden">
    <!-- <img
        style="width: 100%; height: auto; padding: 0; margin: 0"
        src="../../assets/image/banners.jpg"
      /> -->
    <!-- <div
      style="
        display: flex;
        flex-flow: row;
        width: 100%;
        height: 550px;
        background-image: url(https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/zixunBgc.jpg);
        background-size: 100% 100%;
        justify-content: center;
      "
    > -->
    <!-- <img src="../../assets/youshiModerImg/Achievement.png" style="width: 402px;height: 159px;margin-top: 262px;" alt=""> -->
    <!-- <div style="width: 1200px; display: flex; margin-top: 176px">
        <div style="display: flex; flex-flow: column; width: 670px">
          <div>
            <span
              style="
                color: #343434;
                font-size: 48px;
                font-weight: 900;
                letter-spacing: 10px;
              "
              >一切只为<span style="color: #2961e7">好学的你</span></span
            >
            <p
              style="
                margin-top: 10px;
                width: 100%;
                color: #575757;
                font-size: 16px;
                line-height: 25px;
              "
            >
              {{
                currentHref.indexOf("yuanchengjy.com") >= 0 ||
                currentHref.indexOf("ycjyzs.com") >= 0
                  ? "精研学堂教育科技（河南）有限公司、海南精研学堂教育科技有限公司同属于精研学堂教育科技（山东）有限公司运营，旗下品牌有精研学堂教育、精研学堂教育科技（河南），海南精研学堂教育科技 "
                  : ""
              }}
              {{
                currentHref.indexOf("yuanchengjy.com") >= 0 ||
                currentHref.indexOf("ycjyzs.com") >= 0
                  ? "精研学堂教育科技（山东）有限公司"
                  : "精研学堂教育"
              }}是一家集现代化线上教学培训、课程研发、因材施教的新型教育企业。<br />
              我们的宗旨：持续为社会培养和输送职业技能型人才。<br />
              我们的使命：让我们的每一位学员拥有独当一面的职业技能。<br />
              我们的愿景：牢记职业教育初心，打造百年教育品牌。<br />
              目前拥有10余家分公司，获得国家及省级作品著作权累计300余项，在职教员工600余人，累积已服务学员超过200余万人，拥有90%以上的课程好评率。
            </p>
          </div>
          <div style="width: 100px; height: 60px; margin-top: 20px">
            <div class="b_contact" @click="dialogVisible = true">联系我们</div>
          </div>
        </div>

        <div
          style="
            height: auto;
            margin-left: 90px;
            margin-top: -60px;
            position: relative;
          "
        >
          <img
            style="width: 100%; height: auto"
            src="https://static.shanchuangjiaoyu.com/ycpc/img/zjvideo.png"
            alt=""
          />
          <div
            style="
              position: absolute;
              bottom: 180px;
              left: 173px;
              cursor: pointer;
            "
            @mouseover="c_play"
            @mouseout="cplay_h"
            @click="dialogVisibles = true"
          >
            <img :src="cplay" alt="" />
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    <!-- 看视频 -->
    <div class="jiarus">
      <!-- 	<el-dialog title="" :visible.sync="dialogVisible2" width="22%" center :show-close="false"
				@close="closeDialog" class="my-info-dialog">
				<div
					style="width: 352px;height: 567px;position: relative;top: 0; left: 50%; margin-left: -171px;background-color: #FFFFFF;">
					<video muted="muted" controls="controls" type="video/mp4" loop="-1" autoplay="autoplay" :src="video"
						ref="video" controlslist="nodownload" @timeupdate="updateTime"
						style="width: 100%; height: 100%; object-fit: fill">
					</video>
				</div>
			</el-dialog> -->

      <el-dialog @close="closeDialog" :close-on-press-escape="true" :visible.sync="dialogVisibles" width="30%" center
        :show-close="false" :close-on-click-modal="true" class="my-info-dialog">
        <div class="videoBox">
          <video class="videoMp" controls="controls" type="video/mp4" loop="-1" ref="video" autoplay
            :poster="ossUrl + 'guanwang_gongsijianjie.jpg'"
            src="https://static.shanchuangjiaoyu.com/ycpc/shipin/xuanchaun.mp4" controlslist="nodownload"
            @timeupdate="updateTime"></video>
        </div>
      </el-dialog>
    </div>
    <!-- 资质荣誉 -->
    <div style="
        margin-top: 70px;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      ">
      <div class="title">
        <h2><span>实力品牌</span>收获认可</h2>
        <img src="../../assets/image/title_bg.png" alt="" style="right: 100px" />
        <p>获得了由山东省文化厅颁发的网络文化经营许可证，</p>
        <p>取得了诸多国家层面认可的知识产权与自主研发的数十余项证书。</p>
      </div>
      <!-- <div class="content">
        <img src="../../assets/image/shili.jpg" alt="" srcset="" />
        <div class="c_right">
          <div class="c_bg">
            <h4>中国品牌创新发展工程企业</h4>
            <p>
              “中国品牌创新发展工程”是由国务院发起、中国互联网新闻中心联合中国品牌领袖联盟等相关机构和媒体共同启动。精研学堂教育经过层层筛选，入驻中国创新品牌库。 
            </p>
          </div>
          <div class="c_bg">
            <h4>诚信品牌奖</h4>
            <p>
             精研学堂教育始终秉承教育为本，诚信立业的经营理念，用高效优质的服务，收获广大学子的认可。精研学堂教育励精图治，稳步前行，在第七届中国品牌创新发展论坛上，由组委会颁发授予精研学堂教育《诚信品牌奖》奖项。
            </p>
            <img src="../../assets/image/zhang.png" alt="" />
          </div>
        </div>
      </div> -->
      <ul class="content_bm">
        <!-- <li>
          <img src="../../assets/image/zhs.jpg" alt="" srcset="" />
          <div class="con_bg">
            <h4>沃学奖</h4>
            <p>
             荣获在线教育与智慧学习中国峰会组委会颁发的最具口碑在线教育品牌 “沃学奖”，这对于精研学堂教育来说既是一份荣誉也是一份担当，未来精研学堂教育将继续不负众望，不断研发和优化课程，为在线教育行业的发展贡献力量。
            </p>
          </div>
        </li> -->
        <li>
          <div class="c_bg_pic">
            <img :src="OSS_URL_YC + 'pinpai.jpg'" alt="" srcset="" />
          </div>
          <div class="con_bg">
            <h4>准予行政许可</h4>
            <p>
              精研学堂教育作为互联网文化国家重点高新技术企业，在线上教育行业连续多年获得权威机构颁发的荣誉证书，其中包括由山东省文化和旅游厅颁发《准予行政许可决定书》和《网络文化经营许可证》。
            </p>
            <img src="../../assets/image/qx_bg.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <!-- 教学实力 -->
    <div style="
        width: 100%;
        background-color: #fafafa;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding-top: 250px;
        height: 1050px;
      ">
      <div class="title">
        <h2>教学<span>实力</span></h2>
        <img style="top: -46px; right: 0px; left: 210px" src="../../assets/image/title_bg.png" alt="" />
        <div style="text-align: left">
          <p>
            精研学堂教育凭借着雄厚的师资力量，将传统线下教学与现代线上教学优势相结合。并通过调研全<br />
            国不同行业科技型企业的用人需求，从实用性角度出发，打造出了注重实操的【智能化培<br />
            训教学系统】，目的就是让我们每一位学员都能掌握独当一面的实战职业技能。
          </p>
        </div>
      </div>
      <div style="
          width: 1200px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 30px auto;
        ">
        <img src="https://static.shanchuangjiaoyu.com/ycpc/img/jxsl.png" alt="" />
      </div>
      <div class="jxsl_bg">
        <h4>发明专利</h4>
        <p>
          正是因为拥有独特的培训方式与自主研发的智能教学系统，于2022年通过了国家知识产权局专家的层层审核，获得了具有行业标准意义的【互联网精研学堂教育培训方法及系统】发明专利证书，更加证明了精研学堂教育的教学实力。
        </p>
      </div>
    </div>
    <!-- 万企千校工程 -->
    <div class="gcontent">
      <div class="title">
        <h2><span>万企千校</span>工程</h2>
        <img style="left: 50%; margin-left: -80px" src="../../assets/image/title_bg.png" alt="" />
        <div>
          <p>万企千校工程、劳务派遣、人力资源</p>
        </div>
      </div>
      <div class="gcon">
        <div class="gcon_t">
          <div class="gc_l" style="width: 835px">
            <h2>万企千校工程、劳务派遣、人力资源</h2>
            <p>
              精研学堂教育已与全国数万家科技型企业、高等院校达成战略合作，目的就是为了给学员提供后续的就业
            </p>
            <p>
              保障，也就是说学员毕业后，如果想从事这方面的工作，我们会根据你所学的专业课程以及意向发展城市,
            </p>
            <p>进行精准的就业推荐。</p>
          </div>
          <div class="gc_r" style="right: 175px">
            <img src="../../assets/image/qi_bg.png" alt="" />
          </div>
        </div>
        <div class="gcon_b">
          <div class="gcon_b_p">
            <img :src="OSS_URL_YC + 'renLiZiYuanZheng.jpg'" alt="" srcset="" />
            <img :src="OSS_URL_YC + 'laoWuPaiQianZheng.jpg'" style="position: relative" alt="" srcset="" />
            <img src="../../assets/image/qx_bg.png" style="width: 15%; position: relative; right: 70px; z-index: 0" alt=""
              srcset="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 职业技能评测证书 -->
    <!-- <div class="zyjn">
      <div class="title">
        <h2><span>职业技能评测</span>证书</h2>
        <img
          style="left: 50%; margin-left: -80px"
          src="../../assets/image/title_bg.png"
          alt=""
        />
        <div>
          <p>职业技能培训等级评测的专业服务机构</p>
        </div>
      </div>
      <div class="gcon">
        <div class="gcon_t" style="height: 160px">
          <div
            class="gc_l"
            style="left: 90px;
}"
          >
            <h2>职业技能评测证书</h2>
            <p>
              学员结课后还可以报考由【中国精研学堂教育协会】颁发的《职业技能评测证书》，这也是我们精研学堂教育为鼓励每一位学员更加的重视和努力学习职业技能的一项福利。
            </p>
          </div>
          <div class="gc_r">
            <img
              src="https://static.shanchuangjiaoyu.com/ycpc/img/zyjn_bg.png"
              alt=""
            />
          </div>
        </div>
        <div class="gcon_b">
          <div class="gcon_b_p" style="margin-left: 70px">
            <img
              src="https://static.shanchuangjiaoyu.com/ycpc/img/1.jpg"
              style="width: 36%"
              alt=""
              srcset=""
            />
            <div class="gcon_b_t">
              <p>
                凭借此证书，不但会被合作企业优先录用，同时学员入职转正后将给予500至5000不等的一次性岗位补贴，具体补贴标准以用人企业及应聘岗位的补贴声明为准。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 平台优势 -->
    <div class="gcontent" v-if="false">
      <div class="title">
        <h2>平台<span>优势</span></h2>
        <img style="left: 50%; margin-left: -80px; top: -50px" src="../../assets/image/title_bg.png" alt="" />
        <div>
          <p>伴随式教育＋四师体系，激发学习热情</p>
        </div>
      </div>
      <div class="pt_content">
        <ul>
          <li>
            <div class="pt_tbox">
              <h3>
                <p>丰富的</p>
                直播课程
              </h3>
              <span>ONE</span>
            </div>
            <div class="pt_bbox">
              <p>
                精研学堂教育旗下课程包含了UI设计、平面设计、电商设计、网页设计、影视剪辑和影视后期等当下热门课程。
                <br />
                学员可以根据自己的喜好和需求进行挑选。
              </p>
            </div>
          </li>
          <li>
            <div class="pt_tbox">
              <h3>
                <p>强大的</p>
                师资团队
              </h3>
              <span>TWO</span>
            </div>
            <div class="pt_bbox">
              <p>
                精研学堂教育讲师均专业科班出身，学术精湛、实战能力超强。
                班主任制定学前规划，专业讲师直播授课，多位行业名师在线讲解和指导帮助学员顺利接收知识。
                <br />
                助教老师精准作业点评，学习管理师12小时在线答疑，帮助学员解决问题。
              </p>
            </div>
          </li>
          <li>
            <div class="pt_tbox">
              <h3>
                <p>雄厚的</p>
                教学资源
              </h3>
              <span>THREE</span>
            </div>
            <div class="pt_bbox">
              <p>
                通过现代化互联网技术和应用信息科学技术快速学习，避免了像传统教育容易受场地和环境的影响。<br />
                用“直播+回放+伴随式”的学习方式与众多行业名师在线讲解，全方位解决学习问题帮助学员成长。
              </p>
            </div>
          </li>
          <li>
            <div class="pt_tbox">
              <h3>
                <p>专业的</p>
                平台服务
              </h3>
              <span>FOUR</span>
            </div>
            <div class="pt_bbox">
              <p>
                线上教育+民办学校双资质，学员学习双保险。
                讲师直播教学，学员可在线互动提问。<br />
                专属作业区，可跨地域、跨学科分享交流，认识更多志趣相投的伙伴。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 里程碑 -->
    <div v-if="false" style="
        margin-top: 85px;
        width: 100%;
        height: auto;
        background-color: #fafafa;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding-top: 60px;
      ">
      <div class="title">
        <h2><span>里程</span>碑</h2>
        <img style="left: 35%; margin-left: -80px; top: -50px" src="../../assets/image/title_bg.png" alt="" />
        <div>
          <p style="padding-left: 40px">经年累月、反复迭代、勇往直前</p>
        </div>
      </div>
      <ul class="lcbg">
        <li>
          <span>2015</span>
          <p>相继成立多处重要部门</p>
          <p>·教研部</p>
          <p>·学管部</p>
          <p>·业务部</p>
          <p>·技术部</p>
          <p>···</p>
        </li>
        <li>
          <p>2016年精研学堂教育革</p>
          <p>新管理，隆重推出</p>
          <p>专业一流的教务系</p>
          <p>统。</p>
          <span>2016</span>
        </li>
        <li>
          <span>2017</span>
          <p>2017年在全球互联</p>
          <p>网教育的演绎之下，</p>
          <p>成功转型高科技创</p>
          <p>新型企业。</p>
        </li>
        <li>
          <p>2018年精研学堂教育深</p>
          <p>入学习党中央举办</p>
          <p>的全国教育大会精</p>
          <p>神，加速推进教育</p>
          <p>现代化进程。</p>
          <span>2018</span>
        </li>
        <li>
          <span>2019</span>
          <p>2019年是中华人民</p>
          <p>共和国成立70周年，</p>
          <p>精研学堂教育稳中求进，</p>
          <p>获得行业权威发明</p>
          <p>专利证书，与上万</p>
          <p>家企业达成合作，</p>
          <p>真正实现让学员在</p>
          <p>社会上扎根立足。</p>
        </li>
        <li>
          <p>2020年国际新冠疫</p>
          <p>情爆发，精研学堂教育联</p>
          <p>合广大青年学子发</p>
          <p>起万众一心抗击疫</p>
          <p>情声援活动，为中国</p>
          <p>加油！</p>
          <span>2020</span>
        </li>
        <li>
          <h4>展望未来</h4>
          <p>展望未来明确使命，</p>
          <p>坚决执行教育强国理</p>
          <p>念，继续为国家百年</p>
          <p>教育大计做出卓越贡</p>
          <p>献，用理想和创新精</p>
          <p>神获得众多掌声！</p>
        </li>
      </ul>
    </div>
    <!-- 专注教育 -->
    <div v-if="false" style="
        margin-top: 0px;
        padding-bottom: 160px;
        width: 100%;
        height: auto;
        background-color: #ffff;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 150px 0;
      ">
      <div class="title">
        <h2>专注教育<span>公益同行</span></h2>
        <img style="left: 50%; margin-left: -80px; top: -50px" src="../../assets/image/title_bg.png" alt="" />
      </div>
      <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px auto;
          margin-top: 30px;
          position: relative;
        ">
        <div style="
            box-shadow: rgb(225 223 223) 2px 4px 10px 2px;
            border-radius: 20px;
            background: #fff;
            overflow: hidden;
            width: 1215px;
            margin-top: 40px;
          ">
          <img src="https://static.shanchuangjiaoyu.com/ycpc/img/gy.jpg" alt="" />
          <div style="padding: 20px; padding-bottom: 30px; padding-left: 50px">
            <img src="../../assets/image/zjyc/xing.png" alt="" srcset="" />
            <span
              style="color: #575757; font-size: 16px; padding-left: 25px">近年来，精研学堂教育关心慈善事业的发展，前往各地开展爱心捐赠活动，为学校捐赠教学设备等相关物资。公益，让教育事业更美好！</span>
          </div>
          <img style="position: absolute; right: -60px; bottom: 20px" src="../../assets/image/qx_bg.png" alt=""
            srcset="" />
        </div>
      </div>
    </div>
    <!-- 智能教学服务系统 -->
    <div class="zn_bg">
      <div class="cont">
        <div class="zn_tit">
          <h6>
            <span>企</span><span>业</span><span>核</span><span>心</span><span>·</span><span>深</span><span>度</span><span>赋</span><span>能</span>
          </h6>
          <!-- <img src="@/assets/image/enter/ycedu.png" alt="" srcset=""> -->
        </div>
        <div class="gg_title">
          <div class="gg_t">
            <h4>智能教学服务系统</h4>
            <div class="gg_text">让学习过程清晰可控</div>
          </div>
          <!-- <img src="@/assets/image/enter/sycedu.png" alt="" /> -->
        </div>
        <div class="zn_con">
          <div class="zn_con_p">
            <img src="@/assets/image/enter/drj.png" alt="" srcset="" />
            <img src="@/assets/image/enter/zbj.png" alt="" srcset="" />
          </div>
          <div class="zn_con_b">
            <ul>
              <li>
                <div class="zn_w">
                  <h6>在线答疑</h6>
                  <span></span>
                  <p>
                    在学习中可根据个人掌握情况提供学习建议，12小时在线答疑，弥补了传统线下教育容易受环境和地域的影响。教学由班主任和学习管理老师共同制定专属学习计划。
                  </p>
                  <img src="@/assets/image/enter/zxdy.png" alt="" srcset="" />
                </div>
              </li>
              <li>
                <div class="zn_w">
                  <h6>过程可控</h6>
                  <span></span>
                  <p>
                    智能教务系统让学习过程清晰可控。具体表现形式为系统认定作业标准 - 用户上传 - 系统智能分析出结果，导师根据结果进行作业点评，帮助用户修正错误。
                  </p>
                  <img src="@/assets/image/enter/gc.png" alt="" srcset="" />
                </div>
              </li>
              <li>
                <div class="zn_w">
                  <h6>目标导向</h6>
                  <span></span>
                  <p>
                    通过目标导向型学习方法，让学员在课中明确学习目标，最短时间学到最有用的知识；教学系统支持诊断纠错、成果测试，这样可以在发现错误之处后进行及时的补救。
                  </p>
                  <img src="@/assets/image/enter/mbdx.png" alt="" srcset="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 四师体系 -->
    <div class="stx pb-80">
      <div class="cont">
        <div class="gg_title">
          <div class="gg_t">
            <h4>四师体系</h4>
            <div class="gg_text">助力学员扎实掌握职业技能</div>
          </div>
          <!-- <img src="@/assets/image/enter/sycedu.png" alt="" /> -->
        </div>
        <div class="sstx">
          <div class="sstx_l">
            <img src="@/assets/image/enter/ss_t.png" alt="" srcset="" />
            <img src="@/assets/image/enter/ss_bt.png" alt="" srcset="" />
          </div>
          <div class="sstx_r">
            <li>
              <h5>规划学习方向</h5>
              <em></em>
              <p>
                首先<span>班主任老师</span>会根据不同情况的学员，为学员规划学习方向；其次<span>学管老师</span>制定学习周期和学习计划。
              </p>
            </li>
            <li>
              <h5>点对点教学</h5>
              <em></em>
              <p>
                <span>授课老师</span>在线点对点互动教学，<span>助教老师</span>课后进行疑问答疑，当堂的问题当堂解决，不留到第二天。
              </p>
            </li>
            <li>
              <h5>与时俱进</h5>
              <em></em>
              <p>
                独创四师体系与智能教务系统，与时俱进不断的优化更新迭代教学服务，让我们的每一位学员都拥有独当一面的职业技能。
              </p>
            </li>
          </div>
        </div>
      </div>
    </div>
    <!-- 师资力量雄厚 -->
    <div class="szll pb-80">
      <div class="cont">
        <div class="gg_title pt-80">
          <div class="gg_t">
            <h4>师资力量雄厚</h4>
            <div class="gg_text">成熟的教师筛选体系+教学培训制度</div>
          </div>
          <!-- <img src="@/assets/image/enter/sycedu.png" alt="" /> -->
        </div>
        <div class="szll_con">
          <div class="sz_c_t">
            <ul>
              <li>
                <img src="@/assets/image/enter/zm.png" alt="" srcset="" />
              </li>
              <li>
                <img src="@/assets/image/enter/xx.png" alt="" srcset="" />
              </li>
              <li>
                <img src="@/assets/image/enter/dy.png" alt="" srcset="" />
              </li>
              <li>
                <img src="@/assets/image/enter/xuan.png" alt="" srcset="" />
              </li>
              <li>
                <img src="@/assets/image/enter/yc.png" alt="" srcset="" />
              </li>
            </ul>
          </div>
          <div class="sz_c_b">
            <div class="sz_b_l">
              <img src="@/assets/image/enter/team.png" alt="" srcset="" />
            </div>
            <div class="sz_b_r">
              <ul>
                <li>
                  <h5>专业的讲师</h5>
                  <em></em>
                  <p>
                    搭建了由专业的讲师、助教、学管师共同组建的高素质教学服务团队，讲师均为高校科班硕士本科毕业，拥有丰富的线上授课经验和高水准的专业技能。
                  </p>
                  <b>A</b>
                </li>
                <li>
                  <h5>教学水平高</h5>
                  <em></em>
                  <p>
                    百余人的教学团队，丰富的实战经验、超高的教学水平，使学员的职能晋升、专业能力的进步，通过完善的培训体系，达到高精专层次。
                  </p>
                  <b>B</b>
                </li>
                <li>
                  <h5>教师筛选体系</h5>
                  <em></em>
                  <p>
                    教师团队有十分严格的把控标准，从人才筛选、技术考核、定制个人培训方案、教育心理学培训、教育方法培训，到模拟教学培训考核、培训结果验收等都有严格把关。
                  </p>
                  <b>C</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 三年复训 -->
    <!-- <div class="stx pb-80">
      <div class="cont"> -->
        <!-- <div class="gg_title pt-80"> -->
          <!-- <div class="gg_t">
            <h4>三年复训</h4>
            <div class="gg_text">三年内可申请从头复习，免费享受所有服务</div>
          </div> -->
          <!-- <img src="@/assets/image/enter/sycedu.png" alt="" /> -->
        <!-- </div> -->
        <!-- <div class="sn_con">
          <div class="sn_con_t">
            <img src="@/assets/image/enter/sn_fs1.png" alt="" srcset="" />
            <img src="@/assets/image/enter/sn_fs2.png" alt="" srcset="" />
          </div>
          <div class="sn_con_b">
            <ul>
              <li>
                <h5>课程直播</h5>
                <p>当天课程直播，在线学习有问题即问即答。</p>
              </li>
              <li>
                <h5>课程回放</h5>
                <p>当天授课全程录像，可以反复看回放学习。</p>
              </li>
              <li>
                <h5>辅助教学</h5>
                <p>系统为学员提供辅助教学，巩固课后知识。</p>
              </li>
            </ul>
          </div>
        </div> -->
      <!-- </div>
    </div> -->
    <!-- 十年进修计划 -->
    <!-- <div class="ten pb-80">
      <div class="cont"> -->
        <!-- <div class="gg_title pt-80">
          <div class="gg_t">
            <h4>十年进修计划</h4>
            <div class="gg_text">毕业后十年内享受免费技术支持</div>
          </div> -->
          <!-- <img src="@/assets/image/enter/sycedu.png" alt="" /> -->
        <!-- </div> -->
        <!-- <div class="ten_b">
          <div class="sn_con_t">
            <img src="@/assets/image/enter/ten1.png" alt="" srcset="" />
            <img src="@/assets/image/enter/ten2.png" alt="" srcset="" />
          </div>
          <div class="ten_con_b">
            <ul>
              <li>
                <div class="t_top">
                  <h3>技术支持</h3>
                  <span>01</span>
                  <p>
                    毕业后十年内工作中遇到所有技术类的难点，都可以向我们精研学堂教育学院寻求技术支持。
                  </p>
                </div>
              </li>
              <li>
                <div class="t_top">
                  <h3>答疑解惑</h3>
                  <span>02</span>
                  <p>
                    享受毕业学员一样的答疑服务及时处理你的疑难问题。
                  </p>
                </div>
              </li>
              <li>
                <div class="t_top">
                  <h3>就业创业辅助</h3>
                  <span>03</span>
                  <p>
                    就业创业过程中遇到技能对接问题，精研学堂教育学院提供无条件帮助。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
      <!-- </div>
    </div> -->
    <!-- 企业文化 -->
    <!-- <div class="zyjn">
      <div class="title">
        <h2><span>企业</span>文化</h2>
        <img style="left: 50%; margin-left: -80px; top: -50px" src="../../assets/image/title_bg.png" alt="" />
        <p>宗旨、使命、愿景</p>
      </div>
      <div class="qy_content">
        <ul>
          <li>
            <img src="../../assets/image/zjyc/zz.png" alt="" srcset="" />
            <div class="qy_text">
              <h4>企业宗旨</h4>
              <p>持续为社会培养 <br />和输送职业技能型人才</p>
            </div>
          </li>
          <li>
            <img src="../../assets/image/zjyc/sm.png" style="top: -25px" alt="" srcset="" />
            <div class="qy_text" style="transform: translateY(-35px)">
              <h4>企业使命</h4>
              <p>让我们的每一位学员<br />拥有独当一面的职业<br />技能</p>
            </div>
          </li>
          <li>
            <img src="../../assets/image/zjyc/yj.png" alt="" srcset="" />
            <div class="qy_text">
              <h4>企业愿景</h4>
              <p>牢记职业教育初心<br />打造百年教育品牌</p>
            </div>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- 联系我们 -->
    <!-- <div class="lianxiwomen">
      <div class="bottomBox">
        <img style="width: 100%; height: 375px" src="../../assets/image/bottom.jpg" alt="" />
      </div>
      <div class="meihaoBox">
        <div class="title">
          <img v-if="false" src="../../assets/image/svgImg/logo_top.svg" style="width: 185px; left: 220px; top: -60px"
            alt="" srcset="" />
          <h2><span>一切只为</span>好学的你</h2>
          <img style="right: 250px" src="../../assets/image/title_bg.png" alt="" />
          <div>
            <p style="font-size: 24px">
              近 千 万 用 户 正 在 通 过 远 程 教 育 学 习
            </p>
          </div>
        </div>

        <div style="
            width: 191px;
            height: 69px;

            box-shadow: 0px 7px 13px 0px rgba(0, 33, 121, 0.18);
            border-radius: 35px;
            font-size: 26px;
            line-height: 69px;
            margin: 0 auto;
            margin-top: 60px;
          " class="b_contact" @click="dialogVisible = true">
          联系我们
        </div>
      </div>
    </div> -->
    <!-- 弹窗：手机号 -->
    <div class="jiaru">
      <el-dialog title="" :visible.sync="dialogVisible" width="856px" center :show-close="false" class="my-info-dialog">
        <div style="
            width: 857px;
            height: 567px;
            position: relative;
            top: 0;
            left: 50%;
            margin-left: -428px;
          ">
          <img style="width: 100%; height: 100%" src="https://juntongoss.oss-cn-beijing.aliyuncs.com/data/jingrui%402x.png" />
          <div style="
              position: absolute;
              left: 50%;
              right: 0;
              top: 100px;
              text-align: center;
              z-index: 999;
            ">
            <div style="
                color: #0d1e3d;
                font-size: 32px;
                font-weight: bold;
                padding-bottom: 24px;
              ">
              官方联系电话
            </div>
            <div style="
                color: #0d1e3d;
                font-size: 44px;
                font-weight: bold;
                padding-bottom: 46px;
              ">
              <span style="color: #074DFE">
                <!-- 400-0872229 -->
              </span>
            </div>
            <!-- 在线客服-微信扫一扫 -->
            <!-- <div class="weixinBox">
              <img class="weixin" :src="ossUrl + 'kefu_erweima.png'" />
              <div class="weixintext">在线客服-微信扫一扫</div>
            </div> -->
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Bus from "../../assets/js/Bus.js";
export default {
  name: "enter",
  data() {
    return {
      cplay: require("../../assets/image/play.png"),
      dialogVisible: false,
      dialogVisibles: false,
      ossUrl: process.env.VUE_APP_OSS_URL,
      mouseActive: null,
      c1: "https://devstatic.yc-edu.online/api/upload/crm/2022/03/16/RnBGOPGh7dab4Cfdo1KbONd7BqNoqHM5BvAF3Qbq.png",
      c2: "https://devstatic.yc-edu.online/api/upload/crm/2022/03/16/mVaPDgujrtJd8icdu4Nto8ORJVCr55LqXmerzhb7.png",
      form: {
        kechengOption: [],
        kecheng: "",
        tel: "",
        isCheck: false,
        next: false,
        code: "",
        send: "获取验证码",
        timer: null,
        closeUrl: process.env.VUE_APP_OSS_URL + "close.png",
      },
      laoshiImg: process.env.VUE_APP_OSS_URL + "laoshi.png",
      currentHref: "",
      OSS_URL_YC: process.env.VUE_APP_OSS_URL_YC,
    };
  },
  created() {
    this.currentHref = window.location.href;
    this.getData();
    Bus.$on("open_sus", () => {
      // console.log('open_sus接收到命令')
      this.dialogVisible = true;
    });
  },
  methods: {
    c_play() {
      this.cplay = require("../../assets/image/play_h.png");
    },
    cplay_h() {
      this.cplay = require("../../assets/image/play.png");
    },
    mouseoverFun(index) {
      this.mouseActive = index;
    },
    mouseleave(index) {
      this.mouseActive = null;
    },
    closeDialog() {
      this.$refs.video.pause();
    },
    updateTime(e) {
      this.currentTime = e.target.currentTime;
    },
    getData() {
      //获取课程下拉列表
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/course_selection_package_list",
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
            this.form.kechengOption = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    go_next() {
      // console.log('课程：' + this.form.kecheng + '手机号:' + this.form.tel)
      if (!this.form.kecheng) {
        return this.$message({
          message: "请选择课程",
          type: "error",
          offset: "100",
        });
      }
      if (!this.form.tel) {
        return this.$message({
          message: "请输入手机号",
          type: "error",
          offset: "100",
        });
        // return this.$message.error('请输入手机号')
      } else if (this.form.tel.length != 11) {
        return this.$message({
          message: "请输入正确的手机号",
          type: "error",
          offset: "100",
        });
        // return this.$message.error('请输入正确的手机号')
      }
      this.getCode();
      this.form.next = true;
    },
    getCode() {
      // return;
      let time = 60;
      if (this.form.timer) return;

      this.form.timer = setInterval(() => {
        if (time > 0) {
          time--;
          this.form.send = time;
        } else {
          clearInterval(this.form.timer);
          this.form.timer = null;
          this.form.send = "获取验证码";
        }
      }, 1000);

      this.$axios({
        method: "POST",
        url: "/api/index/send_code",
        data: {
          tel: this.form.tel,
          type: 1,
        },
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              offset: "100",
            });
            // this.$message.error(res.msg)
            setTimeout(() => {
              clearInterval(this.form.timer);
              this.form.timer = null;
              this.form.send = "获取验证码";
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      if (!this.form.code) {
        return this.$message({
          message: "请输入验证码",
          type: "error",
          offset: "100",
        });
        // return this.$message.error('请输入验证码')
      }
      this.$axios({
        method: "POST",
        url: "/api/index/submit_mobile",
        data: {
          telphone: this.form.tel,
          coursebase_id: this.form.kecheng,
          code: this.form.code,
        },
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
            this.dialogVisible = false;
            this.$notify({
              title: "成功",
              message: res.msg || res.message,
              type: "success",
              position: "top-left",
            });
          } else {
            this.$notify.error({
              title: "提示",
              message: res.msg || res.message,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.meihaoBox {
  width: 919px;
  height: 422px;
  background-image: linear-gradient(120deg, #cfdcff, #fbfdff, #fcfdff);
  box-shadow: 0px 11px 9px 0px rgba(0, 84, 141, 0.09),
    -3px -23px 40px 0px rgba(0, 35, 68, 0.07);
  border-radius: 146px 0px 146px 0px;
  position: absolute;
  top: -110px;
  box-sizing: border-box;
  padding-top: 40px;
}

.lcbg {
  display: flex;
  width: 1375px;
  height: 670px;
  background: url(../../assets/image/lcb_content.png);

  position: relative;
}

.lcbg li {
  position: absolute;
  font-family: ArialMT;
  text-align: center;
}

.lcbg li p {
  line-height: 30px;
  text-align: left;
}

.lcbg li span {
  text-align: center;
  font-size: 22px;
  display: inline-block;
}

.lcbg li:nth-child(1) {
  left: 68px;
  top: 388px;
  text-align: center;
}

.lcbg li:nth-child(1) p {
  text-align: center;
}

.lcbg li:nth-child(2) {
  left: 187px;
  top: 145px;
}

.lcbg li:nth-child(2) span {
  margin-top: 110px;
}

.lcbg li:nth-child(2n) p {
  color: #fff;
}

.lcbg li:nth-child(3) {
  top: 290px;
  left: 355px;
}

.lcbg li:nth-child(3) span {
  margin-bottom: 50px;
}

.lcbg li:nth-child(4) {
  left: 520px;
  top: 90px;
}

.lcbg li:nth-child(4) span {
  margin-top: 80px;
}

.lcbg li:nth-child(5) {
  left: 710px;
  top: 135px;
}

.lcbg li:nth-child(5) p {
  color: #fff;
}

.lcbg li:nth-child(5) span {
  margin-bottom: 90px;
}

.lcbg li:nth-child(6) {
  left: 912px;
  top: 20px;
}

.lcbg li:nth-child(6) p {
  color: #000;
}

.lcbg li:nth-child(6) span {
  margin-top: 70px;
}

.lcbg li:nth-child(7) {
  left: 1075px;
  top: 230px;
}

.lcbg li:nth-child(7) h4 {
  font-size: 26px;
  margin-bottom: 60px;
}

.lcbg li:nth-child(7) p {
  color: #fff;
}

.lcbg p {
  font-size: 16px;
}

/* 公共样式 */
.gcontent {
  padding-top: 60px;
}

.title {
  text-align: center;
  position: relative;
  margin-top: 80px;
}

.title img {
  position: absolute;
  top: -60px;
  z-index: 0;
  right: 60px;
}

.title h2 {
  font-size: 50px;
  font-weight: 900;
  font-style: italic;
  /* transform: skewX(350deg); */
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
}

.title h2 span {
  color: #275ae7;
}

.title p {
  color: #585858;
  line-height: 26px;
  position: relative;
  font-size: 17px;
  z-index: 1;
}

/* 品牌内容 */
.content {
  margin-top: 50px;
  background: #efefef;
  width: 1200px;
  display: flex;
  height: 700px;
}

.content .c_right {
  padding: 70px 0;
  transform: translate(-30px, 20px);
}

.content .c_bg {
  padding: 50px;
  background-image: linear-gradient(120deg, #fff, #fff, #fffecf);
  border-radius: 45px 0 45px 0;
  height: 260px;
  padding-right: 85px;
  padding-left: 72px;
  margin-bottom: 25px;
  padding-bottom: 70px;
}

.content .c_bg:nth-child(2) {
  background: #fff;
}

.content .c_bg img {
  display: inline-block;
  transform: translate(190px, -75px);
}

.content h4,
.con_bg h4,
.jxsl_bg h4 {
  font-size: 20px;
  font-weight: 600;
  color: #525252;
}

.content p,
.con_bg p,
.jxsl_bg p {
  color: #666;
  line-height: 25px;
  font-size: 16px;
  margin-top: 10px;
}

.content_bm {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin-top: 95px;
  margin-bottom: 160px;
}

.content_bm li {}

.content_bm li:nth-child(1) img {
  /* width: 100%; */
}

.content_bm .con_bg {
  padding: 30px 38px;
  background-image: linear-gradient(120deg, #d2ffda, #fff, #fff);
  border-radius: 45px 0 45px 0;
  height: 195px;
  width: 685px;
  box-shadow: #f4f4f4 1px 1px 9px 1px;
  transform: translate(175px, -55px);
  box-sizing: border-box;
  padding-top: 45px;
}

.content_bm li:nth-child(2) .con_bg {
  background: #fff;
}

.content_bm .c_bg_pic {
  display: flex;
}

.content_bm .c_bg_pic img {
  /* height: 411px; */
  padding-left: 18px;
}

.con_bg {
  position: relative;
}

.con_bg img {
  position: absolute;
  z-index: -1;
  right: 50px;
  top: 80px;
}

.jxsl_bg {
  padding: 35px 55px;
  border-radius: 45px 0 45px 0;
  height: 208px;
  width: 600px;
  background: #fff;
  box-shadow: #f4f4f4 1px 1px 9px 1px;
  transform: translate(15px, -290px);
  box-sizing: border-box;
}

/* 千校工程 */
.gcon {
  width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
  margin-top: 30px;
  position: relative;
  padding-bottom: 80px;
}

.gcon .gcon_t {
  height: 200px;
}

.gcon .gcon_t h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.gcon .gcon_t p {
  color: #515151;
  font-size: 18px;
  line-height: 28px;
}

.gcon .gcon_t .gc_l {
  width: 750px;
  position: absolute;
  left: 125px;
  z-index: 1;
}

.gcon .gcon_t .gc_r {
  width: 460px;
  top: -160px;
  position: absolute;
  right: 260px;
}

.gcon_b_p {
  margin-left: 105px;
}

.gcon_b_p img {
  margin-left: 20px;
  width: 34%;
}

.gcon_b_t {
  background: #fff;
  width: 400px;
  padding: 47px 41px;
  line-height: 25px;
  font-size: 16px;
  border-radius: 0 0 35px;
  color: #5c5c5c;
}

.zyjn {
  background: #fafafa;
  padding-top: 50px;
}

.zyjn .gcon_b_t {
  height: 200px;
  position: relative;
  top: 40px;
}

.zyjn .gcon_b_p {
  display: flex;
}

/* 平台优势 */
.pt_content {
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}

.pt_content ul {
  display: flex;
  padding-left: 50px;
}

.pt_content ul li {
  width: 25%;
}

.pt_content ul li .pt_tbox {
  width: 260px;
  height: 200px;
  background-image: linear-gradient(120deg, #b5c8ff, #dbe4ff, #eef2ff);
  border-radius: 50px 0 0;
  box-sizing: border-box;
}

.pt_content ul li:nth-child(2n) .pt_tbox {
  background-image: linear-gradient(120deg, #57e9a5, #a9f8d4, #ccffe7);
}

.pt_content ul li .pt_tbox h3 {
  font-size: 43px;
  padding-top: 32px;
  padding-left: 20px;
  line-height: 47px;
}

.pt_content ul li .pt_tbox h3 p {
  font-size: 33px;
  font-weight: 300;
}

.pt_content ul li .pt_tbox span {
  float: right;
  line-height: 110px;
  height: 34px;
  font-size: 46px;
  font-family: Arial;
  font-weight: 900;
  color: #d0ddff;
  opacity: 0.8;
}

.pt_content ul li:nth-child(2n) .pt_tbox span {
  opacity: 0.6;
  color: #91f2c5;
}

.pt_content ul li .pt_bbox {
  width: 260px;
  height: 305px;
  background: #ffffff;
  box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.11);
  border-radius: 0px 0px 50px 0px;
  box-sizing: border-box;
  padding: 15px;
}

.pt_content ul li .pt_bbox p {
  width: 179px;
  height: 166px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #606060;
  line-height: 25px;
  margin: 0 auto;
}

/* 企业文化 */
.qy_content {
  width: 1200px;
  margin: 0 auto;
  height: 680px;
}

.qy_content ul {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  width: 950px;
  margin: 0 auto;
}

.qy_content ul li {
  text-align: center;
}

.qy_content ul li img {
  position: relative;
  z-index: 2;
}

.qy_content ul li .qy_text {
  background: #fff;
  text-align: left;
  height: 220px;
  width: 250px;
  border-radius: 50px 0 50px 0;
  padding: 50px 34px;
  box-shadow: #f1f1f1 -1px 3px 17px 1px;
  position: relative;
  top: -50px;
  padding-top: 60px;
}

.qy_content ul li .qy_text h4 {
  font-size: 22px;
  font-weight: 600;
  color: #575757;
  margin-bottom: 10px;
}

.qy_content ul li .qy_text p {
  color: #575757;
  font-size: 18px;
  line-height: 30px;
}

.b_contact {
  width: 120px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  background: linear-gradient(to right, #3c71ff, #749aff);
  border-radius: 36px;
  color: #fff;
  cursor: pointer;
}

.b_contact:hover {
  background: #1956f9;
}

div.jiaru .el-dialog {
  height: 0 !important;
}

.jiarus>>>.el-dialog {
  display: flex;
  justify-content: center;
}

.jiarus>>>.el-dialog--center .el-dialog__body {
  padding: 0;
}

.jiarus>>>.el-dialog__body {
  padding: 0;
}

.jiarus>>>.el-dialog__header {
  padding: 0;
}

div.jiaru .el-dialog .el-input {
  width: 346px;
  height: 60px;
  border-radius: 30px;
  background-color: #f5f6f6;
  /* padding: 2px 10px !important; */
  color: #333333 !important;
  font-size: 16px !important;
}

div.jiaru .el-dialog .el-input__inner {
  /* border: 1px solid #FFFFFF !important;
		border-bottom: 1px solid #E2E2E2 !important;
		padding: 0 !important; */
  margin-top: 10px;
  background-color: #f5f6f6;
  border: 1px solid #f5f6f6 !important;
  width: 98%;
}

.my-info-dialog .el-dialog,
.el-pager li {
  background: none;
}

.my-info-dialog .el-dialog {
  box-shadow: none;
}

.weixinBox {
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.weixin {
  width: 100%;
}

.weixintext {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  margin-top: 6px;
}

.lianxiwomen {
  margin-top: 0px;
  width: 100%;
  height: auto;
  background-color: #f3f3f3;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.lianxiwomenBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.lianxiwomenBox img {
  width: 200px;
  margin: 30px auto;
  position: absolute;
  top: 33%;
  z-index: 10;
  cursor: pointer;
}

.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.videoBox {
  width: 924px;
  margin-top: 0px;
  position: relative;
  border-radius: 10px;
  height: 100%;
}

.videoMp {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
  cursor: pointer;
}

.zn_bg {
  padding-bottom: 80px;
}

.cont {
  width: 1313px;
  margin: 0 auto;
  padding: 0 20px;
}

.zn_bg .zn_tit {
  margin-bottom: 100px;
}

.zn_bg .zn_tit h6 {
  width: 955px;
  margin: 0 auto;
  /* background: url(../../assets/image/enter/ycedu.png) no-repeat; */
  height: 152px;
  background-size: 90%;
  background-position: center -5px;
  padding-left: 20px;
}

.zn_bg .zn_tit img {
  width: 879px;
  display: block;
  margin: 0 auto;
  margin-top: -155px;
}

.zn_bg .zn_tit h6 span {
  font-size: 30px;
  color: #3c71ff;
  padding-right: 85px;
  text-align: center;
  line-height: 152px;
  font-family: PingFang SC;
}

.zn_bg .zn_tit h6 span:last-child {
  padding-right: 0;
}

.gg_title h4 {
  font-size: 42px;
  font-weight: bold;
  font-family: PingFang SC;
}

.gg_title .gg_t {
  display: flex;
  align-items: center;
}

.gg_title .gg_t .gg_text {
  font-size: 20px;
  height: 57px;
  line-height: 57px;
  border: 2px #acacac30 solid;
  text-align: center;
  border-radius: 27px;
  color: #3d3d3d;
  margin-left: 30px;
  padding: 0 15px;
}

.gg_title img {
  position: relative;
  z-index: 0;
  top: -15px;
  opacity: 0.7;
}

.zn_con {
  width: 1246px;
  margin-top: 20px;
  background: #fff;
}

.zn_con_b ul {
  padding: 40px 30px;
  display: flex;
  box-shadow: #eae9e9 0px 17px 20px;
}

.zn_con_b ul li {
  width: 33.33%;
  position: relative;
  padding: 0 50px;
}

.zn_con_b ul li::after {
  height: 185px;
  background: #e7e7e7;
  width: 1px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}

.zn_con_b ul li:last-child::after {
  width: 0;
}

.zn_con_b ul li h6 {
  font-size: 22px;
  position: relative;
  z-index: 1;
}

.zn_con_b ul li p {
  color: #6e6e6e;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.zn_con_b ul li span {
  display: block;
  content: "";
  width: 110px;
  height: 10px;
  background: linear-gradient(90deg, #2a63ef, #2a63ef, #2a63ef, transparent);
  transform: translate(-5px, -10px);
  position: relative;
  z-index: 0;
}

.zn_con_b ul li:nth-child(2) span {
  background: linear-gradient(90deg, #f6d000, #f6d000, #f6d000, transparent);
}

.zn_con_b ul li:nth-child(3) span {
  background: linear-gradient(90deg, #48e5a1, #48e5a1, #48e5a1, transparent);
}

.stx {
  background: #f8f8f8;
}

.stx .gg_title {
  padding-top: 80px;
}

.stx .sstx {
  width: 1246px;
  height: 618px;
  margin: 0 auto;
  background: linear-gradient(151deg, #fff, #fff, #fff, #fff, #fff, #fff5bd);
  margin-top: 25px;
  padding: 50px 140px;
  display: flex;
  box-shadow: #d8d8d8 0px 11px 20px;
}

.stx .sstx .sstx_l img {
  display: block;
  margin-top: 20px;
}

.stx .sstx .sstx_r li h5 {
  font-size: 22px;
  position: relative;
  z-index: 1;
}

.stx .sstx .sstx_r li em {
  display: block;
  content: "";
  width: 128px;
  height: 10px;
  background: linear-gradient(90deg, #2a63ef, #2a63ef, transparent);
  transform: translate(-5px, -10px);
  position: relative;
  z-index: 0;
}

.stx .sstx .sstx_r li:first-child em {
  width: 163px;
}

.stx .sstx .sstx_r {
  width: 387px;
  margin-left: 80px;
}

.stx .sstx .sstx_r li {
  margin-top: 60px;
}

.stx .sstx .sstx_r p {
  color: #8c8c8c;
  font-size: 13px;
  line-height: 25px;
}

.stx .sstx .sstx_r p span {
  color: #2e63ef;
}

.stx .sstx .sstx_r li:nth-child(2) em {
  background: linear-gradient(90deg, #48e5a1, #48e5a1, transparent);
}

.stx .sstx .sstx_r li:nth-child(3) em {
  background: linear-gradient(90deg, #f6d000, #f6d000, transparent);
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.szll .szll_con {
  width: 1257px;
  height: 735px;
  border-radius: 27px;
  background: #f5f5f5;
  margin-top: 50px;
  box-shadow: #d8d8d8 0px 11px 20px;
  padding: 50px 60px;
}

.szll .szll_con .sz_c_t ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;
}

.szll .szll_con .sz_c_b {
  display: flex;
  justify-content: space-between;
}

.szll .szll_con .sz_b_r ul {
  display: flex;
  margin-top: 50px;
}

.szll .szll_con .sz_b_r li {
  width: 133px;
  margin-right: 50px;
}

.szll .szll_con .sz_b_r li:last-child {
  margin-right: 0;
}

.szll .szll_con .sz_b_r li h5 {
  font-size: 22px;
  position: relative;
  z-index: 1;
}

.szll .szll_con .sz_b_r li em {
  display: block;
  content: "";
  width: 110px;
  height: 10px;
  background: linear-gradient(90deg, #48e5a1, #48e5a1, #48e5a1, transparent);
  transform: translate(-5px, -10px);
  position: relative;
  z-index: 0;
}

.szll .szll_con .sz_b_r li p {
  font-size: 13px;
  color: #7d7d7d;
  line-height: 25px;
  position: relative;
  z-index: 1;
}

.szll .szll_con .sz_b_r li b {
  font-weight: 900;
  font-size: 96px;
  color: #e6e6e6;
  top: -90px;
  left: 50px;
  position: relative;
}

.szll .szll_con .sz_b_r li:nth-child(2) b {
  top: -63px;
}

.szll .szll_con .sz_b_r li:nth-child(3) b {
  top: -115px;
}

.sn_con {
  width: 1246px;
  height: 658px;
  background: #f6f6f6;
  box-shadow: #d8d8d8 0px 11px 20px;
  margin-top: 30px;
  padding: 50px 100px;
}

.sn_con .sn_con_t {
  display: flex;
  justify-content: space-between;
}

.sn_con ul {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.sn_con ul li {
  width: 316px;
  height: 145px;
  border-radius: 20px;
  background: #2e63ef;
  color: #fff;
  padding: 40px 30px;
}

.sn_con ul li:nth-child(2) {
  background: #48e5a1;
  color: #000;
}

.sn_con ul li:nth-child(3) {
  background: #f8de00;
  color: #000;
}

.sn_con ul li h5 {
  font-size: 22px;
}

.sn_con ul li p {
  font-size: 13px;
  margin-top: 10px;
}

.ten .ten_b {
  width: 1246px;
  height: 658px;
  background: #fff;
  box-shadow: #d8d8d8 0px 11px 20px;
  margin-top: 30px;
  padding: 65px 123px;
}

.ten .ten_b .sn_con_t {
  display: flex;
  justify-content: center;
}

.ten .ten_b .t_top h3 {
  font-size: 22px;
  background: linear-gradient(90deg, #84efc1, #84efc1ad, transparent) no-repeat;
  width: max-content;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.ten .ten_b .t_top span {
  font-size: 108px;
  font-weight: 900;
  color: #efeff0;
  position: relative;
  z-index: 0;
  margin-top: -100px;
  display: inline-block;
}

.ten .ten_con_b {
  margin-top: 80px;
}

.ten .ten_con_b ul {
  display: flex;
  justify-content: space-between;
}

.ten .ten_con_b li {
  width: 280px;
}

.ten .ten_con_b li:nth-child(3) h3 {
  background-size: 70%;
}

.ten .ten_con_b li p {
  font-size: 14px;
  color: #8c8c8c;
  line-height: 28px;
  margin-top: -20px;
}</style>
