<template>
  <div class="" ref="bbb" id="ccc">
    <div
      id="aaa"
      style="width: 100%; height: 918px; border: 0; padding: 0; margin: 0"
    >
      <img
        style="width: 100%; height: auto"
        src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi1.jpg"
      />
    </div>
    <div style="width: 100%; height: 772px; margin: 0 auto">
      <div
        style="
          color: #333333;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          padding-top: 101px;
        "
      >
        影视行业大热，抓住时机就现在
      </div>
      <div
        style="
          color: #999999;
          font-size: 20px;
          font-weight: 400;
          text-align: center;
          padding-top: 17px;
        "
      >
        目前影视后期行业薪资水平比肩金融行业
      </div>
      <div
        style="
          width: 66%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          padding-top: 96px;
        "
      >
        <div
          style="
            width: 250px;
            height: 322px;
            box-shadow: 3px 0 29px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            background-color: #ffffff;
          "
        >
          <div
            style="
              width: 143px;
              height: 143px;
              margin: 0 auto;
              padding-top: 40px;
            "
          >
            <img
              style="width: 100%; height: auto"
              src="../../assets/jianzhizhongxin/jiuyeqingsong.png"
            />
          </div>
          <div
            style="
              color: #333333;
              font-size: 20px;
              font-weight: bold;
              padding-bottom: 39px;
              padding-top: 20px;
              text-align: center;
            "
          >
            就业轻松
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 20px;
            "
          >
            低门槛，高收入，掌握“薪”技术
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 40px;
            "
          >
            就业无压力
          </div>
        </div>
        <div
          style="
            width: 250px;
            height: 322px;
            box-shadow: 3px 0 29px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            background-color: #ffffff;
          "
        >
          <div
            style="
              width: 143px;
              height: 143px;
              margin: 0 auto;
              padding-top: 40px;
            "
          >
            <img
              style="width: 100%; height: auto"
              src="../../assets/jianzhizhongxin/xinzi.png"
            />
          </div>
          <div
            style="
              color: #333333;
              font-size: 20px;
              font-weight: bold;
              padding-bottom: 39px;
              padding-top: 20px;
              text-align: center;
            "
          >
            平均薪资高
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 20px;
            "
          >
            低行业人才缺口大，平均月薪资可以
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 40px;
            "
          >
            达到12000元+
          </div>
        </div>
        <div
          style="
            width: 250px;
            height: 322px;
            box-shadow: 3px 0 29px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            background-color: #ffffff;
          "
        >
          <div
            style="
              width: 143px;
              height: 143px;
              margin: 0 auto;
              padding-top: 40px;
            "
          >
            <img
              style="width: 100%; height: auto"
              src="../../assets/jianzhizhongxin/ziyouxuanze.png"
            />
          </div>
          <div
            style="
              color: #333333;
              font-size: 20px;
              font-weight: bold;
              padding-bottom: 39px;
              padding-top: 20px;
              text-align: center;
            "
          >
            自由选择
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 20px;
            "
          >
            “钱途”无量，
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 40px;
            "
          >
            主业全职与副业兼职自由选择
          </div>
        </div>
        <div
          style="
            width: 250px;
            height: 322px;
            box-shadow: 3px 0 29px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            background-color: #ffffff;
          "
        >
          <div
            style="
              width: 143px;
              height: 143px;
              margin: 0 auto;
              padding-top: 40px;
            "
          >
            <img
              style="width: 100%; height: auto"
              src="../../assets/jianzhizhongxin/congye.png"
            />
          </div>
          <div
            style="
              color: #333333;
              font-size: 20px;
              font-weight: bold;
              padding-bottom: 39px;
              padding-top: 20px;
              text-align: center;
            "
          >
            从业方向广
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 20px;
            "
          >
            短视频，广告创意，影视后期制作
          </div>
          <div
            style="
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              padding-bottom: 40px;
            "
          >
            教育培训等多领域
          </div>
        </div>
      </div>
    </div>

    <div style="position: relative">
      <div style="width: 100%; height: auto">
        <img
          style="width: 100%; height: auto"
          src="../../assets/jianzhizhongxin/gaoxin_bg.png"
        />
      </div>
      <div
        style="
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div
          style="
            color: #ffffff;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            padding-bottom: 16px;
          "
        >
          高薪工作不是梦
        </div>
        <div
          style="
            color: #ffffff;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 3px;
            text-align: center;
            padding-bottom: 92px;
          "
        >
          学好影视后期增加涨薪筹码
        </div>
        <div style="width: 1059px; height: 525px">
          <img
            style="width: 100%; height: auto"
            src="../../assets/jianzhizhongxin/gaoxin.png"
          />
        </div>
      </div>
    </div>

    <div style="width: 100%; margin: 0 auto; padding-top: 70px;background-color: #fff;">
      <div
        @click="open_sus"
        style="
          width: 277px;
          height: 66px;
          background-color: #fc661c;
          border-radius: 33px;
          text-align: center;
          color: #ffffff;
          font-size: 20px;
          line-height: 66px;
          margin: 0 auto;
		  cursor: pointer;
        "
      >
        立即报名
      </div>
	  <div style="background-color: #fff;">
	  	
	 
		  <div class="jiangshi_zhuanye_title">学影视后期，跟谁学最专业？</div>
		  <div class="jiangshi_zhuanye_title2">著名讲师带你走进影视后期的世界</div>
		  <div style="position: relative; padding-top: 129px">
			<div style="width: 1300px; margin: 0 auto">
			  <img style="width: 100%; height: auto" src="../../assets/image/teacher.png"/>
			</div>
			<div class="jiangshi_zhuanye_box">
			  <div class="jiangshi_zhuanye_box_name">暮暮老师</div>
			  <div class="jiangshi_zhuanye_box_jiehsao">
				<img class="jiangshi_zhuanye_box_jiehsao_img" src="../../assets/jianzhizhongxin/vip.png"/>
				<div style="color: #333333; font-size: 20px">Adobe认证讲师</div>
			  </div>
			  <div class="jiangshi_zhuanye_box_jiehsao">
			   <img class="jiangshi_zhuanye_box_jiehsao_img" src="../../assets/jianzhizhongxin/vip.png"/>
				<div style="color: #333333; font-size: 20px">工信部认证高级平面设计师</div>
			  </div>
			  <div class="jiangshi_zhuanye_box_jiehsao">
			   <img class="jiangshi_zhuanye_box_jiehsao_img" src="../../assets/jianzhizhongxin/vip.png"/>
				<div style="color: #333333; font-size: 20px">站酷特邀设计师</div>
			  </div>
			  <div class="jiangshi_zhuanye_box_jiehsao_content">
				毕业于华中师范大学，设计思维活跃、功底扎实，多次参与全国类设计大赛，具备非常丰富的实战经验，善于激发学生的创造性思维。在课堂上通过幽默风趣的讲课风格，积极与学生互动，让学生在愉悦的环境中收获知识。
			  </div>
			</div>
		  </div>
		</div>
    </div>

    <div id="jiangshizuopin">
      <div class="jiangshi_title"> 讲师作品</div>
      <div class="jiangshi_box">
        <div class="jiangshi_item" v-for="(item, index) in zuopinList_jiangshi" :key="index">
          <img class="jiangshi_img" :src="item"/>
        </div>
      </div>
    </div>

    <div
      id="kechengdagang"
      style="
        width: 100%;
        height: 2504px;
        background-color: #ffffff;
        margin: auto;
      "
    >
      <div
        style="
          color: #333333;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          padding-top: 70px;
          padding-bottom: 14px;
        "
      >
        课程大纲
      </div>
      <div
        style="
          color: #999999;
          font-size: 20px;
          font-weight: 400;
          text-align: center;
          padding-bottom: 97px;
        "
      >
        拒绝枯燥，用生动的案例让你快速上手操作
      </div>
      <div
        style="
          width: 66%;
          height: 101px;
          color: #666666;
          font-size: 20px;
          text-align: left;
          line-height: 40px;
          margin: 0 auto;
          padding-bottom: 104px;
        "
      >
        在新媒体发展迅速的今天，拥有影视后期技术简直就是黄金矿。随着数字电影、数字电视在全国的推广，数字影视制作人才特别是网络流媒体和手机电影等新媒介人才逐渐走俏，而且中国成为全球最大的数字影院市场。据数据调查显示：应届生在影视行业的平均薪资为4000-8000，远远高过其他行业。而在半年后平均薪资高达6000-10000。
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
          padding-top: 104px;
          padding-bottom: 30px;
        "
      >
        <div style="width: 60px; height: 61px">
          <img
            style="width: 100%; height: auto"
            src="../../assets/jianzhizhongxin/dagang1@2x.png"
          />
        </div>
        <div
          style="
            width: 100%;
            height: 59px;
            background-color: #f5f5f5;
            line-height: 60px;
            color: #333333;
            font-size: 26px;
            text-align: left;
            padding-left: 22px;
          "
        >
          第一阶段：基础课
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
        "
      >
        <div>
          <img src="../../assets/jianzhizhongxin/dagang_img1.png" />
        </div>
        <div
          style="
            width: 100%;
            height: 250px;
            background-color: #f5f5f5;
            color: #333333;
            font-size: 18px;
            text-align: left;
            padding-left: 43px;
            padding-top: 61px;
          "
        >
          <div style="padding-bottom: 26px">1.讲解pr界面布局和基础工作流程</div>
          <div class="">2.快速上手操作，掌握PS通用技能</div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
          padding-top: 38px;
          padding-bottom: 30px;
        "
      >
        <div style="width: 60px; height: 61px">
          <img
            style="width: 100%; height: auto"
            src="../../assets/jianzhizhongxin/dagang2@2x.png"
          />
        </div>
        <div
          style="
            width: 100%;
            height: 59px;
            background-color: #f5f5f5;
            line-height: 60px;
            color: #333333;
            font-size: 26px;
            text-align: left;
            padding-left: 22px;
          "
        >
          第二阶段：PS基础
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
        "
      >
        <div>
          <img src="../../assets/jianzhizhongxin/dagang_img2.png" />
        </div>
        <div
          style="
            width: 100%;
            height: 253px;
            background-color: #f5f5f5;
            color: #333333;
            font-size: 18px;
            text-align: left;
            padding-left: 43px;
            padding-top: 61px;
          "
        >
          <div style="padding-bottom: 26px">
            1.掌握ps基础工具及各类格式转换。
          </div>
          <div style="padding-bottom: 26px">
            2.了解通用工具的使用及底层原理。
          </div>
          <div style="padding-bottom: 26px">
            3.透明素材制作，图层混合模式、抠图、图层样式及色彩等等。
          </div>
          <div class="">4.熟练掌握通用知识，并为后期学习打下基础。</div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
          padding-top: 38px;
          padding-bottom: 30px;
        "
      >
        <div style="width: 60px; height: 61px">
          <img
            style="width: 100%; height: auto"
            src="../../assets/jianzhizhongxin/dagang3@2x.png"
          />
        </div>
        <div
          style="
            width: 100%;
            height: 59px;
            background-color: #f5f5f5;
            line-height: 60px;
            color: #333333;
            font-size: 26px;
            text-align: left;
            padding-left: 22px;
          "
        >
          第三阶段：PR基础
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
        "
      >
        <div>
          <img src="../../assets/jianzhizhongxin/dagang_img3.png" />
        </div>
        <div
          style="
            width: 100%;
            height: 253px;
            background-color: #f5f5f5;
            color: #333333;
            font-size: 18px;
            text-align: left;
            padding-left: 43px;
            padding-top: 61px;
            padding-right: 42px;
          "
        >
          <div style="padding-bottom: 26px">
            1.深入浅出讲解影视后期行业及各类工具术语，归纳总结Pr工作流程，快速巩固学习记忆。
          </div>
          <div style="padding-bottom: 26px">
            2.全方面了解各类剪辑工具及高级剪辑手法，掌握多机位剪辑，音频效果，调色等实用技能。
          </div>
          <div style="line-height: 36px">
            3.从剪辑理论入手提升剪辑思想，真正提高核心能力，并进行案例实操，最终帮助学员可以无缝衔接各类剪辑工作。
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
          padding-top: 38px;
          padding-bottom: 30px;
        "
      >
        <div style="width: 60px; height: 61px">
          <img
            style="width: 100%; height: auto"
            src="../../assets/jianzhizhongxin/dagang4@2x.png"
          />
        </div>
        <div
          style="
            width: 100%;
            height: 59px;
            background-color: #f5f5f5;
            line-height: 60px;
            color: #333333;
            font-size: 26px;
            text-align: left;
            padding-left: 22px;
          "
        >
          第四阶段：达芬奇
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
        "
      >
        <div>
          <img src="../../assets/jianzhizhongxin/dagang_img4.png" />
        </div>
        <div
          style="
            width: 100%;
            height: 253px;
            background-color: #f5f5f5;
            color: #333333;
            font-size: 18px;
            text-align: left;
            padding-left: 43px;
            padding-top: 61px;
          "
        >
          <div style="padding-bottom: 26px">
            1.从专业调色软件工具入手，达芬奇是新兴专业剪辑调色类工具。
          </div>
          <div style="padding-bottom: 26px">
            2. 从软件使用到剪辑工具讲解，到专业调色理论知识。
          </div>
          <div>
            3.结合多次RAW格式灰片进行实战调色，逐步夯实调色理论及调色技巧。
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
          padding-top: 38px;
          padding-bottom: 30px;
        "
      >
        <div style="width: 60px; height: 61px">
          <img
            style="width: 100%; height: auto"
            src="../../assets/jianzhizhongxin/dagang5@2x.png"
          />
        </div>
        <div
          style="
            width: 100%;
            height: 59px;
            background-color: #f5f5f5;
            line-height: 60px;
            color: #333333;
            font-size: 26px;
            text-align: left;
            padding-left: 22px;
          "
        >
          第五阶段：AE操作基础
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-start;
          width: 66%;
          margin: 0 auto;
          padding-bottom: 61px;
        "
      >
        <div>
          <img src="../../assets/jianzhizhongxin/dagang_img5.png" />
        </div>
        <div
          style="
            width: 100%;
            height: 253px;
            background-color: #f5f5f5;
            color: #333333;
            font-size: 18px;
            text-align: left;
            padding-left: 43px;
            padding-top: 61px;
            padding-right: 42px;
          "
        >
          <div style="padding-bottom: 26px">
            1.讲解AE使用场景及各类常见工具使用，并随堂制作精美案例，熟悉巩固课上知识点。
          </div>
          <div style="padding-bottom: 26px; line-height: 36px">
            2.并依次讲解三大粒子插件，Particular、form、plexus从小型案例到大型粒子案例，制作经典行业粒子特效。
          </div>
          <div>
            3.深入了解学习E3D插件，制作更精美及个性化作品，提高自身核心竞争力，助力突破内卷
          </div>
        </div>
      </div>
      <div
        @click="open_sus"
        style="
          width: 277px;
          height: 66px;
          background-color: #fc661c;
          border-radius: 33px;
          text-align: center;
          color: #ffffff;
          font-size: 20px;
          line-height: 66px;
          margin: 0 auto;
		  cursor: pointer;
        "
      >
        立即报名
      </div>
    </div>
	
	<div style="background-color: rgb(245, 245, 245);">
	  <div id="xueyuanpingjia" style="width: 66%; margin: auto">
	    <div
	      style="
	        width: 66%;
	        color: #333333;
	        font-size: 30px;
	        font-weight: bold;
	        padding-top: 81px;
	        padding-bottom: 37px;
	      "
	    >
	      学员评价
	    </div>
	    <div
	      style="
	        width: 100%;
	        display: flex;
	        flex-wrap: wrap;
	        justify-content: space-between;
	      "
	    >
	      <div
	        v-for="(item, index) in pingjiaList"
	        :key="index"
	        style="
	          width: 49%;
	          min-width: 450px;
	          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	          border-radius: 12px;
	          display: flex;
	          padding: 26px;
	          margin-bottom: 20px;
			  background-color: #fff;
	        "
	      >
	        <div style="width: 226px; text-align: center">
	          <div>
	            <img :src="item.hradPor" alt="" style="width: 56px; height: 56px; border-radius: 50%;"/>
	          </div>
	          <div style="font-weight: bold; font-size: 16px;margin: 16px 0 8px;">
	            {{ item.name }}
	          </div>
	          <div style="font-size: 12px; color: #999999">
	            {{ item.job }}
	          </div>
	        </div>
	        <div style="margin-left: 26px; display: flex; align-items: center">
	          <div style="height: 66px; border-right: 2px solid #e2e2e2"></div>
	        </div>
	        <div
	          style="
	            margin-left: 26px;
	            display: flex;
	            align-items: center;
	            font-size: 14px;
	            color: #999999;
	            line-height: 30px;
	          "
	        >
	          <div>{{ item.test }}</div>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>

    <div
      id="jianzhizuopin"
      style="
        width: 100%;
        margin: auto;
        background-color: #f5f5f5;
		padding: 160px 0 120px;
      "
    >
      <div
        style="
          width: 66%;
          display: flex;
          justify-content: flex-start;
          margin: auto;
          padding-bottom: 37px;
        "
      >
        <div
          style="
            color: #333333;
            font-size: 30px;
            font-weight: bold;
            margin-right: 51px;
            flex: 0 0 130px;
          "
        >
          兼职作品
        </div>
        <div v-for="(item, index) in dataTabList" :key="index">
          <div
            class="jzzp"
            :class="active_jobTag_index == index ? 'active_tag' : ''"
            @click="clickJobTag(item, index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          width: 66%;
          margin: 0 auto;
          padding-bottom: 25px;
        "
      
      >
      <div class="works">
		
        <el-carousel
          type="card"
          height="280px"
          arrow="always"
          style="width: 1200px"
          :loop="true"
          :initial-index="0"	
         
        >
          <el-carousel-item
            class="zuopin_content_div"
            v-for="(item, index) in jianzhizuopin[active_jobTag_index]"
            :key="index"
          >
            <div
              style="
                width: 280px;
                height: 280px;
                margin: 0 auto;
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
                border-radius: 20px;
              "
            >
              <img style="width: 100%; height: auto;border-radius: 8px;" :src="item" />
            </div>
          </el-carousel-item>
        </el-carousel>
        </div>
      </div>
     
    </div>
    

    <!--学员反馈-->
    <div>
      <div
        style="
          width: 100%;
          height: 966px;
          background-color: #fff;
          padding: 26px 0;
        "
      >
        <div style="width: 66%; margin: auto; text-align: center">
          <div style="margin-bottom: 40px">
            <div style="font-size: 30px; font-weight: bold; color: #333333">
              学员反馈
            </div>
            <div style="font-size: 16px; color: #999999">
              用事实说话，学员的收获就是对我们最大的肯定
            </div>
          </div>
          <div style="width: 1011px; height: auto; margin: auto">
            <img
              style="width: 100%"
              src="../../assets/jianzhizhongxin/xyfk.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!--排行榜单-->
    <div>
      <div
        id="paihangbangdan"
        style="width: 100%; height: 1000px; padding: 66px 0; background-color: #fff;"
      >
        <div style="width: 66%; margin: auto; text-align: center">
          <div style="margin-bottom: 40px">
            <div style="font-size: 30px; font-weight: bold; color: #333333">
              排行榜单
            </div>
            <div style="font-size: 16px; color: #999999">
              每天都有学员在通过兼职赚钱
            </div>
          </div>
          <div style="margin-bottom: 26px">
            <el-carousel
              indicator-position="none"
              :interval="4000"
              type="card"
              width="1000px"
              height="796px"
            >
              <el-carousel-item
                v-for="(item, index) in paihangbangdan_data"
                :key="index"
              >
                <!--                <h3 class="medium">{{ item }}</h3>-->
                <img style="width: 400px" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        id="jigouyoushi"
        style="
          width: 100%;
          height: auto;
          background-color: #f5f5f5;
          padding: 66px 0;
        "
      >
        <div style="width: 66%; margin: auto; min-width: 1050px">
          <div style="margin-bottom: 40px">
            <div style="font-size: 30px; font-weight: bold; color: #333333">
              机构优势
            </div>
          </div>
          <div style="display: flex; justify-content: space-evenly">
            <div
              style="
                background-color: #ffffff;
                width: 616px;
                min-width: 400px;
                padding: 16px 66px;
                position: relative;
                border-radius: 12px;
              "
            >
              <div>
                <div
                  style="
                    height: 66px;
                    width: 140px;
                    position: absolute;
                    left: 50%;
                    margin-left: -70px;
                    top: -65px;
                  "
                >
                  <img
                    style=""
                    src="../../assets/jianzhizhongxin/jgys_blue.png"
                    alt=""
                  />
                </div>
                <div style="margin-top: 66px; height: 66px">
                  <span
                    style="font-size: 14px; color: #333333; line-height: 30px"
                    >精研学堂教育是一家运用现代化在线教育模式的大型高新科技企业，多年专注教育领域，提供在线学习、在线答疑等多项在线教育服务，全力促进中国教育前行！</span
                  >
                </div>
                <div
                  style="
                    border-top: 1px solid #f2f2f2;
                    margin-top: 96px;
                    margin-bottom: 16px;
                  "
                ></div>
                <div style="display: flex; justify-content: space-between">
                  <div style="text-align: center">
                    <div style="color: #074DFE">
                      <span style="font-size: 36px; font-weight: bold">8</span>
                      <span style="font-size: 14px; margin-left: 3px">万</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">
                      官网日访问量
                    </div>
                  </div>
                  <div style="text-align: center">
                    <div style="color: #074DFE">
                      <span style="font-size: 36px; font-weight: bold">30</span>
                      <span style="margin-left: 3px">万</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">
                      官方网站PV
                    </div>
                  </div>
                  <div style="text-align: center">
                    <div style="color: #074DFE">
                      <span style="font-size: 36px; font-weight: bold"
                        >300</span
                      >
                      <span style="margin-left: 3px">万</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">学员数量</div>
                  </div>
                  <div style="text-align: center">
                    <div style="color: #074DFE">
                      <span style="font-size: 36px; font-weight: bold">6</span>
                      <span style="margin-left: 3px">万</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">
                      合作企业数量
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 26px"></div>
            <div
              style="
                background-color: #ffffff;
                width: 616px;
                min-width: 400px;
                padding: 16px 66px;
                position: relative;
                border-radius: 12px;
              "
            >
              <div>
                <div
                  style="
                    height: 66px;
                    width: 140px;
                    position: absolute;
                    left: 50%;
                    margin-left: -70px;
                    top: -65px;
                  "
                >
                  <img
                    style=""
                    src="../../assets/jianzhizhongxin/jgys_orange.png"
                    alt=""
                  />
                </div>
                <div style="margin-top: 66px; height: 66px">
                  <span
                    style="font-size: 14px; color: #333333; line-height: 30px"
                    >精研学堂教育兼职中心不仅仅是国内倍受欢迎的设计师学习平台。自成立至今，
                    一直专注于设计师的学习成长交流。为设计师们提供更多的兼职就业机会，为社会培养以市场需求为导向的专业人才，是真正服务于设计师的良心机构。</span
                  >
                </div>
                <div
                  style="
                    border-top: 1px solid #f2f2f2;
                    margin-top: 96px;
                    margin-bottom: 16px;
                  "
                ></div>
                <div style="display: flex; justify-content: space-between">
                  <div style="text-align: center">
                    <div style="color: #fc661c">
                      <span style="font-size: 36px; font-weight: bold"
                        >17万</span
                      >
                      <!--                      <span style="font-size: 14px;">万</span>-->
                      <span style="font-size: 14px">+</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">
                      累计提供兼职数量
                    </div>
                  </div>
                  <div style="text-align: center">
                    <div style="color: #fc661c">
                      <span style="font-size: 36px; font-weight: bold"
                        >10万</span
                      >
                      <!--                      <span>万</span>-->
                      <span>+</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">服务学员</div>
                  </div>
                  <div style="text-align: center">
                    <div style="color: #fc661c">
                      <span style="font-size: 36px; font-weight: bold"
                        >42万</span
                      >
                      <!--                      <span>万</span>-->
                      <span>分钟</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">课程时长</div>
                  </div>
                  <div style="text-align: center">
                    <div style="color: #fc661c">
                      <span style="font-size: 36px; font-weight: bold"
                        >3000</span
                      >
                      <span>+</span>
                    </div>
                    <div style="font-size: 12px; color: #999999">
                      学员平均月收入
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              background-color: #ffffff;
              width: 100%;
              margin-top: 26px;
              padding: 26px;
              border-radius: 12px;
            "
          >
            <div style="display: flex; justify-content: space-evenly">
              <div
                v-for="(item, index) in jigouyoushi_xiamian_data"
                :key="index"
                style="text-align: center"
              >
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <div style="margin-top: 40px">
                  <div
                    style="
                      font-size: 26px;
                      font-weight: bold;
                      line-height: 30px;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      color: #333333;
                      line-height: 30px;
                      margin-top: 9px;
                    "
                  >
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              margin-top: 66px;
              display: flex;
              align-items: center;
              min-width: 666px;
            "
          >
            <div
              style="
                box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.1);
                width: 378px;
                min-width: 351px;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 66px;
                  background-color: #d0d6e0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #ffffff;
                  font-size: 24px;
                  font-weight: bold;
                "
              >
                <span>传统线下教育</span>
              </div>
              <div style="line-height: 66px; margin: 26px 40px">
                <div
                  v-for="(item, index) in chongtongxianxiajiaoyu"
                  :key="index"
                  style="display: flex; align-items: center"
                >
                  <img :src="item.img" alt="" />
                  <span
                    style="margin-left: 12px; font-size: 14px; color: #666666"
                    >{{ item.text }}</span
                  >
                </div>
              </div>
            </div>
            <div style="box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.1)">
              <div
                style="
                  background-color: #ffffff;
                  width: 490px;
                  height: 539px;
                  min-width: 422px;
                "
              >
                <div
                  style="
                    width: 100%;
                    height: 112px;
                    background-color: #074DFE;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffffff;
                    font-size: 30px;
                    font-weight: bold;
                  "
                >
                  <span>精研学堂教育</span>
                </div>
                <div style="line-height: 66px; margin: 26px 40px">
                  <div
                    v-for="(item, index) in yuanchengjiaoyu"
                    :key="index"
                    style="display: flex; align-items: center"
                  >
                    <img :src="item.img" alt="" />
                    <span
                      style="margin-left: 12px; font-size: 18px; color: #333333"
                      >{{ item.text }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.1);
                width: 400px;
                min-width: 369px;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 66px;
                  background-color: #d0d6e0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #ffffff;
                  font-size: 24px;
                  font-weight: bold;
                "
              >
                <span>弹丸小企业</span>
              </div>
              <div style="line-height: 66px; margin: 26px 40px">
                <div
                  v-for="(item, index) in tanwanxiaoqiye_data"
                  :key="index"
                  style="display: flex; align-items: center"
                >
                  <img :src="item.img" alt="" />
                  <span
                    style="margin-left: 12px; font-size: 14px; color: #666666"
                    >{{ item.text }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div id="jianzhifangshi" style="margin-top: 66px; padding-top: 66px">
            <div>
              <div>
                <div style="font-size: 30px; font-weight: bold; color: #333333">
                  兼职方式
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 26px;
                "
              >
                <div
                  style="display: flex; align-items: center"
                  v-for="(item, index) in jianzhifangshi_data"
                  :key="index"
                >
                  <div
                    style="
                      background-color: #ffffff;
                      width: 369px;
                      padding: 66px 26px;
                      border-radius: 12px;
                    "
                  >
                    <div style="display: flex">
                      <div>
                        <img :src="item.img" alt="" />
                      </div>
                      <div style="margin-left: 16px; padding-top: 6px">
                        <div style="font-size: 24px; line-height: 30px">
                          {{ item.title }}
                        </div>
                        <div
                          style="
                            font-size: 16px;
                            color: #666666;
                            line-height: 30px;
                            margin-top: 22px;
                          "
                        >
                          {{ item.text }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.img_jiantou !== ''">
                    <img :src="item.img_jiantou" alt="" />
                  </div>
                </div>
              </div>
              <div style="margin-top: 166px; text-align: center">
                <el-button
                  @click="open_sus"
                  style="
                    background-color: #fc661c;
                    color: #ffffff;
                    border: 0;
                    width: 266px;
                    font-size: 22px;
                    border-radius: 30px;
                    height: 50px;
					cursor: pointer;
                  "
                >
                  立即报名</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="changjianwenti"
      style="padding-top: 66px; background-color: #ffffff"
    >
      <div
        style="
          width: 66%;
          margin: auto;
          padding-bottom: 66px;
          border-radius: 12px;
        "
      >
        <div style="margin-top: 26px">
          <div>
            <div style="font-size: 30px; font-weight: bold; color: #333333">
              常见问题
            </div>
          </div>
          <div style="margin-top: 66px">
            <div
              style="display: flex; width: 100%; margin-top: 26px"
              v-for="(item, index) in changjianwenti_data"
              :key="index"
            >
              <div style="margin-top: 3px">
                <img :src="item.img" alt="" />
              </div>
              <div style="margin-left: 12px; width: 100%">
                <div style="font-size: 20px; color: #333333">
                  {{ item.title }}
                </div>
                <div
                  style="
                    background-color: #f8f9fa;
                    border-radius: 12px;
                    padding: 16px;
                    margin-top: 16px;
                    font-size: 16px;
                    color: #666666;
                  "
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="bbb" style="width: 100%px;height: 56px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi0.jpg" >
		</div>
		<div id="bbb" style="width: 100%px;height: 918px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi1.jpg" >
		</div>
		<div id="bbb" style="width: 100%px;height: 770px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi2.jpg" >
		</div>
		<div id="ccc" style="width: 100%px;height: 1005px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi3.jpg" >
		</div>
		<div id="ddd" style="width: 100%px;height: 1163px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi4.jpg" >
		</div>
		<div id="eee" style="width: 100%px;height: 973px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi5.jpg" >
		</div>
		<div id="fff" style="width: 100%px;height: 2505px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi6.jpg" >
		</div>
		<div id="ggg" style="width: 100%px;height: 973px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi7.jpg" >
		</div>
		<div id="hhh" style="width: 100%px;height: 663px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi8.jpg" >
		</div>
		<div id="iii" style="width: 100%px;height: 1098px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi9.jpg" >
		</div>
		<div id="jjj" style="width: 100%px;height: 1059px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi10.jpg" >
		</div>
		<div id="kkk" style="width: 100%px;height: 1732px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi11.jpg" >
		</div>
		<div id="lll" style="width: 100%px;height: 786px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi12.jpg" >
		</div>
		<div id="mmm" style="width: 100%px;height: 882px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi13.jpg" >
		</div>
		<div style="width: 100%px;height: 507px;">
			<img style="width: 100%;height: auto;" src="https://static.shanchuangjiaoyu.com/ycpc/assets/jianzhi14.jpg" >
		</div> -->
    <!-- 弹窗：领取课程 -->
    <div class="jiaru">
    	<el-dialog title="" :visible.sync="dialogVisible" width="856px" center :show-close="false"
    		class="my-info-dialog">
    		<div style="width: 857px;height: 567px;position: relative;top: 0; left: 50%; margin-left: -428px;">
    			<img style="width: 100%;height: 100%;"
    				src="https://juntongoss.oss-cn-beijing.aliyuncs.com/data/jingrui%402x.png">
    			<div style="position: absolute;left: 50%;right: 0;top: 80px;text-align: center;z-index: 999;">
    				<!-- <img :src="form.closeUrl" @click="dialogVisible1 = false"
    					style="cursor: pointer; width: 18px; height: 18px;object-fit: cover; position:absolute;right: 10px;"> -->
    				<div style="color: #0D1E3D;font-size: 32px;font-weight: bold;padding-bottom: 24px;">免费领取</div>
    				<div style="color: #0D1E3D;font-size: 44px;font-weight: bold;padding-bottom: 46px;"><span
    						style="color: #074DFE;">0基础30节</span>直播课</div>
    				<div style="padding: 0 50px; text-align: left;" v-if="form.next">
    					<div class="next_span" style="margin: 0 0 10px 0;">您的验证码已通过短信发送到</div>
    					<div class="next_span">{{form.tel.replace(form.tel.substr(3, 4), "****")}}</div>
    					<div class="yanzhengmaBox">
    						<el-input class="yanzhengma_input" v-model="form.code" style="margin-bottom: 50px;"
    							placeholder="请输入验证码"></el-input>
    						<div class="yanzhengma_get pointer" @click="getCode">{{form.send}}</div>
    					</div>
    					<el-button class="pointer" @click="submit"
    						style="width: 346px;height: 60px;border-radius: 30px;background-color: #074DFE;color: #FFFFFF;font-size: 20px;"
    						type="primary" round>最后一步，立即领取</el-button>
    				</div>
    				<div v-else>
    					<div class="popupInput">
    						<el-select style="margin-bottom: 22px;" v-model="form.kecheng" placeholder="请您选择课程">
    							<el-option v-for="(item, index) in form.kechengOption" :key="index"
    								:label="item.name" :value="item.id">
    							</el-option>
    						</el-select>
    						<el-input v-model="form.tel" type="text" maxlength="11" style="margin-bottom: 50px;"
    							placeholder="请输入您的手机号"></el-input>
    
    						<el-button @click="go_next"
    							style="width: 346px;height: 60px;border-radius: 30px;background-color: #074DFE;color: #FFFFFF;font-size: 22px;"
    							 round>立即领课</el-button>
    					</div>
    				</div>
    
    				<!-- <div style="display: flex;justify-content: space-around;padding-top: 18px;">
    					<div style="display: flex;">
    						<img @click="click_check"
    							style="width: 16px;height: 16px;margin-right: 10px;cursor:pointer;"
    							:src="form.isCheck ? 'https://static.shanchuangjiaoyu.com/ycpc/assets/queren@2x.png' : 'https://static.shanchuangjiaoyu.com/ycpc/assets/un_queren@2x.png'">
    
    						<div style="color: #999999;font-size: 12px;line-height: 16px;">登录注册表示您已阅读并同意<span
    								style="color: #074DFE;">《用户服务协议》</span>及<span
    								style="color: #074DFE;">《隐私政策》</span></div>
    					</div>
    				</div> -->
    			</div>
    		</div>
    	</el-dialog>
    </div>
  </div>
</template>

<script>
import Bus from "../../assets/js/Bus.js";
import {pingjiaList, dataTabList, zuopinList_jianzhi, zuopinList_jiangshi} from "../../assets/js/jobData.js"
export default {
  name: "Jianzhizhongxin",
  // components: {
  // 	vueSeamlessScroll
  // },
  data() {
    return {
      dialogVisible: false,
      //学员评价数据
     pingjiaList,
	  dataTabList,
	  zuopinList_jianzhi,
	  jianzhizuopin: [],
	  zuopinList_jiangshi,
      //排行榜单
      paihangbangdan_data: [
        {
          img: require("../../assets/jianzhizhongxin/1.png"),
        },
        {
          img: require("../../assets/jianzhizhongxin/2.png"),
        },
        {
          img: require("../../assets/jianzhizhongxin/3.png"),
        },
      ],

      //机构优势
      jigouyoushi_xiamian_data: [
        {
          img: require("../../assets/jianzhizhongxin/jgys_zxsk.png"),
          title: "在线授课",
          text: "名师在线讲解，与学生互动学习",
        },
        {
          img: require("../../assets/jianzhizhongxin/jgys_khfd.png"),
          title: "课后辅导",
          text: "课后督促学员完成作业并且悉心指导",
        },
        {
          img: require("../../assets/jianzhizhongxin/jgys_for.png"),
          title: "可循环听课",
          text: "课堂直播录课，随时随地，循环听课",
        },
        {
          img: require("../../assets/jianzhizhongxin/jgys_zxdy.png"),
          title: "在线答疑",
          text: "为学员在线答疑，伴随式服务",
        },
      ],

      chongtongxianxiajiaoyu: [
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "1.5万-3万的高额学费及各种额外费用",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "传统教育模式，课堂设置无品质",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "指定地点上课，路途遥远舟车劳顿",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "念PPT式讲课，课程只教基础",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "教师经验良莠不齐，缺失企业级别讲师",
        },
      ],

      yuanchengjiaoyu: [
        {
          img: require("../../assets/jianzhizhongxin/huizhang.png"),
          text: "教学服务口碑相传，兼职单子持续提供",
        },
        {
          img: require("../../assets/jianzhizhongxin/huizhang.png"),
          text: "小班分组教学，实时答疑辅助日常学习",
        },
        {
          img: require("../../assets/jianzhizhongxin/huizhang.png"),
          text: "网络直播授课，课堂氛围活跃即问即答",
        },
        {
          img: require("../../assets/jianzhizhongxin/huizhang.png"),
          text: "理论与实践紧密结合，针对性作业指导",
        },
        {
          img: require("../../assets/jianzhizhongxin/huizhang.png"),
          text: "讲师多年实战经验，传授职场必备技能",
        },
      ],
      tanwanxiaoqiye_data: [
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "服务内容少，不提供兼职任务",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "只教流行风格，不注重根基培养",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "快速节课只教皮毛，内容简浅",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "课程与实践脱轨，内容不系统不全面",
        },
        {
          img: require("../../assets/jianzhizhongxin/kulian.png"),
          text: "社交平台稍有人气就开班教学，缺乏经验",
        },
      ],

      jianzhifangshi_data: [
        {
          img: require("../../assets/jianzhizhongxin/jzfs1.png"),
          title: "联系班主任老师",
          text: "先添加班主任老师微信好友，联系班主任老师后即可咨询并购买课程",
          img_jiantou: require("../../assets/jianzhizhongxin/jiantou.png"),
        },
        {
          img: require("../../assets/jianzhizhongxin/jzfs2.png"),
          title: "购买课程",
          text: "向班主任老师获取报名链接，即可完成课程购买（可花呗支付）",
          img_jiantou: require("../../assets/jianzhizhongxin/jiantou.png"),
        },
        {
          img: require("../../assets/jianzhizhongxin/jzfs3.png"),
          title: "开通兼职权限接任务",
          text: "开通兼职权限后，即可轻松开启接单赚佣金",
          img_jiantou: "",
        },
      ],

      changjianwenti_data: [
        {
          img: require("../../assets/jianzhizhongxin/cjwt_yuan.png"),
          title: "非正式课学员可以兼职吗？",
          text: "不可以！只有报名正式课的学员才可以享受兼职服务",
        },
        {
          img: require("../../assets/jianzhizhongxin/cjwt_yuan.png"),
          title: "课程学习会用到哪些软件和工具？",
          text: "沟通工具：钉钉、微信；作业工具：微信；上课工具：微信；相关软件：视具体课程而定。",
        },
        {
          img: require("../../assets/jianzhizhongxin/cjwt_yuan.png"),
          title: "课程周期是多久呢？",
          text: "每个课程具体时间不一，平均周期为3.5-7.5个月",
        },
        {
          img: require("../../assets/jianzhizhongxin/cjwt_yuan.png"),
          title: "兼职做一单大约是多少钱？",
          text: "兼职单子佣金和工作强度以及工作难度成正比，多劳多得",
        },
        {
          img: require("../../assets/jianzhizhongxin/cjwt_yuan.png"),
          title: "新手学多久可以兼职？",
          text: "学习期间或者学习后皆可兼职",
        },
      ],

      form: {
        kechengOption: [],
        kecheng: "",
        tel: "",
        isCheck: false,
        next: false,
        code: "",
        send: "获取验证码",
        timer: null,
        closeUrl: process.env.VUE_APP_OSS_URL + "close.png",
      },
     
      active_jobTag_index: 0,
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
     

      // activeIndex: 0
    };
  },
  created() {
    // console.log(this.worksList, "123");
    this.getData();
    Bus.$on("danghanglan", (value) => {
      if (value == 1) {
        document.querySelector("#ccc").scrollIntoView(true);
      }
      if (value == 2) {
        document.querySelector("#jiangshizuopin").scrollIntoView(true);
      }

      if (value == 3) {
        document.querySelector("#kechengdagang").scrollIntoView(true);
      }

      if (value == 4) {
        document.querySelector("#jianzhizuopin").scrollIntoView(true);
      }

      if (value == 5) {
        document.querySelector("#xueyuanpingjia").scrollIntoView(true);
      }

      if (value == 6) {
        document.querySelector("#paihangbangdan").scrollIntoView(true);
      }

      if (value == 7) {
        document.querySelector("#jigouyoushi").scrollIntoView(true);
      }

      if (value == 8) {
        document.querySelector("#jianzhifangshi").scrollIntoView(true);
      }

      if (value == 9) {
        document.querySelector("#changjianwenti").scrollIntoView(true);
      }
    });
    // this.getPartTimeJob();
	this.getJianZhiZuoPinData();
  },

  methods: {
	getJianZhiZuoPinData(){
		this.dataTabList.forEach((item,index)=>{
			if(index == 0){
				this.jianzhizuopin[0] = this.zuopinList_jianzhi;
			}else{
				this.jianzhizuopin[index] = this.getRandomData();
			}
		})
	},
    clickJobTag(item, index) {
      this.active_jobTag_index = index;
     
    },
	getRandomData(){
		let jianzhizuopin = [];
		while(true){
			let index = Math.floor(Math.random()*10);
			if(jianzhizuopin.indexOf(this.zuopinList_jianzhi[index]) <0){
				jianzhizuopin.push(this.zuopinList_jianzhi[index]);
				if(jianzhizuopin.length >= 4){
					break;
				}
			}	
		}
		return jianzhizuopin;
	},
    getPartTimeJob() {
      this.$axios({
        method: "GET",
        url: "/api/part/getLabelList",
      })
        .then((res) => {
          if (res) {
            this.tLabelList = res.data;
          }
          console.log(res, 999);
        })
        .catch((error) => {
          console.log(error);
        });
    },
   
	getData() {
		//获取课程下拉列表
		this.$axios({
			method: "POST",
			url: "/api/officialWebsite/course_selection_package_list"
		}).then((res) => {
			if (res.code == 1 || res.code == 200) {
				this.form.kechengOption = res.data;
			} else {
				this.$message.error(res.msg)
			}
		}).catch((error) => {
			console.log(error);
		});
	},
    go_next() {
    	// console.log('课程：' + this.form.kecheng + '手机号:' + this.form.tel)
    	if (!this.form.kecheng) {
    		return this.$message({
    			message: '请选择课程',
    			type: 'error',
    			offset: '100'
    		});
    	}
    	if (!this.form.tel) {
    		return this.$message({
    			message: '请输入手机号',
    			type: 'error',
    			offset: '100'
    		});
    		// return this.$message.error('请输入手机号')
    	} else if (this.form.tel.length != 11) {
    		return this.$message({
    			message: '请输入正确的手机号',
    			type: 'error',
    			offset: '100'
    		});
    		// return this.$message.error('请输入正确的手机号')
    	}
    	this.getCode();
    	this.form.next = true;
    },
    getCode() {
    	let time = 60;
    	if (this.form.timer) return
    
    	this.form.timer = setInterval(() => {
    		if (time > 0) {
    			time--;
    			this.form.send = time;
    		} else {
    			clearInterval(this.form.timer);
    			this.form.timer = null;
    			this.form.send = "获取验证码";
    		}
    	}, 1000)
    
    	this.$axios({
    		method: "POST",
    		url: "/api/index/send_code",
    		data: {
    			tel: this.form.tel,
    			type: 1
    		}
    	}).then((res) => {
    		if (res.code == 1 || res.code == 200) {
    
    		} else {
    			this.$message({
    				message: res.msg,
    				type: 'error',
    				offset: '100'
    			});
    			// this.$message.error(res.msg)
    			setTimeout(() => {
    				clearInterval(this.form.timer);
    				this.form.timer = null;
    				this.form.send = "获取验证码";
    			}, 3000)
    		}
    	}).catch((error) => {
    		console.log(error);
    	});
    },
    submit() {
    	if (!this.form.code) {
    		return this.$message({
    			message: '请输入验证码',
    			type: 'error',
    			offset: '100'
    		});
    		// return this.$message.error('请输入验证码')
    	}
    	this.$axios({
    		method: "POST",
    		url: "/api/index/submit_mobile",
    		data: {
    			telphone: this.form.tel,
    			coursebase_id: this.form.kecheng,
    			code: this.form.code
    		},
    	}).then((res) => {
    		if (res.code == 1 || res.code == 200) {
    			this.dialogVisible = false;
    			this.$notify({
    			    title: '成功',
    			    message: res.msg || res.message,
    			    type: 'success',
    				position: 'top-left'
    			});
    		} else {
    			this.$notify.error({
    				title: '提示',
    			    message: res.msg || res.message,
    				position: 'top-left'
    			});
    		}
    	}).catch((error) => {
    		console.log(error);
    	});
    },

    //立即报名
    open_sus() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style type="text/css">
	.jiaru .el-dialog {
		background: rgba(255, 255, 255, 0) !important;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	}

	.jiaru .el-input__inner {
		border-radius: 10px;
		padding: 0 15px !important;
		box-sizing: border-box;
	}

	.my-info-dialog .el-dialog,
	.el-pager li {
		background: none;
	}

	.my-info-dialog .el-dialog {
		box-shadow: none;
	}

	.popupInput {
		width: 346px;
		border-radius: 30px;
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		margin: 0 auto;
	}
	
	
	

</style>

<style scoped="scoped">
* {
  margin: 0;
  padding: 0;
  border: 0;
}
img {
	overflow: hidden;
	display: block;
	vertical-align:top;
	vertical-align:middle;
}

.jianzhi_daohang {
  background: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.jzzp {
  background-color: #ffffff;
  width: 83px;
  height: 27px;
  color: #666666;
  border-radius: 13.5px;
  padding: 6px 10px;
  font-size: 12px;
  margin-top: 8px;
  margin-right: 20px;
  text-align: center;
}

.jzzp:hover {
  background-color: #fc661c;
  width: 83px;
  height: 27px;
  color: #ffffff;
  border-radius: 13.5px;
  padding: 6px 10px;
  font-size: 12px;
  margin-top: 8px;
  margin-right: 20px;
  text-align: center;
}
.popupInput {
  width: 346px;
  border-radius: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0 auto;
}


.next_span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 0 0 30px;
}

.yanzhengmaBox {
  display: flex;
  justify-content: space-between;
}

.yanzhengma_input {
  width: calc(100% - 126px) !important;
}

.yanzhengma_get {
  width: 116px;
  height: 60px;
  background: #074DFE;
  border-radius: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
}

.message_zindex {
  z-index: 100002 !important;
}
.active_tag {
  background: #fc661c;
  color: #fff;
}
  .works{
    margin: 0 auto;
  }
  
.works>.el-carousel__container{
    width: 960px;
    margin: 0 auto;
  }
.zuopin_content {
  width: 1200px;
  height: 360px;
  padding: 10px;
  margin: 90px 30px;
  /* overflow: hidden; */
  position: relative;
  margin-left: 170px;
}

.zuopin_box {
  /* position: absolute; */
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.el-carousel__item--card{
      width: 50%;
    transition: transform .4s ease-out;
}
.el-carousel__item--card.is-active{
  background: none;
}
.zuopin_content_div {
  flex-shrink: 0;
  width: 650px;
  background: #f5f5f5;
  /* box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15); */
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 120px; */
}
/deep/ .el-input {
  height: 60px;
}
.el-input.is-disabled .el-input__inner {
}
.my-info-dialog .el-dialog,
.el-pager li {
  background: none;
}
.my-info-dialog .el-dialog {
  box-shadow: none;
}
/* /deep/ .el-carousel__item--card.is-active {
  display: flex;
} */
.jiaru .el-dialog {
		background: rgba(255, 255, 255, 0) !important;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	}

	.jiaru .el-input__inner {
		border-radius: 10px;
		padding: 0 15px !important;
		box-sizing: border-box;
	}

	.my-info-dialog .el-dialog,
	.el-pager li {
		background: none;
	}

	.my-info-dialog .el-dialog {
		box-shadow: none;
	}

	.popupInput {
		width: 346px;
		border-radius: 30px;
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		margin: 0 auto;
	}
	#jiangshizuopin {
		width: 100%;
		height: 975px;
		margin: auto;
		background-color: #f5f5f5;
	}
	.jiangshi_title {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: auto;
		color: #333333;
		font-size: 30px;
		font-weight: bold;
		padding-top: 120px;
		padding-bottom: 37px;
	}
	.jiangshi_box {
		display: flex;
		justify-content: space-between;
		width: 1200px;
		margin: 0 auto;
		flex-wrap: wrap;
	}
	.jiangshi_item {
		width: 282px; 
		height: 281px; 
		margin: 0 0 25px 0
	}
	.jiangshi_img {
		width: 100%; 
		object-fit: cover;
		border-radius: 10px;
	}
	.jiangshi_zhuanye_title {
		padding-top: 77px;
		margin-top: 77px;
		color: #333333;
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}
	.jiangshi_zhuanye_title2 {
		padding-top: 14px;
		color: #999999;
		font-size: 20px;
		font-weight: 400;
		text-align: center;
	}
	.jiangshi_zhuanye_box {
		position: absolute;
		left: 70%;
		top: 60%;
		transform: translate(-50%, -50%);
	}
	.jiangshi_zhuanye_box_name {
		color: #3c71ff;
		font-size: 40px;
		font-weight: bold;
		text-align: left;
		padding-bottom: 35px;
	}
	.jiangshi_zhuanye_box_jiehsao {
		display: flex;
		justify-content: flex-start;
		padding-bottom: 20px;
	}
	.jiangshi_zhuanye_box_jiehsao_img {
		width: 30px; 
		height: 30px; 
		margin-right: 9px
	}
	.jiangshi_zhuanye_box_jiehsao_content {
		color: #666666;
		font-size: 16px;
		font-weight: bold;
		line-height: 36px;
		width: 496px;
		height: 125px;
		text-align: left;
	}

.el-carousel__item--card.is-in-stage {
  display: flex !important;
  justify-content: center !important;
}
</style>
