<template>
  <div style="padding: 0; margin: 0; width: 100%; height: auto">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row;
        width: 1200px;
        margin: 40px auto;
      "
      id="topCon"
    >
      <div class="left">
        <div
          style="
            color: #333333;
            font-size: 14px;
            text-align: left;
            padding-bottom: 63px;
          "
        >
          您的位置：首页><span>{{ contents.cate_name }}></span
          ><span>{{ contents.title }}</span>
        </div>
        <!-- 标题 -->
        <div class="title">
          <span class="titles">{{ contents.title }}</span>
          <div>
            <el-tag type="info">{{ contents.cate_name }}</el-tag>
          </div>
        </div>
        <div class="title-icon">
          <div class="titleInfo">
            <span>作者：精研学堂教育</span>
            <span> <i class="el-icon-view"></i> 1556</span>
            <span> <i class="el-icon-thumb"></i> 1</span>
          </div>
          <div>
            <i class="el-icon-time"></i>
            <span>{{ contents.creat_time }}</span>
          </div>
        </div>
        <div class="contents" style="margin-top: 50px">
          <p v-html="contents.content"></p>
        </div>
        <div style="margin-top: 150px; margin-bottom: 30px">
          <span style="font-size: 30px">相关文章</span>
          <div class="article">
            <div
              v-for="(item, index) in newsList"
              :key="index"
              @click="newsDetail(item)"
              class="article-con"
            >
              <img :src="item.image" alt="" />
              <div  style="margin-left:30px;padding: 10px; color">
                <span style="font-size: 18px; color: #333">{{
                  item.title
                }}</span>
                <p style="color: #666666">{{ item.creat_time }}</p>
                <p  class="articleP contents" v-html="item.content"></p>
                <div class="title-icon" style="margin-top: 28px">
                  <el-tag type="info">{{ contents.cate_name }}</el-tag>
                  <span> <i class="el-icon-view"></i> 1556</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tip">
          <p></p>
          <span>热门文章</span>
        </div>
        <div class="top">
          <img :src="contents.image" alt="" />
          <span>{{ contents.title }}</span>
        </div>
        <div
          v-for="(item, index) in newsList"
          :key="index"
          @click="newsDetail(item)"
        >
          <div class="subTitle">
            <i class="el-icon-caret-right"></i>
            <span>{{ item.title }}</span>
          </div>
          <div class="subTime">
            <span>{{ item.creat_time }}</span>
            <span><i class="el-icon-view"></i>11166</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newsdetails",
  data() {
    return {
      contents: [],
      newsList: [],
      title: [],
      conId: 1,
    };
  },
  created() {
    console.log(this.$route.query.id, 7777);
    this.newsId = this.$route.query.id;
    this.getContent();
    this.getNewsList();
  },

  methods: {
    newsDetail(item) {
      this.conId = item.id;
      console.log(this.conId);
      this.$axios({
        method: "get",
        url: "/api/part/getNewsdetail",
        params: {
          id: this.conId,
        },
      })
        .then((res) => {
          this.contents = res.data;
          // console.log(this.contents, 999);
        })
        .catch((error) => {
          console.log(error);
        });
      document.querySelector("#topCon").scrollIntoView(true);
    },
    getContent() {
      this.$axios({
        method: "get",
        url: "/api/part/getNewsdetail",
        params: {
          id: this.newsId,
        },
      })
        .then((res) => {
          this.contents = res.data;
          // console.log(this.contents, 999);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNewsList() {
      this.$axios({
        method: "post",
        url: "/api/part/getNewsList",
      })
        .then((res) => {
          this.newsList = res.data;
          console.log(this.newsList, "333");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.left {
  width: 800px;
  /* display: flex;
  flex-flow: column; */
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titles {
  font-size: 30px;
  font-weight: 500;
}
.title-icon {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: #666666;
}
.right {
  width: 400px;
  margin-left: 100px;
}
.tip {
  display: flex;
  margin-top: 100px;
  margin-bottom: 30px;
}
.tip p {
  width: 3px;
  background: #ff9900;
  margin-right: 10px;
}
.top {
  display: flex;
  flex-flow: row;
  margin-bottom: 30px;
}
.top img {
  width: 100px;
  height: 80px;
  object-fit: cover;
}
.top span {
  margin-left: 15px;
  width: 150px;
}
.titleInfo span {
  margin-right: 10px;
}
.subTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.subTitle i {
  color: #ff9900;
}
.subTitle span {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666666;
}
.subTime {
  display: flex;
  justify-content: space-between;
  color: #666666;
}
.article img {
  width: 200px;
  height: 165px;
  object-fit: cover;
  border-radius: 10px;
}
.article-con {
  display: flex;
  flex-flow: row;
  padding: 10px;
  cursor: pointer;
}
.articleP {
  width: 90%;
  color: #666666;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 40px;
  
}

/deep/ .el-tag.el-tag--info {
  color: #ff9900;
  border-color: #fca500;
  border-radius: 10px;
}
</style>
<style>
.contents img{
  width: 90%;
}</style>