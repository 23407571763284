<template>
  <div
    id="taskdetails"
    style="background-color: #f5f5f5；width: 100%;
        "
  >
    <div style="width: 1200px; margin: 0px auto">
      <div style="margin: 41px auto">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>您的位置：</el-breadcrumb-item>
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>任务大厅</el-breadcrumb-item>
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="taskdetails">
        <div class="taskTitle">
          <span>{{ taskDetails.title }}</span>
          <span>￥{{ taskDetails.amount_money }}</span>
        </div>
        <div class="taskTags">
          <div  style="display: flex">
            <div v-for="(item, index) in taskDetails.label_name" :key="index">
              <el-tag type="info" size="normal">{{ item }}</el-tag>
            </div>
          </div>
          <div>
            <span>{{ taskDetails.company }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="demand">
          <span>需求描述：</span>
        </div>
        <div class="demandContent">
          <ul>
            <li v-html="taskDetails.requirement">
              <!-- {{ taskDetails.requirement }} -->
            </li>
          </ul>
        </div>
        <div style="margin-top: 50px">
          <el-button type="info" size="default" @click="addTask()"
            >我要参与任务</el-button
          >
        </div>
      </div>
      <process></process>
    </div>
  </div>
</template>
<script>
import process from "../job/process.vue";
export default {
  name: "taskdetails",
  data() {
    return {
      taskDetails: {},
    };
  },

  components: {
    process,
  },
  created() {
    console.log(this.$route.query.res, 7777);
    this.taskDetails=this.$route.query.res
    console.log(this.taskDetails, 123);
  },
  methods: {
    addTask() {
      this.$confirm("请添加班主任老师沟通兼职任务需求", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "参与成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>
<style scoped>
.taskdetails {
  width: 1200px;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}
.taskTitle {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  margin-bottom: 26px;
}
.taskTitle span:nth-child(2) {
  color: #ff0000;
}
.taskTags {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 38px;
}
.taskTags span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.line {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  margin-bottom: 32px;
}
.demand {
  margin-bottom: 30px;
}
.demand span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.demandContent {
  font-size: 14px;
  color: #666666;
  font-family: Microsoft YaHei;
}
.demandContent li {
  line-height: 30px;
}

/deep/ .el-tag.el-tag--info {
  width: 90px;
  height: 33px;
  background-color: #f5f5f5;
  border-radius: 17px;
  line-height: 33px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-button--info {
  width: 134px;
  height: 39px;
  background-color: #fc661c;
  border-color: #fc661c;
  border-radius: 4px;
}
/deep/ .el-button--info:hover {
  background-color: #f9874e;
  border-color: #f9874e;
}
</style>