<template>
	<div class="headerBox" :style="{ width: (dh != '/MapContainer' ? 'calc(100vw - 17px)' : '100%') }" id="top">
		<el-menu class="daohang" :default-active="activeIndex" text-color="#000" active-text-color="#DC0D17"
			mode="horizontal" @select="handleSelect" :style="$hasGray ? 'filter: grayscale(1);' : ''">
			<!-- <div style="padding: 0;margin-right: 40px;height: 60px;" @click="click_tab('/Index')">
				<img src="../../assets/image/svgImg/logo_top.svg" style="display:inline;position: relative;top: 6px; cursor: pointer;height: 51px;">
			</div> -->
			<!-- <el-menu-item index="/Index" class="headeaItem" @click="click_tab('/Index')">首页</el-menu-item> -->
			<!-- <el-menu-item index="/circleIndex" class="headeaItem" @click="click_tab('/circleIndex')">企业保险</el-menu-item> -->
			<!-- <el-menu-item index="/Kecheng" class="headeaItem" @click="click_tab('/Kecheng')">薪税服务</el-menu-item> -->
			<!-- <el-menu-item index="/Jianzhizhongxin" class="headeaItem" @click="click_tab('/Jianzhizhongxin', true)">
				<img src="../../assets/image/svgImg/jianzhizhongxin.svg" class="headerImg">
			</el-menu-item> -->
			<!-- <el-menu-item index="/Yanjiuyuan" class="headeaItem" @click="click_tab('/Yanjiuyuan')">人事社保</el-menu-item> -->
			<!-- <el-menu-item class="headeaItem" @click="dialogVisible = true;">0元试学</el-menu-item> -->
			<!-- <el-menu-item index="/JobSearch" class="headeaItem" @click="click_tab('/JobSearch')">用工管理</el-menu-item> -->
			<!-- <el-menu-item index="/rencaizhongxin" class="headeaItem" @click="click_tab('/rencaizhongxin')">人才中心
			</el-menu-item> -->
			<!-- <el-menu-item index="/MapContainer" class="headeaItem" @click="click_tab('/MapContainer')">
				<img src="../../assets/image/svgImg/wanqihezuo.svg" class="headerImg">
			</el-menu-item> -->
			<!-- <el-menu-item index="/qianxiaohulian" class="headeaItem"
				@click="click_tab('/qianxiaohulian')">千校互联</el-menu-item> -->
			<!-- <el-menu-item index="/xuelitisheng" class="headeaItem" style="margin-right: 35px !important;"
				@click="click_tab('/xuelitisheng')">学历提升</el-menu-item> -->
			<!-- <el-menu-item index="/downloadAPP" class="headeaItem" @click="click_tab('/downloadAPP')" style="display: flex;">
				<img v-if="activeIndex == '/downloadAPP'" src="../../assets/image/svgImg/xiazai_active.svg" style="width: 17px;">
				<img v-else src="../../assets/image/svgImg/xiazai.svg" style="width: 17px;">
				下载APP
			</el-menu-item> -->
			<!-- <div @click="goLogin"
				style="padding: 0; width: 70px; text-align: right; cursor: pointer; outline:none; position: relative;">
				<el-badge :is-dot="$store.state.userInfo && $store.state.userInfo.message_count > 0" class="item">
					<img @mouseenter="onmouseover_img" @mouseleave="onmouseout_image" v-if="$store.state.userInfo"
						class="touxiang"
						:src="$store.state.userInfo && $store.state.userInfo.headico ? $store.state.userInfo.headico : ossUrl + 'touxiang.png'">
					<div class="pull_down_uesrinfo" v-if="isMyCenter" @mouseenter="onmouseover_pull_down"
						@mouseleave="onmouseout_img">
						<div class="pull_down_uesrinfo_top">
							<div class="pull_down_uesrinfo_top_img">
								<img :src="userInfo.headico ? userInfo.headico : ossUrl + 'touxiang.png'" class="auto-size">
							</div>
							<div class="pull_down_uesrinfo_top_name">
								<div style="display: flex; align-items: start;">
									<span
										style="font-size: 20px; max-width: 70px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{
											userInfo.name }}</span>
									<span style="margin: 3px 5px; color: #0059FC; font-weight: 900; font-style:italic;">
										{{ userInfo.level ? `VIP${userInfo.level}` : '' }}
									</span>
									<span v-if="unionid"><img src="../../assets/image/微信.png" alt=""></span>
								</div>
								<div style="color: #828282; font-size: 14px;text-align: left;">
									ID: {{ userInfo.id }}
								</div>
							</div>
						</div>
						<div class="pull_down_uesrinfo_middle">
							<div class="pull_down_uesrinfo_middle_flex"
								@click.stop="$router.push('/personalCenter/myNews')">
								<div><i class="el-icon-chat-dot-square"
										style="font-size: 16px; cursor: pointer;">&nbsp;&nbsp;我的消息</i></div>
								<div class="pull_down_uesrinfo_middle_num"
									v-if="$store.state.userInfo && $store.state.userInfo.message_count != 0">{{
										$store.state.userInfo.message_count }}</div>
							</div>
							<div class="pull_down_uesrinfo_middle_flex underline"
								@click.stop="$router.push('/personalCenter/myXueFen')">
								<div><i class="el-icon-coin" style="font-size: 16px; cursor: pointer;">&nbsp;&nbsp;总学分</i>
								</div>
								<div class="pull_down_uesrinfo_middle_numB">{{ userInfo.integral_total }}</div>
							</div>
						</div>
						<div class="pull_down_uesrinfo_bottom">
							<button style="display: block;cursor: pointer;" @click.stop="account_setting_btn"><i
									class="el-icon-setting" style="font-size: 16px;">&nbsp;&nbsp;账户设置</i></button>
							<button style="display: block; font-size: 16px;margin-top: 15px;cursor: pointer;"
								@click="signOut">
								<img src="../../assets/image/退出.png" alt=""
									style="width: 14px; height: 14px; vertical-align:middle; margin-left: 2px;">
								&nbsp;退出登录
							</button>
						</div>
					</div>
				</el-badge>
				<span v-if="!$store.state.userInfo" style="outline:none">登录/注册</span>
			</div> -->
		</el-menu>
		<!-- <el-dialog title="收货地址" :visible.sync="login_visible">
			<el-form>
				<el-form-item >
					<el-input ></el-input>
				</el-form-item>
				<el-form-item >
					<el-select>
						<el-option ></el-option>
						<el-option ></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</el-dialog> -->
		<login :login_visible="login_visible" @update:visible="login_visible = $event"
			@update:enrollFromVisible="enroll_visible = $event" :enroll_visible="enroll_visible"
			@setUserInfo="userInfo = $event"></login>

		<!-- 弹窗：领取课程 -->
		<div class="jiaru">
			<el-dialog title="" :visible.sync="dialogVisible" width="856px" center :show-close="false"
				class="my-info-dialog" :style="$hasGray ? 'filter: grayscale(1);' : ''">
				<div style="width: 857px;height: 567px;position: relative;top: 0; left: 50%; margin-left: -428px;">
					<img style="width: 100%;height: 100%;"
						src="https://juntongoss.oss-cn-beijing.aliyuncs.com/data/jingrui%402x.png">
					<div style="position: absolute;left: 50%;right: 0;top: 80px;text-align: center;z-index: 999;">
						<!-- <img :src="form.closeUrl" @click="dialogVisible1 = false"
							style="cursor: pointer; width: 18px; height: 18px;object-fit: cover; position:absolute;right: 10px;"> -->
						<div style="color: #0D1E3D;font-size: 32px;font-weight: bold;padding-bottom: 24px;">免费领取</div>
						<div style="color: #0D1E3D;font-size: 44px;font-weight: bold;padding-bottom: 46px;"><span
								style="color: #074DFE;">0基础30节</span>直播课</div>
						<div style="padding: 0 50px; text-align: left;" v-if="form.next">
							<div class="next_span" style="margin: 0 0 10px 0;">您的验证码已通过短信发送到</div>
							<div class="next_span">{{ form.tel.replace(form.tel.substr(3, 4), "****") }}</div>
							<div class="yanzhengmaBox">
								<el-input class="yanzhengma_input" v-model="form.code" style="margin-bottom: 50px;"
									placeholder="请输入验证码"></el-input>
								<div class="yanzhengma_get pointer" @click="getCode">{{ form.send }}</div>
							</div>
							<el-button class="pointer" @click="submit"
								style="width: 346px;height: 60px;border-radius: 30px;background-color: #074DFE;color: #FFFFFF;font-size: 20px;"
								type="primary" round>最后一步，立即领取</el-button>
						</div>
						<div v-else>
							<div class="popupInput">
								<el-select :popper-append-to-body="false" style="margin-bottom: 22px;"
									v-model="form.kecheng" placeholder="请您选择课程">
									<el-option v-for="(item, index) in form.kechengOption" :key="index" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
								<el-input v-model="form.tel" type="text" maxlength="11" style="margin-bottom: 50px;"
									placeholder="请输入您的手机号"></el-input>

								<el-button @click="go_next"
									style="width: 346px;height: 60px;border-radius: 30px;background-color: #074DFE;color: #FFFFFF;font-size: 22px;"
									round>立即领课</el-button>
							</div>
						</div>

						<!-- <div style="display: flex;justify-content: space-around;padding-top: 18px;">
							<div style="display: flex;">
								<img @click="click_check"
									style="width: 16px;height: 16px;margin-right: 10px;cursor:pointer;"
									:src="form.isCheck ? 'https://static.shanchuangjiaoyu.com/ycpc/assets/queren@2x.png' : 'https://static.shanchuangjiaoyu.com/ycpc/assets/un_queren@2x.png'">
		
								<div style="color: #999999;font-size: 12px;line-height: 16px;">登录注册表示您已阅读并同意<span
										style="color: #074DFE;">《用户服务协议》</span>及<span
										style="color: #074DFE;">《隐私政策》</span></div>
							</div>
						</div> -->
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Bus from '../../assets/js/Bus.js'
import axios from 'axios'
export default {
	name: 'Header',
	data() {
		return {
			dialogVisible: false,
			form: {
				kechengOption: [],
				kecheng: '',
				tel: '',
				isCheck: false,
				next: false,
				code: '',
				send: "获取验证码",
				timer: null,
				closeUrl: process.env.VUE_APP_OSS_URL + 'close.png'
			},
			userInfo: JSON.parse(localStorage.getItem("userInfo")),
			token: localStorage.getItem("token"),
			unionid: JSON.parse(localStorage.getItem("unionid")),
			img_obj: JSON.parse(localStorage.getItem('ima_obj')) ? JSON.parse(localStorage.getItem('ima_obj')) : '',
			dh: "",
			imgUrl: process.env.VUE_APP_IMG_URL,
			ossUrl: process.env.VUE_APP_OSS_URL,
			activeIndex: '/Index',
			currentPath: '',

			// userInfo: JSON.parse(localStorage.getItem("userInfo")),
			// 用来判断是否处在个人中心页面
			isMyCenter: false,
			is_pull_down: false,
			myCenter_router: [
				'/personalCenter/myKeCheng',
				'/personalCenter/myWork',
				'/personalCenter/myCollect',
				'/personalCenter/myXueFen',
				'/personalCenter/myOrder',
				'/personalCenter/myKaBao',
				'/personalCenter/myNews',
				'/personalCenter/myHonor',
				'/personalCenter/myCertificate',
				'/personalCenter/bindingVX',
				'/personalCenter/userinfo',
				'/personalCenter/edit_password',
				'/personalCenter/bangding_email',
				'/personalCenter/bangding_sj',
			],

			login_visible: false,
			enroll_visible: false,
			wqtUrl: process.env.VUE_APP_BASE_WQT_URL
		}

	},
	created() {
		this.getData();
		Bus.$on("scrollToTop", () => {
			try {
				document.querySelector('#top').scrollIntoView(true);
			} catch (err) {

			}
		})
		this.checkPath();
		// console.log(localStorage.getItem("token"));
	},

	watch: {
		$route: {
			handler: function (val, oldVal) {
				this.login_visible = !!this.$route.query.login_visible
				this.checkPath();
			},
			// 深度观察监听
			deep: true,
			immediate: true
		},
	},


	methods: {
		submit() {
			if (!this.form.code) {
				return this.$message({
					message: '请输入验证码',
					type: 'error',
					offset: '100'
				});
				// return this.$message.error('请输入验证码')
			}
			this.$axios({
				method: "POST",
				url: "/api/officialWebsite/submit_mobile",
				data: {
					telphone: this.form.tel,
					coursebase_id: this.form.kecheng,
					code: this.form.code
				},
			}).then((res) => {
				if (res.code == 1 || res.code == 200) {
					this.dialogVisible = false;
					this.$notify({
						title: '成功',
						message: res.msg || res.message,
						type: 'success',
						position: 'top-left'
					});
				} else {
					this.$notify.error({
						title: '提示',
						message: res.msg || res.message,
						position: 'top-left'
					});
				}
			}).catch((error) => {
				console.log(error);
			});
		},
		getCode() {
			// return;
			let time = 60;
			if (this.form.timer) return

			this.form.timer = setInterval(() => {
				if (time > 0) {
					time--;
					this.form.send = time;
				} else {
					clearInterval(this.form.timer);
					this.form.timer = null;
					this.form.send = "获取验证码";
				}
			}, 1000)

			this.$axios({
				method: "POST",
				url: "/api/officialWebsite/send_code",
				data: {
					tel: this.form.tel,
					type: 1
				}
			}).then((res) => {
				if (res.code == 1 || res.code == 200) {

				} else {
					this.$message({
						message: res.msg,
						type: 'error',
						offset: '100'
					});
					// this.$message.error(res.msg)
					setTimeout(() => {
						clearInterval(this.form.timer);
						this.form.timer = null;
						this.form.send = "获取验证码";
					}, 3000)
				}
			}).catch((error) => {
				console.log(error);
			});
		},
		getData() {
			//获取课程下拉列表
			this.$axios({
				method: "POST",
				url: "/api/officialWebsite/course_selection_package_list"
			}).then((res) => {
				if (res.code == 1 || res.code == 200) {
					this.form.kechengOption = res.data;
				} else {
					this.$message.error(res.msg)
				}
			}).catch((error) => {
				console.log(error);
			});
		},
		go_next() {
			// console.log('课程：' + this.form.kecheng + '手机号:' + this.form.tel)
			if (!this.form.kecheng) {
				return this.$message({
					message: '请选择课程',
					type: 'error',
					offset: '100'
				});
			}
			if (!this.form.tel) {
				return this.$message({
					message: '请输入手机号',
					type: 'error',
					offset: '100'
				});
				// return this.$message.error('请输入手机号')
			} else if (this.form.tel.length != 11) {
				return this.$message({
					message: '请输入正确的手机号',
					type: 'error',
					offset: '100'
				});
				// return this.$message.error('请输入正确的手机号')
			}
			this.getCode();
			this.form.next = true;
		},
		handleSelect(key, keyPath) {
			this.activeIndex = key;
		},
		//判断导航选中状态
		checkPath() {
			this.currentPath = this.$route.path;
			console.log('当前路由：', this.currentPath)
			this.dh = this.currentPath
			if (this.currentPath == '/Index/') {
				this.currentPath = '/Index'
			}
			if (this.currentPath == '/kechengDetail') {
				this.currentPath = '/Kecheng'
			}
			this.activeIndex = this.currentPath;
		},
		click_tab(path, type) {
			console.log(path, type, "路由地址");
			if (path === "/Jianzhizhongxin") {
				window.open(this.wqtUrl, "_blank")
				return
			}
			// path:路由   type: 是否是新页面打开
			if (type) {
				let href = this.$router.resolve({
					path
				})
				window.open(href.href, '_blank')
			} else {
				this.$router.push(path);
				Bus.$emit('scrollToTop')
			}
		},
		goLogin() {
			// return;
			if (this.$store.state.userInfo) { //去我的页面
				// location.href = "/index/center/index.html";
				this.$router.push('/personalCenter')

			} else { //去登录页面
				// location.href = "/index/login/login_pass.html?from=" + window.location.href;
				// this.$router.push('/Login');
				this.login_visible = true
			}
		},

		// 鼠标移入头像
		onmouseover_img() {
			// console.log('onmouseover_img');
			this.isMyCenter = this.myCenter_router.includes(this.$route.path)
		},
		onmouseout_image() {
			this.is_pull_down = false
			setTimeout(() => {
				if (this.is_pull_down) {
					return
				}
				this.isMyCenter = false
			}, 1700)
		},
		onmouseover_pull_down() {
			this.isMyCenter = true
			this.is_pull_down = true
		},
		// 鼠标移出dialog
		onmouseout_img() {
			// console.log('onmouseout_img');
			this.isMyCenter = false
		},
		// 账户设置
		account_setting_btn() {
			this.$router.push('/personalCenter/userinfo')
		},

		// 退出登录
		signOut() {
			this.loading = true
			this.$axios({
				method: 'POST',
				url: 'api/officialWebsite/computer/yuan_cheng/out_login'
			}).then(res => {
				console.log(res);
				// this.loading = false
				if (res.data === 0) {
					localStorage.removeItem("token")
					localStorage.removeItem("userInfo")
					this.$store.commit('updateUserInfo', null)
					this.$router.push('/Index')
					// location.reload();
				} else {
					this.$notify.error({
						title: '提示',
						message: `${res.msg}`
					});
				}
				this.loading = false
			})
		}
	}
}
</script>

<style scoped>
.yanzhengma_get {
	width: 116px;
	height: 60px;
	background: #074DFE;
	border-radius: 10px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 60px;
	text-align: center;
}

.next_span {
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	margin: 0 0 30px;
}

.yanzhengmaBox {
	display: flex;
	justify-content: space-between;
}

.yanzhengma_input {
	width: calc(100% - 126px) !important;
}

.el-menu.el-menu--horizontal {
	border: none !important;
	/* border: 1px solid red; */
}

.headerBox {
	position: fixed;
	z-index: 10001;
	width: calc(100vw - 17px);
	background: #FFFFFF;
	display: flex;
	box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
}

.daohang {
	height: 70px;
	display: flex;
	width: 1200px;
	justify-content: space-between !important;
	align-items: center;
}

ul#nav1 {
	height: 60px;
	background: #FFFFFF;
	margin: 0 auto;
}

ul#nav1 li {
	display: inline;
	height: 60px
}

ul#nav1 li a {
	display: inline-block;
	padding: 0 24px;
	height: 60px;
	line-height: 60px;
	color: #333333;
	font-family: "MicrosoftYaHei";
	font-size: 18px;
}

ul#nav1 li a:hover {
	color: #FFFFFF;
	border-radius: 18px;
	background-color: #3C71FF;
	width: 92px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	padding: 0;
}

ul.daohang li {
	display: inline;
	height: 60px;
}

ul.daohang li a {
	display: inline-block;
	padding: 0 10px;
	height: 60px;
	line-height: 60px;
	color: #000000;
	font-family: "MicrosoftYaHei";
	font-size: 16px;
}

ul.daohang li a:hover {
	color: #074DFE
}

/*设置鼠标滑过或悬停时变化的背景颜色*/
/* ul#nav li a:active{color:#074DFE} */

.el-menu {
	margin: 0 auto !important;
	/* padding-left: 300px !important; */
}

.el-menu-item {
	font-size: 16px !important;
}

.login_inpt {
	border: none !important;
	border-bottom: 1px solid #E2E2E2 !important;
	background-color: #fff !important;
}

.tishi {
	font-size: 14rpx;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #F83039;
	line-height: 30rpx;
	text-align: left;
	margin: 28px 0 20px 0;
	height: 20px;
}

.touxiang {
	width: 36px;
	height: 36px;
	border-radius: 50%;
}

.headeaItem:hover {
	color: #DC0D17 !important;
	border-bottom: 2px solid #DC0D17 !important;
	/* margin-right: 30px !important; */
}

.headerImg {
	width: 94px;
	object-fit: cover;
	position: relative;
	top: -3px;
}

.pull_down_uesrinfo {
	position: absolute;
	right: -100px;
	top: 50px;
	width: 250px;
	height: 301px;
	padding: 33px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 0 0 15px 15px;
	background-color: #FFFFFF;
}

.pull_down_uesrinfo_top {
	display: flex;
	align-items: center;
}

.pull_down_uesrinfo_top_img {
	width: 44px;
	height: 44px;
	margin-right: 10px;
}

.pull_down_uesrinfo_top_img img {
	border-radius: 22px;
}

.pull_down_uesrinfo_middle_flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 15px 0;
}

.pull_down_uesrinfo_middle_num {
	width: 30px;
	height: 22px;
	color: #FFFFFF;
	text-align: center;
	border-radius: 11px 11px 11px 11px;
	background: rgba(0, 89, 252, 1);
}

.pull_down_uesrinfo_middle_numB {
	width: 40px;
	height: 22px;
	text-align: center;
	color: #000000;
}

.underline {
	padding-bottom: 20px;
	border-bottom: 1px solid #E7E7E7;
}</style>
