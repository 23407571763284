<template>
	<div class="emptyPage">
		<!-- 开发中空页面 -->
		<img :src="ossUrl + 'emptyImg.png'" class="emptyImg">
		<div class="text">程序员小哥哥正在努力开发中...</div>
	</div>
</template>

<script>
	export default {
		name: 'emptyPage',
		data() {
			return {
				ossUrl: process.env.VUE_APP_OSS_URL,

			}
		},
		created() {
			
		},
		methods: {
			
		},
		components: {
			
		}

	}
</script>

<style scoped>
	.emptyPage {
		text-align: center;
		margin: 200px auto;
	}
	.emptyImg {
		width: 441px;
		height: 242px;
	}
	.text {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
		margin: 50px 0 0 0;
	}
</style>
