<template>
	<!-- 底部栏 -->
	<div style="filter: grayscale(1);background-color: #1E2021;width: 100%;margin: 0 auto;box-shadow: 0 2px 9px 0 rgba(0,0,0,0.08)"
		:style="{padding:havSuspension?'padding: 56px 0 150px;':'padding: 56px 0 30px;',filter:$hasGray?'grayscale(1);':''}">
		<div style="display: flex;justify-content: space-between;width: 1200px;margin: auto;margin-bottom: 10px;">
			<!-- 第一列 -->
			<div style="text-align: left;word-break:keep-all;white-space:nowrap;">
				<!-- <div style="width: 153px;height: 51px;margin-bottom: 32px;margin-top: 20px;margin-left: -12px;">
					<img src="../../assets/image/svgImg/logo_top.svg" style="width: 100%;">
				</div> -->

				<div style="color: #FFFFFF;font-size: 16px;padding-bottom: 34px;">！</div>
				<!-- background-color: #074DFE; -->
				<div style="width: 24px;height: 1px;border-radius: 1px;margin-bottom: 32px;display: none;">
				</div>
				<div @click="beianhao(1)" style="color: #999999;font-size: 14px;line-height: 22px; cursor: pointer">
					{{currentHref.indexOf('ycjyzs.com') >= 0 ? '鲁ICP备2022030689号' : (currentHref.indexOf('yuanchengjy.com') >= 0 ? '琼ICP备2021005734号-2' : '鲁ICP备2022030689号')}}
				</div>
				<!-- <div @click="beianhao(2)" v-if="!(currentHref.indexOf('ycjyzs.com') >= 0 || currentHref.indexOf('yuanchengjy.com') >= 0)"
					style="color: #999999;font-size: 14px;line-height: 22px; cursor: pointer; margin-top: 9px">
					<img src="../../assets/image/gongan.png" style="margin-right: 5px;">
					鲁公网安备37011202001551号
				</div> -->
				<div style="color: #999999;font-size: 14px; margin-top: 13px">Copyright © {{tYear}}</div>
				<div style="color: #999999;font-size: 14px; margin-top: 2px">{{currentHref.indexOf('yuanchengjy.com') >= 0 ? '海南精研学堂教育科技有限公司版权所有' : '精研学堂教育科技(山东)有限公司版权所有'}}</div>
				<!-- <div style="font-size: 14px; margin-top:13px"><a  style="color: #999999;" href="https://image.wqtong.net/api/upload/mini/2022/07/13/sUIybvEV6DCO4JA6CPOkQW97k6dpIRQjwPnkoQau.jpg" target="_blank">增值电信业务经营许可证： 鲁B2-20220271</a></div> -->
				<!-- <div style="font-size: 14px; margin-top:13px"><a style="color: #999999;"  href="https://image.wqtong.net/api/upload/mini/2022/07/13/2FBOTUPxUcwyUDRTZ6MQal9DXCfPOn27ye8PGiaZ.jpg" target="_blank">网络文化经营许可证-鲁网文-（2022）0484-005号</a></div> -->

			</div>
			<!-- 第二列 -->
			<div style="text-align: left;word-break:keep-all;white-space:nowrap;">
				<div style="color: #FFFFFF;font-size: 14px;padding-bottom: 52px;padding-top: 20px;">
					快捷导航</div>
				<div style="color: #999999;font-size: 14px;padding-bottom: 16px; cursor: pointer;"
					@click="click_tab('/Index')">
					官网首页
				</div>
				<div style="color: #999999;font-size: 14px;padding-bottom: 16px; cursor: pointer;"
					@click="click_tab('/Kecheng')">
					精品课程
				</div>
				<!-- <div style="color: #999999;font-size: 14px;padding-bottom: 16px; cursor: pointer;"
					@click="click_tab('/Dangzhibu', true)">
					党政学习
				</div> -->
				<!-- <div style="color: #999999;font-size: 14px;padding-bottom: 16px; cursor: pointer;"
					@click="click_tab('/Jianzhizhongxin', true)">
					兼职中心
				</div> -->
				<div @click="goXIeYi('/index/index/yszc.html')"
					style="color: #999999;font-size: 14px;padding-bottom: 16px; cursor: pointer;">
					隐私政策
				</div>
				<div @click="goXIeYi('/index/index/fwxy.html')"
					style="color: #999999;font-size: 14px;padding-bottom: 16px; cursor: pointer;">
					用户协议
				</div>
				<div style="color: #999999;font-size: 14px; cursor: pointer;" @click="click_tab('/enter', true)">
					关于我们
				</div>

			</div>
			<!-- 第三列 -->
			<div style="text-align: left;word-break:keep-all;white-space:nowrap;">
				<div style="color: #FFFFFF;font-size: 14px;padding-bottom: 52px;padding-top: 20px;">联系我们</div>
				<div style="color: #FFFFFF;font-size: 14px;font-weight: bold;padding-bottom: 8px;display: flex;">
					<div style="width: 18px;height: 18px;padding-right: 6px;">
						<!-- <img style="width: 100%;height: auto; margin-bottom: 6px"
							src="https://static.shanchuangjiaoyu.com/ycpc/assets/phone@2x.png"> -->
					</div>
					<!-- 400-0872229 -->
				</div>
				<!-- <div style="color: #999999;font-size: 14px;padding-bottom: 24px;padding-left: 18px;">官方电话</div> -->
				<!-- <div style="color: #999999;font-size: 14px;padding-bottom: 16px;"></div> -->
				<div style="color: #FFFFFF;font-size: 14px;padding-bottom: 8px;font-weight: bold;display: flex;">
					<div style="width: 18px;height: 18px;padding-right: 6px;"><img
							style="width: 100%;height: auto; margin-bottom: 6px"
							src="https://static.shanchuangjiaoyu.com/ycpc/assets/time@2x.png"></div>周一至周六：9:00-18:00
				</div>
				<div style="color: #999999;font-size: 14px;padding-left: 18px;">工作时间</div>
			</div>
			<!-- 第四列 -->
			<div style="text-align: left;">
				<div style="color: #FFFFFF;font-size: 14px;padding-bottom: 52px;padding-top: 20px;">关注我们</div>
				<div style="display: flex;justify-content: flex-start;">
					<!-- <div style="padding-right: 45px;">
						<div style="width: 126px;height: 126px;margin-bottom: 12px;">
							<img style="background-color: #FFFFFF;padding: 4px;border-radius: 6px;width: 100%;height: 100%;"
								src="https://static.shanchuangjiaoyu.com/ycpc/img/gongzonghao_erweima.svg">
						</div>
						<div style="color: #FFFFFF;font-size: 12px;text-align: center;">精研学堂服务号</div>
					</div> -->

					<!-- <div style="">
						<div style="  width: 126px;height: 126px;margin-bottom: 12px;">
							<img style="background-color: #FFFFFF;padding: 4px;border-radius: 6px;width: 100%;height: 100%;"
								src="../../assets/image/xiaochengxu_erweima.svg">
						</div>
						<div style="color: #FFFFFF;font-size: 12px;text-align: center;">精研学堂小程序</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	export default {
		name: 'BottomTab',
		props: ['havSuspension'],
		data() {
			return {
				tYear: null, //版权的年份
				currentPath: "",
				currentHref: ''
			}

		},
		created() {
			let tiem = new Date()
			var tYear = tiem.getFullYear();
			this.tYear = tYear
		},
		mounted() {
			this.currentPath = this.$route.path;
			this.currentHref = window.location.href;
			console.log('this.currentHref:', this.currentHref)
		},
		methods: {
			click_tab(path, type) {
				if (type) {
					let href = this.$router.resolve({
						path
					})
					window.open(href.href, '_blank')
				} else {
					this.$router.push(path);
					Bus.$emit('scrollToTop')
				}
			},
			beianhao(type) {
				if (type == 1) {
					window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
				} else {
					window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001551', '_blank');
				}
			},

			goXIeYi(url) {
				// location.href = url;
				window.open(url)
			}
		}
	}
</script>

<style scoped>
	.daohang {
		height: 70px;
		background: #FFFFFF;
		display: flex;
		width: 1200px;
		justify-content: space-between;
		align-items: center;
	}

	ul#nav1 {
		height: 60px;
		background: #FFFFFF;
		margin: 0 auto;
	}

	ul#nav1 li {
		display: inline;
		height: 60px
	}

	ul#nav1 li a {
		display: inline-block;
		padding: 0 24px;
		height: 60px;
		line-height: 60px;
		color: #333333;
		font-family: "MicrosoftYaHei";
		font-size: 18px;
	}

	ul#nav1 li a:hover {
		color: #FFFFFF;
		border-radius: 18px;
		background-color: #3C71FF;
		width: 92px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		padding: 0;
	}

	ul.daohang li {
		display: inline;
		height: 60px;
	}

	ul.daohang li a {
		display: inline-block;
		padding: 0 10px;
		height: 60px;
		line-height: 60px;
		color: #000000;
		font-family: "MicrosoftYaHei";
		font-size: 16px;
	}

	ul.daohang li a:hover {
		color: #074DFE
	}

	/*设置鼠标滑过或悬停时变化的背景颜色*/
	/* ul#nav li a:active{color:#074DFE} */

	.el-menu {
		margin: 0 auto !important;
		/* padding-left: 300px !important; */
	}

	.el-menu-item {
		font-size: 16px !important;
	}

	.login_inpt {
		border: none !important;
		border-bottom: 1px solid #E2E2E2 !important;
		background-color: #fff !important;
	}

	.tishi {
		font-size: 14rpx;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F83039;
		line-height: 30rpx;
		text-align: left;
		margin: 28px 0 20px 0;
		height: 20px;
	}

	.touxiang {
		width: 34px;
		height: 33px;
		border-radius: 50%;
	}
</style>
