<template>
  <div>
    <div
      id="container"
      style="position: relative; background: rgb(252, 249, 242)"
    ></div>
    <el-collapse-transition>
      <div id="imgBox" :class="imgB">
        <img
          src="https://static.shanchuangjiaoyu.com/ycpc/shipin/wqhz.jpg"
          class="cooperationBg"
        />
      </div>
    </el-collapse-transition>
    <div
      class="open"
      v-if="display"
      :style="display"
      @mouseleave="hover_h"
      @mouseover="hoverimg"
      @click="jinru"
    >
      <img :src="b_bg" alt="" srcset="" />
    </div>
    <div class="input-card">
      <div @click="click_diqu">
        <img
          style="
            width: 16px;
            margin-right: 10px;
            margin-top: -10px;
            transform: translateY(-4px);
          "
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/weizhi.png"
          alt=""
        />
        <span
          class="quyu quyu1"
          :title="diquValue"
          :class="diquValue == '全国' ? '' : 'active'"
          >{{ diquValue }}</span
        >
        <img
          style="
            width: 10px;
            position: relative;
            top: -7px;
            left: 3px;
            cursor: pointer;
          "
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/jiantou.png"
          alt=""
        />
      </div>
      <div class="weizhibox" @click="click_gongsi" style="top: 1px; left: -5px">
        <span class="quyu active">{{ query.stypeName }}</span>
        <img
          style="
            width: 10px;
            position: relative;
            top: -7px;
            left: 3px;
            cursor: pointer;
          "
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/jiantou.png"
          alt=""
        />
      </div>
      <div id="gongsiBox" style="" v-show="isShow.gongsi">
        <div
          class="gongsiBox_item"
          :class="query.stypeName == '职位' ? 'active' : ''"
          @click="click_gongsi_list('职位')"
        >
          职位
        </div>
        <div
          class="gongsiBox_item"
          :class="query.stypeName == '公司' ? 'active' : ''"
          @click="click_gongsi_list('公司')"
        >
          公司
        </div>
      </div>
    </div>

    <!-- 地区 -->
    <div class="diquBox" v-if="isShow.diqu">
      <div class="zhixiashi">
        <div class="diquTitle">热门城市</div>
        <div style="margin-top: 15px; margin-left: 20px">
          <span class="chengshi" @click="bindhotcity('全国', '0', 0)"
            >全国</span
          >
          <span class="chengshi" @click="bindhotcity('济南', '370100', 2)"
            >济南</span
          >
          <span class="chengshi" @click="bindhotcity('上海', '310100', 1)"
            >上海</span
          >
          <span class="chengshi" @click="bindhotcity('深圳', '440300', 2)"
            >深圳</span
          >
          <span class="chengshi" @click="bindhotcity('北京', '110100', 1)"
            >北京</span
          >
          <span class="chengshi" @click="bindhotcity('天津', '120100', 1)"
            >天津</span
          >
          <span class="chengshi" @click="bindhotcity('广州', '440100', 2)"
            >广州</span
          >
        </div>
        <div class="diquTitle" style="margin-top: 15px">国内城市</div>
      </div>
      <!-- 地区编号信息 -->
      <div v-for="(item, index) in arealist" :key="index">
        <!-- <div>{{item.area_name}}</div> -->
        <div class="firstNub">{{ index }}</div>
        <div
          class="diQuContent"
          v-for="(item2, index_sheng) in item"
          :key="index_sheng"
        >
          <span class="chengshiName" @click="bindareacity(item2)"
            >{{ item2.name }}：</span
          >
          <div class="shiName">
            <span
              v-for="(item3, index_sub) in item2.subList"
              :key="index_sub"
              @click="bindareacity(item3)"
              class="chengshiLeft"
              >{{ item3.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="adbg"></div>
    <div class="card-bottom">
      <div class="titleBox">
        <span v-if="query.stype == 2"
          >可视区域内为您找到
          <span class="active">{{ total }}</span> 职位信息</span
        >
        <span v-else
          >可视区域内为您找到
          <span class="active">{{ total }}</span> 家合作企业</span
        >
        <img
          @click="list_show"
          style="width: 12px; height: 6px; cursor: pointer"
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/jiantouBig.png"
          alt=""
        />
      </div>
      <div v-show="isShow.list" class="Boxlist" v-infinite-scroll="load">
        <div v-if="query.stype == 2" class="">
          <a
            v-for="(item, index) in regionList"
            :key="index"
            class="list"
            @click="bindnavigateTo(item.id, item.company_id)"
          >
            <div class="tupian">
              <img v-if="item.company" :src="item.company.pic" class="carImg" />
              <img v-else :src="item.company" class="carImg" />
            </div>
            <div class="list_right">
              <div style="display: flex; justify-content: space-between">
                <div class="titleName" style="line-height: 42px">
                  {{ item.job_title }}
                </div>
                <div style="font-size: 26px; color: #ff0606">
                  {{ item.min_salary }}-{{ item.max_salary }}K
                </div>
              </div>

              <div
                class="list_content"
                style="position: relative; top: -5px; padding: 0"
              >
                <span
                  v-for="(itemTag, indexTag) in item.label"
                  :key="indexTag"
                  style="
                    background-color: #f7f7f7;
                    pad: 1px 4px;
                    border-radius: 5px;
                    color: #999999;
                    font-size: 12px;
                    margin-right: 15px;
                  "
                  >{{ itemTag }}</span
                >
              </div>
              <div class="list_content" style="padding: 0">
                <div
                  style="
                    width: calc(100% - 105px);
                    display: flex;
                    align-items: center;
                  "
                  class=""
                >
                  <div v-if="item.company">
                    {{ item.company.company_name }}
                  </div>
                  <div class="line">|</div>
                  <div v-text="item.scale"></div>
                </div>
                <!-- <div style="display: flex;">
                <div
                  style=""
                  class="nowrap"
                  v-for="(itemad, indexad) in item.province_city_district"
                  :key="indexad"
                >
                  {{ itemad.title }}
                </div>
                </div> -->
              </div>
            </div>
          </a>
        </div>
        <div v-else>
          <a v-for="(item, index) in lists" :key="index" class="list">
            <div class="tupian" @click="bindnavigateTo(item.id)">
              <img :src="item.pic" class="carImg" />
            </div>
            <div class="list_right">
              <div class="titleName" @click="bindnavigateTo(item.id)">
                {{ item.company_name }}
              </div>
              <div class="list_content" @click="bindnavigateTo(item.id)">
                <span>{{ item.industry_type }}</span
                ><span class="line" v-if="item.industry_type">|</span>
                <span v-if="item.scale == 1">0-20人</span>
                <span v-if="item.scale == 2">21-99人</span>
                <span v-if="item.scale == 3">100-499人</span>
                <span v-if="item.scale == 4">500-999人</span>
                <span v-if="item.scale == 5">999+人</span>
              </div>
              <div class="list_content" style="position: relative">
                <!-- {{ item.dizhi_name + item.address }} -->
                <viewer v-if="item.statement != ''">
                  <el-image
                    v-for="(img, index) in item.statement"
                    :z-index="10087"
                    :key="index"
                    style="
                      width: 100px;
                      height: 25px;
                      position: absolute;
                      right: 73px;
                      opacity: 0;
                      cursor: pointer;
                    "
                    :src="img"
                    :preview-src-list="item.statement"
                  >
                  </el-image>
                </viewer>

                <span v-if="item.province_city_district[1]">{{
                  item.province_city_district[1].title
                }}</span
                ><span class="line"> | </span>
                <span
                  v-if="item.statement != ''"
                  @click="ckly"
                  class="ckly"
                  style="color: #2083ff"
                  >查看录用声明</span
                >
              </div>
            </div>
          </a>

          <div class="list_more" v-if="jinzh" style="color:#999;font-size:14px"><i class="el-icon-loading"></i>加载中...</div>
        </div>
      </div>
    </div>

    <div class="input-card2">
      <div
        style="height: 23px; position: relative; top: 3px"
        @click="click_xinzi"
      >
        <span
          id="xinziTitle"
          :class="query.xinziValue ? 'active' : ''"
          class="quyu"
          >薪资</span
        >
        <img
          style="width: 10px; position: relative; top: -3px; cursor: pointer"
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/jiantou.png"
          alt=""
        />
      </div>
      <div id="gongsiguimo" class="weizhibox" @click="click_guimo">
        <span
          id="guimoTitle"
          class="quyu"
          :class="
            query.guimoValue || (query.scale_start && query.scale_end)
              ? 'active'
              : ''
          "
          >公司规模</span
        >
        <img
          style="width: 10px; position: relative; top: -3px; cursor: pointer"
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/jiantou.png"
          alt=""
        />
      </div>
      <div id="gongsixingzhi" class="weizhibox" @click="click_xingzhi">
        <span
          id="xinziTitle"
          class="quyu"
          :class="query.xingzhiValue ? 'active' : ''"
          >性质</span
        >
        <img
          style="width: 10px; position: relative; top: -3px; cursor: pointer"
          src="https://www.shanchuangjiaoyu.com/static/home/addpage/img/jiantou.png"
          alt=""
        />
      </div>
      <!-- <div id="qingkong" class="weizhibox" @click="click_qingkong">
        <span id="xinziTitle" class="quyu">清空筛选</span>
      </div> -->
    </div>

    <div id="xinziBox" v-show="isShow.xinzi">
      <div style="text-align: left">薪资</div>
      <div class="checkBoxFlex">
        <el-radio-group
          class="xinzi_checkbox"
          @change="xxz"
          v-model="query.xinziValue"
        >
          <el-radio
            v-for="(item, index) in xinziList"
            :key="index"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </el-radio-group>
        <div class="salary_range" v-if="this.query.xinziValue == 5">
          <div class="salary_range_input">
            <el-input v-model="starpay" placeholder="" size="small" clearable>
              <template slot="prepend">最低薪资</template>
            </el-input>
            <!-- <div class="lines"></div> -->
            <el-input v-model="endpay" placeholder="" size="small" clearable>
              <template slot="prepend">最高薪资</template>
            </el-input>
          </div>
          <p class="salary_range_tip">薪资以k为单位, 1000=1k,请输入整数。</p>
        </div>
      </div>
      <!-- <div>
				<input type="text" class="input"> -- <input type="text" class="input">
				<span class="xinzi_danwei">（K）</span>
			</div> -->
      <div class="xinzi_bottom">
        <span id="chongzhi" class="xinzi_btn" @click="reset_xinzi">重置</span>
        <div>
          <span
            id="quxiao"
            class="xinzi_btn"
            style="margin-right: 45px"
            @click="cancel_xinzi"
            >取消</span
          >
          <span id="queding" class="xinzi_btn active" @click="submit_xinzi"
            >确定</span
          >
        </div>
      </div>
    </div>

    <div id="guimo" v-show="isShow.guimo">
      <div style="text-align: left">公司规模（人）</div>
      <div class="checkBoxFlex">
        <el-radio-group
          class="xinzi_checkbox"
          v-model="query.guimoValue"
          @change="changeRadio"
        >
          <el-radio
            v-for="(item, index) in guimoList"
            :key="index"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </el-radio-group>
        <div class="salary_range" v-if="this.query.guimoValue == 6">
          <div class="salary_range_input">
            <el-input v-model="staren" placeholder="" size="small" clearable>
            </el-input>
            <p>-</p>
            <!-- <div class="lines"></div> -->
            <el-input v-model="endren" placeholder="" size="small" clearable>
            </el-input>
          </div>
          <p class="salary_range_tip">以个为单位,请输入整数。</p>
        </div>
      </div>
      <!-- <div>
        <el-input
          v-model="query.scale_start"
          class="input"
          @input="changeInputScale"
        ></el-input>
        --
        <el-input
          v-model="query.scale_end"
          type="text"
          class="input"
          @input="changeInputScale"
        ></el-input>
        <span class="xinzi_danwei">（人）</span>
      </div> -->
      <div class="xinzi_bottom">
        <span id="chongzhi" class="xinzi_btn" @click="reset_guimo">重置</span>
        <div>
          <span
            id="quxiao"
            class="xinzi_btn"
            style="margin-right: 45px"
            @click="cancel_guimo"
            >取消</span
          >
          <span id="queding" class="xinzi_btn active" @click="submit_guimo"
            >确定</span
          >
        </div>
      </div>
    </div>

    <div id="xinzi" v-show="isShow.xingzhi">
      <div style="text-align: left">公司性质</div>
      <div class="checkBoxFlex">
        <el-radio-group class="xinzi_checkbox" v-model="query.xingzhiValue">
          <el-radio
            v-for="(item, index) in xingzhiList"
            :key="index"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="xinzi_bottom">
        <span id="chongzhi" class="xinzi_btn" @click="reset_xingzhi">重置</span>
        <div>
          <span
            id="quxiao"
            class="xinzi_btn"
            style="margin-right: 45px"
            @click="cancel_xingzhi"
            >取消</span
          >
          <span id="queding" class="xinzi_btn active" @click="submit_xingzhi"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapContainer",
  data() {
    return {
      arr:[],
      jinzh:true,
      page:1,
      pingyi: "",
      loading: "",
      imgB: "imgBox",
      citys: "",
      display: true,
      b_bg: require("../../assets/image/svgImg/open.svg"),
      map: null,
      min_salary: "",
      max_salary: "",
      markers: [],
      zoom: 5, //縮放级别   <7 全国，7-10 省，>10市
      diquValue: "全国", //地区站位
      arealist: {},
      isShow: {
        diqu: false, //展示地区
        gongsi: false, //展示公司 职位
        list: true, //展示公司 职位列表
        xinzi: false, //展示薪资
        guimo: false, //展示公司规模
        xingzhi: false, //展示公司性质
      },
      query: {
        level: 0, //地图显示的缩放级别，对应的数字
        province_id: 0, //省ID
        city_id: 0, //市ID
        // regionCode: 0,
        stypeName: "公司", //公司 职位
        stype: 1, //公司 职位
        xinziValue: "", //薪资
        guimoValue: 0, //公司规模
        // scale_start: '', //公司规模开始
        // scale_end: '', //公司规模结束
        xingzhiValue: 0, //公司性质
        starpay: "", //最低薪资
        endpay: "", //最高薪资
      },
      lists: [],
      postLists: [],
      total: "",
      totals: 0,
      xinziList: [
        {
          value: 0,
          label: "无条件",
        },
        {
          value: 1,
          label: "3K以下",
        },
        {
          value: 2,
          label: "3-5K",
        },
        {
          value: 3,
          label: "5-10K",
        },
        {
          value: 4,
          label: "10K以上",
        },
        {
          value: 5,
          label: "自定义",
        },
      ],

      guimoList: [
        {
          value: 1,
          label: "0-20人",
        },
        {
          value: 2,
          label: "21-99人",
        },
        {
          value: 3,
          label: "100-499人",
        },
        {
          value: 4,
          label: "500-999人",
        },
        {
          value: 5,
          label: "999+人",
        },
        //   {
        //   value: 6,
        //   label: "自定义",
        // },
      ],

      xingzhiList: [
        {
          value: 1,
          label: "集团",
        },
        {
          value: 2,
          label: "个人",
        },
        {
          value: 3,
          label: "国有",
        },
        {
          value: 4,
          label: "外资",
        },
        {
          value: 5,
          label: "有限公司",
        },
      ],
      starpay: null, //最低薪资
      endpay: null, //最高薪资
      // isareaid: fasle,   //数据是否请求
      regionList: [],
    };
  },
  created() {
    this.getAreaList();
    // this.getgongsilist();
    // this.getgslist();
    // this.getCompanyList();
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
    // this.map.setZoom(5);
  },
  methods: {
    load(){
    if (this.jinzh) {
					this.page++
					//触底触发的操作，
					//如是数据列表，则查数据，追加在列表上
					//如其他情况自行变动即可
					this.getgslist()
				}
    },
    inited(viewer) {
      this.$viewer = viewer;
      this.$viewer.index = this.activeIndex;
      // 不要他的按钮
      this.$viewer.options.button = false;
      // 不要他的底部缩略图
      this.$viewer.options.navbar = false;
      // 不要他的底部标题
      this.$viewer.options.title = false;
      // 不要他的底部工具栏
      this.$viewer.options.toolbar = false;
      this.show();
    },
    show() {
      this.$viewer.show();
    },
    ckly() {},
    //滑入事件
    hoverimg() {
      this.b_bg = require("../../assets/image/svgImg/open_h.svg");
    },
    hover_h() {
      this.b_bg = require("../../assets/image/svgImg/open.svg");
    },
    jinru() {
      this.display = !this.display;
      this.imgB = "imgBox hover";
    },
    //点击地区
    click_diqu() {
      this.isShow = {
        diqu: !this.isShow.diqu,
        gongsi: false,
        list: false,
        xinzi: false,
        guimo: false,
        xingzhi: false,
      };
    },
    //点击热点城市
    bindhotcity(value, city_id, level) {
      var _this = this;
      this.diquValue = value;
      this.query.province_id = 0;
      this.query.city_id = city_id;
      this.query.level = level;
      this.isShow.diqu = false;
      var geocoder = new T.Geocoder({
        city: "全国", //城市设为北京，默认：“全国”
        radius: 1000, //范围，默认：500
      });
      console.log(value);

      if (level == 0) {
        this.query.city_id = "";
        _this.map.setZoom(5);
        this.zoom = 5;
      } else {
        geocoder.getLocation(value, function (status, result) {
          _this.map.setZoomAndCenter(8, [
            result.geocodes[0].location.lng,
            result.geocodes[0].location.lat,
          ]);
        });
      }
      this.zoom = 8;
      this.bindst();
    },
    //点击省市，城市编号信息
    bindareacity(item) {
      let _this = this;
      this.diquValue = item.name;
      this.query.level = item.level;

      this.query.city_id = item.code;
      console.log(item.level);

      this.isShow.diqu = false;
      if (item.level == 0) {
        this.map.setZoomAndCenter(8, [item.lng, item.lat]);
        this.zoom = 8;
      }
      if (item.level == 1 || item.level == 2) {
        this.map.setZoomAndCenter(11, [item.lng, item.lat]);
        this.zoom = 11;
      }

      this.bindst();
    },
    //点击公司 职位
    click_gongsi() {
      this.isShow = {
        diqu: false,
        gongsi: !this.isShow.gongsi,
        list: false,
        xinzi: false,
        guimo: false,
        xingzhi: false,
      };
    },
    click_gongsi_list(value) {
      if (value === "公司") {
        this.query.stype = 1;
        this.query.stypeName = "公司";
        // this.bindst();
      }
      if (value === "职位") {
        this.query.stype = 2;
        this.query.stypeName = "职位";
        // this.bindst();
      }

      this.isShow.gongsi = false;
      //   this.bindstore();
    },
    //列表展示的事件
    list_show() {
      this.isShow = {
        diqu: false,
        gongsi: false,
        list: !this.isShow.list,
        xinzi: false,
        guimo: false,
        xingzhi: false,
      };
    },
    click_xinzi() {
      this.isShow = {
        diqu: false,
        gongsi: false,
        list: this.isShow.list,
        xinzi: !this.isShow.xinzi,
        guimo: false,
        xingzhi: false,
      };
    },
    click_guimo() {
      this.isShow = {
        diqu: false,
        gongsi: false,
        list: this.isShow.list,
        xinzi: false,
        guimo: !this.isShow.guimo,
        xingzhi: false,
      };
    },
    click_xingzhi() {
      this.isShow = {
        diqu: false,
        gongsi: false,
        list: this.isShow.list,
        xinzi: false,
        guimo: false,
        xingzhi: !this.isShow.xingzhi,
      };
    },
    xxz() {
      this.min_salary = "";
      this.max_salary = "";
    },
    //提交薪资
    submit_xinzi() {
      // console.log(this.query.xinziValue, "薪资");
      // console.log(this.starpay);
      // console.log(this.endpay);
      if (this.query.xinziValue == 1) {
        this.max_salary = 3;
      }
      if (this.query.xinziValue == 2) {
        this.min_salary = 3;
        this.max_salary = 5;
      }
      if (this.query.xinziValue == 3) {
        this.min_salary = 5;
        this.max_salary = 10;
      }
      if (this.query.xinziValue == 4) {
        this.min_salary = 10;
      }
      if (this.starpay) {
        this.min_salary = this.starpay / 1000;
      }
      if (this.endpay) {
        this.max_salary = this.endpay / 1000;
      }
      if (this.query.xinziValue) {
        this.isShow.xinzi = true;
        this.bindst();
      } else {
        this.$message.error("请选择薪资");
      }
    },
    //重置薪资
    reset_xinzi() {
      this.query.xinziValue = "";
      this.min_salary = "";
      this.max_salary = "";
      this.starpay = "";
      this.endpay = "";
      this.bindst();
    },
    //取消薪资
    cancel_xinzi() {
      this.isShow.xinzi = false;
    },
    changeInputScale() {
      this.query.guimoValue = 0;
    },
    changeRadio() {
      this.query.scale_start = "";
      this.query.scale_end = "";
    },
    //提交公司规模
    submit_guimo() {
      if (
        this.query.guimoValue ||
        (this.query.scale_start && this.query.scale_end)
      ) {
        this.isShow.guimo = false;
        this.bindst();
      } else {
        this.$message.error("请选择公司规模或输入完整的公司人数范围");
      }
    },
    //重置公司规模
    reset_guimo() {
      this.query.guimoValue = 0;
      this.query.scale_start = "";
      this.query.scale_end = "";
      this.bindst();
    },
    //取消公司规模
    cancel_guimo() {
      this.isShow.guimo = false;
    },
    //提交性质
    submit_xingzhi() {
      if (this.query.xingzhiValue || this.query.xingzhiValue == "0") {
        this.isShow.xingzhi = false;
        this.bindst();
      } else {
        this.$message.error("请选择公司性质");
      }
    },
    //重置性质
    reset_xingzhi() {
      this.query.xingzhiValue = "";
      this.bindst();
    },
    //取消性质
    cancel_xingzhi() {
      this.isShow.xingzhi = false;
    },
    //清空筛选
    click_qingkong() {
      this.isShow.xinzi = false;
      this.isShow.guimo = false;
      this.isShow.xingzhi = false;
      this.query.xinziValue = "";
      this.query.guimoValue = 0;
      this.query.scale_start = "";
      this.query.scale_end = "";
      this.query.xingzhiValue = "";
      this.starpay = "";
      this.endpay = "";
      this.bindst();
    },

    initMap() {
      let that = this;
      that.map = new T.Map("container");
      // that.map.setMaxBounds(new T.LngLatBounds(new T.LngLat(116.27648,39.84042), new T.LngLat(116.4605,39.9721)));
        that.map.centerAndZoom(new T.LngLat(103.83179, 36.06242), 5);
      //  this.map.addControl(new T.Scale())
      //   this.map.addControl(new T.ToolBar({
      //      position:'RT'
      //   }))
      //   that.map.on("zoomchange",function(){
      //         // that.mapZoomend();
      //         })
      //鼠标滚轮
      that.map.addEventListener("zoomend", function (e) {
        that.mapZoomend()});
      var bounds = that.map.getBounds();
      // that.map.setLimitBounds(bounds);
      //鼠标拖拽
      that.map.addEventListener("dragend", function () {
        that.mapDragend();
      });
    },
    //地图移动事件
    mapDragend(e) {
      var that = this;
      var center = that.map.getCenter();
      var geocoder = new T.Geocoder({
        city: "全国", //城市设为北京，默认：“全国”
        radius: 1000, //范围，默认：500
      });
      var _zoom = that.zoom;
      console.log("滚轮缩放等级_zoom：" + that.zoom);
      // console.log('地图中心点center：' + center)
      geocoder.getLocation(new T.LngLat(center.lng, center.lat), function (result) {
        if (result) {
          if (_zoom <= 7) {
            that.query.city_id = 0;
            //  that.bindstore();
            that.pingyi = 1;
          } else if (_zoom > 7 && _zoom < 11) {
            // console.log("在第二级省内拖动，获取省的数据")
            that.pingyi = 1;
          that.query.city_id = result.addressComponent.province_code.slice(3);
            // that.bindstore();
          } else if (_zoom >= 11 && _zoom < 13) {
                that.query.city_id=result.addressComponent.city_code.slice(3)
             console.log(that.query.city_id)
             if(that.query.city_id === '110000'){
               that.query.city_id = "110100";
             }
             if(that.query.city_id === '310000'){
               that.query.city_id = "310100";
              }
               if(that.query.city_id === '120000'){
               that.query.city_id = "120100";
              }
               if(that.query.city_id === '500000'){
               that.query.city_id = "500100";
              }
          } else {
             that.query.city_id = result.addressComponent.county_code.slice(3);

            that.bindstore();
          }
        }
      });
    },
    //获取地区数据
    getAreaList() {
      this.$axios({
        method: "get",
        url: "/api/admin/getwebarea",
        data: {},
      })
        .then((res) => {
          //   console.log(res, 999);
          if (res.code == 200) {
            this.arealist = res.data;
            // console.log(this.arealist, 888);
          }
        })
        .catch((error) => {
          console.log(error, 999);
        });
    },
    //获取地区列表
    getgslist() {
      let params = {
        type: 1,
        list_rows: 50,
        page:this.page
        // count_type:1,
        // address_id:1,
      };

      if (this.query.guimoValue) {
        params.scale_id = this.query.guimoValue;
      }
      if (this.starpay) {
        params.min_salary = this.starpay;
      }
      if (this.endpay) {
        params.max_salary = this.endpay;
      }
      if (this.query.xingzhiValue) {
        params.nature_id = this.query.xingzhiValue;
      }
      if (this.query.city_id != 0) {
        params.address_id = Number(this.query.city_id);
      }
      if (this.min_salary) {
        params.min_salary = this.min_salary;
      }
      if (this.max_salary) {
        params.max_salary = this.max_salary;
      }
      this.$axios({
        method: "get",
        url: "/api/officialWebsite/talent_center/get_company_list",
        params,
      })
        .then((res) => {
          // console.log(res, 20031);
          let arr = [];
          let arr1 = [];
          if (res.code == 200) {
            res.data.data.forEach((e) => {
              return (e.statement = [e.statement]);
            });
            // this.lists = res.data.data;
            res.data.data.forEach((e) => {
              if (e.statement != "") {
                arr.push(e);
              } else {
                arr1.push(e);
              }
            });
            arr1.sort(function () {
              return Math.random() - 0.5;
            });
            arr.sort(function () {
              return Math.random() - 0.5;
            });
            var arr3=[...arr, ...arr1];
            this.arr.push(...arr3)
            this.lists = this.arr
             if (res.data.data.length <50) {
							this.jinzh = false
						}
         
              this.total = res.data.total;
            
        

            this.getgongsilist();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //公司数量
    getgongsilist() {
      var that = this;
      let params = {
        type: this.query.stype,
        count_type: this.query.stype,
        // address_id:1,
      };
      if (this.query.guimoValue) {
        params.scale_id = this.query.guimoValue;
      }
      if (this.starpay) {
        params.min_salary = this.starpay;
      }
      if (this.endpay) {
        params.max_salary = this.endpay;
      }
      if (this.query.xingzhiValue) {
        params.nature_id = this.query.xingzhiValue;
      }
      if (this.query.city_id) {
        params.pid = Number(this.query.city_id);
      } else {
        params.pid = 0;
      }
      if (this.min_salary) {
        params.min_salary = this.min_salary;
      }
      if (this.max_salary) {
        params.max_salary = this.max_salary;
      }
      if (this.query.city_id) {
        params.pid = this.query.city_id;
      }

      this.$axios({
        method: "get",
        url: "/api/officialWebsite/talent_center/get_company_count",
        params,
      })
        .then((res) => {
          if (res.code == 200) {
            // this.total = res.data.total;
            // this.regionList=res.data
            // console.log(this.regionList, "公司数量");
            if (res.data[0]) {
              this.citys = res.data[0].code;
            }
            this.addMarker(res.data);
            // that.loading.close()
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyList() {
      let params = {
        type: 2,
        list_rows: 50,
        page: 0,
      };

      if (this.query.guimoValue) {
        params.scale_id = this.query.guimoValue;
      }
      if (this.starpay) {
        params.min_salary = this.starpay;
      }
      if (this.endpay) {
        params.max_salary = this.endpay;
      }
      if (this.query.xingzhiValue) {
        params.nature_id = this.query.xingzhiValue;
      }
      if (this.query.city_id) {
        params.address_id = Number(this.query.city_id);
      }
      if (this.min_salary) {
        params.min_salary = this.min_salary;
      }
      if (this.max_salary) {
        params.max_salary = this.max_salary;
      }

      this.$axios({
        method: "get",
        url: "/api/officialWebsite/talent_center/get_company_list",
        params,
      })
        .then((res) => {
          if (res.code == 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              res.data.data[i].min_salary = parseInt(
                res.data.data[i].min_salary
              );
              res.data.data[i].max_salary = parseInt(
                res.data.data[i].max_salary
              );
            }
            this.total = res.data.total;
            this.regionList = res.data.data;
            // this.lists = res.data.data;
            // console.log(this.regionList, "公司数量");
            // this.getgongsilist();
            this.addMarker(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //页面渲染：获取数据,添加标记
    bindstore() {},
    bindst() {
      this.isShow.list = true;
      var _this = this;
      _this.page = 1
				_this.lists = []
				_this.arr = []
				_this.jinzh = true
      if (_this.pingyi != 1) {
        if (this.markers.length > 0) {
          this.removeMarkers();
        }

        if (this.query.stype == 1) {
          _this.getgslist();
        } else {
          _this.getCompanyList();
        }
      }
    },
    //地图滚轮
    mapZoomend() {
      console.log("老杜")
      var that = this;
      that.pingyi = "";
      //获取当前地图级别
      var _zoom = that.map.getZoom();
      var center = that.map.getCenter();

      // 逆地址解析:坐标=>地址
      var geocoder = new T.Geocoder();
      console.log("滚轮缩放等级_zoom：" + that.map.getZoom());
      // console.log('地图中心点center：' + center)

      geocoder.getLocation(new T.LngLat(center.lng, center.lat), function (result) {
        console.log(result)
        if (result) {
          // let adcode = result.regeocode.addressComponent.adcode;
          //4-6省，7-10//市，10-14街道
          if (_zoom < 7) {
            that.diquValue = "全国";
          }
          if (_zoom <7) {
            //缩放到第一级，获取全国的数据
            that.query.city_id = 0;
            that.bindstore();
          } else if (_zoom >= 7 && _zoom <= 10) {
                that.query.city_id = result.addressComponent.province_code.slice(3);
              
  
          } else if (_zoom >= 11 && _zoom < 13) {
            
             that.query.city_id=result.addressComponent.city_code.slice(3)
             console.log(that.query.city_id)
             if(that.query.city_id === '110000'){
               that.query.city_id = "110100";
             }
             if(that.query.city_id === '310000'){
               that.query.city_id = "310100";
              }
               if(that.query.city_id === '120000'){
               that.query.city_id = "120100";
              }
               if(that.query.city_id === '500000'){
               that.query.city_id = "500100";
              }
              
            // if (result.regeocode.addressComponent.city) {
             
            //   geocoder.getLocation(
            //     result.regeocode.addressComponent.city,
            //     function (status, result) {
            //       that.query.city_id = result.geocodes[0].adcode;
            //     }
            //   );
            // } else {
            //   if (result.regeocode.addressComponent.province == "北京市") {
            //     console.log("北京");
            //     that.query.city_id = "110100";
            //   } else if (
            //     result.regeocode.addressComponent.province == "上海市"
            //   ) {
            //     that.query.city_id = "310100";
            //   } else if (
            //     result.regeocode.addressComponent.province == "天津市"
            //   ) {
            //     that.query.city_id = "120100";
            //   } else if (
            //     result.regeocode.addressComponent.province == "重庆市"
            //   ) {
            //     that.query.city_id = "500100";
            //   } else if (result.regeocode.addressComponent.adcode == "469023") {
            //     that.query.city_id = "469023";
            //   } else {
            //     that.query.city_id = that.citys;
            //   }
            // }
          } else {
            that.query.city_id = result.addressComponent.county_code.slice(3);

            that.bindstore();
          }
          that.zoom = _zoom;
        }
      });
    },
    // 点标记的创建与添加
    addMarker(positions) {
      var that = this,
        show_name = "家",
        level = that.level;
      // console.log(that.level, "1010101");
      if (this.markers.length > 0) {
        that.removeMarkers();
      }
      if ((this.zoom > 12 && this.query.stype == 1) || positions == "") {
        for (let item = 0; item < this.lists.length; item++) {
          if (this.lists[item].scale == 1) {
            var scale = "0-20人";
          }
          if (this.lists[item].scale == 2) {
            var scale = "21-99人";
          }
          if (this.lists[item].scale == 3) {
            var scale = "100-499人";
          }
          if (this.lists[item].scale == 4) {
            var scale = "500-999人";
          }
          if (this.lists[item].scale == 5) {
            var scale = "999+人";
          }

          let marker = new T.Label({
            map: that.map,
            position:new T.LngLat(this.lists[item]["longitude"],this.lists[item]["latitude"]) ,
            text:`
        <div style="text-align: center">
        <div class="gongsi_marker_box">
       <img src="${this.lists[item].pic}" alt="" style="width:60px!important" class="gongsi_fengmian_img">
       <div class="marker_text"><p class="m-title">${this.lists[item].company_name}</p><p style="display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;font-size: 13px;">${this.lists[item].industry_type}<span style="color:#ccc;padding:0 16px;">|</span>${scale}</p></div>
       </div>
       <p style="
      width: 0;
    height: 0;
    border-left: 10px solid transparent;

    border-top: 10px solid #2365fa;
    position: absolute;
    left: 124px;
    right: 0;
    bottom: -23px;"></p>
       <div class="bdian" style="width: 10px;
    height: 10px;
    background: #2365fa;
    border-radius: 50%;
    position: absolute;
        left: 130px;
    bottom: -40px;"></div>
       </div>`, // 自定义点标记覆盖物内容
            offset: new T.Point(-155, -195),
          });
          //添加标记的点击事件
            marker.addEventListener("click", function (e) {
            that.markerClick(e);
            console.log(e, "这个是什么");
          });
          that.map.addOverLay(marker)
          that.markers.push(marker);
        }
      } else if (this.zoom > 11 && this.query.stype == 2) {
        for (let item = 0; item < this.regionList.length; item++) {
          if (this.regionList[item].company.scale == 1) {
            var scale = "0-20人";
          }
          if (this.regionList[item].company.scale == 2) {
            var scale = "21-99人";
          }
          if (this.regionList[item].company.scale == 3) {
            var scale = "100-499人";
          }
          if (this.regionList[item].company.scale == 4) {
            var scale = "500-999人";
          }
          if (this.regionList[item].company.scale == 5) {
            var scale = "999+人";
          }

          let marker = new T.Label({
            map: that.map,
            position:new T.LngLat( 
              this.regionList[item].company["longitude"],
              this.regionList[item].company["latitude"])
            ,
            text:  `
        <div style="text-align: center">
        <div class="gongsi_marker_box">
       <img src="${this.regionList[item].company.pic}" alt="" style="width:60px!important" class="gongsi_fengmian_img">
       <div class="marker_text"><p class="m-title">${this.regionList[item].company.company_name}</p><p style="display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;font-size: 13px;">${this.regionList[item].company.industry_type}<span style="color:#ccc;padding:0 16px;">|</span>${scale}</p></div>
       </div>
       <p style="
      width: 0;
    height: 0;
    border-left: 10px solid transparent;

    border-top: 10px solid #2365fa;
    position: absolute;
    left: 124px;
    right: 0;
    bottom: 60px;"></p>
       <div class="bdian" style="width: 10px;
    height: 10px;
    background: #2365fa;
    border-radius: 50%;
    position: absolute;
    left:130px;bottom: 40px;"></div>
       </div>`, // 自定义点标记覆盖物内容
            offset: new T.Point(-155, -195),
          });
          //添加标记的点击事件
         marker.addEventListener("click", function (e) {
            that.markerClick(e);
          });

          that.markers.push(marker);
        }
      } else {
        for (let item = 0; item < positions.length; item++) {
          if (positions[item].count > 0) {
            var markerName = positions[item].name;
            var markerNum = positions[item].count;
            let marker = new T.Label({
                text:     `<div class="marker">
						<div class="markerName" style="font-size: 12px;color: #FFFFFF;text-align: center;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">` +
              markerName +
              `</div><div class="markerNub" style="font-size: 15px;font-weight: bold;color: #FFFFFF;text-align: center;">` +
              markerNum +
              show_name +
              `</div></div>`,
                position:new T.LngLat(positions[item]["lng"], positions[item]["lat"]),
                offset: new T.Point(-70, -50)
            });
                        that.map.addOverLay(marker)
            //添加标记的点击事件
            marker.addEventListener("click", function (e) {
              that.markerClick(e);
              console.log(e, "这个是什么");
            });
            // //添加标记的鼠标移入事件
            // marker.addEventListener("mouseover", function (e) {
            //   let contentMarker = e.target.getContent();
            //   contentMarker = contentMarker.replace(
            //     "marker",
            //     "marker markerMouseover"
            //   );
            //   e.target.setContent(contentMarker);
            // });
            // //添加标记的鼠标移出事件
            // marker.addEventListener("mouseout", function (e) {
            //   let contentMarker = e.target.getContent();
            //   contentMarker = contentMarker.replace("markerMouseover", "");
            //   e.target.setContent(contentMarker);
            // });
            that.markers.push(marker);
           
          }
        }
      }
    },

    //删除标记
    removeMarkers() {
      this.map.clearOverLays()

      this.markers = [];
    },

    // 监听Marker的点击事件
    markerClick(e) {
      console.log(e)
      this.pingyi = "";
      var that = this;
      var _zoom = that.map.getZoom();
      let clickLng = parseFloat(e.lnglat.lng);
      let clickLat = parseFloat(e.lnglat.lat);
      that.diquValue = "全国";
      var geocoder = new T.Geocoder()

      geocoder.getLocation(new T.LngLat(clickLng, clickLat), function (result) {
        if (result) {
          if (_zoom < 7) {

            that.query.city_id = result.addressComponent.province_code.slice(3);
            
            that.zoom = 7;
            // that.bindstore();
          } else if (_zoom >= 7 && _zoom < 11) {
            that.query.city_id=result.addressComponent.city_code.slice(3)
            that.zoom = 11;
          } else if (_zoom >= 11 && _zoom < 13) {
            that.query.city_id = result.addressComponent.county_code.slice(3);
            that.zoom = 13;
          } else {
            that.zoom++;
          }
          that.map.centerAndZoom(new T.LngLat(clickLng, clickLat), that.zoom);
        } else {
          alert("地图逆向解析失败，请联系开发人员");
        }
      });
    },
    //跳转公司详情
    bindnavigateTo(id, company_id) {
      let tz = this.$router.resolve({
        path: "/gongsiDetail",
        query: {
          id: id,
          type: this.query.stype,
          company_id,
        },
      });
      window.open(tz.href, "_blank");
    },
  },
  watch: {
    "query.city_id": function (e) {
      this.bindst();
    },
    "query.stype": function (e) {
      this.bindst();
    },
  },
};
</script>

<style scoped>
.list_more{text-align:center;}
#container {
  /* padding:0px;
        margin: 0px;
        width: 100%;
        height: 800px; */
  width: 100%;
  height: 100vh;
  z-index: 0;
}
.salary_range {
  width: 400px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.salary_range_input {
  /* width: 300px; */
  display: flex;
  flex-flow: row;
  align-items: center;
}
.salary_range_tip {
  font-size: 14px;
  color: #b3b0b0;
  transform: scale(0.8);
}

.lines {
  width: 30px;
  height: 2px;
  background-color: #666666;
}
.input-card {
  position: absolute;
  top: 82px;
  left: 24px;
  z-index: 1;
  display: flex;
  padding: 15px 0 15px 20px;
  align-items: center;
}

.input-card2 img {
  top: -8px !important;
  left: 3px;
}
.quyu {
  color: #333333;
  position: relative;
  top: -2px;
  cursor: pointer;
}
.quyu1 {
  display: inline-block;
  width: 42px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 15px;
  top: 2px;
}
.weizhibox {
  margin-left: 20px;
  height: 23px;
  position: relative;
  top: 3px;
}

.active {
  color: #ff7c06 !important;
}

#gongsiBox {
  background-color: #fff;
  position: absolute;
  top: 75px;
  right: 0;
  border-radius: 5px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);
  padding: 10px 60px 0 25px;
  z-index: 4;
}

.gongsiBox_item {
  font-weight: 400;
  line-height: 61px;
  cursor: pointer;
}

.card-bottom {
  background-color: #ffffff;
  position: absolute;
  top: 160px;
  left: 24px;
  border-radius: 5px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);

  width: 375px;
}
.Boxlist {
  overflow-y: scroll;
  max-height: calc(100vh - 285px);
  padding: 8px;
  padding-top: 0;
}
.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;
}

.list {
  display: flex;
  padding: 20px 0;
  border-top: 1px solid #f5f5f5;
  cursor: pointer;
}

.tupian {
  width: 86px;
  height: 86px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);
  border-radius: 5px;
  margin-right: 15px;
}

.carImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.list_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 101px);
}

.titleName {
  color: #333333;
  font-size: 17px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  font-weight: bold;
}

.list_content {
  color: #333333;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0;
}

.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line {
  color: #ddd;
  margin: 0 5px 0 8px;
}

.input-card2 {
  position: absolute;
  top: 81px;
  left: 195px;
  display: flex;
  padding: 15px 30px 15px 0px;
}

#xinziBox {
  background-color: #ffffff;
  position: absolute;
  top: 160px;
  left: 22%;
  border-radius: 5px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);
  padding: 25px 30px 35px 30px;
  min-width: 440px;
}

.checkBoxFlex {
  border-bottom: 2px solid #f2f2f2;
  margin-bottom: 30px;
  align-items: center;
}

.xinzi_checkbox {
  margin: 20px 50px 25px 0;
}

.input {
  width: 136px;
  height: 40px;
  /* border: 2px solid #E2E3E5; */
  border-radius: 3px;
  /* padding: 0 10px; */
}

.xinzi_danwei {
  font-size: 18px;
  font-weight: 400;
  color: #666666;
}

.xinzi_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.xinzi_btn {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

#guimo {
  background-color: #fff;
  position: absolute;
  top: 160px;
  left: 22%;
  border-radius: 5px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);
  padding: 25px 30px 35px 30px;
  min-width: 440px;
}

.checkBoxFlex {
  border-bottom: 2px solid #f2f2f2;
  margin-bottom: 30px;
}

.xinzi_checkbox {
  margin: 20px 20px 25px 0;
}

#xinzi {
  background-color: #ffffff;
  position: absolute;
  top: 160px;
  left: 22%;
  border-radius: 5px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);
  padding: 25px 30px 35px 30px;
  min-width: 440px;
}

.diquBox {
  background-color: #fff;
  position: absolute;
  top: 150px;
  left: 25px;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
  width: 28%;
  max-width: 374px;
  max-height: calc(100vh - 230px);
  overflow: auto;
  z-index: 3;
}

.zhixiashi {
  background-color: #fff;
  padding: 25px 5px 15px;
  text-align: left;
}

.diquTitle {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
}

.chengshi {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 35px;
  margin-right: 18px;
  cursor: pointer;
}

.firstNub {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 25px;
  padding: 0 25px;
  text-align: left;
}

.diQuContent {
  padding: 0 15px 0 25px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
}

.chengshiName {
  width: 60px;
  line-height: 35px;
  font-size: 14px;
  cursor: pointer;
}

.shiName {
  width: calc(100% - 60px);
}

.chengshiLeft {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 35px;
  margin-left: 18px;
  cursor: pointer;
  min-width: 65px;
  display: inline-block;
}

.markerName {
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}

.markerNub {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  /* margin-top: 8px; */
}

.gongsi_fengmian_img {
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.marker_text {
  font-size: 14px;
  color: #333;
  line-height: 28px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  height: 66px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
<style type="text/css">
.tdt-label{border: none !important;background: transparent;box-shadow: none;z-index: 1;}
.tdt-label:hover{z-index: 2;}
.marker {
  background-color: #2083ff;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
  padding: 15px;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
  flex-direction: column;
  transition: 0.3s;
  cursor: pointer;
  z-index: 1;
}

.marker:hover {
  background-color: rgba(251, 28, 90, 0.85);
  transform: scale(1.1);
  z-index: 2;
}

.T-logo,
.T-copyright {
  display: none !important;
}
/deep/ .el-input-group--prepend .el-input__inner {
  width: 70px;
}
.gongsi_marker_box {
  background-color: #2365fa;
  display: flex;
  position: relative;
  /* width: 265px; */
  height: 65px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -3px 4px 8px 0px #7da0ef;
  top: 15px;
  left: 10px;
  padding-right: 10px;
}
.gongsi_fengmian_img {
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.marker_text {
  font-size: 14px;
  color: #fff;
  line-height: 28px;
  padding-left: 10px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  height: 66px;

  text-align: left;
}
.marker_text .m-title {
  font-size: 17px;
  font-weight: 600;
}
@keyframes ext {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -100px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.imgBox {
  position: absolute;
  top: 70px;
  width: 100%;
  z-index: 151;
  padding: 0;

  /* background-color: #0067ED; */
}
.imgBox.hover {
  animation: ext 1.5s ease forwards;
}

.cooperationBg {
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  display: block;
  margin: 0;
  object-fit: cover;
}

.open img {
  position: absolute;
  width: 140px;
  bottom: 10px;
  left: 50%;
  margin-left: -80px;
  z-index: 152;
  cursor: pointer;
}
.adbg {
  background-color: #ffffff;
  position: absolute;
  top: 80px;
  height: 55px;
  left: 24px;
  border-radius: 5px;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 10%);
  width: 375px;
}
.ckly {
  padding: 3px 5px;
}
.el-image-viewer__wrapper .el-image-viewer__btn {
  display: flex !important;
}
/* .T-marker{transition: .3s;} */
.T-marker:hover {
  z-index: 1000 !important;
}
.T-toolbar {
  right: 50px !important;
}
</style>
