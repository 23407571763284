<template>
	<div class="index">
		<div style="height: 70px;" id="goTop">
			<Header></Header>
		</div>
		<!-- 研究院详情 -->
		<div style="background-color: #F5F5F5;padding: 50px 0 70px">
			<div class="yanjiuyuan_content">
				<div class="yanjiuyuan_shouye">您的位置：<span class="pointer"
						:style="tabIndex == 1 ? 'color: #FF7C06' : '' " @click="goIndex" @mousemove="tabIndex = 1"
						@mouseout="tabIndex=null">首页</span> >
					<span :style="tabIndex == 2 ? 'color: #FF7C06' : '' " class="pointer" @mousemove="tabIndex = 2"
						@click="goYanjiuyuan" @mouseout="tabIndex=null">资讯中心</span> >
					<span v-if="type==2" :style="tabIndex == 3 ? 'color: #FF7C06' : '' " class="pointer"
						@mousemove="tabIndex = 3" @mouseout="tabIndex=null" @click="goList">研究院动态 > </span>
					<span class="yanjiuyuan_link">{{type == 1 ? '活动详情' : '资讯详情'}}</span>
				</div>
				<div class="dongtai_detail_title" v-if="detailData">
					{{type == 2 ? detailData.title : detailData.title}}
				</div>
				<div class="dongtai_detail_time" v-if="detailData">
					{{type == 2 ? detailData.release_time : detailData.creat_time}}
				</div>
			</div>
		</div>
		<div style="padding: 60px 0;min-height: 300px;" v-if="detailData"  v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
			<div class="yanjiuyuan_content">
				<div class="dongtai_detail_content detailImg"
					v-html="type == 2 ? detailData.content : detailData.content" v-if="detailData"></div>

				<!-- 上一篇 -->
				<div class="dongtai_detail_link" v-if="type == 2">
					<div class="dongtai_detail_link_item link_left" :class="{activeLink: currentMouse == 'left'}"
						@mouseenter="currentMouse = 'left'" @mouseleave="currentMouse = null"
						@click="dongtaiDetail(detailData.previous.id)">
						<i class="el-icon-back"></i>
						<span class="dongtai_detail_link_item_content">{{detailData.previous.title || '没有上一篇啦'}}</span>
					</div>
					<div class="dongtai_detail_link_item link_right" :class="{activeLink: currentMouse == 'right'}"
						@mouseenter="currentMouse = 'right'" @mouseleave="currentMouse = null"
						@click="dongtaiDetail(detailData.next_article.id)">
						<span class="dongtai_detail_link_item_content">{{detailData.next_article.title || '没有下一篇啦'}}</span>
						<i class="el-icon-right"></i>
					</div>
				</div>
				<div v-else class="dongtai_detail_link">
					<div class="dongtai_detail_link_item link_left" :class="{activeLink: currentMouse == 'left'}"
						@mouseenter="currentMouse = 'left'" @mouseleave="currentMouse = null"
						@click="dongtaiDetail(upPageData.id)">
						<i class="el-icon-back"></i>
						<span class="dongtai_detail_link_item_content">{{upPageData.title || '没有上一篇啦'}}</span>
					</div>
					<div class="dongtai_detail_link_item link_right" :class="{activeLink: currentMouse == 'right'}"
						@mouseenter="currentMouse = 'right'" @mouseleave="currentMouse = null"
						@click="dongtaiDetail(nextPageData.id)">
						<span class="dongtai_detail_link_item_content">{{nextPageData.title || '没有下一篇啦'}}</span>
						<i class="el-icon-right"></i>
					</div>
				</div>

			</div>
		</div>

		<BottomTab v-if="detailData"></BottomTab>
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	import Header from '../components/Header.vue'
	import BottomTab from '../components/BottomTab.vue'
	import {
		huoDonngJianYingData
	} from '../../assets/js/yanjiuyuanData.js'
	export default {
		name: 'detail_yanjiuyuan',
		data() {
			return {
				id: '',
				type: '',
				currentMouse: null,
				detailData: null,
				tabIndex: '',
				dataList: [],
				huoDonngJianYingData,
				isStateData: '', //1  是接口返回的数据   2  不是接口返回的数据
				upPageData: {},
				nextPageData: {},
				loading: true,
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.type = this.$route.query.type;
			if (this.type == 1) {
				this.dataList = this.huoDonngJianYingData;
				this.getDetailByList();
			} else {
				this.getDetail();
			}
			
		},
		methods: {
			// 资讯中心详情
			getDetail() {
				this.loading = true;
				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/business_cooperation/get_information_detail",
					data: {
						id: this.id
					}
				}).then(res => {
					this.detailData = res.data
					this.loading = false;
				}).catch((error) => {
					console.log(error)
				});
			},
			//活动剪影
			getDetailByList() {
				this.loading = true;
				this.dataList.forEach((item, index) => {
					if (this.id == item.id) {
						this.detailData = item;
						if (index == 0) {
							this.upPageData = this.dataList[this.dataList.length - 1];
						} else {
							this.upPageData = this.dataList[index - 1];
						}
						if (index == this.dataList.length - 1) {
							this.nextPageData = this.dataList[0];
						} else {
							this.nextPageData = this.dataList[index + 1];
						}
					}
					this.loading = false;
				})
			},
			dongtaiDetail(id) {
				if (id) {
					this.id = id;
					
					let targetUrl = this.$common.getPath(this.$route.path) + '?type=' + this.type + '&id=' + this.id;
					window.history.pushState({}, '', targetUrl);
					
					if (this.type == 1) {
						this.getDetailByList();
					} else {
						this.getDetail();
					}
					 document.querySelector('#goTop').scrollIntoView(true);
				}
			},
			goYanjiuyuan() {
				this.$router.push('/Yanjiuyuan')
			},
			goIndex() {
				this.$router.push('/Index')
				Bus.$emit('scrollToTop')
			},
			goList(){
				this.$router.push('/yanjiuyuanList')
			}

		},
		components: {
			Header,
			BottomTab
		}

	}
</script>

<style scoped lang="scss">
  :deep(div .ql-video){   
	width: 100%;
    height: 675px;
    margin: 0 auto;
    display: block;}
	.yanjiuyuan_content {
		width: 1200px;
		margin: 0 auto;
	}

	.yanjiuyuan_shouye {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 13px;
	}


	.dongtai_detail_title {
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 33px;
		margin: 80px 0 30px;
		text-align: center;
	}

	.dongtai_detail_time {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		text-align: center;
	}

	.yanjiuyuan_link {
		color: #FF7C06;
	}

	.yanjiuyuan_content_jianjie {
		display: flex;
		justify-content: space-between;
		margin: 70px 0 0 0;
	}

	.yanjiuyuan_content_shipin {
		width: 584px;
		height: 313px;
	}

	.yanjiuyuan_content_jianjie_right {
		width: 528px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		cursor: pointer;
	}

	.yanjiuyuan_content_jianjie_right_title {
		font-size: 26px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 16px;
	}

	.yanjiuyuan_content_jianjie_right_title2 {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #666666;
		line-height: 16px;
		margin-left: 12px;
	}

	.yanjiuyuan_content_jianjieBox {
		margin: 0 0 40px 0;
	}


	.dongtai_detail_link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 100px 0 0 0;
		cursor: pointer;
	}

	.dongtai_detail_link_item {
		width: 585px;
		height: 127px;
		background: #F8F8F8;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 127px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.link_left i {
		margin: 0 20px 0 0;
		font-size: 26px;
	}

	.link_right i {
		margin: 0 0 0 20px;
		font-size: 26px;
	}

	.dongtai_detail_link_item_content {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 470px;
		display: inline-block;
	}

	.activeLink {
		background: #FF7C06;
		color: #fff;
	}
	
</style>
<style type="text/css">
	.detailImg img {
		width: 100%;
		margin: 20px 0;
	}
</style>
