<template>
  <div>
    <div class="tabs">
      <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'>
        <!-- <div  @click="tabsClick(index)" style="width:100px; height:50px;">{{ item.tabs_title }}</div> -->
      </myTabs>
    </div>
    <div class="tabs_info" v-loading="loading" >
      <childTabs v-if="tabs_active === 0" v-cloak>
        <div v-for="(item, index) in childTabsList" :key="index" @click="childTabsClick(index)" class="childTabs_box">
          <div  class="childTabs_slot"
            :class="{ childTabsText: index === child_tabs_active }">{{ item.child_tabs_title }}</div>
          <div :class="{ active: index === child_tabs_active }" class="childTabs_bottom"></div>
        </div>
      </childTabs>

      <!-- 主体 -->
      <div class="myContent_data">

        <!-- 正式课 -->
        <div v-if="tabs_active === 0" v-cloak>
          <!-- 当前班级 -->
          <div v-show="child_tabs_active === 0" class="current_banji_all_Info" v-for="(item,index) in courseData" :key="item.id">
            <div class="current_banji">
              <!-- 班级序号 -->
              <div class="current_banji_num">{{ index + 1 < 10 ? '0' : '' }}{{  index + 1 }}</div>
              <!-- 班级信息 -->
              <div class="current_banji_info">
                <div class="current_banji_name">{{ item.name }}</div>
                <div class="current_banji_issue">{{ item.code }}期</div>
              </div>
              <!-- 班级操作/选择 -->
              <div class="current_banji_opt">
                <!-- <button  class="current_banji_classBtn" :class="{ kebiao: index === kebiao_index && classmatId == item.id}" @click="banji_classBtn(index,item.id)">
                  课程表</button> -->
                  <button  class="current_banji_classBtn" :class="{ kebiao: index === kebiao_index && classmatId == item.id}" @click="banji_classBtn(index,item.id)">
                  公开课</button>
                <button class="current_banji_dbBtn" :class="{ dbBtn: index === db_index }" @click="banji_dbBtn(index,item.id)">点播课
                </button>
              </div>
            </div>
            <!-- 当前班级课表 -->
            <!-- 这里到时候要判断是否显示 -->
            <div class="current_banji_kebiao_box" v-if="index === kebiao_index && classmatId == item.id">
            <!-- 进度 -->
            <div class="progress">
              <el-progress :text-inside="true" :stroke-width="26" :percentage="Number(myCIntegral)"></el-progress>
              <div @click="get_course_xuefen(item.id,item.percentages)" style="width: 94px;height: 46px;border-radius: 23px;background: rgba(255,255,255,1); text-align: center; line-height: 46px;cursor: pointer;">领学分</div>
            </div>
              <div class="current_banji_kebiao" v-for="(val, index) in officialClassData" :key="index">
                <div class="current_banji_kebiao_left">
                  <div class="current_banji_kebiao_left_top">
                    {{ val.dname }}
                  </div>
                  <div class="current_banji_kebiao_left_bottom">
                    {{ $common.format(val.starttm, 1) }} ~ {{ format(val.endtm) }}
                  </div>
                </div>
                <!-- 正在直播中 -->
                 <div class="formal_onLive_css" v-if="val.live_status === 2" @click="toLive(val, 'zhengShiKe')">
                  <onLive_css></onLive_css>
                  <div style="margin-top: 10px; color: #797979;" >直播中</div>
                 </div>
                 <div v-if="val.live_status === 4">
                  <div style="margin-top: 10px; color: #797979;margin-right: 15px;" >未开播</div>
                 </div>

                 <div v-if="val.live_status === 1">
                  <div style="margin-top: 10px; color: #797979;margin-right: 15px;" >即将开播</div>
                 </div>

                 <!-- 已结束 -->
                <div class="current_banji_kebiao_right" style="cursor: pointer;" v-if="val.live_status === 3">

                  <el-popover placement="bottom" width="356" trigger="hover" v-if="!!val.course_materials">
                    <div class="download_sucai_popover">
                      <div class="class_sucai">
                        <div>课堂素材下载链接:</div>
                        <div class="download_sucai_popover_flex">
                          <span type="text" class="download_sucai_popover_text omit_text" >{{ val.course_materials && val.course_materials.material ? val.course_materials.material : '当前没有素材' }}</span>
                          <button v-if="val.course_materials && val.course_materials.material" class="download_sucai_popover_text"  @click="copy(val.course_materials ? val.course_materials.material : '/',val.course_materials ? val.course_materials.material_code : '/')">复制</button>
                        </div>
                        <div style="margin-top: 20px;">提取码:</div>
                        <div class="download_sucai_popover_flex">
                          <span class="download_sucai_popover_text">{{ val.course_materials && val.course_materials.material_code ? val.course_materials.material_code : '/' }}</span>
                          <button v-if="val.course_materials && val.course_materials.material_code" class="download_sucai_popover_text" @click="copy(val.course_materials ? val.course_materials.material_code : '/')">复制</button>
                        </div>
                      </div>
                      <div style="width:100%;height: 1px;background-color: #E7E7E7; margin: 25px 0;"></div>
                      <div class="class_biji">
                        <div>课堂笔记下载链接:</div>
                        <div class="download_sucai_popover_flex">
                          <span class="download_sucai_popover_text omit_text">{{ val.course_materials && val.course_materials.class_notes ? val.course_materials.class_notes : '当前没有素材' }}</span>
                          <button v-if="val.course_materials && val.course_materials.class_notes" class="download_sucai_popover_text" style="margin-left: 20px;" @click="copy(val.course_materials ? val.course_materials.class_notes : '/', val.course_materials ? val.course_materials.class_notes_code : '/')">复制</button>
                        </div>
                        <div style="margin-top: 20px;">提取码:</div>
                        <div class="download_sucai_popover_flex">
                          <span class="download_sucai_popover_text omit_text">{{ val.course_materials && val.course_materials.class_notes_code ? val.course_materials.class_notes_code : '/' }}</span>
                          <button v-if="val.course_materials && val.course_materials.class_notes_code" class="download_sucai_popover_text" style="margin-left: 20px;" @click="copy(val.course_materials ? val.course_materials.class_notes_code : '/')">复制</button>
                        </div>
                      </div>
                    </div>
                    <div class="banji_operate" slot="reference">
                      <div>
                        <img src="../../../../assets/image/形状 3 拷贝 2.png" alt="">
                      </div>
                      <span class="current_banji_kebiao_right_text">下载素材</span>
                    </div>
                  </el-popover>
                  <div v-if="!!val.hashomework && !val.hashomework.hashomereadover.length" class="banji_operate" @click="submit_work(item.classmat_id ,val.hashomework ? val.hashomework.id : '', val.hashomework ? val.hashomework.syllabus_id : '' )">
                    <div>
                      <img src="../../../../assets/image/交作业.png" alt="">
                    </div>
                    <span class="current_banji_kebiao_right_text">交作业</span>
                  </div>
                  <div class="banji_operate" @click="see_replay(val.classmat_id, val.id)">
                    <div>
                      <img src="../../../../assets/image/看回放.png" alt="">
                    </div>
                    <span class="current_banji_kebiao_right_text">看回放</span>
                  </div>

                </div>
              </div>
              <!-- 分页 -->
              <el-pagination
                background
                hide-on-single-page
                layout="prev, pager, next"
                :total="syllabusPage.total"
                :page-size="syllabusPage.list_rows"
                :current-page="syllabusPage.page"
                @current-change="current_change"
              >
              </el-pagination>
            </div>

            <!-- 点播课 -->
            <div v-if="index === db_index " class="current_banji_kebiao_box">
              <div class="click_course_flex">
                <div class="click_course_box" v-for="(val, index) in clickCourseData" :key="index">
                  <img @click="click_course_click(item.id, val.id)" :src="val.photofile" alt="" class="auto-size">
                  <div class="click_course_name">{{ val.name }}</div>
                </div>
              </div>
            </div>
             
          </div>
           <!-- 分页 -->
              <div v-if="child_tabs_active === 0">
                <el-pagination
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  :total="coursePage.total"
                  :page-size="coursePage.list_rows"
                  :current-page="coursePage.page"
                  @current-change="course_current_change"
                >
                </el-pagination>
              </div>
          <no_data v-if="child_tabs_active === 0 && !courseData.length"></no_data>
          <!-- 往期回放 -->
          <div class="past_playback" v-show="child_tabs_active === 1" v-cloak v-for="(item,index) in history_class" :key="item.id" >
            <div class="current_banji">
              <!-- 班级序号 -->
              <div class="current_banji_num">{{ index + 1 < 10 ? '0' : '' }}{{  index + 1 }}</div>
              <!-- 班级信息 -->
              <div class="current_banji_info">
                <div class="current_banji_name">{{ item.classmat.name }}</div>
                <div class="current_banji_issue">{{ item.classmat.code }}期</div>
              </div>
              <div class="history_class_opt">
                <div v-if="index === is_history_class" @click="is_history_class_btn(index)"><i class="el-icon-caret-top" style="font-size: 30px; color: #D7D7D7;cursor: pointer;" ></i></div>
                <div v-else @click="is_history_class_btn(index)"><i  class="el-icon-caret-bottom" style="font-size: 30px; color: #D7D7D7;cursor: pointer;"></i></div>
              </div>
            </div>
              <div class="history_class_box" >
                <div class="current_banji_kebiao" v-for="(val,i) in item.syllabus_many" :key="i" v-show="index === is_history_class">
                  <div class="current_banji_kebiao_left">
                    <div class="current_banji_kebiao_left_top">
                      {{ val.dname }}
                    </div>
                    <div class="current_banji_kebiao_left_bottom">
                      {{ $common.format(val.starttm, 1) }} ~ {{ format(val.promotion_time) }}
                    </div>
                  </div>
                  <!-- <div class="current_banji_kebiao_right">
                    <div v-if="val.startclass === '已结束'">
                      <button class="history_class_btn" @click="work_btn(val.id, val.classmat_id)">作业要求</button>
                      <button class="history_class_btn" @click="see_replay(val.classmat_id, val.id)" style="margin: 0 0 0 15px;">查看回放</button>
                    </div>
                    <div v-if="val.startclass === '未开播'">
                      <button class="history_class_btn">未开播</button>
                    </div>
                    <div v-if="val.startclass === '即将开播'">
                      <button class="history_class_btn">未开播</button>
                    </div>
                    <div v-if="val.startclass === '直播中'">
                      <onLive_css></onLive_css>
                      <div style="margin-top: 10px; color: #797979;" >直播中</div>
                    </div>
                  </div> -->
                  <div class="formal_onLive_css" @click="toLive(val, 'zhengShiKe')" v-if="val.live_status === 2">
                  <onLive_css></onLive_css>
                  <div style="margin-top: 10px; color: #797979;" >直播中</div>
                 </div>
                 <div v-if="val.live_status === 4">
                  <div style="margin-top: 10px; color: #797979;margin-right: 15px;" >未开播</div>
                 </div>

                 <div v-if="val.live_status === 1">
                  <div style="margin-top: 10px; color: #797979;margin-right: 15px;" >即将开播</div>
                 </div>

                 <div class="current_banji_kebiao_right"  style="cursor: pointer;" v-if="val.live_status === 3">

                  <el-popover placement="bottom" width="356" trigger="hover" v-if="!!val.course_materials">
                    <div class="download_sucai_popover">
                      <div class="class_sucai">
                        <div>课堂素材下载链接:</div>
                        <div class="download_sucai_popover_flex">
                          <span type="text" class="download_sucai_popover_text omit_text" >{{ val.course_materials && val.course_materials.material ? val.course_materials.material : '当前没有素材' }}</span>
                          <button v-if="val.course_materials && val.course_materials.material" class="download_sucai_popover_text"  @click="copy(val.course_materials ? val.course_materials.material : '/',val.course_materials ? val.course_materials.material_code : '/')">复制</button>
                        </div>
                        <div style="margin-top: 20px;">提取码:</div>
                        <div class="download_sucai_popover_flex">
                          <span class="download_sucai_popover_text">{{ val.course_materials && val.course_materials.material_code ? val.course_materials.material_code : '/' }}</span>
                          <button v-if="val.course_materials && val.course_materials.material_code" class="download_sucai_popover_text" @click="copy(val.course_materials ? val.course_materials.material_code : '/')">复制</button>
                        </div>
                      </div>
                      <div style="width:100%;height: 1px;background-color: #E7E7E7; margin: 25px 0;"></div>
                      <div class="class_biji">
                        <div>课堂笔记下载链接:</div>
                        <div class="download_sucai_popover_flex">
                          <span class="download_sucai_popover_text omit_text">{{ val.course_materials && val.course_materials.class_notes ? val.course_materials.class_notes : '当前没有素材' }}</span>
                          <button v-if="val.course_materials && val.course_materials.class_notes" class="download_sucai_popover_text" style="margin-left: 20px;" @click="copy(val.course_materials ? val.course_materials.class_notes : '/', val.course_materials ? val.course_materials.class_notes_code : '/')">复制</button>
                        </div>
                        <div style="margin-top: 20px;">提取码:</div>
                        <div class="download_sucai_popover_flex">
                          <span class="download_sucai_popover_text omit_text">{{ val.course_materials && val.course_materials.class_notes_code ? val.course_materials.class_notes_code : '/' }}</span>
                          <button v-if="val.course_materials && val.course_materials.class_notes_code" class="download_sucai_popover_text" style="margin-left: 20px;" @click="copy(val.course_materials ? val.course_materials.class_notes_code : '/')">复制</button>
                        </div>
                      </div>
                    </div>
                    <div class="banji_operate" slot="reference">
                      <div>
                        <img src="../../../../assets/image/形状 3 拷贝 2.png" alt="">
                      </div>
                      <span class="current_banji_kebiao_right_text">下载素材</span>
                    </div>
                  </el-popover>
                  <div v-if="!!val.hashomework && !val.hashomework.hashomereadover.length" class="banji_operate" @click="submit_work(item.classmat_id ,val.hashomework ? val.hashomework.id : '', val.hashomework ? val.hashomework.syllabus_id : '' )">
                    <div>
                      <img src="../../../../assets/image/交作业.png" alt="">
                    </div>
                    <span class="current_banji_kebiao_right_text">交作业</span>
                  </div>
                  <div class="banji_operate" @click="see_replay(val.classmat_id, val.id)">
                    <div>
                      <img src="../../../../assets/image/看回放.png" alt="">
                    </div>
                    <span class="current_banji_kebiao_right_text">看回放</span>
                  </div>

                </div>
                </div>
              </div>
          </div>
              <no_data v-if="child_tabs_active === 1 && !history_class.length"></no_data>
        </div>

        <!-- 体验课 -->

        <div v-if="tabs_active === 1" v-cloak>
          <no_data v-if="!trialClassData.length"></no_data>
           <div class="trial_class" v-for="(item, index) in trialClassData" :key="index">
            <div class="trial_class_img">
              <img :src="item.home_cover" class="auto-size">
              <!-- 直播中 -->
              <div class="trial_class_img_bottom_text" @click="toLive(item, 'tiYanKe')"  v-if="item.live_status === 2">
                  <img src="../../../../assets/image/liveing.png" alt="">
                  <div style="color: #000000;">进入学习</div>
              </div>

              <!-- 即将直播 -->
              <div class="trial_class_img_bottom_text upcoming" v-if="item.live_status === 1">
                 <div class="upcoming_box">
                    <div style="font-size: 20px;">开播倒计时</div>
                    <div>
                      <span class="upcoming_live_color">{{item.countDownH}}</span>
                      <span class="time_small"> 时 </span>
                      <span class="upcoming_live_color">{{item.countDownM}}</span>
                      <span class="time_small"> 分 </span>
                      <span class="upcoming_live_color">{{item.countDownS}}</span>
                      <span class="time_small"> 秒 </span>
                    </div>
                 </div>
              </div>
              <!-- {{ item.countDownH }} -- {{ item.countDownM }} -- {{ item.countDownS }} -->
              <!-- 未开播 -->
              <div class="trial_class_img_bottom_text upcoming" v-if="item.live_status === 4">
                 <div class="upcoming_box">
                    <div style="font-size: 20px;">开播倒计时</div>
                    <div>
                      <span class="no_started_color">{{item.countDownH}}</span>
                      <span class="time_small"> 时 </span>
                      <span class="no_started_color">{{item.countDownM}}</span>
                      <span class="time_small"> 分 </span>
                      <span class="no_started_color">{{item.countDownS}}</span>
                      <span class="time_small"> 秒 </span>
                    </div>
                 </div>
              </div>

              <!-- 已结束 -->
              <div class="trial_class_img_bottom_text upcoming" v-if="item.live_status === 3">
                 <div>课程已结束</div>
              </div>

            </div>
            <div class="trial_class_data">
              <div class="trial_class_name">
                {{ item.dname }}
              </div>
              <div style="cursor: default;">
                <div class="trial_class_live_state trial_class_live_state_x" v-if="item.live_status === 2">
                  <div class="live_state"><onLive_css bgcA="#FFFFFF" bgcB="#FFFFFF"></onLive_css></div>
                  <div class="live_state_text">直播中</div>
                </div>

                <div class="trial_class_live_state be_about_to_live_box" v-if="item.live_status === 1">
                  <div class="live_state be_about_to_live">
                    <div class="be_about_to_live_triangle"></div>
                  </div>
                  <div class="live_state_text be_about_to_live_text">即将开播</div>
                </div>

                <div class="trial_class_live_state no_live_box trial_class_live_state_x" v-if="item.live_status === 4">
                  <div class="live_state no_live">
                    <div class="no_live_css">
                      <div class="no_live_css_middle"></div>
                    </div>
                  </div>
                  <div class="live_state_text no_live_text">未开播</div>
                </div>

                <div class="finish" v-if="item.live_status === 3">
                  <div class="finish_text">已结束</div>
                </div>
              </div>
              <div style="border: 1px solid #EFEFEF; width: 100%; margin: 20px 0;"></div>
              <div class="trial_class_live_time">
                <img src="../../../../assets/image/时 间.png">
                <div style="margin: 0 10px;">直播时间: {{ item.starttm }}</div>
                <img src="../../../../assets/image/形状 1 拷贝 15.png">
              </div>
              <div class="trial_class_teacher">
                <img src="../../../../assets/image/讲师.png" alt="">
                <span style="margin: 0 10px;">讲师: {{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 1654938000
export default {
  data() {
    return {
		myKeChengPage: {},
      loading: false,
      inputValue: 100,
      // tabs list
      tabsList: [
        {
          // tabs_title: '正式课'
          tabs_title: '公开课'
        },
        {
          tabs_title: '体验课'
        }
      ],
      // 当前选中的tabs
      tabs_active: 0,

      //  childTabs  List
      childTabsList: [
        {
          // child_tabs_title: '当前班级'
          child_tabs_title: '视频课'
        },
        // {
        //   child_tabs_title: '往期回放'
        // }
      ],
      // 当前选中的 childTabs
      child_tabs_active: 0,
      kebiao_index: null, // 是否显示课表/点播
      db_index: null,

      // 课程数据
      courseData: [],

      // 往期回放
      history_class: [],

      // 体验课数据
      trialClassData: [],

      // 正式课 课程表
      officialClassData: [],

      // 点播课数据
      clickCourseData: [],
      // 倒计时
      // countdown: {},   //封面倒计时
			timer_down: [],

      is_history_class: null,
      daojishi: 0,

      syllabusPage: {
        total: null,
        list_rows: 10,
        page: 1
      },
      classmatId: '',

      myCIntegral: '',   // 积分

      coursePage: {
        total: null,
        list_rows: 5,
        page: 1
      }
    }
  },
  methods: {
    // tabs 点击事件
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
      if (index === 0) {
        this.coursePage.page = 1
        this.getCourseData()
      } else if (index === 1) {
        this.daojishi = 0
        this.getMyTrialData()
      }
    },
    // childTabs 点击事件
    childTabsClick(index) {
      this.child_tabs_active = index
      if (index === 0) {
        this.getCourseData()
      } else if (index === 1) {
        this.getHistoryLiveData()
      }
    },
    // 复制
    copy(val, code,link) {
      // console.log(val, code);
      let createInput = document.createElement("input");
      createInput.value = code
      document.body.appendChild(createInput);
      createInput.select(); //选择对象
      document.execCommand("Copy"); //执行浏览器复制功能
      // this.$message.success("复制成功");
      this.$notify({
        title: '成功',
        message: '提取码已复制成功',
        type: 'success'
      });
      createInput.remove();
      let reg = /(http|https):\/\/([\w.]+\/?)\S*/
      // console.log(reg.test(val));
      if (reg.test(val)) {
        // window.location.href(val)
        window.open(val)
      }
    },

    // 获取课程数据
    getCourseData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_course',
        data: {
          list_rows: this.coursePage.list_rows,
          page: this.coursePage.page
        }
      }).then(res => {
        if (res.code === 200) {
          this.courseData = res.data.data
          this.coursePage.total = res.data.total
        }
        this.loading = false
      })
    },
    // 课程分页
    course_current_change(val) {
      this.coursePage.page = val
      this.getCourseData()
    },
    // 课程表点击
    banji_classBtn(index, classmat_id) {
		if(this.myKeChengPage) {
			this.myKeChengPage.banji_classBtn = {index, classmat_id}
		} else {
			this.myKeChengPage = {
				banji_classBtn: {index, classmat_id}
			}
		}
		
		localStorage.setItem('myKeChengPage', JSON.stringify(this.myKeChengPage))
		
        if (this.kebiao_index === index) {
			this.kebiao_index = null
			this.syllabusPage.page = 1
			return
        }
        this.db_index = null
        this.kebiao_index = index
        this.syllabusPage.page = 1
		this.getOfficialClassData(classmat_id)
		this.getIntegral(classmat_id)
    },
    getOfficialClassData(classmat_id) {
		this.officialClassData = []
      this.classmatId = classmat_id
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_syllabus',
        data: {
          class_id: classmat_id,
          list_rows: this.syllabusPage.list_rows,
          page: this.syllabusPage.page
        }
      }).then(res => {
        if (res.code === 200) {
          this.officialClassData = res.data.data
          this.syllabusPage.total = res.data.total
        } else{
          this.$message.error(`${res.msg}`)
        }
      this.loading = false
      })
    },
    // 获取积分
    getIntegral(classmat_id) {
      this.classmatId = classmat_id
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_course_integral',
        data: {
          class_id: classmat_id,
        }
      }).then(res => {
        if (res.code === 200) {
         this.myCIntegral = res.data[0].percentages
        } else{
          this.$message.error(`${res.msg}`)
        }
      this.loading = false
      })
    },
    // 点播客
    banji_dbBtn(index,classmat_id) {
     if (this.db_index === index) {
        this.db_index = null
        return
      }
      this.kebiao_index = null
      this.db_index = index

      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_click_course',
        data: {
          class_id: classmat_id
        }
      }).then(res => {
         if (res.code === 200) {
          this.clickCourseData = res.data[0].course.click_course
          console.log(this.clickCourseData);
        } else{
          this.$message.error(`${res.msg}`)
        }
      this.loading = false
      })
    },

    // 往期回放
    getHistoryLiveData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/history_class'
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.history_class = res.data
        }
      })
    },
    is_history_class_btn(i){
      if (this.is_history_class === i) {
        this.is_history_class = null
        return
      }
      this.is_history_class = i
    },
    //体验课 
    getMyTrialData() {
      this.loading = true
      // this.$axios({
      //   method: 'POST',
      //   url: 'api/officialWebsite/computer/yuan_cheng/my_trial_class'
      // }).then(res => {
      // this.loading = false
      //   if (res.code === 200) {
      //     this.courseData = res.data
      //   }
      // })
      this.$axios({

        method: 'GET',
        url: 'api/officialWebsite/boutique_experience_class?page=1&list_rows=10',
        data: {
          page: 1,
          list_rows: 10
        }
      }).then(res => {
      this.loading = false
        if (res.code === 200) {
          this.trialClassData = res.data.list
          let arr = []
          res.data.list.forEach(item => {
            if(item.live_status === 1 ||item.live_status === 4) {
              arr.push(item)
            }
          })
          if (!this.daojishi && !!arr.length) {
            this.start(arr)
            this.daojishi = 1
          }
        }
      })
    },

    add0(m) {
		return m < 10 ? '0' + m : m
	},
	//时间戳转时间
	format (shijianchuo, type) {
		if (!shijianchuo) return;
		if (parseInt(shijianchuo) < 10000000000) {
			shijianchuo = parseInt(shijianchuo) * 1000
		}
		var time = new Date(shijianchuo);
		var y = time.getFullYear();
		var m = time.getMonth() + 1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var time = {
			y,
			m: this.add0(m),
			d: this.add0(d),
			h: this.add0(h),
			mm: this.add0(mm),
		}
		var timeValue = time;
		if(type == 1) {
			timeValue = time.y + '年' + time.m + '月' + time.d + '日'  + time.h + ':' + time.mm;
		}else {
			timeValue = time.h + ':' + time.mm;
		}
		return timeValue
	},

  // 看回放跳转
  see_replay(classId, id) {
    console.log('看回放：', classId, id);
    // window.location.href = `/hfdb/${id}.html`
	this.$router.push({
		path: '/playback',
		query: {
			classId: classId,
			id: id
		}
	})
  },

  // 去看点播课
  click_course_click(id, val_id) {
    console.log('click_course_click');
    // window.location.href = `/db/${id}/cid/${val_id}.html`
	this.$router.push({
		path: '/DianBo',
		query: {
			classId: id,
			id: val_id
		}
	})
  },
  // 去看直播
  toLive(item, from) {
	console.log(item)
	// return;
	if (item.live_status == 2) {
	  let token = localStorage.getItem("token")
	  if(token !== null) {
		if(item.punishment_status == 1) {
			this.$notify.warning('您已被移出直播间')
			return
		}
		this.$router.push({
			name: 'liveIndex',
			query: {
				id: item.id,
				from
			}
		})
	  } else {
		// //直接跳转
		this.$router.push({
		  path:'/Login',
		  query:{
			backurl: "/liveIndex?id=" + item.id + '&from=tiYanKe',
			backmode:1,
		  }
		});
	 }
	}
 },
   
  // 体验课封面倒计时
   setTime(dom,item){
    let _this = this
    const leftTime = +new Date(dom) - +new Date();
    if (leftTime > 0 && item.timer) {
      const d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      const h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
      const m = Math.floor(leftTime / 1000 / 60 % 60);
      const s = Math.floor(leftTime / 1000 % 60);
    
      _this.$set(item, 'countDownH', _this.add0(h))
      _this.$set(item, 'countDownM', _this.add0(m))
      _this.$set(item, 'countDownS', _this.add0(s))
    } else {
      clearInterval(item.timer);
      this.daojishi = 0
      this.getMyTrialData()
    }
  },

  start(arr){
    arr.forEach((dom) => {
      // this.setTime(dom.time, dom);
      this.$set(dom, 'timer', setInterval(() => {
          this.setTime(dom.starttm, dom)
      }, 1000)) 
    });
  },

  //  location.href = "/index/live/sj_index?id=" + id;
  // 作业要求跳转
  work_btn(id, classmat_id) {
    window.location.href = `/subwork/id/${id}/classmat_id/${classmat_id}.html`
    // this.$router.push({
    //     path: '/work',
    //     query: {
    //       syllabus_id: syllabus_id,
    //       homework_id: homework_id,
    //       classmat_id: classmat_id,
    //       title: title
    //     }
    //   })
  },

  // 正式课学分领取
  get_course_xuefen(id, percentages) {
    let _this = this
    if (percentages < 100) {
      _this.$notify.error({
        title: '提示',
        message: `未达到领取条件`
      });
    }
    _this.loading = true
    _this.$axios({
      method:'POST',
      url: 'api/officialWebsite/computer/yuan_cheng/receive_integral',
      data: {
        id: id,
        cate: 4
      }
    }).then(res => {
      if (res.code === 200) {
        _this.$store.dispatch("pullUserInfoByToken")
        _this.getCourseData()
      }else {
        _this.$notify.error({
          title: '提示',
          message: `${res.msg}`
        });
      }
      _this.loading = false
    })
  },

  // 交作业
  submit_work(classmat_id,homework_id,syllabus_id) {
    if (!homework_id) {
       this.$notify({
          title: '提示',
          message: '该课程暂未布置作业',
          type: 'warning'
        });
        return
     }
    this.$router.push({
        path: '/work',
        query: {
          syllabus_id: syllabus_id,
          homework_id: homework_id,
          classmat_id: classmat_id,
          type: this.tabs_active === 0 ? 'formal' : 'stk',
          is_kecheng: 1
        }
      })
  },
  // 课程当前页变化
  current_change(val) {
    // this.work_params.page = val
    this.syllabusPage.page = val
    this.getOfficialClassData(this.classmatId)
  },
},
 created () {
    this.getCourseData()
	
	this.myKeChengPage = JSON.parse(localStorage.getItem('myKeChengPage'));
	if(this.myKeChengPage && this.myKeChengPage.banji_classBtn) {
		this.banji_classBtn(this.myKeChengPage.banji_classBtn.index, this.myKeChengPage.banji_classBtn.classmat_id)
	}
  },
  destroyed() {
   this.trialClassData.forEach(item => {
    if (!!item.timer) {
      clearInterval(item.timer);
    }
   })
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] { display: none }
.tabs {
  display: flex;
}

.tabs_info {
  // height: 578px;
   min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  padding: 44px;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.childTabs_slot {
  width: 68px;
  height: 20px;
  text-align: center;
  margin-right: 25px;
}

.childTabs_box {
  display: flex;
  cursor: default;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.childTabs_bottom {
  width: 32px;
  height: 2px;
  margin-top: 3px;
  transform: translateX(-40%);
}

.active {
  background: #007DFC;
}

.childTabsText {
  color: #007DFC;
}

.myContent_data {
  margin-top: 20px;
}

.current_banji_all_Info {
  width: 946px;
  border-radius: 16px 16px 16px 16px;
  background: #F8F8F8;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.current_banji {
  margin: 20px 0;
  display: flex;
  justify-content: start;
  align-items: center;
}

.current_banji_num {
  margin: 0 20px;
  font-style: italic;
  width: 50px;
  text-align: center;
  color: #D7D7D7;
  font-size: 40px
}

.current_banji_name {
  width: 300px;
  color: #000000;
  font-size: 18px
}

.current_banji_issue {
  color: #797979;
  font-size: 14px
}

.current_banji_opt {
  display: flex;
  justify-content: end;
  margin-left: 350px;
}

.current_banji_classBtn,
.current_banji_dbBtn {
  width: 81px;
  height: 38px;
  font-size: 17px;
  line-height: 38px;
  text-align: center;
  color: #615F5F;
  cursor: pointer;
  border-radius: 19px 19px 19px 19px;
  background: rgba(255, 255, 255, 1)
}

.current_banji_classBtn {
  margin-right: 13px;
}

.current_banji_kebiao_box {
  width: 907px;
  padding: 20px 0px 20px 86px;
  // margin-right: 10px;
}
.history_class_box{
  width: 907px;
  padding: 0px 0px 0px 86px;
}
.current_banji_kebiao {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 9999;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  background-color: #EBEBEB;
}

.dbBtn {
  color: #007DFC;

}

.kebiao {
  color: #007DFC;

}

.current_banji_kebiao_right {
  display: flex;
}

.banji_operate {
  display: flex;
  width: 70px;
  margin: 0 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.current_banji_kebiao_right_text {
  margin-top: 5px;
  font-weight: 400;
  height: 13px;
  font-size: 12;
  cursor: default;
  color: #808080;
}

// 下载素材弹出
.download_sucai_popover {
  padding: 20px;
}

.download_sucai_popover_flex {
  display: flex;
  justify-content: space-between;
}

.download_sucai_popover_text {
  color: #007DFC;
}

.omit_text {
  width: 150px;
  overflow:hidden; //超出的文本隐藏
  text-overflow:ellipsis; //溢出用省略号显示
  white-space:nowrap; //溢出不换行
}

.copy{
  width: 50px;
}

// 体验课
.trial_class {
  width: 801px;
  height: 362px;
  margin: 50px auto;
  border-radius: 23px;
  display: flex;
  justify-content: space-between;
  // background: rgba(195, 53, 53, 1)
}

.trial_class_img {
  position: relative;
  width: 482px;
  height: 362px;
  border-radius: 23px 0 0 23px;
}
.trial_class_img img {
  border-radius: 23px 0 0 23px;
}
.trial_class_data {
  width: 320px;
  height: 362px;
  border-radius: 0 23px 23px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border: 1px solid #EFEFEF;
  padding-left: 20px;
}

.progress{
  display: flex;
  margin-bottom: 20px;
}
// #E8A46B
.trial_class_live_state{
  margin-top: 20px;
  width: 162px;
  height: 36px;
  background-color: #FFF3DD;
  border-radius: 18px;
  display: flex;
}

.trial_class_live_state_x{
  width: 134px;
}
.live_state{
  width: 47px;
  height: 36px;
  background-color: #FE8427;
  border-radius: 18px  0 18px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.live_state_text{
  margin-left: 10px;
  height: 36px;
  line-height: 36px;
  color: #FE8427;
  font-size: 20px;
}

.formal_onLive_css{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
}

.trial_class_teacher{
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.trial_class_live_time{
  display: flex;
  justify-content: center;
  align-items: center;
}
// 即将直播
.be_about_to_live_box {
  background-color:  rgba(219,255,217,1);
}

.be_about_to_live {
  // background-color:  rgba(15, 181, 0, 1)
   background: linear-gradient(to right, rgba(19, 200, 0, 1), rgba(95, 226, 92, 1), rgba(103, 239, 55, 1));
}
.be_about_to_live_text {
  color:  rgba(15, 181, 0, 1)
}

.be_about_to_live_triangle{
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 13px solid #FFFFFF;
    margin-left: 10px;
}

// 未开播
.no_live_box {
  background-color:  rgba(237, 238, 255, 1);
}

.no_live {
  // background-color:  rgba(15, 181, 0, 1)
   background: linear-gradient(to right, rgba(75, 103, 216, 1), rgba(120, 175, 236, 1));
}
.no_live_text {
  color:  rgba(0, 51, 160, 1)
}
.no_live_css{
  position: relative;
  width: 17px;
  height: 17px;  
  border-radius: 50%;
  background-color: #FFFFFF;
}
.no_live_css_middle{
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, rgba(75, 103, 216, 1), rgba(120, 175, 236, 1));
}


// 已结束
.finish {
  margin-top: 20px;
  width: 134px;
  height: 36px;
  background-color: rgba(220,220,220,1);;
  border-radius: 18px;
}

.finish_text {
  font-size: 20px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  color: rgba(130, 130, 130, 1);
}


.current_banji_kebiao_left_top{
  font-size: 18px;
}

.current_banji_kebiao_left_bottom{
  font-size: 14px;
  color: #797979;
}

.trial_class_name{
  // font-size: 20px;
  // font-weight: 400;
  padding-right: 20px;

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

// 已开播图片文字
.trial_class_img_bottom_text{
  position: absolute;
  width: 200px;
  height: 60px;
  border-radius: 30px;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 27px;
  padding: 0 20px;
  color: #000000;
  cursor: pointer;
  // background-color: #FFFFFF;
  background: linear-gradient(to right top, #fff, #fff, #ffc096);

}

.past_playback{
  width: 946px;
  border-radius: 16px 16px 16px 16px;
  background: #F8F8F8;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
// 即将开播
.upcoming_box{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upcoming{
  width: 300px;
  height: 100px;
  border-radius: 100px;
  cursor: default;
  background: linear-gradient(to right, #FFFFFF , #FDFFD2);
}
.upcoming_live_color{
  color: #16B10B;
  font-size: 39px;
  font-weight: 700;
}

// 

// 点播课
.click_course_box{
  width: 194px;
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px 10px 0;
}
.click_course_name{
  height: 30px;
}
.click_course_flex{
  display: flex;
  flex-flow: wrap;
}

// 倒计时
.no_started_color{
  font-size: 39px;
  color: #3E75F7;
  font-weight: 700;
}

.time_small{
  font-size: 14px;
}


// 已结束
.finished_live{

}
.history_class_opt{
  display: flex;
  justify-content: end;
  width: 495px;
}

.history_class_btn {
  width: 100px;
  height: 50px;
  border-radius: 50px;
  font-size: 18px;
  color: #FFFFFF;
  background-color: #797979;
}
.history_class_btn{

}

</style>

<style lang="less" scoped>
/deep/ .el-progress{
  width: 88%;
  height: 50px;
  display: flex;
  align-items: center
}
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}
/deep/ .el-pagination{
  display: flex !important;
  justify-content: end !important;
}
</style>