import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'

// import rencaizhongxin from '../views/emptyPage.vue'
import Kecheng from '../views/Index/Kecheng.vue'
import rencaizhongxin from '../views/Index/rencaizhongxin.vue'
import qianxiaohulian from '../views/Index/qianxiaohulian.vue'
import Yanjiuyuan from '../views/yanjiuyuan/yanjiuyuan.vue'


import JobSearch from '../views/JobSearch/JobSearch.vue'
import Jianzhizhongxin from '../views/Index/Jianzhizhongxin.vue'
import Dangzhibu from '../views/Index/Dangzhibu.vue'
import DZB_detail from '../views/Index/DZB_detail.vue'
import About from '../views/Index/About.vue'
// import MapContainer from '../views/emptyPage.vue'
import MapContainer from '../views/Index/MapContainer.vue'
import gongsiDetail from '../views/Index/gongsiDetail.vue'
import downloadAPP from '../views/Index/downloadAPP.vue'
import details from '../views/Index/Details.vue'
import newsdetails from '../views/Index/NewsDetail.vue'
import enter from '../views/Index/enter.vue'
import jobIndex from '../views/job/index.vue'
import Renwu from '../views/job/renwu.vue'
import jobList from '../views/job/jobList.vue'
import jobListDetail from '../views/job/jobListDetail.vue'
import taskdetails from '../views/job/TaskDetails.vue'
import circle from '../views/circle/index.vue'
import kechengDetail from "../views/kecheng/kechengDetail.vue";
import yanjiuyuanDetail from '../views/yanjiuyuan/detail_yanjiuyuan.vue'
import memberDetail from '../views/yanjiuyuan/detail_member.vue'
import yanjiuyuanList from '../views/yanjiuyuan/yanjiuyuanList.vue'
import xuelitisheng from '../views/xueli/xuelitisheng.vue'
import liveIndex from '../views/live/index.vue'
import Playback from '../views/live/playback.vue'
import DianBo from '../views/live/dianbo.vue'

import personalCenter from '../views/Index/center_index'
import myKeCheng from '../views/Index/center_index/myContentRight/myKeCheng'
import myWork from '../views/Index/center_index/myContentRight/myWork'
import myCollect from '../views/Index/center_index/myContentRight/myCollect'
import myXueFen from '../views/Index/center_index/myContentRight/myXueFen'
import myOrder from '../views/Index/center_index/myContentRight/myOrder'
import myKaBao from '../views/Index/center_index/myContentRight/myKaBao'
import myNews from '../views/Index/center_index/myContentRight/myNews'
import myInvoice from '../views/Index/center_index/myContentRight/myInvoice'
import myHonor from '../views/Index/center_index/myContentRight/myHonor'
import myCertificate from '../views/Index/center_index/myContentRight/myCertificate'
import bindingVX from '../views/Index/center_index/myContentRight/bindingVX'
import userinfo from '../views/Index/center_index/myContentRight/userinfo'
import edit_password from '../views/Index/center_index/myContentRight/edit_password'
import bangding_email from '../views/Index/center_index/myContentRight/bangding_email'
import bangding_sj from '../views/Index/center_index/myContentRight/bangding_sj'
import unsubscribe from '../views/Index/center_index/myContentRight/unsubscribe'
import counterBlast from '../views/counterBlast'

// ��ҵ���ύ��δ�ύ��ת
import work from '../views/Index/center_index/components/work'
Vue.use(VueRouter)

const routes = [{
	path: "/Login",
	name: "Login",
	component: Login
}, {
	path: '/',
	name: 'Index',
	component: Index,
	redirect: {
		name: 'Home'
	}
},
{
	path: '/Index',
	name: 'Index',
	component: Index,
	children: [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/Home',
		name: 'Home',
		component: Home
	},
	{
		path: '/circleIndex',
		name: 'circleIndex',
		component: circle
	},
	{
		path: '/downloadAPP',
		name: 'downloadAPP',
		component: downloadAPP
	},
	{
		path: '/rencaizhongxin',
		name: 'rencaizhongxin',
		component: rencaizhongxin,
	},
	// {
	// 	path: '/qianxiaohulian',
	// 	name: 'qianxiaohulian',
	// 	component: qianxiaohulian,
	// },
	{
		path: '/details',
		name: 'details',
		component: details,
	},
	{
		path: '/Yanjiuyuan',
		name: 'Yanjiuyuan',
		component: Yanjiuyuan,
	},
	{
		path: '/JobSearch',
		name: 'JobSearch',
		component: JobSearch,
	},
	{
		path: '/MapContainer',
		name: 'MapContainer',
		component: MapContainer,
	}, {
		path: '/newsdetails',
		name: 'newsdetails',
		component: newsdetails,
	}, {
		path: "/enter",
		name: "enter",
		component: enter,
	},
	{
		path: "/gongsiDetail",
		name: "gongsiDetail",
		component: gongsiDetail,
	},
	{
		path: "/xuelitisheng",
		name: "xuelitisheng",
		component: xuelitisheng,
	},
	{
		path: "/playback",
		name: "Playback",
		component: Playback,
	},
	{
		path: "/DianBo",
		name: "DianBo",
		component: DianBo,
	},
	{
		path: "/liveIndex",
		name: "liveIndex",
		component: liveIndex,
	},
	{
		path: "/personalCenter",
		name: "personalCenter",
		redirect: '/personalCenter/myKeCheng',
		component: personalCenter,
		children: [
			{
				path: '/personalCenter/myKeCheng',
				name: 'myKeCheng',
				component: myKeCheng,
			},
			{
				path: '/personalCenter/myWork',
				name: 'myWork',
				component: myWork,
			},
			{
				path: '/personalCenter/myCollect',
				name: 'myCollect',
				component: myCollect,
			},
			{
				path: '/personalCenter/myXueFen',
				name: 'myXueFen',
				component: myXueFen,
			},
			{
				path: '/personalCenter/myOrder',
				name: 'myOrder',
				component: myOrder,
			},
			{
				path: '/personalCenter/myKaBao',
				name: 'myKaBao',
				component: myKaBao,
			},
			{
				path: '/personalCenter/myNews',
				name: 'myNews',
				component: myNews,
			},
			{
				path: '/personalCenter/myInvoice',
				name: 'myInvoice',
				component: myInvoice,
			},
			{
				path: '/personalCenter/myHonor',
				name: 'myHonor',
				component: myHonor,
			},
			{
				path: '/personalCenter/myCertificate',
				name: 'myCertificate',
				component: myCertificate,
			},
			{
				path: '/personalCenter/bindingVX',
				name: 'bindingVX',
				component: bindingVX,
			},
			{
				path: '/personalCenter/userinfo',
				name: 'userinfo',
				component: userinfo,
			},
			{
				path: '/personalCenter/edit_password',
				name: 'edit_password',
				component: edit_password,
			},
			{
				path: '/personalCenter/bangding_email',
				name: 'bangding_email',
				component: bangding_email,
			},
			{
				path: '/personalCenter/bangding_sj',
				name: 'bangding_sj',
				component: bangding_sj,
			},
			{
				path: '/personalCenter/unsubscribe',
				name: 'unsubscribe',
				component: unsubscribe,
			},
		],
	},
	{
		path: "/work",
		name: "work",
		component: work,
	},
	]
},
{
	path: '/job/index',
	name: 'jobIndex',
	component: jobIndex,
	children: [{
		path: '/Jianzhizhongxin',
		name: 'Jianzhizhongxin',
		component: Jianzhizhongxin,
	}, {
		path: '/renwu',
		name: 'Renwu',
		component: Renwu
	}, {
		path: '/jobList',
		name: 'jobList',
		component: jobList
	}, {
		path: '/jobListDetail',
		name: 'jobListDetail',
		component: jobListDetail
	}, {
		path: '/taskdetails',
		name: 'taskdetails',
		component: taskdetails
	}]
},
{
	path: '/Dangzhibu',
	name: 'Dangzhibu',
	component: Dangzhibu,
},
{
	path: '/counterBlast',
	name: 'counterBlast',
	component: counterBlast,
},

{
	path: '/DZB_detail',
	name: 'DZB_detail',
	component: DZB_detail,
},
{
	path: '/About',
	name: 'About',
	component: About,
},

{
	path: '/gongsiDetail',
	name: 'gongsiDetail',
	component: gongsiDetail,
},
{
	path: "/Kecheng",
	name: "Kecheng",
	component: Kecheng,
},
{
	path: "/kechengDetail",
	name: "kechengDetail",
	component: kechengDetail,
},
{
	path: '/yanjiuyuanDetail',
	name: 'yanjiuyuanDetail',
	component: yanjiuyuanDetail,
},
{
	path: '/memberDetail',
	name: 'memberDetail',
	component: memberDetail,
},
{
	path: '/yanjiuyuanList',
	name: 'yanjiuyuanList',
	component: yanjiuyuanList,
}
]


const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, form, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})
export default router
