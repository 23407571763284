<template>
  <div>
    <div class="tabs">
     <myTabs :ishonor="true" :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading="loading">
      <div class="myhonor_box" v-for="item in myHonorList" :key=item.id>
        <div class="myhonor_img">
          <div v-if="item.honor_log.length">
            <img :src="item.image_no">
            <p class="getTime">{{ item.creat_time.split(' ')[0] + '获得'}}</p>
          </div>

          <div v-else>
            <img :src="item.image_no">
            <p class="noGet">未获得</p>
          </div>
          <!-- <p :class="noGet">未获得</p> -->
        </div>
        <div>
          <button class="set_head_portrait_btn" @click="set_avatar(item.id)" v-if="item.honor_log.length && item.id != $store.state.userInfo.honor.id">设置头像</button>
          <button class="cancel_btn" @click="cancel_set_avatar(item.id)" v-if="item.honor_log.length && item.id == $store.state.userInfo.honor.id">取消设置</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ossUrl: process.env.VUE_APP_IMG_URL,
      honorID: JSON.parse(localStorage.getItem("userInfo")).honor.id,
      loading: false,
      tabsList: [
        {
          tabs_title: '我的荣誉'
        }
      ],
      tabs_active: 0,

      myHonorList:[],  // 我的荣誉数据

      show: false
    }
  },
  methods: {
    // 获取我的荣誉数据
    getMyHonorData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url:'api/officialWebsite/computer/yuan_cheng/my_honor'
      }).then(res => {
        if(res.code === 200) {
          this.myHonorList = res.data
        }else {
           this.$notify.error({
            title: '错误',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
    },
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },

    // 设置头像
    set_avatar(id) {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/change_honor_label',
        data: {
          widget_id: id
        }
      }).then(res => {
        if (res.code === 200) {
          console.log('res.code === 200)');
          this.$notify({
            title: '成功',
            message: '设置头像成功',
            type: 'success'
          });
          // this.getMyHonorData()
            this.$store.dispatch("pullUserInfoByToken")
            // window.location.reload(true)
        }else {
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }

        this.loading = false
      })
    },

    // 取消设置头像
    cancel_set_avatar(id) {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/del_honor_label',
        data: {
          widget_id: id
        }
      }).then(res => {
        if (res.code === 200) {
          console.log('res.code === 200 quxiao)');

          this.$notify({
            title: '成功',
            message: '取消设置成功',
            type: 'success'
          });
         
          this.$store.dispatch("pullUserInfoByToken")
          // window.location.reload(true)
        }else {
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
    }
  },
  created () {
    this.getMyHonorData()
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  padding: 60px 100px;

  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}
.myhonor_box{
  width: 215px;
  height: 290px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.myhonor_img{
  position:relative
}
.getTime{
  position: absolute;
  left: 25%;
  bottom: 15px;
  color: #FFF;
}
.noGet {
  position: absolute;
  left: 40%;
  bottom: 16px;
  color: #FFF;
}
.set_head_portrait_btn{
  width: 92px;
  margin-top: 10px;
  color: #FFFFFF;
  height: 30px;
  border-radius: 14px 14.4761px 14.4761px 14.4761px;
  // background: #F86F3C;
  background-image: url(../../../../assets/image/myhonor/setimg.png);
}
.cancel_btn{
  width: 92px;
  height: 30px;
  border-radius: 14.48px;
  color: #FFFFFF;
  margin-top: 10px;
  background: rgba(165,165,165,1);
}
</style>

<style lang="less" scoped>
/deep/ .el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/  .el-loading-spinner .circular {
  display: none;
}
</style>