<template>
  <div>
    <!-- tabs -->
    <div class="tabs">
      <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading = 'loading'>
      <!-- 主体 -->
      <div class="myContent_data">
        <!-- 全部作业 / 正式课作业 / 体验课作业 -->
        <div v-for="(item, index) in MyHomeworkData" :key="item.id" :class="{ isShowClassList: classIndex === index }">
         <div class="work_box" >
          <!-- 作业图片 -->
            <!-- <viewer :images="MyHomeworkData"> -->
            <div class="work_img" @click="getClassList(index,item.optiontable_id,item.id)">
              <img style="object-fit: cover;" :src="item.filepath" class="auto-size" alt="">
            </div>
          <!-- </viewer> -->
          <!-- 作业基本信息 -->
          <div >
            <div class="work_info"  @click="getClassList(index,item.optiontable_id,item.id)">
              <div class="work_info_top">
                <div class="course_identify course_identify_official" :class="{ course_identify_experience: item.optiontable_id === 0 }">
                  {{ item.optiontable_id === 0 ? '体验' : item.optiontable_id === 1 ? '正式' : '附课'}}
                </div>
                <div class="course_name">{{ item.name }}</div>
              </div>
              <div class="work_info_middle" style="cursor: pointer;">
                <div class="homework_sum">累计布置作业: {{ item.homework_sum }}份</div>
                <img v-if="classIndex === index" src="../../../../assets/image/work_xiala.png" alt="">
                <img v-else src="../../../../assets/image/shouqi.png" alt="">
              </div>
              <div class="work_info_bottom">
                我已提交: <span style="color: #1070EE">{{ item.homework_over_num }}份</span>
              </div>
            </div>
            
            <div style="cursor: pointer;" v-if="classIndex === index && item.optiontable_id !== 0">
              <div class="class_list_box"  v-for="(val,ind) in classList" :key="ind">
                <div class="class_list" >
                  <div class="class_name">{{ val.code }}期 &nbsp;{{ val.name }}</div>
                  <div style="width: 50%; text-align: right" v-if="sectionIndex != ind" @click="show_section_list(ind,val.id)">
                    <i class="el-icon-arrow-right" style="color: #DDDDDD;" ></i>
                  </div>
                  <div style="width: 50%; text-align: right" v-if="sectionIndex === ind" @click="hide_section_list(ind,val.id)">
                     <i class="el-icon-arrow-down" style="color: #DDDDDD;" ></i>
                  </div>
                </div>

                <div v-if="sectionIndex === ind" class="section_list_box">
                  <div   v-for='(data, i) in section_list' :key="i">
                    <div class="section_list_time">&nbsp;&nbsp;{{ data.startdate[0] }}&nbsp; {{ data.startdate[2] }}</div>
                    <div class="section_list_work">
                      <div class="section_list_work_left">
                        <div class="section_name">
                          {{ data.course_info?data.course_info.name:"" }}
                        </div>
                        <div class="section_work_teachername">
                          <span>讲师: {{ data.teacher_name }}&nbsp;&nbsp;&nbsp;授课: {{ data.startdate[1] }}~{{ data.enddate[1] }}</span>
                        </div>
                      </div>
                      <div   class="section_list_work_right">
                        <div @click="toWork(data.id, data.classmat_id, item.optiontable_id, item.id)" v-if="data.hashomework_count >= 1 && data.hashomework_count_own === 0" class="section_btn">交作业</div>
                        <div @click="toWork(data.id, data.classmat_id, item.optiontable_id, item.id)" v-if="data.hashomework_count >= 1 && data.hashomework_count_own > 0" class="section_btn submitted">已提交</div>
                        <div v-if="data.hashomework_count == 0" class="section_btn unpublished">未布置</div>
                      </div>
                    </div>
                  </div>
                  <el-pagination
                    background
                    hide-on-single-page
                    layout="prev, pager, next"
                    :total="sectionListParamsTotal"
                    :page-size="sectionListParams.list_rows"
                    :current-page="sectionListParams.page"
                    @current-change="sectionList_current_change($event,val.id)"
                  >
                  </el-pagination>
                </div>
              </div>
             
                <el-pagination
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  :total=" classListParamsTotal"
                  :page-size="classListParams.list_rows"
                  :current-page="classListParams.page"
                  @current-change="classList_current_change($event,index,item.optiontable_id,item.id)"
                >
                </el-pagination>
            </div>
            

            <!-- 体验课 -->
            <div v-if="classIndex === index && item.optiontable_id === 0">
              <div class="experience">
                <div class="experience_work">
                  <div v-for='(data, i) in experienceList' :key="i">
                    <div class="section_list_time">&nbsp;&nbsp;{{ data.startdate[0] }}&nbsp; {{ data.startdate[2] }}</div>
                    <div class="section_list_work">
                      <div class="section_list_work_left">
                        <div class="section_name">
                          {{ data.dname }}
                        </div>
                        <div class="section_work_teachername">
                          <span>讲师: {{ data.teacher_name }}&nbsp;&nbsp;&nbsp;授课: {{ data.startdate[1] }}~{{ data.enddate[1] }}</span>
                        </div>
                      </div>
                      <div class="section_list_work_right">
                        <div @click="toWork(data.id, data.classmat_id, 0, item.id)" v-if="data.hashomework_count == 1 && data.hashomework_count_own === 0" class="section_btn">交作业</div>
                        <div @click="toWork(data.id, data.classmat_id, 0, item.id)" v-if="data.hashomework_count == 1 && data.hashomework_count_own > 0" class="section_btn submitted">已提交</div>
                        <div v-if="data.hashomework_count == 0" class="section_btn unpublished">未布置</div>
                      </div>
                    </div>
                  </div>
                    <el-pagination
                      background
                      hide-on-single-page
                      layout="prev, pager, next"
                      :total="experienceListParamsTotal"
                      :page-size="experienceListParams.list_rows"
                      :current-page="experienceListParams.page"
                      @current-change="experienceList_current_change($event,item.id)"
                    >
                    </el-pagination>
                </div>
              </div>
            </div>
          </div>
          
         </div>
         <el-divider v-if="index != MyHomeworkData.length - 1 && index != classIndex"></el-divider>
        </div>
         <no_data v-if="!MyHomeworkData.length"></no_data>

      </div> 
      <!-- 分页 -->
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        :page-size="getMyHomeworkParams.list_rows"
        :current-page="getMyHomeworkParams.page"
        @current-change="current_change"
      >
      </el-pagination>
    </div>
     <!-- 删除dialog -->
  </div>
</template>

<script>

// /work/7/461096.html
export default {
  data() {
    return {
		myWorkPage: {},
      loading: false,
      del_dialog: false,
      // tabs list
      tabsList: [
        {
          tabs_title: '全部作业'
        },
        {
          tabs_title: '正式课作业'
        },
        {
          tabs_title: '体验课作业'
        }
      ],
      // 当前选中的tabs
      tabs_active: 0,

      // 我的作业数据
      MyHomeworkData:[],
      // 我的作业参数
      getMyHomeworkParams: {
        // type: 2,     //  1 未提交  2已提交 
        page: 1,
        list_rows: 3,
        type: ''
      },
    
      audio_list:[],  // 音频数据
      playing_audio: null,

      del_id: '0',

      audio_ind: 0,
      audio_url: '',

      // 作业分页参数
      // work_params:{
      //   page: 1,
      //   list_rows: 3
      // },
      total: null,

      showAllRequire: null,
      showAllText: true,
      job_type: null,

      
      // 课程
      classIndex: null,
      classList: [],

      classListParams: {
        coursetitle_id: '',
        list_rows: 10,
        page: 1,
      },
      classListParamsTotal: null,
      // 章节
      sectionListParams: {
        classmat_id: '',
        list_rows: 10,
        page: 1,
      },
      sectionListParamsTotal: null,
      sectionIndex: null,
      section_list: [],

      // 体验课数据
      experienceList: [],
      experienceListParams: {
        coursetitle_id: '',
        list_rows: 10,
        page: 1,
      },
      experienceListParamsTotal: null,
    }
  },
  methods: {
    // tabs 点击事件
    tabsClick(index) {
      this.tabs_active = index
      this.classListParams = []
      this.classList = []
      this.section_list = []
      this.classIndex = null
      this.sectionIndex = null
      this.sectionListParams.page = 1
      this.classListParams.page = 1
      this.getMyHomeworkParams.page = 1
      this.experienceListParams.page = 1
      if (index === 0) {
        this.getMyHomeworkParams.type = ''
        this.getMyHomeworkData()
      } else if (index === 1) {
        this.getMyHomeworkParams.type = 1
        this.getMyHomeworkData()
      } else{
        this.getMyHomeworkParams.type = 0
        this.getMyHomeworkData()
      }
        
    },

    // 获取课程数据
    getMyHomeworkData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/mobile/user_homework/user_course_lists',
        data: this.getMyHomeworkParams
      }).then(res => {
        if (res.code === 200) {
          this.MyHomeworkData = res.data.list
          this.total = res.data.total
        }else{
           this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
        // console.log(this.MyHomeworkData);
      })
    },


    // 获取课程章节
    getClassList(index,optiontable_id, id) {
		console.log('获取课程章节:', index,optiontable_id, id)
		if(this.myWorkPage) {
			this.myWorkPage.getClassList = {index, optiontable_id, id}
		} else {
			this.myWorkPage = {
				getClassList: {index, optiontable_id, id}
			}
		}
		localStorage.setItem('myWorkPage', JSON.stringify(this.myWorkPage))
		
      if (this.classIndex === index) {
        this.classIndex = null
        this.sectionListParams.page = 1
        return
      }
      this.sectionIndex = null
      this.classIndex = index
      this.sectionListParams.page = 1
      this.classListParams.page = 1
      this.experienceListParams.page = 1
      if (optiontable_id !== 0) {
        this.classListParams.coursetitle_id = id
        this.getFormalClass(id)
      }else {
       this.experienceListParams.coursetitle_id = id
       this.getExperienceClass(id)
      }
    },

    // 获取正式课章节
    getFormalClass(id) {
      let _this = this
      _this.loading = true
      _this.classList = []
      _this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/mobile/user_homework/user_formal_class',
        data: {
          ..._this.classListParams, coursetitle_id: id
        }
      }).then(res => {
        if (res.code === 200) {
          _this.classList = res.data.list
          _this.classListParamsTotal = res.data.total
          if (!res.data.list.length) {
            _this.$notify({
              title: '提示',
              message: `暂无数据`,
              type: 'warning'
            });
           } else {
			   if(this.myWorkPage && this.myWorkPage.show_section_list) {
			   		this.show_section_list(this.myWorkPage.show_section_list.index, this.myWorkPage.show_section_list.id)
			   	}
		   } 
        }else{
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        _this.loading = false
      })
    },

    // 体验课作业列表 
    getExperienceClass(id) {
      let _this = this
      _this.loading = true
      _this.experienceList = []
      _this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/mobile/user_homework/user_homework_lists',
        data: {
          ..._this.experienceListParams, coursetitle_id: id
        }
      }).then(res => {
        if (res.code === 200) {
          _this.experienceList = res.data.list
          _this.experienceListParamsTotal = res.data.total
           if (!res.data.list.length) {
            _this.$notify({
              title: '提示',
              message: `暂无数据`,
              type: 'warning'
            });
           }
        }else{
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        _this.loading = false
      })
    },
    // 获取/显示章节列表  api/officialWebsite/mobile/user_homework/class_section_list
    show_section_list(index,id) {
		console.log('获取/显示章节列表:', index,id)
		if(this.myWorkPage) {
			this.myWorkPage.show_section_list = {index,id}
		}
		localStorage.setItem('myWorkPage', JSON.stringify(this.myWorkPage))
      let _this = this
      _this.sectionIndex = index
      _this.loading = true
      _this.section_list = []
      _this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/mobile/user_homework/class_section_list',
        data:{
          ..._this.sectionListParams, classmat_id: id
        }
      }).then(res => {
        if(res.code === 200) {
          _this.section_list = res.data.list
          this.sectionListParamsTotal = res.data.total
        }else{
          _this.$notify.error({
            title: '提示',
            message:  `${res.msg}`
          });
        }
        this.loading = false
      })
      
    },

    // 隐藏章节列表
    hide_section_list(index,id) {
      if (this.sectionIndex === index) {
        this.sectionIndex = null
        this.sectionListParams.page = 1
      }
    },

    toWork(id,classmat_id, optiontable_id, coursetitle_id) {
      let _this = this
      _this.$router.push({
        path: '/work',
        query: {
          syllabus_id: id,
          classmat_id: classmat_id,
          type: optiontable_id === 0 ? 'stk' : 'formal',
          tabs_active: _this.tabs_active,
          sectionIndex: _this.sectionIndex,
          classIndex: _this.classIndex,
          coursetitle_id: coursetitle_id,
          page: _this.getMyHomeworkParams.page
        }
      })
    },
   
    playing(e) {
      this.playing_audio = e
    },

  // 补零
  add0(m) {
		return m < 10 ? '0' + m : m
	},
  // 父  触发子语音播放，只保证当前只有一个播放
  myWorkAudioClick(ind) {
    let _this = this
      for (let k = 0; k < _this.MyHomeworkData.length; k++) {
        if (k != ind) {
            _this.$refs.myWorkAudioRef[k].$refs.audio.pause()
            _this.$refs.myWorkAudioRef[k]._data.isPlaying = false
        }
      }
      _this.$refs.myWorkAudioRef[ind].handlePlay()
  },

  // 课程当前页变化
  current_change(val) {
    // this.work_params.page = val
    this.classIndex = null
    this.getMyHomeworkParams.page = val
    this.getMyHomeworkData()
  },

  showAllTextClick(ind) {
    // console.log(ind);
    this.showAllRequire = ind
  },

  // 章节/作业分页
  sectionList_current_change(val, id) {
    this.sectionListParams.page = val
    this.show_section_list(this.sectionIndex, id)
  },

  // 正式课分页
  classList_current_change(val, index, optiontable_id, id) {
    if (optiontable_id !== 0) {
      this.classListParams.page = val
      this.sectionListParams.page = 1
      this.sectionIndex = null
      this.getFormalClass(id)
    }
  },
  // 体验课分页
  experienceList_current_change(val, id) {
    this.experienceListParams.page = val
    console.log(id);
    this.getExperienceClass(id)
  }
  },

  created () {
    if (isNaN(Number(this.$route.query.tabs_active))) {
       this.getMyHomeworkData()
    }else {
      this.getMyHomeworkParams.page = Number(this.$route.query.page)
      if (Number(this.$route.query.tabs_active) === 0) {
        this.getMyHomeworkParams.type = ''
       }else if (Number(this.$route.query.tabs_active) === 1) {
        this.getMyHomeworkParams.type = 1
       }else if (Number(this.$route.query.tabs_active) === 2){
        this.getMyHomeworkParams.type = 0
       }
      this.getMyHomeworkData()
    }
	
	this.myWorkPage = JSON.parse(localStorage.getItem('myWorkPage'));
	if(this.myWorkPage && this.myWorkPage.getClassList) {
		this.getClassList(this.myWorkPage.getClassList.index, this.myWorkPage.getClassList.optiontable_id, this.myWorkPage.getClassList.id)
	}
  },

  mounted () {
    let audioobjs = document.getElementsByTagName('audio')
    this.audio_list = audioobjs
  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      // console.log(to.fullPath);
      let p = from.fullPath.split('?')[0]
      if (p === '/work' && vm.$route.query.type) {
        vm.tabs_active = Number(vm.$route.query.tabs_active)
        vm.sectionIndex = Number(vm.$route.query.sectionIndex)
        vm.classIndex = Number(vm.$route.query.classIndex)

        if (vm.$route.query.type === 'formal') {
          vm.getFormalClass(vm.$route.query.coursetitle_id)
        }else{
          vm.getExperienceClass(vm.$route.query.coursetitle_id)
        }
        vm.show_section_list(vm.sectionIndex, vm.$route.query.classmat_id)
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  padding: 22px;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 4px #eee);
}

.childTabs_slot {
  width: 68px;
  height: 20px;
  text-align: center;
  margin-right: 25px;
}

.childTabs_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.childTabs_bottom {
  width: 32px;
  height: 2px;
  margin-top: 3px;
  transform: translateX(-40%);
}

.active {
  background: #007DFC;
}

.childTabsText {
  color: #007DFC;
}


.delete_box{
  display: flex;
  width: 32px;
  justify-content: space-around;
}

.delete {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #BEBEBE;
}

.omit_text {
  width: 350px;
  overflow:hidden; //超出的文本隐藏
  text-overflow:ellipsis; //溢出用省略号显示
  white-space:nowrap; //溢出不换行
}
.all_text{
  width: 350px;
  overflow:''; //超出的文本隐藏
  text-overflow:''; //溢出用省略号显示
  white-space:''; //
}
.img_bgc {
    filter: brightness(100);
}


// 全部/正式/体验
.work_box{
  display: flex;
  width: 100%;
  padding: 32px 29px;
  min-height: 130px;
  
  // margin-bottom: 56px;
  // background-color: #338BFF;
}

.isShowClassList{
  // padding: 32px 29px;
  // width: 1000px;
  // margin-top: 32px;
  background-color: #F6F6F6;
  border-radius: 20px;
}

.work_img{
  width: 168px;
  height: 130px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  // background-color: aqua;
  margin-right: 34px;
}

.work_info{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 741px;
  height: 130px;
  padding-right: 60px;
  // background-color: aquamarine;
}

.work_info_top{
  display: flex;
  align-items: center;
}
.course_identify{
  width: 42px;
  height: 24px;
  border-radius: 11px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  margin-right: 10px;
  // background: rgba(213,226,255,1);
}

// 正式标识
.course_identify_official{
  background-color: #CEE1FF;
  color: #619AE4;
}

// 体验标识
.course_identify_experience{
  background-color: #EAEAEA;
  color: #7D7D7D;
}

.course_name{
  font-weight: 400;
  font-size: 22px;
  color: #2B2B2B;
}

.work_info_middle{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homework_sum{
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.work_info_bottom{
  font-family: 'Microsoft YaHei';
  font-size: 14px;
}


.class_list_box{
  display: flex;
  flex-direction: column;
  width: 741px;
  min-height: 65px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 22px 42px;
  background: rgba(255,255,255,1);
  // background-color: red;
}

.class_list{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.class_name{
  font-weight: 400;
  font-size: 20px;
  color: #2B2B2B;
}

.section_list_box{
  margin-top: 43px;
}
.section_list{
  padding: 22px 42px;
  border-radius:0 0  10px 10px;
  background: rgba(255,255,255,1);
}

.section_list_time{
  width: 500px;
  height: 16px;
  border-left: 4px solid #007DFC;
  color: #007DFC;
  line-height: 16px;
}
.section_list_work{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 46px 26px 20px;
  padding: 20px;
  background-color: #F6F6F6;
  width: 631px;
  height: 94px;
  border-radius: 10px;
}
.section_name{
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  font-size: 20px;
  color: #2B2B2B;
}
.section_work_teachername{
  font-size: 16px;
  color: #939393;
}
.section_btn{
  width: 69px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  font-size: 14px;
  border-radius: 14.5px;
  color: #FFFFFF;
  cursor: pointer;
  background: rgba(15,111,237,1);
}

.submitted{
  background: rgba(0,201,118,1);
}
.unpublished {
  background: rgba(209,209,209,1);
}

.experience{
  width: 741px;
  border-radius: 10px;
  background: rgba(255,255,255,1);
}
.experience_work{
  padding: 43px 51px;
}
</style>



<style lang="less" scoped>
 /deep/ .del_work_dialog .el-dialog {
    height: 252px;
    margin-top: 40vh !important;
    border-radius: 22px;
    background: rgba(255,255,255,1);
  }
 /deep/ .del_work_dialog_text  {
    font-size: 22px;
    color: #5A5A5A;
    text-align: center;
    padding: 0 0 75px 0;

 }
 .del_work_dialog_btn{
  display: flex;
  justify-content: space-between;
 }
 .work_dialog_btn{
  width: 98px;
  height: 43px;
  border-radius: 21px;
  text-align: center;
  line-height: 43px;
  font-size: 20px;
  color: #FFFFFF;
  background: rgba(0,125,252,1);
 }
 
 .work_dialog_cancel_btn{
  background: rgba(164,164,164,1);
 }
 /deep/ .el-dialog__body {
  padding: 30px 40px;
 }

 /deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}

/deep/ .el-pagination{
  display: flex !important;
  justify-content: end !important;
}

/deep/ .el-divider--horizontal{
  margin: 0;
  width: 79% !important;
  margin-left: 23%;
}
</style>