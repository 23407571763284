<template>
	<div class="index" @scroll="scroll_fun" v-if="courseDetail.id">
		<div id="pageTop"></div>
		<!-- 课程详情 -->
		<div style="height: 70px;" :style="{opacity: opacityshow}">
			<Header></Header>
		</div>
		<div class="" v-if="show">
			<!-- 头部显示的导航栏 -->
			<div class="header_kecheng">
				<div class="navbar-content">
					<div class="navbar-left">
						<div class="li" @click="click_tab(1)" :class="clickTabIndex == 1 ? 'active' : ''">课程详情 </div>
						<!-- <div class="li" @click="click_tab(2)" :class="clickTabIndex == 2 ? 'active' : ''">评论 (999+)</div> -->
					</div>
					<div class="navbar-btn" @click="goBaoMing()">
						<span> 立即报名 </span>
					</div>
				</div>
			</div>
			<!-- 回到顶部 -->
			<a href="#pageTop" class="to-top">
				<img class="topImg" src="https://static.shanchuangjiaoyu.com/ycpc/assets/a@2x.png">
			</a>
		</div>

		<div class="content_box" v-loading = 'loading'>
			<div class="content-center">
				<div class="content_top">
					<span>课程</span>
					<span class="sep">/</span>
					<span>精研学堂</span>
					<span class="sep">/</span>
					<span class="content_top_title">{{courseDetail.name}}</span>
				</div>

				<div class="course-body">
					<div class="left">
						<img :src="courseDetail.filepath" class="course-banner">
					</div>
					<div class="right">
						<div class="course-info_wrap">
							<div class="wrap__top">
								
									<h3>{{courseDetail.name}}</h3>
									
								<div style="display: flex;justify-content: space-between;">
								<div class="course-data">
									<span class="member-num"> {{(courseDetail.count / 10000).toFixed(1)  }}</span>万同学看过
									<span class="dot"></span>
									<span>{{courseDetail.section}}章</span>
									<span>{{courseDetail.knob}}节</span>
									<span class="dot"></span>
									<span>来自精研学堂</span>
								</div>
								<div @click="collect_kecheng" v-if="courseDetail.collection"><img src="../../assets/image/shoucang.png" alt=""></div>
								<div @click="collect_kecheng" v-else><img src="../../assets/image/yishoucang.png" alt=""></div>
								</div>
								<div class="course-desc">
									{{courseDetail.decrition}}
								</div>
							</div>
							<div class="wrap__bottom">
								<div class="detail-price">
									<span class="price-tag">￥</span>
									<span>{{courseDetail.pricesj}}</span>
								</div>
								<div class="course-handle" @click="goBaoMing()">
									<span> 立即报名 </span>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="course-details-wrap_width">
					<div class="course-left_area">
						<div class="the-tabs" ref="piediv">
							<ul class="heads">
								<li @click="click_tab(1)" :class="clickTabIndex == 1 ? 'active' : ''">课程详情 </li>
								<!-- <li @click="click_tab(2)" :class="clickTabIndex == 2 ? 'active' : ''">评论 (999+) </li> -->
							</ul>
						</div>
						<div class="description">
							<div v-if="clickTabIndex == 1" class="course_content_box" v-html="courseDetail.content">
							</div>
							<!-- <img v-if="clickTabIndex == 1" src="../../assets/image/course_content.jpg"> -->
							<div v-else>
								<div v-for="(item, index) in pinglunList" :key="index" class="course-comment-warp">
									<div class="user-data-warp">
										<img src="../../assets/image/course.jpg" class="avatar">
										<div class="right-top-box">
											<div class="">马春莲</div>
											<div class="">03月02日 22:48</div>
										</div>
									</div>
									<div class="content">
										<span>发发自己的作品集！！</span>
									</div>
									<div class="img-box">
										<img src="../../assets/image/course.jpg" v-for="(item_img, index_img) in 4"
											:key="index_img">
									</div>
									<div class="comment-warp">
										<div class="comment-box" v-for="(item_comment, index_comment) in 3"
											:key="index_comment">
											<span class="comment-nickname">平淡才是真001：</span>
											<span class="comment-replies-content">你学了多久这么多推荐，每天很努力练习吧</span>
										</div>
										<div class="check-more-btn"> 查看更多评论 </div>
									</div>
									<div class="line"></div>
								</div>
								<div class="loading-page" @click="click_page">
									<div class="load-btn">点击查看更多内容</div>
								</div>
							</div>

						</div>
					</div>

					<div class="course-right_area">
						<div class="label">
							<div class="label-line"></div>
							<div class="label-tit">猜你想学</div>
						</div>
						<div class="course-recommendation_wrap">
							<div class="course-list" v-for="(item, index) in likeCourseData" :key="index"
								@click="goCourseDetail(item.id)">
								<div class="img-box">
									<img :src="item.filepath">
								</div>
								<div class="list_right">
									<div class="right__top">
										<div class="title">{{item.name}}</div>
										<p class="price">
											<span>￥</span>{{item.pricesj}}
										</p>
									</div>
									<p class="study_num">
										<span>{{item.count}}</span>位同学在学
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<el-dialog title="报名课程" :visible.sync="dialogVisible" width="764px" class="baoming_box">
				<div class="baomingBox">
					<img :src="courseDetail.filepath">
					<div class="meal-info">
						<div class="meal-title">{{courseDetail.name}}</div>
						<div class="meal-tips">
							<div class="meal-knob">
								共{{courseDetail.section}}章{{courseDetail.knob}}节
							</div>
						</div>
						<div class="meal-paynum">
							<div class="meal_old">
								原价 <span>￥{{courseDetail.pricesj}}</span>
							</div>
							<div class="meal_new">
								助学价￥<span>{{courseDetail.price}}</span>
							</div>
						</div>
						<div class="meal_btn" @click="goZhiFu">报名支付</div>
						<div class="meal_agr">
							<el-checkbox v-model="checked" checked style="margin-right: 10px;"></el-checkbox>
							<span>同意并遵守</span><a href="/index/index/fwxy.html" target="_blank">《用户协议》</a><span>和</span><a
								href="/index/index/yszc.html" target="_blank">《隐私政策》</a>
						</div>
					</div>
				</div>
			</el-dialog>

		</div>

		<BottomTab></BottomTab>
	</div>
</template>

<script>
	import Header from '../components/Header.vue'
	import BottomTab from '../components/BottomTab.vue'
	export default {
		name: 'kechengDetail',
		data() {
			return {
				id: null,
				show: false,
				piedivTop: null,
				opacityshow: 1,
				clickTabIndex: 1,
				courseDetail: {},
				likeCourseData: [],
				pinglunList: [{}, {}, {}, {}, {}],
				dialogVisible: false,
				checked: true,
				loading: false
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getDetailData();
			this.getCourseLikeData();

		},
		methods: {
			//页面滚动
			scroll_fun(e) {
				let scrollTop = e.srcElement.scrollTop;
				// console.log('滚动距离：', scrollTop)
				if(scrollTop <= 5) {
					this.piedivTop = this.$refs.piediv.getBoundingClientRect().top + 30;
				}
				if (scrollTop > this.piedivTop) {
					this.show = true;
				} else {
					this.show = false;
				}

				let scrollDis = this.piedivTop / 10;

				if (scrollTop >= this.piedivTop) {
					this.opacityshow = 0;
				} else if (scrollTop >= scrollDis * 9) {
					this.opacityshow = 0.3
				} else if (scrollTop >= scrollDis * 8) {
					this.opacityshow = 0.5
				} else if (scrollTop >= scrollDis * 5) {
					this.opacityshow = 0.5
				} else if (scrollTop >= scrollDis * 3) {
					this.opacityshow = 0.8
				} else if (scrollTop >= scrollDis * 2) {
					this.opacityshow = 1
				} else if (scrollTop >= scrollDis * 1) {
					this.opacityshow = 1
				} else {
					this.opacityshow = 1
				}

			},
			click_tab(type) {
				this.clickTabIndex = type;
			},
			getDetailData() {
				this.$axios({
					method: 'POST',
					url: "/api/officialWebsite/get_course_details",
					data: {
						course_id: this.id
					}
				}).then(res => {
					this.courseDetail = res.data;
				})
			},
			// 猜你喜欢课程
			getCourseLikeData() {
				this.$axios({
					method: 'POST',
					url: "/api/officialWebsite/get_course_likes"
				}).then(res => {
					this.likeCourseData = res.data;
				})
			},
			click_page() {
				// this.pinglunList.push({});

			},
			goCourseDetail(id) {
				this.id = id;
				this.getDetailData();
				this.getCourseLikeData();
				let targetUrl = this.$common.getPath(this.$route.path) + '?id=' + this.id;
				window.history.pushState({}, '', targetUrl);
				document.querySelector('#pageTop').scrollIntoView(true);
			},
			// 立即报名
			goBaoMing() {
				if (localStorage.getItem("token")) {
					this.dialogVisible = true;
				} else {
					let currentUrl = window.location.href;
					// location.href = "/index/login/login_pass.html?from=" + currentUrl;
          //直接跳转
          // this.$router.push('/Login');
					  this.$router.push({
              path:'/Index',
              query:{
								login_visible: 1,
								backurl:"/kechengDetail?id=" + this.$route.query.id,
                backmode:1,
								isKeCheng: true
              }
            });
				}
			},
			goZhiFu() {
				if (this.checked) {
					this.dialogVisible = false;
					location.href = "/index/pay/order?id=" + this.id;
				} else {
					this.$message({
						message: "请阅读协议",
						type: "error",
						offset: 100
					})
				}

			},
			collect_kecheng() {
				this.loading = true
				this.$axios({
					method: 'POST',
					url: 'api/officialWebsite/computer/yuan_cheng/collection_syllabus',
					data: {
						id: this.$route.query.id,
						type: this.$route.query.active === 0 ? 2 : 4
					}
				}).then(res => {
					if (res.code === 200) {
						if (!res.data) {
							this.$notify({
								title: '成功',
								message: '课程收藏成功',
								type: 'success'
							});
						}else {
							this.$notify({
								title: '成功',
								message: '取消课程收藏',
								type: 'success'
							});
						}
						this.getDetailData(this.$route.query.id)
					}else{
						this.$notify.error({
							title: '提示',
							message: `${res.msg}`
						});
					}
				this.loading = false

				})
			}
		},
		components: {
			Header,
			BottomTab
		}

	}
</script>

<style scoped lang="scss">
	img {
		vertical-align: middle;
	}

	.content_box {
		background-color: #F7F7F7;
	}

	.content-center {
		width: 1200px;
		margin: 0 auto;
	}

	.content_top {
		width: 100%;
		display: flex;
		align-items: center;
		height: 52px;
		color: #a3a8ab;
	}

	.sep {
		margin: 0 8px 0 18px;
	}

	.content_top_title {
		color: #18252c;
	}

	.course-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		padding: 24px;
		border-radius: 8px;
		margin-bottom: 20px;

		.left {
			width: 530px;
			height: 353px;
			margin-right: 20px;

			.course-banner {
				width: 100%;
				height: 100%;
				border-radius: 8px;
				object-fit: cover;
			}
		}

		.right {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			position: relative;

			.course-info_wrap {
				height: 100%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;

				.wrap__top {
					-webkit-box-direction: normal;

					h3 {
						height: 45px;
						font-size: 32px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #18252c;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						max-width: 600px;
					}

					.course-data {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #747c80;
						line-height: 6px;
						padding: 14px 0 24px 0;

						.dot {
							display: inline-block;
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background-color: #d1d3d5;
							vertical-align: top;
							margin: 0 8px;
						}
					}

					.course-desc {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #747c80;
						line-height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 6;
					}
				}

				.wrap__bottom {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;
					font-size: 40px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #ff2b26;

					.detail-price {}

					.price-tag {
						font-size: 18px;
					}

					.course-handle {
						width: 295px;
						height: 56px;
						font-size: 19px;
						line-height: 56px;
						text-align: center;
						background: -webkit-gradient(linear, right top, left top, from(#ff4d40), to(#ff7e75));
						background: linear-gradient(270deg, #ff4d40, #ff7e75);
						border-radius: 8px;
						cursor: pointer;

						span {
							font-size: 19px;
							font-family: PingFangSC-Semibold, PingFang SC;
							font-weight: 600;
							color: #fff;
							line-height: 26px;
						}
					}
				}
			}
		}


	}

	.course-details-wrap_width {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 0 0 30px 0;

		.course-left_area {
			width: 850px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			background-color: #fff;
			border-radius: 8px;
			padding: 20px 54px 18px 32px;

			.the-tabs {
				.heads {
					display: flex;

					li {
						min-width: 72px;
						height: 25px;
						font-size: 18px;
						font-family: PingFangSC-Semibold, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
						font-weight: 600;
						color: #747c80;
						line-height: 25px;
						cursor: pointer;
						z-index: 10;
						text-align: center;
					}

					li:first-child {
						margin-right: 40px;
					}

					.active {
						font-weight: 600;
						color: #18252c !important;
						border-bottom: 4px solid #00d753;
					}
				}

			}

			.description {
				margin-top: 25px;

			}
		}

		.course-right_area {
			margin-left: 15px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			width: 335px;
			background-color: #fff;
			border-radius: 8px;
			padding: 0 16px;

			.label {
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				padding: 20px 0;

				.label-line {
					width: 6px;
					height: 18px;
					background-color: #0cb65b;
				}

				.label-tit {
					position: relative;
					text-align: left;
					padding-left: 16px;
					font-size: 18px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #18252c;
				}
			}

			.course-recommendation_wrap {
				.course-list {
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;
					cursor: pointer;

					.img-box {
						width: 140px;
						height: 93px;
						margin-right: 10px;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							border-radius: 7px;
							-o-object-fit: cover;
							object-fit: cover;
						}
					}
				}

				.list_right {
					-webkit-box-flex: 1;
					-ms-flex: 1;
					flex: 1;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					text-align: left;
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;

					.right__top {
						.title {
							width: 144px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							margin-bottom: 10px;
							color: #18252c;
							font-size: 16px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
						}

						.price {
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #ff2b26;
							line-height: 20px;
						}
					}
				}

				.study_num {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #a3a8ab;

					span {}
				}
			}
		}

	}

	.header_kecheng {
		width: calc(100% - 17px);
		position: fixed;
		top: 0;
		box-shadow: 0 2px 9px rgba(0, 0, 0, 0.08);
		z-index: 10002;
		background-color: #fff;

		.navbar-content {
			width: 1200px;
			height: 80px;
			line-height: 80px;
			margin: 0 auto;
			padding: 0 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.navbar-btn {
				width: 295px;
				height: 56px;
				line-height: 56px;
				background: -webkit-gradient(linear, right top, left top, from(#ff4d40), to(#ff7e75));
				background: linear-gradient(270deg, #ff4d40, #ff7e75);
				border-radius: 8px;
				color: #fff;
				text-align: center;
				font-size: 19px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				cursor: pointer
			}

			.navbar-left {
				display: flex;
				align-items: center;

				.li {
					font-size: 24px;
					color: #747c80;
					font-weight: 600;
					cursor: pointer;
				}

				.li:first-child {
					margin-right: 40px;
				}

				.active {
					color: #18252c;
					position: relative;
					// border-bottom: 6px solid #1cdc94;
				}

				.active:after {
					content: '';
					width: 96px;
					height: 6px;
					background: linear-gradient(316deg, #0cb65b, #1cdc94);
					position: absolute;
					display: block;
					bottom: 20px;
				}
			}
		}
	}

	.to-top {
		position: fixed;
		bottom: 4%;
		right: 17px;
		width: 63px;
		height: 43px;
		background: #fff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;

		.topImg {
			width: 18px;
			object-fit: cover;
		}

	}

	.course-comment-warp {
		margin-top: 20px;

		.user-data-warp {
			display: flex;

			.avatar {
				width: 40px !important;
				height: 40px;
				border-radius: 50%;
			}

			.right-top-box {
				margin-left: 8px;
				height: 40px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}

			.right-top-box :first-child {
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
				font-weight: 500;
				color: #18252c;
				line-height: 20px;
			}

			.right-top-box :nth-child(2) {
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
				font-weight: 400;
				color: #9fa4a7;
				line-height: 17px;
			}
		}

		.content {
			position: relative;
			margin-top: 8px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
			font-weight: 400;
			color: #18252c;
			line-height: 24px;
			word-wrap: break-word;
			word-break: break-word;
			cursor: pointer;
		}

		.img-box {
			position: relative;
			margin-top: 12px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			width: 100%;

			img {
				cursor: pointer;
				margin-left: 12px;
				width: 186px !important;
				height: 140px;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 8px;
			}
		}

		.img-box :first-child {
			margin-left: 0;
		}

		.comment-warp {
			margin-top: 20px;
			width: 100%;
			background: #f5f8f8;
			padding: 8px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			border-radius: 8px;

			.comment-box {
				margin-bottom: 2px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;

				.comment-nickname {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
					font-weight: 400;
					color: #a3a8ab;
					line-height: 20px;
				}

				.comment-replies-content {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
					font-weight: 400;
					color: #36404a;
					line-height: 20px;
					max-width: 480px;
					min-width: 200px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					display: block;
				}
			}

			.check-more-btn {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
				font-weight: 400;
				color: #a3a8ab;
				line-height: 20px;
				margin-top: 8px;
				height: 20px;
				cursor: pointer;
			}
		}

		.line {
			margin-top: 20px;
			width: 100%;
			height: 1px;
			background: #e6e6e6;
			border-radius: 9px;
		}

	}

	.loading-page {
		width: 100%;
		margin: 12px 0 25px;

		.load-btn {
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #fff8f1;
			border-radius: 6px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
			font-weight: 400;
			color: #f80;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}

	.course_content_box img {
		width: 100%;
	}

	.baomingBox {
		width: 100%;
		display: flex;
		justify-content: space-between;
		overflow: hidden;

		img {
			width: 340px;
			height: 255px;
			border-radius: 8px;
			object-fit: cover;
		}

		.meal-info {
			width: 350px;
			height: auto;

			.meal-title {
				font-size: 24px;
				color: #333;
			}

			.meal-tips {
				margin: 16px 0 20px;
				display: -webkit-flex;
				display: flex;
				-webkit-align-items: center;
				align-items: center;

				.meal-knob {
					font-size: 18px;
					color: #ff7c06;
				}
			}

			.meal-paynum {
				display: -webkit-flex;
				display: flex;
				align-items: center;

				.meal_old {
					font-size: 18px;
					color: #666;

					span {
						color: #999;
						text-decoration: line-through;
					}
				}

				.meal_new {
					font-size: 16px;
					color: #ff7c06;
					margin-left: 20px;
					display: flex;
					align-items: center;

					span {
						font-size: 28px;
					}
				}
			}

			.meal_btn {
				width: 136px;
				height: 48px;
				background: linear-gradient(163deg, rgba(253, 131, 21, 1) 0%, rgba(247, 82, 107, 1) 100%);
				-webkit-border-radius: 27px;
				border-radius: 27px;
				text-align: center;
				line-height: 48px;
				font-size: 20px;
				color: #fff;
				cursor: pointer;
				margin: 20px 0 10px;
			}

			.meal_agr span {
				font-size: 16px;
				color: #999;
			}

			.meal_agr a {
				font-size: 16px;
				color: #ff7c06;
			}

			a,
			a:link {
				text-decoration: none;
			}
		}
	}
</style>
<style type="text/css">
	.baoming_box .el-dialog__header {
		background-color: #f5f5f5 !important;
	}
</style>
