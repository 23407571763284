<template>
  
  <div style="display: flex; align-items: center; position: relative;">
      <input 
      name="points" 
      id="points"
      type="range"
      class="progress"
      :value="inputValue" 
      :max="duration" 
      min ="0" 
      step="1" 
      style="width: 718px;
      margin-bottom:3px;"
      disabled
      :style="
      {
        // background: '-webkit-linear-gradient(top, #FD6D6C, ##) 0% 0% / '+ inputValue*100/duration +'% 100% no-repeat'
        background: `-webkit-linear-gradient(top, ${inputBgc_A}, ${inputBgc_B}) 0% 0% / `+ leftbgc  +'% 100% no-repeat'

      }"
    >
    <div class="div_slot">
    <slot></slot>
    </div>
    <span id="schedule_span">{{ inputValue >= 100 ? 100 + '%' : inputValue + '%' }}</span>
  </div>
</template>

<script>
export default {
  props: {
    inputBgc_A:{
      type:String
    },
    inputBgc_B:{
      type:String
    },
    inputValue:{
       type:Number,
       default:'0'
    }
  },
  data () {
    return {
      duration:'100', // 最大值
      rangeA: null,
      span:null,
      cs:null,
      leftbgc:0
    }
  },
  mounted () {
    this.rangeA = document.getElementById('points');
    this.span = document.getElementById('schedule_span');
    // this.cs = window.getComputedStyle(rangeA, 0);
        // span.style.left = ((range.value - 1000) * parseInt(cs.width) / 9000
        //     + 18 - range.value/300) + "px";
        // span.innerHTML = range.value;
        // console.log(span.style.left);inputValue*100/duration +'% 100%
        this.leftbgc = Math.ceil((this.inputValue * 71.8 * 0.1 - 50) / 718 * 100 + 7) 
        this.span.style.left = (this.inputValue * 71.8 * 0.1 - this.inputValue - 25) + 'px'
        // console.log(this.span.style.left);
        // range.onchange(() => {
        // console.log((this.inputValue * 71.8 * 0.1) + 'px');
        // })
  },
  methods: {
    // rangeChange(value) {
    //   console.log(value);
    //     span.style.left = (this.inputValue * 71.8 * 0.1 - 30) + 'px'
    // }
  },
  watch: {
    inputValue(val) {
      if (val > 100) {
        return
      } else {
        this.leftbgc = Math.ceil((val * 71.8 * 0.1 - 50) / 718 * 100 + 7) 
        this.span.style.left = Math.ceil((val * 71.8 * 0.1 - val)) + 'px'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.progress{
  width: 100%;
  height: 40px;
}

input[type=range] {/*input外壳样式*/

    -webkit-appearance: none;

    border-radius: 10px 0 0 10px;

    height: 16px;

    z-index: 10;

}

input[type=range]::-webkit-slider-runnable-track {/*轨道*/
    height: 16px;
    background: rgba(0,0,0,0.1);
        border-radius: 10px 0 0 10px;


}

// input[type=range]::-webkit-slider-thumb {/*滑块*/

//     -webkit-appearance: none;
//     height: 26px;
//     width: 53px;
//     margin-top: -5px; /*使滑块超出轨道部分的偏移量相等*/
//     background: #ffffff;
//     border-radius: 12px 12px 12px 12px; /*外观设置为圆形*/
//     border: solid 0.125em rgba(205, 224, 230, 0.5); /*设置边框*/
//     // box-shadow: 0 .125em .125em #3b4547; /*添加底部阴影*/

//  }

 .div_slot{
    width: 151px; 
    height: 46px; 
    border-radius: 23px; 
    background-color: #FFFFFF; 
    text-align: center; line-height: 46px; 
    display: flex; 
    justify-content: space-around; 
    align-items: center;
    padding: 10px;
    margin-left: 10px;
 }

 #schedule_span{
  display: block;
  z-index: 9999;
  position: absolute;
  width: 53px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 12px 12px 12px 12px;
  background: rgba(255,255,255,1);
 }
</style>