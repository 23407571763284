<template>
  <div>
    <div class="tabs">
      <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading = 'loading' :class='{ no_data_tabs_info: !myMessageData.length }'>
      <div>
        <div class="myNews_top" v-if="!!myMessageData.length">
          <div class="all_read" @click="all_read" >全部标为已读</div>
          <div @click="del_all">删除全部</div>
        </div>
        <div class="myNews_bottom" v-for="(item,index) in myMessageData" :key="index">
          <div class="myNews_bottom_left" v-if="item.image">
            <img v-if="item.isVideo!='mp4'&&item.isVideo!='qt'" style="object-fit: cover" :src="/(http|https):\/\/([\w.]+\/?)\S*/.test(item.image) ? item.image : imgUrl + item.image" class="auto-size">
            <video v-else style="object-fit: cover" :src="/(http|https):\/\/([\w.]+\/?)\S*/.test(item.image) ? item.image : imgUrl + item.image" class="auto-size"></video>
          </div>
          <div class="myNews_bottom_right" @click="jump(item.id, item.path_id, item.msgtype)">
            <div class="myNews_bottom_right_top">
              {{ item.title }}
            </div>
            <div class="myNews_bottom_right_bottom" :style="{ width: item.image ? 816 + 'px' : 890 + 'px' }">
              <div style="color: #5A5A5A;">{{ item.msg }}</div>
              <div class="myNews_bottom_right_bottom_state_box">
                <div>{{ item.dt }}</div>
                <div class="myNews_bottom_right_bottom_state">
                  <!-- 已读 -->
                  <div class="read" v-if="item.has_option.receiveflag">
                    <img src="../../../../assets/image/对 1.png" style="vertical-align:middle">
                    <span style="vertical-align: middle; line-height: 16px;">&nbsp;&nbsp;已读</span>
                  </div>
                  <!-- 未读 -->
                  <div class="unread" v-else>
                    <div class="unread_identify"></div>
                    <span>未读</span>
                  </div>
                  <div @click="del_myNews($event, item.has_option.sysmessage_id)">
                    <img style="vertical-align: middle" src="../../../../assets/image/删除.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="myload" v-if="isShow">
          <span class="myload_more" v-if="load" @click="loadMore">点击加载更多...</span>
          <span v-if="noMore">没有更多了</span>
        </div>
      </div>
      <no_data v-if="!myMessageData.length"></no_data>
    </div>
    <el-dialog :visible.sync="del_dialog" class="del_news_dialog" width="361px" :show-close="false">
      <div class="del_news_dialog_text">
        确定删除？
      </div>
      <div class="del_news_dialog_btn">
        <button class="news_dialog_btn" @click="ensure_del">确定</button>
        <button class="news_dialog_btn news_dialog_cancel_btn" @click="undelete">取消</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isImg: true,
      imgUrl: process.env.VUE_APP_IMG_URL,
      del_dialog: false,
      loading: false,
      del_id: '',   //删除单个
      all_del: false, //是否删除全部
      tabsList: [
        {
          tabs_title: '消息'
        },
        {
          tabs_title: '通知'
        }
      ],
      tabs_active: 0,
      // total: 0,
      type: 1,     // 1 消息， 2通知
      myMessageData: [],   // 我的消息数据
      page:1,
      load:true,
      noMore:false,
      isShow:false
    }
  },
  methods: {
    // tabs 切换
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
      this.type = index + 1
      this.page=1
      this.getMyNews()
    },

    getMyNews() {
      this.loading = true
      if(this.page==1)this.myMessageData=[]
      this.load=false
      this.$axios({
        method:'POST',
        url:'api/officialWebsite/computer/yuan_cheng/my_message',
        data:{
          type: this.type,
          list_rows:10,
          page:this.page
        }
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          let list=res.data.list
          this.isShow=Boolean(list.total>10)
          this.noMore=!Boolean(list.to < list.total)
          this.load=!this.noMore
          res.data.list.data.forEach(el => {
            if(el.image){
              let arr=el.image.split(".")
              el.isVideo=arr[arr.length-1]
            }
          });
          this.myMessageData.push(...res.data.list.data)
          // this.total = total
        }
      })
    },

    // 删除单个
    del_myNews(e, id) {
      e.stopPropagation()
      this.del_id = id
      this.del_dialog = true
    },
    // 取消删除
    undelete() {
      this.all_del = false
      this.del_dialog = false
    },
    // 确定删除
    ensure_del() {
      this.loading = true
      if (!this.all_del) {
        this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/del_message',
        data: {
          id: this.del_id
        }
        }).then(res => {
          this.loading = false
          console.log(res);
          if (res.code === 200) {
            this.getMyNews()
          }else{
            this.$notify.error({
              title: '错误',
              message: `${res.msg}`
            });
          }
        })
      } else {
        // 删除全部
        this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/del_message'
        }).then(res => {
          this.loading = false
          console.log(res);
          if (res.code === 200) {
            this.getMyNews()
          }
        })
      }
      this.del_dialog = false
    },
    // 全部标为已读
    all_read() {
      console.log('all_read');
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/message_has_read'
      }).then(res => {
        if (res.code === 200) {
          this.getMyNews()
          this.$store.dispatch("pullUserInfoByToken")
        }else{
          this.$notify.error({
              title: '错误',
              message: `${res.msg}`
            });
        }
      })
    },

    // 删除全部
    del_all() {
      this.all_del = true
      this.del_dialog = true
    },
    jump(id, path_id, msgtype) {
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/message_has_read',
        data: {
          id: id,
        }
      }).then(res => {
        if (res.code === 200) {
          this.newToWork(path_id, msgtype)
        }else{
          this.$notify.error({
              title: '提示',
              message: `${res.msg}`
            });
        }
      })
      
    },
    // 消息跳转作业
    newToWork(path_id, msgtype) {
      if (msgtype == 2 || msgtype == 3 || msgtype == 6 || msgtype == 8 ) {
        this.$axios({
          method: 'POST',
          url: 'api/officialWebsite/computer/yuan_cheng/get_data_by_path_id',
          data: {
            msgtype: msgtype,
            path_id: path_id
          }
        }).then(res => {
          console.log(res);
          if (res.code === 200) {
            // 未提交
           if(msgtype == 3) {
            this.$router.push({
              path: '/work',
              query: {
                syllabus_id: res.data.syllabus_id,
                homework_id: res.data.homework_id,
                classmat_id: res.data.classmat_id,
                isNews: 1,
                newTabIndex: this.tabs_active,
                newType: this.type
              }
            })
           }else{
            // 已提交
            this.$router.push({
              path: '/work',
              query: {
                syllabus_id:  res.data.syllabus_id,
                homework_id:  res.data.homework_id,
                homereadover_id:  res.data.homereadover_id,
                classmat_id:  res.data.classmat_id,
                isNews: 1 ,
                newTabIndex: this.tabs_active,
                newType: this.type
              }
            })
           }
           this.$store.dispatch("pullUserInfoByToken")
          }else{
            this.$notify.error({
              title: '错误',
              message: `${res.msg}`
            });
          }
        })
      }else {
         this.$notify({
          title: '提示',
          message: '暂无可跳转项',
          type: 'warning'
        });
      }
    },
    loadMore(){
      this.page++
      this.getMyNews()
    },
  },

  created () {
    this.tabs_active = Number(this.$route.query.newTabIndex)
    this.type = Number(this.$route.query.newType)
    if (isNaN(this.tabs_active) && isNaN(this.type)) {
      this.tabs_active = 0
      this.type = 1
    }
    this.getMyNews()
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  padding: 40px 66px 60px 27px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.myNews_top{
  display: flex;
  justify-content: end;
  cursor: default;
}

.all_read{
  margin-right: 50px;
}

.myNews_bottom{
  width: 955px;
  height: 112px;
  margin: 10px 0;
  padding: 19px 18px;
  display: flex;
  border-radius: 16px 16px 16px 16px;
  background: rgba(249,249,249,1);
}

.myNews_bottom_left{
  width: 74px;
  height: 74px;
  border-radius: 11px 11px 11px 11px;
  // background-color: aqua;
}
.myNews_bottom_left img{
  border-radius: 11px;
}
.myNews_bottom_right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
}

.myNews_bottom_right_bottom{
  font-size: 14px;
  // width: 819px;
  color: #979797;
}
.myNews_bottom_right_top{
  font-size: 18px;
}
.myNews_bottom_right_bottom_state_box{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.myNews_bottom_right_bottom_state{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.read{
  margin-right: 50px;
}

.unread{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;

}

.unread_identify{
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 10px;
  background: rgba(0,125,252,1);
}

.pagination{
  // margin-left: 56%;
  // transform: none
}

.no_data_tabs_info{
  justify-content: start
}
</style>

<style lang="less" scoped>
/deep/ .el-pagination{
  display: flex;
  justify-content: end;
}
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}


/deep/ .del_news_dialog .el-dialog {
    height: 252px;
    margin-top: 40vh !important;
    border-radius: 22px;
    background: rgba(255,255,255,1);
  }
 /deep/ .del_news_dialog_text  {
    font-size: 22px;
    color: #5A5A5A;
    text-align: center;
    padding: 0 0 75px 0;

 }
 .del_news_dialog_btn{
  display: flex;
  justify-content: space-between;
 }
 .news_dialog_btn{
  width: 98px;
  height: 43px;
  border-radius: 21px;
  text-align: center;
  line-height: 43px;
  font-size: 20px;
  color: #FFFFFF;
  background: rgba(0,125,252,1);
 }
 
 .news_dialog_cancel_btn{
  background: rgba(164,164,164,1);
 }
 /deep/ .el-dialog__body {
  padding: 30px 40px;
 }
 .myload{
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  color: #aeaeae;
  user-select: none;
 }
 .myload_more:hover{
  color: #000;
 }
 .myload_more{
  cursor: pointer;
 }
</style>