<template>
  <div class="box">
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading="loading">
      <div class="bangding_sj">
        <div class="myID">你的ID: {{ ID }}</div>

        <!-- 已绑定手机号  显示当前绑定的手机号 -->
        <div class="current_mobile_box">
          <div class="current_mobile_box" v-if="!isShow_bangding">
            <div class="current_mobile_text">
            当前绑定的手机号: <span>{{ tel.substr(0,3)+"****"+tel.substr(7) }}</span>
            </div>
            <div class="edit_current_mobile" @click="edit_current_mobile_btn">
              修改
            </div>
          </div>

        <!-- 手机号  验证码 -->
          <div style="margin-top: 20px;" v-else>
            <el-form label-width="60px">
              <el-form-item label="手机号: ">
                <el-input placeholder="请输入新的手机号" class="shuru_sj" v-model="bind_tel.tel"></el-input>
              </el-form-item>

              <el-form-item label="验证码: ">
                <el-input placeholder="请输入验证码" class="shuru_sj" v-model="bind_tel.code">
                 <template #suffix>
                  <!-- <span style="color:#007DFC">获取验证码</span> -->
                  <button type="button" :disabled="disabled" style="color:#007DFC" @click="sendcode">{{ btntxt }}</button>
                 </template>
                </el-input>
              </el-form-item>
            </el-form>

            <div class="bangding_btn" @click="bangding_btn">绑定</div>
          </div>
        </div>
      </div>

      
        <!-- 验证通过弹框 -->
     <div class="div_mask" v-if="code_pass_dialog">
      <div class="mask_text">{{ code_pass_dialog_text }}</div>
     </div>
    </div>
    <!-- 修改手机号弹窗/ 确认当前手机号 -->
       <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          center
          class="shuru_sj_dialog"
        >
        <div>
          <div style="font-size: 20px;">当前绑定的手机号: <span>{{ tel.substr(0,3)+"****"+tel.substr(7) }}</span></div>
          <div class="confirm_mobile_box">
            <div style="text-align: center;">请确认当前绑定手机号：</div>
            <el-input v-model="tel_input"></el-input>
          </div>
        </div>
          <span slot="footer" class="dialog-footer">
            <button type="button"  @click="edit_tel_confirm_btn" class="dialog-footer-btn">确认</button>
            <button type="button"  @click="dialogVisible = false" class="dialog-footer-btn quxiao">取消</button>
          </span>
        </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tabsList: [
        {
          tabs_title: '绑定手机'
        }
      ],
      tabs_active: 0,   // 当前选中的tabs
      tel: JSON.parse(localStorage.getItem("userInfo")).tel, // 用户绑定的手机号

      ID: JSON.parse(localStorage.getItem("userInfo")).id,
      tel_input: '',  //用户输入验证的手机号
      dialogVisible: false, // 确认手机号弹窗
      disabled: false,
      btntxt: '获取验证码',

      code_pass_dialog: false,   // 验证是否通过弹框
      code_pass_dialog_text: '',  // 验证通过弹框 上的文字

      isShow_bangding: false,     // 验证通过后显示绑定新手机号页面

      // 要绑定的手机号/验证码
      bind_tel:{
        tel:'',
        code:''
      },

      TIMER: null
    }
  },
  methods: {
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },
    // 修改手机号btn
    edit_current_mobile_btn() {
      this.dialogVisible = true
    },

    // 绑定手机号按钮
    bangding_btn() {
      let _this = this
      _this.loading = true
      _this.$axios({
        method:'POST',
        url:'api/officialWebsite/computer/yuan_cheng/binding_phone',
        data:{
          ..._this.bind_tel
        }
      }).then(res => {
         if (res.code === 200) {
          console.log(res.data);
          _this.$notify({
            title: '提示',
            message: `绑定成功`,
            type: 'success'
          });
          _this.tel = _this.bind_tel.tel
          _this.isShow_bangding = false
          _this.bind_tel.tel = ''
          _this.bind_tel.code = ''
          clearTimeout(this.TIMER)
          this.btntxt = '获取验证码'
          this.disabled = false
          this.$store.dispatch("pullUserInfoByToken")

          // location.reload()
         }else{
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
         }
         _this.loading = false
      })
    },

    // 验证手机号确定事件
    edit_tel_confirm_btn() {
      this.dialogVisible = false
      this.loading = true
      this.$axios({
        method:'POST',
        url:'api/officialWebsite/computer/yuan_cheng/validation_phone',
        data:{
          tel: this.tel_input
        }
      }).then(res => {
        this.code_pass_dialog = true
        if (res.code === 200) {
          this.code_pass_dialog_text = '验证通过'
          this.tel_input = ''
          this.isShow_bangding = true
        } else {
          this.code_pass_dialog_text = '验证失败'
        }
        setTimeout(() => {
          this.code_pass_dialog = false
        },1500)
        this.loading = false
      })
    },
    // 发送验证码
    sendcode() {
      if (!this.bind_tel.tel) {
        this.$notify({
          title: '警告',
          message: `请输入手机号`,
          type: 'warning'
        });
        return
      }
      this.loading = true
      this.$axios({
        method:'POST',
        url:'api/officialWebsite/send_code',
        data:{
          tel: this.bind_tel.tel,
          type: 5
        }
      }).then(res => {
         if (res.code === 200) {
          this.time = 60;
          this.timer();
         } else {
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
         }
      this.loading = false
      }) 
   },
   // 验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "秒";
        this.TIMER = setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "重新获取";
        this.disabled = false;
      }
   },
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}
.box{
  position: relative;
}
.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
    padding: 100px 81px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.myID{
  color: #999999;
  font-size: 20px;
}

.current_mobile_box{
  display: flex;
}

.current_mobile_text{
  color: #2C2C2C;
  text-align: center;
  height: 41px;
  line-height: 41px;

}
.edit_current_mobile{
  width: 110px;
  height: 41px;
  border-radius: 20px;
  color: #FFFFFF;
  text-align: center;
  line-height: 41px;
  margin-left: 20px;
  background: rgba(0,125,252,1);
}
.dialog-footer{
  display: flex;
  justify-content: space-between;
  // padding: 0 5px;
}
.confirm_mobile_box{
  margin-top: 30px;
}

.bangding_btn{
  margin-top: 100px;
  width: 150px;
  height: 41px;
  border-radius: 20px;
  color: #FFFFFF;
  text-align: center;
  line-height: 41px;
  background: rgba(0,125,252,1);
}


.div_mask{
  width: 100%;
  height: 100%;
	z-index:1000;
	position: absolute;
	left:0px;
	top:0px;
	background-color: #000000;
	/*透明度*/
	opacity: 0.4; 
	text-align: center;
	vertical-align: middle;
}
.mask_text {
  height: 59px;
  background: #FFFFFF;
  color: #000000;
  line-height: 59px;
  width: 164px;
  border-radius: 7px;
  margin: 30% auto;
}

.dialog-footer-btn{
  width: 92px;
  height: 42px;
  border-radius: 21px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  background: rgba(0,125,252,1);
}

.quxiao{
  background: rgba(177,177,177,1);
}
</style>

<style lang="less" scoped>
/deep/ .shuru_sj_dialog .el-dialog{
  margin-top: 40vh !important;
  width: 450px !important;
  height: 325px;
  border-radius: 32px;
  padding: 0 82px;
  background: rgba(255,255,255,1);
}

/deep/ .shuru_sj_dialog .el-input__inner {
  height: 40px;
  border: none;
  border-radius: 20px 20px 20px 20px;
  background: rgba(231,231,231,1);
}

/deep/.shuru_sj_dialog .el-dialog--center .el-dialog__body {
  padding: 35px 0;
}

/deep/.shuru_sj_dialog .el-dialog__footer{
  padding: 0;
}

/deep/.shuru_sj_dialog .el-input__suffix{
  right: 20px
}

/deep/ .shuru_sj .el-input__inner{
  width: 276px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background: rgba(231,231,231,1);
}
/deep/ .shuru_sj .el-input__suffix{
  right: 20px
}
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}
</style>