import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import LibGenerateTestUserSig from './lib/lib-generate-test-usersig-es.min.js'
import Bus from '../assets/js/Bus.js'
import store from '../store/index.js'
import router from '../router/index.js'
import Axios from '../axios/index.js'

/**
 * @param {Object} cid  章节id
 * tim登录  
 */
function timLogin(cid) {
	let that = this, tim = null;
	return new Promise((resolve, reject) => {
		if(!cid) {
			reject('章节ID不存在')
			return
		}
	
		let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
	
		if(!(userInfo && userInfo.timid)) {   //未登录
			router.push('/');
			reject('未登录')
			return
		}
		
		tim = store.state.tim;
		if (tim) {
			console.log('IM已存在tim:', tim)
			resolve(tim)
			return
		}
		store.dispatch("getSiteConfig").then(res => {
			const IMData = {
				tcPlayerId: 'player-container-id',
				IMSDKAPPID: Number(store.state.imConfig.sdk_app_id),
				IMSDKSECRETKEY: store.state.imConfig.secret_key,
			}
			tim = TIM.create({   // SDK 实例 tim 
				SDKAppID: IMData.IMSDKAPPID 
			});
			tim.setLogLevel(0); 
			tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
			let userId = userInfo.timid + '_cid' + cid;
			userId = String(userId)
			
			//生成IM签名
			let generator = new LibGenerateTestUserSig(IMData.IMSDKAPPID, IMData.IMSDKSECRETKEY, 604800);
			let userSig = generator.genTestUserSig(userId);
			console.log('userId:', userId, userSig)
			// 2、登录
			// window.tim = tim;
			let promise = tim.login({userID: userId, userSig: userSig});
			promise.then(function(imResponse) {
			  // console.log('登录成功:', imResponse.data); // 登录成功
			  store.commit('setTim', tim)
			  if (imResponse.data.repeatLogin === true) {
			      // 标识账号已登录，本次登录操作为重复登录
			      console.log(imResponse.data.errorInfo);
			    }
				
				//监听初始化
				tim.on(TIM.EVENT.SDK_READY, function(event) {
					// console.log('全局监听到初始化:', event)
				
					// 更新个人资料-设置自己的昵称和头像
					const sex = userInfo.sex ? userInfo.sex : 1
					console.log('个人资料:', userInfo)
					console.log('个人资料:', userInfo.name)
					let promise_my = tim.updateMyProfile({
						nick: userInfo.name,
						avatar: userInfo.headico || 'https://static.shanchuangjiaoyu.com/mini/20210629/static/headico.jpg',
						gender: userInfo.sex == 1 ? TIM.TYPES.GENDER_MALE : TIM.TYPES.GENDER_FEMALE,
						selfSignature: userInfo.usesig ? userInfo.usesig : '我的签名',
						allowType: TIM.TYPES.ALLOW_TYPE_ALLOW_ANY
					});
					promise_my.then(function(imResponse) {
						console.log('更新个人资料成功:', imResponse.data); // 更新资料成功
					}).catch(function(imError) {
						console.warn('更新个人资料updateMyProfile error:', imError); // 更新资料失败的相关信息
						console.log(imError)
					});
				});
				registerEvents(tim);
				resolve(tim)
			}).catch(function(imError) {
			  console.warn('login error:', imError); // 登录失败的相关信息
			  reject(imError)
			});
		}).catch(err => {
			// console('获取IM配置失败')
			reject('获取IM配置失败')
		})
		
	})
}

function registerEvents(tim) {
	tim.off(TIM.EVENT.MESSAGE_RECEIVED, messageReceived, this)
	tim.off(TIM.EVENT.KICKED_OUT, kickOut, this)
	tim.on(TIM.EVENT.MESSAGE_RECEIVED, messageReceived, this)
	tim.on(TIM.EVENT.KICKED_OUT, kickOut, this)
}

// SDK 收到推送的单聊、群聊、群提示、群系统通知的新消息
function messageReceived(event) {
	for (let i = 0; i < event.data.length; i++) {
		let item = event.data[i]
		// console.log('全局监听到的消息：', item)
		Bus.$emit('createMessage', {message: item, type: 2})
	}
}
// 用户被踢下线时触发事件
function kickOut(event) {
	// console.log('全局监听到用户被踢下线：', event)
	Bus.$emit('kickOut', event)
}

export default {
	timLogin
}