<template>
	<div>
		<!-- 兼职中心导航栏 -->
		<el-menu class="jianzhi_daohang" :default-active="activeIndex" text-color="#FFFFFF" active-text-color="#ffffff"
			background-color="#C82114" mode="horizontal">
			<div style="width: 1200px;margin: 0 auto;justify-content: space-between;display: flex;">
				<el-menu-item style="padding: 0;margin-right: 10px;" @click="goIndex">
					<img src="../../assets/image/svgImg/jianzhi_logo.svg" style="width: 30px;">
				</el-menu-item>
				<!-- <el-menu-item index="1" style="" @click="danghanglan(1)">兼职中心</el-menu-item> -->
				<el-menu-item index="2" style="" @click="danghanglan(2)">讲师作品</el-menu-item>
				<el-menu-item index="3" style="" @click="danghanglan(3)">课程大纲</el-menu-item>
				<el-menu-item index="4" style="" @click="danghanglan(4)">兼职作品</el-menu-item>
				<el-menu-item index="5" style="" @click="danghanglan(5)">学员评价</el-menu-item>
				<el-menu-item index="6" style="" @click="danghanglan(6)">排行榜单</el-menu-item>
				<el-menu-item index="7" style="" @click="danghanglan(7)">机构优势</el-menu-item>
				<el-menu-item index="8" style="" @click="danghanglan(8)">兼职方式</el-menu-item>
				<el-menu-item index="9" style="" @click="danghanglan(9)">常见问题</el-menu-item>
				<div class="pointer" @click="tz_wqty"
					style="display: flex;justify-content: space-between;padding: 8px 22px;background-color: #FFFFFF;width: 124px;height: 35px;border-radius: 4px;margin-top: 10px;">
					<div style="width: 14px;height: 18px;">
						<img style="width: 100%;height: auto;" src="../../assets/jianzhizhongxin/myorder@2x.png">
					</div>
					<div style="color: #333333;font-size: 14px;">任务大厅</div>
				</div>
				<div class="pointer" @click="click_list"
					style="display: flex;justify-content: space-between;padding: 8px 12px;background-color: #FFFFFF;width: 124px;height: 35px;border-radius: 4px;margin-top: 10px;">
					<div style="width: 18px;height: 18px;">
						<img style="width: 100%;height: auto;" src="../../assets/jianzhizhongxin/paihang@2x.png">
					</div>
					<div style="color: #333333;font-size: 14px;">薪资排行榜</div>
				</div>
			</div>
		</el-menu>
	</div>
</template>

<script>
import Bus from "../../assets/js/Bus";
	export default {
		name: 'HeaderJob',
		data() {
			return {
				activeIndex: '1',
				tz_url:process.env.VUE_APP_BASE_WQT_URL
			}
		},

		methods: {
			tz_wqty(){
				    this.$confirm('即将跳转至合作方【万企通】官网，是否继续访问？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
            window.open(this.tz_url,'_blank')
        }).catch(() => {
          
        });
				
			},
			goIndex() {
				this.$router.push('./#')
				// window.location.href = 'https://www.yuanchengedu.net/'
			},
			// click_renwu() {
			// 	this.$router.push('./renwu')
			// 	document.querySelector('#nav').scrollIntoView(true);
			// },
			//排行榜
			click_list() {
				this.$router.push('/jobList')
				document.querySelector('#nav').scrollIntoView(true);
			},


      danghanglan(value) {
        Bus.$emit("danghanglan", value)
        // console.log("danghanglan")
      },
		}
	}
</script>

<style scoped>
	.jianzhi_daohang {
		background: #FFFFFF;
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin: 0;
		position: fixed;
		z-index: 1000;
		width: calc(100% - 17px) !important;
	}

	.el-menu.el-menu--horizontal {
		border-bottom: none !important;
	}

	.jzzp {
		background-color: #FFFFFF;
		width: 83px;
		height: 27px;
		color: #666666;
		border-radius: 13.5px;
		padding: 6px 10px;
		font-size: 12px;
		margin-top: 8px;
		margin-right: 20px;
		text-align: center;
	}

	.jzzp:hover {
		background-color: #FC661C;
		width: 83px;
		height: 27px;
		color: #FFFFFF;
		border-radius: 13.5px;
		padding: 6px 10px;
		font-size: 12px;
		margin-top: 8px;
		margin-right: 20px;
		text-align: center;
	}
	.el-menu-item {
		padding: 0 10px;
		line-height: 56px !important;
	}
</style>
