<template>
  <div>
    <div class="myContent">
      <div class="myContent_wrap">
        <div :class="{ myContent_left: !myContent_userinfo, userinfo_left: myContent_userinfo}">
          <!-- 左侧菜单 -->
            <div v-if="!myContent_userinfo">
              <div class="menu_left" 
                :class="{ menu_left_active: current_menu_left === index, noClick: index == 4 || index == 7 }"
                v-for="(item, index) in menu_left_list" :key="item.id" @click="menu_left_click(index, item.router)">
                <div class="menu_left_img" v-if="item.img" :class="{ vx_img_size: index == 11 }">
                  <img :src="item.img" class="auto-size" :class="{ white: current_menu_left === index}">
                </div>
                <div style="cursor: pointer;">{{ item.menu_name }}</div>
              </div>
            </div>
            <div v-else>
              <div class="menu_left" 
                :class="{ userinfo_current_menu_left: userinfo_current_menu_left === index }"
                v-for="(item, index) in userinfo_menu_left_list" :key="index" @click="userinfo_menu_left_click(index, item.router)">
                <div class="menu_left_img" v-if="item.img">
                  <img   :src="item.img" class="auto-size" :class="{ white: userinfo_current_menu_left === index}">
                </div>
                <div style="cursor: pointer;">{{ item.menu_name }}</div>
              </div>
            </div>
        </div>
        <div class="myContent_right">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myContent_userinfo:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 左侧菜单项
      menu_left_list: [
        {
          id: '0',
          menu_name: '我的课程',
          router: '/personalCenter/myKeCheng',
          img: require('../../../../assets/image/mycenter_menu/课程.png')
        },
        {
          id: '1',
          menu_name: '我的作业',
          router: '/personalCenter/myWork',
          img: require('../../../../assets/image/mycenter_menu/作业.png')

        },
        {
          id: '2',
          menu_name: '我的收藏',
          router: '/personalCenter/myCollect',
          img: require('../../../../assets/image/mycenter_menu/收藏.png')

        },
        {
          id: '3',
          menu_name: '我的学分',
          router: '/personalCenter/myXueFen',
          img: require('../../../../assets/image/mycenter_menu/学分.png')

        },
        {
          id: '4',
          menu_name: '——',

        },
        {
          id: '5',
          menu_name: '我的订单',
          router: '/personalCenter/myOrder',
          img: require('../../../../assets/image/mycenter_menu/订单.png')

        },
        // {
        //   id: '6',
        //   menu_name: '我的卡包',
        //   router: '/personalCenter/myKaBao',
        //   img: require('../../../../assets/image/mycenter_menu/卡包.png')

        // }, 
        {
          id: '7',
          menu_name: '我的消息',
          router: '/personalCenter/myNews',
          img: require('../../../../assets/image/mycenter_menu/消息.png')

        },
        {
          id: '8',
          menu_name: '——',
        },
        {
          id: '12',
          menu_name: '我的发票',
          router: '/personalCenter/myInvoice',
          img: require('../../../../assets/image/mycenter_menu/消息.png')
        },
        {
          id: '9',
          menu_name: '我的荣誉',
          router: '/personalCenter/myHonor',
          img: require('../../../../assets/image/mycenter_menu/荣誉.png')

        },
        // {
        //   id: '10',
        //   menu_name: '我的证书',
        //   router: '/personalCenter/myCertificate',
        //   img: require('../../../../assets/image/mycenter_menu/证书.png')

        // },
        {
          id: '11',
          menu_name: '绑定微信',
          router: '/personalCenter/bindingVX',
          img: require('../../../../assets/image/mycenter_menu/微信.png')

        },
      ],
      current_menu_left: 0,  //当前选中的左侧菜单

      userinfo_menu_left_list:[
        {
          id: '0',
          menu_name: '个人资料',
          router: '/personalCenter/userinfo',
          img: require('../../../../assets/image/mycenter_menu/我的-个人资料.png')
        },
        // {
        //   id: '1',
        //   menu_name: '更改密码',
        //   router: '/personalCenter/edit_password',
        //   img: require('../../../../assets/image/mycenter_menu/密码.png')
        // },
        // {
        //   id: '2',
        //   menu_name: '绑定邮箱',
        //   router: '/personalCenter/bangding_email',
        //   img: require('../../../../assets/image/mycenter_menu/邮箱.png')
        // },
        // {
        //   id: '3',
        //   menu_name: '绑定手机',
        //   router: '/personalCenter/bangding_sj',
        //   img: require('../../../../assets/image/mycenter_menu/手机.png')
        // },
        // {
        //   id: '4',
        //   menu_name: '注销账号',
        //   router: '/personalCenter/unsubscribe',
        //   img: require('../../../../assets/image/mycenter_menu/off.png')
        // },
      ],

      userinfo_current_menu_left: 0,
    }
  },
  created() {
    // if (!this.myContent_userinfo) {
      // if (this.$route.path != '/personalCenter/myKeCheng') {
      // this.$router.push(this.menu_left_list[this.current_menu_left].router)
      // this.$router.push(this.userinfo_menu_left_list[this.userinfo_current_menu_left].router)
      // }
    // }
    if(window.name == ""){
      window.name = "isReload";  // 在首次进入页面时我们可以给window.name设置一个固定值 
      this.$router.push(this.menu_left_list[this.current_menu_left].router)
    }else if(window.name == "isReload"){
      let list = this.userinfo_menu_left_list.map(val => val.router)
      if (list.includes(this.$route.fullPath.split('?')[0])) {
        this.$emit('userinfo', true)
        list.forEach((val, ind) => {
          if (val === this.$route.fullPath.split('?')[0]){
            this.userinfo_current_menu_left =  ind
          }
        })
      }else {
        this.$router.push(this.menu_left_list[this.current_menu_left].router)
      }
    }

  },
  methods: {
    // 左侧菜单点击事件
    menu_left_click(index, router) {
      this.current_menu_left = index
      // 路由跳转
      this.$router.push(router)
    },

    userinfo_menu_left_click(index, router) {
      this.userinfo_current_menu_left = index
      // 路由跳转
      this.$router.push(router)
    }
  },
  watch: {
    // myContent_userinfo(val) {
    //   if (val) {
    //     this.userinfo_current_menu_left = 0
    //     this.$router.push(this.userinfo_menu_left_list[0].router)
    //   }
    // },
    $route: {
      handler: function(val, oldVal){
        let uInfo_all_routerList = this.userinfo_menu_left_list.map(item => item.router)
        let current_uInfo = uInfo_all_routerList.indexOf(val.fullPath.split('?')[0])
        if (current_uInfo != -1) {
          this.$nextTick(() => {
            this.$emit('userinfo', true)
          })
          
          if (this.myContent_userinfo) {
          if (current_uInfo != this.userinfo_current_menu_left) {
            this.userinfo_current_menu_left = current_uInfo
          }
        }
        }
        // let all_router = [...this.menu_left_list, ...this.userinfo_menu_left_list]
        let all_routerList = this.menu_left_list.map(item => item.router)
        let current = all_routerList.indexOf(val.fullPath.split('?')[0])
        // if (current != this.current_menu_left) {
        //   this.current_menu_left = current
        // }
        if (current != -1) {
          this.$nextTick(() => {
            this.$emit('userinfo', false)
          })
          this.current_menu_left = current
          if (!this.myContent_userinfo) {
            if (current != this.current_menu_left) {
            this.current_menu_left = current
          }
          }
        }
      },
      // 深度观察监听
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.myContent {
  width: 1245px;
  height: 100%;
  margin: 0 auto;
  margin-top: 59px;
  padding-bottom: 59px;
}

.myContent_wrap {
  display: flex;
  background-color: #F7F7F7;
}

.myContent_left {
  width: 162px;
  max-height: 540px;
  border-radius: 21px 21px 21px 21px;
  background-color: #FFFFFF;
  margin-right: 35px;
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userinfo_left{
  width: 162px;
  height: 280px;
  border-radius: 21px 21px 21px 21px;
  background-color: #FFFFFF;
  margin-right: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myContent_right {
  width: 1045px;
  // height: 612px;
  border-radius: 0 0 21px 21px;
  // // background: rgba(255, 255, 255, 1)
  // background-color: aqua;
}

.menu_left {
  width: 128px;
  height: 49px;
  // margin: 15px 0;
  line-height: 49px;
  text-align: center;
  border-radius: 24.5px 24.5px 24.5px 24.5px;
  display: flex;
  padding: 0 10px;
  justify-content: space-around;
  align-items: center;
  // background: rgba(0, 125, 252, 1)
}

.menu_left_img{
  // width: 18px;
  // height: 19px;
}


.menu_left_active {
  color: aliceblue;
  background: #074DFE;
}

.noClick {
  cursor: not-allowed;
  color: #C7C7C7;
  pointer-events: none;
}

// .jianbian_btn {
//   // background-color: red;
//   background-image: linear-gradient(90deg, #338BFF, #074DFE);
// }
.userinfo_current_menu_left{
  color: aliceblue;
  background-image: linear-gradient(90deg, #338BFF, #074DFE);
}

.white {
    filter: brightness(100);
}

.vx_img_size{
  width: 21px !important;
  height: 19px !important;
}
</style>

<style>

</style>