<template>
  <div>
    <!-- 公司详情 -->
    <div class="detail-top">
      <div class="detail-top-center">
        <img :src="detail.pic" alt="" class="gongsi_logo" />
        <div>
          <div class="detail-name">{{ detail.company_name }}</div>
          <div class="detail-biaozhi">
            {{
              detail.nature == 1
                ? "集团"
                : detail.nature == 2
                ? "个人"
                : detail.nature == 3
                ? "国有"
                : detail.nature == 4
                ? "外资"
                : detail.nature == 5
                ? "有限公司"
                : ""
            }}

            ·
            {{
              detail.scale == 1
                ? "0-20人"
                : detail.nature == 2
                ? "21-99人"
                : detail.nature == 3
                ? "100-499人"
                : detail.nature == 4
                ? "500-999人"
                : detail.nature == 5
                ? "999+人"
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="detail_content">
      <div class="detail_content_title">公司简介</div>
      <div class="detail_content_jieshao" v-html="detail.introduction"></div>
      <div class="detail_content_title" v-if="detail.company_album.length>0">公司环境</div>
      <div class="detail_tu" v-if="detail.company_album.length>0">
        <el-carousel :interval="2000" arrow="always" height="645px">
          <el-carousel-item
            v-for="(item, index) in detail.company_album"
            :key="index"
          >
            <img :src="item.image" class="carImg" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="detail_content_title">工商信息</div>
      <div class="detail_gongshang">
        <div style="display: flex">
          <div>
            <span class="detail_gongshang_label">公司名称:</span>
            <span class="detail_gongshang_value">{{
              detail.company_name
            }}</span>
          </div>
          <div style="margin: 0 88px">
            <span class="detail_gongshang_label">公司规模:</span>
            <span class="detail_gongshang_value">{{
              detail.scale == 1
                ? "0-20人"
                : detail.nature == 2
                ? "21-99人"
                : detail.nature == 3
                ? "100-499人"
                : detail.nature == 4
                ? "500-999人"
                : detail.nature == 5
                ? "999+人"
                : ""
            }}</span>
          </div>
          <div>
            <span class="detail_gongshang_label">公司性质:</span>
            <span class="detail_gongshang_value">
              {{
                detail.nature == 1
                  ? "集团"
                  : detail.nature == 2
                  ? "个人"
                  : detail.nature == 3
                  ? "国有"
                  : detail.nature == 4
                  ? "外资"
                  : detail.nature == 5
                  ? "有限公司"
                  : ""
              }}
            </span>
          </div>
        </div>

        <div class="detail_gongshang_flex">
          <span class="detail_gongshang_label">纳税人识别号：</span>
          <span class="detail_gongshang_value">{{ detail.tax_number }}</span>
        </div>
        <div class="detail_gongshang_flex">
          <span class="detail_gongshang_label">公司注册地址：</span>
          <span class="detail_gongshang_value">{{detail.company_address}}</span>
        </div>
        <div class="detail_gongshang_flex">
          <span class="detail_gongshang_label">工作地点：</span>
          <span class="detail_gongshang_value">
            {{ detail.company_address}}{{detail.registered_address}}</span>
        </div>
        <div class="detail_gongshang_flex">
          <span class="detail_gongshang_label">公司简介：</span>
          <span
            class="detail_gongshang_value"
            v-html="detail.introduction"
          ></span>
        </div>
      </div>
      <div class="detail_content_title" v-if="type==1&&detail.recruitment_one">推荐职位</div>
      <div class="detail_tuijian" v-if="detail.recruitment_one">
        <div class="detail_tuijian_list">
          <div style="width: 100%">
            <div style="display: flex; justify-content: space-between">
              <span class="detail_tuijian_list_job"
                >{{ detail.recruitment_one.job_title }}[{{
                  detail.province_city_district[0].title
                }}-{{ detail.province_city_district[1].title }}]</span
              >
              <span class="detail_tuijian_list_xinzi"
                >{{ detail.recruitment_one.min_salary }}-{{
                  detail.recruitment_one.max_salary 
                }}K ·
                <span
                  v-for="(item, index) in detail.recruitment_one.label"
                  :key="index"
                >
                  {{ item }}
                </span>
              </span>
            </div>
            <div
             style="white-space: pre-wrap;" v-html="change(detail.recruitment_one.job_descriptions)"
              class="detail_tuijian_list_content"
          
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gongsiDetail",
  data() {
    return {
      id: "",
      detail: {company_album:[]},
      company_id:"",

    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.company_id=this.$route.query.company_id
  
    this.getDetail();
  },
  methods: {
     change:function(content){
  content = content.replace(/;/g,";</br>");
  content = content.replace(/；/g,"；</br>");
  return content;
 },
    getDetail() {
      const params = {
        id: this.id,
        type: this.type,
        company_id: this.company_id,
      };
      this.$axios({
        method: "get",
        url: "/api/officialWebsite/talent_center/get_company_detail",
        params: params,
      })
        .then((res) => {
          console.log(Number(this.type));
          if (res.code == 200) {
           
            if (Number(this.type)==1) {
              if( res.data.recruitment_one){
                   res.data.recruitment_one.max_salary=parseInt(res.data.recruitment_one.max_salary)
              }
              if( res.data.recruitment_one){
                 res.data.recruitment_one.min_salary=parseInt(res.data.recruitment_one.min_salary)
              }
              if(res.data.statement!=null){
              res.data.company_album.unshift({
                image:res.data.statement
              })
              }
              this.detail = res.data;
            } else {
              this.detail = res.data.company;
              this.detail.company_album=res.data.company_album
              if(res.data.company.statement!=null){
              this.detail.company_album.unshift({
                image:res.data.company.statement
              })
              }
            }

            console.log(this.detail, "详情");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {},
};
</script>

<style scoped>
/* 企业详情 */
.article-detail-content {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.detail-top {
  width: 100%;
  height: 247px;
  background: #2e2e2e;
}
.detail-top-center {
  width: 1200px;
  max-width: 1200px;
  height: 247px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.gongsi_logo {
  width: 137px;
  height: 137px;
  border-radius: 10px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
  margin-right: 35px;
  object-fit: cover;
}
.detail-name {
  font-size: 45px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.detail-biaozhi {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f0f0f0;
  margin: 30px 0 15px 0;
}

.detail_content {
  width: 1200px;
  max-width: 1200px;
  margin: 60px auto;
}
.detail_content_title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  position: relative;
}
.detail_content_title::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  display: block;
  height: 3px;
  width: 26px;
  background-color: #ff7c06;
}
.detail_content_jieshao {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
  margin: 30px 0 0;
  margin-bottom: 100px;
}
.detail_tu {
  /* display: flex; */
  margin: 100px auto;
  
  /* width: 72%;
  height: 40%; */
}
.detail_tu_item {
  width: calc(50% - 22px);
  /* height: 327px; */
  border-radius: 10px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
}
.carImg {
  /* width: 100%;*/
  height: 100%; 
  object-fit: cover;
  border-radius: 5px;
}
.el-carousel__item{text-align: center;}
.layui-carousel {
  margin: 0 auto;
  width: 100% !important;
  height: 676px !important;
}
.detail_gongshang {
  border-radius: 5px;
  box-shadow: 1px 1px 1px 2px #e6e6e6;
  margin: 53px 0 100px;
  padding: 45px 35px;
}
.detail_gongshang_label {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 48px;
  width: 140px;
}
.detail_gongshang_value {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
  width: calc(100% - 140px);
}
.detail_gongshang_flex {
  display: flex;
  margin: 20px 0 0 0;
}
.detail_tuijian {
  margin: 30px 0 0 0;
}
.detail_tuijian_list {
  padding: 40px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
.detail_tuijian_list_job {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff7c06;
  line-height: 50px;
  /* margin-right: 55px; */
  /* width: 500px; */
  display: inline-block;
}
.detail_tuijian_list_xinzi {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0606;
  line-height: 50px;
}
.detail_tuijian_list_content {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height:25px;
  padding-top: 30px;
}
.detail_tuijian_list_line {
  margin: 0 10px;
  color: #e0e0e0;
}
</style>
