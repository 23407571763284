<template>
  <div>
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading="loading">

      <!-- 查询到的页面 -->
      <div v-if="search_result" class="myCertificate_img_box">

      <!-- 证书数量 -->
        <div class="myCertificate_num">
          您共拥有<span style="color: #0F6FEC; font-size: 23px">{{ myCertificateData.length }}</span>个毕业证书
        </div>

      <!-- 证书详情 -->
        <div class="myCertificate_details">
          <div>
            <div 
              class="myCertificate_details_left"
              v-for="(item, index) in myCertificateData"
              :key="index"
              @click="myCertificate_details_left_btn(index)"
              :class="{ myCertificate_details_left_active: myCertificate_left_active == index }"
            >
              <div :class="{ myCertificate_details_left_active_sanjiao: myCertificate_left_active == index }"></div>
              <div class="myCertificate_details_left_text">{{ item.certificate.title }}</div>
            </div>
          </div>
          <div class="myCertificate_details_right">
            <!-- 正面 -->
            <div style="margin-bottom: 50px;">
              <div class="myCertificate_details_right_img">
              <img class="auto-size" :src="myCertificateData[myCertificate_left_active].skills_certificate" alt="">
            </div>
            <div class="myCertificate_details_right_download">
              <div style="width: 50%;">正面</div>
              <div style="width: 50%; display: flex; justify-content: end;">
                <div class="download_img" @click="downCom(myCertificateData[myCertificate_left_active].certificate.cover, '正面')">下载原图</div>
              </div>
            </div>
            </div>


            <!-- 反面 -->
            <!-- <div>
              <div class="myCertificate_details_right_img">
              <img  class="auto-size" :src="myCertificateData[myCertificate_left_active].certificate.curtain_pic" alt="">
            </div>
            <div class="myCertificate_details_right_download">
              <div style="width: 50%;">反面</div>
              <div style="width: 50%; display: flex; justify-content: end;">
                <div class="download_img" @click="downCom">下载原图</div>
              </div>
            </div>
            </div> -->
          </div>
        </div>

      </div>

      <!-- 查询证书 -->
      <div class="myCertificate_box" v-else>
          <div class="myCertificate_title_box">
            <p class="myCertificate_title">精研学堂教育学院证书查询</p>
            <p class="myCertificate_subtitle">请使用学员姓名和ID进行查询</p>
          </div>
          <div class="myCertificate_form">
            <el-form>
              <el-form-item>
                <el-input placeholder="姓名" v-model="search_myCertificate_from.student_name" prefix-icon="el-icon-user-solid" style="width: 355px; background-color: #EFEFEF;"></el-input>
              </el-form-item>

              <el-form-item>
                <el-input placeholder="ID" v-model="search_myCertificate_from.student_id" prefix-icon="el-icon-postcard" style="width: 355px; background-color: #EFEFEF;"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_btn" @click="search_btn">查询</div>
      </div>

    </div>
     <!-- dialog  查询有误后显示 -->
  
         <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          center
          :before-close="handleClose"
        >
        <span slot="title">
          <h2>提示</h2>
        </span>
        <!-- 提示1 -->
          <!-- <div class="dialog_text" v-if="_name">
            <div>您输入的姓名有误</div>
            <div>请重新输入后在查询</div>
          </div> -->

        <!-- 提示2 -->
          <!-- <div class="dialog_text" v-else-if="_id" >
            <div>您输入的信息与当前登录的账户不匹配</div>
            <div>请重新输入与当前账户一致的信息进行查询</div>
          </div> -->

        <!-- 提示3 -->
          <div class="dialog_text" v-if="!search_result">
            <div>{{ myCertificate_dialog_text[0] }}</div>
            <div>{{ myCertificate_dialog_text[1] }}</div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false" class="dialog-footer-btn">重新输入</el-button>
          </span>
        </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tabsList: [
        {
          tabs_title: '我的证书'
        }
      ],
      tabs_active: 0,

      myCertificate_left_active: 0,
// myCertificate_details_left_active
      info: 0,  // 临时数据
      dialogVisible: false,

      search_myCertificate_from:{
        student_id:'',
        student_name:''
      },

      userInfo: JSON.parse(localStorage.getItem("userInfo")),

      search_result: false,

      myCertificateData: [],
      
      myCertificate_dialog_text: []
    }
  },
  methods: {
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },

    // 查询
    search_btn() {
      this.loading = true
      // this.info = 1
      this.$axios({
        method:'POST',
        url:'api/officialWebsite/computer/yuan_cheng/my_certificate',
        data:{
          ...this.search_myCertificate_from
        }
      }).then(res => {
        if (res.code === 200 && !!res.data.length) {
          this.search_result = true
          this.myCertificateData = res.data
        }else {

          this.myCertificate_dialog_text = res.msg.split('，')
          this.dialogVisible = true
          this.search_result = false
        }
        this.loading = false
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    myCertificate_details_left_btn(i) {
      this.myCertificate_left_active = i
    },
    // 下载证书
    downCom(url, name) {
      this.downloadByBlob(url, name);
      console.log('downCom');
    },
    downloadByBlob(url, name) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          this.download(url, name)
          URL.revokeObjectURL(url);
        });
      };
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    }
  },
  computed: {
    // _name () {
    //   return  this.search_myCertificate_from.student_name != this.userInfo.name
    // },
    // _id() {
    //   return this.search_myCertificate_from.student_id != this.userInfo.id
    // }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.myCertificate_box{
  padding: 100px 272px;
}
.myCertificate_title_box{
  text-align: center;
}
.myCertificate_title{
  font-weight: 700;
  font-size: 50px;
  width: 510px;
}
.myCertificate_subtitle{
  font-weight: 400;
  font-size: 20px;
  color: #6D6D6D;
}

.myCertificate_form{
  margin-top: 35px;
  display: flex;
  justify-content: center;
}
.myCertificate_form_input {
  
}

.search_btn{
  width: 355px;
  height: 52px;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  line-height: 52px;
  border-radius: 7px 7px 7px 7px;
  background: rgba(0,125,252,1);
  margin: 30px auto;
}

.myCertificate_img_box{
  padding: 50px 27px;
}

.myCertificate_num{
  margin-bottom: 20px;
  color: #6F6F6F;
}

.myCertificate_details{
  display: flex;
}

.myCertificate_details_left{
  position: relative;
  width: 176px;
  height: 54px;
  padding: 0 17px;
  color: #000;
  text-align: center;
  line-height: 54px;
  border-radius: 11px 11px 11px 11px;
  // background: rgba(15,111,237,1);
  background-color: #FFFFFF;
  margin-bottom: 20px;
  margin-right: 20px;
  // border: 1px solid #f60;
  -webkit-box-shadow:0 0 10px 5px #EEE;
  -moz-box-shadow: 0 0 10px 5px #EEE;
  box-shadow: 0 0 10px 5px #EEE;
}
.myCertificate_details_left_text{
  overflow: hidden;
  text-overflow: ellipsis; //文本溢出显示省略号
  white-space: nowrap; //文本不会换行
}
.myCertificate_details_left_active{
  background: rgba(15,111,237,1);
  color: #FFFFFF;
}
.myCertificate_details_left_active_sanjiao {
  width: 0;
  height: 0;
  position: absolute;
  left: 174px;
  top: 17px;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(15,111,237,1);
}
.myCertificate_details_right{
  width: 781px;
  min-height: 650px;
  // padding: 15px;
}
.myCertificate_details_right_img{
  height: 558px;
  margin-bottom: 10px;
  background-color: aqua;
}
.myCertificate_details_right_download{
  display: flex;
  text-align: right;
}

.download_img{
  width: 77px;
  height: 25px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  line-height: 25px;
  border-radius: 3px 3px 3px 3px;
  cursor:pointer;
  background: rgba(64,64,64,1);
}


.dialog-footer-btn{
 width: 132px;
  height: 43px;
  border-radius: 21px 21px 21px 21px;
  background: rgba(0,125,252,1);
  font-size: 20px;
}
.dialog_text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 20px;
  color: #666666;
}
</style>

<style lang="less" scoped>

/deep/ .el-dialog {
  border-radius: 20px;
  width: 468px !important;
  margin-top: 40vh !important;
  height: 319px;
}

/deep/ .el-input__inner{
  border: none;
  background-color: #EEEEEE;
}
/deep/ .el-dialog__header{
  padding: 50px 20px 10px !important;
}
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}

</style>