import { render, staticRenderFns } from "./SideTab.vue?vue&type=template&id=f70f6cb4&scoped=true&"
import script from "./SideTab.vue?vue&type=script&lang=js&"
export * from "./SideTab.vue?vue&type=script&lang=js&"
import style0 from "./SideTab.vue?vue&type=style&index=0&id=f70f6cb4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70f6cb4",
  null
  
)

export default component.exports