<template>
	<div>
		<!-- 学历提升 -->
		<div class="bannerBox">
			<div class="banner">
				<img src="https://static.shanchuangjiaoyu.com/web/xueli/xueli_banner.jpg" alt="" class="bannerImg1">
				<div class="bannerContent">
					<p class="banner1_p1">{{currentYear}}年成人学历提升报考中心</p>
					<div class="banner2_tit">
						低学历考成人<span class="tit_color">专科&本科</span>学历
					</div>
					<div class="banner_con">
						<div class="banner1_p1">国家承认</div>
						<div class="banner1_p1">正规学历</div>
						<div class="banner1_p1">学信网终身可查</div>
					</div>
					<div class="banner_p2">
						<span class="text_color">终身有效</span>文凭，
						<span class="text_color">学费低，课时少，</span>上班族也能轻松考！
					</div>
					<div class="banner_btns">
						<div @click="openDialog" :class="mouse_btn1 == 1 ? 'btn2' : 'btn1'" style="margin-right: 85px;" @mousemove="mouse_btn1=1" @mouseout="mouse_btn1=null">
							成人<span class="setColor">专科</span>报考条件<span class="jiantou1">></span><span class="jiantou2">></span>
						</div>
						<div @click="openDialog" :class="mouse_btn2 == 1 ? 'btn1' : 'btn2'" @mousemove="mouse_btn2=1" @mouseout="mouse_btn2=null">
							成人<span class="setColor">本科</span>报考条件<span class="jiantou1">></span><span class="jiantou2">></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="category">
			<h2 class="category_tit">您需要了解的</h2>
			<p class="category_stit">成人学历提升常用方式</p>
			<div class="category_con">
				<div class="hvr-bob" v-for="(bob, index) in bobList">
					<h2 class="category_h2">{{bob.title}}</h2>
					<p class="category_p" v-html="bob.content"></p>
				</div>
			</div>
			<div @click="openDialog" class="button category_btn">
				<div class="mouseBtn1">学历提升在线咨询<span class="jiantou1">></span><span class="jiantou2">></span></div>
			</div>
		</div>
		
		<div class="bannerBox marginTop50">
			<div class="banner banner2">
				<img src="https://static.shanchuangjiaoyu.com/web/xueli/xueli_banner.jpg" alt="" class="bannerImg2">
				<div class="bannerContent">
					<p class="banner1_p1">职场新手<span class="banner1_p1_p1_text">OR中大专</span> · 您都需要一个本科学历</p>
					<div class="xueliBox">
						<ul>
							<li>
								<img class="xueliImg" src="https://static.shanchuangjiaoyu.com/web/xueli/chuzhong.png"/>
								<div class="xueli_text">
									<p>初中学历</p>
									<p>考大专、本科</p>
								</div>
							</li>
							<li>
								<img class="xueliImg" src="https://static.shanchuangjiaoyu.com/web/xueli/gaozhong.png"/>
								<div class="xueli_text">
									<p>高中学历</p>
									<p>考大专、本科</p>
								</div>
							</li>
							<li>
								<img class="xueliImg" src="https://static.shanchuangjiaoyu.com/web/xueli/zhongzhuan.png"/>
								<div class="xueli_text">
									<p>中专学历</p>
									<p>考大专、本科</p>
								</div>
							</li>
							<li>
								<img class="xueliImg" src="https://static.shanchuangjiaoyu.com/web/xueli/dazhuan.png"/>
								<div class="xueli_text">
									<p>大专学历考本科</p>
								</div>
							</li>
							<li>
								<img class="xueliImg" src="https://static.shanchuangjiaoyu.com/web/xueli/dier.png"/>
								<div class="xueli_text">
									<p>修第二学历</p>
								</div>
							</li>
						</ul>
					</div>
					<p class="need_p">温馨提示：有高中毕业证或中专文凭，可考虑提升本科学历。</p>
					<div @click="openDialog" class="button need_btn ">
						<div class="mouseBtn2">学历提升在线咨询<span class="jiantou1">></span><span class="jiantou2">></span></div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="area">
			<h2 class="area_tit">{{currentYear}}年疑问&报考 <span class="setLanse"> 发布专区(新)</span></h2>
			<div class="area_con">
				<div class="area_top">
					<h3 class="area_h3">大部分考生关心的<span class="setHuangse">问题一览</span></h3>
					<h3 class="area_h3">全国学历<span class="setHuangse">报考查询</span></h3>
				</div>
				<div class="area_box ">
					<div class="area_left">
						<div class="area_left_li" :class="(index == 0 || index == 1 ||index == 4 || index == 7) ? 'setColor' : ''" v-for="(item, index) in wentiList" :key="index">
							<div @click="openDialog" class="area_p" :class="areaLft==index ? 'mouseColor' : ''" @mousemove="areaLft=index" @mouseout="areaLft=null">{{item.content}}</div>
							<div @click="openDialog" class="areaBtn" :class="areaLftBtn==index ? 'mouseBtnColor' : ''" @mousemove="areaLftBtn=index" @mouseout="areaLftBtn=null">查看答案</div>
						</div>
					</div>
					<div class="area_right">
						<div @click="openDialog" class="area_right_li" v-for="(item, index) in areaList" :key="index">{{item}}</div>
						<div @click="openDialog" class="area_right_li" :class="mouse_area == 1 ? 'mouse_area' : ''" @mousemove="mouse_area=1" @mouseout="mouse_area=null">更多》</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="category">
			<h2 class="category_tit">什么专业适合我？</h2>
			<p class="major_stit">超百位资深教师<span class="setLanse">1对1辅导</span></p>
			<div class="category_con">
				<div class="major-bob" v-for="(major, index_major) in majorList" :key="index_major">
					<h2 @click="openDialog" class="category_h2" :class="mouse_majorTitle == index_major ? 'mouse_majorTitle' : ''" @mousemove="mouse_majorTitle=index_major" @mouseout="mouse_majorTitle=null">{{major.title}}</h2>
					<ul>
						<li @click="openDialog" v-for="(item, index) in major.content" :key="index">{{item}}</li>
					</ul>
				</div>
			</div>
			<div @click="openDialog" class="button category_btn">
				<div class="mouseBtn1">更多专业咨询<span class="jiantou1">></span><span class="jiantou2">></span></div>
			</div>
			<p class="major_p">选专业小妙招：  1、有兴趣  2、容易考  3、有前景</p>
		</div>
		
		<div class="bannerBox" style="margin-top: 60px;">
			<div class="banner">
				<img src="https://static.shanchuangjiaoyu.com/web/xueli/xueli_banner.jpg" alt="" class="bannerImg3">
				<div class="bannerContent" style="bottom: 10%;">
					<div class="time_tit">
						<span class="time_tit_color setHuangse">重要通知！</span>
						<span class="time_tit_test">{{currentYear}}年学历提升教改前培训报考 <span class="setHuangse">即将截至</span> </span>
					</div>
					<p class="time_p1">敬告考生：错过本次报考，下次报考时间为{{currentYear}}年底</p>
					<div class="time_notes">
						<div class="time_notes_left">
							<img src="https://static.shanchuangjiaoyu.com/web/xueli/baokaoxuzhi.svg" alt="" class="time_icon">
							<h3 class="time_h3">报考须知</h3>
						</div>
						<div class="time_notes_right">
							<p class="time_p2">今年报考时间已经公布，预计报考人数将会增加，官网会出现堵塞。</p>
							<p class="time_p3">建议考生提早报考！</p>
						</div>
					</div>
					<div class="time_form">
						<el-cascader placeholder="请选择您的城市" v-model="city" :options="addressData" @change="handleChange" class="time_form_input"></el-cascader>
						<el-input v-model="userName" class="time_form_input" placeholder="请输入您的姓名"></el-input>
						<el-input v-model="tel" class="time_form_input" placeholder="请输入您的电话"></el-input>
						<div class="time_btn" @click="submit">提交</div>
					</div>
					<p class="time_p1">提交电话后，学历教育老师会立刻为您详细解答学历报考及相关问题，我们会严格保护您的隐私权益，请放心接听。</p>
					<div class="time_notes">
						<div class="time_notes_left">
							<img src="https://static.shanchuangjiaoyu.com/web/xueli/wenxintishi.svg" alt="" class="time_icon">
							<h3 class="time_h3">温馨提示</h3>
						</div>
						<div class="time_notes_right">
							<p class="time_p1">因每年报考学生较多，每次报考期间都会出现错报，漏报，以及资料准备不够齐全，而导致无法正常报考或者取证的情况，<span class="setChengse">温馨建议</span>各位考生，提前跟咱们老师详细了解报考条件及提交报考资料等流程，以免延误考试，耽误正常毕业。</p>
						</div>
					</div>
					<div @click="openDialog" class="button time_btn need_btn">
						<div class="mouseBtn2">更多院校查询<span class="jiantou1">></span><span class="jiantou2">></span></div>
					</div>
				</div>
			</div>
		</div>
		<div class="user">
			<h2 class="use_tit">专业解读：<span class="tip setLanse">学历文凭</span>有什么用？</h2>
			<div class="use_con">
				<div class="use_box use_box1 setBackJuse" :class="xuanzhuanIndex==1 ? 'startZhuan' : (xuanzhuanIndex==11? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=1" @mouseout="xuanzhuanIndex=11">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/huodegongzuo.svg" alt="">
					<p>获得工作</p>
					<p>机会</p>
				</div>
				<div class="use_box use_box2 setBackLanse" :class="xuanzhuanIndex==2 ? 'startZhuan' : (xuanzhuanIndex==22? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=2" @mouseout="xuanzhuanIndex=22">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/shengzhi.svg" alt="">
					<p>升职加薪</p>
				</div>
				<div class="use_box use_box3 setBackJuse" :class="xuanzhuanIndex==3 ? 'startZhuan' : (xuanzhuanIndex==33? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=3" @mouseout="xuanzhuanIndex=33">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/kaogongwuyuan.svg" alt="">
					<p>考取公务员</p>
				</div>
				<div class="use_box use_box4 setBackLanse" :class="xuanzhuanIndex==4 ? 'startZhuan' : (xuanzhuanIndex==44? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=4" @mouseout="xuanzhuanIndex=44">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/pingji.svg" alt="">
					<p>评级/职称</p>
				</div>
				<div class="use_box use_box5 setBackJuse" :class="xuanzhuanIndex==5 ? 'startZhuan' : (xuanzhuanIndex==55? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=5" @mouseout="xuanzhuanIndex=55">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/luohu.svg" alt="">
					<p>落户一线</p>
					<p>城市</p>
				</div>
				<div class="use_box use_box6 setBackLanse" :class="xuanzhuanIndex==6 ? 'startZhuan' : (xuanzhuanIndex==66? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=6" @mouseout="xuanzhuanIndex=66">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/zixinxin.svg" alt="">
					<p>增强自信心</p>
				</div>
				<div class="use_box use_box7 setBackJuse" :class="xuanzhuanIndex==7 ? 'startZhuan' : (xuanzhuanIndex==77? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=7" @mouseout="xuanzhuanIndex=77">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/kaoyan.svg" alt="">
					<p>考研</p>
				</div>
				<div class="use_box use_box8 setBackLanse" :class="xuanzhuanIndex==8 ? 'startZhuan' : (xuanzhuanIndex==88? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=8" @mouseout="xuanzhuanIndex=88">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/chuguo.svg" alt="">
					<p>出国留学</p>
				</div>
				<div class="use_box use_box9 setBackJuse" :class="xuanzhuanIndex==9 ? 'startZhuan' : (xuanzhuanIndex==99? 'stopZhuan' : '')" @mousemove="xuanzhuanIndex=9" @mouseout="xuanzhuanIndex=99">
					<img src="https://static.shanchuangjiaoyu.com/web/xueli/kaozhengshu.svg" alt="">
					<p>考取资格</p>
					<p>证书</p>
				</div>
			</div>
			<div @click="openDialog" class="use_btn" :class="mouse_use == 1 ? 'mosu_use_btn' : ''" @mousemove="mouse_use=1" @mouseout="mouse_use=null">点击了解详情</div>
		</div>
		<div class="max">
			<h2 class="max_tit setLanse">省事省力MAX！</h2>
			<h2 class="max_tit">全程为您<span class="setLanse">指导</span>提升学历<span class="setLanse">事宜</span></h2>
			<div class="max_con">
				<el-row :gutter="30">
					<el-col :span="6" v-for="(item, index) in maxList" :key="index">
						<div class="maxBox" :class="mouse_max==index? 'mouse_max_box' : ''" @mousemove="mouse_max=index" @mouseout="mouse_max=null">
							<div class="maxImgBox">
								<div class="maxImgBox2">
									<img :src="item.image" alt="">
								</div>
							</div>
							<div class="max_text">
								<h2>{{item.title}}</h2>
								<hr>
								<p v-html="item.content"></p>
								<div @click="openDialog" class="button max_btn">{{item.but_txt}}</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="bannerBox marginTop50">
			<div class="banner">
				<img src="https://static.shanchuangjiaoyu.com/web/xueli/xueli_banner.jpg" alt="" class="bannerImg4">
				<div class="bannerContent" style="bottom: 20%;">
					<p class="banner4_p1">选择我们，<span class="setHuangse">学历不再成为职业道路上的绊脚石</span></p>
					<div class="advantage_con">
						<img src="https://static.shanchuangjiaoyu.com/web/advantage_icon.png" alt="" class="advantage_img">
						<div class="advantage_con_ul">
							<div class="advantage_con_li" :class="mouse_advantage==index ? 'mouse_advantage_li' : ''" @mousemove="mouse_advantage=index" @mouseout="mouse_advantage=null" v-for="(item, index) in adcantList" :key="index">
								<div class="advantage_icon">
									<img :src="mouse_advantage==index ? item.icon_mouse :item.icon" alt="">
								</div>
								<div class="lineBox">
									<hr>
								</div>
								<div class="advantage_bottom_box">
									<div class="advantage_bottom_box2">
										<h3 class="advantage_h3">{{item.title}}</h3>
										<div class="advantage_p" v-html="item.content"></div>
										<div @click="openDialog" class="button advantage_btn">{{item.but_txt}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="advantage_words">
						<p class="advantage_words_p1 setHuangse">高的学历不能确保你走上顶峰，却可让你免于跌落谷底！</p>
						<p class="advantage_words_p2">——献给仍在为生活努力拼搏的你。</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 左侧 -->
		<div class="side_left">
			<img src="https://static.shanchuangjiaoyu.com/web/left_bg.png" alt="">
			<div class="left_box">
				<h2 class="left_tit setLanse">报考指导</h2>
				<div class="left_btns">
					<div @click="openDialog" class="button left_btn" :class="(index == 0 || index == 3 || index ==6) ? 'setHuangse' : ''" v-for="(item, index) in leftButtonList">{{item}}</div>
				</div>
			</div>
		</div>
		<!-- 右侧 -->
		<div class="side_right">
			<img src="https://static.shanchuangjiaoyu.com/web/right_bg.png" alt="">
			<div class="right_box">
				<h2 class="right_tit">热门专业</h2>
				<div class="right_btns">
					<div @click="openDialog" class="button right_btn" :class="(index == 0 || index == 3 || index ==6 || index ==9) ? 'setHuangse' : ''" v-for="(item, index) in rightButtonList">{{item}}</div>
				</div>
			</div>
		</div>
		<!-- 弹窗：领取课程 -->
		<div class="jiaru">
			<el-dialog title="" :visible.sync="dialogVisible" width="856px" center :show-close="false"
				class="my-info-dialog">
				<div style="width: 857px;height: 567px;position: relative;top: 0; left: 50%; margin-left: -428px;">
					<img style="width: 100%;height: 100%;"
						src="https://juntongoss.oss-cn-beijing.aliyuncs.com/data/jingrui%402x.png">
					<div style="position: absolute;left: 50%;right: 15px;top: 80px;text-align: center;z-index: 999;">
						<p style="font-size: 26px;margin: 50px 0;font-weight: bold;color: rgba(0, 0, 0, 0.8);">您的专属学历提升顾问</p>
						<div class="width: 100%;">
							<img src="../../assets/image/codeImg/qrcode.png" alt="" style="width: 60%;">
						</div>
						<p style="margin: 20px 0 0 0;font-size: 18px;">微信扫一扫—立即咨询</p>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {provinceAndCityData, CodeToText} from "element-china-area-data"
	export default {
		name: '',
		data() {
			return {
				mouse_btn1: null,
				mouse_btn2: null,
				bobList: [],
				wentiList: [],
				areaLft: null,
				areaLftBtn: null,
				areaList: [],
				currentYear: '2022',
				mouse_area: null,
				majorList: [],
				mouse_majorTitle: null,
				mouse_use: null,
				maxList: [],
				mouse_max: null,
				adcantList: [],
				mouse_advantage: null,
				leftButtonList: [],
				rightButtonList: [],
				xuanzhuanIndex: null,
				addressData: provinceAndCityData,
				city: [],
				userName: '',
				tel: '',
				dialogVisible: false
			}
		},
		created() {
			this.init()
		},
		methods: {
			openDialog() {
				this.dialogVisible = true
			},
			submit() {
				if(this.tel.length != 11) {
					alert('请填写正确的11位手机号码！')
					return
				}
				// alert('已提交到专属老师')
 			},
			handleChange(value) {
				console.log(value)
				value.forEach(item => {
					console.log(CodeToText[item])
				})
				
			},
			init() {
				this.currentYear = new Date().getFullYear();
				this.bobList = [
					{title: '自学考试', content: '不管你是职场精英还是新人<br><span class="setColor">报考门槛低</span>，轻松入学<br>重在学生自学能力<br><span class="setColor">学费低，人人都能学</span>'},
					{title: '成人高考', content: '<span class="setColor">国家承认，正规学历</span><br>教育部备案，学信网可查<br>不用到校学习，手机就能学<br>上课<span class="setColor">时间自由，费用低</span>'},
					{title: '精研学堂教育', content: '<span class="setColor">名校</span>文凭，<span class="setColor">终身有效</span><br>线上教育<br>不受时间和空间限制<br><span class="setColor">学习方式灵活</span>'},
					{title: '电大/国开', content: '<span class="setColor">入学门槛低</span><br>开放式教育形式<br>学习形式灵活<br>专业设置实用<br><span class="setColor">花钱少</span>'},
				];
				
				this.wentiList = [
					{content: '1.学费多少钱？多久可以拿到证？'},
					{content: '2.只有初中学历，可以报考吗？'},
					{content: '3.上班族没时间学习怎么办？'},
					{content: '4.需要考哪些科目？'},
					{content: '5.自考/成考/精研学堂/电大的区别是什么？'},
					{content: '6.证书国家承认吗？对工作帮助大吗？'},
					{content: '7.没时间，不去学校可以吗？'},
					{content: '8.考不过可以免费重修吗？'},
					{content: '9.既便宜又易过的专业有哪些？'}
				];
				
				this.areaList = [
					'北京', '上海', '广东', '广西', '重庆', '浙江', '全国', '贵州', '四川', '陕西', '江西', '青海', '江苏', '安徽', '福建', '山西', '新疆'
				];
				
				this.majorList = [
					{
						title: '计算机类',
						content: ['通信工程', '信息管理', '电子信息工程', '自动化', '计算机应用', '计算机网络技术', '计算机科学与技术', '更多》']
					},
					{
						title: '经济管理类',
						content: ['人力资源管理', '商务管理', '金融管理', '行政管理', '会展管理', '采购与供应', '会计学', '更多》']
					},
					{
						title: '建筑工程类',
						content: ['土木工程', '建筑工程', '安全工程', '造价工程', '土地资源管理', '工程管理', '建筑学', '更多》']
					},
					{
						title: '艺术类',
						content: ['平面设计', '动漫设计', '包装设计', '三维画图', '影视编导', '美术教育', '播音主持', '更多》']
					},
					{
						title: '语言法律类',
						content: ['英语专业', '汉语言文学', '对外汉语', '法语专业', '日语专业', '德语专业', '俄语专业', '更多》']
					}
				];
				
				this.maxList = [
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max01.svg',
						title: '学历低，底子差？',
						content: '专业定制<span class="biaoji">多种学历</span>提升<br>途径，即使小学文凭也不怕！',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max02.svg',
						title: '经济差，没余钱？',
						content: '学费可0利息分期，<br><span class="biaoji">低首付</span>拒绝经济压力！',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max03.svg',
						title: '工作忙，没时间？',
						content: '<span class="biaoji">网络授课，</span>不用出家门，<br>让您坐着躺着也能学！',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max04.svg',
						title: '纠结中，怕被骗？',
						content: '反对弄虚作假，<br>只提供<span class="biaoji">正规</span>学历教育！',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max05.svg',
						title: '考点多，记不住？',
						content: '资深老师多年经验，为您从海<br>量题型中<span class="biaoji">摘出热门题型！</span>',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max06.svg',
						title: '考不过，毕业难？',
						content: '<span class="biaoji">精选重点院校</span>易通过专业，<br>考不过可重修!',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max07.svg',
						title: '有疑问，怎么办？',
						content: '老师实时答疑，<span class="biaoji">真人一对一</span><br>语音答题，完全免费！',
						but_txt: '查看详情'
					},
					{
						image: 'https://static.shanchuangjiaoyu.com/web/xueli/max08.svg',
						title: '学不好，有办法？',
						content: '老师专业为您定制<span class="biaoji">专属学历方</span><br><span class="biaoji">案，</span>学习不用担忧！',
						but_txt: '查看详情'
					}
				];
				
				this.adcantList = [
					{
						icon: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_01.svg',
						icon_mouse: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_m_01.svg',
						title: '优势NO.1',
						content: '全新学习模式<br>拒绝死记硬背',
						but_txt: '咨询入口'
					},
					{
						icon: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_02.svg',
						icon_mouse: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_m_02.svg',
						title: '优势NO.2',
						content: '提升学历<br>师资强大',
						but_txt: '咨询入口'
					},
					{
						icon: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_03.svg',
						icon_mouse: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_m_03.svg',
						title: '优势NO.3',
						content: '海量题库<br>历年真题分析',
						but_txt: '咨询入口'
					},
					{
						icon: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_04.svg',
						icon_mouse: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_m_04.svg',
						title: '优势NO.4',
						content: '国家承认<br>正规可查',
						but_txt: '咨询入口'
					},
					{
						icon: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_05.svg',
						icon_mouse: 'https://static.shanchuangjiaoyu.com/web/xueli/youshi_m_05.svg',
						title: '优势NO.5',
						content: '校区众多<br>方便学习',
						but_txt: '咨询入口'
					}
				];
				
				this.leftButtonList = ['报考条件', '政策解读', '名校推荐', '易考专业', '加分政策', '自学考试', '考试安排', '考前资料', '报考费用'];
				this.rightButtonList = ['人力资源', '计算机', '会计', '汉语言文学', '项目管理', '工商管理', '行政', '机械工程', '学前教育', '更多专业'];
			}
		}
	}
</script>

<style scoped lang="less">
	.marginTop50 {
		margin-top: 80px;
	}
	.button {
		height: 64px;
		border: none;
		border-radius: 5px;
		display: block;
		font-size: 20px;
		color: #fff;
		// box-shadow: inset 0 0 20px rgb(255 255 255 / 50%);
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.setLanse {
		color: #3f85f4;
	}
	.setHuangse {
		color: #ffff00;
	}
	.setChengse {
		color: #fc7f11;
	}
	.setBackLanse {
		background-color: #659EF6;
	}
	.setBackJuse {
		background-color: #FC8C28;
	}
	
	.bannerBox {
		width: 100%;
		overflow: hidden;
	}

	.banner {
		position: relative;
		width: 100%;
	}

	.bannerContent {
		position: absolute;
		top: 10%;
		bottom: 15%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;

		.banner1_p1 {
			font-size: 30px;
			color: #fff;
			line-height: 1;
			font-weight: 300;
		}

		.banner2_tit {
			font-size: 71px;
			color: #fff;
			font-weight: bold;
			letter-spacing: 5px;

			.tit_color {
				color: #FFEA00;
			}

		}

		.banner_con {
			display: flex;

			.banner1_p1 {
				background: rgba(0, 0, 0, 0.25);
				height: 61px;
				border: rgba(255, 255, 255, 0.25) 1px dashed;
				font-size: 30px;
				color: #fff;
				line-height: 61px;
				float: left;
				padding: 0 50px;
				margin: 0 1px 0 0;
			}

			.banner1_p1:last-child {
				margin: 0;
			}

		}

		.banner_p2 {
			font-size: 18px;
			color: #fff;
			line-height: 1;

			.text_color {
				color: #fbfc04;
			}
		}

		.banner_btns {
			margin: 40px 0 0 0;
			display: flex;
			align-items: center;

			.btn1 {
				color: #333;
				background: #fff;
				width: 304px;
				height: 60px;
				border: none;
				border-radius: 4px;
				box-shadow: inset 0 0 20px rgb(0 61 155 / 50%);
				font-size: 18px;
				text-align: center;
				line-height: 60px;
				cursor: pointer;

				.setColor {
					color: #3f86f4;
				}
			}

			.btn2 {
				background: #fc7f11;
				width: 304px;
				height: 60px;
				border: none;
				border-radius: 4px;
				box-shadow: inset 0 0 20px rgb(255 255 255 / 27%);
				font-size: 18px;
				color: #fff;
				text-align: center;
				line-height: 60px;
				cursor: pointer;

				.setColor {
					color: #fefe00;
				}
			}
		}
	}

	.category {
		text-align: center;
		padding-top: 80px;
		.category_tit {
			font-size: 50px;
			font-weight: bold;
		}
		.category_stit {
			font-size: 18px;
			color: #3f86f4;
			text-align: center;
			line-height: 1;
			margin-top: 22px;
		}
		.major_stit {
			font-size: 26px;
			color: #333;
			text-align: center;
			line-height: 1;
			margin-top: 18px;
		}
		.category_con {
			margin: 50px auto 0;
			width: 100%;
			display: flex;
			justify-content: center;
			.hvr-bob {
				background: rgba(255, 255, 255, 0.42);
				width: 220px;
				height: 245px;
				box-shadow: 0 2px 5px rgb(30 55 163 / 14%);
				margin-right: 40px;
				position: relative;
			}
			.hvr-bob:hover {
			   animation: changeTop 2s infinite;
			   -webkit-animation: changeTop 2s infinite;
			}
			@keyframes changeTop {
				0% {  top: 0}
				50% { top: -15px }
				100% { top: 0px }
			}
			@-webkit-keyframes changeTop {
				0% {  top: 0}
				50% { top: -15px }
				100% { top: 0px }
			}
			
			.category_h2 {
				background: #3f86f4;
				width: 100%;
				height: 52px;
				font-size: 21px;
				color: #fff;
				line-height: 52px;
				text-align: center;
				box-shadow: 0 4px 4px rgb(30 55 163 / 14%);
				font-weight: bold;
			}
			.category_p {
				font-size: 16px;
				color: #666;
				text-align: center;
				line-height: 30px;
				margin-top: 26px;
			}
			/deep/.setColor {
				color: #fc7f11;
			}
			.hvr-bob:last-child {
				margin-right: 0;
			}
			.major-bob {
				width: 179px;
				background: #fff;
				margin-right: 26px;
			}
			.major-bob:last-child {
				margin-right: 0;
			}
			.major-bob ul li {
				width: 140px;
				height: 39px;
				border-bottom: #bfbfbf 1px dashed;
				font-size: 16px;
				color: #333;
				text-align: center;
				line-height: 39px;
				margin: 0 auto;
				cursor: pointer;
			}
			.major-bob ul li:hover {
				color: #fc7f11 !important;
				border-bottom: #fc7f11 1px dashed !important;
			}
			.major-bob ul li:last-child {
				 border-bottom: none !important;
				 color: #3f86f4;
			 }
			
			 .mouse_majorTitle {
				 cursor: pointer;
				 background-color: #fc7f11;
			 }
			
		}
		.category_btn {
			background: #3f86f4;
			width: 650px;
			margin: 50px auto 0;
		}
	}
	.banner2 {
		.bannerContent {
			bottom: 20%;
		}
		.banner1_p1_p1_text {
			font-size: 48px;
			font-weight: bold;
		}
		.need_p {
			font-size: 17px;
			color: #fbfc04;
			line-height: 54px;
			text-align: center;
		}
		.need_btn  {
			background: #fc7f11;
			width: 680px;
			margin: 50px auto 0;
		}
	}
	.area {
		padding-top: 90px;
		.area_tit {
			font-size: 50px;
			font-weight: bold;
			text-align: center;
		}
		.area_con {
			width: 1000px;
			max-width: 100%;
			margin: 50px auto 0;
			.area_top {
				width: 100%;
				height: 81px;
				display: flex;
				background-image: linear-gradient(to right, #4684e1, #4a8aed, #4d8def);
				border-radius: 40px 40px 0 0;
				align-items: center;
				.area_h3 {
					color: #fff;
					font-size: 27px;
					width: 50%;
					text-align: center;
					letter-spacing: 5px;
				}
			}
			.area_box {
				width: 100%;
				background-color: #f9f9f9;
				border-bottom: 6px solid #cccccc;
				display: flex;
				.area_left {
					position: relative;
					width: 50%;
					text-align: center;
					padding: 20px 0;
					.area_left_li {
						display: flex;
						width: 84%;
						margin: 0 auto;
						justify-content: space-between;
						align-items: center;
						height: 42px;
						border-bottom: #d2d2d2 1px dashed;
						.area_p {
							font-size: 16px;
							color: #333;
							cursor: pointer;
						}
						.areaBtn {
							background: transparent;
							width: 110px;
							height: 30px;
							line-height: 30px;
							border: #3f86f4 1px solid;
							border-radius: 5px;
							font-size: 16px;
							color: #3f86f4;
							cursor: pointer;
						}
						.mouseColor {
							color: #fc7f11;
						}
						.mouseBtnColor {
							background-color: #fc7f11;
							color: #fff !important;
							border: none;
						}
					}
					.setColor {
						.area_p {
							color: #fc7f11;
						}
						.areaBtn {
							border: #fc7f11 1px solid;
							color: #fc7f11;
						}
					}
					.area_left_li:last-child {
						border-bottom: none;
					}
				}
				.area_left::after {
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					height: 370px;
					width: 1px;
					background-color: #d2d2d2;
					display: inline-block;
				}
				.area_right {
					width: 50%;
					text-align: center;
					padding: 30px 0 20px 10px;
					display: flex;
					flex-wrap: wrap;
					.area_right_li {
						margin: 0 0 25px 24px;
						cursor: pointer;
						position: relative;
						background: #fff;
						width: 117px;
						height: 40px;
						border: none;
						border-radius: 5px;
						display: block;
						font-size: 20px;
						color: #333;
						line-height: 40px;
						text-align: center;
						box-shadow: inset 0 0 6px rgb(63 134 244 / 43%);
					}
					.area_right_li:last-child {
						box-shadow: inset 0 0 6px rgb(252 127 17 / 43%);
						color: #fc7f11;
					}
					.mouse_area {
						background-color: #fc7f11;
						color: #fff !important;
					}
				}
			}
		}
	}
	.major_p {
		font-size: 18px;
		color: #fc7f11;
		line-height: 1;
		text-align: center;
		margin-top: 16px;
	}
	.bannerImg1 {
		width: 100%;
		height: 666px;
		object-fit: cover;
	}
	.bannerImg2 {
		width: 100%;
		height: 820px;
		object-fit: cover;
	}
	.bannerImg3 {
		width: 100%;
		height: 840px;
		object-fit: cover;
	}
	.bannerImg4 {
		width: 100%;
		height: 850px;
		object-fit: cover;
	}
	.time_tit {
		display: flex;
		align-items: center;
		.time_tit_color {
			font-size: 55px;
			font-weight: bold;
		}
		.time_tit_test {
			font-size: 35px;
			color: #fff;
		}
	}
	.time_p1 {
		font-size: 18px;
		color: #ffff00;
		text-align: center;
		line-height: 1;
	}
	.time_notes {
		width: 1004px;
		max-width: 100%;
		height: 150px;
		display: flex;
		box-shadow: 0 0 5px 10px rgba(209, 209, 209, 0.8) -1;
		.time_notes_left {
			width: 189px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: #f78521;
			border-radius: 10px 0 0 10px;
			.time_icon {
				width: 53px;
				height: 53px;
				object-fit: cover;
			}
			.time_h3 {
				font-size: 24px;
				color: #fff;
				line-height: 1;
				text-align: center;
				margin-top: 11px;
				font-weight: bold;
			}
		}
		.time_notes_right {
			width: calc(100% - 189px);
			height: 100%;
			background-color: #f8f8f8;
			border-radius: 0 10px 10px 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0 35px;
			box-sizing: border-box;
			.time_p1 {
				font-size: 18px;
				color: #444;
				line-height: 32px;
			}
			.time_p2 {
				font-size: 21px;
				color: #444;
				line-height: 48px;
			}
			.time_p3 {
				font-size: 28px;
				color: #3f86f4;
				font-weight: bold;
				line-height: 48px;
			}
		}
		
	}
	.time_form  {
		width: 1000px;
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.time_form_input {
			margin: 0;
			width: 250px;
			/deep/.el-input__inner {
				background: #fff;
				width: 250px;
				height: 64px;
				border: none;
				border-radius: 5px;
				font-size: 18px;
				color: #666;
				text-align: center;
				padding: 0;
			}
		}
		.time_btn {
			background: #fc7f11;
			width: 150px;
			height: 64px;
			border: none;
			border-radius: 5px;
			font-size: 18px;
			color: #fff;
			box-shadow: inset 0 0 20px rgb(255 255 255 / 50%);
			line-height: 64px;
			text-align: center;
			cursor: pointer;
		}
		.time_btn:hover {
			background: rgba(255, 127, 17, 0.9);
		}
	}
	.xueliBox {
		width: 915px;
		max-width: 100%;
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			li {
				width: 150px;
				height: 183px;
				position: relative;
				.xueliImg {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.xueli_text {
					position: absolute;
					width: 100%;
					text-align: center;
					top: 100px;
					font-size: 18px;
					color: #555;
					font-weight: bold;
					height: 60px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
			li:hover {
				animation: changeTopOne 0.5s 1 forwards;
				-webkit-animation: changeTopOne 0.5s 1 forwards;
			}
			@keyframes changeTopOne {
				0% { top: 0 }
				100% { top: -10px }
			}
			@-webkit-keyframes changeTopOne {
				0% { top: 0 }
				100% { top: -10px }
			}
		} 
	}
	.time_btn {
		background: #fc7f11;
		width: 680px;
		height: 64px;
	}
	.user {
		margin: 55px 0 0 0;
		.use_tit {
			font-size: 50px;
			font-weight: bold;
			letter-spacing: 5px;
			text-align: center;
			.tip {
				font-size: 55px;
			}
		}
		.use_con {
			position: relative;
			width: 929px;
			height: 529px;
			margin: 48px auto 0;
			background: url(https://static.shanchuangjiaoyu.com/web/xueli/getXueLi.png);
			.use_box {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				position: absolute;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.8) -1;
				img {
					max-width: 38px;
					max-height: 37px;
					margin-bottom: 5px;
				}
				p {
					font-size: 16px;
					color: #fff;
					text-align: center;
					line-height: 20px;
				}
			}
			.startZhuan {
				animation: xuanzhaun 0.5s ease-in-out forwards;
				-webkit-animation: xuanzhaun 0.5s ease-in-out forwards;
			}
			.stopZhuan {
				animation: huifu 0.5s ease-in-out forwards;
				-webkit-animation: huifu 0.5s ease-in-out forwards;
			}
			@keyframes xuanzhaun {
			   0% {
				   transform: rotate(0deg) scale(1, 1);
			   }
			   100% {
				   transform: rotate(360deg) scale(1.2,1.2);
			   }
			}
			@keyframes huifu {
			   0% {
			   		transform: rotate(360deg) scale(1.2,1.2)
			   }
			   100% {
				   transform: rotate(0deg) scale(1, 1);
			   }
			}
			.use_box1 {
				top: 429px;
				left: 0;
			}
			.use_box2 {
				top: 270px;
				left: 27px;
			}
			.use_box3 {
				top: 128px;
				left: 107px;
			}
			.use_box4 {
				top: 36px;
				left: 228px;
			}
			.use_box5 {
				top: 0;
				left: 414px;
			}
			.use_box6 {
				    top: 36px;
				right: 239px;
			}
			.use_box7 {
				top: 128px;
				right: 110px;
			}
			.use_box8 {
				top: 268px;
				right: 27px;
			}
			.use_box9 {
				top: 429px;
				right: 0;
			}
		}
		.use_btn {
			background: #3f86f4;
			width: 280px;
			height: 64px;
			border: none;
			border-radius: 32px;
			font-size: 20px;
			color: #fff;
			display: block;
			margin: 20px auto 0;
			box-shadow: inset 0 0 18px rgb(255 255 255 / 50%);
			text-align: center;
			line-height: 64px;
			cursor: pointer;
		}
		.mosu_use_btn {
			background-color: #fc7f11;
		}
	}
	.max {
		padding-top: 70px;
		.max_tit {
			font-size: 50px;
			font-weight: bold;
			letter-spacing: 5px;
			text-align: center;
		}
		.max_con {
			width: 1009px;
			margin: 60px auto 0;
			.maxBox {
				height: 250px;
				position: relative;
				margin-top: 70px;
				box-shadow: 0 0 3px 2px rgba(64, 134, 244, 0.3);
				.maxImgBox {
					border: 1px solid rgba(64, 134, 244, 0.3);
					border-radius: 50%;
					width: 110px;
					height: 110px;
					position: absolute;
					top: -55px;
					left: 50%;
					transform: translateX(-50%);
					.maxImgBox2 {
						position: absolute;
						top: 10px;
						left: 10px;
						border-radius: 50%;
						width: 90px;
						height: 90px;
						background-color: #659EF6;
						img {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							max-width: 50%;
							max-height: 50%;
						}
					}
				}
				.max_text {
					position: absolute;
					top: 65px;
					left: 1%;
					width: 98%;
					text-align: center;
					h2 {
						font-size: 18px;
						color: #333;
						line-height: 1;
						font-weight: bold;
					}
					hr {
						background: #3f86f4;
						width: 80px;
						height: 2px;
						margin: 9px auto 0;
						border: none;
					}
					p {
						font-size: 16px;
						color: #333;
						line-height: 26px;
						text-align: center;
						margin-top: 15px;
						/deep/.biaoji {
							color: #3f86f4;
						}
					}
					.max_btn {
						background: #3f86f4;
						width: 148px;
						height: 38px;
						font-size: 14px;
						margin: 25px auto 0;
						border-radius: 19px;
						cursor: pointer;
					}
				}
			}
			.mouse_max_box {
				box-shadow: 0 0 3px 2px rgba(252, 140, 40, 0.3); 
				.maxImgBox {
					border: 1px solid rgba(252, 140, 40, 0.3);
					.maxImgBox2 {
						background-color: #FC8C28;
					}
				}
				.max_text {
					hr {
						background: #FC8C28;
					}
					p {
						/deep/.biaoji {
							color: #FC8C28;
						}
					}
					.max_btn {
						background: #FC8C28;
					}
				}
			}
		}
	}
	.banner4_p1 {
		color: #fff;
		font-size: 50px;
		font-weight: bold;
	}
	.advantage_con {
		width: 1000px;
		margin: 50px auto 0;
		position: relative;
		.advantage_img {
			position: absolute;
			width: 100%;
			top: 30px;
		}
		.advantage_con_ul {
			display: flex;
			justify-content: space-between;
			.advantage_con_li {
				width: 180px;
				position: relative;
				.advantage_icon {
					background: #fff;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					box-shadow: inset 0 0 4px rgb(63 134 244 / 46%);
					margin: 0 auto;
					position: relative;
					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						max-width: 50%;
						max-height: 50%;
					}
				}
				.lineBox {
					position: relative;
					height: 40px;
					hr {
						height: 100%;
						width: 2px;
						border: none;
						background-color: rgba(255, 255, 255, 0.6);
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				.advantage_bottom_box {
					width: 100%;
					height: 255px;
					background-color: rgba(255, 255, 255, 0.3);
					border-radius: 10px;
					padding: 6px;
					box-sizing: border-box;
					.advantage_bottom_box2 {
						width: 100%;
						height: 100%;
						background-color: rgba(255, 255, 255, 0.9);
						border-radius: 10px;
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.advantage_h3 {
							font-size: 24px;
							color: #5996f4;
							font-weight: bold;
						}
						.advantage_p {
							font-size: 16px;
							color: #666;
							line-height: 24px;
							margin-top: 22px;
							text-align: center;
						}
						.advantage_btn {
							background: #3b7fe5;
							width: 112px;
							height: 34px;
							border-radius: 17px;
							font-size: 16px;
							margin: 33px auto 0;
						}
					}
				}
			}
			.mouse_advantage_li {
				.advantage_icon {
					background-color: #FC8C28;
				}
				.advantage_bottom_box {
					background-color: rgba(252, 140, 40, 0.4);
					.advantage_bottom_box2 {
						background-color: rgba(252, 140, 40, 0.9);
						.advantage_h3, .advantage_p {
							color: #fff;
						}
						/deep/.advantage_btn {
							background: #fff;
							color: #FC8C28;
						}
					}
				}
			}
		}
	}
	.advantage_words {
		margin: 70px auto 0;
		.advantage_words_p1 {
			font-size: 36px;
			text-align: center;
			letter-spacing: 5px;
		}
		.advantage_words_p2 {
			font-size: 26px;
			text-align: right;
			color: #fff;
			margin-top: 30px;
			letter-spacing: 1px;
		}
	}
	.side_left {
		position: fixed;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 133px;
		height: 400px;
		.left_box {
			position: absolute;
			top: 86px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.left_tit {
				font-size: 20px;
				font-weight: bold;
			}
			.left_btns {
				width: 104px;
				margin: 6px auto 0;
				.left_btn {
					background: rgba(63, 134, 244, 1);  
					width: 100%;
					height: 28px;
					border-radius: 5px;
					margin-top: 10px;
					font-size: 16px;
					line-height: 28px;
					box-shadow: none;
				}
				.left_btn:hover {
					background: rgba(63, 134, 244, 0.8);  
				}
			}
		}
	}
	.side_right {
		position: fixed;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		width: 133px;
		height: 400px;
		.right_box {
			position: absolute;
			top: 48px;
			width: 100%;
			.right_tit {
				width: 84px;
				height: 50px;
				font-size: 18px;
				color: #fff;
				line-height: 50px;
				font-weight: normal;
				text-align: center;
				margin-left: 5px;
			}
			.right_btns {
				width: 104px;
				margin: 5px auto 0;
				.right_btn {
					background: rgba(252, 127, 17, 1);
					width: 100%;
					height: 27px;
					border-radius: 5px;
					margin-top: 10px;
					font-size: 16px;
					line-height: 27px;
					box-shadow: none;
				}
				.right_btn:hover {
					background: rgba(252, 127, 17, 0.8);  
				}
			}
		}
	}
	.need_btn:hover {
		background-color: #fff;
		color: #FC8C28;
		transition: background-color 0.3s, color 0.3s;
	}
	.mouseBtn2 {
		height: 64px;
		line-height: 64px;
		text-align: center;
		width: 100%;
	}
	.mouseBtn2:hover {
		border: 2px solid #FC8C28;
		animation: changeWidth 0.3s 1;
		-webkit-animation: changeWidth 0.3s 1;
	}
	.category_btn:hover {
		background-color: #fff;
		color: #3f86f4;
		transition: background-color 0.3s, color 0.3s;
	}
	.mouseBtn1 {
		height: 64px;
		line-height: 64px;
		text-align: center;
		width: 100%;
	}
	.mouseBtn1:hover {
		border: 2px solid #3f86f4;
		animation: changeWidth 0.3s 1;
		-webkit-animation: changeWidth 0.3s 1;
	}
	
	@keyframes changeWidth {
		0% { width: 90%; }
		100% { width: 100%; }
	}
	@-webkit-keyframes changeWidth {
		0% { width: 90%; }
		100% { width: 100%; }
	}
	.jiantou1 {
		animation-delay: 0s;
		animation: setOpacity 3s infinite;
		-webkit-animation: setOpacity 3s infinite;
	}
	.jiantou2 {
		animation-delay: 1s;
		animation: setOpacity 3s infinite;
		-webkit-animation: setOpacity 3s infinite;
	}
	@keyframes setOpacity {
		0% { opacity: 1; }
		25% { opacity: 0.5; }
		50% { opacity: 0; }
		75% { opacity: 0.5; }
		100% { opacity: 0.75; }
	}
	
	.jiaru {
		/deep/.el-dialog {
			background: rgba(255, 255, 255, 0) !important;
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
		.my-info-dialog .el-dialog,
		.el-pager li {
			background: none;
		}
		
		.my-info-dialog .el-dialog {
			box-shadow: none;
		}
	}

</style>
