<template>
    <div class="process">
        <div class="processTitle">
          <span>全国征集创意方案</span>
          <img :src="ossUrl + 'job_linggan.png'" />
        </div>
        <div class="processStep">
          <div class="step">
            <div class="processImg">
             <img :src="ossUrl + 'job_chakan.png'" />
              <span>查看任务</span>
            </div>
            <div class="arrow">
              <img :src="ossUrl + 'job_jiantou.png'" />
            </div>
          </div>
          <div class="step">
            <div class="processImg">
              <img :src="ossUrl + 'job_tijiao.png'" />
              <span>提交申请</span>
            </div>
            <div class="arrow">
              <img :src="ossUrl + 'job_jiantou.png'" />
            </div>
          </div>
          <div class="step">
            <div class="processImg">
              <img :src="ossUrl + 'job_tianjia.png'" />
              <span>添加好友</span>
            </div>
            <div class="arrow">
              <img :src="ossUrl + 'job_jiantou.png'" />
            </div>
          </div>
          <div class="step">
            <div class="processImg">
              <img :src="ossUrl + 'job_goutong.png'" />
              <span>沟通需求</span>
            </div>
            <div class="arrow">
               <img :src="ossUrl + 'job_jiantou.png'" />
            </div>
          </div>
          <div class="step">
            <div class="processImg">
             <img :src="ossUrl + 'job_jiaofu.png'" />
              <span>交付初稿</span>
            </div>
            <div class="arrow">
               <img :src="ossUrl + 'job_jiantou.png'" />
            </div>
          </div>
          <div class="step">
            <div class="processImg">
              <img :src="ossUrl + 'job_queren.png'" />
              <span>最终确认</span>
            </div>
            <div class="arrow">
              <img :src="ossUrl + 'job_jiantou.png'" />
            </div>
          </div>
          <div class="step">
            <div class="processImg">
             <img :src="ossUrl + 'job_jiesuan.png'" />
              <span>结算金额</span>
            </div>
            <div class="arrow" style="opacity: 0">
              <img :src="ossUrl + 'job_jiesuan.png'" />
            </div>
          </div>
        </div>
      </div>
</template>
<script>
export default {
    name:'process',
    data(){
        return{
			ossUrl: process.env.VUE_APP_OSS_URL,
		}
    },
}
</script>
<style scoped>
.process {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 29px;
  margin-bottom: 87px;
}
.processTitle {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}
.processTitle img {
  width: 77px;
  height: 24px;
  margin-left: 5px;
}
.processImg {
  display: flex;
  flex-flow: column;
  text-align: center;
  width: 79px;
  margin-top: 53px;
}
.processImg img {
  width: 79px;
  height: 79px;
  border-radius: 50%;
}
.processImg span {
  margin-top: 14px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.processStep {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.arrow {
  margin-top: 10px;
}
.arrow img {
  width: 16px;
  height: auto;
}
.step {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>