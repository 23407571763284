<template>
  <div class="">
    <!-- 任务大厅 -->
    <div class="">
      <div class="renwu_top">
        <img src="../../assets/image/renwu_bg.png" class="renwu_bg" />
        <div class="top_view">
          <div class="top_left">
            <div class="top_name">精研学堂教育兼职任务交易中心</div>
            <div class="top_guzhu">服务企业雇主数</div>
            <div class="top_num">13,500</div>
            <div class="top_xuqiu">
              <span>解决企业需求数</span>
              <div class="top_shuzi">
                <span class="top_shuzi_item">1</span>
                <span class="top_shuzi_item">0</span>
                <span class="top_shuzi_item">3</span>
                <span class="top_shuzi_item">6</span>
                <span class="top_shuzi_item">7</span>
                <span class="top_shuzi_item">5</span>
              </div>
            </div>
            <div class="top_btn" @click="click_paixu">接单赚钱</div>
            <div class="kuaixu">
              <div class="kuaixunImg">兼职快讯</div>
              <marquee
                behavior="scroll"
                direction="up"
                scrollAmount="2"
                height="30"
              >
                <div
                  class="kuaixun_text"
                  v-for="(item, index) in taskData"
                  :key="index"
                >
                  <span class="notice">
                    <p>{{ item.company }}</p>
                    <p>的“{{ item.title }}”</p>
                    <p v-if="item.id % 2 == 0">企业雇佣需求已发布</p>
                    <p v-else>企业雇佣需求已完结</p>
                    <p>￥{{ item.amount_money }}</p></span
                  >
                </div>
              </marquee>
            </div>
          </div>
          <div class="top_right">
            <div class="top_right_li">
              <img src="../../assets/image/bao.png" class="li_img" />
              <div class="li_right_box">
                <div class="li_title">平台担保</div>
                <div class="li_content">服务交易安全</div>
              </div>
            </div>
            <div class="top_right_li">
              <img src="../../assets/image/duo.png" class="li_img" />
              <div class="li_right_box">
                <div class="li_title">平均每3分钟</div>
                <div class="li_content">产生一个任务需求</div>
              </div>
            </div>
            <div class="top_right_li">
              <img src="../../assets/image/hao.png" class="li_img" />
              <div class="li_right_box">
                <div class="li_title">98.7%的企业雇主</div>
                <div class="li_content">服务交易安全</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content"></div>
      <div class="content">
        <div
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 100px 0 50px 0;
          "
        >
          <img
            style="width: 46px; height: 27px; padding-right: 10px"
            src="https://static.shanchuangjiaoyu.com/ycpc/assets/left.png"
          />
          <div
            style="
              color: #000000;
              font-size: 46px;
              font-weight: bold;
              line-height: 27px;
              padding: 0 10px;
            "
          >
            海量兼职任务任你选择
          </div>
          <img
            style="width: 46px; height: 27px; padding-left: 10px"
            src="https://static.shanchuangjiaoyu.com/ycpc/assets/right.png"
          />
        </div>

        <div class="content_tab">
          <div
            class="content_tab_item"
            :class="active_paixu == 1 ? 'active_tab' : ''"
            @click="click_paixu()"
          >
            综合排序
          </div>
          <div
            class="content_tab_item"
            :class="active_paixu == 2 ? 'active_tab' : ''"
            @click="click_new()"
          >
            最新任务
          </div>
        </div>
        <div class="tabBox">
          <img src="../../assets/image/renmen.png" class="remen" />
          <div class="tab_biaoqian">热门标签:</div>
          <div class="tab" v-for="(item, index) in remenTab" :key="index">
            <div
              class="tab_item pointer"
              :class="activeTab == index ? 'active_item' : ''"
              @click="click_tab(item, index)"
            >
              {{ item.value }}
            </div>
          </div>
        </div>

        <div class="renwuBox">
          <div
            class="renwu_item"
            v-for="(item, index) in taskData"
            :key="index"
          >
            <div class="renwu_item_title">{{ item.title }}</div>
            <div class="renwu_item_yaoqiu">任务要求</div>
            <div class="renwu_item_content" v-html="item.requirement"></div>
            <div class="renwu_item_name">发布者： {{ item.company }}</div>
            <div class="renwu_item_tag">
              <div
                class="renwu_item_tag_index"
                v-for="(item_tag, index_tag) in item.tags"
                :key="index_tag"
              >
                {{ item_tag }}
              </div>
            </div>
            <div class="renwu_item_bottom">
              <div class="renwu_item_bottom_jiage">
                ￥{{ item.amount_money }}
              </div>
              <div class="">
                <button class="jiaogao" type="button" @click="addWorks()">
                  参与交稿
                </button>
                <button class="chakan" type="button" @click="openDetails(item)">
                  查看详情
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="ossImgBox" v-if="this.taskData.length == 0">
          <el-image
            class="noword"
            src="https://static.shanchuangjiaoyu.com/yc_baidu/noword.png"
          ></el-image>
          <div class="ossImgText">暂无内容哦~</div>
        </div>
        <pages
          v-if="this.taskData.length > 0"
          :page="page"
          @changePage="changePage"
        ></pages>
      </div>
    </div>
  </div>
</template>

<script>
import pages from "../components/pages.vue";
import { renwuData } from "../../assets/js/jobData.js";
export default {
  name: "Renwu",
  components: {
    pages,
  },
  data() {
    return {
      active_paixu: 1, // 1综合排序 2最新任务
      remenTab: [],
      renwuData,
      taskData: [],
      activeTab: 0,
      page: {
        total: 1, //总条目数
        pageSize: 8, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      kuaixunList: [
        '憬轩的   "憬轩装饰公司VI设计"   企业雇佣需求已发布   ￥1500',
        'MTC7的   "我需要企业宣传册设计"   企业雇佣需求已发布   ￥3865',
        '恒鑫的   "服饰品牌LOGO 设计"  企业雇佣需求已完结   ￥2600',
        '二里坝的   "我需要品牌名称和品牌故事"   企业雇佣需求已发布   ￥1300',
        'tcdha的   "企业片头动画效果剪辑和设计"   企业雇佣需求已完结   ￥6400',
      ],
      laberKey: "", //标签id
    };
  },
  created() {
    this.getClassify();
  },
  methods: {
    getClassify() {
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/talent_center/get_label_list",
      })
        .then((res) => {
          this.remenTab = res.data;
          this.laberKey = this.remenTab[0].key;
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePage(data) {
      this.page.currentPage = data;
      document.querySelector("#content").scrollIntoView(true);
      this.getData();
    },
    getData() {
      // console.log("当前点击的页码是1：", this.page.currentPage);
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/talent_center/get_list",
        params: {
          list_rows: this.page.pageSize,
          page: this.page.currentPage,
          label_id: this.laberKey,
          type: this.active_paixu,
        },
      })
        .then((res) => {
          // console.log(res, 666);
          this.taskData = res.data.data;
          this.page.total = res.data.total;
          // console.log(this.page.total);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 综合排序
    click_paixu(item) {
      this.active_paixu = 1;
      this.page.currentPage = 1;
      document.querySelector("#content").scrollIntoView(true);
      this.getData();
    },
    // 最新任务
    click_new() {
      this.active_paixu = 2;
      this.page.currentPage = 1;
      document.querySelector("#content").scrollIntoView(true);
      this.getData();
    },
    //标签
    click_tab(item, index) {
      this.activeTab = index;
      this.laberKey = item.key;
      this.page.currentPage = 1;
      this.getData();
    },
    // 查看详情
    openDetails(e) {
      let task = JSON.stringify(e);
      this.$router.push({
        name: "taskdetails",
        query: {
          res: e,
        },
      });
      document.querySelector("#nav").scrollIntoView(true);
    },
    addWorks() {
      this.$alert("成为精研学堂教育正式课的学员才能参与交稿哦！", "参与交稿", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      })
        .then(() => {
          let currentUrl = window.location.href;
          console.log("当前页面：", currentUrl);
          // location.href = "/index/login/login_pass.html?from=" + currentUrl;
          //直接跳转
          this.$router.push('/Index');
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "请联系老师",
          });
        });
    },
  },
};
</script>

<style scoped>
.index {
  background-color: #f5f5f5;
}
.notice {
  /* display: grid; */
  width: 600px;
  /* grid-template-columns: 16%  25% 23% 20%; */
  display: flex;

}
.notice p:nth-child(1) {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notice p:nth-child(2) {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.renwu_top {
  position: relative;
  width: 100%;
  height: 440px;
}

.renwu_bg {
  width: 100%;
  height: 440px;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.top_view {
  position: absolute;
  width: 1200px;
  left: calc((100% - 1200px) / 2);
  top: 0px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}

.top_name {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin: 50px 0 20px;
}

.top_guzhu {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.top_num {
  font-size: 42px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin: 15px 0 18px;
}

.top_xuqiu {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.top_shuzi {
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
}

.top_shuzi_item {
  width: 21px;
  height: 31px;
  background: linear-gradient(0deg, #373737, #565656);
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 31px;
  text-align: center;
  margin: 0 5px 0 0;
}

.top_btn {
  margin: 40px 0 35px 0;
  width: 203px;
  height: 40px;
  background: #fc661c;
  border-radius: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.kuaixu {
  display: flex;
  align-items: center;
  height: 30px;
  overflow: hidden;
}

.kuaixunImg {
  width: 120px;
  font-size: 20px;
  font-family: eryakuheiti;
  font-weight: 700;
  color: #fc661c;
  line-height: 31px;
  margin: 0 10px 0 0;
}

.kuaixun_text {
  max-width: 800px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 30px; 
}

.top_right {
  width: 190px;
  margin: 42px 0 0 0;
}

.top_right_li {
  display: flex;
  align-items: center;
  width: 190px;
  height: 118px;
  background-color: #fff;
  margin-bottom: 2px;
  padding: 0 0 0 15px;
}

.li_img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.li_right_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.li_title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fc661c;
}

.li_content {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.content {
  width: 1200px;
  margin: 0 auto 70px;
}

.content_tab {
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content_tab_item {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin: 0 0 20px 0;
  cursor: pointer;
}

.content_tab_item:first-child {
  margin-right: 120px;
}

.active_tab {
  color: #fc661c;
  position: relative;
}

.active_tab:after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 1px;
  display: block;
  background-color: #fc661c;
}

.tabBox {
  display: flex;
  align-items: center;
  margin: 80px 0 60px 0;
}

.remen {
  width: 17px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
}

.tab_biaoqian {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 25px;
}

.tab {
  display: flex;
  align-items: center;
}

.tab_item {
  width: 100px;
  height: 34px;
  background: #ffffff;
  border-radius: 17px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 34px;
  text-align: center;
  margin-right: 20px;
}

.active_item {
  background: #fc661c;
  color: #ffffff;
}

.renwuBox {
  display: flex;
  flex-wrap: wrap;
}

.renwu_item {
  width: 590px;
  /* height: 424px; */
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 36px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
}

.renwu_item:nth-child(2n) {
  margin-left: 20px;
}

.renwu_item_title {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.renwu_item_yaoqiu {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 30px;
  margin: 24px 0 0 0;
}

.renwu_item_content {
  font-size: 18px;
  font-family: Microsoft YaHei;
  color: #999999;
  line-height: 30px;
  height: 120px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* .renwu_item_content p:nth-child(1) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
} */
.renwu_item_content p {
  background-color: red;
}
.renwu_item_name {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 24px 0 18px 0;
}

.renwu_item_tag {
  display: flex;
  margin: 0 0 30px 0;
}

.renwu_item_tag_index {
  width: 90px;
  height: 33px;
  background: #f5f5f5;
  border-radius: 17px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
  text-align: center;
  margin: 0 25px 0 0;
}

.renwu_item_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.renwu_item_bottom_jiage {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff3d00;
}

.jiaogao {
  width: 112px;
  height: 40px;
  border: 1px solid #fc661c;
  border-radius: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fc661c;
  line-height: 40px;
  text-align: center;
  margin: 0 20px 0 0;
  cursor: pointer;
}

.chakan {
  width: 112px;
  height: 40px;
  background: #fc661c;
  border-radius: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.ossImgBox {
  margin: 30px auto;
  width: 40%;
  text-align: center;
}
</style>
