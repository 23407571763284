<template>
	<div class="index">
		<div class="top">
			<!-- <img src="https://static.shanchuangjiaoyu.com/ycpc/assets/head_img.jpg" class="pc" alt=""> -->
			<img src="../../assets/image/dangzhibu_banner2.jpg" class="pc" alt="">
			<div class="wap wap-menu" style="position: absolute;">
				<img src="./dangshi_files/menu.png" class="menu" alt="" style="display: inline;">
				<img src="./dangshi_files/close.png" class="close-menu" alt="" style="display: none;">
			</div>
		</div>
		<!-- nav 导航 -->
<!--		<div class="nav6">-->
<!--			<ul class="px1200" style="max-width: 1390px;">-->
<!--				<li><a href="https://news.bupt.edu.cn/dj/index.htm">网站首页</a></li>-->


<!--				<li><a href="https://news.bupt.edu.cn/dj/zyls.htm">重要论述</a></li>-->


<!--				<li><a href="https://news.bupt.edu.cn/dj/ywjj.htm">要闻聚焦</a></li>-->


<!--				<li><a href="https://news.bupt.edu.cn/dj/wwssbss.htm">我为师生办实事</a></li>-->


<!--				<li><a href="https://news.bupt.edu.cn/dj/jcdt.htm">基层动态</a></li>-->


<!--				<li><a href="https://news.bupt.edu.cn/dj/qwjd.htm">权威解读</a></li>-->


<!--				<li>-->
<!--					<a href="https://news.bupt.edu.cn/dj/xfhsyj.htm">寻访红色印记</a><span>&gt;</span>-->
<!--					<ul>-->

<!--						<li><a href="https://news.bupt.edu.cn/dj/xfhsyj/dsgs.htm">党史故事</a></li>-->

<!--						<li><a href="https://news.bupt.edu.cn/dj/xfhsyj/dsrw.htm">党史人物</a></li>-->

<!--						<li><a href="https://news.bupt.edu.cn/dj/xfhsyj/dsyzyj.htm">党史遗址遗迹</a></li>-->
<!--					</ul>-->
<!--				</li>-->



<!--				<li>-->
<!--					<a href="https://news.bupt.edu.cn/dj/xxzl.htm">学习资料</a><span>&gt;</span>-->
<!--					<ul>-->

<!--						<li><a href="https://news.bupt.edu.cn/dj/xxzl/tszl.htm">党史书架</a></li>-->

<!--						<li><a href="https://news.bupt.edu.cn/dj/xxzl/twzl.htm">图文学习</a></li>-->

<!--						<li><a href="https://news.bupt.edu.cn/dj/xxzl/yxsp.htm">影音资料</a></li>-->
<!--					</ul>-->
<!--				</li>-->



<!--				<div class="clear"></div>-->
<!--			</ul>-->
<!--		</div>-->

		<!-- 热点新闻 -->
		<div class="section section1 px1200">
			<div class="hot-new">
				<div class="new-switch">
					<div id="slideBox" class="slideBox">
						<div class="bd">
							<div class="tempWrap" style="overflow:hidden; position:relative; width:595px">
								<ul
									style="width: 4165px; position: relative; overflow: hidden; padding: 0px; margin: 0px; left: -595px;">
									<li class="clone" style="float: left; width: 595px;">
										<a href="https://news.bupt.edu.cn/dj/info/1020/2341.htm" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>用好红色资源 赓续红色血脉 努力创造无愧于历史和人民的新业绩</p>
										</a>
									</li>
									<li style="float: left; width: 595px;">
										<a  @click="goDetail(DZB_lunshu_8)" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>以史为鉴、开创未来，埋头苦干、勇毅前行</p>
										</a>
									</li>

									<li style="float: left; width: 595px;">
										<a href="https://news.bupt.edu.cn/dj/info/1020/2487.htm" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>坚持用马克思主义及其中国化创新理论武装全党</p>
										</a>
									</li>

									<li style="float: left; width: 595px;">
										<a href="https://news.bupt.edu.cn/dj/info/1020/2439.htm" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>坚定理想信念 补足精神之钙</p>
										</a>
									</li>

									<li style="float: left; width: 595px;">
										<a href="https://news.bupt.edu.cn/dj/info/1020/2364.htm" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>习近平：在纪念辛亥革命110周年大会上的讲话</p>
										</a>
									</li>

									<li style="float: left; width: 595px;">
										<a href="https://news.bupt.edu.cn/dj/info/1020/2341.htm" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>用好红色资源 赓续红色血脉 努力创造无愧于历史和人民的新业绩</p>
										</a>
									</li>

									<li class="clone" style="float: left; width: 595px;">
										<a href="https://news.bupt.edu.cn/dj/info/1020/2583.htm" target="_blank">
											<img src="https://static.shanchuangjiaoyu.com/ycpc/assets/new.jpg">
											<p>以史为鉴、开创未来，埋头苦干、勇毅前行</p>
										</a>
									</li>
								</ul>
							</div>

						</div>
						<!-- <div class="hd">
							<ul>
								<li class="on"><a></a></li>
								<li class=""><a></a></li>
								<li class=""><a></a></li>
								<li class=""><a></a></li>
								<li class=""><a></a></li>
							</ul>
						</div> -->
					</div>
				</div>
				<div class="new-list gglsits">
					<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
						<span
							style="background: url(https://news.bupt.edu.cn/dj/images/title-bj2.png) no-repeat left center;background-size: 100% 100%;">重要论述</span>
<!--						<a href="https://news.bupt.edu.cn/dj/zyls.htm" target="">更多+</a>-->
					</h2>
					<ul>
						<ul>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_1)"
									target="_blank" title="习近平：继续把党史总结学习教育宣传引向深入 更好把握和运用党的百年奋斗历史经">习近平：继续把党史总结学习教育宣传引向深入
									更好把握和运用党的百年奋斗历史经</a></li>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_2)"
									target="_blank" title="以史为鉴、开创未来，埋头苦干、勇毅前行">以史为鉴、开创未来，埋头苦干、勇毅前行</a></li>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_3)"
									target="_blank" title="习近平2021用典④国际关系">习近平2021用典④国际关系</a></li>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_4)"
									target="_blank" title="中共中央政治局召开专题民主生活会 习近平主持会议并发表重要讲话">中共中央政治局召开专题民主生活会
									习近平主持会议并发表重要讲话</a></li>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_5)"
									target="_blank" title="习近平2021用典③文化自信">习近平2021用典③文化自信</a></li>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_6)"
									target="_blank" title="习近平2021用典②治国理政">习近平2021用典②治国理政</a></li>
							<li><a style="text-align: left;" @click="goDetail(DZB_lunshu_7)"
									target="_blank" title="习近平2021用典①学史力行">习近平2021用典①学史力行</a></li>
						</ul>
					</ul>
				</div>
			</div>

			<div class="new-down">
				<div class="picScroll-left pc">
					<div class="bd">
						<div class="tempWrap" style="overflow:hidden; position:relative; width:1194px">
							<ul class="picList"
								style="width: 2786px; position: relative; overflow: hidden; padding: 0px; margin: 0px; left: -1194px;">
								<li class="clone" style="float: left; width: 378px;">
									<div class="pic"><a href="https://hqpp.bupt.edu.cn/" target="_blank"
											onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 59084)"><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/left_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>
								<li style="float: left; width: 378px;">
									<div class="pic"><a href="http://news.bupt.edu.cn/ddh15/" target="_blank"
											onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 61157)"><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/center_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>

								<li style="float: left; width: 378px;">
									<div class="pic"><a href="https://news.bupt.edu.cn/xxzt/bwcx/bydt.htm"
											target="_blank"
											onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 61156)"><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/right_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>

								<li style="float: left; width: 378px;">
									<div class="pic"><a
											><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/left_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>

								<li class="clone" style="float: left; width: 378px;">
									<div class="pic"><a target="_blank"
											><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/center_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>
								<li class="clone" style="float: left; width: 378px;">
									<div class="pic"><a
											target="_blank"
											><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/right_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>
								<li class="clone" style="float: left; width: 378px;">
									<div class="pic"><a href="https://hqpp.bupt.edu.cn/" target="_blank"
											onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 59084)"><img
												src="https://static.shanchuangjiaoyu.com/ycpc/assets/left_dang.jpg"
												width="378" height="103.63"></a></div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="wap">
					<div class="swiper-container swiper-container-initialized swiper-container-horizontal">
						<div class="swiper-wrapper" style="transition-duration: 0ms;">
							<div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2"><a
									href="https://hqpp.bupt.edu.cn/"
									onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 59084)" target="_blank"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/left_dang.jpg"></a></div>
							<div class="swiper-slide" data-swiper-slide-index="0"><a
									href="http://news.bupt.edu.cn/ddh15/"
									onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 61157)" target="_blank"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/center_dang.jpg"></a></div>
							<div class="swiper-slide" data-swiper-slide-index="1"><a
									href="https://news.bupt.edu.cn/xxzt/bwcx/bydt.htm"
									onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 61156)" target="_blank"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/right_dang.jpg"></a></div>
							<div class="swiper-slide" data-swiper-slide-index="2"><a href="https://hqpp.bupt.edu.cn/"
									onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 59084)" target="_blank"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/left_dang.jpg"></a></div>

							<div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
								data-swiper-slide-index="0"><a href="http://news.bupt.edu.cn/ddh15/"
									onclick="_addDynClicks(&quot;wbimage&quot;, 1727158128, 61157)" target="_blank"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/center_dang.jpg"></a></div>
						</div>
						<span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="section section2">
			<div class="s2-child">
				<div class="s2-top px1200">
					<div class="s2-left new-list" style="float: left;">
						<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
							<span
								style="background: url(https://news.bupt.edu.cn/dj/images/title-bj2.png) no-repeat left center;background-size: 100% 100%;">要闻聚焦</span>
							<!-- <a href="https://news.bupt.edu.cn/dj/ywjj.htm" target="">更多+</a> -->
							<a target=""></a>
						</h2>
						<ul>
							<ul>
								<li><a target="_blank" title="不忘初心跟党走，牢记使命勇担当，热烈祝贺精研学堂教育党支部成立"
										style="text-align: left;"
										@click="goDetail(DZB_yaowen_1)">不忘初心跟党走，牢记使命勇担当，热烈祝贺精研学堂教育党支部成立</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_yaowen_2)"
										target="_blank" title="精诚合作，携手共赢，精研学堂教育万企合作项目正式上线">精诚合作，携手共赢，精研学堂教育万企合作项目正式上线</a>
								</li>
								<li><a style="text-align: left;" @click="goDetail(DZB_yaowen_3)"
										target="_blank"
										title="历城区宣传部副部长、区文旅局局长王克锋等一行领导莅临精研学堂教育考察指导！">历城区宣传部副部长、区文旅局局长王克锋等一行领导莅临精研学堂教育考察指导！</a>
								</li>
								<li><a style="text-align: left;" @click="goDetail(DZB_yaowen_4 )"
										target="_blank"
										title="美好未来，就在精研学堂--精研学堂教育乡村爱心支教及捐赠活动">美好未来，就在精研学堂--精研学堂教育乡村爱心支教及捐赠活动</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_yaowen_5)"
										target="_blank"
										title="热烈欢迎济南大学继续教育学院孙喜武院长一行莅临精研学堂教育考察指导工作">热烈欢迎济南大学继续教育学院孙喜武院长一行莅临精研学堂教育考察指导工作</a>
								</li>
								<li><a style="text-align: left;" @click="goDetail(DZB_yaowen_6 )"
										target="_blank"
										title="热烈欢迎济南历城区文联赵景伟主席、历城区委宣传部马本法处长莅临精研学堂教育指导工作">热烈欢迎济南历城区文联赵景伟主席、历城区委宣传部马本法处长莅临精研学堂教育指导工作</a>
								</li>
							</ul>
						</ul>
					</div>
					<div class="s2-right new-list">
						<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
							<span
								style="background: url(https://news.bupt.edu.cn/dj/images/title-bj2.png) no-repeat left center;background-size: 100% 100%;">企业动态</span>
							<!-- <a href="https://news.bupt.edu.cn/dj/jcdt.htm" target="">更多+</a> -->
							<a target=""></a>
						</h2>
						<ul>
							<ul>
								<li><a style="text-align: left;" @click="goDetail(DZB_dongtai_1)"
										target="_blank"
										title="促进职业教育发展，正确树立用人观念，精研学堂教育面向专科生招聘！">促进职业教育发展，正确树立用人观念，精研学堂教育面向专科生招聘！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_dongtai_2)"
										target="_blank"
										title="上档次海报都这样设计，“精研学堂教育”：教你几个方法用起来！">上档次海报都这样设计，“精研学堂教育”：教你几个方法用起来！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_dongtai_3)"
										target="_blank"
										title="提升50%成交转化率，精研学堂教育表示，这样设计的案件技巧最实用！">提升50%成交转化率，精研学堂教育表示，这样设计的案件技巧最实用！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_dongtai_4)"
										target="_blank"
										title="现代精研学堂教育科技：线上教育相比较于线下教育，竟有这些优势！">现代精研学堂教育科技：线上教育相比较于线下教育，竟有这些优势！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_dongtai_5)"
										target="_blank"
										title="精研学堂教育：我要上清华？我要上北大？学习教育的真正意义是什么？">精研学堂教育：我要上清华？我要上北大？学习教育的真正意义是什么？</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_dongtai_6)"
										target="_blank"
										title="精研学堂教育科技被评为，在线教育优质品牌，学员感恩送锦旗！">精研学堂教育科技被评为，在线教育优质品牌，学员感恩送锦旗！</a></li>
							</ul>
						</ul>
					</div>
				</div>
				<div class="s2-top px1200">
					<div class="s2-left new-list" style="width: 100%;">
						<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
							<span
								style="background: url(https://news.bupt.edu.cn/dj/images/title-bj2.png) no-repeat left center;background-size: 100% 100%;">师生风采</span>
							<!-- <a href="https://news.bupt.edu.cn/dj/wwssbss.htm" target="">更多+</a> -->
							<a target=""></a>
						</h2>
						<ul>
							<ul class="wwss">
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_1)"
										target="_blank" title="毕业后在精研学堂教育充电学习，收获颇丰！">毕业后在精研学堂教育充电学习，收获颇丰！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_2)"
										target="_blank" title="不为莫知而止休，祝愿精研学堂教育蒸蒸日上，步步登高">不为莫知而止休，祝愿精研学堂教育蒸蒸日上，步步登高</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_3)"
										target="_blank"
										title="高效优质的服务暖人心，学员赠送锦旗，对精研学堂教育的栽培表示感谢！">高效优质的服务暖人心，学员赠送锦旗，对精研学堂教育的栽培表示感谢！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_4)"
										target="_blank" title="跨专业在精研学堂教育学习，也可顺利毕业找到心仪工作！">跨专业在精研学堂教育学习，也可顺利毕业找到心仪工作！</a>
								</li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_5)"
										target="_blank" title="满怀感激，不远千里来到精研学堂教育赠送锦旗">满怀感激，不远千里来到精研学堂教育赠送锦旗</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_6)"
										target="_blank"
										title="热烈欢迎山东艺术设计职业学院副院长魏广等一行莅临精研学堂教育参观交流">热烈欢迎山东艺术设计职业学院副院长魏广等一行莅临精研学堂教育参观交流</a>
								</li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_7)"
										target="_blank"
										title="热烈庆祝精研学堂教育荣获“济南市公共就业服务中心技能培训机构”认证">热烈庆祝精研学堂教育荣获“济南市公共就业服务中心技能培训机构”认证</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_8)"
										target="_blank" title="喜讯！热烈祝贺精研学堂教育荣获“国家高新技术企业”认证">喜讯！热烈祝贺精研学堂教育荣获“国家高新技术企业”认证</a>
								</li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_9)"
										target="_blank" title="学员高度认可送锦旗--学员真挚赠与精研学堂教育锦旗">学员高度认可送锦旗--学员真挚赠与精研学堂教育锦旗</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_10)"
										target="_blank" title="学员真挚感谢老师教导，力挺精研学堂教育！">学员真挚感谢老师教导，力挺精研学堂教育！</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_11)"
										target="_blank" title="精研学堂教育：影视后期发展前景怎么样？">精研学堂教育：影视后期发展前景怎么样？</a></li>
								<li><a style="text-align: left;" @click="goDetail(DZB_fengcai_12)"
										target="_blank"
										title="精研学堂教育荣获第七届中国品牌创新发展论坛诚信品牌奖，校长刘潇做大会发言">精研学堂教育荣获第七届中国品牌创新发展论坛诚信品牌奖，校长刘潇做大会发言</a>
								</li>
							</ul>
						</ul>
					</div>

				</div>
				<div class="s2-btm px1200">
					<div class="dsll">
						<h2>
              <img src="./dangshi_files/dang1.png" alt="">
              <span>历次党代会</span>
<!--              <a href="https://news.bupt.edu.cn/dj/index/bylcddh.htm" target="">更多+</a>-->
            </h2>
						<div style="background: #fff;padding: 30px;">
							<div class="ddhleft fl">
								<a  title="第十五次党代会">
									<img src="../../assets/image/1000-584.jpg">
									<div class="fg-tit-bg">
										<p>第十五次党代会...</p>
									</div>
								</a>

							</div>


							<ul class="ddh-rig fr">
								<li>
									<a title="第十四次党代会概览">
										<img src="../../assets/image/843-561.jpg">
										<div class="fg-tit-bg">
											<p>第十四次党代会概览...</p>
										</div>
									</a>
								</li>
								<li>
									<a title="第十四次党代会概览">
										<img src="../../assets/image/843-562.jpg">
										<div class="fg-tit-bg">
											<p>第十四次党代会概览...</p>
										</div>
									</a>
								</li>
								<li>
									<a title="第十四次党代会概览">
										<img src="../../assets/image/843-563.jpg">
										<div class="fg-tit-bg">
											<p>第十四次党代会概览...</p>
										</div>
									</a>
								</li>
								<li>
									<a title="第十四次党代会概览">
										<img src="../../assets/image/843-564.jpg">
										<div class="fg-tit-bg">
											<p>第十四次党代会概览...</p>
										</div>
									</a>
								</li>
								<div class="clear"></div>
							</ul>

							<div class="clear"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section section3">
			<div class="dsll px1200">
				<h2>
          <img src="./dangshi_files/dang1.png" alt="">
          <span>权威解读</span>
<!--          <a href="https://news.bupt.edu.cn/dj/qwjd.htm" target="">更多+</a>-->
        </h2>
				<ul>
					<ul>
						<li>
							<a @click="goDetail(DZB_jiedu_1)" target="_blank"
								title="深刻理解“十个坚持”的宝贵历史经验">
								<h3 style="text-align: left;">深刻理解“十个坚持”的宝贵历史经验 <span>理论</span></h3>
								<p>党的十九届六中全会审议通过《中共中央关于党的百年奋斗重大成就和历史经验的决议》(以下简称《决议》)，以“十个坚持”深刻总结了中国共产党领导中国人民百年奋斗的历史经验。“十个坚持”是系统完整、相互贯通的统一整体，其中，党的领导是根本、人民至上是命脉、理论创新是先导、独立自主是基点、中国道路是方向、胸怀天下是情怀、开拓创新是动力、敢于斗争是品格、统一战线是法宝、自我革命是特质，十个方面相互联系、相互...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_2)" target="_blank"
								title="从三个历史决议看我们党如何总结历史经验">
								<h3 style="text-align: left;">从三个历史决议看我们党如何总结历史经验 <span>理论</span></h3>
								<p>【学习贯彻习近平新时代中国特色社会主义思想】注重从党的历史经验中汲取智慧和力量以不断开拓前进，是我们党的一个优良传统和政治优势。一百年来，中国共产党始终高度重视对历史经验的总结和运用，在探究历史规律中掌握历史主动，不断增强执政本领，不断凝聚智慧力量，不断从胜利走向新的胜利。我们党的历史，在一定意义上就是一部创造经验、总结经验、积累经验、运用经验的历史。回望党的百年历程，党的六届七中全会、十一届...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_3)" target="_blank"
								title="百年来马克思主义中国化的基本经验">
								<h3 style="text-align: left;">百年来马克思主义中国化的基本经验 <span>理论</span></h3>
								<p>中国共产党的历史是一部不断推进马克思主义中国化的历史。党的十九届六中全会通过的《中共中央关于党的百年奋斗重大成就和历史经验的决议》指出：“马克思主义理论不是教条而是行动指南，必须随着实践发展而发展，必须中国化才能落地生根、本土化才能深入人心。”马克思主义中国化的百年历程，书写了马克思主义发展史上的伟大篇章，推动了中国共产党与中国人民的伟大觉醒，使实现中华民族伟大复兴的历史进程由被动转向主动。一...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_4)" target="_blank"
								title="习近平新时代中国特色社会主义思想实现了马克思主义中国化新的飞跃">
								<h3 style="text-align: left;">习近平新时代中国特色社会主义思想实现了马克思主义中国化新的飞跃 <span>理论</span></h3>
								<p>《中共中央关于党的百年奋斗重大成就和历史经验的决议》（以下简称《决议》）指出，“习近平新时代中国特色社会主义思想是当代中国马克思主义、二十一世纪马克思主义，是中华文化和中国精神的时代精华，实现了马克思主义中国化新的飞跃”。这一重大论断科学阐明了习近平新时代中国特色社会主义思想的理论内涵和重大意义，标明了这一重要思想在马克思主义发展史、中华文明发展史上的重要地位。深刻回答了新时代的重大时代课题坚...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_5)" target="_blank"
								title="改革开放是党的一次伟大觉醒（深入学习贯彻党的十九届六中全会精神）">
								<h3 style="text-align: left;">改革开放是党的一次伟大觉醒（深入学习贯彻党的十九届六中全会精神） <span>理论</span></h3>
								<p>曲青山党的十九届六中全会通过的《中共中央关于党的百年奋斗重大成就和历史经验的决议》指出：“改革开放是党的一次伟大觉醒”。深刻认识、准确把握我们党作出的这个重大历史结论和重要政治论断，对我们深刻理解党的十一届三中全会是划时代的、党的十八届三中全会也是划时代的，学习好党在改革开放和社会主义现代化建设新时期的历史经验，继续解放思想、锐意进取，具有重要指导意义。&nbsp;伟大觉醒基于对党和国家前途命运的深刻把握...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_6)" target="_blank"
								title="党的理论创新百年历程及其启示">
								<h3 style="text-align: left;">党的理论创新百年历程及其启示 <span>理论</span></h3>
								<p>中国共产党是一个以马克思主义为指导思想和理论基础的政党。一百年来，党坚持把马克思主义写在自己的旗帜上，坚持用马克思主义的立场、观点、方法观察时代、把握时代、引领时代，不断推进马克思主义中国化，实现了马克思主义中国化一次又一次飞跃。创立毛泽东思想，实现了马克思主义中国化的第一次历史性飞跃党的十九届六中全会公报指出，毛泽东思想是马克思列宁主义在中国的创造性运用和发展，是被实践证明了的关于中国革命和...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_7)" target="_blank"
								title="对第三个历史决议的深刻阐释">
								<h3 style="text-align: left;">对第三个历史决议的深刻阐释 <span>理论</span></h3>
								<p>“通过！”2021年11月11日下午，人民大会堂，习近平总书记话音甫落，掌声如潮，响彻全场。一篇闪耀着马克思主义真理光辉的文献诞生了！党的十九届六中全会全面总结党的百年奋斗重大成就和历史经验，体现了我们党重视和善于运用历史规律的高度政治自觉，体现了我们党牢记初心使命、继往开来的自信和担当。全会最重要的成果，是审议通过了《中共中央关于党的百年奋斗重大成就和历史经验的决议》（以下简称《决议》）。这篇马克思...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_8)" target="_blank"
								title="牢牢把握党百年奋斗的历史经验">
								<h3 style="text-align: left;">牢牢把握党百年奋斗的历史经验 <span>理论</span></h3>
								<p>作者：国防大学政治学院党的百年奋斗征程，在人类文明的历史长河中只是短暂瞬间，但所积淀的历史财富却足以永恒。党的十九届六中全会审议通过的《中共中央关于党的百年奋斗重大成就和历史经验的决议》，凝结着中国共产党人寻根溯源、面向未来的历史主动精神，是我们党在新时代牢记初心使命、坚持和发展中国特色社会主义的政治宣言。这份光辉文献，系统总结了中国共产党百年奋斗的历史经验，既为我们看清楚过去党为什么能够成功...
								</p>
								<span>详情</span>
							</a>
						</li>
						<li>
							<a @click="goDetail(DZB_jiedu_9)" target="_blank"
								title="党史学习教育要坚持两大原则">
								<h3 style="text-align: left;">党史学习教育要坚持两大原则 <span>理论</span></h3>
								<p>开展党史学习教育，其根本目的是要吸取前人的经验教训，来服务于现实，以改进今天和明天的工作。习近平总书记指出：“我们回顾历史，不是为了从成功中寻求慰藉，更不是为了躺在功劳簿上、为回避今天面临的困难和问题寻找借口，而是为了总结历史经验、把握历史规律，增强开拓前进的勇气和力量。”“历史总是要前进的，历史从不等待一切犹豫者、观望者、懈怠者、软弱者。只有与历史同步伐、与时代共命运的人，才能赢得光明的未来...
								</p>
								<span>详情</span>
							</a>
						</li>
					</ul>
				</ul>
			</div>
		</div>

		<div class="section section4">
			<div class="dsll px1200">
				<h2>
          <img src="./dangshi_files/dang1.png" alt="">
          <span>寻访红色印记</span>
<!--          <a href="https://news.bupt.edu.cn/dj/xfhsyj.htm" target="">更多+</a>-->
        </h2>
			</div>
			<div class="s4-main px1200">
				<div class="s4-left">
					<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
						<span
							style="background: url(https://news.bupt.edu.cn/dj/images/title-bj2.png) no-repeat left center;background-size: 100% 100%;">党史故事</span>
<!--						<a href="https://news.bupt.edu.cn/dj/xfhsyj/dsgs.htm" target="">更多+</a>-->
					</h2>
					<div class="s4-lbody">
						<div>
							<div class="s4-limg1"><a href="http://www.12371.cn/special/ds"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/red1.jpg" alt=""></a></div>
							<div class="s4-limg2"><a
									href="https://article.xuexi.cn/articles/video/index.html?art_id=6606166362615238040&amp;part_id=15736429330359604202&amp;reedit_timestamp=1601353139000&amp;to_audit_timestamp=2020-09-29+12%3A18%3A59&amp;study_style_id=video_default&amp;ref_read_id=1JTHQ2ZW-PWOV-N3VA-VYBC-RVJIZMYNA"><img
										src="https://static.shanchuangjiaoyu.com/ycpc/assets/red2.jpg" alt=""></a></div>
						</div>
						<div class="new-list">
							<ul>
								<li><a style="text-align:left;" href="http://www.12371.cn/special/ds">党史日志</a></li>
								<li><a style="text-align:left;"
										href="https://article.xuexi.cn/articles/video/index.html?art_id=6606166362615238040&amp;part_id=15736429330359604202&amp;reedit_timestamp=1601353139000&amp;to_audit_timestamp=2020-09-29+12%3A18%3A59&amp;study_style_id=video_default&amp;ref_read_id=1JTHQ2ZW-PWOV-N3VA-VYBC-RVJIZMYNA">党史故事100讲</a>
								</li>
								<li><a style="text-align:left;"
                       @click="goDetail(DZB_gushi_3)">新四军军部在武汉成立始末</a></li>
								<li><a style="text-align:left;"
                       @click="goDetail(DZB_gushi_4)">“打响武装反抗国民党反动派的第一枪”</a>
								</li>
								<li><a style="text-align:left;"
                       @click="goDetail(DZB_gushi_5)">青年毛泽东的志气骨气底气</a></li>
								<li><a style="text-align:left;"
                       @click="goDetail(DZB_gushi_6)">90年前的苏区 这些“海归”干了哪些大事</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="s4-right">
					<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
						<span
							style="background: url(https://news.bupt.edu.cn/dj/images/title-bj2.png) no-repeat left center;background-size: 100% 100%;">党史人物</span>
<!--						<a href="https://news.bupt.edu.cn/dj/xfhsyj/dsrw.htm" target="">更多+</a>-->
					</h2>
					<ul>
						<li><a @click="goDetail(DZB_renwu_1)"><img alt=""
									src="https://static.shanchuangjiaoyu.com/ycpc/assets/pengxuefeng.jpg"
									height=" height: 192px;">
								<p>彭雪枫：“宁...</p>
							</a></li>

						<li><a @click="goDetail(DZB_renwu_2)"><img alt=""
									src="https://static.shanchuangjiaoyu.com/ycpc/assets/quqiubai.jpg"
									height=" height: 192px;">
								<p>瞿秋白：出版...</p>
							</a></li>

						<li><a @click="goDetail(DZB_renwu_3)"><img alt=""
									src="https://static.shanchuangjiaoyu.com/ycpc/assets/xiangjingyu.jpg"
									height=" height: 192px;">
								<p>向警予：十载...</p>
							</a></li>

						<li><a @click="goDetail(DZB_renwu_4)"><img alt=""
									src="https://static.shanchuangjiaoyu.com/ycpc/assets/yangjingyu.jpg"
									height=" height: 192px;">
								<p>杨靖宇：恨不...</p>
							</a></li>

						<li><a @click="goDetail(DZB_renwu_5)"><img alt=""
									src="https://static.shanchuangjiaoyu.com/ycpc/assets/dengzhongxia.jpg"
									height=" height: 192px;">
								<p>邓中夏：最后...</p>
							</a></li>

						<li><a @click="goDetail(DZB_renwu_6)"><img alt=""
									src="https://static.shanchuangjiaoyu.com/ycpc/DZB_wenzhang_img/renwu_6_1.jpeg"
									height=" height: 192px;">
								<p>叶挺：烈火...</p>
							</a></li>

					</ul>
				</div>
			</div>
		</div>

		<div class="section section5 px1200">
			<h2 class="ggtit-h2" style="display: flex;justify-content: space-between;">
				<span>党史遗址遗迹</span>
<!--				<a href="https://news.bupt.edu.cn/dj/xfhsyj/dsyzyj.htm" target="">更多+</a>-->
			</h2>
			<div class="s5-main">
				<div class="picScroll-left pc">
					<div class="bd">
						<div class="tempWrap" style="overflow:hidden; position:relative; width:1208px">
							<ul class="picList"
								style="width: 3322px; position: relative; overflow: hidden; padding: 0px; margin: 0px; left: -302px;">
								<li class="clone" style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=5286429346812675044&amp;item_id=5286429346812675044"
											target="_blank"><img
												src="./dangshi_files/7E439E3537463A52FD7791F6485_34025F14_AE37.jpg">
											<p>红色地标里的党史故事：白求恩和驮在马背上的“卢沟桥”</p>
										</a>
									</div>
								</li>

								<li style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=1858035981608256216&amp;item_id=1858035981608256216"
											target="_blank"><img
												src="./dangshi_files/63631E0A8B8E7F400BE519C0DBA_F3DDAB36_DC63.jpg">
											<p>延安时期的“窑洞大学”</p>
										</a>
									</div>
								</li>

								<li style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=9252083335983799840&amp;item_id=9252083335983799840"
											target="_blank"><img
												src="./dangshi_files/6247759C6DD2E415EBAA66A629B_F8E78568_F927.jpg">
											<p>杨家沟：党中央转战陕北红色文化第一村</p>
										</a>
									</div>
								</li>

								<li style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=9423239878974858607&amp;item_id=9423239878974858607"
											target="_blank"><img
												src="./dangshi_files/D90DE8C2FC8B0B3576D76B485DF_4FB1DE99_DF92.jpg">
											<p>上海市静安区：中共中央秘书处机关旧址</p>
										</a>
									</div>
								</li>

								<li style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=10011362587977616045&amp;item_id=10011362587977616045"
											target="_blank"><img
												src="./dangshi_files/EDD8658DD1F5F0C9A8BECCD488D_F7A176BE_D582.jpg">
											<p>红色“冀”忆｜城南庄：城南轶事 建国起步</p>
										</a>
									</div>
								</li>

								<li style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=5819802463967559968&amp;item_id=5819802463967559968"
											target="_blank"><img
												src="./dangshi_files/00B906A00257BB6F3CE1870C3A9_A3206F65_BC9E.jpg">
											<p>东经116度的血脉相连</p>
										</a>
									</div>
								</li>

								<li style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=5286429346812675044&amp;item_id=5286429346812675044"
											target="_blank"><img
												src="./dangshi_files/7E439E3537463A52FD7791F6485_34025F14_AE37.jpg">
											<p>红色地标里的党史故事：白求恩和驮在马背上的“卢沟桥”</p>
										</a>
									</div>
								</li>

								<li class="clone" style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=1858035981608256216&amp;item_id=1858035981608256216"
											target="_blank"><img
												src="./dangshi_files/63631E0A8B8E7F400BE519C0DBA_F3DDAB36_DC63.jpg">
											<p>延安时期的“窑洞大学”</p>
										</a>
									</div>
								</li>
								<li class="clone" style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=9252083335983799840&amp;item_id=9252083335983799840"
											target="_blank"><img
												src="./dangshi_files/6247759C6DD2E415EBAA66A629B_F8E78568_F927.jpg">
											<p>杨家沟：党中央转战陕北红色文化第一村</p>
										</a>
									</div>
								</li>
								<li class="clone" style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=9423239878974858607&amp;item_id=9423239878974858607"
											target="_blank"><img
												src="./dangshi_files/D90DE8C2FC8B0B3576D76B485DF_4FB1DE99_DF92.jpg">
											<p>上海市静安区：中共中央秘书处机关旧址</p>
										</a>
									</div>
								</li>
								<li class="clone" style="float: left; width: 282px;">
									<div class="pic">
										<a href="https://www.xuexi.cn/lgpage/detail/index.html?id=10011362587977616045&amp;item_id=10011362587977616045"
											target="_blank"><img
												src="./dangshi_files/EDD8658DD1F5F0C9A8BECCD488D_F7A176BE_D582.jpg">
											<p>红色“冀”忆｜城南庄：城南轶事 建国起步</p>
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="wap">
					<div class="swiper-container swiper-container-initialized swiper-container-horizontal">
						<div class="swiper-wrapper" style="transition-duration: 0ms;">
							<div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2"><a
									href="https://www.xuexi.cn/lgpage/detail/index.html?id=9423239878974858607&amp;item_id=9423239878974858607"
									target="_blank"><img
										src="./dangshi_files/D90DE8C2FC8B0B3576D76B485DF_4FB1DE99_DF92.jpg">
									<p>上海市静安区：中共中央秘书处机关旧址</p>
								</a></div>
							<div class="swiper-slide" data-swiper-slide-index="0"><a
									href="https://www.xuexi.cn/lgpage/detail/index.html?id=1858035981608256216&amp;item_id=1858035981608256216"
									target="_blank"><img
										src="./dangshi_files/63631E0A8B8E7F400BE519C0DBA_F3DDAB36_DC63.jpg">
									<p>延安时期的“窑洞大学”</p>
								</a></div>
							<div class="swiper-slide" data-swiper-slide-index="1"><a
									href="https://www.xuexi.cn/lgpage/detail/index.html?id=9252083335983799840&amp;item_id=9252083335983799840"
									target="_blank"><img
										src="./dangshi_files/6247759C6DD2E415EBAA66A629B_F8E78568_F927.jpg">
									<p>杨家沟：党中央转战陕北红色文化第一村</p>
								</a></div>
							<div class="swiper-slide" data-swiper-slide-index="2"><a
									href="https://www.xuexi.cn/lgpage/detail/index.html?id=9423239878974858607&amp;item_id=9423239878974858607"
									target="_blank"><img
										src="./dangshi_files/D90DE8C2FC8B0B3576D76B485DF_4FB1DE99_DF92.jpg">
									<p>上海市静安区：中共中央秘书处机关旧址</p>
								</a></div>

							<div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
								data-swiper-slide-index="0"><a
									href="https://www.xuexi.cn/lgpage/detail/index.html?id=1858035981608256216&amp;item_id=1858035981608256216"
									target="_blank"><img
										src="./dangshi_files/63631E0A8B8E7F400BE519C0DBA_F3DDAB36_DC63.jpg">
									<p>延安时期的“窑洞大学”</p>
								</a></div>
						</div>
						<span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="section section6">
			<div class="s6-child">
				<div class="dsll px1200">
					<h2 style="display: flex; align-items: center">
            <img src="./dangshi_files/dang1.png" alt="">
            <span>学习资料</span>
<!--            <a href="https://news.bupt.edu.cn/dj/xxzl.htm" target="" style=" margin-top: -62px;">更多+</a>-->
					</h2>

				</div>
				<div class="s6-main px1200">
					<div class="">
						<div class="picMarquee-left">

							<div class="bd">
								<div class="tempWrap" style="overflow:hidden; position:relative; width:1200px; height: 266px">
                  <vue-seamless-scroll :data="studentsList" :class-option="classOption">
                    <ul class="picList"
                      style="width: 100%; position: relative; display: flex; overflow: hidden; padding: 0px; margin: 0px;">

                      <li class="clone" style="float: left; width: 100%;" v-for="(item,index) in studentsList" :key="index">
                        <div class="pic">
                          <a :href="item.url" target="_blank">
                            <img :src="item.img_url">
                            <p>{{ item.text }}</p>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </vue-seamless-scroll>

                </div>

							</div>
							<div class="hd">
								<a class="next">
                  <img @click="left_shijian" src="./dangshi_files/left-arr.png" alt="">
                </a>
								<a class="prev">
                  <img @click="right_shijian" src="./dangshi_files/right-arr.png" alt="">
                </a>
							</div>
						</div>
					</div>
					<div class="wap"></div>
				</div>
			</div>
		</div>

		<div id="footer">
			<div class="footer">
				<!-- 版权内容请在本组件"内容配置-版权"处填写 -->
				<p>版权所有：精研学堂教育科技（山东）有限公司 </p>
				<p>地址: 山东省济南市历城区北园大街9号荣盛时代国际广场7号楼7-2506&nbsp; &nbsp; 邮编: 250000</p>
			</div>
		</div>
	</div>
</template>

<script>
	// import "./dangshi_files/swiper.css";
	// import "./dangshi_files/public.css";
	// // import "./dangshi_files/style.css";
	// import "./dangshi_files/stylem.css";
	import {studentsList} from "../../assets/js/jsonData";
  import {
    DZB_dongtai_1,
    DZB_dongtai_2,
    DZB_dongtai_3,
    DZB_dongtai_4,
    DZB_dongtai_5,
    DZB_dongtai_6,
    DZB_fengcai_1,
    DZB_fengcai_10,
    DZB_fengcai_11,
    DZB_fengcai_12,
    DZB_fengcai_2,
    DZB_fengcai_3,
    DZB_fengcai_4,
    DZB_fengcai_5,
    DZB_fengcai_6,
    DZB_fengcai_7,
    DZB_fengcai_8,
    DZB_fengcai_9,
    DZB_jiedu_1,
    DZB_jiedu_2,
    DZB_jiedu_3,
    DZB_jiedu_4,
    DZB_jiedu_5,
    DZB_jiedu_6,
    DZB_jiedu_7,
    DZB_jiedu_8,
    DZB_jiedu_9,
    DZB_lunshu_1,
    DZB_lunshu_2,
    DZB_lunshu_3,
    DZB_lunshu_4,
    DZB_lunshu_5,
    DZB_lunshu_6,
    DZB_lunshu_7,
    DZB_lunshu_8,
    DZB_yaowen_1,
    DZB_yaowen_2,
    DZB_yaowen_3,
    DZB_yaowen_4,
    DZB_yaowen_5,
    DZB_yaowen_6,
    DZB_renwu_1,
    DZB_renwu_2,
    DZB_renwu_3,
    DZB_renwu_4,
    DZB_renwu_5,
    DZB_renwu_6,
    DZB_gushi_3,
    DZB_gushi_4,
    DZB_gushi_5,
    DZB_gushi_6,
  } from "../../assets/js/DZB_detail";
  export default {
		name: 'Dangzhibu',
		// components: {
		// 	vueSeamlessScroll
		// },
		data() {
			return {
				list: '',

        studentsList: [{
          url: 'http://fuwu.12371.cn/2015/06/15/ARTI1434358914061382.shtml',
          img_url: require('./dangshi_files/C5F43BA61CBA88EE359C43E9A19_8923E79E_2F7D.jpg'),
          text: '《新中国口述史》'
        },{
          url: 'http://www.12371.cn/special/blqs/xjptzglz/',
          img_url: require('./dangshi_files/E3CA9C95BC6DBCD819C53C6C495_FC85228C_8CE9.gif'),
          text: '《习近平谈治国理政》第一卷'
        },{
          url: 'http://www.12371.cn/special/zglz2/',
          img_url: require('./dangshi_files/3DFF91E388B7674507A43149DF3_8EDA7CA3_5458.gif'),
          text: '《习近平谈治国理政》第二卷'
        },{
          url: 'http://www.12371.cn/special/zglz3/',
          img_url: require('./dangshi_files/0CC543309A6F643428017223167_70437786_2C56.gif'),
          text: '《习近平谈治国理政》第三卷'
        },{
          url: 'http://www.12371.cn/special/lzggcdls/index.shtml',
          img_url: require('./dangshi_files/91F39C2DB172A46BE8E3FEBB76E_7F8A7B38_7124.gif'),
          text: '《论中国共产党历史》'
        },{
          url: 'http://cpc.people.com.cn/n1/2021/0225/c64387-32036202.html',
          img_url: require('./dangshi_files/E393BFEAA634BF8D9713D82C9E9_5B408620_50AF.gif'),
          text: '《毛泽东邓小平江泽民胡锦涛关于中国共产党历史论述摘编》'
        },{
          url: 'http://www.12371.cn/special/sxwd/',
          img_url: require('./dangshi_files/4D85FB8589909455C389CA0DC93_6CA76C00_B0D3.gif'),
          text: '《习近平新时代中国特色社会主义思想学习问答》'
        }],
        classOption: {
          limitMoveNum: 6, // 开始无缝滚动的数据量 this.dataList.length
          direction: 2,    // 0向下 1向上 2向左 3向右
        },
        DZB_yaowen_1,
        DZB_yaowen_2,
        DZB_yaowen_3,
        DZB_yaowen_4,
        DZB_yaowen_5,
        DZB_yaowen_6,
        DZB_dongtai_1,
        DZB_dongtai_2,
        DZB_dongtai_3,
        DZB_dongtai_4,
        DZB_dongtai_5,
        DZB_dongtai_6,
        DZB_fengcai_1,
        DZB_fengcai_2,
        DZB_fengcai_3,
        DZB_fengcai_4,
        DZB_fengcai_5,
        DZB_fengcai_6,
        DZB_fengcai_7,
        DZB_fengcai_8,
        DZB_fengcai_9,
        DZB_fengcai_10,
        DZB_fengcai_11,
        DZB_fengcai_12,
        DZB_lunshu_1,
        DZB_lunshu_2,
        DZB_lunshu_3,
        DZB_lunshu_4,
        DZB_lunshu_5,
        DZB_lunshu_6,
        DZB_lunshu_7,
        DZB_lunshu_8,
        DZB_jiedu_1,
        DZB_jiedu_2,
        DZB_jiedu_3,
        DZB_jiedu_4,
        DZB_jiedu_5,
        DZB_jiedu_6,
        DZB_jiedu_7,
        DZB_jiedu_8,
        DZB_jiedu_9,
        DZB_renwu_1,
        DZB_renwu_2,
        DZB_renwu_3,
        DZB_renwu_4,
        DZB_renwu_5,
        DZB_renwu_6,
        DZB_gushi_3,
        DZB_gushi_4,
        DZB_gushi_5,
        DZB_gushi_6,
			}
		},
		mounted() {
			// this.getData()
		},
		methods: {
			goDetail(type) {
        // console.log("asdsa",eval())
        window.localStorage.setItem('actIndx',JSON.stringify(type))
				this.$router.push({name: 'DZB_detail', params: {type: type}})
			},

      //点击向左滑动
      left_shijian() {
        this.classOption.direction = 2
      },

      //点击向右滑动
      right_shijian() {
        this.classOption.direction = 3
      },
		}
	}
</script>

<style>
	/* *{
		padding: 0;
		margin: 0;
		border: 0;
	} */
	* {
		padding: 0;
		margin: 0;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-o-box-sizing: border-box;
		box-sizing: border-box;
	}

	html,
	body {
		color: #000;
		font-size: 14px;
		font-family: "微软雅黑";
		/* max-width: 1920px; */
		margin-left: auto !important;
		margin-right: auto !important;
		text-align: left;
		/* height: 100%; */
		width: 100%;
		overflow-x: hidden;
	}

	li {
		list-style: none;
	}

	a {
		text-decoration: none;
		
	}
	a:hover{
		cursor:pointer
	}

	img,
	input {
		border: 0;
	}

	.wap {
		display: none;
	}

	body .clear {
		clear: both;
		height: 0 !important;
		width: 0 !important;
		border: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	.px1200 {
		max-width: 1200px;
		margin: 0 auto;
	}


	.top {
		max-width: 1920px;
		margin: 0 auto;
		position: relative;
	}

	.top>img {
		display: block;
		width: 100%;
	}

	.wap-menu {
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 99999;
	}

	.wap-menu img {
		height: 30px;
	}

	.close-menu {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
	}

	.nav6 {
		background: url(https://news.bupt.edu.cn/dj/images/nav-bj-box.png) no-repeat center center;
		background-size: 100% 100%;
		height: 84px;
	}

	.nav6>ul {
		background: url(https://news.bupt.edu.cn/dj/images/nav-bj.png) no-repeat center center;
		background-size: 100% 100%;
		position: relative;
		top: -19px;
		padding: 0 53px;
	}

	.nav6>ul>li {
		float: left;
		width: 12%;
		text-align: center;
		position: relative;
	}

	.nav6>ul>li>a {
		line-height: 85px;
		color: #fff;
		display: block;
		font-size: 22px;
		font-weight: 600;
		position: relative;
	}

	.nav6>ul>li:hover>a {
		color: #fae385;
	}

	.nav6>ul>li:hover>a::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 3px;
		background: #fae385;
	}

	.nav6>ul>li.active>a {
		color: #fae385;
	}

	.nav6>ul>li.active>a::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 3px;
		background: #fae385;
	}

	.nav6>ul>li>span {
		display: none;
		position: absolute;
		right: 0;
		top: 20px;
		cursor: pointer;
	}

	.nav6>ul>li>ul {
		width: 100%;
		position: absolute;
		top: 100%;
		background: #fff;
		box-shadow: 0 0 10px #ccc;
		z-index: 9;
		display: none;
	}

	.nav6>ul>li>ul li a {
		display: block;
		padding: 15px 10px;
		color: #333;
	}

	.nav6>ul>li:hover ul {
		display: block;
	}

	.nav6>ul>li>ul li:hover {
		background: #b5282e;
	}

	.nav6>ul>li>ul li a:hover {
		color: #fff;
	}




	/* footer 底部 */
	#footer {
		height: auto !important;
		background: #b5282e;
		text-align: center;
	}

	#footer .fp-tableCell {
		height: auto !important;
	}

	.footer {
		padding: 20px 20px;
	}

	#footer .footer p {
		font-size: 14px;
		line-height: 24px;
		color: #fff;
		margin: 3px 0;
	}

	#footer .footer p a {
		color: #fff;
	}

	#footer .footer p span {
		margin-left: 15px;
	}



	/* 分页 */
	.pagination {
		text-align: center;
		font-size: 0;
		padding: 60px 0;
	}

	.pagination a {
		display: inline-block;
		border: 1px solid #dcdcdc;
		padding: 0 12px;
		height: 35px;
		font-size: 14px;
		line-height: 35px;
		text-align: center;
		color: #666;
		margin: 0 7px;
	}

	.pagination a:hover {
		background: #ba2d35;
		color: #fff;
		border: 1px solid #ba2d35;
	}

	.pagination select {
		height: 37px;
		padding: 0 5px;
		outline: none;
		border-color: #eee;
	}






	.wwss li {
		display: inline-block;
		width: 48%
	}

	.wwss li:nth-child(odd) {
		margin-right: 2%
	}

	@media screen and (max-width: 1440px) {
		.nav6>ul>li>a {
			font-size: 18px;
		}
	}

	@media screen and (max-width: 1024px) {
		.pc {
			display: none;
		}

		.wap {
			display: block;
		}

		.wap-menu img {
			height: 25px;
		}

		.nav6 {
			position: fixed;
			z-index: 9999;
			padding-top: 50px;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.8);
			display: none;
			opacity: 0;
			height: 100%;
		}

		.nav6>ul {
			background: none;
			padding: 0 30px;
			width: auto;
			top: 0;
		}

		.nav6>ul>li {
			float: none;
			width: auto;
			display: block;
			text-align: left;
			padding: 15px 0;
			margin: 0;
			height: auto;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}

		.nav6>ul>li>a {
			font-size: 16px;
			line-height: normal;
			font-weight: normal;
			padding: 0 10px;
		}

		.nav6>ul>li:hover>a {
			color: #fff;
		}

		.nav6>ul>li:hover>a::after {
			content: none;
		}

		.nav6>ul>li.active>a::after {
			content: none;
		}

		.nav6>ul>li:hover ul {
			display: none;
		}

		.nav6>ul>li>span {
			display: block;
			color: #fff;
			transition: all 0.3s;
		}

		.nav6>ul>li>span.active {
			transform: rotateZ(90deg);
		}

		.nav6>ul>li>ul {
			position: static;
			padding: 0;
			width: auto;
			background: none;
			padding-left: 30px;
			box-shadow: none;
		}

		.nav6>ul>li>ul>li {
			display: block;
			text-align: left;
			padding: 15px 0;
			margin: 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}

		.nav6>ul>li>ul>li:last-child {
			border: none;
		}

		.nav6>ul>li>ul>li a {
			color: #fff;
			padding: 0;
		}

		.nav6>ul>li>ul>li:hover {
			background: none;
		}

		.nav6>ul>li>ul>li a {
			color: #fff;
			padding: 0;
		}

	}

	@media screen and (max-width:768px) {
		.pagination {
			padding: 30px 0;
		}

		.pagination a {
			padding: 0 8px;
			height: 30px;
			font-size: 12px;
			line-height: 30px;
			margin: 0px 5px;
		}

		.pagination select {
			height: 32px;
			font-size: 12px;
		}

		.wap-menu img {
			height: 25px;
		}

		.wwss li {
			display: inline-block;
			width: 100%
		}

		.wwss li:nth-child(odd) {
			margin-right: 0%
		}
	}

	@media screen and (max-width:480px) {
		#footer .footer p {
			font-size: 12px;
		}

		#footer .footer p span {
			display: block;
			margin: 0;
		}
	}

	.swiper-container {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1
	}

	.swiper-container-no-flexbox .swiper-slide {
		float: left
	}

	.swiper-container-vertical>.swiper-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-transition-property: -webkit-transform;
		transition-property: -webkit-transform;
		-o-transition-property: transform;
		transition-property: transform;
		transition-property: transform, -webkit-transform;
		-webkit-box-sizing: content-box;
		box-sizing: content-box
	}

	.swiper-container-android .swiper-slide,
	.swiper-wrapper {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}

	.swiper-container-multirow>.swiper-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}

	.swiper-container-free-mode>.swiper-wrapper {
		-webkit-transition-timing-function: ease-out;
		-o-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
		margin: 0 auto
	}

	.swiper-slide {
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 100%;
		height: 100%;
		position: relative;
		-webkit-transition-property: -webkit-transform;
		transition-property: -webkit-transform;
		-o-transition-property: transform;
		transition-property: transform;
		transition-property: transform, -webkit-transform
	}

	.swiper-slide-invisible-blank {
		visibility: hidden
	}

	.swiper-container-autoheight,
	.swiper-container-autoheight .swiper-slide {
		height: auto
	}

	.swiper-container-autoheight .swiper-wrapper {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-transition-property: height, -webkit-transform;
		transition-property: height, -webkit-transform;
		-o-transition-property: transform, height;
		transition-property: transform, height;
		transition-property: transform, height, -webkit-transform
	}

	.swiper-container-3d {
		-webkit-perspective: 1200px;
		perspective: 1200px
	}

	.swiper-container-3d .swiper-cube-shadow,
	.swiper-container-3d .swiper-slide,
	.swiper-container-3d .swiper-slide-shadow-bottom,
	.swiper-container-3d .swiper-slide-shadow-left,
	.swiper-container-3d .swiper-slide-shadow-right,
	.swiper-container-3d .swiper-slide-shadow-top,
	.swiper-container-3d .swiper-wrapper {
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d
	}

	.swiper-container-3d .swiper-slide-shadow-bottom,
	.swiper-container-3d .swiper-slide-shadow-left,
	.swiper-container-3d .swiper-slide-shadow-right,
	.swiper-container-3d .swiper-slide-shadow-top {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10
	}

	.swiper-container-3d .swiper-slide-shadow-left {
		background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
		background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: -o-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
	}

	.swiper-container-3d .swiper-slide-shadow-right {
		background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
		background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
	}

	.swiper-container-3d .swiper-slide-shadow-top {
		background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
		background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
	}

	.swiper-container-3d .swiper-slide-shadow-bottom {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
		background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: -o-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
	}

	.swiper-container-wp8-horizontal,
	.swiper-container-wp8-horizontal>.swiper-wrapper {
		-ms-touch-action: pan-y;
		touch-action: pan-y
	}

	.swiper-container-wp8-vertical,
	.swiper-container-wp8-vertical>.swiper-wrapper {
		-ms-touch-action: pan-x;
		touch-action: pan-x
	}

	.swiper-button-next,
	.swiper-button-prev {
		position: absolute;
		top: 50%;
		width: 27px;
		height: 44px;
		margin-top: -22px;
		z-index: 10;
		cursor: pointer;
		background-size: 27px 44px;
		background-position: center;
		background-repeat: no-repeat
	}

	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		opacity: .35;
		cursor: auto;
		pointer-events: none
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
		left: 10px;
		right: auto
	}

	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
		right: 10px;
		left: auto
	}

	.swiper-button-prev.swiper-button-white,
	.swiper-container-rtl .swiper-button-next.swiper-button-white {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
	}

	.swiper-button-next.swiper-button-white,
	.swiper-container-rtl .swiper-button-prev.swiper-button-white {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
	}

	.swiper-button-prev.swiper-button-black,
	.swiper-container-rtl .swiper-button-next.swiper-button-black {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
	}

	.swiper-button-next.swiper-button-black,
	.swiper-container-rtl .swiper-button-prev.swiper-button-black {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
	}

	.swiper-button-lock {
		display: none
	}

	.swiper-pagination {
		position: absolute;
		text-align: center;
		-webkit-transition: .3s opacity;
		-o-transition: .3s opacity;
		transition: .3s opacity;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		z-index: 10
	}

	.swiper-pagination.swiper-pagination-hidden {
		opacity: 0
	}

	.swiper-container-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 10px;
		left: 0;
		width: 100%
	}

	.swiper-pagination-bullets-dynamic {
		overflow: hidden;
		font-size: 0
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		-webkit-transform: scale(.33);
		-ms-transform: scale(.33);
		transform: scale(.33);
		position: relative
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1)
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1)
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
		-webkit-transform: scale(.66);
		-ms-transform: scale(.66);
		transform: scale(.66)
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
		-webkit-transform: scale(.33);
		-ms-transform: scale(.33);
		transform: scale(.33)
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
		-webkit-transform: scale(.66);
		-ms-transform: scale(.66);
		transform: scale(.66)
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
		-webkit-transform: scale(.33);
		-ms-transform: scale(.33);
		transform: scale(.33)
	}

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 100%;
		background: #000;
		opacity: .2
	}

	button.swiper-pagination-bullet {
		border: none;
		margin: 0;
		padding: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none
	}

	.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
		background: #007aff
	}

	.swiper-container-vertical>.swiper-pagination-bullets {
		right: 10px;
		top: 50%;
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0)
	}

	.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 6px 0;
		display: block
	}

	.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 8px
	}

	.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		display: inline-block;
		-webkit-transition: .2s top, .2s -webkit-transform;
		transition: .2s top, .2s -webkit-transform;
		-o-transition: .2s transform, .2s top;
		transition: .2s transform, .2s top;
		transition: .2s transform, .2s top, .2s -webkit-transform
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 4px
	}

	.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		white-space: nowrap
	}

	.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		-webkit-transition: .2s left, .2s -webkit-transform;
		transition: .2s left, .2s -webkit-transform;
		-o-transition: .2s transform, .2s left;
		transition: .2s transform, .2s left;
		transition: .2s transform, .2s left, .2s -webkit-transform
	}

	.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		-webkit-transition: .2s right, .2s -webkit-transform;
		transition: .2s right, .2s -webkit-transform;
		-o-transition: .2s transform, .2s right;
		transition: .2s transform, .2s right;
		transition: .2s transform, .2s right, .2s -webkit-transform
	}

	.swiper-pagination-progressbar {
		background: rgba(0, 0, 0, .25);
		position: absolute
	}

	.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
		background: #007aff;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: left top;
		-ms-transform-origin: left top;
		transform-origin: left top
	}

	.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
		-webkit-transform-origin: right top;
		-ms-transform-origin: right top;
		transform-origin: right top
	}

	.swiper-container-horizontal>.swiper-pagination-progressbar,
	.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
		width: 100%;
		height: 4px;
		left: 0;
		top: 0
	}

	.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
	.swiper-container-vertical>.swiper-pagination-progressbar {
		width: 4px;
		height: 100%;
		left: 0;
		top: 0
	}

	.swiper-pagination-white .swiper-pagination-bullet-active {
		background: #fff
	}

	.swiper-pagination-progressbar.swiper-pagination-white {
		background: rgba(255, 255, 255, .25)
	}

	.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
		background: #fff
	}

	.swiper-pagination-black .swiper-pagination-bullet-active {
		background: #000
	}

	.swiper-pagination-progressbar.swiper-pagination-black {
		background: rgba(0, 0, 0, .25)
	}

	.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
		background: #000
	}

	.swiper-pagination-lock {
		display: none
	}

	.swiper-scrollbar {
		border-radius: 10px;
		position: relative;
		-ms-touch-action: none;
		background: rgba(0, 0, 0, .1)
	}

	.swiper-container-horizontal>.swiper-scrollbar {
		position: absolute;
		left: 1%;
		bottom: 3px;
		z-index: 50;
		height: 5px;
		width: 98%
	}

	.swiper-container-vertical>.swiper-scrollbar {
		position: absolute;
		right: 3px;
		top: 1%;
		z-index: 50;
		width: 5px;
		height: 98%
	}

	.swiper-scrollbar-drag {
		height: 100%;
		width: 100%;
		position: relative;
		background: rgba(0, 0, 0, .5);
		border-radius: 10px;
		left: 0;
		top: 0
	}

	.swiper-scrollbar-cursor-drag {
		cursor: move
	}

	.swiper-scrollbar-lock {
		display: none
	}

	.swiper-zoom-container {
		width: 100%;
		height: 100%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center
	}

	.swiper-zoom-container>canvas,
	.swiper-zoom-container>img,
	.swiper-zoom-container>svg {
		max-width: 100%;
		max-height: 100%;
		-o-object-fit: contain;
		object-fit: contain
	}

	.swiper-slide-zoomed {
		cursor: move
	}

	.swiper-lazy-preloader {
		width: 42px;
		height: 42px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -21px;
		margin-top: -21px;
		z-index: 10;
		-webkit-transform-origin: 50%;
		-ms-transform-origin: 50%;
		transform-origin: 50%;
		-webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
		animation: swiper-preloader-spin 1s steps(12, end) infinite
	}

	.swiper-lazy-preloader:after {
		display: block;
		content: '';
		width: 100%;
		height: 100%;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
		background-position: 50%;
		background-size: 100%;
		background-repeat: no-repeat
	}

	.swiper-lazy-preloader-white:after {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")
	}

	@-webkit-keyframes swiper-preloader-spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg)
		}
	}

	@keyframes swiper-preloader-spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg)
		}
	}

	.swiper-container .swiper-notification {
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;
		opacity: 0;
		z-index: -1000
	}

	.swiper-container-fade.swiper-container-free-mode .swiper-slide {
		-webkit-transition-timing-function: ease-out;
		-o-transition-timing-function: ease-out;
		transition-timing-function: ease-out
	}

	.swiper-container-fade .swiper-slide {
		pointer-events: none;
		-webkit-transition-property: opacity;
		-o-transition-property: opacity;
		transition-property: opacity
	}

	.swiper-container-fade .swiper-slide .swiper-slide {
		pointer-events: none
	}

	.swiper-container-fade .swiper-slide-active,
	.swiper-container-fade .swiper-slide-active .swiper-slide-active {
		pointer-events: auto
	}

	.swiper-container-cube {
		overflow: visible
	}

	.swiper-container-cube .swiper-slide {
		pointer-events: none;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		z-index: 1;
		visibility: hidden;
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0;
		width: 100%;
		height: 100%
	}

	.swiper-container-cube .swiper-slide .swiper-slide {
		pointer-events: none
	}

	.swiper-container-cube.swiper-container-rtl .swiper-slide {
		-webkit-transform-origin: 100% 0;
		-ms-transform-origin: 100% 0;
		transform-origin: 100% 0
	}

	.swiper-container-cube .swiper-slide-active,
	.swiper-container-cube .swiper-slide-active .swiper-slide-active {
		pointer-events: auto
	}

	.swiper-container-cube .swiper-slide-active,
	.swiper-container-cube .swiper-slide-next,
	.swiper-container-cube .swiper-slide-next+.swiper-slide,
	.swiper-container-cube .swiper-slide-prev {
		pointer-events: auto;
		visibility: visible
	}

	.swiper-container-cube .swiper-slide-shadow-bottom,
	.swiper-container-cube .swiper-slide-shadow-left,
	.swiper-container-cube .swiper-slide-shadow-right,
	.swiper-container-cube .swiper-slide-shadow-top {
		z-index: 0;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden
	}

	.swiper-container-cube .swiper-cube-shadow {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .6;
		-webkit-filter: blur(50px);
		filter: blur(50px);
		z-index: 0
	}

	.swiper-container-flip {
		overflow: visible
	}

	.swiper-container-flip .swiper-slide {
		pointer-events: none;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		z-index: 1
	}

	.swiper-container-flip .swiper-slide .swiper-slide {
		pointer-events: none
	}

	.swiper-container-flip .swiper-slide-active,
	.swiper-container-flip .swiper-slide-active .swiper-slide-active {
		pointer-events: auto
	}

	.swiper-container-flip .swiper-slide-shadow-bottom,
	.swiper-container-flip .swiper-slide-shadow-left,
	.swiper-container-flip .swiper-slide-shadow-right,
	.swiper-container-flip .swiper-slide-shadow-top {
		z-index: 0;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden
	}

	.swiper-container-coverflow .swiper-wrapper {
		-ms-perspective: 1200px
	}

	.section {
		margin-top: 40px;
	}




	.right_lm {
		margin-top: 20px;
	}

	.w {
		width: 960px;
		margin: 0 auto;
	}

	.right_lm h1 {
		float: left;
	}

	.right_lm .loc {
		float: right;
	}

	.right_lm .loc a {
		color: #333;
	}



	.ddhleft {
		width: 500px;
		height: 396px;
		float: left;
		position: relative;
		border-radius: 10px;
		overflow: hidden;
	}

	.ddh-rig {
		float: right;
	}

	.ddhleft img {
		width: 100%;
		height: 100%;
		transform: all 0.1s;
		-webkit-transition: all 1s;
		-moz-transition: all 1s;
		animation-iteration-count: infinite;
		transform: scale(1);

	}

	.ddhleft:hover img {
		transform: scale(1.5);
	}


	.fg-tit-bg {
		position: absolute;
		bottom: 0px;
		/* background: url(../images/tit-bg.png) bottom repeat-x; */
		width: 100%;
		height: 88px;
		text-align: center;
	}

	.fg-tit-bg p {
		font-size: 16px;
		color: #fff;
		margin-top: 50px;
	}

	.ddh-rig {
		float: right;
		width: 640px;
		height: 206px;
	}

	.ddh-rig li {
		height: 190px;
		position: relative;
		margin-bottom: 15px;
		width: 300px;
		float: left;
		margin-left: 20px;
		border-radius: 10px;
		overflow: hidden;
	}

	.ddh-rig li img {
		width: 100%;
		height: 190px;

		transform: all 0.1s;
		-webkit-transition: all 1s;
		-moz-transition: all 1s;
		animation-iteration-count: infinite;
		transform: scale(1);


	}

	.ddh-rig li:hover img {
		transform: scale(1.5);
	}









	/* 首页 ----- 热点新闻 */
	.hot-new {
		padding: 0 0 40px 0;
		background: #fff;
		overflow: hidden;
		width: 100%;
	}

	.new-switch {
		float: left;
		width: 49.6%;
	}

	.new-switch .slideBox {
		position: relative;
		overflow: hidden;
	}

	.new-switch .slideBox ul li a img {
		display: block;
		width: 100%;
		transition: all 0.3s;
		height: 380px;
	}

	.new-switch .slideBox ul li:hover a img {
		transform: scale(1.1);
	}

	.new-switch .slideBox ul li:hover a p {
		color: #b5282e;
	}

	.new-switch .slideBox ul li a {
		display: block;
		position: relative;
	}

	.new-switch .slideBox ul li a p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		line-height: 45px;
		background: rgba(59, 61, 65, 0.6);
		padding-left: 20px;
		padding-right: 140px;
		color: #fff;
		font-size: 16px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.new-switch .slideBox .hd {
		position: absolute;
		bottom: 15px;
		right: 14px;
	}

	.new-switch .slideBox .hd ul {
		text-align: center;
		font-size: 0;
	}

	.new-switch .slideBox .hd ul li {
		display: inline-block;
		width: 12px;
		height: 12px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		border-radius: 50%;
		background: #999;
		margin: 0 4px;
		cursor: pointer;
	}

	.new-switch .slideBox .hd ul li.on {
		background: #fff;
	}


	.ggtit-h2 {
		line-height: 50px;
		background: #eee7e2;
		background-size: auto 100%;
		padding-right: 10px;
		color: #fff;
		overflow: hidden;
		font-size: 0;
	}

	.ggtit-h2 span {
		display: inline-block;
		color: #fff;
		font-size: 24px;
		vertical-align: middle;
		padding: 0 20px 0 15px;
		/* background:  url(../images/title-bj1.png) no-repeat left center; */
		background-size: 100% 100%;
	}

	.ggtit-h2 a {
		float: right;
		color: #000;
		font-size: 16px;
	}

	.ggtit-h2 a:hover {
		color: #b5282e;
	}

	.hot-new .new-list ul {
		padding: 15px;
	}

	.hot-new .new-list ul li a {
		line-height: 45px;
	}

	.new-list {
		width: 48.7%;
		float: right;
	}

	.new-list ul {
		padding: 15px;
	}

	.new-list ul li {
		position: relative;
		left: 0;
		transition: all 0.3s;
	}

	.new-list ul li:nth-child(1) {
		border-top: none;
	}

	.new-list ul li a {
		font-size: 16px;
		color: #000;
		line-height: 50px;
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-left: 25px;
	}

	.new-list ul li::before {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #a8a9ad;
		top: 50%;
		transform: translateY(-50%);
		left: 5px;
	}

	.new-list ul li:hover {
		left: 10px;
	}

	.new-list ul li:hover a {
		color: #b5282e;
		font-weight: 600;
	}

	.new-down li {
		width: 31.5%;
		margin: 0 10px;
	}

	.new-down li a {
		display: block;
		overflow: hidden;
	}

	.new-down li img {
		display: block;
		width: 100%;
		transition: all 0.3s;
	}

	.new-down li img:hover {
		transform: scale(1.1);
	}

	.new-down .swiper-container img {
		display: block;
		width: 100%;
	}


	.section2 {
		padding: 30px 0 70px 0;
		background: url(https://news.bupt.edu.cn/dj/images/s2-bj.jpg) no-repeat center center;
		background-size: 100% 100%;
	}

	.s2-top {
		overflow: hidden;
	}

	.s2-top>div {
		width: 48%;
		position: relative;
		margin-top: 40px;
		background: #fff;
	}

	.s2-top>div>h2 {
		background: #eee7e2;
	}

	.s2-top>div>h2>span {
		/* background: url(../images/title-bj2.png) no-repeat left center; */
		background-size: 100% 100%;
	}

	.s2-top>div ul {
		padding: 10px 15px 20px 15px;
	}

	.s2-top>div ul li {
		border-bottom: 1px dashed #eadcce;
	}

	.s2-left {
		float: left;
	}

	.s2-right {
		float: right;
	}

	.dsll>h2 {
		line-height: 60px;
		overflow: hidden;
		padding: 40px 0;

	}

	.dsll>h2>img {
		vertical-align: middle;
		max-height: 60px;
		margin-right: 10px;
		/*margin-top: -10px;*/
	}

	.dsll>h2>span {
		font-size: 30px;
		color: #000;
	}

	.dsll>h2>a {
		float: right;
		color: #000;
		font-size: 16px;
	}

	.dsll>h2>a:hover {
		color: #b5282e;
	}

	.dsll>ul {
		background: #fff;
		padding: 20px 15px;
		overflow: hidden;
	}

	.dsll>ul li {
		float: left;
		width: 29%;
		margin: 20px 2%;
		padding: 15px;
		transition: all 0.3s;
	}

	.dsll>ul li:hover {
		box-shadow: 0 0 10px rgba(203, 28, 29, 0.5);
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-o-border-radius: 5px;
		-ms-border-radius: 5px;
		border-radius: 5px;
	}

	.dsll>ul li a {
		display: block;
	}

	.dsll h2 a {
		float: right;
		color: #000;
		font-size: 16px;
	}


	.dsll>ul li a h3 {
		padding-right: 50px;
		font-size: 16px;
		line-height: 26px;
		font-weight: normal;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: relative;
		color: #000;
		font-weight: bold;
	}

	.dsll>ul li a h3 span {
		position: absolute;
		right: 0;
		top: 0;
		height: 24px;
		line-height: 24px;
		border: 1px solid #cb1c1d;
		color: #cb1c1d;
		font-size: 14px;
		padding: 0 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-o-border-radius: 5px;
		-ms-border-radius: 5px;
		border-radius: 5px;
	}

	.dsll>ul li a p {
		font-size: 14px;
		margin: 10px 0 15px 0;
		color: #555;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.dsll>ul li a>span {
		display: block;
		width: 60px;
		height: 30px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-o-border-radius: 5px;
		-ms-border-radius: 5px;
		border-radius: 5px;
		text-align: center;
		line-height: 30px;
		font-size: 14px;
		background: #f4f5f7;
		color: #555;
	}

	.dsll>ul li a>span:hover {
		background: #b5282e;
		color: #fff;
	}

	.section3 {
		background: url(https://news.bupt.edu.cn/dj/images/s3-bj.jpg) no-repeat left center;
		background-size: 100% 100%;
		margin-top: 0;
		padding-bottom: 80px;
	}

	.section3 h2 span {
		color: #fff;
	}

	.section3 h2>a {
		color: #fff;
	}

	.section3 h2>a:hover {
		color: #fff;
	}

	.section4 {
		margin-top: 0;
	}

	.section4 .dsll>h2 {
		padding: 30px 0 20px 0;
		border-bottom: 2px solid #cac0bf;
		color: #b5282e;
	}

	.section4 .dsll>h2 span {
		color: #b5282e;
	}

	.s4-main {
		overflow: hidden;
	}

	.s4-main>div {
		width: 48%;
		position: relative;
		margin-top: 40px;
		background: #fff;
	}

	.s4-left {
		float: left;
	}

	.s4-right {
		float: right;
	}

	.s4-lbody {
		padding: 20px 20px 0 20px;
		background: #f5f3f3;
	}

	.s4-lbody>div {
		overflow: hidden;
		float: none;
		width: auto;
	}

	.s4-lbody>div>div {
		width: 48%;
		position: relative;
		background: #fff;
	}

	.s4-lbody>div>div a img {
		display: block;
		width: 100%;
		position: relative;
		top: 0;
		transition: all 0.3s;
	}

	.s4-lbody>div>div a img:hover {
		top: -5px;
		transform: scale(1.1);
	}

	.s4-limg1 {
		float: left;
	}

	.s4-limg2 {
		float: right;
	}

	.s4-left {
		width: auto;
	}

	.s4-left>.s4-left ul {
		padding: 15px 0;
	}

	.s4-left ul li::before {
		background: #b5282e;
	}

	.s4-left ul li a {
		line-height: 40px;
	}

	.s4-right>ul {
		padding: 10px 20px;
		background: #f5f3f3;
		overflow: hidden;
	}

	.s4-right>ul>li {
		float: left;
		width: 30%;
		margin: 10px 1.65%;
		transition: all 0.3s;
	}

	.s4-right>ul>li:hover {
		top: -5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
	}

	.s4-right>ul>li:hover img {
		transform: scale(1.1);
	}

	.s4-right>ul>li a {
		display: block;
		width: 100%;
		overflow: hidden;
		position: relative;
	}

	.s4-right>ul>li a img {
		transition: all 0.3s;
		display: block;
		width: 100%;
		height: 180px;
	}

	.s4-right>ul>li a p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 10px;
		line-height: 35px;
		font-size: 14px;
		background: rgba(0, 0, 0, .4);
		color: #fff;
		text-align: center;
	}

	.section5 h2 {
		background: none;
		margin-top: 0;
	}

	.section5 h2 span {
		background: none;
		color: #b5282e;
	}

	.s5-main {
		margin-top: 30px;
	}

	.s5-main ul li {
		width: 23.5%;
		float: left;
		margin: 0 10px;
		transition: all 0.3s;
		position: relative;
		top: 0;
	}

	.s5-main ul li:hover {
		top: -5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
	}

	.s5-main ul li img {
		display: block;
		width: 100%;
		height: 200px;
	}

	.s5-main ul li a p {
		line-height: 40px;
		font-size: 16px;
		color: #0d0d0d;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: #e5e1e0;
		text-align: center;
		padding: 0 10px;
	}

	.s5-main .swiper-container img {
		display: block;
		width: 100%;
	}

	.s5-main .swiper-container p {
		line-height: 30px;
		font-size: 12px;
		color: #0d0d0d;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: #e5e1e0;
		text-align: center;
		padding: 0 10px;
	}

	.section6 {
		background: url(https://news.bupt.edu.cn/dj/images/s6-bj.jpg) no-repeat center center;
		background-size: 1920px 100%;
		padding: 30px 0;
	}

	.s6-child .dsll h2 {
		text-align: center;
	}

	.s6-child .dsll h2 span {
		display: block;
		color: #b5282e;
	}

	.s6-child .dsll h2 {
		padding: 0;
	}

	.s6-main {
		margin-top: 40px;
	}

	.s6-child .picMarquee-left {
		width: 1200px !important;
		overflow: hidden !important;
	}

	.s6-child .picMarquee-left .bd ul {
		overflow: hidden !important;
		zoom: 1;
	}

	.s6-child .picMarquee-left .bd ul li {
		width: 180px !important;
		border: 10px solid #dadede;
		padding: 15px 14px;
		margin: 0 10px;
		transition: all 0.5s;
	}

	.s6-child .picMarquee-left .bd ul li:hover {
		border: 10px solid #b5282e;
	}

	.s6-child .picMarquee-left .bd ul li a {
		overflow: hidden;
		display: block;
		width: 100%;
	}

	.s6-child .picMarquee-left .bd ul li img {
		display: block;
		width: 100%;
		transition: all 0.3s;
		height: 182px !important;
	}

	.s6-child .picMarquee-left .bd ul li:hover img {
		opacity: 0.8;
	}

	.s6-child .picMarquee-left .bd ul li:hover a p {
		color: #b5282e;
	}

	.s6-child .picMarquee-left .bd ul li a p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #333;
		font-size: 14px;
		margin-top: 5px;
	}

	.s6-child .picMarquee-left .hd {
		text-align: center;
		font-size: 0;
	}

	.s6-child .picMarquee-left .hd a img {
		height: 30px;
		margin: 20px 15px;
		opacity: 0.5;
		cursor: pointer;
		transition: all 0.3s;
	}

	.s6-child .picMarquee-left .hd a img:hover {
		opacity: 1;
	}




	/* 图片列表页 样式 */
	.img-lists ul {
		overflow: hidden;
		width: 100%;
	}

	.img-lists ul li {
		float: left;
		width: 23%;
		margin: 45px 0.9% 0 0.9%;
		border: 1px solid #eee;
		transition: all 0.3s;
		position: relative;
		top: 0;
	}

	.img-lists ul li:hover {
		box-shadow: 0 0 10px rgba(51, 51, 51, 0.16);
		top: -10px;
	}

	.img-lists ul li a {
		display: block;
		width: 100%;
	}

	.img-lists ul li a>img {
		display: block;
		width: 100%;
		height: 156px;
	}

	.img-lists ul li a>div {
		padding: 10px;
		font-size: 0;
	}

	.img-lists ul li a>div p {
		color: #333;
		font-size: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: 5px;
	}

	.img-lists ul li a>div img {
		height: 14px;
		vertical-align: middle;
	}

	.img-lists ul li a>div span {
		font-size: 14px;
		display: inline-block;
		margin-left: 5px;
		color: #999;
		vertical-align: middle;
	}

	/* 文字列表页 样式 */

	.text-lists {
		max-width: 1080px;
		margin: 0 auto;
		padding: 30px 60px 0 60px;
	}

	.text-lists .text-ul li {
		line-height: 60px;
		border-top: 1px solid #e5e5e5;
		text-align: left;
		padding-right: 100px;
		position: relative;
		color: #999999;
		position: relative;
		transition: all 0.3s;
		top: 0;
	}

	.text-lists .text-ul li:nth-child(1) {
		border-top: none;
	}

	.text-lists .text-ul li:hover {
		top: -5px;
	}

	.text-lists .text-ul li a {
		padding-left: 20px;
		display: block;
		width: 90%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 16px;
		color: #333;
	}

	.text-lists .text-ul li a::before {
		content: "";
		position: absolute;
		border-width: 6px;
		border-style: solid;
		border-color: transparent transparent transparent #bfbfbf;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	}

	.text-lists .text-ul li span {
		position: absolute;
		width: 100px;
		right: 0;
		font-size: 16px;
		text-align: right;
		top: 0;
	}

	.text-lists .text-ul li span::after {
		content: "";
		position: absolute;
		width: 2px;
		height: 17px;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background: #999999;
	}

	.text-lists .text-ul li:hover {
		color: #bb1b1b;
	}

	.text-lists .text-ul li:hover a {
		color: #bb1b1b;
	}

	.text-lists .text-ul li:hover a::before {
		border-color: transparent transparent transparent #bb1b1b;
	}

	.text-lists .text-ul li:hover span::after {
		background: #bb1b1b;
	}



	/* 内容页 样式 */
	/* 内容页 样式 */
	.art-content {
		padding: 30px 60px 0 60px;
	}

	.cont-tit {
		padding: 30px 0 20px 0;
		border-bottom: 1px solid #e5e5e5;
		text-align: center;
	}

	.cont-tit h3 {
		color: #333;
		font-size: 24px;
	}

	.cont-tit p {
		font-size: 0;
		margin-top: 25px;
	}

	.cont-tit p img {
		display: inline-block;
		vertical-align: middle;
		height: 16px;
		margin-right: 5px;
	}

	.cont-tit p .liulan {
		margin-left: 50px;
	}

	.cont-tit p span {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		color: #999;
	}

	.cont-body {
		padding: 0 0 20px 0;
	}

	.cont-body p {
		text-indent: 2em;
		font-size: 16px;
		line-height: 36px;
		color: #666;
		margin: 20px 0;
	}

	.cont-body img {
		display: block;
		max-width: 80%;
		margin: 0 auto;
	}

	@media screen and (max-width: 1200px) {
		.s5-main ul li {
			width: 24%;
			margin: 0 5px;
		}
	}

	@media screen and (max-width: 1024px) {
		.ggtit-h2 {
			line-height: 45px;
		}

		.ggtit-h2 span {
			font-size: 20px;
		}

		.ggtit-h22 {
			line-height: 65px;
		}

		.ddhleft {
			width: 100%;
			margin-bottom: 20px;
		}

		.ddh-rig {
			height: auto;
		}



















		.new-switch .slideBox ul li a p {
			font-size: 14px;
		}

		.new-list ul li a {
			line-height: 40px;
			font-size: 14px;
		}

		.hot-new .new-list ul li a {
			line-height: 35px;
			font-size: 14px;
		}

		.dsll>ul li {
			width: 33%;
			margin: 10px 0;
			padding: 10px;
		}

		.dsll>h2 {
			line-height: 45px;
		}

		.dsll>h2 span {
			font-size: 24px;
		}

		.dsll>h2>img {
			height: 45px;
		}

		.dsll>h2>a {
			font-size: 14px;
		}

		.s4-left ul li a {
			line-height: 32px;
		}

		.img-lists ul li {
			width: 30%;
			margin: 35px 1.5% 0 1.5%;
		}

		.img-lists ul li a>div p {
			font-size: 14px;
		}

		.img-lists ul li a>div img {
			height: 12px;
		}

		.img-lists ul li a>div span {
			font-size: 12px;
		}


		.cont-tit {
			padding: 0 0 15px 0;
		}

		.cont-tit h3 {
			font-size: 20px;
		}


	}

	@media screen and (max-width: 768px) {
		.section {
			padding-left: 15px;
			padding-right: 15px;
		}

		.section2 {
			padding-bottom: 40px;
		}

		.new-switch {
			float: none;
			width: 100%;
		}

		.new-list {
			float: none;
			width: 100%;
			margin-top: 20px;
		}

		.new-switch .slideBox ul li a p {
			font-size: 12px;
			padding-right: 100px;
		}

		.new-switch .slideBox .hd {
			bottom: 18px;
			right: 10px;
		}

		.new-switch .slideBox .hd ul li {
			width: 8px;
			height: 8px;
			margin: 0 3px;
		}

		.ggtit-h2 span {
			font-size: 16px;
		}

		.s2-top>div {
			float: none;
			width: auto;
		}

		.s2-top>div.s2-left {
			margin-top: 20px;
		}

		.dsll>ul li {
			width: 45%;
			margin: 5px 2.5%;
			box-shadow: 0 0 5px rgba(203, 28, 29, 0.2);
		}

		.dsll>ul li a h3 {
			font-size: 14px;
		}

		.dsll>ul li a h3 span {
			font-size: 12px;
		}

		.dsll>ul li a p {
			font-size: 12px;
		}

		.dsll>ul li a>span {
			font-size: 12px;
		}

		.dsll>h2 {
			line-height: 35px;
			padding: 20px 0;
		}

		.dsll>h2>span {
			font-size: 20px;
		}

		.dsll>h2>img {
			height: 35px;
		}

		.section3 {
			padding-bottom: 40px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.s4-main>div {
			float: none;
			width: auto;
		}

		.section4 .dsll>h2 {
			padding: 20px 0 20px 0;
		}

		.s4-lbody {
			padding: 15px 15px 0 15px;
		}

		.s4-lbody>div {
			margin-top: 0;
		}

		.s4-lbody>div ul {
			padding: 15px 0;
		}

		.s4-right>ul {
			padding: 15px;
		}

		.s5-main {
			margin-top: 20px;
		}

		.section5 h2 {
			line-height: 20px;
		}

		.s6-main {
			margin-top: 20px;
		}

		.img-lists ul li {
			width: 48%;
			box-sizing: border-box;
			margin: 20px 1% 0 1%;
		}

		.img-lists ul li a>div p {
			font-size: 12px;
		}


		.text-lists {
			padding: 30px 30px 0 30px;
		}

		.text-lists .text-ul li a {
			font-size: 14px;
		}

		.text-lists .text-ul li span {
			font-size: 14px;
		}


		.art-content {
			padding: 30px 30px 0 30px;
		}

		.cont-tit {
			padding: 0 0 10px 0;
		}

		.cont-tit h3 {
			font-size: 18px;
		}

		.cont-tit p img {
			height: 14px;
		}

		.cont-tit p span {
			font-size: 14px;
		}

		.cont-body p {
			font-size: 14px;
		}



	}

	@media screen and (max-width: 480px) {
		.ddhleft {
			width: 100%;
			height: auto;
		}

		.ddh-rig {
			width: 100%;
		}

		.ddh-rig li {
			margin-bottom: 15px;
			margin-left: 0;
			width: 100%;
		}









		.hot-new {
			padding: 0;
		}

		.ggtit-h2 {
			line-height: 35px;
		}

		.ggtit-h2 span {
			font-size: 14px;
		}

		.ggtit-h2 a {
			font-size: 12px;
		}

		.dsll>h2 {
			line-height: 30px;
			padding: 15px 0;
		}

		.dsll>h2>span {
			font-size: 16px;
		}

		.dsll>h2>img {
			height: 30px;
		}

		.dsll>h2>a {
			font-size: 12px;
		}

		.dsll>ul {
			padding: 15px 5px;
		}

		.section3 {
			padding-bottom: 20px;
		}

		.s4-right>ul>li {
			width: 46%;
			margin-left: 2%;
			margin-right: 2%;
		}

		.text-lists {
			padding: 15px 15px 0 15px;
		}

		.text-lists .text-ul li a {
			font-size: 12px;
		}

		.text-lists .text-ul li span {
			width: 80px;
			font-size: 12px;
		}

		.text-lists .text-ul li span::after {
			height: 12px;
		}


		.art-content {
			padding: 30px 15px 0 15px;
		}

		.cont-tit {
			padding: 0 0 10px 0;
		}

		.cont-tit h3 {
			font-size: 14px;
		}

		.cont-tit p img {
			height: 12px;
		}

		.cont-tit p span {
			font-size: 12px;
		}

		.cont-body p {
			font-size: 12px;
			line-height: 30px;
			margin: 10px 0;
		}

		.cont-tit p .liulan {
			margin-left: 20px;
		}

	}
</style>
