<template>
  <div>
    <!-- 登录 -->
    <el-dialog 
      v-loading = 'loading'
      class="login_dialog"
      :visible="login_visible"
      @update:visible="loginFromVisible"
      @closed="loginFromVisible"
      :show-close="false"
      :style="$hasGray?'filter: grayscale(1);':''"
    >
    <!-- logo -->
    <!-- <div class="ycjy_logo">
      <img class="logo" src="../../assets/image/yc_logo.svg" alt="">
    </div> -->
    <!-- 账号登录 / 验证码登录 text -->
    <div class="login_text">
      <div class="login_fanhui">
        <div class="login_fanhui_img" :class="{ no_fanhui: login_mode != 2}">
          <img v-if="login_mode === 2 || retrieve_password || this.login_mode === 3" @click="fanhui"  class="fanhui_img" src="../../assets/image/login_img/fan.png" alt="">
        </div>
        <div style="font-size: 23px;" v-if="!retrieve_password && this.login_mode != 3">{{ this.login_mode === 1 ? '账号登录' : '验证码登录' }}</div>
        <div style="font-size: 23px;" v-else>{{ retrieve_password ? '找回密码' : '微信登录' }}</div>
      </div>
      <!-- <div>
        <img src="../../assets/image/login_img/tuoyuan.png" alt="">
      </div> -->
    </div>
    <!-- 账号登录表单 -->
			<el-form v-if="login_mode === 1 && !retrieve_password" ref="login_fromRef" :model="login_from" :rules="login_from_rules">
				<el-form-item prop="account">
					<el-input v-model="login_from.account" placeholder="请输入您的账号/ID/手机号"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input 
            class="password"
            placeholder="请输入您的密码"
            :type="password_type"
            v-model="login_from.password"
          >
          <template #suffix v-if="password_type === 'password'">
              <img @click="KaiGuanXiaoYanJing" style="vertical-align:middle" src="../../assets/image/login_img/bukejian.png">
          </template>
          <template #suffix v-else>
              <img @click="KaiGuanXiaoYanJing" style="vertical-align:middle" src="../../assets/image/眼睛.png">
          </template>
          </el-input>
				</el-form-item>  
			</el-form>

      <!-- 找回密码 form -->
      <el-form v-if="retrieve_password" ref="retrieve_password_formRef">
				<el-form-item>
					<el-input v-model="retrieve_password_form.tel" placeholder="请输入您的手机号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input 
            placeholder="请输入验证码"
            v-model="retrieve_password_form.code"
          >
          <template #suffix>
              <!-- <button id="yzmRef" :disabled="disabled" style="color:#007DFC" @click="sendcode">{{ btntxt }}</button> -->
              <button type="button" id="yzmRef" v-show="code_show" style="color:#007DFC" @click="sendcode(retrieve_password_form.tel, 2)">{{ code_text }}</button>
              <button type="button" id="yzmRef" v-show="!code_show" style="color:#007DFC" >{{count}} s后重发</button>
          </template>
          </el-input>
				</el-form-item>  
        <el-form-item>
					<el-input v-model="retrieve_password_form.password" placeholder="请输入密码"></el-input>
				</el-form-item>
        <el-form-item>
					<el-input v-model="retrieve_password_form.again_password" placeholder="请再次输入您密码"></el-input>
				</el-form-item>
			</el-form>
      <div v-if="retrieve_password" class="retrieve_password_btn_box">
        <button class="retrieve_password_btn" :class="{ isretrieve_password_btn: is_retrieve_password }" @click="retrieve_password_btn">确定</button>
      </div>
    <!-- 验证码表单 -->
      <el-form v-if="login_mode === 2 && !retrieve_password" ref="code_fromRef" :model="code_form" :rules="code_form_rules">
				<el-form-item prop="tel">
					<el-input v-model="code_form.mobile" placeholder="请输入您的手机号"></el-input>
				</el-form-item>
				<el-form-item prop="code">
					<el-input 
            class="password"
            placeholder="请输入验证码"
            v-model="code_form.code"
          >
         <template #suffix>
            <!-- <span style="color:#007DFC">获取验证码</span> -->
            <button type="button" id="yzmRef" v-show="code_show" style="color:#007DFC" @click="sendcode(code_form.mobile, 1)">{{ code_text }}</button>
            <button type="button" id="yzmRef" v-show="!code_show" style="color:#007DFC" >{{count}} s后重发</button>
          </template>
          </el-input>
				</el-form-item>  
			</el-form>


      <div class="form_bottom_box">
        <!-- 记住密码 / 找回密码 -->
        <div class="JiZhu" v-if="login_mode === 1 && !retrieve_password">
          <el-checkbox class="fu-xuan-kuang" v-model="JiZhuMiMa"
            >记住密码</el-checkbox
          >
          <!-- <span class="zhao-hui-mi-ma" style="cursor: pointer" @click="zhaohuimima">找回密码</span> -->
        </div>

        <!-- 登录按钮 / 协议 -->
        <div v-if="!retrieve_password && login_mode != 3">
          <button class="login_btn" v-if="!is_login">快速登录</button>
          <button class="login_btn islogin_btn" v-else @click="login_btn">快速登录</button>
          <div class="xieyi">
            <el-checkbox class="fu-xuan-kuang" v-model="TongYiXieYi">
            </el-checkbox>
            同意并遵守
            <span class="xieyi_text" @click="login_xieyi(1)">《用户服务协议》</span>
            及
            <span class="xieyi_text" @click="login_xieyi(2)">《隐私协议》</span>
          </div>
        </div>

        <!-- 微信 / 验证码 / 注册 -->

        <div class="cut_login_mode" v-if="login_mode === 1 && !retrieve_password">
          <!-- <div class="cut_login_mode_flex" @click="vx_login">
            <div><img src="../../assets/image/login_img/vx.png" alt=""></div>
            <div class="cut_login_mode_text">微信登录</div>
          </div> -->

          <!-- <div class="cut_login_mode_flex" @click="code_mode_btn">
            <div><img src="../../assets/image/login_img/sj.png" alt=""></div>
            <div class="cut_login_mode_text">验证码登录</div>
          </div> -->

          <!-- <div @click="enroll_btn" class="cut_login_mode_flex">
            <div><img src="../../assets/image/login_img/zhuce.png" alt=""></div>
            <div class="cut_login_mode_text">立即注册</div>
          </div> -->
        </div>
      </div>
      <!-- 微信登录 -->
      <div class="vx_login_box" v-show="login_mode === 3">
        <div v-if="login_mode === 3" id="login_container" style="width: 100%; height: 100%; text-align: center;">
        </div>
      </div>

      <div v-show="login_mode === 3" >
        <div class="xieyi vx_xieyi" v-if="login_mode === 3">
        <el-checkbox class="fu-xuan-kuang" v-model="TongYiXieYi">
        </el-checkbox>
        同意并遵守
        <span class="xieyi_text" @click="login_xieyi(1)">《用户服务协议》</span>
        及
        <span class="xieyi_text" @click="login_xieyi(2)">《隐私协议》</span>
      </div>
      </div>

		</el-dialog>

    <!-- 注册 -->
    <el-dialog 
      v-loading = 'loading'
      class="enroll_dialog"
      :visible="enroll_visible" 
      @update:visible="enrollFromVisible"
      @closed="enrollFromVisible"
      :show-close="false"
    >
      <div class="enroll_img_box">
        <img src="../../assets/image/login_img/zhuce_img.png" alt="" :style="$hasGray?'filter: grayscale(1);':''">
        <div class="enroll_img_text">
          <span>免费领取</span><br />
          <span :style="$hasGray?'filter: grayscale(1);':''" style="color: #1C56E8;">0基础30节</span><span>直播课</span>
        </div>

        <!-- 注册表单 -->
        <div class="enroll_form" v-if="!unregistered">
          <el-form ref="enroll_formRef" :model="enroll_form" :rules="enroll_form_rules">
          <el-form-item prop="kecheng">
            <el-select 
              :popper-append-to-body="false"
              v-model="enroll_form.kecheng" 
              placeholder="请您选择课程"
              popper-class="select-popper"
             >
              <el-option 
                :label="item.name" 
                :value="item.id"
                v-for="(item,index) in course_list"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item  prop="tel">
            <el-input v-model="enroll_form.tel" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
         </el-form>
        </div>

        <!-- 领取注册按钮 -->
        <div class="zhuce_btn" @click="zhuce_btn_click" v-if="!unregistered">
          <div class="zhuce_btn_img" :style="$hasGray?'filter: grayscale(1);':''">
            <img src="../../assets/image/login_img/zhuce_btn.png" alt="">
            <span class="lingke_btn">立即领课</span>
          </div>
        </div>

        <!-- 发送注册验证码 -->
        <div class="zhuce_code_text" v-if="unregistered">
          <span>点击获取验证码发送至</span><br />
          <span>{{ enroll_form.tel.substr(0,3)+"****"+enroll_form.tel.substr(7) }}</span>
        </div>

        <div class="zhuce_code_form" v-if="unregistered">
          <el-form>
            <el-form-item>
            <el-input 
              class="zhuce_code_input"
              v-model="enroll_form.code"
              placeholder="请输入验证码"
            >
            <template #suffix>
                <!-- <span style="color:#007DFC">获取验证码</span> -->
                <!-- <button id="yzmRef" :disabled="disabled" style="color:#007DFC" @click="sendcode(enroll_form.tel)">{{ btntxt }}</button> -->
                <button type="button" id="yzmRef" v-show="code_show" style="color:#007DFC" @click="sendcode(enroll_form.tel, 1)">{{ code_text }}</button>
                <button type="button" id="yzmRef" v-show="!code_show" style="color:#007DFC" >{{count}} s后重发</button>
              </template>
              </el-input>
            </el-form-item>  
          </el-form>
        </div>

        <!-- 确认提交 -->
        <div class="zhuce_btn" @click="submit_mobile" v-if="unregistered">
          <div class="queren_btn_img">
            <img style="width: 100%;" src="../../assets/image/login_img/queren.png" alt="">
            <span class="lingke_btn">确认提交</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
export default {
  props: {
    login_visible:{
      type: Boolean,
      default: false
    },
    enroll_visible:{
      type: Boolean,
      default: false
    }  // 注册dialog 
  },
  data () {
    return {
      loading: false,
      password_type: 'password',
      // 登录表单
      login_from: {
        account: "",
        password: "",
      },
      // 账号表单验证
      login_from_rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ],
      },

      // 手机验证码登录表单
      code_form:{
        mobile: '',
        code: ''
      },
      code_form_rules: {
        tel: [{ required: true, message: "请输入账号", trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      // 记住密码
      JiZhuMiMa: false,

      // 找回密码
      retrieve_password: false,
      // 找回密码表单
      retrieve_password_form: {
        tel: '',
        code: '',
        password: '',
        again_password: ''
      },
      // 同意协议
      TongYiXieYi: false,

      // backUrl:this.$route.query.backurl,//登陆成功后跳转地址
      // backMode:this.$route.query.backmode,//登陆成功后跳转模式 [1:location.href, 0:router.push(默认模式)]

      login_mode: 1, // 登录方式   1 账号密码 2 验证码 3 微信

      disabled: false,
      btntxt: '获取验证码',

      // 注册表单
      enroll_form: {
        tel: '',
        kecheng: '',
        code: ''
      },
      
      course_list: [], // 注册选取课程
      unregistered: false,  // true表示没有注册过，false表示已经注册了
      enroll_form_rules: {
        kecheng: [{ required: true, message: "请选择课程", trigger: "change" }],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
      },

      // 定时发送验证码
      code_show:true,
      count:'',
      timer:null,
      code_text: '获取验证码',
      union_id: ''
    }
  },
  methods: {
    // 取消dialog
    loginFromVisible(val) {
      Bus.$emit('colseLogin', val)
      this.$emit('update:visible', val)
      if(this.retrieve_password) {
        // this.$refs.retrieve_password_formRef.resetFields()
        this.retrieve_password_form = {
          tel: '',
          code: '',
          password: '',
          again_password: ''
        }
        this.login_mode = 1
        this.retrieve_password = !this.retrieve_password
        return
      }
      if (this.login_mode === 1) {
        this.$refs.login_fromRef.resetFields()
      }
      if (this.login_mode === 2) {
        this.$refs.code_fromRef.resetFields()
      }
      this.clear_timer()
      this.login_mode = 1
    },

    // 密码 type 切换
    KaiGuanXiaoYanJing() {
      if (this.password_type === 'password') {
        this.password_type = "text";
      } else if (this.password_type === 'text') {
        this.password_type = "password";
      }
    },

    // 找回密码
    zhaohuimima() {
      this.retrieve_password = true
    },
    // 确定重置密码
    retrieve_password_btn() {
      if(this.retrieve_password_form.tel == '') {
          this.$notify.error({
            title: '错误',
            message: `请输入手机号`
          });
          return;
      }
      if(this.retrieve_password_form.code == '') {
          this.$notify.error({
            title: '错误',
            message: `请输入验证码`
          });
          return;
      }
      if(this.retrieve_password_form.password == '') {
          this.$notify.error({
            title: '错误',
            message: `请输入密码`
          });
          return;
      }
      if(this.retrieve_password_form.again_password == '') {
          this.$notify.error({
            title: '错误',
            message: `请输入确认密码`
          });
          return;
      }
      if(this.retrieve_password_form.password !== this.retrieve_password_form.again_password) {
        this.$notify.error({
            title: '错误',
            message: `输入的两次密码不一致`
          });
          return;
      }
      this.loading = true
      this.$axios({
        method: 'POST',
        url: "api/officialWebsite/auth/reset_password",
        data: {
          tel: this.retrieve_password_form.tel,
          code: this.retrieve_password_form.code,
          password: this.retrieve_password_form.password,
        }
      }).then((res) => {
        console.log("重置密码",res)
        if(res.code == 200) {
          if(res.data){
            this.$notify({
              title: '成功',
              message: `修改成功`,
              type: 'success'
            })
            this.login_mode = 1
            this.retrieve_password = !this.retrieve_password
          }else{
            this.$notify({
              title: '失败',
              message: `修改失败`,
              type: 'error'
            })
          }
        }
        if(res.code == 500) {
           this.$notify.error({
            title: '错误',
            message: `${res.msg}`
          })
        }
      this.loading = false
      })
    },
    //注册协议和隐私政策的点击事件
    login_xieyi(type) {
      if(type == 1) {
        // window.location.href = 'https://yuanchengedu.net/static/index/fwxy.html'
        window.open('https://yuanchengedu.net/static/index/fwxy.html', '_blank');
      } else {
        // window.location.href = 'https://yuanchengedu.net/static/index/yszc.html'
        window.open('https://yuanchengedu.net/static/index/yszc.html', '_blank');
      }
    },
    // 微信二维码
    // login(type) {
    //   let _this = this;
    //   setTimeout((res) => {
    //     if(type == 2) {
    //       var obj = new WxLogin({
    //         self_redirect:false,
    //         id:"login_container",
    //         appid: "wxfb97eb437aa4e005",
    //         scope: "snsapi_login",
    //         redirect_uri: encodeURIComponent(window.origin+'/new/#/index'),
    //         state: _this.state ? _this.state : '',
    //         style: "black",
    //         href: ""
    //       });
    //     }
    //   },100)
    // },
    // 登录按钮
    login_btn() {
      const self = this;
       console.log('请阅读并勾选协议 xinLogin')

      //判断复选框是否被勾选 勾选则调用配置cookie方法
      if(!this.TongYiXieYi) {
        this.$notify.error({
          title: '错误',
          message: '请阅读并勾选协议'
        });
        return;
      }
      // if(this.union_id !== null) {
      // //  data.union_id = this.union_id
      // }
      if (this.login_mode === 1) {
        this.account_mode()  // 账号密码登录
      }
      if (this.login_mode === 2) {
        this.code_mode()    // 验证码登录
      }
    },

    //登陆成功后的回调方法
    loginCallback(token){
      let _this = this;
      console.log(token + 'token');
      if(token) {
        localStorage.setItem("token",token);
      }
      _this.$store.dispatch("pullUserInfoByToken").then(function (res) {
        if(res.code == 200){
          _this.$emit('setUserInfo', res.data)
          if (_this.$route.query.backmode === '1' && _this.$route.query.isKeCheng) {
             _this.$router.push(_this.$route.query.backurl)
          }else if (_this.$route.query.backmode === '1' &&  _this.$route.query.isLive){
			   _this.$router.push(_this.$route.query.backurl)
            // location.href = _this.$route.query.backurl 
          }
          else {
            _this.$router.push('/')
          }
          // location.reload()
          _this.$notify({
            title: '成功',
            message: '登录成功',
            type: 'success'
          });
        }else{
          localStorage.removeItem("token");
          _this.$message.error(res.msg);
        }
      })
    },

    // 账号密码登录
     account_mode() {
      let _this = this
      _this.$refs.login_fromRef.validate((valid) => {
          if (valid) {
            _this.loading = true
            let data = {
              tel: '',
              pwd: ''
            }
            if(_this.$route.query.tel && _this.$route.query.pwd) {
              data.tel = _this.$route.query.tel
              data.pwd = _this.$route.query.pwd
            } else {
              data.tel = _this.login_from.account
              data.pwd = _this.login_from.password
            }
            _this.$axios({
              method: "post",
              url: "api/officialWebsite/computer/yuan_cheng/login",
              data: {
                account: data.tel,
                password: data.pwd
              },
            }).then((res) => {
              if(res.code == 200) {
                  localStorage.setItem("unionid", JSON.stringify(res.data.unionid));
                  this.loginFromVisible(false)

                // _this.$axios({
                //   method: "post",
                //   url: process.env.VUE_APP_BASE_URL_YUANCHENG + "index/login/login",
                //   data: {
                //     account: data.tel,
                //     password: data.pwd
                //   },
                // }).then(res => {
                //   console.log(res);
                // })

                if(res.data.union_id !== null) {
                  this.loginCallback(res.data.token)
                } else {
                  self.state = res.data.student_id
                  //更新绑定unionid不会返回token 这里需要单独进行记录
                  localStorage.setItem("token",res.data.token);
                  // this.login(2)
                }
              } else {
                _this.$notify.error({
                  title: '错误',
                  message: `${res.msg}`
                });
              }
                _this.loading = false
            })
          } else {
              _this.$notify.error({
                title: '错误',
                message: `必填项不能为空`
              });
            return false;
          }
        });
      
     },

     // 验证码登录
     code_mode() {
      this.loading = true
      this.$axios({
        method: 'POSt',
        url: 'api/officialWebsite/mobile/auth/verification_code_login',
        data: {
          tel: this.code_form.mobile,
          code: this.code_form.code
        }
      }).then(res => {
        if (res.code === 200) {
          this.loginFromVisible(false)
          this.loginCallback(res.data.token)
        }else {
          this.$notify.error({
            title: '错误',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
      // api/officialWebsite/mobile/auth/verification_code_login 短信验证码登录接口
     },

     // 切换到验证码登录
     code_mode_btn() {
      this.login_mode = 2
      this.$refs.login_fromRef.resetFields()
     },
     // 返回
     fanhui() {
      this.login_mode = 1
      this.clear_timer()
      this.retrieve_password = false

      this.code_form = {
        mobile: '',
        code: ''
      }

      this.retrieve_password_form = {
        tel: '',
        code: '',
        password: '',
        again_password: ''
      }
     },

     // 发送验证码  
    sendcode(mobile, type) {
      let _this = this
      if (!mobile) {
        _this.$notify.error({
          title: '错误',
          message: `手机号不能为空`
        });
        return
      }
      _this.$nextTick(() => {
				_this.$axios({
          method:'POST',
          url:'api/officialWebsite/send_code',
          data:{
            tel: mobile,
            type: type
          }
        }).then(res => {
          if (res.code === 200) {
            _this.code_timer();
          } else {
            _this.$notify.error({
              title: '错误',
              message: `${res.msg}`
            });
          }
        })
			})
    },
   // 验证码倒计时
    code_timer() {
      // if (this.time > 0) {
      //   this.disabled = true;
      //   this.time--;
      //   this.btntxt = this.time + "秒";
      // setTimeout(this.timer, 1000);
      // } else {
      //   this.time = 0;
      //   this.btntxt = "重新获取";
      //   this.disabled = false;
      // }
      if (!this.timer) {
            const TIME_COUNT = 60;
            this.count = TIME_COUNT;
            this.code_show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.code_show = true;
                this.code_text = '重新获取'
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
   },
   clear_timer() {
      clearInterval(this.timer)
      this.timer = null
      this.code_show = true
      this.code_text = '获取验证码'
   },
   // 注册
   enroll_btn() {
    this.loginFromVisible(false)
    this.$emit('update:enrollFromVisible', true)
    this.getCourseList()
   },
   // 取消注册dialog
    enrollFromVisible(val) {
      this.$emit('update:enrollFromVisible', val)
      this.unregistered = false
      this.clear_timer()
      // this.enroll_form = {
      //   tel: '',
      //   kecheng: ''
      // }
      // this.$refs.enroll_formRef.resetFields()
    },

   // 获取注册课程
   getCourseList() {
    this.loading = true
    this.$axios({
      method: 'POST',
      url: 'api/officialWebsite/course_selection_package_list'
    }).then(res => {
      if (res.code === 200) {
        this.course_list = res.data
      }
    })
    this.loading = false
   },

   // 注册 / 领取课程
   zhuce_btn_click() {
    let _this = this
    if (!_this.enroll_form.kecheng) {
      _this.$notify.error({
        title: '错误',
        message: `请选择课程`
       });
       return
    }
    // 判断手机号是否注册过
    this.loading = true
    _this.$axios({
      method: 'POST',
      url: 'api/officialWebsite/mobile/auth/phone_is_use',
      data: {
        phone: _this.enroll_form.tel
      }
    }).then(res => {
      if (res.code === 200 && res.data) {
        // 未注册    发送验证码
        this.$nextTick(() => {
          this.unregistered = true
        })
      } else {
        // 已注册
          _this.$notify.error({
            title: '错误',
            message: `该学员已经领取过课程`
          });
      }
    this.loading = false
    })
   },

   // 确认提交手机号 注册
   submit_mobile() {
    if (!this.enroll_form.code) {
      _this.$notify.error({
        title: '错误',
        message: `请输入验证码`
      })
      return
    }
    let _this = this
    this.loading = true
    _this.$axios({
      method: 'POST',
      url: 'api/officialWebsite/submit_mobile',
      data: {
        telphone: this.enroll_form.tel,
        code: this.enroll_form.code,
        coursebase_id: this.enroll_form.kecheng
      }
    }).then(res => {
      console.log(res);
      if (res.code === 200) {
        _this.$notify({
          title: '成功',
          message: `注册成功`,
          type: 'success'
        })
      this.enrollFromVisible(false)
      this.loginFromVisible(false)
      }else {
        _this.$notify.error({
          title: '错误',
          message: `${res.msg}`
        })
      }
      this.loading = false

    })
   },


   // 切换微信登录
   vx_login() {
    this.login_mode = 3
    setTimeout((res) => {
      if(this.login_mode == 3) {
        let href = 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMjE1cHg7CiAgICBtYXJnaW4tdG9wOiAwOwp9Ci53ZWJfcXJjb2RlX3R5cGVfaWZyYW1lewogIGhlaWdodDoyNDBweAp9Cg=='
        var obj = new WxLogin({
          self_redirect:false,
          id:"login_container",
          appid: process.env.VUE_APP_XV_APPID,
          scope: "snsapi_login",
          // redirect_uri: encodeURIComponent(window.origin+'/new/#/Login?index=1&tel=' + this.Denglu.account + '&pwd=' + this.Denglu.password),
          // redirect_uri: encodeURIComponent('https://www.yuanchengedu.net/new/#/index'),
          redirect_uri: encodeURIComponent(window.origin + '/new/#/Index?tel=' + this.login_from.account + '&pwd' + this.login_from.password),
          style: "black",
          href: href
        });
      }
    },100)
   }

  },
  computed: {
    // 是否可以点击确定重置密码
    is_retrieve_password() {
      return !!this.retrieve_password_form.tel && !!this.retrieve_password_form.code && !!this.retrieve_password_form.password && !!this.retrieve_password_form.again_password
    },

    // 是否可以点击确定登录
    is_login() {
      return !!this.login_from.account && !!this.login_from.password || !!this.code_form.mobile && !!this.code_form.code
    }
  },
  watch: {
     $route(to,from) {
      if (this.$route.name == 'bindingVX') {
        return
      }
      let _this = this;
      if (_this.$route.query.code) {
        if (!_this.TongYiXieYi) {
          _this.$notify.error({
            title: '错误',
            message: '请阅读并勾选协议'
          })
          this.$emit('update:visible', true)
          this.login_mode = 1

        } else {
          this.$axios({
          method: "POST",
          url: "/api/officialWebsite/auth/authorized_wechat_college_official_website",
          data: {
            code: this.$route.query.code
          }
        }).then((res) => {
          //已绑定微信，成功登陆
          if (res.code == 200) {
            this.loginFromVisible(false)
            this.loginCallback(res.data.token);
            localStorage.setItem("unionid", JSON.stringify(res.data.unionid));
            return 
          }
          // //未绑定微信，需要登陆绑定账号
          if (res.code == 40051) {
            this.union_id = res.data.union_id
            if (this.$route.query.pwd !== '' && this.$route.query.tel !== '') {
              // this.$message.success('微信绑定成功');
              this.$notify({
                title: '成功',
                message: '微信绑定成功',
                type: 'success'
              });
              this.account_mode()
              return;
            }
            this.$emit('update:visible', true)
            this.login_mode = 1
              this.$notify({
                title: '提示',
                message: '微信未绑定，请用账号密码登录',
                type: 'warning'
              });
          }
          if (res.code == 500) {
            this.$notify.error({
              title: '提示',
              message: `${res.msg}`
            });
          }
        })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ycjy_logo{
  width: 229px;
  height: 87px;
  border-radius: 43px;
  padding: 17px;
  position: absolute;
  top: -7%;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);;
}
.logo{
  width: 100%;
  height: 100%;
}

.login_text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: 55px auto;
}
.login_fanhui{
  width: 100%;
  display: flex;
}
.login_fanhui_img{
  display: flex;
  width: 145px;
  align-items: center;
}
.no_fanhui {
  width: 155px;
}
.fanhui_img{
  vertical-align:middle;
}
.JiZhu{
  display: flex;
  justify-content: space-between;
}

.login_btn{
  width: 338px;
  height: 58px;
  margin-top: 25px;
  border-radius: 29px;
  opacity: 0.5;
  color: #FFFFFF;
  font-size: 19px;
  text-align: center;
  line-height: 58px;
  background: #7AA0FF;
}

.islogin_btn{
   background: #074DFE;
   opacity: 1;
}

.retrieve_password_btn_box{
  display: flex;
  justify-content: center;
}
.retrieve_password_btn{
  width: 338px;
  height: 58px;
  margin-top: 25px;
  border-radius: 29px;
  opacity: 0.5;
  color: #FFFFFF;
  font-size: 19px;
  text-align: center;
  line-height: 58px;
  background: linear-gradient(to right, #3F73FA, #1C55E8);
}
.isretrieve_password_btn{
  opacity: 1;
  background: linear-gradient(to right, #3F73FA, #1C55E8);
}
.xieyi{
  padding: 0 10px;
  margin-top: 15px;
  cursor: default
}
.xieyi_text {
  color: #8798CF;
}

.cut_login_mode{
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

.cut_login_mode_flex{
  width: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cut_login_mode_text{
  margin-top: 5px;
}
.form_bottom_box{
  margin-left: 48px;
  margin-right: 53px;
}

.enroll_img_box{
  position: relative;
}
.enroll_img_text{
  position: absolute;
  left: 510px;
  top: 140px;
  font-weight: 900;
  font-size: 38px;
}

.zhuce_btn{
  position: absolute;
  left: 510px;
  top:474px
}
.zhuce_btn_img{
  position: relative;
}
.queren_btn_img{
  position: relative;
  width: 286px;
  height: 73px;
}
.lingke_btn{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
  color: #FFFFFF;
  font-family: 'PingFang SC';
  font-size: 23px;
  font-weight: 900;
}
.zhuce_code_text{
  position: absolute;
  left: 560px;
  top: 299px;
  font-size: 20px;
  text-align: center;
}

.vx_login_box{
  // width: 255px;
  height: 280px;
  // // background-color: #8798CF;
  // margin: 0 auto;
}

.vx_xieyi{
  margin-left: 57px;
}
</style>

<style lang="less" scoped>
/deep/ .login_dialog .el-dialog {
  width: 481px;
  height: 620px;
  border-radius: 35px;
  position: relative;
  margin-top: 20vh !important;
  background: rgba(255,255,255,1);
}

/deep/ .login_dialog .el-input__inner{
  border: none;
  padding-left: 10px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .login_dialog .el-form{
  // padding: 0 74px;
  margin: 0 50px;
}

/deep/ .login_dialog .el-checkbox__inner{
  border-radius: 50%;
}

/deep/ .login_dialog .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  border-radius: 50%;
}

/deep/ .login_dialog .el-loading-spinner,
/deep/ .enroll_dialog  .el-loading-spinner{
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 300px;
  background-position:center; 
  top:0; 
}
/deep/ .login_dialog  .el-loading-spinner .circular,
/deep/ .enroll_dialog  .el-loading-spinner .circular{
  display: none;
}

/deep/ .enroll_dialog .el-dialog{
  background: none;
  box-shadow: none;
}
/deep/ .enroll_dialog .el-dialog .el-form .el-input__inner{
  width: 288px;
  height: 55px;
  border-radius: 11px;
  border: none;
  background: rgba(242,242,242,1);
}

/deep/ .enroll_form{
  position: absolute;
  left: 510px;
  top: 299px
}
 .el-select /deep/ .select-popper {
      z-index: 8888 !important;
    }


/deep/ .zhuce_code_form{
  position: absolute;
  left: 510px;
  top: 400px;
  font-size: 20px;
}

/deep/ .zhuce_code_form .el-input__suffix{
  right: 12px;
  top: 7px;
}

/deep/ .enroll_dialog .el-dialog .zhuce_code_form .el-form .el-input__inner{
  border-radius: 29px;
}
</style>