import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "./axios";
import request from "./axios/request"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import scroll from 'vue-seamless-scroll'
import myTabs from '../src/views/Index/center_index/components/myTabs'
import childTabs from '../src/views/Index/center_index/components/childTabs'
import myWorkAudio from '../src/views/Index/center_index/components/myWorkAudio'
import schedule from '../src/views/Index/center_index/components/schedule'
import onLive_css from '../src/views/Index/center_index/components/onLive_css'
import no_data from '../src/views/Index/center_index/components/no_data'
import login from './views/components/login'
//import Viewer
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// import VueClipboard from 'vue-clipboard2'

// Vue.use(VueClipboard)

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(Viewer,{
  defaultOptions: {
    zIndex: 10010
  }
})
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});

Vue.prototype.$axios = axios
Vue.prototype.$request = request

// 是否将官网首页置灰
Vue.prototype.$hasGray = false

// 全局方法
import common from './assets/js/common.js'
Vue.prototype.$common = common;

Vue.use(ElementUI);
Vue.use(scroll)

Vue.config.productionTip = false

// 个人中心全局组件
Vue.component('myTabs', myTabs)
Vue.component('childTabs', childTabs)
Vue.component('myWorkAudio', myWorkAudio)
Vue.component('schedule', schedule)
Vue.component('onLive_css', onLive_css)
Vue.component('no_data', no_data)

Vue.component('login', login)

let vm = new Vue({
  router,
  store,
  render: h => h(App)
})

if(common.getPathParam('token')) {
	common.login('token', common.getPathParam('token')).then(data => {
		vm.$mount('#app')
	}, () =>{
		vm.$mount('#app')
	})
} else {
	vm.$mount('#app')
	//判断当前 token 是否有效
	vm.$store.dispatch("pullUserInfoByToken")
}

if (!localStorage.getItem("token")) {
  localStorage.removeItem("token")
  localStorage.removeItem("userInfo")
}

