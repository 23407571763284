<template>
  <div class="work_box" v-loading = 'loading' v-if="workInfoData">
    <div class="work">
    <!-- 面包屑 -->
      <div class="work_breadcrumb ">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-if="!isKeCheng && !isNews" :to="{ path: '/personalCenter/myWork' }">我的作业</el-breadcrumb-item>
          <el-breadcrumb-item v-if="!isKeCheng && !isNews" :to="{ path: `/personalCenter/myWork?tabs_active=${tabs_active}&sectionIndex=${sectionIndex}&classIndex=${classIndex}&coursetitle_id=${coursetitle_id}&classmat_id=${classmat_id}&page=${page}&type=${optiontable_type}`}">章节列表</el-breadcrumb-item>
          
          <el-breadcrumb-item v-if="!!isKeCheng && !isNews" :to="{ path: '/personalCenter/myKeCheng' }">我的课程</el-breadcrumb-item>
          <el-breadcrumb-item v-if="!isKeCheng && !!isNews" :to="{ path: `/personalCenter/myNews?newTabIndex=${newTabIndex}&newType=${newType}` }">我的消息</el-breadcrumb-item>
          <el-breadcrumb-item>{{ !workInfoData.hasdone ? '提交作业' : '作业详情' }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

    <!-- 主体 -->
      <div class="work_body">

        <div class="work_body_top">
          
          <div>
            <el-carousel indicator-position="none" class="work_img" height="150px" v-if="workInfoData.anli_img&&workInfoData.anli_img.length>1">
              <el-carousel-item v-for="(item,index) in workInfoData.anli_img" :key="index">
                <img @click="getBigVideo('img',index)" style="object-fit: cover" v-if="/(http|https):\/\/([\w.]+\/?)\S*/.test(item.file) && item.file.split('.')[3] != 'mp4' && item.file.split('.')[3] != 'qt'" :src="/(http|https):\/\/([\w.]+\/?)\S*/.test(item.file) ? item.file : 'https://static.shanchuangjiaoyu.com/' +  item.file" class="auto-size">
              </el-carousel-item>
            </el-carousel>
            <div class="work_img" v-else-if="workInfoData.anli_img && workInfoData.anli_img[0]">
              <img @click="getBigVideo('img',0)" style="object-fit: cover" v-if="workInfoData.anli_img[0].file.split('.')[3] != 'mp4' && workInfoData.anli_img[0].file.split('.')[3] != 'qt'" :src="/(http|https):\/\/([\w.]+\/?)\S*/.test(workInfoData.anli_img[0].file) ? workInfoData.anli_img[0].file : 'https://static.shanchuangjiaoyu.com/' +  workInfoData.anli_img[0].file" class="auto-size">
              <div class="video_top" v-else @click="getBigVideo(workInfoData.anli_img[0].file)">
              <video style="width: 100%; height: 100%;object-fit: cover;" :src="workInfoData.anli_img[0].file"></video>
              <span class="myVideo_mask">
                <i class="el-icon-video-play reversal"></i>
              </span>
              </div>
            </div>
            <div style="color: #B2B2B2;font-size: 13px; margin-top: 15px;">作业案例图</div>
          </div>

          <!-- 作业信息 -->
          <div class="work_info">
            <div class="work_info_name">#{{ workInfoData.title }}</div>
            <div style="margin-top: 29px;">
              <div class="work_info_title">授课讲师: <span style="color: #000;">{{ workInfoData.teacher_name }}</span></div>
              <div class="work_info_request">
                <div class="work_info_title" style="width: 69px;">作业要求: </div>
                <div style="width: 660px;">
                  <div v-for="(item, index) in workRequestList" :key="index">
                    &nbsp;{{ index + 1 }}.{{ item }}
                  </div>
                </div>
              </div>
              <div class="work_info_title">
                授课时间: <span style="color: #000;">{{ workInfoData.shouke_time }}</span>
              </div>
              <div class="work_info_title" v-if="optiontable_type!='stk'">
                作业时间: <span style="color: #000;">{{ workInfoData.downtime }} - {{ workInfoData.lastfinisheddt }}</span>
              </div>
            </div>
          </div>

        </div>
        <!-- 分割线 -->
        <el-divider></el-divider>

        <div class="work_body_bottom">
          <div class="work_body_bottom_title">{{ !workInfoData.hasdone ? '上传作业' : '学员作业' }}</div>

          <!-- 作业已经提交 -->
          <div v-if="!!workInfoData.hasdone">
            <div class="submitted_work" v-for="(item, index) in workCompletion" :key="index">
            <div class="student_img"><img :src="item.student.headico" alt="" class="auto-size"></div>
            <div class="submitted_work_right">
              <div class="student_img_right_text">
                <div>{{ item.student.name }}</div>
                <div style="color: #999999;" v-if="item.student_dt">{{ item.student_dt ? $common.format(item.student_dt).m : '' }}月{{ item.student_dt ? $common.format(item.student_dt).d : '' }}日 {{ item.student_dt ?  $common.format(item.student_dt).h : '' }}:{{ item.student_dt ? $common.format(item.student_dt).mm : '' }}</div>
              </div>

              <div class="my_submitted_work">
                <div class="" v-html="item.memo?item.memo.replace(/\n/g,'<br />'):''"></div>
                <div class="my_submitted_work_img_box">
                      <div class="my_submitted_work_img" v-for="(val, ind) in item.file" :key="ind">
                      <viewer :images="item.file" style="display: flex;" v-if="/(http|https):\/\/([\w.]+\/?)\S*/.test(val.uploadfile) && val.uploadfile.split('.')[3] != 'mp4' && val.uploadfile.split('.')[3] != 'qt'">
                        <img style="object-fit: cover" :src="/(http|https):\/\/([\w.]+\/?)\S*/.test(val.uploadfile) ? val.uploadfile : 'https://static.shanchuangjiaoyu.com/' +  val.uploadfile" class="auto-size" alt="">
                      </viewer>
                      <video :ref="'video'+index" v-if="/(http|https):\/\/([\w.]+\/?)\S*/.test(val.uploadfile) && val.uploadfile.split('.')[3] == 'mp4' || val.uploadfile.split('.')[3] == 'qt'" :style="`width:100%;height:100%;object-fit:${videoObiectFit}`" :src="val.uploadfile" :controls="videoing=='video'+index"></video>
                      <span v-if="/(http|https):\/\/([\w.]+\/?)\S*/.test(val.uploadfile) && val.uploadfile.split('.')[3] == 'mp4' || val.uploadfile.split('.')[3] == 'qt'" v-show="videoing!='video'+index" class="video_mask2" @click="getVideoChange(index)">
                        <i class="el-icon-video-play reversal"></i>
                      </span>
                      </div>
                </div>
                <div class="my_submitted_work_bottom">
                  <div>
                    <div class="my_submitted_work_bottom_left">
                      <img style="vertical-align:middle;" src="../../../../assets/image/work/lianjie.png" alt="">&nbsp;
                     
                      <span style="color: #2480F8;vertical-align:middle;" v-if="item.syllabus_name">{{ item.syllabus_name }}</span>
                      <!-- <span style="color: #2480F8;"> 111</span> -->
                    </div>
                  </div>

                  <!-- 点赞/评论 -->
                  <div style="display: flex;">

                  <span class="is_submit_work_data_right_bottom" v-if="item.student_id == $store.state.userInfo.id && !item.readover">
                      <el-popover placement="bottom" trigger="hover" popper-class="myPopover">
                        <button class="myPopover_delbtn"  @click="del_work(item.id)">删除作业</button>
                        <button type="text" slot="reference">
                          <div class="delete_box"> 
                            <div class="delete"></div>
                            <div class="delete"></div>
                            <div class="delete"></div>
                          </div>
                        </button>
                      </el-popover>
                    </span>

                    <div style="margin: 0 20px;" @click="com_btn(index, item.id)">
                      <img style="vertical-align:middle;" src="../../../../assets/image/work/pinlun.png" alt="">
                      &nbsp;&nbsp;<span>{{ item.com_num }}</span>
                    </div>
                    <div @click="work_praise_btn(item.is_praise, item.id)">
                      <img 
                        style="vertical-align:middle;" 
                        :src="item.is_praise === 0 ?  require('../../../../assets/image/work/weidianzan.png') :  require('../../../../assets/image/work/dianzan.png')"
                        alt=""
                      >
                      &nbsp;&nbsp;<span :class="{ dianzan: item.is_praise != 0 }">{{ item.praise }}</span>
                    </div>
                  </div>
                </div>
                <!-- 评论 --> 
              </div>

              <!-- 评论 -->
              <div class="com_box" v-show="index == isShowComInd">
                <!-- 评论输入框 -->
                  <div class="com_input_box">
                    <div class="com_img"><img :src="$store.state.userInfo.headico" class="auto-size" alt=""></div>
                    <div class="com_input_right_text">
                      <!-- textarea cols="5" -->
                      <textarea v-model="com_textarea"  type="textarea" cols="5" class="com_input" style="resize:none"  @keydown="keydown($event, item.student.id, item.id)"></textarea> 
                      <div class="com_input_right_handle" >
                        <img class="emoj_img" @click="isShowEmoj = !isShowEmoj" style="vertical-align:middle;" src="../../../../assets/image/work/biaoqing.png" alt="">
                        <!-- <div>{{ emojiUrl, emojiMap, emojiName }}</div> -->
                        <div class="emoj" v-if="index == isShowComInd && isShowEmoj">
                          <div v-for="(emojiItem, ind1) in emojiName" :key="ind1" class="emoji" @click="emojiItemClick(emojiItem)">
                            <img :src="emojiUrl + emojiMap[emojiItem]" alt="" class="auto-size">
                          </div>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span style="vertical-align:middle;color: #1170EE; cursor:default"  @click="send_com(item.student.id, item.id)">发送</span>
                      </div>
                    </div>
                  </div>

                  <!-- 讲师评论 -->
                  <div class="com_name" style="margin-top: 20px;" v-show="item.teacher_name != '--' && item.audio || item.context">
                    <div class="com_img" style="transform: translateY(-6px);">
                      <img :src="item.teacher_headico" class="auto-size" alt="">
                    </div>
                    <div>
                      <div class="teacher_flex" style="padding-left: 22px;max-width:830px;">
                        <div>
                          <div>{{ item.teacher_name + '老师' }}</div>
                        </div>&nbsp;
                        <div style="width: 64px; height: 27px;vertical-align: middle;">
                          <img class="auto-size" src="../../../../assets/image/work/laoshi.png" alt="">
                        </div>&nbsp;:&nbsp;
                        <span @click="myWorkTeacherAudioClick(index)" v-show="item.audio">
                          <myWorkAudio style="display:inline-block;vertical-align: middle;" ref="myWorkTeacherAudioRef" :url="item.audio" :duration="Number(item.duration)"></myWorkAudio>
                        </span>
                        <span v-show="item.context" v-html="item.context?item.context.replace(/\n/g,'<br />'):''"></span>
                      </div>
                      <div style="margin-left: 22px;" class="com_time" v-if="item.readover_dt">
                        {{ $common.format($common.getTimestamp(item.readover_dt)).m }}月{{ $common.format($common.getTimestamp(item.readover_dt)).d }}日
                        {{ $common.format($common.getTimestamp(item.readover_dt)).h }}:{{ $common.format($common.getTimestamp(item.readover_dt)).mm }}
                      </div>
                    </div>
                  </div>

                  <div class="allComBox" v-for="(v,i) in comList" :key="i">
                    <div class="com_img" style="transform: translateY(-6px);"><img :src="v.usersend.headico" class="auto-size" alt=""></div>

                    <div class="allComBox_text">
                    <!-- 文字评论 -->
                      <div class="com_name" v-if="v.type === 0">
                      <div style="width: 797px;">{{ v.usersend.name }}
                        <span v-if="v.user_send_flag != 0" style="width: 64px; height: 27px"><img class="auto-size" src="../../../../assets/image/work/laoshi.png" alt=""></span>&nbsp;:&nbsp;
                         <span class="com_content" v-html="parseMessageEmoji(v.msg)"></span>
                      </div>
                        
                      </div>
                     
                      
                      <!-- 语音评论 -->
                      <div class="com_name" v-show="v.type === 1"><div>{{ v.usersend.name }}</div>
                        &nbsp;<div v-if="v.user_send_flag != 0" style="width: 64px; height: 27px"><img class="auto-size" src="../../../../assets/image/work/laoshi.png" alt=""></div>&nbsp;:
                        &nbsp;<span @click="myWorkComAudioClick(i)"><myWorkAudio  ref="myWorkComAudioRef" :url="item.mp3file" :duration="Number(item.second)"></myWorkAudio></span>
                      </div>
                      <div class="com_time" v-if="v.dt">
                        {{ $common.format($common.getTimestamp(v.dt)).m }}月{{ $common.format($common.getTimestamp(v.dt)).d }}日
                        {{ $common.format($common.getTimestamp(v.dt)).h }}:{{ $common.format($common.getTimestamp(v.dt)).mm }}
                      </div>
                    </div>
                  </div>

                  <!-- 查看更多 -->
                  <button type="button" class="view_more" @click="view_more_btn(item.com_num,item.id)">
                    <span v-show="comment_page.page < Math.ceil(item.com_num / comment_page.list_rows)">
                      查看更多 &nbsp;
                    <i class="el-icon-arrow-down" style="font-size: 18px;"></i>
                    </span>
                    <span v-show="comment_page.page >= Math.ceil(item.com_num / comment_page.list_rows)">
                      没有更多了 &nbsp;
                      <i class="el-icon-arrow-up" style="font-size: 18px;"></i>
                    </span>
                  </button>

              </div>
            </div>
            </div>
          </div>

          <!-- 作业未提交 -->
          <div v-else>
            <div class="submit_work_img">
              <el-upload
                :action="ossUrl"
                list-type="picture-card"
                :on-success="submit_work_success"
                :on-error="submit_work_error"
                :before-upload="beforeWorkUpload"
                :limit="limit"
                :on-progress="progressUpload"
                :on-exceed="masterFileMax"
                :file-list="submitWorkImgList"
                :class="showUpload?'':'hideVideo'"
              >
                  <div  slot="file" slot-scope="{file}" v-if="upload_type.indexOf('video')<0">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  <!-- <div class="success_state">
                    <i class="el-icon-upload-success el-icon-check"></i>
                  </div> -->
                </div>
                <div v-else slot="file" slot-scope="{file}">
                  <!-- <video_player :url="file.url" /> -->
                  <video style="width: 100%; height: 149px;position: relative;object-fit: cover;" :src="file.url"></video>
                  <span class="video_mask">
                    <i class="el-icon-zoom-in reversal2" @click="getBigVideo(file.url)"></i>
                    <i class="el-icon-video-play reversal"></i>
                    <i class="el-icon-delete reversal2" @click="handleRemove(file)"></i>
                  </span>
                </div>
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-progress v-if="isShowProgress&&upload_type.indexOf('video')!=-1" :percentage="progress" :format="format"></el-progress>
              <!-- <el-dialog :visible.sync="dialogVisible">
                <img style="object-fit: cover" width="100%" :src="dialogImageUrl" alt="">
              </el-dialog> -->
              <br />
              <div class="submit_work_attention">
                注: 每张作业图依照4:3比例进行显示，大小不超过5M，最多上传9张<br />
                <span style="margin-left: 26px;">视频大小不超过100M</span>
              </div>
            </div>

            <!-- 提交作业文本域 -->
            <div class="submit_work_textarea_box">
              <el-input
                class="submit_textarea"
                type="textarea"
                :rows="5"
                placeholder="作业说明..."
                v-model="submit_work_textarea"
                maxlength="200"
                :v-model="submit_work_textarea"
                show-word-limit
              >
              </el-input>
            </div>

            <!-- 立即提交 -->
            <div class="submit_work_btn_box">
              <button type="button" class="submit_work_btn" @click="submit_work_click">立即提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogVisible2" @click="dialogVisible2=false" class="mydialog" style="z-index:10010;">
      <video @click.stop="" :src="bigVideoUrl" controls></video>
    </div>
    <!-- 删除dialog -->
      <el-dialog 
        :visible.sync="del_dialog" 
        class="del_work_dialog" 
        width="361px" 
        :show-close="false" 
      >
        <div class="del_work_dialog_text">
          确定删除作业？
        </div>
        <div class="del_work_dialog_btn">
          <button class="work_dialog_btn" @click="ensure_del">确定</button>
          <button class="work_dialog_btn work_dialog_cancel_btn" @click="del_dialog = false">取消</button>
        </div>
      </el-dialog>

      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :z-index='9999'
        :url-list="[...url]"
    />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { emojiUrl, emojiMap, emojiName } from '../../../../assets/js/emojiMap'
export default {
  data () {
    return {
      isShowProgress: false,
      progress: 0,
      showViewer: false,
      url: [],
      loading: false,
      workInfoData: {},
      workRequestList: [],  // 作业要求

      workCompletion: [],    // 作业完成情况

      // praise_type: 'praise'  // 类型，praise为确认点赞，cancal 为取消点赞
      com_textarea: '',
      isShowComList: false,

      comList: [],   // 评论列表
      comment_page: {   // 获取评论列表分页参数
        page: 1,
        list_rows: 3,
        total: 0
      },
      ossUrl: process.env.VUE_APP_BASE_URL + 'api/student/upload',

      dialogImageUrl: '',
      dialogVisible: false,
      dialogVisible2:false,
      bigVideoUrl:"",

      limit: 9,   // 最大上传作业个数
      submitWorkImgList: [],   // 上传作业照片数据
      upload_type: '',
      submit_work_textarea: '',

      isShowComInd: null,
      emojiUrl: emojiUrl,
      emojiMap: emojiMap,
      emojiName: emojiName,
      isShowEmoj: false,
      emojiItemText: '',

      homework_ID: '',


      del_id: '',
      del_dialog: false,

      // 回跳到我的作业参数
      sectionIndex: '',
      classIndex: '',
      tabs_active: 0,
      coursetitle_id: '',
      classmat_id: '',
      page: '',
      optiontable_type: '',

      isKeCheng: '',
      isNews: '',
      newTabIndex: 0,
      newType: 1,
      showUpload:true,
      videoObiectFit:"cover",
      videoing:"",
      dbm_type:"",//临时加的，我不想加，但国世逼我加的
    }
  },
  methods: {
    // 获取作业详情
    getWorkInfo() {
      let _this = this
      this.loading = true
      this.$axios({
        method: 'POST',
        url: '/api/officialWebsite/mobile/user_homework/user_homework_detail',
        data: {
          syllabus_id: _this.$route.query.syllabus_id
        }
      }).then(res => {
        if (res.code === 200) {
          this.dbm_type=res.data.creat_time?"stk":"formal"
          _this.workInfoData = res.data
          if(res.data.context)_this.workRequestList = res.data.context.split('\n')
          _this.homework_ID = res.data.id
          _this.getWorkCompletion(res.data.id)
        }else {
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
    },

    // 作业完成情况
    getWorkCompletion(id) {
      let _this = this
      this.loading = true
      _this.$axios({
        method: 'POST',
        url: '/api/officialWebsite/mobile/user_homework/user_homereadover_list',
        data: {
          page: 1,
          list_rows: 10,
          syllabus_id: _this.$route.query.syllabus_id,
          homework_id: id
        }
      }).then(res => {
         if(res.code === 200) {
           _this.workCompletion = res.data.list
         }else {
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
         }
         this.loading = false
      })
    },

    // 已提交作业/评论列表
    // /api/officialWebsite/mobile/user_homework/homereadover_comment_list
    getWorkComment(homereadover_id) {
      this.loading = true
      let _this = this
      this.$axios({
        method: 'POST',
        url: '/api/officialWebsite/mobile/user_homework/homereadover_comment_list',
        data: {
          page: _this.comment_page.page,
          list_rows: _this.comment_page.list_rows,
          homereadover_id: homereadover_id
        }
      }).then(res => {
        // console.log(res);
        if(res.code === 200) {
          this.comList.push(...res.data.list)
          // this.comment_page.total = res.data.total
        }else {
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
    },

    // 学员作业点赞
    work_praise_btn(is_praise, id) {
      let _this = this
      this.loading = true
      _this.$axios({
        method: 'POST',
        url: '/api/officialWebsite/mobile/user_homework/homereadover_praise',
        data: {
          homereadover_id: id,
          type: is_praise === 0 ? 'praise' : 'cancal',
          course_type: _this.$route.query.type?_this.$route.query.type:_this.dbm_type
        }
      }).then(res => {
        if (res.code === 200) {
          _this.getWorkCompletion(_this.homework_ID)
        }else{
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
      this.loading = false
      })
    },

    // 显示评论列表
    com_btn(ind, homereadover_id) {
      this.comList = []
      if (this.isShowComInd !== ind){
        this.isShowComInd = ind
        this.getWorkComment(homereadover_id)
      }else{
        this.isShowComInd = null
        this.comList = []
        this.comment_page.page = 1
      }
      // this.isShowComList = !this.isShowComList
    },

    // 发送评论
    send_com(user_recerve, homereadover_id){
      if(!this.com_textarea)return
      let _this = this
      _this.loading = true
      _this.$axios({
        method: 'POST',
        url: '/api/officialWebsite/mobile/user_homework/homereadover_comment',
        data: {
          msg: _this.com_textarea,
          user_recerve: user_recerve,
          type: 0,
          homereadover_id: homereadover_id,
          course_type: _this.$route.query.type
        }
      }).then(res => {
        if (res.code === 200) {
          _this.$notify({
            title: '成功',
            message: '发送成功',
            type: 'success'
          });
          this.comList = []
          this.comment_page.page = 1
          _this.getWorkComment(homereadover_id)
          _this.getWorkCompletion(_this.homework_ID)
          _this.com_textarea = ''
        }else {
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
    },

    // 父  触发子语音播放，只保证当前只有一个播放
    // 讲师语音
    myWorkTeacherAudioClick(ind) {
      let _this = this
      // console.log(_this.$refs.myWorkTeacherAudioRef);
        for (let k = 0; k < _this.workCompletion.length; k++) {
          if (k != ind) {
              _this.$refs.myWorkTeacherAudioRef[k].$refs.audio.pause()
              _this.$refs.myWorkTeacherAudioRef[k]._data.isPlaying = false
          }
        }
        _this.$refs.myWorkTeacherAudioRef[ind].handlePlay()
    },

    myWorkComAudioClick(ind) {
      let _this = this
      // console.log(_this.$refs.myWorkComAudioRef);
        for (let k = 0; k < _this.comList.length; k++) {
          if (k != ind) {
              _this.$refs.myWorkComAudioRef[k].$refs.audio.pause()
              _this.$refs.myWorkComAudioRef[k]._data.isPlaying = false
          }
        }
        _this.$refs.myWorkComAudioRef[ind].handlePlay()
    },
    // 查看更多评论
    view_more_btn(com_num, homereadover_id) {
      this.comment_page.total = Math.ceil(com_num / this.comment_page.list_rows)
      // this.ismore = this.comment_page.page < this.comment_page.total
      if (this.comment_page.page < this.comment_page.total) {
        this.comment_page.page += 1
        this.getWorkComment(homereadover_id)
      } else{
        this.isShowComInd = null
        this.comList = []
        this.comment_page.page = 1
      }
    },

    // 提交作业
    // handleRemove(file, fileList) {
    //   // console.log(file, fileList);
    //   // console.log(this.submitWorkImgList);
    //   this.submitWorkImgList = fileList.map(item => {
    //     return item.response.data.url
    //   })

    // },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    format(percentage) {
      return percentage === 100 ? '上传成功' : `${percentage}%`;
    },
    // 上传进度
    progressUpload(event, file, fileList) {
      this.isShowProgress = true
      // this.progress = 0
      this.progress = Number(Math.round(event.percent))
      if (this.progress == 100) {
        setTimeout(() => {
          this.isShowProgress = false
        }, 1000)
      }
      this.format(this.progress)
    },
    // 作业文件上传成功
    submit_work_success(response, file, fileList) {
      console.log(file, fileList);
      if(this.upload_type.indexOf("video")<0){
        this.showUpload=Boolean(fileList.length<9)
      }else{
        this.showUpload=false
      }
      let url = file.response.data.url
      let uid = file.raw.uid
      // console.log(file.data.filepath.split('api/')[1]);
      this.submitWorkImgList.push({
        uid,
        url
      })
      // this.workImgToBase64(file.data.url)
      // console.log(this.submitWorkImgList);
    },
    // 作业上传失败时
    submit_work_error() {
      this.$notify({
          title: '提示',
          message: '上传作业图片失败',
          type: 'warning'
        })
    },

    // 作业大小限制
     beforeWorkUpload(file) {
      if(file.type.indexOf("image")==-1&&file.type.indexOf("video")==-1){
        this.$notify({
          title: '提示',
          message: `仅支持图片视频上传！`,
          type: 'warning'
        });
        return false
      }
      const isImgLt3M = file.size / 1024 / 1024 < 5;
      const isVideoLt100M = file.size / 1024 / 1024 < 100;

      if (!!this.submitWorkImgList.length) {
        if (this.submitWorkImgList[0].url.split('.')[3] != 'mp4' && this.submitWorkImgList[0].url.split('.')[3] != 'qt'  && file.type.indexOf("video")!=-1) {
          // this.submitWorkImgList = []
          this.$notify({
            title: '提示',
            message: `图片视频不可以同时上传`,
            type: 'warning'
          });
          return false
        }
      }

      this.upload_type = file.type
      if (file.type.indexOf("video")!=-1) {
        this.limit = 1
        if(!isVideoLt100M) {
          this.$notify({
            title: '提示',
            message: `视频上传大小不超过100M/个`,
            type: 'warning'
          });
        }
      return isVideoLt100M
      }else {
        this.limit = 9
        if (!isImgLt3M) {
          this.$notify({
            title: '提示',
            message: `图片上传大小不超过5M/张`,
            type: 'warning'
          });
        }
      return isImgLt3M
      }
    },

    // 最大上传数量限制
    masterFileMax(files, fileList) {
      // this.$message.warning(`最多上传 ${this.limit} 个文件。`);
      this.$notify({
        title: '提示',
        message: `最多上传 ${this.limit} 个文件`,
        type: 'warning'
      });
    },

    // 立即提交作业
    submit_work_click() {
      let _this = this

      if(!_this.submitWorkImgList.length) {
        _this.$notify({
          title: '提示',
          message: '作业图片/视频不能为空',
          type: 'warning'
        });
        return 
      }
      if(!_this.submit_work_textarea) {
        _this.$notify({
          title: '提示',
          message: '作业说明不能为空',
          type: 'warning'
        });
        return 
      }
      // let workImgList = _this.submitWorkImgList.map(item => item.url)
      let arr = []
      _this.submitWorkImgList.forEach((item, index) => {
        arr.push({
          file: item.url,
          type: this.upload_type.indexOf("video")!=-1 ? 2 : 1,
          sort: index + 1
        })
      })
      _this.loading = true
      _this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/submit_user_homereadover',
        data: {
          homework_id: _this.homework_ID,
          memo: _this.submit_work_textarea,
          is_official: _this.$route.query.type === 'stk' ? 0 : 1,   // 1.是 0.否(体验课)
          uploadfile: arr,
          title: _this.workInfoData.title
        }
      }).then(res => {
        if (res.code === 200) {
          _this.$notify({
            title: '成功',
            message: '上传成功',
            type: 'success'
          });
          location.reload()
          // _this.getWorkComment()

        }else {
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
    },

    // emoj表情点击
    emojiItemClick(item) {
      // console.log(item);
      this.emojiItemText = item
      this.com_textarea = this.com_textarea + item
    },

    // 表情处理
    parseMessageEmoji(message) {
      if (!message) return
      message=message.replace(/\n/g,'<br />')
      message = message.replace(/\[.*?\]/g, function(keyword) {
        if (emojiMap[keyword]) {
          return "<img style='width: 30px;max-height: 30px; vertical-align:middle' src='" + emojiUrl + emojiMap[keyword] + "' />"
        } else {
          return keyword
        }
      });
      return message;
    },

    del_work(id) {
      this.del_id = id
      this.del_dialog = true
    },

      // 确定删除作业
    ensure_del() {
      let _this = this
      _this.loading = true
      _this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/mobile/user_homework/delete_job',
        data:{
          job_id:_this.del_id,
          job_type: _this.$route.query.type === 'stk' ? 1 : 2,
        }
      }).then(res => {
        if (res.code === 200) {
          // _this.getWorkCompletion(_this.homework_ID)
          this.getWorkInfo()
        }else{
          _this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        _this.loading = false

      })
      _this.del_dialog = false
    },

    keydown(event, sID, ID) {
      if (event.keyCode == 13) {
        if (!event.shiftKey && event.keyCode == 13) {
          event.cancelBubble = true; //ie阻止冒泡行为
          event.stopPropagation();//Firefox阻止冒泡行为
          event.preventDefault(); //取消事件的默认动作*换行
          this.send_com(sID, ID)
        }
    }
  },
  // 预览
  handlePictureCardPreview(file) {
      this.url = this.submitWorkImgList.map(item => item.url)
      const index =this.url.findIndex(item => item === file.url)
      this.url.splice(index, 1)
      this.url.unshift(file.url)
      this.showViewer = true
     },

     // 关闭查看器
     closeViewer() {
      this.url = []
      this.showViewer = false
    },
    // 删除提交作业
    handleRemove(file) {
      console.log(file);
      const index =this.submitWorkImgList.findIndex(item => item.uid === file.uid)
      this.submitWorkImgList.splice(index, 1)//移除已上传的图片
      console.log(this.submitWorkImgList);
      if(this.upload_type.indexOf("video")<0){
        this.showUpload=Boolean(this.submitWorkImgList.length<9)
      }else{
        this.showUpload=true
      }
     },
    //  预览视频
     getBigVideo(url,i){
      if(url=="img"){
        let arr=[]
        this.workInfoData.anli_img.forEach((el,ii)=>{
          if(ii>=i){
            this.url.push(el.file)
          }else{
            arr.push(el.file)
          }
        })
        this.url.push(...arr)
        this.showViewer = true
        return
      }
      this.dialogVisible2=true
      this.bigVideoUrl=url
     },
    //  是否全屏
    isFullscreenChange(){
       let isFullscreen=document.fullscreen || document.mozFullScreen || document.webkitIs
       return isFullscreen==undefined? false : isFullscreen
    },
    getVideoChange(i){
      let str="video"+i
      if(this.videoing!=str){
        this.videoing=str
        this.$refs[str][0].play()
      }
    },
  },
  created () {
    this.getWorkInfo()
    this.classIndex = this.$route.query.classIndex
    this.sectionIndex = this.$route.query.sectionIndex
    this.tabs_active = this.$route.query.tabs_active
    this.coursetitle_id = this.$route.query.coursetitle_id
    this.classmat_id = this.$route.query.classmat_id
    this.page = this.$route.query.page
    this.optiontable_type = this.$route.query.type
    this.isKeCheng = Number(this.$route.query.is_kecheng)
    this.isNews = Number(this.$route.query.isNews)
    this.newTabIndex = this.$route.query.newTabIndex
    this.newType = this.$route.query.newType
  },
  mounted () {
    // 点击表情外的区域关闭表情选择
    document.addEventListener('click', (e)=> {
      // console.log(e.target.className);
        if (e.target.className != 'emoj_img') {
          if (e.target.className === 'auto-size' || e.target.className === 'emoj') {
            return
          }
            this.isShowEmoj = false;
        }
    })
    window.onresize=()=>{
      if(this.isFullscreenChange()){
        this.videoObiectFit="contain"
      }else{
        this.videoObiectFit="cover"
      }
    }
    // 跳转到work后置顶显示
    let scrollContent = document.querySelector('.index');
    if (scrollContent){
      scrollContent.scrollTop = 0;
      scrollContent.scrollLeft = 0;
    }
  },
  computed: {
    // allPage() {
    //   return this.comment_page.total % this.comment_page.list_rows === 0
    // },
    // 拿到最大条数
    // maxPage() {
    //   return Math.ceil(this.comment_page.total / this.comment_page.list_rows)
    // }
  },
  watch:{
    videoing(newval,oldval){
      console.log(newval,"新值");
      if(oldval!='')this.$refs[oldval][0].pause()
    }
  },
  components: {
    ElImageViewer
  }
}
</script>

<style lang="scss" scoped>
.work{
  width: 1045px;
  margin: 0px auto;
  // padding: 40px;
  // background-color: aqua;
}
.work_breadcrumb{
  padding: 20px 0;
}
.work_body{
  min-height: 900px;
  background: rgba(255,255,255,1);
  border-radius: 18px;
  padding: 54px 37px 54px 37px;
}

.work_box{
  background-color: #F7F7F7;
  width: 100%;
  padding-bottom: 60px;
  // height: 2300px;
}

.work_body_top{
  display: flex;
  justify-content: start;
}
.work_img{
  width: 206px;
  height: 154px;
  border-radius: 20px;
  margin-right: 27px;
  overflow: hidden;
  // background: red;
}
.work_info_name{
  font-weight: 400;
  font-size: 22px;
}

.work_info_request{
  display: flex;
}
.work_info_title{
  color: #646464;
}

.work_body_bottom_title{
  font-weight: Bold;
  height: 21px;
  margin-bottom: 50px;
  font-size: 20px;
}
.submitted_work{
  display: flex;
  margin-bottom: 25px;
}
.student_img{
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.student_img_right_text{
  height: 42px;
}

.my_submitted_work{
  width: 920px;
  min-height: 233px;
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: aqua;
}

.my_submitted_work_img{
  width: 191px;
  height: 143px;
  border-radius: 10px;
  overflow: hidden;
  margin:0 11px 11px 0;
  position: relative;
  // background-color: red
}
.video_mask2{
  width: 191px;
  height: 143px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  color: #FFF;
  font-size: 32px;
  line-height: 143px;
  text-align: center;
}
.video_mask2>i{
  font-weight: normal;
}
.my_submitted_work_bottom{
  display: flex;
  justify-content: space-between;
}

.dianzan{
  color: #1170EE;
}


.com_box{
  width: 100%;
  background-color: #F2F2F2;
  border-radius: 20px;
  margin-top: 20px;
  padding: 30px 27px;
}

.com_input_box{
  display: flex;
  justify-content: space-between;
}
.com_img{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
}
.com_input{
  width: 800px;
  height: 60px;
  border: none;
  border-radius: 7px;
  padding: 7px;
  padding-right: 140px;
  background: rgba(226,226,226,1);
}
.com_input_right_text{
  position: relative;
}

.com_input_right_handle{
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}
.allComBox{
  margin: 35px 0;
  display: flex;
}
.allComBox_text{
  margin-left: 22px;
  // height: 42px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}

.com_name{
  color: #1170EE;
  display: flex;
  align-items: start;
}

.com_content{
  max-width: 680px;
  color: #000 !important;
  // vertical-align:middle;
  // line-height: 2;
}
.com_time{
  color: #B9B9B9;
}
.my_submitted_work_bottom_left{
  min-width: 50px;
  height: 32px;
  border-radius: 16px;
  padding: 3px 22px;
  background: rgba(242,242,242,1);
}

// .com_audio{
//   display: flex;
// }

.view_more{
  width: 100%;
  display: flex;
  color: #1170EE;
  justify-content: center;
}

.submit_work_attention{
  color: #A9A9A9;
}

.submit_work_textarea_box{
  margin-top: 40px;
}

.submit_work_btn_box{
  width: 100%;
  display: flex;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
}
.submit_work_btn{
  width: 242px;
  height: 50px;
  font-size: 20px;
  color: #FFF;
  line-height: 50px;
  border-radius: 50px;
  background-image: linear-gradient(90deg, #338BFF, #074DFE);
}

.my_submitted_work_img_box{
  display: flex;
  flex-wrap: wrap;
}

.emoj{
  display: flex;
  position: absolute;
  left: -167px;
  top: 50px;
  width: 290px;
  height: 200px;
  padding: 20px;
  flex-wrap: wrap;
  overflow-y: scroll;
  background-color: #e2e2e2;
}

.emoji{
  width: 35px;
  margin: 5px;
  height: 35px;
}

.teacher_flex{
  // display: flex;
  // align-items: center;
}
.teacher_flex>div{
  display: inline-block;
  
}
.is_submit_work_data_right_bottom {
  display: block;
  height: 20px;
  line-height: 20px;
  vertical-align:middle;
  color: #9C9C9C;
}

.delete_box{
  display: flex;
  width: 32px;
  justify-content: space-around;
}

.delete {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #BEBEBE;
}
</style>


<style lang="less">

.myPopover{
  padding: 0 !important;
  height: 30px;
  border-radius: 6px;
  border: none !important;
  box-shadow: none !important; 
}
.myPopover_delbtn{
  height: 30px;
  width: 70px;
  transform: translateX(50%);
  border-radius: 6px;
  background: rgba(65,65,65,1);
  color: #FFFFFF;
}
.myPopover[x-placement^=left] .popper__arrow::after{
    
    border-left-color: rgba(65,65,65,1) !important;
  }
  .myPopover[x-placement^=right] .popper__arrow::after{
    
    border-right-color: rgba(65,65,65,1) !important;
  }
  .myPopover[x-placement^=bottom] .popper__arrow::after{
    
    border-bottom-color: rgba(65,65,65,1) !important;
  }
  .myPopover[x-placement^=top] .popper__arrow::after{
    
    border-top-color: rgba(65,65,65,1) !important;
  }
</style>

<style lang="less" scoped>

/deep/ .submit_work_img .el-upload--picture-card{
  background-color: #F0F0F0;
  border: none;
  width: 212px;
  height: 149px;
  border-radius: 20px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
/deep/ .el-upload--picture-card i{
  font-size: 50px;
  transform: translateY(30%);
}

/deep/ .el-upload--picture-card i [class*=" el-icon-"], [class^=el-icon-] {
  font-weight: 800;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 212px;
  height: 149px;
  border-radius: 20px;
}

/deep/ .submit_textarea .el-textarea__inner{
  border: none;
  background-color: #F0F0F0;
  padding: 20px;
  font-size: 20px;
  border-radius: 20px;
}

/deep/  .el-textarea .el-input__count {
  background-color: #F0F0F0;
  right: 28px;
  font-size: 20px;
}

/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}

 /deep/ .del_work_dialog .el-dialog {
    height: 252px;
    margin-top: 40vh !important;
    border-radius: 22px;
    background: rgba(255,255,255,1);
  }
 /deep/ .del_work_dialog_text  {
    font-size: 22px;
    color: #5A5A5A;
    text-align: center;
    padding: 0 0 75px 0;

 }
 .del_work_dialog_btn{
  display: flex;
  justify-content: space-between;
 }
 .work_dialog_btn{
  width: 98px;
  height: 43px;
  border-radius: 21px;
  text-align: center;
  line-height: 43px;
  font-size: 20px;
  color: #FFFFFF;
  background: rgba(0,125,252,1);
 }
 
 .work_dialog_cancel_btn{
  background: rgba(164,164,164,1);
 }
 /deep/ .el-dialog__body {
  padding: 30px 40px;
 }

 /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: cover
 }
 .el-upload-video{
    position: absolute;
    display: inline-block;
    width: 35px;
    height: 35px;
    left: 0;
    line-height: 35px;
    left: 19%;
    top: 57%;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
  }
  .el-upload-video:hover{
    background-color: rgba(0, 0, 0, .5);
  }

  
  /deep/ .el-progress-bar{
    width: 212px !important;

  }
  .success_state{
    position: absolute;
    right: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 0 1pc 1px rgb(0 0 0 / 20%);
  }
  .success_state i{
    font-size: 12px;
    margin-top: 11px;
    transform: rotate(-45deg);
  }
  .el-image-viewer__wrapper{
    z-index: 10010 !important;
  }

  .video_mask{
    width: 100%;
    height: 149px;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    line-height: 149px;
    text-align: center;
    color: #FFFFFF;
    font-size: 32px;
    font-weight: 100 !important;
  }
  .video_mask>i{
    font-weight: normal !important;
    cursor: pointer;
  }
  .reversal,.video_mask:hover .reversal2{
    opacity: 1;
  }
  .video_mask:hover .reversal,.reversal2{
    opacity: 0;
  }
  .hideVideo /deep/ .el-upload--picture-card {
    display: none;
  }
  .mydialog{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, .3);
    padding: 10vh 0;
  }
  .mydialog>video{
    max-width: 80vw;
    max-height: 80vh;
    object-fit: fit;
  }
  .video_top{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .myVideo_mask{
    width: 206px;
    height: 154px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    font-size: 36px;
    line-height: 154px;
    text-align: center;
  }
  .myVideo_mask>i{
    font-weight: normal !important;
  }
</style>