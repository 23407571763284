import { render, staticRenderFns } from "./myWork.vue?vue&type=template&id=272c0e66&scoped=true&"
import script from "./myWork.vue?vue&type=script&lang=js&"
export * from "./myWork.vue?vue&type=script&lang=js&"
import style0 from "./myWork.vue?vue&type=style&index=0&id=272c0e66&lang=scss&scoped=true&"
import style1 from "./myWork.vue?vue&type=style&index=1&id=272c0e66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272c0e66",
  null
  
)

export default component.exports