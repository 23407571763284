<template>
	<div class="index" :style="{height:(dh!='/MapContainer'?'100vh !important':'100% !important')}" @scroll="scrollToTop">
		<!-- <div style="height: 70px;" id="top">
			<Header></Header>
		</div> -->
		<div style="min-height:700px">
			<router-view />
		</div>
		<!-- <Bottom-tab :havSuspension="this.$route.path == '/Index/' || this.$route.path == '/Index'"></Bottom-tab> -->
		<Side-tab :isShow="isShow" v-if="this.$route.path == '/Index/' || this.$route.path == '/Index'"></Side-tab>
		<suspension :isShow="isShow" v-show="this.$route.path == '/Index/' || this.$route.path == '/Index'"></suspension>
	</div>
</template>

<script>
	import Header from './components/Header.vue'
	import BottomTab from './components/BottomTab.vue'
	import SideTab from './components/SideTab.vue'
	import suspension from './components/suspension.vue'
	import Bus from '../assets/js/Bus.js'
	export default {
		name: 'Index',
		data() {
			return {
				clientHeight: '',
				isShow: false,
				dh:"",
			}
		},
		mounted() {
			this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
			
		},
		watch: {
    $route (to, from ) {
      // 监听路由变化, 实现类似 小程序的 onShow 事件
        this.dh=this.$route.path
    }
  },


		methods: {
			// 滚动事件
			scrollToTop(e) {
				let scrollTop = e.srcElement.scrollTop;
				if(scrollTop >= this.clientHeight - 300) {
					//显示 回到顶部
					this.isShow = true;
				}else {
					//隐藏
					this.isShow = false;
				}
			}
		},
		created() {
			this.dh=this.$route.path;
		},
		components: {
			Header,
			BottomTab,
			SideTab,
			suspension
		}
	}
</script>

<style scoped>

</style>
