<template>
  <div>
    <div class="tabs">
      <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading = 'loading'>
      <div class="uesrinfo">
        <div class="uesrinfo_avatar myheader-user-headico">
          <img :src="uesrinfo_from.headico" alt="" class="auto-size">
        </div>
        <div class="uesrinfo_detail">
          <el-form label-width="80px">
            <el-form-item label="昵 称:">
              <el-input class="uesrinfo_input" v-model="uesrinfo_from.name"></el-input>
            </el-form-item>
            <el-form-item label="性 别:">
               <el-radio v-model="uesrinfo_from.sex" label="1">男</el-radio>
               <el-radio v-model="uesrinfo_from.sex" label="2">女</el-radio>
               <el-radio v-model="uesrinfo_from.sex" label="3">保密</el-radio>
            </el-form-item>
            <!-- 省市区 -->
            <el-form-item label="所在地:">
              <el-select
                v-model="provincial"  
                placeholder="请选择"
                @change="provincial_select"  
              >
                <el-option
                  v-for="item in provincial_list"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select  
                placeholder="请选择"
                v-model="city" 
                @change="discrete_select"
              >
                <el-option
                  v-for="item in city_list"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select 
                placeholder="请选择"
                v-model="discrete" 
              >
                <el-option
                  v-for="item in discrete_list"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="replace_avatar">
        <el-upload
          class="upload-demo"
          :action="ossUrl"
          :on-success="getImgCover"
          :show-file-list="false"
        >
          更换头像
        </el-upload>
      </div>
      <div class="isOk" @click="isOk">确认保存</div>
    </div>
  </div>
</template>

<script>

import { provincial,citys,discretes } from '../../../../assets/js/cityList'
export default {
  data() {
    return {
      loading: false,
      userInfo_area: JSON.parse(localStorage.getItem("userInfo")).area_id,
      ossUrl:'api/student/upload',
      // tabsList
      tabsList: [
        {
          tabs_title: '个人资料'
        }
      ],

      // 当前选择的tabs
      tabs_active: 0,

      // userinfo / 修改提交的表单
      uesrinfo_from:{
        name: JSON.parse(localStorage.getItem("userInfo")).name,
        sex: JSON.parse(localStorage.getItem("userInfo")).sex.toString(),
        headico: JSON.parse(localStorage.getItem("userInfo")).headico,
        area: []
      },

      // 省市区选择 /  测试
      
        provincial: JSON.parse(localStorage.getItem("userInfo")).area_id ? JSON.parse(localStorage.getItem("userInfo")).area_id.split('-')[0] : '', // 省级
        city: JSON.parse(localStorage.getItem("userInfo")).area_id ? JSON.parse(localStorage.getItem("userInfo")).area_id.split('-')[0] : '',       // 市级
        discrete: JSON.parse(localStorage.getItem("userInfo")).area_id ? JSON.parse(localStorage.getItem("userInfo")).area_id.split('-')[0] : '',   // 区级

        // 省市区数据
        provincial_list:provincial(),
        city_list:[],
        discrete_list:[]
    }
  },
  methods: {

    // tabs切换
    tabsClick(index) {
      // console.log(index);
      this.tabs_active = index
    },

    // 省级选择
    provincial_select(val){
      // 拿城市数据
      this.city_list = citys(val)
      this.city = this.city_list[0]
      this.discrete_select(this.city_list[0])
      this.discrete = this.discrete_list[0]
      // console.log(this.area);
    },

    // 市级选择
    discrete_select(val) {
      // 拿区县数据
      this.discrete_list = discretes(val)
      this.discrete = this.discrete_list[0]
    },

    // 更换头像
    getImgCover(file, fileList) {
      this.uesrinfo_from.headico = file.data.url
    },

    // 确定保存
    isOk() {
      this.loading = true
      console.log(this.uesrinfo_from);
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/save_personal_data',
        data:{
          ...this.uesrinfo_from,sex: Number(this.uesrinfo_from.sex),area: [this.provincial,this.city,this.discrete]
        }
      }).then(res => {
        if (res.code === 200) {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.$store.dispatch("pullUserInfoByToken")
          setTimeout(() => {
            location.reload()
          }, 1000);
        }else{
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
        
      })
    }
  },

  created () {
    this.provincial_select(this.provincial)
    this.discrete_select(this.city)
  },
  computed: {
    area() {
      // return  this.area_id = `${this.provincial}-${this.city}-${this.discrete}`
      return this.uesrinfo_from.area = [this.provincial,this.city,this.discrete]
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  padding: 100px 27px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.uesrinfo{
  height: 160px;
  // background-color: aqua;
  display: flex;
}

.uesrinfo_avatar{
  width: 137px;
  height: 137px;
  border-radius: 68px 68.526px 68.526px 68.526px;
  background: rgba(52,134,91,1);
}
.uesrinfo_detail{
  width: 780px;
  height: 137px;
  margin-left: 25px;
}
.replace_avatar{
  width: 137px;
  text-align: center;
}
.isOk{
  width: 150px;
  height: 41px;
  border-radius: 20px 20.8392px 20.8392px 20.8392px;
  background: rgba(0,125,252,1);
  font-size: 16px;
  text-align: center;
  line-height: 41px;
  color: #FFFFFF;
  cursor:pointer;
  margin: 130px 0 0 200px;
}
</style>

<style lang="less" scoped>

/deep/ .el-input__inner{
  width: 218px;
  height: 40px;
  border-radius: 20px;
  background: rgba(231,231,231,1);
}
/deep/ .el-form-item__label{
  padding-right: 10px;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  color: #2C2C2C;
}
/deep/ .el-input--suffix .el-input__inner{
  margin-right: 15px;
}

/deep/ .el-input--suffix span{
  right: 36px;
}

/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}
</style>