<template>
  <div>
    <div class="tabs">
      <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading = 'loading'>
      <!-- 大讲堂 -->
      <div class="big_classroom" v-if="tabs_active === 0 && myCollectList.length">
        <div v-if="!!item.coursebase" @click="formal_curriculum_click(item.coursebase.id)" class="big_classroom_box" v-for="item in myCollectList" :key="item.id">
          <div class="big_classroom_top">
            <img :src="item.coursebase.filepath" alt="" class="auto-size">
          </div>
          <div class="big_classroom_bottom">
            <p>{{ item.coursebase.name }}</p>
            <div class="big_classroom_bottom_right">大讲堂</div>
          </div>
        </div>
      </div>
      <!-- 正式课 -->
      <div class="formal_curriculum" v-if="tabs_active === 1 && myCollectList.length">
        <div v-if="!!item.coursebase" @click="formal_curriculum_click(item.coursebase.id)" class="formal_curriculum_box" v-for="item in myCollectList" :key="item.id">
          <div class="formal_curriculum_top">
            <img :src="item.coursebase.filepath" alt="" class="auto-size">
          </div>
          <div class="formal_curriculum_bottom">
            <p>{{ item.coursebase.name }}</p>
            <div class="formal_curriculum_bottom_right">{{ item.coursebase.course.name }}</div>
          </div>
        </div>
      </div>

        <no_data v-if="!myCollectList.length"></no_data>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tabsList: [
        {
          tabs_title: '大讲堂'
        },
        {
          tabs_title: '正式课'
        }
      ],
      tabs_active: 0,

      myCollectList: []
    }
  },
  methods: {
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
      this.getMyCollectData()
    },

    // 获取收藏数据
    getMyCollectData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_collect',
        data:{
          type: this.tabs_active
        }
      }).then(res => {
        this.loading = false
        if (res.code = 200) {
          console.log(res);
          this.myCollectList =  res.data
        }
      })
    },

    // 跳转我的收藏
    formal_curriculum_click(id) {
      // location.href = `/meal/${id}.html`
      this.$router.push({
        path: `/kechengDetail`,
        query: {
          id: id,
          active: this.tabs_active
        }
      })
    }
  },
  created () {
    this.getMyCollectData()
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  
}

.tabs_info {
  min-height: 572px;
  border-radius: 0px 0px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

// .colorGray {
//   background-color: #F0F0F0;
// }

// 大讲堂 / 体验课
.big_classroom,
.formal_curriculum {
  padding: 50px 22px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
}

.big_classroom_box,
.formal_curriculum_box {
  margin-right: 37px;
  margin-bottom: 20px;
  width: 276px;
}

.big_classroom_top,
.formal_curriculum_top {
  width: 276px;
  height: 214px;
  border-radius: 9px 9px 9px 9px;
  // background: rgba(238, 238, 238, 1)
  background-color: aqua;
}

.big_classroom_bottom,
.formal_curriculum_bottom {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.big_classroom_bottom_right,
.formal_curriculum_bottom_right {
  padding: 0 10px;
  border-radius: 15px;
  background-color: #F0F0F0;
  color: #6A6868;
}
.formal_curriculum_top img,
.big_classroom_top img{
  border-radius: 9px;
}
</style>


<style lang="less" scoped>
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}
</style>