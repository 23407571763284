<template>
  <div id="cav" style="background-color: #f5f5f5" v-loading="loading">
   
    <div
      style="
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <img
        style="
          width: 100%;
          height: 247px;
          padding: 0;
          margin: 0;
          object-fit: cover;
        "
        :src="background"
      />
      <div class="schoolInfo">
        <img :src="schoolInfo.school_cover" alt="" />
        <div class="schoolTitle">
          <span>{{ schoolInfo.title }}</span>
          <div class="schoolSubTitle">
            <el-button type="primary" round size="default">{{ schoolInfo.school_type }}</el-button>
            <el-button type="primary" round size="default">{{ schoolInfo.school_level }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div style="background-color: #ffffff; width: 100%; height: auto">
      <div class="schoolSynopsis">
        <div class="synopsisTitle">
          <span>学校简介</span>
          <p></p>
        </div>
        <div class="synopsis">
          <p v-html="schoolInfo.school_details"></p>
        </div>
        <div class="synopsisTitle">
          <span>学校环境</span>
          <p></p>
        </div>
        <div class="schoolImg">
          <img
            :src="schoolInfo.school_img"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // name: "details",
  data() {
    return {
      schoolInfo: [],
      background:'https://devstatic.yc-edu.online/api/upload/crm/2022/03/16/ZauzzRdvRDTCh2YoX9JKQKrx6hkUxNhSSq9GXAYE.jpg',
      loading:false
    };
  },

  created() {
    // this.topping();
    // console.log(this.$route.query.college_id, 7777);
    this.schoolId = this.$route.query.college_id;
    this.getSchoolDetails();

  },
  mounted() {
    // document.querySelector("#top").scrollIntoView(true);
    //发送axios  get请求

  },
  methods: {
    getSchoolDetails(){
      this.loading=true;
      setTimeout(() => {
        this.$axios({
      method: "POST",
      url: "/api/officialWebsite/campus_cooperation/college_detail",
      data: { college_id: this.schoolId },
    })
      .then((res) => {
        this.schoolInfo = res.data;
        this.loading=false
            document.querySelector("#top").scrollIntoView(true);
        // console.log(this.schoolInfo, 123);
      })
      .catch((error) => {
        console.log(error);
      });
      }, 500);
    
    },
  },
};
</script>
<style>
</style>
<style scoped>

.schoolInfo {
  width: 1200px;
  display: flex;
  flex-flow: row;
  align-items: center;
  position: absolute;
  z-index: 10;
}
.schoolInfo img {
  width: 137px;
  height: 137px;
  border-radius: 50%;
  object-fit: cover;
}
.schoolTitle {
  display: flex;
  flex-flow: column;
  margin-left: 30px;
  /* color: #ffffff; */
  color: #071785;
  
}
.schoolTitle span {
  font-size: 45px;
  font-family:Microsoft YaHei;
  font-weight: 800;
}
.schoolSubTitle {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 4px;
}
.schoolSubTitle>>>.el-button--primary{
 background-color: #074DFE;
 border-color: #074DFE;
 font-size: 18px;
}
.schoolSubTitle>>>.el-button.is-round{
 padding: 9px 23px;
}
.schoolSubTitle p:nth-child(2) {
  margin-left: 20px;
}
.schoolSynopsis {
  width: 1200px;
  margin: 0 auto;
}
.synopsisTitle {
  padding-top: 60px;
}
.synopsisTitle span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.synopsisTitle p {
  /* width: 26px; */
  width: 77px;
  height: 3px;
  background-color: #074DFE;
  border-radius: 10px;
  margin: 20px 0;
}
.synopsis {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
}
.schoolImg {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  /* margin-bottom: 100px; */
  margin: 100px auto;
}
.schoolImg img {
  max-width: 1200px;
  height: auto;
}
</style>