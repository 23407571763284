export const emojiMap = {
	'[调皮]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_113@2x.png',
	'[流汗]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_75@2x.png',
	'[偷笑]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_7@2x.png',
	'[再见]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_9@2x.png',
	'[敲打]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_69@2x.png',
	'[擦汗]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_68@2x.png',
	'[猪头]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_85@2x.png',
	'[玫瑰]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_88@2x.png',
	'[流泪]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_76@2x.png',
	'[大哭]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_33@2x.png',
	'[嘘]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_27@2x.png',
	'[酷]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_120@2x.png',
	'[抓狂]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_59@2x.png',
	'[委屈]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_37@2x.png',
	'[便便]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_5@2x.png',
	'[炸弹]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_79@2x.png',
	'[菜刀]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_107@2x.png',
	'[可爱]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_18@2x.png',
	'[色]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_105@2x.png',
	'[害羞]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_38@2x.png',
	'[得意]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_48@2x.png',
	'[吐]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_22@2x.png',
	'[微笑]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_49@2x.png',
	'[怒]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_53@2x.png',
	'[尴尬]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_39@2x.png',
	'[惊恐]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_54@2x.png',
	'[冷汗]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_10@2x.png',
	'[爱心]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_83@2x.png',
	'[示爱]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_95@2x.png',
	'[白眼]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_91@2x.png',
	'[傲慢]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_8@2x.png',
	'[难过]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_127@2x.png',
	'[惊讶]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_55@2x.png',
	'[疑问]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_90@2x.png',
	'[困]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_29@2x.png',
	'[么么哒]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_3@2x.png',
	'[憨笑]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_56@2x.png',
	'[NO]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_0@2x.png',
	'[OK]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_1@2x.png',
	'[下雨]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_2@2x.png',
	'[乒乓]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_4@2x.png',
	'[信封]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_6@2x.png',
	'[凋谢]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_11@2x.png',
	'[刀]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_12@2x.png',
	'[删除]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_13@2x.png',
	'[勾引]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_14@2x.png',
	'[发呆]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_15@2x.png',
	'[发抖]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_16@2x.png',
	'[可怜]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_17@2x.png',
	'[右哼哼]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_19@2x.png',
	'[右太极]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_20@2x.png',
	'[右车头]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_21@2x.png',
	'[吓]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_23@2x.png',
	'[咒骂]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_24@2x.png',
	'[咖啡]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_25@2x.png',
	'[啤酒]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_26@2x.png',
	'[回头]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_28@2x.png',
	'[坏笑]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_30@2x.png',
	'[多云]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_31@2x.png',
	'[大兵]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_32@2x.png',
	'[太阳]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_34@2x.png',
	'[奋斗]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_35@2x.png',
	'[奶瓶]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_36@2x.png',
	'[左哼哼]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_40@2x.png',
	'[左太极]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_41@2x.png',
	'[左车头]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_42@2x.png',
	'[差劲]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_43@2x.png',
	'[弱]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_44@2x.png',
	'[强]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_45@2x.png',
	'[彩带]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_46@2x.png',
	'[彩球]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_47@2x.png',
	'[心碎了]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_50@2x.png',
	'[快哭了]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_51@2x.png',
	'[怄火]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_52@2x.png',
	'[手枪]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_57@2x.png',
	'[打哈欠]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_58@2x.png',
	'[折磨]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_60@2x.png',
	'[抠鼻]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_61@2x.png',
	'[抱抱]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_62@2x.png',
	'[抱拳]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_63@2x.png',
	'[拳头]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_64@2x.png',
	'[挥手]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_65@2x.png',
	'[握手]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_66@2x.png',
	'[撇嘴]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_67@2x.png',
	'[晕]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_70@2x.png',
	'[月亮]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_71@2x.png',
	'[棒棒糖]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_72@2x.png',
	'[汽车]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_73@2x.png',
	'[沙发]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_74@2x.png',
	'[激动]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_77@2x.png',
	'[灯泡]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_78@2x.png',
	'[熊猫]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_80@2x.png',
	'[爆筋]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_81@2x.png',
	'[爱你]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_82@2x.png',
	'[爱情]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_84@2x.png',
	'[猫咪]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_86@2x.png',
	'[献吻]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_87@2x.png',
	'[瓢虫]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_89@2x.png',
	'[皮球]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_92@2x.png',
	'[睡觉]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_93@2x.png',
	'[磕头]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_94@2x.png',
	'[礼品袋]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_96@2x.png',
	'[礼物]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_97@2x.png',
	'[篮球]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_98@2x.png',
	'[米饭]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_99@2x.png',
	'[糗大了]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_100@2x.png',
	'[红双喜]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_101@2x.png',
	'[红灯笼]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_102@2x.png',
	'[纸巾]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_103@2x.png',
	'[胜利]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_104@2x.png',
	'[药]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_106@2x.png',
	'[蛋糕]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_108@2x.png',
	'[蜡烛]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_109@2x.png',
	'[街舞]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_110@2x.png',
	'[衰]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_111@2x.png',
	'[西瓜]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_112@2x.png',
	'[象棋]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_114@2x.png',
	'[跳绳]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_115@2x.png',
	'[跳跳]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_116@2x.png',
	'[车厢]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_117@2x.png',
	'[转圈]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_118@2x.png',
	'[鄙视]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_119@2x.png',
	'[钞票]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_121@2x.png',
	'[钻戒]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_122@2x.png',
	'[闪电]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_123@2x.png',
	'[闭嘴]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_124@2x.png',
	'[闹钟]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_125@2x.png',
	'[阴险]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_126@2x.png',
	'[雨伞]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_128@2x.png',
	'[青蛙]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_129@2x.png',
	'[面条]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_130@2x.png',
	'[鞭炮]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_131@2x.png',
	'[风车]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_132@2x.png',
	'[飞吻]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_133@2x.png',
	'[飞机]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_134@2x.png',
	'[饥饿]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_135@2x.png',
	'[香蕉]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_136@2x.png',
	'[骷髅]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_137@2x.png',
	'[麦克风]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_138@2x.png',
	'[麻将]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_139@2x.png',
	'[鼓掌]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_140@2x.png',
	'[呲牙]': 'https://webim-1252463788.file.myqcloud.com/assets/emoji/emoji_141@2x.png',
	'[大笑]': 'https://s2.videocc.net/emotion-sdk/v2/laugh.png',
	'[哭笑]': 'https://s2.videocc.net/emotion-sdk/v2/laugh-cry.png',
	'[奸笑]': 'https://s2.videocc.net/emotion-sdk/v2/bad-smile.png',
	'[吃瓜]': 'https://s2.videocc.net/emotion-sdk/v2/eat-melon.png',
	'[让我看看]': 'https://s2.videocc.net/emotion-sdk/v2/let-me-see.png',
	'[按脸哭]': 'https://s2.videocc.net/emotion-sdk/v2/cry-face.png',
	'[愤怒]': 'https://s2.videocc.net/emotion-sdk/v2/anger.png',
	'[ok]': 'https://s2.videocc.net/emotion-sdk/v2/ok.png',
	'[心碎]': 'https://s2.videocc.net/emotion-sdk/v2/broken-heart.png',
	'[加1]': 'https://s2.videocc.net/emotion-sdk/v2/plus-1.png',
	'[正确]': 'https://s2.videocc.net/emotion-sdk/v2/correct.png',
	'[错误]': 'https://s2.videocc.net/emotion-sdk/v2/error.png',
	'[满分]': 'https://s2.videocc.net/emotion-sdk/v2/full-marks.png',
	'[笔记]': 'https://s2.videocc.net/emotion-sdk/v2/note.png',
	'[比心]': 'https://s2.videocc.net/emotion-sdk/v2/than-heart.png',
	'[赞]': 'https://s2.videocc.net/emotion-sdk/v2/great.png',
	'[红包]': 'https://s2.videocc.net/emotion-sdk/v2/red-packet.png',
	'[奶茶]': 'https://s2.videocc.net/emotion-sdk/v2/milk-tea.png',
	'[时钟]': 'https://s2.videocc.net/emotion-sdk/v2/clock.png',
	'[晚安]': 'https://s2.videocc.net/emotion-sdk/v2/good-night.png',
}
