<template>
	<div class="index">
		<div style="height: 70px;" id="goTop">
			<Header></Header>
		</div>
		<!-- 研究院动态列表 -->
		<div class="yanjiuyuan_content">
			<div class="yanjiuyuan_shouye">您的位置：<span class="pointer" :style="tabIndex == 1 ? 'color: #FF7C06' : '' "
					@click="goIndex" @mousemove="tabIndex = 1" @mouseout="tabIndex=null">首页</span> >
				<span :style="tabIndex == 2 ? 'color: #FF7C06' : '' " class="pointer" @mousemove="tabIndex = 2"
					@click="goYanjiuyuan" @mouseout="tabIndex=null">资讯中心</span> >
				<span class="yanjiuyuan_link">研究院动态</span>
			</div>
			<div  v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"  style="min-height: 500px;">
				<div v-for="(item, index) in dataList" :key="index">
					<div class="dongtailist_list" :class="{dongtailist_list_active: currentIdx == index}"
						@mouseenter="currentIdx = index" @mouseleave="currentIdx = null" @click="goDetail(item)">
						<div class="yanjiuyuan_dongtai_left">
							<img :src="item.image" class="imgWidth">
						</div>
						<div class="yanjiuyuan_dongtai_right setImg">
							<div class="yanjiuyuan_dongtai_right_title">{{item.title}}</div>
							<div class="yanjiuyuan_dongtai_right_time">{{item.release_time}}</div>
							<div class="yanjiuyuan_dongtai_right_text listTextHtml" v-html="item.content"></div>
							<div class="yanjiuyuan_dongtai_righ_btn">查看详情</div>
						</div>
					</div>
				</div>
				
				<pages v-if="dataList.length > 0" :page="page" class="pageBox" @changePage="changePage"></pages>
			</div>
			
		</div>

		<BottomTab></BottomTab>
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	import Header from '../components/Header.vue'
	import BottomTab from '../components/BottomTab.vue'
	import pages from '../components/pages.vue'
	export default {
		name: 'yanjiuyuanList',
		data() {
			return {
				tabIndex: null,
				dataList: [],
				currentIdx: null,
				total: 1,
				page: {
					total: 1, //总条目数
					pageSize: 10, //每页显示条目个数
					currentPage: 1, //当前页数
				},
				loading: true,
			}
		},
		created() {
			this.getDataList();
		},
		methods: {
			getDataList() {
				this.loading = true;
				//获取 研究院动态
				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/business_cooperation/get_information_list",
					data: {
						page: this.page.currentPage,
						list_rows: this.page.pageSize,
						status: 2
					}
				}).then(res => {
					this.dataList = res.data.data;
					
					this.page.total = res.data.total;
					this.loading = false;
				}).catch((error) => {
					console.log(error);
				});
			},

			goYanjiuyuan() {
				this.$router.push('/Yanjiuyuan')
			},
			goIndex() {
				this.$router.push('/Index')
				Bus.$emit('scrollToTop')
			},
			goDetail(item) {
				this.$router.push('/yanjiuyuanDetail?type=2' + '&id=' + item.id)
			},
			changePage(page) {
				// console.log("当前页：", page);
				this.page.currentPage = page;
			   this.getDataList();
			   document.querySelector('#goTop').scrollIntoView(true);
			},

		},
		components: {
			Header,
			BottomTab,
			pages
		}

	}
</script>

<style type="text/css">
	.listTextHtml p,
	.listTextHtml p span,
	.listTextHtml span,
	.listTextHtml h1,
	.listTextHtml h2,
	.listTextHtml h3,
	.listTextHtml h4,
	.listTextHtml h5,
	.listTextHtml strong {
		color: #999 !important;
		font-size: 14px !important;
		font-weight: normal !important;
		background-color: rgba(0, 0, 0, 0) !important;
	}

	.listTextHtml img {
		display: none;
	}

	.activeDondtai {
		background: #FF7C06;
		color: #fff !important;
	}

	.activeDondtai .listTextHtml span,
	.activeDondtai .listTextHtml h1,
	.activeDondtai .listTextHtml p,
	.activeDondtai .listTextHtml strong {
		color: #fff !important;
	}

	.activeDondtai .yanjiuyuan_dongtai_right_title {
		color: #fff !important;
	}

	.activeDondtai .yanjiuyuan_dongtai_right_time {
		color: #fff !important;
	}

	.activeDondtai .yanjiuyuan_dongtai_right_text {
		color: #fff !important;
	}
</style>
<style scoped lang="scss">
	.yanjiuyuan_content {
		width: 1200px;
		margin: 60px auto;
	}

	.yanjiuyuan_shouye {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 13px;
		margin-bottom: 60px;
	}

	.yanjiuyuan_link {
		color: #FF7C06;
	}

	.dongtailist_list {
		padding: 28px 33px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #E6E6E6;
		cursor: pointer;
	}

	.dongtailist_list .yanjiuyuan_dongtai_left {
		width: 248px;
		height: 165px;
		overflow: hidden;
	}

	.yanjiuyuan_dongtai_left img {
		width: 248px;
		height: 165px;
		object-fit: cover;
	}

	.dongtailist_list .yanjiuyuan_dongtai_right {
		width: 853px;
	}

	.dongtailist_list .yanjiuyuan_dongtai_right_title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}

	.dongtailist_list .yanjiuyuan_dongtai_right_time {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		margin: 12px 0;
	}

	.yanjiuyuan_dongtai_right_text {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-height: 22px;
		height: 44px;
	}


	.setImg .yanjiuyuan_dongtai_right_text img {
		display: none;
	}

	.yanjiuyuan_dongtai_righ_btn {
		width: 100px;
		height: 30px;
		border: 1px solid #C2C2C2;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
		text-align: center;
		margin-top: 20px;
	}

	.dongtailist_list_active {
		background: #F0F0F0;
	}

	.dongtailist_list_active .yanjiuyuan_dongtai_right_title {
		color: #FF7C06;
	}

	.dongtailist_list_active .yanjiuyuan_dongtai_righ_btn {
		background: #FF7C06;
		color: #FFF;
		border: 1px solid #FF7C06;
	}

	.pageBox {
		margin: 30px 0;
	}
</style>
