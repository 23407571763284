var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"#f5f5f5"}},[_c('img',{staticStyle:{"width":"100%","height":"auto","padding":"0","margin":"0"},attrs:{"src":require("../../assets/image/schoolls.png")}}),_vm._m(0),_c('div',{staticStyle:{"margin-bottom":"20px"},attrs:{"id":"content"}}),_vm._m(1),_c('div',{staticClass:"shcoolList"},[_c('div',{staticClass:"schoolOption"},[_c('span',{staticClass:"schoolOption_title"},[_vm._v("院校所在地：")]),_c('div',[_c('div',{staticClass:"occupation_tag_view"},[_c('div',{staticClass:"tagNmae pointer",class:[_vm.active_regionTag_index == 202 ? 'active_tag' : ''],on:{"click":function($event){return _vm.clickAllRegion()}}},[_vm._v(" 全部 ")]),_vm._l((_vm.regionList),function(item,index){return _c('div',{key:index,staticClass:"tagNmae pointer",class:[
              _vm.active_regionTag_index == index && _vm.active_regionTag_index != 202
                ? 'active_tag'
                : '',
              index > 7 ? 'position_none' : 'position_block',
              _vm.show ? 'position_none' : 'position_block' ],on:{"click":function($event){return _vm.clickRegionTag(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),_c('div',{staticClass:"icon"},[_c('el-button',{attrs:{"size":"default","icon":_vm.icons},on:{"click":_vm.openSchool}})],1)]),_c('div',[_c('div',{staticClass:"schoolOption"},[_c('span',{staticClass:"schoolOption_title"},[_vm._v("院校类型：")]),_c('div',[_c('div',{staticClass:"occupation_tag_view"},[_c('div',{staticClass:"tagNmae pointer",class:_vm.active_typeTag_index == 202 ? 'active_tag' : '',on:{"click":function($event){return _vm.clickAllType()}}},[_vm._v(" 全部 ")]),_vm._l((_vm.typeList),function(item,index){return _c('div',{key:index,staticClass:"tagNmae pointer",class:_vm.active_typeTag_index == index && _vm.active_typeTag_index != 202
                  ? 'active_tag'
                  : '',on:{"click":function($event){return _vm.clickTypeTag(item, index)}}},[_vm._v(" "+_vm._s(item.value)+" ")])})],2)])])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"cardsList"},_vm._l((_vm.schoolList),function(item,index){return _c('div',{key:index,staticClass:"schoolCards"},[_c('div',{staticClass:"schoolInfo"},[_c('img',{attrs:{"src":item.school_img,"alt":""}}),_c('div',{staticClass:"schoolTitle"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.school_details)}})])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"schoolBtn"},[_c('span',[_vm._v(_vm._s(item.school_type))]),_c('div',{staticClass:"detailsBtn"},[_c('el-button',{attrs:{"type":"info","size":"default"},on:{"click":function($event){return _vm.details(item.id)}}},[_vm._v("院校详情")])],1)])])}),0),(this.schoolList.length == 0)?_c('div',{staticClass:"ossImgBox"},[_c('el-image',{staticClass:"noword",attrs:{"src":"https://static.shanchuangjiaoyu.com/yc_baidu/noword.png"}}),_c('div',{staticClass:"ossImgText"},[_vm._v("暂无内容哦~")])],1):_vm._e(),_c('div',{staticClass:"paging"},[(this.schoolList.length > 0)?_c('el-pagination',{attrs:{"background":"","prev-text":"上一页","next-text":"下一页","layout":"prev, pager, next","total":_vm.page.total,"page-size":_vm.page.pageSize,"current-page":_vm.page.currentPage},on:{"current-change":_vm.changePage}}):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board"},[_c('div',{staticClass:"boardTitle"},[_c('span',[_vm._v("我们能提供")]),_c('img',{attrs:{"src":require("../../assets/image/icon.png"),"alt":""}})]),_c('div',{staticClass:"boardIco"},[_c('div',{staticClass:"boardTip"},[_c('img',{attrs:{"src":require("../../assets/image/post.png"),"alt":""}}),_c('div',{staticClass:"boardSlogan"},[_c('span',[_vm._v("校企合作")]),_c('p',[_vm._v("千校互联-融合生态")])])]),_c('div',{staticClass:"boardTip"},[_c('img',{attrs:{"src":require("../../assets/image/enterprise.png"),"alt":""}}),_c('div',{staticClass:"boardSlogan"},[_c('span',[_vm._v("产教融合")]),_c('p',[_vm._v("扎根产业-赋能企业")])])]),_c('div',{staticClass:"boardTip"},[_c('img',{attrs:{"src":require("../../assets/image/business.png"),"alt":""}}),_c('div',{staticClass:"boardSlogan"},[_c('span',[_vm._v("促进发展")]),_c('p',[_vm._v("促活经济-科技攻关")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"boardTitle"},[_c('span',[_vm._v("我们拥有众多合作院校")])])}]

export { render, staticRenderFns }