<template>
	<div class="downloadAPP">
		<!-- 下载APP -->
		<img src="https://static.shanchuangjiaoyu.com/ycpc/new/downBj.png" style="width: 100%;object-fit: cover;">
		<div style="position: absolute;width: 100%; top: 0; margin: 87px auto;">
			<div class="down_logo">
				<img src="../../assets/image/svgImg/xiazai/logo.svg" class="downyc">
				<div class="logo_box">
					<div class="logo">精研学堂教育客户端</div>
					<div class="zhichi">同时支持 Windows，平板， iOS，Android版本</div>
				</div>
			</div>
			<div class="down_content">
				<div class="">
					<div class="content_img">
						<img src="../../assets/image/svgImg/xiazai/kaifazhong.svg" class="kaifa">
						<img src="../../assets/image/svgImg/xiazai/PC.svg" class="zhichiImg">
						<div class="jiaoduImg" @click="look_erweima(1)"></div>
					</div>
					<div class="wenzi_box">
						<div class="name">PC客户端</div>
						<div class="content">PC小白变大咖</div>
						<div class="content">手把手教你硬技能</div>
					</div>
				</div>
				<div class="">
					<div class="content_img">
						<img src="../../assets/image/svgImg/xiazai/kaifazhong.svg" class="kaifa">
						<img src="../../assets/image/svgImg/xiazai/iOS.svg" class="zhichiImg">
						<div class="jiaoduImg pointer" @click="look_erweima(2)"></div>
					</div>
					<div class="wenzi_box">
						<div class="name">IOS客户端</div>
						<div class="content">随时随地</div>
						<div class="content">移动看课方便高效</div>
					</div>
				</div>
				<div class="">
					<div class="content_erweima" v-if="currentIndex == 3">
						<img class="erweima" src="../../assets/image/erweima.png">
						<div class="xiazia">扫码下载</div>
					</div>
					<div class="content_img" v-else>
						<img src="../../assets/image/svgImg/xiazai/erweima.svg" class="kaifa">
						<img src="../../assets/image/svgImg/xiazai/anzhuo.svg" class="zhichiImg">
						<div class="jiaoduImg pointer" @click="look_erweima(3)"></div>
					</div>
					<div class="wenzi_box">
						<div class="name">安卓客户端</div>
						<div class="content">作业辅导</div>
						<div class="content">授课老师线上批改</div>
					</div>
				</div>
				<div class="">
					<div class="content_erweima" v-if="currentIndex == 4">
						<img class="erweima" src="../../assets/image/wap.png">
						<div class="xiazia">扫一扫</div>
					</div>
					<div class="content_img" v-else>
						<img src="../../assets/image/svgImg/xiazai/erweima.svg" class="kaifa">
						<img src="../../assets/image/svgImg/xiazai/wap.svg" class="zhichiImg">
						<div class="jiaoduImg pointer" @click="look_erweima(4)"></div>
					</div>
					<div class="wenzi_box">
						<div class="name">Wap站</div>
						<div class="content">多方位指导</div>
						<div class="content">让你从入门到精通</div>
					</div>
				</div>
				<div class="">
					<div class="content_img">
						<img src="../../assets/image/svgImg/xiazai/kaifazhong.svg" class="kaifa">
						<img src="../../assets/image/svgImg/xiazai/pingban.svg" class="zhichiImg">
						<div class="jiaoduImg" @click="look_erweima(5)"></div>
					</div>
					<div class="wenzi_box">
						<div class="name">平板客户端</div>
						<div class="content">直播+回放授课</div>
						<div class="content">循序渐进助你轻松学</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'downloadAPP',
		data() {
			return {
				ossUrl: process.env.VUE_APP_OSS_URL,
				currentIndex: null
			}
		},
		created() {
			
		},
		methods: {
			look_erweima(index) {
				console.log(index)
				this.currentIndex = index;
				
			}
		},
		components: {
			
		}

	}
</script>

<style scoped>
	.downloadAPP {
		position: relative;
		background-color: #FAFBFF;
	}
	.down_logo {
		display: flex;
		width: 100%;
		justify-content: center;
		margin: 0px 0 113px 0;
	}
	.downyc {
		width: 122px;
		height: 122px;
		object-fit: cover;
		margin: 0 10px 0 0;
	}
	.logo_box {
		display: flex;
		height: 122px;
		flex-direction: column;
		justify-content: center;
	}
	.logo {
		font-size: 36px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #2E2927;
	}
	.zhichi {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #2E2927;
	}
	.down_content {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
	}
	.content_img {
		width: 154px;
		height: 154px;
		background: #FFFFFF;
		box-shadow: 0px 3px 29px 0px rgba(16, 111, 237, 0.1);
		border-radius: 20px;
		position: relative;
		overflow: hidden;
	}
	.wenzi_box {
		text-align: center;
	}
	.name {
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		margin: 30px 0;
	}
	.content {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
	}
	.zhichiImg {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.jiaoduImg {
		width: 64px;
		height: 57px;
		position: absolute;
		top: 0px;
		right: 0px;
	}
	.kaifa {
		position: absolute;
		width: 64px;
		height: 57px;
		object-fit: cover;
		right: 0;
		top: 0;
	}
	.content_erweima {
		width: 154px;
		height: 154px;
		background: #FFFFFF;
		box-shadow: 0px 3px 29px 0px rgba(16, 111, 237, 0.1);
		border-radius: 20px;
		text-align: center;
	}
	.erweima {
		width: 100px;
		margin-top: 22px;
	}
	.xiazia {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
	}
</style>
