<template>
  <!-- <div class="" style="padding: 0; margin: 0; width: 100%; height: 100%"> -->

  <div class="index" v-if="showPage">
    <div style="height: 70px;" id="contents">
      <Header></Header>
    </div>

    <div class="" style="padding: 0; margin: 0; width: 100%; height: auto">
      <!--    <div style="margin-top:20px; width: 30px;
	height: 30px;" id="contents">   </div> -->
      <div style="
        padding: 69px 0 69px 0;
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        align-items: center;
      ">
        <div style="color: #333333; font-size: 32px; padding-left: 18px">
          全部课程
        </div>
        <div style="
          border-radius: 22px;
          border: 1px solid #e2e2e2;
          display: flex;
          height: 48px;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
        ">
          <img style="width: 25px; height: 21px" src="https://static.shanchuangjiaoyu.com/ycpc/assets/gonggao@2x.png" />
          <span style="font-size: 16px; color: #333333; margin-left: 10px">公告:</span>
          <div style="
            color: #333333;
            font-size: 16px;
            padding-left: 18px;
            padding-right: 26px;
          ">
            带你了解
            <span style="color: #074DFE; cursor: pointer" @click="goAbout">
              精研学堂</span>
          </div>
          <img style="
            width: 16px;
            height: 17px;
            margin-top: 2px;
            padding-right: 20px;
          " src="https://static.shanchuangjiaoyu.com/ycpc/assets/toright@2x.png" />
        </div>
      </div>

      <div style="padding: 0 0px 40px; width: 1200px; margin: 0 auto">
        <div style="
          display: flex;
          background-color: #f5f6fa;
          border-radius: 20px;
          padding: 0 0px;
        ">
          <div class="titleTab" :class="tid == 202 ? 'activeTab' : ''" @click="click_ALLtab()">
            全部
          </div>
          <div class="titleTab" :class="tid == item.id && tid != 202 ? 'activeTab' : ''"
            v-for="(item, index) in titleTabList" :key="index" @click="click_tab(item, index)">
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-if="DataList && DataList.length > 0" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        v-loading="loading">
        <div style="
          width: 1200px;
          background-color: #ffffff;
          padding: 0;
          margin: 0 auto;
        ">
          <div class="kecheng_box">
            <div v-for="(item, index) in DataList" :key="index" class="kecheng_box_item"
              :style="index == 3 || index == 7 ? 'margin-right: 0' : ''" @click="toKecheng(item)">
              <img class="kecheng_box_item_img" :src="item.filepath" />
              <div class="kecheng_box_item_title">
                <span style="
                  color: #333333;
                  font-size: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                ">{{ item.name }}</span>
                <span class="tancan">{{ item.type_name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="paging">
          <el-pagination background prev-text="上一页" next-text="下一页" layout="prev, pager, next" :total="page.total"
            :page-size="page.pageSize" :current-page="page.currentPage" @current-change="changePage">
          </el-pagination>
        </div>
        <!-- <pages :page="page" class="pageBox" @changePage="changePage"></pages> -->
      </div>
    </div>

    <BottomTab></BottomTab>
  </div>
  <!-- </div> -->
</template>

<script>
import Header from '../components/Header.vue'
import BottomTab from '../components/BottomTab.vue'
import pages from "../components/pages.vue";
export default {
  name: "Kecheng",
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_URL,
      tid: 202,
      tids: 0,
      course_id: 3,
      titleTabList: [],
      page: {
        total: 1, //总条目数
        pageSize: 8, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      DataList: [],
      loading: false,
      showPage: false
    };
  },
  created() {
    // this.click_ALLtab();
    this.getAllData();
    // this.getLabelData();
    this.getAllLabelData();
    this.getData();
  },
  methods: {
    //获取分类
    getLabelData() {
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/get_design_school_course_classification",
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
            this.titleTabList = res.data;
            this.tid = this.titleTabList[0].id;
            this.getData();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllLabelData() {
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/get_design_school_course_classification",
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
            this.titleTabList = res.data;
            this.tid = 202;
            this.getAllData();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData() {
      //获取数据
      this.$axios({
        method: "POST",
        url:
          "api/officialWebsite/get_course_list?limit=" +
          this.page.pageSize +
          "&page=" +
          this.page.currentPage +
          "&tid=" +
          this.tid,
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
            // console.log(res,'resss');
            this.DataList = res.data.data;
            this.page.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
          this.showPage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllData() {
      //获取数据
      this.$axios({
        method: "POST",
        url:
          "api/officialWebsite/get_course_list?limit=" +
          this.page.pageSize +
          "&page=" +
          this.page.currentPage +
          "&tid=" +
          this.tids +
          "&course_id=" +
          this.course_id,
      })
        .then((res) => {
          if (res.code == 1 || res.code == 200) {
            // console.log(res,'resss');
            this.DataList = res.data.data;
            this.page.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    click_ALLtab() {
      this.tid = 202;
      // console.log(item, "333");
      // console.log(this.tid,'123');
      this.loading = true;
      setTimeout(() => {
        document.querySelector("#contents").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.page.currentPage = 1;
      this.getAllData();
    },
    click_tab(item, index) {
      this.tid = item.id;
      // console.log(item, "333");
      // console.log(this.tid,'123');
      this.loading = true;
      setTimeout(() => {
        document.querySelector("#contents").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.page.currentPage = 1;
      this.getData();
    },
    changePage(page) {
      console.log("当前页：", page);
      this.page.currentPage = page;
      if (this.tid == 202) {
        this.getAllData();
      } else {
        this.getData();
      }

      this.loading = true;

      setTimeout(() => {
        document.querySelector("#contents").scrollIntoView(true);
        this.loading = false;
      }, 500);
    },
    toKecheng(item) {
      /* let href = '/meal/';
      if (item.zt != 4) {
        href = '/meal/'
      } 
      location.href = href + item.id; */
      this.$router.push({
        path: '/kechengDetail',
        query: {
          id: item.id
        }
      })
    },
    goAbout() {
      let href = this.$router.resolve({
        path: "/enter",
      });
      window.open(href.href, "_blank");
    },
  },
  components: {
    pages,
    Header,
    BottomTab
  },
};
</script>

<style scoped="scoped">
.kecheng_box {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}

.kecheng_box_item {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 8px;
  box-sizing: border-box;
  margin: 0 10px 20px 0;
  width: 292px;
  cursor: pointer;
}

.kecheng_box_item:hover {
  padding: 8px;
  box-shadow: 3px 3px 29px 0px rgba(0, 0, 0, 0.15);
}

.kecheng_box_item_img {
  width: 100%;
  height: 214px;
  border-radius: 10px;
  object-fit: cover;
  object-position: 50% 17%;
}

.kecheng_box_item_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 40px;
}

.tancan {
  min-width: 60px;
  height: 26px;
  background: #fffbf7;
  border: 1px solid #ffd4a7;
  border-radius: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff7c06;
  line-height: 26px;
  text-align: center;
  padding: 0px 5px;
}

.titleTab {
  color: #999999;
  font-size: 14px;
  padding: 12px 34px;
  cursor: pointer;
}

.activeTab {
  background: #ffffff;
  box-shadow: 0px 3px 18px 0px rgba(47, 130, 255, 0.08);
  border-radius: 21px;
  color: #074DFE;
}

.pageBox {
  margin: 30px 0;
}

.paging {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 109px;
}
</style>
