<template>
  <div>
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading = 'loading'>
      <button v-if="!hasUnsubscribe" class="unsubscribe_btn" @click="dialogVisible = true" >注销账号</button>
      <div class="unsubscribe_success_box" v-else>
        <div class="unsubscribe_success_img">
          <img src="../../../../assets/image/unsubscribe_success.png" class="auto-size" alt="">
        </div>
        <div style="font-size: 28px;">您的注销申请已提交成功</div>
        <div style="font-size: 19px; color: #A5A5A5;">请及时联系【在线客服】并关注最新进展</div>
      </div>
    </div>

    <!-- 修改手机号弹窗/ 确认当前手机号 -->
       <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          center
          class="unsubscribe_dialog"
        >
          <div style="text-align: center; font-size: 28px;">确定注销账号吗？</div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" round @click="confirm_btn" class="dialog-footer-btn">确认</el-button>
            <el-button type="info"  round @click="dialogVisible = false" class="dialog-footer-btn">取消</el-button>
          </span>
        </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hasUnsubscribe:false,
      loading: false,
      dialogVisible: false,
      tabs_active: 0,
      tabsList: [
        {
          tabs_title: '注销账号'
        },
      ],
      timeout:null,//防抖
    }
  },
  created () {
    this.getLogout();
  },
  methods: {
     // tabs 点击事件
    tabsClick(index) {
      this.tabs_active = index
    },
    // 是否注销
    getLogout(){
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/the_personal_data'
      }).then(res => {
        if (res.code === 200) {
          this.hasUnsubscribe=Boolean(res.data.cancellation_application!=0)
        }
      })
    },
    // 确定注销
    confirm_btn() {
      let _this=this
      if (_this.timeout) clearTimeout(_this.timeout);
      _this.timeout = setTimeout(() => {
        //需要执行的事件
        _this.$axios({
          method: 'POST',
          url: 'api/officialWebsite/computer/yuan_cheng/close_the_account'
        }).then(res => {
          _this.dialogVisible=false
          if (res.code === 200) {
            _this.hasUnsubscribe=res.data
          }
        })
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  padding: 40px 66px 60px 27px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.unsubscribe_btn{
  width: 150px;
  height: 58px;
  border-radius: 29px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  margin: 25% 0 0 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,125,252,1);
}

.unsubscribe_success_img{
  width: 58px;
  height: 58px;
}

.unsubscribe_success_box{
  margin: 25% 0 0 50%;
  transform: translate(-50%, -50%);
  width: 343px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
</style>

<style lang="less" scoped>
/deep/ .el-pagination{
  display: flex;
  justify-content: end;
}
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 300px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}


/deep/ .unsubscribe_dialog .el-dialog{
  margin-top: 40vh !important;
  width: 450px !important;
  height: 287px;
  border-radius: 32px;
  padding: 35px 82px;
  background: rgba(255,255,255,1);
}

/deep/ .dialog-footer{
  display: flex;
  width: 227px;
  justify-content: space-between;
}

/deep/ .dialog-footer-btn{
  width: 92px;
  height: 42px;
  border-radius: 21px;
  font-size: 18px;
}
</style>