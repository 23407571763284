var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticStyle:{"width":"100%","height":"auto","padding":"0","margin":"0"},attrs:{"src":require("../../assets/image/personnel.png")}}),_c('div',{staticClass:"search"},[_c('div',{staticClass:"search_left"},[_c('div',{staticClass:"region"},[_c('el-select',{attrs:{"placeholder":"全国"},on:{"change":_vm.seradd},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.regionList),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1),_c('div'),_c('div',[_c('el-input',{staticStyle:{"width":"800px"},attrs:{"placeholder":"请输入院校、专业","clearable":""},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}})],1)]),_c('div',[_c('el-button',{attrs:{"type":"primary","size":"default"},on:{"click":function($event){return _vm.searchUser()}}},[_vm._v("搜索")])],1)]),_c('div',{staticStyle:{"background-color":"#f5f5f5","width":"100%","height":"ayto","margin-top":"-70px"}},[_c('div',{attrs:{"id":"content"}}),_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","flex-flow":"column","width":"1200px","padding-top":"90px","margin":"0 auto"}},[_vm._m(0),_c('div',{staticStyle:{"display":"flex","flex-flow":"row","position":"relative"}},[_c('div',{staticClass:"occupation_tag_view"},[_c('div',{staticClass:"tagNmae pointer",class:_vm.active_categoryTag_index == 202 ? 'active_tag' : '',on:{"click":_vm.getAllUserList}},[_vm._v(" 全部 ")]),_vm._l((_vm.categoryList),function(item,index){return _c('div',{key:index,staticClass:"tagNmae pointer",class:[
              _vm.active_categoryTag_index == item.id &&
              _vm.active_categoryTag_index != 202
                ? 'active_tag'
                : '',
              index > 8 ? 'position_none' : 'position_block',
              _vm.show ? 'position_none' : 'position_block' ],on:{"click":function($event){return _vm.clickCategoryTag(item, index)}}},[_vm._v(" "+_vm._s(item.post_name)+" ")])})],2),_c('div',{staticClass:"icon"},[_c('el-button',{attrs:{"size":"default","icon":_vm.icons},on:{"click":_vm.openSchool}})],1)])]),_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","flex-flow":"column","width":"1200px","padding-top":"40px","margin":"0 auto"}},[_vm._m(1),_c('div',[_c('div',{staticClass:"occupation_tag_view"},[_c('div',{staticClass:"tagNmae pointer",class:_vm.active_educationTag_index == 202 ? 'active_tag' : '',on:{"click":_vm.getAlleduList}},[_vm._v(" 全部 ")]),_vm._l((_vm.educationList),function(item,index){return _c('div',{key:index,staticClass:"tagNmae pointer",class:_vm.active_educationTag_index == item.id &&
              _vm.active_educationTag_index != 202
                ? 'active_tag'
                : '',on:{"click":function($event){return _vm.clickEducationTag(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"userList"},_vm._l((_vm.userList),function(item){return _c('div',{key:item.id,staticClass:"users"},[(item.gender == '男')?_c('div',{staticClass:"userImg"},[_c('img',{attrs:{"src":_vm.man,"alt":""}})]):_vm._e(),(item.gender == '女')?_c('div',{staticClass:"userImg"},[_c('img',{attrs:{"src":_vm.woman,"alt":""}})]):_vm._e(),_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"userTitle"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.postName))])]),_c('div',[_c('el-descriptions',{attrs:{"column":_vm.column}},[_c('el-descriptions-item',{attrs:{"label":"年龄"}},[_vm._v(_vm._s(item.age))]),_c('el-descriptions-item',{attrs:{"label":"地区"}},[_vm._v(_vm._s(item.region))]),_c('el-descriptions-item',{attrs:{"label":"专业"}},[_vm._v(_vm._s(item.major))]),_c('el-descriptions-item',{attrs:{"label":"院校"}},[_vm._v(_vm._s(item.colleges))])],1)],1)]),_c('div',{staticClass:"userBtn"},[_c('el-button',{attrs:{"type":"info","size":"default"},on:{"click":_vm.details}},[_vm._v("查看详情")])],1),_c('div',{staticClass:"userEdu"},[_c('span',[_vm._v(_vm._s(item.education_name))])])])}),0),(this.userList.length == 0)?_c('div',{staticClass:"ossImgBox"},[_c('el-image',{staticClass:"noword",attrs:{"src":"https://static.shanchuangjiaoyu.com/yc_baidu/noword.png"}}),_c('div',{staticClass:"ossImgText"},[_vm._v("暂无内容哦~")])],1):_vm._e(),_c('div',{staticClass:"paging"},[(this.userList.length > 0)?_c('el-pagination',{attrs:{"background":"","prev-text":"上一页","next-text":"下一页","layout":"prev, pager, next","total":_vm.page.total,"page-size":_vm.page.pageSize,"current-page":_vm.page.currentPage},on:{"current-change":_vm.changePage}}):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("职位类别")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("学历")])])}]

export { render, staticRenderFns }