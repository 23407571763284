<template>
    <div style="height: 100vh;overflow-y: auto;background: #F8F8F8;">
        <div style="height: 70px;">
            <Header></Header>
        </div>
        <div>
            <div>
                <div class="topbg">
                    <img src="../assets/image/fanlogo.png" class="topbg-logo" alt="">
                    <div class="topbg-box">
                        <img src="../assets/image/fanzhashengming.png" class="topbg-desc" alt="">
                        <div style="font-size: 46px;color: #fff;margin-left: 53px;">精研学堂教育科技(山东)有限公司</div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-content">
                    <div class="topimg" @click="goDe" title="“平安在您身边 全民心防 泉城无诈”反诈宣传活动举行">
                        <img src="../assets/image/subaoimg.png" style="width: 54px;height: 60px;margin-left: 43px;"
                            alt="">
                        <div style="font-size: 18px;font-weight: bold;margin-left: 10px;">速豹新闻：</div>
                        <div style="font-size: 18px;">“平安在您身边 全民心防 泉城无诈”反诈宣传活动举行</div>
                    </div>
                    <div style="margin-top:55px ;">
                        <div style="font-family: Microsoft YaHei;">
                            <div style="font-size: 30px;text-indent: 30px;"> 致广大学员：</div>
                            <div class="textContent">
                                当前，各种网络诈骗犯罪活动猖獗、案件高发，花样繁多，行骗手法隐蔽、多样，已由单纯的电话、短信诈骗发展到了知乎、黑猫投诉、小红书、抖音、微信、微博等多个领域，而且存在向低龄化学生以及家长蔓延的趋势，随时都有可能发生在我们身边，与我们的生活息息相关。
                            </div>
                            <div class="textContent">
                                作为一家培训机构，我们精研学堂教育的很多学员，就曾遭遇过以退费为名头实施的网络诈骗，不仅给我们的学员造成了经济损失，也给公司造成了不良影响，为此，精研学堂教育联合济南市公安局市中分局、山东商报举办了平安在您身边
                                “全民心防 泉城无诈”反诈宣传活动。
                            </div>
                        </div>
                    </div>
                    <div class="videobox" @click="dialogVisible=true" title="平安在您身边·全民心防·泉城无诈">
                        平安在您身边·全民心防·泉城无诈
                    </div>
                    <div>
                        <div style="font-family: Microsoft YaHei;">
                            <div class="textContent"> 在此，我司郑重声明如下：</div>
                            <div class="textContent">
                                我司从未委托任何个人或第三方机构进行任何退费及相关协助工作，任何要求学员用户向个人帐户或非我司指定收款账户转账付款的行为均非我司官方收款行为，为无效付款，请广大用户通过银行、支付宝、微信等渠道转账、支付时认真核实，提高警惕，谨慎辨别，以防上当。切实保障好自己的权益！
                            </div>
                            <div class="textContent">
                                我司特别提醒广大用户，希望大家面对诱惑，要擦亮眼睛明辨是非，<span
                                    style="color: #FF243A;">不要随意添加陌生QQ、微信，不要拉群、进群，更不要相信陌生群内的任何信息，并且要对可能存在诈骗行为的相关QQ、微信及时进行举报和拉黑，避免上当受骗。</span>
                            </div>
                            <div class="textContent">
                                如遇类似或可疑情况，或用户对退款、费用支付等有任何疑问的，请第一时间与我司联系。
                                <!-- 计学院教育官方电话：400-0872229 -->

                            </div>
                            <div class="textContent">
                                特此声明！
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <BottomTab></BottomTab>
        </div>

        <el-dialog title=" "  :visible.sync="dialogVisible" custom-class="customCclass" width="513px">
            <video controls="controls" v-if="dialogVisible" autoplay type="video/mp4" loop="-1"
                src="https://static.shanchuangjiaoyu.com/ycpc/shipin/fanzha.mp4" controlslist="nodownload"
                style="width: 500px; height:800px; object-fit: fill; border-radius: 10px;cursor: pointer;">
            </video>
        </el-dialog>
    </div>
</template>

<script>
import Header from './components/Header.vue'
import BottomTab from './components/BottomTab.vue'
export default {
    components: {
        Header,
        BottomTab
    },
    data() {
        return {
            dialogVisible:false
        }
    },
    methods: {
        goDe() {
            window.open('http://jinan.subaoxw.com/zj/2022/1027/78796.html')
        }
    },
    mounted() {

    },

}
</script>

<style lang="scss" >
.textContent{
    font-size: 18px;margin-top: 10px;text-indent: 30px;color: #4e4e4e;
}
.customCclass{
    background: transparent !important;
    margin: 0 auto;
    box-shadow: none !important;

}
.customCclass .el-dialog__body{
    padding: 0;
}
.customCclass .el-dialog__header{
    display: none;
}
.videobox {
    width: 834px;
    cursor: pointer;

    height: 86px;
    background-image: url('https://shanchuangjiaoyu.oss-cn-beijing.aliyuncs.com/yc_app/mine/jingchavideo.png');
    background-size: 100% 100%;
    margin: 23px 0;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-left: 110px;
    font-weight: bold;
}

.topimg {
    width: 834px;
    height: 86px;
    background: rgba(232, 239, 255, 1);
    color: #1C5EEF;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.box-content {
    width: 834px;
    margin: 0 auto;
}

.box {
    width: 1214px;
    border-radius: 29px 29px 29px 29px;
    background: rgba(255, 255, 255, 1);
    margin: 0 auto;
    border-radius: 50px;
    margin-top: -170px;
    z-index: 9;
    padding-top: 102px;
    padding-bottom: 111px;

    position: relative;
    margin-bottom: 100px;
}

.topbg {
    width: 100%;
    height: 745px;
    position: relative;
    background-image: url('../assets/image/topbg.png');
    background-size: 100% 100%;

    .topbg-logo {
        width: 236px;
        height: 75px;
        position: absolute;
        left: 360px;
        top: 84px;
    }

    .topbg-box {
        position: absolute;
        left: 360px;
        top: 264px;
    }

    .topbg-desc {
        width: 896px;
        height: 199px;

    }
}
</style>