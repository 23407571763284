<template>
  <div>
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading="loading">
      <div class="edit_password">
        <div class="edit_password_text">互联网账号存在被盗风险，建议您定期更改密码以保护账号安全</div>
        <div class="edit_password_text">
          <el-form label-width="80px">
            <el-form-item label="原密码: ">
              <el-input placeholder="请输入原密码" v-model="edit_password_from.old_password">
                <template #suffix>
                  <img style="vertical-align:middle" src="../../../../assets/image/眼睛.png">
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码: ">
              <el-input :type="input_type" placeholder="请输入新密码" v-model="edit_password_from.new_password">
                 <template #suffix>
                    <div @click="input_type_cut">
                      <img v-if="!password_type" style="vertical-align:middle" src="../../../../assets/image/闭眼睛.png">
                      <img v-else style="vertical-align:middle" src="../../../../assets/image/眼睛.png">
                    </div>
                 </template>
              </el-input>
            </el-form-item>
            <el-form-item label="确认密码: ">
              <el-input  :type="input_type" placeholder="请再次输入新密码" v-model="edit_password_from.once_password">
                 <template #suffix>
                    <div @click="input_type_cut">
                      <img v-if="!password_type" style="vertical-align:middle" src="../../../../assets/image/闭眼睛.png">
                      <img v-else style="vertical-align:middle" src="../../../../assets/image/眼睛.png">
                    </div>
                 </template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <button class="confirm_save_btn" @click="confirm_save_btn">
          确认保存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      password_type: false,
      input_type: 'password',
      tabsList: [
        {
          tabs_title: '更改密码'
        }
      ],
      tabs_active: 0,

      // 修改密码表单
      edit_password_from:{
        old_password: '',
        new_password: '',
        once_password: ''
      }
    }
  },
  methods: {
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },

    // 修改密码
    confirm_save_btn() {
      if(!this.edit_password_from.old_password || !this.edit_password_from.new_password || !this.edit_password_from.once_password){
        this.$message.warning('必填项不能为空')
        return
      }
      else if (this.edit_password_from.old_password === this.edit_password_from.new_password) {
        this.$message.warning('修改的密码不能和原密码一样')
        return
      }else if(this.edit_password_from.new_password != this.edit_password_from.once_password) {
        this.$message.warning('两次密码输入不一致')
        return
      } 
      this.loading = true
      this.$axios({
        method: 'POST',
        url: "api/officialWebsite/computer/yuan_cheng/change_password",
        data: {
          ...this.edit_password_from
        }
      }).then(res => {
      this.loading = false
        if (res.code === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo")
          this.$store.dispatch("pullUserInfoByToken")
          // this.$message.success('密码修改成功, 请重新登录')
          this.$notify({
            title: '成功',
            message: '密码修改成功, 请重新登录',
            type: 'success'
          });
          // this.$router.push('/login')
          this.$router.push({
              path:'/index',
              query:{
                backmode:0,
								login_visible: 1
              }
            });
        } else{
          // this.$message.error(`${res.msg}`)
          this.$notify.error({
            title: '错误',
            message: `${res.msg}`
          });
        }
      })
    },
    input_type_cut() {
      if (!this.password_type){
        this.input_type = 'text'
      } else {
        this.input_type = 'password'
      }
      this.password_type = !this.password_type
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  padding: 100px 81px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.edit_password{
  width: 380px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: aqua;
}
.edit_password_text{
  font-size: 13px;
  color: #9B9B9B;
}
.confirm_save_btn{
  width: 150px;
  height: 41px;
  color: #FFFFFF;
  text-align: center;
  line-height: 41px;
  border-radius: 20px 20.8392px 20.8392px 20.8392px;
  background: rgba(0,125,252,1);
}
</style>

<style lang="less" scoped>
/deep/ .el-input__inner {
  width: 276px;
  height: 40px;
  border: none;
  border-radius: 20px 20px 20px 20px;
  background: rgba(231,231,231,1);
}

/deep/ .el-input__suffix{
  right: 45px
}

/deep/ .el-form-item__label{
  width: 60px;
  height: 42px;
  line-height: 42px;
  padding-right: 10px;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  color: #2C2C2C;
  text-align: justify;
}
/deep/ .el-form-item__label::after{
  display: inline-block;
  width: 100%;
  content: ""
}


/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}

</style>