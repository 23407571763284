<template>
  <div>
    <div class="myheader"
      style="background-image: url('https://www.yuanchengedu.net/static/home/img/user_center_bg.jpg')">
      <div class="myheader-wrap flex-top" style="position: relative;">
        <div class="myheader-user flex-middle">
          <div class="myheader-user-headico" @click="edit_userinfo">
            <!--头像框-->
            <!--头像-->
            <img
              :src="$store.state.userInfo && $store.state.userInfo.headico ? $store.state.userInfo.headico : ossUrl + 'touxiang.png'"
              alt="" class="auto-size z_img">

              <!-- 头像挂件 -->
                <img :src="ossUrl + $store.state.userInfo.widget"
                  class="head_pendant_Vipimg"
                  :class="{ 
                    head_pendant_img:$store.state.userInfo && $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 19 || $store.state.userInfo && $store.state.userInfo.honor.id && userInfo.honor.id === 20 || $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 21 || $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 22,
                    hierarchy: $store.state.userInfo && $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 19 || $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 20 || $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 21 || $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 22
                  }"
                  style=""
                  v-if="$store.state.userInfo && !!$store.state.userInfo.widget"
                > 
                <!-- 皇冠 -->
                <!-- <img v-else class="huangguan_img" :src="img_obj.head_pendant ? img_obj.head_pendant : ''">  -->

                <!-- 挂件装饰 -->
                <img v-if="$store.state.userInfo && $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 19 || $store.state.userInfo && $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 21" class="xingxing" src="../../../../assets/image/myhonor/VIP6进步之星-星星.png" alt="">
                <img v-if="$store.state.userInfo && $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 20" class="xingxing" src="../../../../assets/image/myhonor/创意之星-闪电.png" alt="">
                <img v-if="$store.state.userInfo && $store.state.userInfo.honor.id && $store.state.userInfo.honor.id === 22" class="xingxing" src="../../../../assets/image/myhonor/明星学员-明星.png" alt="">
          </div>
          <div class="myheader-user-identity">
            <div class="myheader-user-name">
              <span style="color:#fff;font-size:35px;">{{ userInfo.name }}</span>
              <!-- vip标识 -->
              <img v-if="$store.state.userInfo && !!$store.state.userInfo.honor.bs" style="margin-bottom: 13px; width: 38px; height: 16px;" :src="ossUrl + $store.state.userInfo.honor.bs" alt="">
            </div>
            <!--   <div class="myheader-user-gender">男 | --</div>-->
            <div class="myheader-user-opt flex-middle" style="margin-top:15px;">
              <div class="myheader-user-btn" style="background: rgba(255,255,255,.2); color: #fff;cursor: pointer;" @click="edit_userinfo">
                编辑资料
              </div>
              <div class="myheader-user-btn" style="background: rgba(255,255,255,.2); color: #fff;cursor: pointer;" @click="bind">绑定手机</div>
            </div>
          </div>
          <div style="position: absolute;bottom: 48px;right: 12px;color: #fff;font-size: 20px;" v-if="!!userInfo.zsk_time">
            入学时间:  {{ $common.format(userInfo.zsk_time).y }}年{{ $common.format(userInfo.zsk_time).m }}月{{ $common.format(userInfo.zsk_time).d }}日</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        ossUrl: process.env.VUE_APP_OSS_URL,
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        img_obj: JSON.parse(localStorage.getItem('ima_obj')) ? JSON.parse(localStorage.getItem('ima_obj')) : ''
      }
    },
    methods: {
      // 点击编辑资料
      edit_userinfo() {
        this.$emit('edit_userinfo', true)
        this.$router.push('/personalCenter/userinfo')
      },
      bind() {
        this.$emit('edit_userinfo', true)
        this.$router.push('/personalCenter/bangding_sj')
      }
    },
    watch: {
      '$store.state.userInfo.Name': {
         handler:function(newVal,oldVal){
          console.log(newVal,oldVal);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.head_pendant_img{
  position: absolute !important;
  top: -43px !important;
  left: -80px !important;
}

.head_pendant_Vipimg{
  position: absolute;
  top: -29px;
  left: -26px;
  z-index: 1;
  border-radius: 0 !important
}
.z_img{
  z-index: 1;
  position: absolute;
  top: 2px;
  left: -1px;
}

.hierarchy{
 z-index: 0 !important;
}
.xingxing{
  position: absolute;
  z-index: 99;
  top: -43px;
  left: -77px;
}
</style>