<template>
  <div class="content">
    <!-- 排行榜 -->
    <div class="list_left">
      <div
        class="list_item pointer"
        v-for="(item, index) in listData"
        :key="index"
        @click="click_item(item, index)"
      >
        <img :src="item.img_url" class="lsitImg" />
        <div class="listName">{{ item.title }}</div>
      </div>
    </div>
    <div class="list_right">

      <!-- 琅琊榜 -->
      <div class="hangxiebang">
        <div class="list_right_top">
          <span class="list_right_top_title">精研学堂琅琊榜</span>
          <span class="list_right_top_more" @click="open1">查看全部</span>
        </div>
        <div class="list_right_content">
          <div class="yinying"></div>
          <div class="list_right_content_box">
            <img :src="ossUrl + 'langxiebang.png'" class="langxiebang_img" />
            <div class="list_right_bottom">
              <div class="list_right_content_title">
                高级讲师：带你领教颜色冲击下的愉悦
              </div>
              <div class="list_right_bottom_user">
                <img :src="ossUrl + 'touxiang.png'" class="touxiang" />
                <div class="list_right_bottom_user_box">
                  <div class="list_right_bottom_user_name">高级飞</div>
                  <div class="list_right_bottom_user_qianming">
                    谢谢你，希望你今天开心
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "jobList",
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_URL,
      ossUrl: process.env.VUE_APP_OSS_URL,
      listData: [],
    };
  },
  created() {
    this.getRankingList();
  },
  methods: {
    getRankingList() {
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/talent_center/get_ranking_list"
      }).then((res) => {
          this.listData = res.data;
        }).catch((error) => {
          console.log(error);
        });
    },
  
    click_item(item, index) {
      this.$router.push({ name: "jobListDetail", query: { id: item.id } });

      document.querySelector("#nav").scrollIntoView(true);
    },
    open1() {
      this.$notify({
        title: "暂时没有更多内容了...",
      });
    },
  },
};
</script>

<style  scoped>
.content {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 1800px;
}
.list_left {
  width: 880px;
  margin: 70px 30px 70px 0;
}
.list_item {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 0 30px 0;
  overflow: hidden;
}
.lsitImg {
  /* width: 100%; */
  width: 880px;
  height: 264px;
  object-fit: cover;
}
.listName {
  padding: 20px 15px 25px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.list_right {
  width: 283px;
  margin: 70px 0 0 0;
}
.hots {
  padding: 20px;
  box-sizing: border-box;
  width: 283px;
  height: 365px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 31px;
  position: relative;
}
.hotList {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.hotList img {
  width: 62px;
  height: 62px;
  border-radius: 10px;
}
.hotConent {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.hotConentTitle {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.hotTag {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.hotList ul {
  width: 240px;
}
.hotList li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;
}
.news {
  padding: 20px;
  box-sizing: border-box;
  width: 283px;
  height: 365px;
  background: linear-gradient(#fff2f1, #ffffff);
  border-radius: 10px;
  margin-bottom: 31px;
  position: relative;
}
.newsList {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 283px;
}
.newsList li {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}
.newsList span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  margin-left: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.newsList img {
  width: 34px;
  height: 20px;
}
.newsListBtn {
  margin-top: 24px;
  position: absolute;
  bottom: 0;
}
.topic {
  width: 241px;
  height: 140px;
  background: #fffaf8;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.topicImg {
  display: flex;
  flex-flow: revert;
  align-items: center;
}
.topicImg img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.topicImg span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin-left: 10px;
}
.topicTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin: 10px 0 18px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.topicBtn {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.topicBtn p {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.hangxiebang {
  padding: 20px;
  box-sizing: border-box;
  width: 283px;
  height: 359px;
  background: #ffffff;
  border-radius: 10px;
}
.list_right_news {
  display: flex;
  align-items: baseline;
}
.list_right_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list_right_top_title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}
.list_right_top_time {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-left: 9px;
}
.list_right_top_more {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.list_right_content {
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
}
.yinying {
  position: absolute;
  top: 0;
  /*left: 8px;*/
  width: 249px;
  height: 271px;
  /*background: #e0e0e0;*/
  border-radius: 10px;
  box-shadow: 0px 3px 12px 3px #f0f0f0;
}

.list_right_content_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 249px;
  height: 258px;
  background: #ffffff;
  border-radius: 10px;
}
.langxiebang_img {
  width: 100%;
}
.list_right_bottom {
  padding: 15px;
}
.list_right_content_title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.list_right_bottom_user {
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
}
.touxiang {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
}
.list_right_bottom_user_box {
  /* height: 28px;
		display: flex;
		justify-content: space-between;
		flex-direction: column; */
}
.list_right_bottom_user_name {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.list_right_bottom_user_qianming {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
/deep/ .el-button--info {
  width: 240px;
  height: 29px;
  background-color: #f3f5f7 !important;
  border-radius: 4px !important;
  border-color: #f3f5f7 !important;
  /* line-height: 29px; */
  border: none;
  padding: 0 !important;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999 !important;
}
/deep/ .el-rate {
  transform: scale(0.8);
}
/deep/ .el-rate__icon {
  margin-right: 0;
}
/deep/ .el-tag--mini {
  height: 17px;
  background-color: #fff3ea;
  border-radius: 2px;
  font-size: 8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fc661c;
  line-height: 17px;
  border-color: #fff3ea;
}
/deep/ .el-button {
  padding: 6px 6px;
  border: 2px solid #fc661c;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fc661c;
  background: none;
  border-radius: 0;
}
</style>
<style>
.el-notification {
  margin-top: 100px;
}
</style>
