import axios from "axios";
import router from "../router";
import context from "../store";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
  function (config) {
    if (
      config.url !=
      "https://api.polyv.net/live/v3/channel/common/get-chat-token"
    ) {
      config.headers.common["token"] = localStorage.getItem("token");
      config.withCredentials = true;
    }
    config.headers.common["X-CSRF-Token"] = localStorage.getItem("token");
    if (
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("userInfo"))
    ) {
      config.headers.common["current-user-id"] = JSON.parse(
        localStorage.getItem("userInfo")
      ).id;
    }
    if (config.data && config.data.isTwo) {
      delete config.data.isTwo;
      delete config.headers.common["X-CSRF-Token"];
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
axios.interceptors.response.use((res) => {
  if (
    ((res.data.code === 500 && res.data.msg === "登录凭证已过期!") ||
      res.data.code === 40071) &&
    window.location.hash != "#/Jianzhizhongxin" &&
    window.location.hash !== "#/enter"
  ) {
    context.commit("updateUserInfo", null);
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    router.push({
      path: "/index",
      query: {
        backmode: 0,
        login_visible: 1,
      },
    });
  }
  return res.data;
});
export default axios;
