export const remenTab = [{
	name: '电商',
	id: 1
}, {
	name: '广告设计',
	id: 2 
}, {
	name: '视频剪辑',
	id: 3
}, {
	name: '特效',
	id: 4
}, {
	name: '板绘',
	id: 5
}, {
	name: '平面效果图',
	id: 6
}, {
	name: '人像修图',
	id: 7
}]

export const renwuData = [{
	id: 1,
	title: 'LOGO设计 山人行',
	content: 'logo设计 名称 山人行 主营原生态健康山产品。要求：简单大气耐看，好辨认。可图文结合，也可首拼与文字结合，或首拼组成山的形状，等等，一定要大气，耐看，有特色。大气稳重或简单时尚风格皆可。我们广告语是 行遍千山 寻味自然。',
	name: '壹品艺成',
	label: '平面效果图',
	jiage: '888.00'
}, {
	title: '白酒瓶包装设计',
	content: '请帮我设计一款白酒酒瓶设计，品牌中文叫魁星楼，瓶身包装，白酒礼盒设计，白酒品牌logo设计。我们的广告语叫魁星点斗，独占鳌头。我希望设计风格偏复古贵气，可以增加点国风元素。',
	name: '魁星楼酒业有限公司',
	label: '广告设计',
	jiage: '1198.00',
	id: 2 
}, {
	title: 'TVC广告片剪辑',
	content: '1.负责沟通产品、程序并帮助组内相关人员与产品、程序沟通合；2.负责项目设计部工作任务的安排，人员培训，工作分配，进度控制等工作，保证整体进度合理',
	name: '远程教育科技（山东）有限公司',
	label: '视频剪辑, 特效',
	jiage: '4900.00',
	id: 3
}, {
	title: '电商定制设计',
	content: '高端定制母婴、美妆、护肤、保健食品、洗护日化品的详情页，需要拍摄和精修主图。',
	name: '远程教育科技（山东）有限公司',
	label: '电商, 平面效果图',
	jiage: '1100.00',
	id: 4
}, {
	title: 'PS人像修图抠图字体美工',
	content: '需要用PS进行人像修图和抠图，要求精致无暇，此外需要出一版简单的海报，字体原创。',
	name: '远程教育科技（山东）有限公司',
	label: '人像修图',
	jiage: '650.00',
	id: 5
}, {
	title: '礼物动画特效制作',
	content: '制作礼物的动画特效，svga、打赏、头像框、挂件等，此外需要礼物设计场景定制jso等。',
	name: '未石动画工作室',
	label: '特效, 视频剪辑',
	jiage: '2200.00',
	id: 6
}, {
	title: '三维片头动画制作',
	content: '动画片头时长在一分钟以内，主题内容已经定稿，需要用三维建模软件建模和动画渲染，最后需要视频剪辑软件添加特效。',
	name: '云渊文化有限公司',
	label: '特效, 视频剪辑',
	jiage: '4000.00',
	id: 7
}, {
	title: '环保主题的宣传画',
	content: '要求数位板手绘制作，主题可围绕环保关键词展开自由创作，可适当用PS等修图软件补充画面细节。',
	name: '行上原创传媒有限公司',
	label: '板绘, 平面效果图',
	jiage: '1500.00',
	id: 8
}]

export const listData= [{
	img: 'list1.png',
	name: '第34期排行榜单',
	detail: 'list_detail2.jpg'
},{
	img: 'list2.png',
	name: '第33期排行榜单', 
	detail: 'list_detail1.png'
},{
	img: 'list2.png',
	name: '第33期排行榜单', 
	detail: 'list_detail1.png'
},{
	img: 'list2.png',
	name: '第33期排行榜单', 
	detail: 'list_detail1.png'
},{
	img: 'list2.png',
	name: '第33期排行榜单', 
	detail: 'list_detail1.png'
},{
	img: 'list2.png',
	name: '第33期排行榜单', 
	detail: 'list_detail1.png'
},{
	img: 'list2.png',
	name: '第33期排行榜单', 
	detail: 'list_detail1.png'
}]

// 学员评论
export const pingjiaList = [{
	id: 1,
	hradPor: 'https://static.shanchuangjiaoyu.com/yc_mobile/job/rongxu.jpg',
	name: '荣旭',
	job: '影视特效师',
	test: '短短学习三个月，兼职收入已经达到1200+元，最想要感谢的就是远程教育老师认真负责的态度，他们深深地感染了我，那我到底有什么样的学习心得呢，快来点开看一下吧！'
}, {
	id: 2,
	hradPor: 'https://static.shanchuangjiaoyu.com/yc_mobile/job/jiangchaochen.jpg',
	name: '姜超晨',
	job: '影视特效师',
	test: '不学习永远不知道，从设计小白到独立完成设计项目到底有多顺利，我在远程教育老师们的专业指导之下，进步飞快，现在已经可以用兼职接单的收入，弥补个人的生活费用，如果你也有这样的计划，那就赶快去联系老师报名学习吧！'
}, {
	id: 3,
	hradPor: 'https://static.shanchuangjiaoyu.com/yc_mobile/job/zhuangjialong.jpg',
	name: '庄嘉隆',
	job: '影视特效师',
	test: '远程教育是一个一心一意做教育的学习平台，“伴随式”的学习模式，让我体验到了与老师成为良师益友的过程，学习有了保障，面对困难时才会更加得心应手，感谢相遇，未来我们一起前行！'
}, {
	id: 4,
	hradPor: 'https://static.shanchuangjiaoyu.com/yc_mobile/job/shijinlu.jpg',
	name: '史金璐',
	job: '影视特效师',
	test: '我刚开始做兼职的时候，还无法达到客户的要求，老师对我的作品做了专业分析和指导之后，渐渐有了门路，同样我的设计功底也变得更加扎实，感谢老师在这里我衷心的祝愿远程教育飞黄腾达蒸蒸日上！'
}]

// 兼职作品  标签
export const dataTabList = ['全部', '电商', '广告', '剪辑', '特效', '板绘', '平面效果图', '人像修图']

// 兼职作品
export const zuopinList_jianzhi = [
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin1.jpg',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin2.jpg',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin3.jpg',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin4.jpg',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin5.png',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin6.png',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin7.png',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin8.png',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin9.jpg',
	'https://static.shanchuangjiaoyu.com/yc_mobile/job/jianzhizuopin10.jpg',
]

// 讲师作品
export const zuopinList_jiangshi = [
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin01.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin02.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin03.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin04.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin05.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin06.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin07.jpg',
	'https://static.shanchuangjiaoyu.com/ycpc/job/jiangshizuopin08.jpg',
]