<template>
  <div>
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info">
      <div class="wxBox">
        <img src="../../../../assets/image/底图.png">

        <!-- 绑定二维码 -->
        <div class="vx_QRcode" v-show="!unionid">
				  <div id="wx_banding" ref="wx_bandingRef"></div>
		    </div>

        <!-- 扫描成功蒙层 -->
        <!-- <div class="success_mask">
          <div class="success_mask_flex">
            <img src="../../../../assets/image/vx对 1.png" alt="">
            <div>扫描成功</div>
          </div>
        </div> -->

        <!-- 未扫描之前  右侧文字 -->
        <div class="success_right_text" v-if="!unionid">
          请打开微信扫一扫绑定
        </div>

        <!-- 绑定成功 微信信息 -->
        <div class="xvInfo_mask" v-if="unionid">
           <div class="success_mask_flex">
            <img src="../../../../assets/image/xv.png" alt="" style="margin-top: 10px; margin-left: 32px;">
            <div class="vx_mark">绑定成功</div>
          </div>
        </div>


        <!-- 扫描成功右侧文字 -->
        <!-- <div class="success_right_text">
          请在微信中<br>
          轻触<span style="font-weight: 700;">同意</span>即可绑定成功
        </div> -->

        <!-- 绑定成功 右侧微信信息 换绑 -->
        <div class="success_right_text" v-if="unionid">
         <!-- 当前绑定的微信: {{ '123456156423' }}<br/> -->
         <button class="unbind_vx_btn" @click="unbind_vx_btn">解绑</button>
        </div>
      </div>

      <!-- 解绑dialog -->
      <el-dialog :visible.sync="unbind_visible" class="unbind_dialog" width="361px" :show-close="false">
        <div class="unbind_dialog_text">
          确定解绑当前微信?
        </div>
        <div class="unbind_dialog_btn">
          <button class="unbindVx_dialog_btn" @click="ensure_ensure">确定</button>
          <button class="unbindVx_dialog_btn unbindVx_cancel_btn" @click="unbind_visible = false">取消</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
       unbind_visible: false,
      tabsList: [
        {
          tabs_title: '绑定微信'
        }
      ],
      tabs_active: 0,

      // 是否绑定了微信
      unionid: JSON.parse(localStorage.getItem("unionid")),
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
    }
  },
  methods: {
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },
    //生成二维码
    creatQrCode(e) {
      console.log(e);
      var qrcode = new QRCode(this.$refs.wx_bandingRef, {
        text: e, // 需要转换为二维码的内容
        width: 130,
        height: 130,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 微信二维码
    bind_vx() {
      var href = 'data:text/css;base64,LmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiAgICBkaXNwbGF5OiBub25lOwp9CgouaW1wb3dlckJveCAudGl0bGUgewogICAgZGlzcGxheTogbm9uZTsKfQoKLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMTI5cHg7CiAgIG1hcmdpbi10b3A6IDA7CiAgIGJvcmRlcjpub25lOwp9Ci5pbXBvd2VyQm94IC53cnBfY29kZXsKIHdpZHRoOiAxMjlweAp9CgouaW1wb3dlckJveCAuc3RhdHVzewogIHBhZGRpbmc6IDI4cHg7CiAgcG9zaXRpb246IGFic29sdXRlOwoJbGVmdDogMDsKICB0b3A6IDA7CiAgY29sb3I6ICNGRkZGRkY7CiAgYm9yZGVyLXJhZGl1czogMTBweDsKICBvdmVyZmxvdzogaGlkZGVuOwoJYmFja2dyb3VuZC1jb2xvcjogIzBGNkZFRDsKCS8q6YCP5piO5bqmKi8KCW9wYWNpdHk6IDAuOTsgCn0KCi5pbXBvd2VyQm94IC5pbmZvIHsKICB3aWR0aDogMTI5cHg7CiAgaGVpZ2h0OiAxMjlweDsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgdG9wOiAwOwogIGxlZnQ6IDA7Cn0KCi53YWl0aW5nIC5wYW5lbENvbnRlbnR7CiAgd2lkdGg6IDEyOXB4OwogIGhlaWdodDogMTI5cHg7Cn0KCi5zdGF0dXNfdHh0IHAgewogIGRpc3BsYXk6IG5vbmU7Cn0KCi5pbXBvd2VyQm94IC5pY29uMzhfbXNnLnN1Y2N7CiAgbWFyZ2luLWxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoLTUwJSk7Cn0='
      var obj = new WxLogin({
        self_redirect:false,
        id:"wx_banding", 
        appid: process.env.VUE_APP_XV_APPID,
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(window.origin + '/new/#/personalCenter/bindingVX'),
        state: this.userInfo.id ? this.userInfo.id : '',
        style: "black",
        href: href
      });
    },
    // 解绑vx
    unbind_vx_btn() {
        this.unbind_visible = true
    },
    // 确定解绑
    ensure_ensure() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/we_chat_remove_bind'
      }).then(res => {
        if(res.code === 200) {
          localStorage.removeItem("unionid")
          this.$notify({
            title: '成功',
            message: '成功解绑',
            type: 'success'
          });
          location.reload()
        }else {
          this.$notify.error({
          title: '提示',
          message: `${res.msg}`
        });
        }
        this.loading = false
        this.unbind_visible = false
      })
    }
  },
  mounted () {
    if (!this.unionid) {
      this.bind_vx()
    }
  },

  watch: {
    $route(to,from) {
      let _this = this;
      if (this.$route.query.code) {
        this.$axios({
          method: "POST",
          url: "/api/officialWebsite/auth/authorized_wechat_college_official_website",
          data: {
            code: _this.$route.query.code,
            state: _this.userInfo.id ? _this.userInfo.id : ''
          }
        }).then((res) => {
          if (res.code === 200) {
            console.log(res + 'res');
            this.unionid = res.data.unionid
            localStorage.setItem("unionid", JSON.stringify(res.data.unionid));
            // localStorage.setItem("token", JSON.stringify(res.data.token));
            this.$store.dispatch("pullUserInfoByToken")
            this.$notify({
              title: '成功',
              message: '绑定成功',
              type: 'success'
            });
            // location.reload()
          }else{
            this.$notify.error({
              title: '提示',
              message: `${res.msg}`
            });
          }
        })
      }
  }
}
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  padding: 0 150px;
  background-color: #FFFFFF;
}

.wxBox{
  position: relative;
}

.vx_QRcode{
  width: 129px;
  height: 129px;
  position: absolute;
  left: 159px;
  top: 226px;
  // background-color: #000000;
}


.success_mask{
	z-index:1000;
	position: absolute;
	left: 159px;
  top: 226px;
	background-color: #074DFE;
	/*透明度*/
	opacity: 0.7; 
	text-align: center;
	vertical-align: middle;
}

.xvInfo_mask{
  width: 129px;
  height: 129px;
	z-index:1000;
	position: absolute;
	left: 159px;
  top: 226px;
	background-color: #FFFFFF;
	/*透明度*/
	opacity: 0.7; 
	text-align: center;
  
	vertical-align: middle;
}

.success_mask_flex{
  height: 129px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.vx_mark{
  position: absolute;
  left: 31px;
  top: 88px;
}

.success_right_text{
  font-size: 20px;
  position: absolute;
  left: 335px;
  top: 227px;
  color: #FFFFFF;
}

.unbind_vx_btn{
  width: 44px;
  height: 20px;
  border-radius: 10px;
  color: #007DFC;
  background: rgba(255,255,255,1);
}


// .impowerBox .status{
//   padding: 28px;
//   position: absolute;
// 	left: 0;
//   top: 0;
//   color: #FFFFFF;
//   border-radius: 10px;
//   overflow: hidden;
// 	background-color: #074DFE;
// 	/*透明度*/
// 	opacity: 0.9; 
// }

// .impowerBox .info {
//   width: 129px;
//   height: 129px;
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// .waiting .panelContent{
//   width: 129px;
//   height: 129px;
// }

// .status_txt p {
//   display: none;
// }

// .impowerBox .icon38_msg.succ{
//   margin-left: 50%;
//   transform: translateX(-50%);
// }
</style> 

<style lang="less" scoped>
 /deep/ .unbind_dialog .el-dialog {
    height: 252px;
    margin-top: 40vh !important;
    border-radius: 22px;
    background: rgba(255,255,255,1);
    position: relative;
    z-index: 10;
    filter: drop-shadow(0px -1px 1px #F5F5F5);
  }
 /deep/ .unbind_dialog_text  {
    font-size: 22px;
    color: #5A5A5A;
    text-align: center;
    padding: 0 0 75px 0;

 }
 .unbind_dialog_btn{
  display: flex;
  justify-content: space-between;
 }
 .unbindVx_dialog_btn{
  width: 98px;
  height: 43px;
  border-radius: 21px;
  text-align: center;
  line-height: 43px;
  font-size: 20px;
  color: #FFFFFF;
  background: rgba(0,125,252,1);
 }
 
 .unbindVx_cancel_btn{
  background: rgba(164,164,164,1);
 }
 /deep/ .el-dialog__body {
  padding: 30px 40px;
 }
</style>
