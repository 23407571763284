import axios from "axios";
import router from "../router";
import context from "../store";
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});
request.defaults.timeout = 10000;
request.defaults.withCredentials = true;
//axios.defaults.xsrfHeaderName = null;
//axios.defaults.xsrfCookieName = "token";
//axios.defaults.xsrfHeaderName = "token";
// axios.defaults.headers.common['token'] = localStorage.getItem("token")
request.interceptors.request.use(
  function (config) {
    config.headers.common["token"] = localStorage.getItem("token");
    config.headers.common["X-CSRF-Token"] = localStorage.getItem("token");
    if (
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("userInfo"))
    ) {
      config.headers.common["current-user-id"] = JSON.parse(
        localStorage.getItem("userInfo")
      ).id;
    }
    if (config.data && config.data.isTwo) {
      delete config.data.isTwo;
      delete config.headers.common["X-CSRF-Token"];
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
request.interceptors.response.use((res) => {
  return res.data;
});
export default request;
