<template>
	<div class="">
		<!-- 底部悬浮窗 -->
		<div v-if="isShow" :style="$hasGray?'filter: grayscale(1);':''" style="position: fixed;bottom:0;width: calc(100% - 17px);z-index: 999; display: none;">
			<div style="background: rgba(30,22,23,0.9);width: 100%;height: 100px;">
				<div style="width: 1200px;height: 100%; position: relative;; margin: 0 auto;">
					<div style="width: 100%;height: 100%; position: absolute; bottom: 0;">
						<div class="bottom_box"
							style=" position: absolute; bottom: 0; left: 140px; height: 100%;display: flex;align-items: center;">
							<div style="color: #FFFFFF;font-size: 20px;font-weight: bold;letter-spacing:2px;">
								立即免费领取<span style="color: #3C71FF;font-size: 30px;font-weight: bold;">0基础30节</span>直播课
							</div>
							<el-select style="height: 52px;margin: 0px 20px 0 32px;" v-model="form.kecheng"
								placeholder="请您选择课程">
								<el-option v-for="(item, index) in form.kechengOption" :key="index" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
							<el-input v-model="form.tel" type="text" maxlength="11"
								style="margin: 0px;width: 190px;height: 52px;" placeholder="请输入您的手机号">
							</el-input>
							<div @click="dialogVisible = true; form.next = false" class="pointer"
								style="background-color: #3C71FF;color: #FFFFFF;text-align: center;border-radius: 10px;width: 190px;height: 52px;line-height: 52px;margin: 0 0 0 20px;font-size: 18px;">
								立即领课</div>
						</div>

					</div>
					<!-- <img style="position: absolute;bottom: 0; left: 0; width: 105px;" :src="laoshiImg"> -->
				</div>
			</div>

		</div>

		<!-- 弹窗：领取课程 -->
		<div class="jiaru">
			<el-dialog title="" :visible.sync="dialogVisible" width="856px" center :show-close="false"
				class="my-info-dialog" :style="$hasGray?'filter: grayscale(1);':''">
				<div style="width: 857px;height: 567px;position: relative;top: 0; left: 50%; margin-left: -428px;">
					<img style="width: 100%;height: 100%;"
						src="https://juntongoss.oss-cn-beijing.aliyuncs.com/data/jingrui%402x.png">
					<div style="position: absolute;left: 50%;right: 0;top: 80px;text-align: center;z-index: 999;">
						<!-- <img :src="form.closeUrl" @click="dialogVisible1 = false"
							style="cursor: pointer; width: 18px; height: 18px;object-fit: cover; position:absolute;right: 10px;"> -->
						<div style="color: #0D1E3D;font-size: 32px;font-weight: bold;padding-bottom: 24px;">免费领取</div>
						<div style="color: #0D1E3D;font-size: 44px;font-weight: bold;padding-bottom: 46px;"><span
								style="color: #074DFE;">0基础30节</span>直播课</div>
						<div style="padding: 0 50px; text-align: left;" v-if="form.next">
							<div class="next_span" style="margin: 0 0 10px 0;">您的验证码已通过短信发送到</div>
							<div class="next_span">{{form.tel.replace(form.tel.substr(3, 4), "****")}}</div>
							<div class="yanzhengmaBox">
								<el-input class="yanzhengma_input" v-model="form.code" style="margin-bottom: 50px;"
									placeholder="请输入验证码"></el-input>
								<div class="yanzhengma_get pointer" @click="getCode">{{form.send}}</div>
							</div>
							<el-button class="pointer" @click="submit"
								style="width: 346px;height: 60px;border-radius: 30px;background-color: #074DFE;color: #FFFFFF;font-size: 20px;"
								type="primary" round>最后一步，立即领取</el-button>
						</div>
						<div v-else>
							<div class="popupInput">
								<el-select style="margin-bottom: 22px;" v-model="form.kecheng" placeholder="请您选择课程">
									<el-option v-for="(item, index) in form.kechengOption" :key="index"
										:label="item.name" :value="item.id">
									</el-option>
								</el-select>
								<el-input v-model="form.tel" type="text" maxlength="11" style="margin-bottom: 50px;"
									placeholder="请输入您的手机号"></el-input>

								<el-button @click="go_next"
									style="width: 346px;height: 60px;border-radius: 30px;background-color: #074DFE;color: #FFFFFF;font-size: 22px;"
									 round>立即领课</el-button>
							</div>
						</div>

						<!-- <div style="display: flex;justify-content: space-around;padding-top: 18px;">
							<div style="display: flex;">
								<img @click="click_check"
									style="width: 16px;height: 16px;margin-right: 10px;cursor:pointer;"
									:src="form.isCheck ? 'https://static.shanchuangjiaoyu.com/ycpc/assets/queren@2x.png' : 'https://static.shanchuangjiaoyu.com/ycpc/assets/un_queren@2x.png'">
		
								<div style="color: #999999;font-size: 12px;line-height: 16px;">登录注册表示您已阅读并同意<span
										style="color: #074DFE;">《用户服务协议》</span>及<span
										style="color: #074DFE;">《隐私政策》</span></div>
							</div>
						</div> -->
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	export default {
		name: 'suspension',
		props: ['isShow'],
		data() {
			return {
				dialogVisible: false,
				form: {
					kechengOption: [],
					kecheng: '',
					tel: '',
					isCheck: false,
					next: false,
					code: '',
					send: "获取验证码",
					timer: null,
					closeUrl: process.env.VUE_APP_OSS_URL + 'close.png'
				},
				laoshiImg: process.env.VUE_APP_OSS_URL + 'laoshi.png',
			}
		},
		created() {
			this.getData();
			Bus.$on('open_sus', () => {
				// console.log('open_sus接收到命令')
				this.dialogVisible = true;
			})
		},
		methods: {
			getData() {
				//获取课程下拉列表
				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/course_selection_package_list"
				}).then((res) => {
					if (res.code == 1 || res.code == 200) {
						this.form.kechengOption = res.data;
					} else {
						this.$message.error(res.msg)
					}
				}).catch((error) => {
					console.log(error);
				});
			},
			go_next() {
				// console.log('课程：' + this.form.kecheng + '手机号:' + this.form.tel)
				if (!this.form.kecheng) {
					return this.$message({
						message: '请选择课程',
						type: 'error',
						offset: '100'
					});
				}
				if (!this.form.tel) {
					return this.$message({
						message: '请输入手机号',
						type: 'error',
						offset: '100'
					});
					// return this.$message.error('请输入手机号')
				} else if (this.form.tel.length != 11) {
					return this.$message({
						message: '请输入正确的手机号',
						type: 'error',
						offset: '100'
					});
					// return this.$message.error('请输入正确的手机号')
				}
				this.getCode();
				this.form.next = true;
			},
			getCode() {
        // return;
				let time = 60;
				if (this.form.timer) return

				this.form.timer = setInterval(() => {
					if (time > 0) {
						time--;
						this.form.send = time;
					} else {
						clearInterval(this.form.timer);
						this.form.timer = null;
						this.form.send = "获取验证码";
					}
				}, 1000)

				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/send_code",
					data: {
						tel: this.form.tel,
						type: 1
					}
				}).then((res) => {
					if (res.code == 1 || res.code == 200) {

					} else {
						this.$message({
							message: res.msg,
							type: 'error',
							offset: '100'
						});
						// this.$message.error(res.msg)
						setTimeout(() => {
							clearInterval(this.form.timer);
							this.form.timer = null;
							this.form.send = "获取验证码";
						}, 3000)
					}
				}).catch((error) => {
					console.log(error);
				});
			},
			submit() {
				if (!this.form.code) {
					return this.$message({
						message: '请输入验证码',
						type: 'error',
						offset: '100'
					});
					// return this.$message.error('请输入验证码')
				}
				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/submit_mobile",
					data: {
						telphone: this.form.tel,
						coursebase_id: this.form.kecheng,
						code: this.form.code
					},
				}).then((res) => {
					if (res.code == 1 || res.code == 200) {
						this.dialogVisible = false;
						this.$notify({
						    title: '成功',
						    message: res.msg || res.message,
						    type: 'success',
							position: 'top-left'
						});
					} else {
						this.$notify.error({
							title: '提示',
						    message: res.msg || res.message,
							position: 'top-left'
						});
					}
				}).catch((error) => {
					console.log(error);
				});
			},
		}

	}
</script>

<style scoped>
	.next_span {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin: 0 0 30px;
	}

	.yanzhengmaBox {
		display: flex;
		justify-content: space-between;
	}

	.yanzhengma_get {
		width: 116px;
		height: 60px;
		background: #074DFE;
		border-radius: 10px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
	}

	.yanzhengma_input {
		width: calc(100% - 126px) !important;
	}
</style>
<style type="text/css">
	.bottom_box .el-input__inner {
		
		border-radius: 10px;
		padding: 0 15px !important;
		box-sizing: border-box;
	}


	.jiaru .el-dialog {
		background: rgba(255, 255, 255, 0) !important;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	}

	.jiaru .el-input__inner {
		height: 60px;
		border-radius: 10px;
		padding: 0 15px !important;
		box-sizing: border-box;
	}

	.my-info-dialog .el-dialog,
	.el-pager li {
		background: none;
	}

	.my-info-dialog .el-dialog {
		box-shadow: none;
	}

	.popupInput {
		width: 346px;
		border-radius: 30px;
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		margin: 0 auto;
	}
</style>
