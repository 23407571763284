<template>
  <div class="childTabs">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style lang="scss" scoped>
.childTabs {
  display: flex;
}


// .childTabs_slot {
//   width: 68px;
//   height: 17px;
//   text-align: center;
//   margin-right: 25px;
// }
</style>