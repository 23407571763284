<template>
  <div class="index">
    <div style="">
      <img style="" src="../../assets/dangzhibu_banner.jpg" alt="">
    </div>
    <div style="width: 66%; margin: auto; margin-top: 66px">
      <div style="display: flex; justify-content: space-between">
        <div>
          <div style="font-size: 26px; font-weight: bold">{{detail.title}}</div>
        </div>
        <div>
          <span>当前位置：</span>
          <span @click="wangzhanshouye" style="cursor: pointer">网站首页</span>
          <span style="margin: 0 6px">></span>
          <span style="color: #000000; font-weight: bold">正文</span>
        </div>
      </div>
      <div style="margin-top: 26px">
        <div v-html="detail.text"></div>
      </div>
    </div>
    <div>
      <div style="background-color: #b5282e; text-align: center; height: auto">
        <div style="padding: 20px; color: #fff">
          <p style="line-height: 26px">版权所有：精研学堂教育党委宣传部（新闻中心）</p>
          <p style="line-height: 26px">地址: 济南市历城区荣盛时代广场A座25楼    邮编: 250200</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  DZB_yaowen_1,
  DZB_yaowen_2,
  DZB_yaowen_3,
  DZB_yaowen_4,
  DZB_yaowen_5,
  DZB_yaowen_6,
  DZB_dongtai_1,
  DZB_dongtai_2,
  DZB_dongtai_3,
  DZB_dongtai_4,
  DZB_dongtai_5,
  DZB_dongtai_6,
  DZB_fengcai_1,
  DZB_fengcai_2,
  DZB_fengcai_3,
  DZB_fengcai_4,
  DZB_fengcai_5,
  DZB_fengcai_6,
  DZB_fengcai_7,
  DZB_fengcai_8,
  DZB_fengcai_9,
  DZB_fengcai_10,
  DZB_fengcai_11,
  DZB_fengcai_12,
  DZB_lunshu_1,
  DZB_lunshu_2,
  DZB_lunshu_3,
  DZB_lunshu_4,
  DZB_lunshu_5,
  DZB_lunshu_6,
  DZB_lunshu_7,
  DZB_jiedu_1,
  DZB_jiedu_2,
  DZB_jiedu_3,
  DZB_jiedu_4,
  DZB_jiedu_5,
  DZB_jiedu_6,
  DZB_jiedu_7,
  DZB_jiedu_8,
  DZB_jiedu_9,
} from '../../assets/js/DZB_detail.js'
export default {
  data() {
    return {
      DZB_yaowen_1,
      DZB_yaowen_2,
      DZB_yaowen_3,
      DZB_yaowen_4,
      DZB_yaowen_5,
      DZB_yaowen_6,
      DZB_dongtai_1,
      DZB_dongtai_2,
      DZB_dongtai_3,
      DZB_dongtai_4,
      DZB_dongtai_5,
      DZB_dongtai_6,
      DZB_fengcai_1,
      DZB_fengcai_2,
      DZB_fengcai_3,
      DZB_fengcai_4,
      DZB_fengcai_5,
      DZB_fengcai_6,
      DZB_fengcai_7,
      DZB_fengcai_8,
      DZB_fengcai_9,
      DZB_fengcai_10,
      DZB_fengcai_11,
      DZB_fengcai_12,
      DZB_lunshu_1,
      DZB_lunshu_2,
      DZB_lunshu_3,
      DZB_lunshu_4,
      DZB_lunshu_5,
      DZB_lunshu_6,
      DZB_lunshu_7,
      DZB_jiedu_1,
      DZB_jiedu_2,
      DZB_jiedu_3,
      DZB_jiedu_4,
      DZB_jiedu_5,
      DZB_jiedu_6,
      DZB_jiedu_7,
      DZB_jiedu_8,
      DZB_jiedu_9,
      detail: null,

    }
  },
  created() {
    let type = window.localStorage.getItem('actIndx');
    if(this.$route.params.type !== undefined) {
      this.detail = this.$route.params.type
    } else {
      this.detail = JSON.parse(window.localStorage.getItem('actIndx'))
    }

  },
  methods: {
    wangzhanshouye() {
      this.$router.go(-1)
      console.log(1111)
    }
  }
}
</script>