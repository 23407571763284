<template>
  <div class="myCenter">
    <!-- 个人中心 top -->
    <myHeader @edit_userinfo="edit_userinfo"></myHeader>
    <!-- 个人中心 top -->


    <!-- 详情主体 -->
    <myContent :myContent_userinfo="myContent_userinfo" @userinfo="myContent_userinfo = $event"></myContent>
    <!-- 详情主体 -->

  </div>
</template>

<script>
import myHeader from './components/myHeader.vue'
import myContent from './components/myContent.vue'
export default {
  data() {
    return {
      myContent_userinfo: false,
      // token: localStorage.getItem('token')
    }
  },
  components: {
    myHeader,
    myContent
  },
  methods: {
    edit_userinfo(event) {
      this.myContent_userinfo = event
    }
  },
   beforeRouteEnter(to,from,next){
    next(vm => {
      console.log(to.fullPath);
      if(!vm.backUrl){
        vm.$router.push(to.fullPath)
      }
    })
  },
  // created () {
  //   if(window.name == ""){
  //     console.log("首次被加载");
  //     window.name = "isReload";  // 在首次进入页面时我们可以给window.name设置一个固定值 
  //   }else if(window.name == "isReload"){
  //     console.log("页面被刷新");
  //     this.$router.push(this.$route.fullPath)
  //     console.log(this.$route.fullPath);
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import './center_css/basecss.css';
@import './center_css/myHeader.css';
@import './center_css/myCourse.css';

.myCenter {
  background-color: #F7F7F7;
}


</style>
