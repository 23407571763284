<template>
	<div class="content">
		<!-- 排行榜详情 -->
		<div class="loadingShow" v-loading="loading" v-if="loading"></div>
		<div class="detailBox" v-html="details" v-else></div>
	</div>
</template>

<script>
	export default {
		name: "jobListDetail",
		data() {
			return {
				loading: false,
				id: null,
				details: {}
			};
		},
		created() {
			this.id = this.$route.query.id;
			this.loading = true;
			this.getData();
		},
		methods: {
			getData() {
				this.$axios({
						method: "GET",
						url: "/api/officialWebsite/talent_center/get_ranking_detail",
						params: {
							ranking_id: this.id
						}
					})
					.then((res) => {
						this.loading = false;
						this.details = res.data.detailed;
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	};
</script>

<style scoped>
	.content {
		background-color: #f5f5f5;
		text-align: center;
	}

	.loadingShow {
		min-height: 500px;
	}
</style>
<style type="text/css">
	.detailBox img {
		width: 1200px;
		vertical-align: top;
	}
</style>
