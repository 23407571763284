import { render, staticRenderFns } from "./childTabs.vue?vue&type=template&id=44afd1c5&scoped=true&"
import script from "./childTabs.vue?vue&type=script&lang=js&"
export * from "./childTabs.vue?vue&type=script&lang=js&"
import style0 from "./childTabs.vue?vue&type=style&index=0&id=44afd1c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44afd1c5",
  null
  
)

export default component.exports