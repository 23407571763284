<template>
  <div>
    <div class="tabs">
     <myTabs :isxuefen="true" :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading = 'loading'>
      <div class="myXueFen_top public_margin" style="cursor: default;">
        <div>总学分: {{ integral_total }}</div>
        <div @click="credit_purpose_btn" style="vertical-align:middle;display: flex; align-items: center;">
          学分用途&nbsp; <img style="vertical-align:middle;" src="../../../../assets/image/yongtu.png" alt="">
        </div>
      </div>
      <!-- 任务 -->
      <div class="myXueFen_new_task public_margin" v-for="(item, index) in myXueFenData" :key="item.id">
        <div class="myXueFen_new_task_left">
          {{ index === 0 ? '新手任务' : index === 1 ? '日常任务' : '分享任务' }}
        </div>
        <div class="myXueFen_new_task_right" >
          <!-- 子任务 -->
          <div class="myXueFen_new_task_right_A" v-for="(val,index) in item" :key="val.id" :class="{ no_border: index === item.length - 1, pTop: index === 0 }">
            <div class="myXueFen_new_task_right_A_left">
              {{ val.title }}
            </div>
            <div class="myXueFen_new_task_right_A_right">
              <div class="task_xuefen_num">
                <img src="../../../../assets/image/学分.png" style="vertical-align:middle;">
                <span style="vertical-align:middle; color: #FD6D6C;"> + {{ val.integral }}</span>
              </div>

                <button class="get" v-if="!val.receive && val.able" @click="get_xuefen(val.id, val.type)">领取</button>
                <button class="yet_get" @click="isXuefen" v-if="val.receive">已领取</button>
                <button class="no_get" @click="noXuefen" v-if="!val.able">未完成</button>

            </div>
           <!-- 新手任务进度 -->
          </div>
               <div class="myXueFen_new_task_right_schedule" v-if="index === 0">
                  <div class="myXueFen_new_task_right_schedule_top"><span style="font-size: 18px;">新手任务进度</span> <span style="color:#777777">· 新手任务完成领取额外奖励</span></div>
                  <div class="myXueFen_new_task_right_schedule_bottom">
                    <schedule inputBgc_A="#FD6D6C" inputBgc_B="#FD6D6C" :inputValue="schedule_value">
                      <div class="schedule_jiangli">
                        <img class="schedule_jiangli_img" src="../../../../assets/image/jiangli2.png" alt=""  >
                      </div>
                      <p @click="new_additional_xuefen">领取奖励</p>
                    </schedule>
                  </div>
                </div>
        </div>
      </div>
    </div>

    <el-dialog title="学分用途" :visible.sync="credit_purpose_visible" width="200">
      <div>
        <div class="credit_purpose_text_title">学分可用于兑换礼物</div>
        <div class="credit_purpose_text">用学分兑换鲜花，用于上课的时候给喜欢的老师发送礼物，礼物可选择康乃馨、博士帽、奖杯、咖啡。</div>
      </div>
      <div class="credit_purpose_text_title_margin">
        <div class="credit_purpose_text_title">学分可用于兑换优惠券</div>
        <div class="credit_purpose_text">购买课程的时候，可以用优惠券抵用部分现金，优惠券可以通过学分兑换。</div>
      </div>
      <div class="credit_purpose_text_title_margin">
        <div class="credit_purpose_text_title">学分可用于购买画材</div>
        <div class="credit_purpose_text">购买课程所需要的画材，可以用学分抵用部分金额。</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      integral_total: JSON.parse(localStorage.getItem("userInfo")).integral_total,
      loading: false,
      tabsList: [
        {
          tabs_title: '学分任务'
        }
      ],
      tabs_active: 0,

      myXueFenData: [],

      credit_purpose_visible: false  // 学分dialog
    }
  },
  methods: {
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },

    // 领取学分
    get_xuefen(id, type) {
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/receive_integral',
        data: {
          id: id,
          cate: type === 3 ?  6 : type    // 3 改成 6分享积分
        }
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.$store.dispatch("pullUserInfoByToken")
          this.integral_total = JSON.parse(localStorage.getItem("userInfo")).integral_total,
          this.getXueFenData()
        }
      })
    },

    // 获取学分的所有数据
    getXueFenData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_credits'
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.myXueFenData = res.data
        }
      })
    },

    // 学分用途
    credit_purpose_btn() {
      console.log('credit_purpose_btn');
      this.credit_purpose_visible = true
    },
    // 新手额外学分
    new_additional_xuefen() {
      // console.log(this.schedule_value);
      if (this.schedule_value >= 100) {
        this.loading = true
        this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/receive_integral',
        data: {
          id: 100,
          cate: 3    
        }
        }).then(res => {
          if (res.code === 200) {
            this.$store.dispatch("pullUserInfoByToken")
            this.integral_total = JSON.parse(localStorage.getItem("userInfo")).integral_total,
            this.getXueFenData()
          }else {
            this.$notify.error({
              title: '提示',
              message: `${res.msg}`
            });
          }
          this.loading = false
        })
      }else {
        this.$notify.error({
          title: '提示',
          message: `未达到领取条件`
        });
        this.loading = false
      }
    },
    isXuefen() {
      this.$notify({
        title: '提示',
        message: '该任务学分已领取, 请勿重复领取',
        type: 'warning'
      });
    },
    noXuefen() {
      this.$notify({
        title: '提示',
        message: '该任务未完成, 暂不可领取',
        type: 'warning'
      });
    }
  },
  created () {
    this.getXueFenData()
  },

  computed: {
    // 新手任务进度值
    schedule_value() {
      let sum = []
      let scheduleValue = 0
      this.myXueFenData.forEach((item, i) => {
        console.log(item);
        if (i === 0) {
          sum = item.map(val => {
            return val.receive
          })
        }
      })
      let num = sum.filter(Boolean).length
      if (num === 1) {
        scheduleValue = Math.ceil(100 / 3)
      } else if (num === 2) {
        scheduleValue = Math.ceil(100 / 3 * 2)
      } else if (num === 3) {
        scheduleValue = 100
      }
      return scheduleValue
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  padding: 30px 27px;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.public_margin {
  margin-bottom: 35px;
}

.myXueFen_top {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 955px;
}

.myXueFen_new_task,
.myXueFen_daily_task {
  width: 955px;
  min-height: 326px;
  padding: 40px;
  // display: flex;
  border-radius: 16px 16px 16px 16px;
  background: rgba(249, 249, 249, 1)
}

.myXueFen_new_task_left,
.myXueFen_daily_task_left {
  margin-right: 40px;
  // width: 41px;
  // height: 163px;
  // line-height: 41px;
  font-size: 24px;
  font-weight: Bold;
  font-weight: 700;
  color: #303030;
}

.myXueFen_new_task_right,
.myXueFen_daily_task_right {
  display: flex;
  width: 784px;
  min-height: 251px;
  margin-left: 50px;
  margin-top: 20px;
  flex-direction: column;
  justify-content: space-between;
}
.myXueFen_task_right_underline{
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
}
.myXueFen_new_task_right_A,
.myXueFen_new_task_right_B,
.myXueFen_new_task_right_C,
.myXueFen_daily_task_right_A {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #EEEEEE;
}
.pTop{
  padding-top: 0;
}
.no_border{
  border: none;
}

.myXueFen_new_task_right_A_left,
.myXueFen_new_task_right_B_left,
.myXueFen_new_task_right_C_left,
.myXueFen_daily_task_right_A_left{
  font-size: 18px;
}
.myXueFen_new_task_right_A_right,
.myXueFen_new_task_right_B_right,
.myXueFen_new_task_right_C_right,
.myXueFen_daily_task_right_A_right {
  display: flex;
  align-items: center;
}
.task_xuefen_num,
.daily_task_num{
  margin-right: 50px;
}

.myXueFen_new_task_right_schedule{
  // display: flex;
  border-top: 1px solid #EEEEEE;
  padding-top: 20px;
}

.myXueFen_new_task_right_schedule_top{
  margin: 5px 0;
}
.myXueFen_new_task_right_schedule_bottom{
  // width: 100%;
  // display: flex;
  cursor: default;
}

.myXueFen_daily_task_right{
  height: 268px;
}

.yet_get{
  width: 74px;
  height: 38px;
  color: #FFFFFF;
  text-align: center;
  line-height: 38px;
  border-radius: 19px;
  background: #D9D9D9;
}
.get{
  width: 74px;
  height: 38px;
  color: #FFFFFF;
  text-align: center;
  line-height: 38px;
  border-radius: 19px;
  background: #FF6D6D;
}

.no_get{
  width: 74px;
  height: 38px;
  color: #FFFFFF;
  text-align: center;
  line-height: 38px;
  border-radius: 19px;
  background: #9D9D9D;
}

.credit_purpose_text_title{
  font-size: 20px;
  color: #666;
}
.credit_purpose_text_title_margin{
  margin-top: 15px;
}
.credit_purpose_text{
    font-size: 18px;
    color: #999;
    line-height: 1.8;
    margin-top: 10px
}

.schedule_jiangli{
  position: relative;
  width: 42px;

}
.schedule_jiangli_img{
  position: absolute;
  left: 11px;
  top: -30px;
}
</style>

<style lang="less" scoped>
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}

/deep/ .el-dialog{
  width: 35%;
  border-radius: 10px;
  overflow: hidden;
}
/deep/ .el-dialog__header{
  background-color: #EEEEEE;
}
/deep/ .el-dialog__title{
  font-size: 28px;
}
</style>