<template>
	<div class="pageBox">
		<!-- 页码 -->
		<el-pagination background layout="prev, pager, next" :total="page.total" :page-size="page.pageSize"
			:current-page="page.currentPage" prev-text="上一页" next-text="下一页" @current-change="changePage">
		</el-pagination>
	</div>
</template>

<script>
	export default {
		props: ['page'],
		name: 'pages',
		data() {
			return {


			}
		},
		created() {

		},
		methods: {
			//页码改变事件
			changePage(val) {
				// console.log(`当前页: ${val}`);
				this.$emit('changePage', val)
			},
		},
		components: {

		}

	}
</script>

<style scoped>
	.pageBox {
		text-align: center;
	}
</style>
<style type="text/css">
	.el-pagination.is-background .btn-next,
	.el-pagination.is-background .btn-prev,
	.el-pagination.is-background .el-pager li {
		border-radius: 2px;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #074DFE !important;
	}
	.btn-prev, .btn-next {
		padding: 0 10px !important;
	}
	
</style>
