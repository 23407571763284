<template>
	<div class="index chengyuandetail">
		<div style="height: 70px;">
			<Header></Header>
		</div>
		<!-- 成员详情 -->
		<div class="yanjiuyuan_content">
			<div class="yanjiuyuan_shouye">您的位置：<span class="pointer" :style="tabIndex == 1 ? 'color: #FF7C06' : '' "
					@click="goIndex" @mousemove="tabIndex = 1" @mouseout="tabIndex=null">首页</span> >
				<span :style="tabIndex == 2 ? 'color: #FF7C06' : '' " class="pointer" @mousemove="tabIndex = 2"
					@click="goYanjiuyuan" @mouseout="tabIndex=null">资讯中心</span> >
				<span class="yanjiuyuan_link">成员详情</span>
			</div>

			<div class="detail_yanjiuyuan_yuanzhang" v-if="detail">
				<img :src="detail.thumb" class="detail_yanjiuyuan_renyuan">
				<div class="yanjiuyuan_yuanzhang_right">
					<div class="yanjiuyuan_yuanzhang_name">{{detail.name}}</div>
					<div class="yanjiuyuan_yuanzhang_job" v-html="detail.tags"></div>
					<div class="yanjiuyuan_yuanzhang_jieshao" v-html="detail.content"></div>
				</div>
			</div>

			<div style="margin: 80px 0 60px;">
				<span class="chengyuan_detail_title">作品展示</span>
			</div>
			<el-row :gutter="20">
				<el-col :span="8" v-for="(item, index) in detail.files" :key="index">
					<el-image :src="item" class="zuopinimg" :preview-src-list="srcList" @click="bigImg(item)">
					</el-image>
				</el-col>
			</el-row>
		</div>

		<BottomTab v-if="detail.id"></BottomTab>
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	import Header from '../components/Header.vue'
	import BottomTab from '../components/BottomTab.vue'
	import {
		memberDetail
	} from '../../assets/js/yanjiuyuanData.js'
	export default {
		name: 'detail_member',
		data() {
			return {
				id: '',
				tabIndex: null,
				memberDetail,
				detail: {},
				srcList: []
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getDetailById();
			this.bigImg();
		},
		methods: {
			getDetailById() {
				this.memberDetail.forEach((item, index) => {
					if (this.id == item.id) {
						this.detail = item;
					}
				})
			},

			goYanjiuyuan() {
				this.$router.push('/Yanjiuyuan')
			},
			goIndex() {
				this.$router.push('/Index')
				Bus.$emit('scrollToTop')
			},
			bigImg(img) {
				this.srcList = [];
				this.srcList.push(img)
			}

		},
		components: {
			Header,
			BottomTab
		}

	}
</script>

<style scoped lang="scss">
	.yanjiuyuan_content {
		width: 1200px;
		margin: 60px auto;
	}

	.yanjiuyuan_shouye {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 13px;
	}


	.dongtai_detail_title {
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 33px;
		margin: 80px 0 30px;
		text-align: center;
	}

	.dongtai_detail_time {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		text-align: center;
	}

	.yanjiuyuan_link {
		color: #FF7C06;
	}


	.detail_yanjiuyuan_yuanzhang {
		display: flex;
		justify-content: space-between;
		margin: 60px 0 0 0;
	}

	.detail_yanjiuyuan_renyuan {
		width: 366px;
		height: 366px;
		background: #FF7C06;
		border-radius: 50%;
		object-fit: cover;
	}

	.yanjiuyuan_yuanzhang_right {
		display: flex;
		flex-direction: column;
		width: 774px;
	}

	.yanjiuyuan_yuanzhang_name {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 99px;
	}

	.yanjiuyuan_yuanzhang_job {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
	}

	.yanjiuyuan_yuanzhang_jieshao {
		margin-top: 39px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #989898;
		line-height: 32px;
	}

	.chengyuan_detail_title {
		font-size: 26px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 16px;
	}
	.zuopinimg {
		width: 100%;
		height: 310px !important;
		border-radius: 8px;
		margin-bottom: 50px;
	}
	
</style>
<style type="text/css">
	.zuopinimg img {
		object-fit: cover;
	}
	.el-image-viewer__btn {
		display: none !important; 
	}
</style>