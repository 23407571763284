export const educationalOptions = [
  "全日制大专",
  "全日制大专以上",
  "全日制本生（硕士以上）",
  "全日制本科",
  "全日制本科以上",
  "全日制研究生（博士）",
  "全日制研究生（硕士以上）",
  "全日制研究生（硕士）",
  "大专",
  "大专以上",
  "本科",
  "本科以上",
  "研究生（硕士以上）",
  "研究生（硕士）",
];

export const employerOptions = [
  "上海市嘉定区人民武装部",
  "上海市宝山区人民武装部",
  "上海市崇明区人民武装部",
  "上海市徐汇区人民武装部",
  "上海市杨浦区人民武装部",
  "上海市松江区人民武装部",
  "上海市浦东新区人民武装部",
  "上海市虹口区人民武装部",
  "上海市金山区人民武装部",
  "上海市长宁区人民武装部",
  "上海市闵行区人民武装部",
  "上海市青浦区人民武装部",
  "上海市静安区人民武装部",
  "上海市黄浦区人民武装部",
  "上海警备区",
  "上海警备区普陀离职干部休养所",
  "上海警备区杨浦第一退休干部休养所",
  "上海警备区杨浦第三退休干部休养所",
  "上海警备区杨浦第二离职干部休养所",
  "上海警备区杨浦第二退休干部休养所",
  "上海警备区杨浦第五离职干部休养所",
];
export const majorOptions = [
  "一般力学与力学基础，工程力学",
  "一般力学与力学基础，流体力学",
  "世界史",
  "世界史，学科教学（历史）（专业学位）",
  "世界经济",
  "中共党史",
  "中共党史，中国近现代史基本问题研究",
  "中共党史，思想政治教育",
  "中共党史，马克思主义理论",
  "中医五官科学",
  "中医儿科学",
  "中医内科学",
  "中医内科学（专业学位）",
  "中医内科学（专业学位），中西医结合临床（专业学位）",
  "中医内科学，中医内科学（专业学位）",
  "中医内科学，中西医结合",
  "中医内科学，中西医结合临床",
  "中医外科学",
  "中医外科学（专业学位）",
  "中医妇科学",
  "中医学",
];
export const positionNameOptions = [
  "三级校对",
  "主治医师",
  "主管技师",
  "主管护师",
  "主管药师",
  "二级播音员",
  "会计员",
  "会计师",
  "会计（九级文员以下）",
  "侦查员（九级文员以下）",
  "兽医师",
  "军事代表（九级文员以下）",
  "出纳（九级文员以下）",
  "助教",
  "助理会计师",
  "助理兽医师",
  "助理农艺师",
  "助理员（九级文员以下）",
  "助理员（八级文员、七级文员）",
  "助理实验师",
  "助理审计师",
];
export const workLocationOptions = [
  "上海",
  "云南",
  "云南临沧",
  "云南丽江",
  "云南保山",
  "云南大理",
  "云南德宏",
  "云南怒江",
  "云南文山",
  "云南昆明",
  "云南昭通",
  "云南普洱",
  "云南曲靖",
  "云南楚雄",
  "云南玉溪",
  "云南红河",
  "云南西双版纳",
  "云南迪庆",
  "内蒙古",
  "内蒙古乌兰察布",
  "内蒙古乌海",
];
