import {emojiMap} from '../../util/lib/emojiMap.js'
import Axios from '../../axios/index'
import Store from '../../store/index.js'

export default {
	/**
	 * 查询url路径包含的指定参数，返回对应的值
	 * @param {*} key   要查询的参数
	 */
	getPathParam(key, url) {
		url = url || location.href
		// console.log('url路径:', url)
		if (url.indexOf('?') == -1) return
		let pram = url.split('?')[1];
		let keyValue = pram.split('&');
		for (let i = 0; i < keyValue.length; i++) {
			let item = keyValue[i].split('=');
			if (item[0] == key) {
				return item[1]
			}
		}
		return null;
	},
	/**
	 * 登录
	 * loginWay: 登录方式:  token
	 */
	login(loginWay, token) {
		return new Promise((resolve, reject) => {
			// token登录
			if(loginWay == 'token') {
				localStorage.setItem("token", token)
				Axios({
					method: 'POST',
					url: "/api/officialWebsite/computer/yuan_cheng/the_personal_data"
				}).then(res => {
					if(res.code == 200) {
						localStorage.setItem("userInfo", JSON.stringify(res.data))
						Store.commit("updateUserInfo", res.data)
						resolve(res.data)
					} else {
						reject(res)
					}
				}, (res) => {
					console.log('token登录失败了')
					reject(res)
				});
			}
		})
		
	},
	//补0
	add0(m) {
		return m < 10 ? '0' + m : m
	},
	//时间戳转时间
	format: function(shijianchuo, type) {
		if (!shijianchuo) return;
		if (parseInt(shijianchuo) < 10000000000) {
			shijianchuo = parseInt(shijianchuo) * 1000
		}
		var time = new Date(shijianchuo);
		var y = time.getFullYear();
		var m = time.getMonth() + 1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		var time = {
			y,
			m: this.add0(m),
			d: this.add0(d),
			h: this.add0(h),
			mm: this.add0(mm),
			s: this.add0(s)
		}
		var timeValue = time;
		if(type == 1) {
			timeValue = time.y + '.' + time.m + '.' + time.d + ' ' + time.h + ':' + time.mm + ':' + time.s;
		}else {
			timeValue = time;
		}
		return timeValue
	},
	
	// 时间戳转时间格式(中国标准时间)
	getTime: function(shijianchuo){
		if (!shijianchuo) return;
		if (parseInt(shijianchuo) < 10000000000) {
			shijianchuo = parseInt(shijianchuo) * 1000
		}
		var time = new Date(shijianchuo);
		return time
	},

	// 日期格式转时间戳
	getTimestamp: function(dateStr) {
		if (!dateStr) return;
		let shijianchuo = new Date(dateStr).getTime();
		shijianchuo = shijianchuo / 1000;
		return shijianchuo;
	},
	
	
	/**
	 * //中国标准时间格式转时间类型   Tue Oct 12 2021 10:31:18 GMT+0800 (中国标准时间)   1970-01-01 08:33:41
	 * @param {Object} china  要转换的标准时间
	 * @param {Object} type   如果没有，则返回  1970-01-01 08:33:41；   如果有值，  返回对象{y, m, d, h, mm, s}
	 */
	getTimeByChina: function(china, type) {
		// console.log(china)
		// console.log(isNaN(china))
		var timeValue = china;
		if (!china) return;
		if (isNaN(china)) return timeValue;   //若果是纯数字，则不是标准时间，不需要转换
		
		var time = new Date(timeValue);
		var y = time.getFullYear();
		var m = time.getMonth() + 1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		var time = {
			y,
			m: this.add0(m),
			d: this.add0(d),
			h: this.add0(h),
			mm: this.add0(mm),
			s: this.add0(s)
		}
		
		if(type) {
			timeValue = time;
		}else {
			timeValue = time.y + '-' + time.m + '-' + time.d + ' ' + time.h + ':' + time.mm + ':' + time.s;
		}
		// console.log('返回值：', timeValue)
		return timeValue
	},
	
	/**
	 * 获取当前的url
	 */
	getPath: function(pathStr){
		let endIndex = window.location.href.indexOf(pathStr) + pathStr.length;
		let urlStar = window.location.href.substr(0, endIndex);
		return urlStar;
	},

	
	/**
	 * 聊天消息-表情和过滤词处理
	 * noImg: 表情是否解析成img  弹幕消息不需要解析
	 */
	parseMessage: function(message, bannedWords, noImg){
		if(!message) return
		if(!bannedWords) bannedWords = [];
		let mathMessage= ''
		let messageArr = message.split('[');
		messageArr.forEach(messItem => {
			let emojiRight = messItem.indexOf(']');
			if (emojiRight <= 0) {
				bannedWords.forEach(item => {
					if(item) {
						messItem = messItem.replaceAll(item,"*".repeat(item.length));
					}
				})
				mathMessage = mathMessage+"["+messItem
				return;
			}
			let emoji = "[" + messItem.substring(0, emojiRight) + "]";
			emoji = emoji.replace(/\[.*?\]/g, function(keyword) {
				if (emojiMap[keyword]) {
					if(noImg) {
						return keyword
					}
					return "<img style='width: 30px;max-height: 30px' src='" +
						emojiMap[keyword] + "' />"
				} else {
					bannedWords.forEach(item => {
						if(item) {
							keyword = keyword.replaceAll(item,"*".repeat(item.length));
						}
					})
					return keyword
				}
			});
						
			let rightMessage = messItem.substring(emojiRight + 1);
			bannedWords.forEach(item => {
				if(item) {
					rightMessage = rightMessage.replaceAll(item,"*".repeat(item.length));
				}
			})
			mathMessage = mathMessage + emoji + rightMessage
		})
		mathMessage = mathMessage.substring(1);
		return mathMessage
	},
	/**
	 * 判断：字符串里是否含有数组的值
	 * 返回：匹配到的值或者False
	 */
	booleanBanned(str, arr) {
		if(!str) return false
		if(!arr) arr = [];
		let strOfArr = false;
		let messageArr = str.split('[');
		messageArr.forEach(messItem => {
			if(strOfArr) return strOfArr
			let emojiRight = messItem.indexOf(']');
			if (emojiRight <= 0) {
				arr.forEach(item => {
					if(item) {
						if(messItem.indexOf(item) !== -1) {
							strOfArr = item
						}
					}
				})
				return strOfArr;
			}
			let emoji = "[" + messItem.substring(0, emojiRight) + "]";
			emoji = emoji.replace(/\[.*?\]/g, function(keyword) {
				if (emojiMap[keyword]) {
					return false
				} else {
					arr.forEach(item => {
						if(item) {
							if(messItem.indexOf(item) !== -1) {
								strOfArr = item
							}
						}
					})
				}
				return strOfArr
			});	
			
			let rightMessage = messItem.substring(emojiRight + 1);
			arr.forEach(item => {
				if(item) {
					if(rightMessage.indexOf(item) !== -1) {
						strOfArr = item
					}
				}
			})
		})
		return strOfArr
	},
	/**
	 * 获取当前浏览器类型
	 */
	getMyBrowser() {
		const userAgent = navigator.userAgent;
		console.log('获取当前浏览器类型:', userAgent)
		const isOpera = userAgent.indexOf("Opera") != -1
		if(isOpera) {   //(奥普拉)浏览器
			return "Opera"
		}
		if(userAgent.indexOf("Firefox") != -1) {   //火狐
			return "FF"
		}
		if(userAgent.indexOf("QQBrowser") != -1) {   //	QQ
			return "QQBrowser"
		}
		if(userAgent.indexOf("Chrome") != -1) {   //谷歌
			return "Chrome"
		}
		
		if(userAgent.indexOf("Safari") != -1) {   //苹果
			return "Safari"
		}
		if(userAgent.indexOf("compatible") != -1 && userAgent.indexOf("MSIE") != -1 && !isOpera) {   //IE
			return "IE"
		}
		return false
	}
}
