<template>
	<div class="zb-wrap">
		<!-- 点播 -->
		<div class="vipWrap">
			<div class="zb-header">当前播放：{{currentPlay.name}}</div>
			<div class="vip-video-content">
				<div class="videoBox">
					<video @play="onPlay" id="player" :src="currentPlay.videofile" :poster="currentPlay.photofile" style="width: 100%;height: 100%;" controls></video>
				</div>
				
				<div class="listBox" :style="isShowList ? 'width: 200px' : 'width: 0'">
					<div class="vip-video-list-btn" @click="isShowList = !isShowList">
						<img v-if="isShowList" src="../../assets/image/live/youjiantou.png" alt="">
						<img v-else src="../../assets/image/live/zuojiantou.png" alt="">
						<span>目录</span>
					</div>
					<div v-if="isShowList">
						<div class="vip-video-list-title">{{currentPlay.name}}</div>
						<div class="vip-course-list">
							<div class="vip-course-item" @click="changeVideo(item, index)" :style="item.id == currentPlay.id ? 'background-color: #1F232E' : ''" v-for="(item, index) in playbackList" :key="index">
								<span class="title" :style="item.id == currentPlay.id ? 'color: #84A4FF' : ''">{{item.name}}</span>
								<img v-if="item.id == currentPlay.id" class="zb-player" src="../../assets/image/live/bofangzhong.gif" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	export default {
		name: 'dianbo',
		data() {
			return {
				query: {},
				playbackList: [],
				currentPlay: {},  //当前播放
				isShowList: true,
			}
		},
		created() {
			this.query = this.$route.query;
			console.log('传参：', this.query)
			this.getPlaybackList();
			Bus.$emit('scrollToTop')
		},
		methods: {
			// 获取回放列表
			getPlaybackList() {
				this.$axios({
					method: "POST",
					url: "api/officialWebsite/mobile/course_live/click_course",
					data: {
						classmat_id: this.query.classId
					}
				}).then((res) => {
					if (res.code === 200) {
						this.total = res.data.total;
						this.playbackList = res.data;
						this.changeVideo(this.query)
					}
				});
			},
			// 切换播放视频
			changeVideo(item, index) {
				index = index || this.playbackList.findIndex(paly => paly.id == item.id)
				index = Math.max(index, 0)
				this.currentPlay = this.playbackList[index];
				this.ended();
			},
			// 获取当前时间戳 10位
			getCurrentTime() {
				return parseInt(new Date().getTime()/1000)
			},
			onPlay() {
				this.start_time = this.getCurrentTime();
			},
			ended() {
				console.log('结束记录')
				if(!this.start_time) return;
				this.end_time = this.getCurrentTime();
				this.$axios({
					method: "POST",
					url: "api/officialWebsite/computer/create_video_lesson_learning_record",
					data: {
						syllabus_id:this.currentPlay.id,
						start_time: this.start_time,
						end_time: this.end_time,
						course_type: 3
					}
				}).then((res) => {
					this.start_time = null;
					this.end_time = null;
				});
			},
		},
		beforeDestroy() {
			console.log('销毁')
			this.ended();
		}
	}
</script>

<style scoped lang="scss">
	.zb-wrap {
		width: 100%;
		height: auto;
		background-color: #212121;
		padding: 40px 0 80px;
	}

	.vipWrap {
		width: 1200px;
		margin: 0 auto;

		.zb-header {
			font-size: 18px;
			color: #fff;
			margin-bottom: 14px;
		}

		.vip-video-content {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.videoBox {
				background-color: #000;
				height: 600px;
				flex: 1;
				#player {
					width: 100%;
					height: 600px;
				}
			}
		}

		.listBox {
			height: 600px;
			background-color: #2E2E2E;
			box-sizing: border-box;
			position: relative;

			.vip-video-list-btn {
				width: 26px;
				height: auto;
				font-size: 14px;
				color: #fff;
				padding: 5px 0;
				text-align: center;
				-webkit-border-radius: 4px;
				border-radius: 4px;
				background-color: #2E2E2E;
				box-sizing: border-box;
				position: absolute;
				left: -26px;
				top: 50%;
				z-index: 200;
				transform: translateY(-50%);
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				img {
					width: 10px;
					margin-bottom: 5px;
				}
			}

			.vip-video-list-title {
				width: 100%;
				max-height: 60px;
				font-size: 18px;
				color: #fff;
				padding: 0 15px;
				margin: 15px 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				box-sizing: border-box;
				text-align: center;
			}

			.vip-video-list-title {
				width: 100%;
				max-height: 60px;
				font-size: 16px;
				color: #fff;
				padding: 0 15px;
				margin: 15px 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				box-sizing: border-box;
			}

			.vip-course-list {
				width: 100%;
				height: calc(600px - 82px);
				padding: 20px 0 10px;
				overflow-y: auto;
				box-sizing: border-box;
				.vip-course-item {
					width: 100%;
					height: 48px;
					padding: 0 15px;
					box-sizing: border-box;
					cursor: pointer;
					display: flex;
					-webkit-justify-content: space-between;
					justify-content: space-between;
					-webkit-align-items: center;
					align-items: center;
					font-size: 14px;
					.title {
						color: #fff;
						width: calc(100% - 42px);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.zb-player {
						width: 26px;
					}
				}
				.vip-course-item:hover {
					background-color: rgba(255, 255, 255, 0.2);
					.title {
						color: #84A4FF;
					}
				}
			}
			.vip-course-list::-webkit-scrollbar {
				width: 0;
			}
			
		}
	
	}
</style>
