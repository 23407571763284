<template>
  <div>
    <div class="tabs">
     <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <!-- https://static.shanchuangjiaoyu.com/ -->
    <div class="tabs_info" v-loading="loading">
      <div class="outermost_layer_kabao_box" v-for="item in KabeoData" :key="item.id">
        <div class="kabao_box">
        <div class="kabao_box_left">
          <div class="kabao_box_left_top">
            <p :class="{ USED_text_color: item.status === 1 }" class="name_text_color">{{ item.has_coursebase.name }}</p>
            <p :class="{ USED_text_color: item.status === 1 }" v-if="!item.activity_give">赠送: 无赠送课程</p>
            <p :class="{ USED_text_color: item.status === 1 }" v-else>赠送: {{ item.activity_give }}</p>
          </div>
          <div class="kabao_box_left_bottom">
            <p :class="{ USED_text_color: item.status === 1 }">有效期至: {{ format(item.dt) }}</p>
          </div>
        </div>
        <div class="kabao_box_right" v-if="item.orderflag" :class="{ USEDAndExpired_right_bg: item.status === 1 }">
          <p class="kabao_box_right_faceValue">&yen;{{ item.discountprice }}</p>
          <button class="kabao_box_right_btn"  v-if="item.status !== 1">去使用</button>
        </div>

        <!-- 分期卡 -->
         <div class="kabao_box_right"  :class="{ kabao_box_right_fenqi: !item.orderflag, USEDAndExpired_right_bg: item.status === 1 }" v-else>
          <p class="kabao_box_right_fenqi_text">分期卡</p>
          <button class="kabao_box_right_btn" :class="{ kabao_box_right_fenqi_btn: !item.orderflag }" v-if="item.status !== 1">去使用</button>
        </div>

        <!-- 已使用图 -->
        <div class="USED_img" v-if="item.status === 1">
          <img src="../../../../assets/image/已使用.png" alt="">
        </div>

        <!-- 已过期图 -->
        <!-- <div class="Expired_img">
          <img src="../../../../assets/image/已过期.png" alt="">
        </div> -->
      </div>
      </div>
        <no_data v-if="!KabeoData.length"></no_data>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tabsList: [
        {
          tabs_title: '可用券'
        },
        {
          tabs_title: '已使用'
        },
        {
          tabs_title: '已过期'
        }
      ],
      tabs_active: 0,
      KabeoData:[]
    }
  },
  methods: {
    tabsClick(index) {
      this.tabs_active = index
      this.getKabeoData()
    },

    // 获取卡包数据
    getKabeoData() {
      this.loading = true
      this.$axios({
        method: 'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/my_card',
        data:{
          type: this.tabs_active
        }
      }).then(res => {
        this.loading = false
        console.log(res);
        if (res.code === 200) {
          this.KabeoData = res.data
        }
      })
    },
    //补0
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    // 时间戳 转换  xxxx-xx-xx
    format(shijianchuo) {
      if (!shijianchuo) return;
      if (parseInt(shijianchuo) < 10000000000) {
        shijianchuo = parseInt(shijianchuo) * 1000
      }
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var time = {
        y,
        m: this.add0(m),
        d: this.add0(d),
      }
      var timeValue = time;
      timeValue = time.y + '-' + time.m + '-' + time.d;
      return timeValue
    }
  },
  created () {
    this.getKabeoData()
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  background-color: #FFFFFF;
  padding:90px 65px;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}

.kabao_box{
  width: 557px;
  height: 151px;
  // background: rgba(236,236,236,1);
  // background-color: aqua;
  position: relative;
  display: flex;
  -webkit-mask-image: radial-gradient(circle at 390px 10px, transparent 10px, red 10.5px), linear-gradient(transparent 25%, red 0, red 75%, transparent 0);
  -webkit-mask-size: 100%, 4px 16px;
  -webkit-mask-repeat: repeat, repeat-y;
  -webkit-mask-position: 0 -10px, 388px;
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
}

.kabao_box_left{
  width: 70%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ECECEC;
}
.kabao_box_right{
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // text-align: center;
  // line-height: 151px;
  background-color: #FF404D;
}
.kabao_box_right_fenqi {
  background-color: #FFAC40;
}
.outermost_layer_kabao_box{
  width: 557px;
  height: 151px;
  margin: 20px;
  -webkit-mask-image: radial-gradient(circle at 6px, transparent 6px, red 6.5px);
  -webkit-mask-position: -6px;
  -webkit-mask-size: 100% 12px;
}


.kabao_box_right_faceValue {
  font-size: 50px;
  color: #FFFFFF;
  font-weight: 700;
}

.kabao_box_right_fenqi_text{
  font-size: 38px;
  color: #FFFFFF;
  font-weight: 700;
}

.kabao_box_right_btn{
  width: 77px;
  height: 30px;
  background-color: #FFFFFF;
  color: #FF404D;
  border-radius: 15px;
}

.kabao_box_right_fenqi_btn{
  color: #FFAC40;
}

.USED_img,
.Expired_img{
  position: absolute;
  right: 0;
  bottom: 0;
}


// 已使用/已过期的文字颜色
.USED_text_color,
.Expired_text_color{
  opacity: .6;
}
.name_text_color{
  font-weight: Bold;
  font-size: 26px;
}

// 已使用/已过期的 右侧背景色
.USEDAndExpired_right_bg {
    background-color: #CACACA;
}

.kabao_box_left_bottom{
  margin-top: 10px;
}
</style>

<style lang="less" scoped>
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}
</style>