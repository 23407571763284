<template>
  <div class="sideTab" :style="$hasGray?'filter: grayscale(1);':''">
    <!-- 侧边栏 -->
    <div class="topTab">
      <!-- <div class="item">
        <div
          :style="
            mouseActive == 1
              ? 'width: 50px;background-color: #074DFE;'
              : 'width: 50px;'
          "
          class="active"
          style="right: 50%; transform: translateX(50%)"
          @mouseover="mouseoverFun(1)"
          @mouseleave="mouseleave(1)"
        >
		<img  v-if="mouseActive == 1" src="../../assets/image/svgImg/shouji_active.svg" style="width: 42px;">
		<img v-else src="../../assets/image/svgImg/shouji.svg" style="width: 42px;">
        </div>
        <div class="weixinBox_top" v-if="mouseActive == 1">
          <img class="weixin" src="../../assets/image/erweima.png"/>
          <div class="weixintext">扫码下载APP</div>
        </div>
      </div> -->
      <!-- <div class="item">
        <div
          :style="
            mouseActive == 2
              ? 'width: 50px;background-color: #074DFE;'
              : 'width: 50px;'
          "
          class="active"
          @mouseover="mouseoverFun(2)"
          @mouseleave="mouseleave(3)"
        >
		  <img  v-if="mouseActive == 2" src="../../assets/image/svgImg/kefu_active.svg" style="width: 42px;">
		  <img v-else src="../../assets/image/svgImg/kefu.svg" style="width: 42px;">
          <div class="weixinBox" v-if="mouseActive == 2">
            <img class="weixin" :src="ossUrl + 'kefu_erweima.png'" />
            <div class="weixintext">在线客服-微信扫一扫</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="item">
        <div
          :style="
            mouseActive == 3
              ? 'margin-right:280px;position:relative'
              : 'width: 50px;'
          "
          class="active"
          @mouseover="mouseoverFun(3)"
          @mouseleave="mouseleave(3)"
        >
		<img v-if="mouseActive == 3" src="../../assets/image/svgImg/dianhua_active.svg" style="width: 42px;background-color: #074DFE;position:absolute; left:0px;width: 50px;height: 50px;padding:4px">
		<img v-else src="../../assets/image/svgImg/dianhua.svg" style="background-color: #ffffff;position: absolute; left: 4px;width: 42px;">

          <span
            class="textVlaue flash"
            :style="mouseActive == 3 ? 'margin-right:195px;' : ''"
            style="
              width: 165px;
              background-color: #ffffff;
              margin-right: 245px;
              border-radius: 30px 10px 30px 30px;
              box-shadow: 0px 0px 5px #0049a83d;
            "
          >
            <span
              style="
                width: 165px;
                height: 43px;
                margin-right: 230px;
                z-index: -1;
                display: flex;
                justify-content: space-around;
                align-items: center;
				        padding-left: 5px;
              "
            >
			  <img src="../../assets/image/svgImg/ce_dong_dianhua.svg" style="width: 32px;">
              <p style="color: #074DFE; font-size: 14px;font-weight: 700;margin-right: 10px;">
                400-0872229
              </p>
            </span>
          </span>
        </div>
      </div> -->
      <!-- <div class="item">
        <div
          :style="
            mouseActive == 4
              ? 'width: 50px; background-color: #074DFE;'
              : 'width: 50px;'
          "
          class="active"
          style="right: 50%; transform: translateX(50%)"
          @mouseover="mouseoverFun(4)"
          @mouseleave="mouseleave(4)"
        >
		<img  v-if="mouseActive == 4" src="../../assets/image/svgImg/xiaochengxu_active.svg" style="width: 42px;">
		<img v-else src="../../assets/image/svgImg/xiaochengxu.svg" style="width: 42px;">
		
        </div>
        <div class="weixinBox4" v-if="mouseActive == 4">
          <img class="weixin" src="../../assets/image/xiaochengxu_erweima.svg" />
          <div class="weixintext">扫码进入小程序</div>
        </div>
      </div> -->
      <!-- <div
        class="item"
        @mouseover="mouseoverFun(5)"
        @mouseleave="mouseleave(5)"
      >
        <div
          :style="
            mouseActive == 5
              ? 'width: 160px;background-color: #074DFE;'
              : 'width: 50px;'
          "
          class="active"
          @click="toAbout"
        >
			<img  v-if="mouseActive == 5" src="../../assets/image/svgImg/zoujinyuancheng_active.svg" style="width: 42px;">
			<img v-else src="../../assets/image/svgImg/zoujinyuancheng.svg" style="width: 42px;">
			<span v-if="mouseActive == 5" class="textVlaue">走进精研学堂</span>
        </div>
      </div> -->
      <!-- <div class="item" @click="fanzha" @mouseover="mouseoverFun(7)" @mouseleave="mouseleave(7)">
        <div class="active" style="right: 50%;width: 100%;; transform: translateX(50%)"  >
          <div>
            <img  src="../../assets/image/fanzha.png" style="width: 23px;height: 28px;margin-bottom:5px;">
            <div style="font-size: 12px;color: #5F5E5E;line-height: 10px;margin-bottom: 20px;">反诈防骗</div>
          </div>
        </div>
        <div class="fanzha" v-if="mouseActive == 7">
          <img class="weixin" style="width: 127px;height: 90px;" src="../../assets/image/fanzhabg.png" />
        </div>
      </div> -->
    </div>
    
    <!-- <div style="height: 50px">
      <div
        class="topBox"
        :style="mouseActive == 6 ? 'background-color: #074DFE;' : ''"
        @click="goTop"
        v-if="isShow"
        @mouseover="mouseoverFun(6)"
        @mouseleave="mouseleave(6)"
      >
	   <img  v-if="mouseActive == 6" src="../../assets/image/svgImg/zhiding_active.svg" style="width: 42px;">
       <img v-else src="../../assets/image/svgImg/zhiding.svg" style="width: 42px;">
      </div>
    </div> -->
  </div>
</template>

<script>
import Bus from "../../assets/js/Bus.js";
export default {
  name: "SideTab",
  props: ["isShow"],
  data() {
    return {
      ossUrl: process.env.VUE_APP_OSS_URL,
      mouseActive: null,
      scrollTop: "",
      phone:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/Qyc6kqI8NIjyZSS2dbR1wKG7KShuNPK6MCmHtsK4.png",
      phone_h:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/tnHO0gF7CpNyzSQZk1ruufsunYs3dbths1AzNLzT.png",
      tel: "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/iLDOTiTmIPQbPc1QuK9Q1zIzqLJvLqA1QJLp0ASW.png",
      tel_h:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/pdXSsOjUtezoTte155860yI9c3AKcEz7uAmFqmnr.png",
      service:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/mXfsrskd7QGtCpx54H1l53WOsT840x3JcpLYTrVy.png",
      service_h:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/MZoRweYhllqxoPQwVzdhqyKBq1VdveLcpSLMoUGj.png",
      applet:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/HSyFm3qCz1iLLw604ALerdCNKGniQkAMYl5YH8Ti.png",
      applet_h:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/Rf98gub5ldL08kGXJ80q0OkLVPMnjA0tIEqnyAVa.png",
      goto: "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/OFOdmM60oaphKsg4u0Y36Ea3j3YVF9qTuT2r9MRZ.png",
      goto_h:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/mnZOre5DFbYpzfTrD6ePQ2fMa7mfkjXuzt4Nl9hE.png",
      top: "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/f45dr0PY1yB4CjD0418GTc5QKLXKszjreGsDYY5k.png",
      top_h:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/laLlPDmZEfrGIyfc803AZn7xUsgMvuhCYruwVJ5n.png",
      gifTel:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/YrfP1UzZe3UgHZVJdwSZJHlXy8jsPPg32fRZwk2h.png",
      tel_s:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/16/A83U0VtMfWR7xg7wznTeg8xueH5ehhebP4HEG4IV.png",
    };
  },
  mounted() {},

  methods: {
    mouseoverFun(index) {
      // console.log(index);
      this.mouseActive = index;
    },
    mouseleave(index) {
      this.mouseActive = null;
    },
    goTop() {
      Bus.$emit("scrollToTop");
      this.mouseActive = null;
    },
    fanzha(){
      const routeUrl = this.$router.resolve({
        path: "/counterBlast",
      });
      window.open(routeUrl.href, "_blank");
    },
    toAbout() {
      let href = this.$router.resolve({
        // path: '/About'
        path: "/enter",
      });
      window.open(href.href, "_blank");
    },
  },
};
</script>

<style scoped>
.sideTab {
  position: fixed;
  bottom: 100px;
  right: 17px;
  z-index: 999;
}

.item {
  position: relative;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 3px 0 30px rgba(0, 0, 0, 0.08);
}

.active {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textVlaue {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* margin-left: 10px; */
}

.weixinBox {
  position: absolute;
  right: 70px;
  top: -50px;
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 22px 22px 10px;
  box-sizing: border-box;
  z-index: 10;
}

.weixinBox::after {
  content: "";
  position: absolute;
  top: 65px;
  right: -10px;
  /* transform: translateY(-50%); */
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  border-top: 10px solid transparent;
}

.weixinBox_top {
  position: absolute;
  right: 70px;
  top: 0px;
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 18px 18px 6px;
  box-sizing: border-box;
  z-index: 10;
}

.weixinBox_top::after {
  content: "";
  position: absolute;
  top: 25px;
  right: -10px;
  /* transform: translateY(-50%); */
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  border-top: 10px solid transparent;
}
.weixinBox4 {
  position: absolute;
  right: 70px;
  top: -95px;
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 15px 15px 10px;
  box-sizing: border-box;
}

.weixinBox4::after {
  content: "";
  position: absolute;
  top: 110px;
  right: -10px;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  border-top: 10px solid transparent;
}
.fanzha {
  position: absolute;
  right: 30px;
  top:-10px;
  width: 180px;
  box-sizing: border-box;
}

.fanzha::after {
  content: "";
  position: absolute;
  top: 110px;
  right: -10px;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  border-top: 10px solid transparent;
}

.weixin {
  width: 100%;
}

.weixintext {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  margin-top: 6px;
}

.topBox {
  box-shadow: 3px 0 30px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topImg {
  width: 18px;
  object-fit: cover;
}

.topTab {
  margin: 0 0 100px 0;
  cursor: pointer;
}
.flash {
  animation: flash 1s infinite;
}
@keyframes flash {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-14px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
