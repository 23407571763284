<template>
  <div class="circle">
    <!-- 圈子 -->
    <div class="circles">
      <div class="tabs">
        <button
          class="tabs_btn"
          :class="activeName == '1' ? 'tabs_btn_focus' : ''"
          @click="tabClick('1')"
        >
          推荐
        </button>
        <!-- <button
          class="tabs_btn"
          :class="activeName == '2' ? 'tabs_btn_focus' : ''"
          @click="tabClick('2')"
        >
          作业
        </button> -->
        <!-- <button
          class="tabs_btn"
          :class="activeName == '3' ? 'tabs_btn_focus' : ''"
          @click="tabClick('3')"
        >
          作品
        </button> -->
        <!-- <button
          class="tabs_btn"
          :class="activeName == '4' ? 'tabs_btn_focus' : ''"
          @click="tabClick('4')"
        >
          阅读
        </button> -->
      </div>
      <div style="width: 1130px">
          <img class="oneBanner" :src="bannerList[0].image" v-if="bannerList.length==1">
          <el-carousel
            :interval="5000"
            indicator-position="none"
            class="banners"
            v-if="bannerList.length>1"
          >
            <!-- 轮播 -->
            <el-carousel-item v-for="(item, index) in bannerList" :key="index"  style="width: 1056px">
              <!-- <div > -->
              <img class="banner" :src="item.image" alt="" />
              <!-- </div> -->
            </el-carousel-item>
          </el-carousel>
        <div
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="busy"
        >
          <div
            class="circleBox"
            v-for="(item, index) in contentList"
            :key="index"
          >
            <div class="circleTitle">
              <!-- 头像 -->
              <img
                class="circle_portrait"
                :src="item.headico ? item.headico : ossUrl + 'touxiang.png'"
                alt=""
              />
              <div class="circleInformation">
                <span>{{ item.student_name }}</span>
                <p>{{ item.publish_time }}</p>
              </div>
              <!-- <img class="authen" src="../../assets/image/v.png" alt="" /> -->
            </div>
            <div class="circleContent" v-if="item.contentstr>368">
              <span
                :ref="item.ref"
                :class="item.contentisOpen ? 'contentBigOpen' : 'contentBig'
                "
              >
                {{ item.desc }}
                <p
                  v-if="item.contentisOpen"
                  class="circleClose"
                  @click="close(index)"
                >
                  收起
                </p>
              </span>
                <p
                  v-if="!item.contentisOpen"
                  class="circleShow"
                  @click="shows(index)"
                >
                  <span style="color: #000000">...</span>&nbsp;&nbsp; 展开
                </p>
            </div>
            <div class="circleContent" v-else>
              <span
                :ref="item.ref"
                class="contentLittle"
              >
                {{ item.desc }}
              </span>
            </div>
            <div v-if="item.photo.length == 1" class="circle_Img-one">
              <div v-for="(itemimg, indeximg) in item.photo" :key="indeximg">
                <el-image
                  class="circleImg-one"
                  :src="itemimg"
                  :z-index="10086"
                  :preview-src-list="item.photo"
                >
                </el-image>
              </div>
            </div>
            <div
              v-if="item.photo.length <= 4 && item.photo.length > 1"
              class="circle_Img-four"
            >
              <div
                v-for="(itemimg, indeximg) in item.photo"
                class="divimage_four"
                :key="indeximg"
              >
                <el-image
                  class="circleImg-four"
                  :src="itemimg"
                  :z-index="10086"
                  :preview-src-list="item.photo"
                >
                </el-image>
              </div>
            </div>
            <div v-if="item.photo.length >= 5" class="circle_Img-five">
              <div
                v-for="(itemimg, indeximg) in item.photo"
                :key="indeximg"
                class="divimage_five"
              >
                <el-image
                  class="circleImg-five"
                  :src="itemimg"
                  :z-index="10086"
                  :preview-src-list="item.photo"
                >
                </el-image>
              </div>
            </div>
            <div class="circleIco">
              <div class="circleIcoTags">
                <el-tag type="info" size="normal">{{ item.course }}</el-tag>
              </div>
              <div class="btn">
                <!-- <div class="share" @click="iconBtn">
                <img src="../../assets/image/share.png" alt="" />
                <p>分享</p>
              </div> -->
                <div class="comment">
                  <img
                    src="../../assets/image/comment.png"
                    @click="iconComment(item, index)"
                    alt=""
                  />
                  <p>{{ item.comment_count }}</p>
                </div>
                <div class="like">
                  <img
                    @click="iconClick(index)"
                    :src="contentList[index].is_praise != '' ? likes : like"
                    alt=""
                  />
                  <p
                    :class="
                      contentList[index].is_praise != '' ? 'giveLike' : ''
                    "
                  >
                    {{ contentList[index].praise }}
                  </p>
                </div>
                <!-- <div class="collection" @click="iconBtn">
                <img src="../../assets/image/collection.png" alt="" />
                <p>185</p>
              </div> -->
              </div>
            </div>
            <!-- 评论弹框 -->
            <!-- <transition name="fade"> -->
            <transition name="el-zoom-in-top">
              <div
                v-if="item.visible && comments.hid == item.id"
                class="m-top"
                v-loading="loadsing"
              >
                <!-- <div class="triangle"></div> -->
                <div class="comments">
                  <div
                    class="comments-contact"
                    v-for="(coitem, coindex) in comments.list"
                    :key="coindex"
                  >
                    <img
                      class="commentsLogo"
                      :src="
                        coitem.headico
                          ? coitem.headico
                          : ossUrl + 'touxiang.png'
                      "
                      alt=""
                    />
                    <div class="contacts">
                      <span class="commentsName"
                        >{{ coitem.comment_user }}
                        <span class="commentsSub">{{ coitem.content }}</span>
                      </span>
                      <p class="commentTime">{{ coitem.dt }}</p>
                    </div>
                  </div>
                  <div class="commentMore" @click="moreClick(item.id)">
                    <span v-if="!noComment">查看更多</span>
                    <span v-if="noComment">没有更多了</span>
                    <i v-if="!noComment" class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <el-empty :image-size="200" v-if="emptys"></el-empty>
        <div class="circleEmpty" v-if="cutState">
          <p class="listEndStyle"><i class="el-icon-loading"></i>加载中...</p>
        </div>
        <p class="listEndStyle" v-if="listLoading">
          <i class="el-icon-loading"></i>加载中...
        </p>
        <!-- 回到首页 -->
        <div class="goTopouter">
          <div
            class="goTop"
            v-show="goTopShow"
            @click="goTop"
            @mouseenter="gotopsvg = true"
            @mouseleave="gotopsvg = false"
          >
            <img
              src="../../assets/image/svgImg/zhiding.svg"
              alt=""
              v-show="!gotopsvg"
            />
            <img
              src="../../assets/image/svgImg/zhiding_active.svg"
              alt=""
              v-show="gotopsvg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emptyPage from "../emptyPage.vue";
import Bus from "../../assets/js/Bus.js";
export default {
  name: "circleIndex",
  data() {
    return {
      ossUrl: process.env.VUE_APP_OSS_URL,
      emptys:false,//空状态
      activeName: "1",
      busy: false,
      noMore: false,
      noComment: false,
      listLoading: false,
      bannerList: [], //banner
      like: "https://devstatic.yc-edu.online/api/upload/crm/2022/03/26/epqe9YNqnHgFj4BbgdT8JNLIWSBPZp0vmuP3TXhd.png", //点赞
      likes:
        "https://devstatic.yc-edu.online/api/upload/crm/2022/03/26/TOhiPRf8GLSS4ZBlfEHBKaa7weW6ErhdJOgyBRoQ.png", //点赞选中
      comments: {
        page: 1,
        hid: 0,
        list: [],
      },
      contentList: [],
      count: 0,
      heights: "", //获取文章高度
      heightes: 0, // 文章高度
      loading: false, // 加载
      loadsing: false, // 评论加载等待
      workpage: 0,
      recommend: 1,
      cutState: false,
      scrollTop: "",
      goTopShow: false,
      gotopsvg: false,
      studentid:"",
      aaa:false,
    };
  },
  mounted() {
    this.getbannerList();
    this.getUserinfo()
    this.getContentHeight();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 获取用户信息
    getUserinfo(){
      this.$axios({
        method:"get",
        url:"/api/student/studentInfo"
      }).then(res=>{
        // console.log(res,"用户信息");
        if(res.code==200){
          this.studentid=res.data.id
        }else{
          this.studentid=""
        }
        this.cutState=true
        this.getworksList()
        this.aaa=true
      })
    },
    // 加载更多
    loadMore() {
      this.busy = true;
      if (this.noMore == true) return;
      if (this.cutState == true) return;
      this.listLoading = true;
      this.workpage++;
      if(!this.aaa){
        this.cutState=true
        return
      }
      this.getworksList()
    },
    // 切换导航
    tabClick(tabnum) {
      if (tabnum == "2"||tabnum == "3" || tabnum == "4") {
        this.$message({
          showClose: true,
          message: "页面正在建设中",
          offset: 420,
        });
        return;
      }
      this.noMore = false;
      this.workpage = 1;
      if (tabnum == "1") {
        this.contentList = [];
        this.recommend = 1;
        this.cutState = true;
        this.getworksList();
      }
      //  else if (tabnum == "2") {
      //   this.contentList = [];
      //   this.recommend = "";
      //   this.cutState = true;
      //   this.getworksList();
      // }
      this.activeName = tabnum;
    },
    moreClick(hid) {
      if (this.noComment) return;
      this.loadsing = true;
      this.comments.page++;
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/circle/homework_comment_list",
        data: { list_rows: 6, page: this.comments.page, homework_id: hid },
      }).then((res) => {
        // console.log(res, "作业评论列表");
        if (res.code == 200) {
          if (res.data.data.length != 0) {
            this.comments.list = this.comments.list.concat(res.data.data);
          } else {
            this.$notify({
              title: "暂无更多评论了...",
              message: "",
              showClose: false,
            });
            this.noComment = true;
          }
          this.loadsing = false;
        }
      });
    },
    iconBtn() {
      this.$notify({
        title: "此功能暂未开放...",
        message: "",
        showClose: false,
      });
    },
    // 展开关闭评论
    iconComment(item, index) {
      this.$forceUpdate();
      this.comments.hid = item.id;
      if (item.comment_count == 0) {
        this.$message({
          showClose: true,
          message: "暂无评论",
          offset: 100,
        });
        this.contentList[index].visible = false;
        return;
      }
      this.contentList[index].visible = !this.contentList[index].visible;
      if (this.contentList[index].visible) {
        this.getPinglunList(item.id);
      } else {
        this.noComment = false;
      }
    },
    // 获取文章高度
    getContentHeight() {
      this.$nextTick(() => {
        this.contentList.forEach((el, i) => {
          let heightCss = this.$refs[`contents${i}`][0].offsetHeight;
          el.heights = heightCss;
          if (el.heights == "60px") {
            el.heightes = 60;
          }
          if (el.heights == "90px") {
            el.heightes = 90;
          }
        });
      });
    },
    // 展开详情
    shows(i) {
      this.$forceUpdate();
      this.contentList[i].contentisOpen = true;
    },
    // 省略详情
    close(i) {
      this.$forceUpdate();
      this.contentList[i].contentisOpen = false;
    },
    // 点赞
    iconClick(index) {
      if(this.studentid==""){
        this.$message({
          showClose: true,
          message: "请登录后再进行操作",
          offset: 100,
        });
        return
      }
      let this_=this
      this.$axios({
        method:"POST",
        url:"/api/officialWebsite/circle/praise",
        data:{id:this_.contentList[index].id,student_id:this_.studentid}
      }).then(res=>{
        // console.log(res,2323);
        if(res.code==200){
          if(this_.contentList[index].is_praise==""){
            this_.contentList[index].is_praise="111"
            this_.contentList[index].praise++
          }else{
            this_.contentList[index].is_praise=""
            this_.contentList[index].praise--
          }
        }
      })
    },
    // 获取作业列表
    getworksList() {
      this.emptys=false
      let this_ = this;
      if (this.contentList.length == 0) this.cutState = true;
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/circle/homework_list",
        data: { page: this.workpage, recommend: this.recommend,student_id:this.studentid},
      }).then((res) => {
          if(res.data.total==0){
            this.emptys=true
            this.cutState=false
            this.listLoading=false
            return
          }else{
            this.emptys=false
          }
        // console.log(res, "作业列表111");
        if (res.code == 200) {
          this.cutState = false;
          if (this_.contentList.length == 0) {
            this_.contentList = res.data.data;
          } else {
            if (res.data.data.length != 0) {
              this_.contentList = this_.contentList.concat(res.data.data);
            } else {
              this_.$notify({
                title: "暂无更多推荐内容...",
                message: "",
                showClose: false,
              });
              this.busy = true;
              this_.noMore = true;
              this.listLoading = false;
              return
            }
          }
          // console.log(res.data.data, 111111111);
          this_.contentList.forEach((el, index) => {
            el.active_content_index = 1; //圈子内容展开隐藏
            el.contentisOpen = false;
            el.heights = "";
            el.heightes = 0;
            el.visible = false;
            if (!el.ref && !el.contentstr) {
              el.ref = "contents" + index;
              el.contentstr = el.desc.replace(/[\u0391-\uFFE5]/g, "aa").length;//获取作业说明内容长度
            }
          });
        }
        this.busy = false;
        this.listLoading = false;
      });
    },
    //获取作业评论列表
    getPinglunList(hid) {
      // console.log(hid, "作业ID");
      this.comments.page = 1;
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/circle/homework_comment_list",
        data: { list_rows: 6, page: this.comments.page, homework_id: hid },
      }).then((res) => {
        // console.log(res, "作业评论列表");
        if (res.code == 200) {
          this.comments.list = res.data.data;
        }
      });
    },
    // 获取轮播图
    getbannerList() {
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/banner_lists",
        data: { list_rows: 5, page: 1, type: 3 },
      }).then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data;
        }
      });
    },
    //距顶部距离
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop;
      if (this.scrollTop > 500) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
    // 回到顶部
    goTop() {
      Bus.$emit("scrollToTop");
    },
  },
  components: {
    emptyPage,
  },
};
</script>

<style scoped>
* {
  user-select: none;
}
.circleEmpty {
  width: 1130px;
  height: 1000px;
}
.circle {
  background-color: #f5f5f5;
}
.circles {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
}
.tabs {
  width: 144px;
  height: 100%;
  box-sizing: border-box;
}
.tabs_btn {
  display: block;
  border: none;
  width: 102px;
  height: 42px;
  border-radius: 21px;
  margin: 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
}
.tabs_btn_focus {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  box-shadow: 0px 8px 7px 0px rgba(23, 58, 234, 0.13);
  background-color: #074DFE;
}
.banner {
  width: 1056px;
  height: 284px;
  border-radius: 36px;
  object-fit: cover;
}
.oneBanner{
  width: 1056px;
  height: 284px;
  border-radius: 36px;
  margin: 0 37px 50px 37px;
}
.banners {
  margin:0 0 64px 40px;
  border-radius: 36px;
  overflow: hidden;
}
.banners >>> .el-carousel__arrow--right {
  right: 20px;
}
.banners >>> .el-carousel__arrow i {
  font-size: 36px;
  font-weight: 800;
  opacity: 0.6;
}
.banners >>> .el-carousel__arrow i:hover {
  opacity: 1;
}
.circleBox {
  width: 1056px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 21px;
  margin-left: 42px;
}
.circleTitle {
  height: 100px;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 39px;
}
.circle_portrait {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.circleInformation {
  margin-left: 10px;
}
.circleInformation span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}
.circleInformation p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.circleContent {
  padding: 10px;
  /* cursor: pointer; */
  display: flex;
  flex-flow: column;
  /* align-items: flex-end; */
  width: 896px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 27px;
  margin: 0 69px 0 82px;
  /* letter-spacing:80px;      临时添加 */
}
.contentShow {
  color: #333333;
  font-size: 14px;
  line-height: 30px;
  /* height: 90px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.contentShows {
  color: #333333;
  font-size: 14px;
  line-height: 30px;
  /* height: auto; */
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; */
}
.circle_Img {
  display: grid;
  /* grid-template-columns: 33.7% 66.3%; */
  grid-template-columns: 16.8% 83.2%;
  padding: 10px;
  overflow: hidden;
  width: 956px;
  cursor: pointer;
  /* height: 330px; */
}
.circle_Img-one {
  display: grid;
  padding: 10px;
  max-width: 850px !important;
  height: 300px;
  border-radius: 20px;
  margin-left: 83px;
}
.circle_Img-four {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 10px;
  width: 808px;
  /* height: 300px; */
  border-radius: 20px;
  margin-left: 90px;
  /* height: 330px; */
}
.circle_Img-five {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 10px;
  width: 606px;
  row-gap: 10px;
  column-gap: 10px;
  /* height: 300px; */
  border-radius: 20px;
  margin-left: 90px;
  /* height: 330px; */
}
.circle_Img div {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  margin-right: 10px;
}
.circleImg-one {
  width: auto;
  max-width: 850px;
  height: 300px;
  cursor: pointer;
  border-radius: 10px;
  object-fit: contain;
  object-position: 50% 10%;
}
.divimage_four {
  display: inline-block;
}
.divimage_five {
  display: inline-block;
}
.circleImg-four {
  width: 191px;
  height: 143px;
  border-radius: 10px;
  cursor: pointer;
  object-position: 50% 10%;
}
.circleImg-four>>>.el-image__inner{
  object-fit: cover;
}
.circleImg-five {
  width: 191px;
  height: 143px;
  border-radius: 10px;
  cursor: pointer;
  object-position: 50% 10%;
}
.circleImg-five>>>.el-image__inner{
  object-fit: cover;
}
.circleIco {
  padding: 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.circleIcoTags {
  margin-left: 83px;
  margin-top: 14px;
}
.btn,
.share,
.comment,
.like,
.collection {
  display: flex;
  flex-flow: row;
  margin: 0 20px;
}
.btn img {
  width: 20px;
  height: 20px;
  margin: auto 10px;
  cursor: pointer;
}
.btn p {
  color: #999999;
  font-size: 14px;
}
.circles >>> .el-tabs--left .el-tabs__nav-wrap.is-left::after {
  display: none;
}
.circles >>> .el-tabs__active-bar {
  display: none;
}
.circles >>> .el-tabs__item.is-active {
  width: 102px;
  height: 41px;
  line-height: 41px;
  color: #ffffff !important;
  background: linear-gradient(115deg, #005aff, #3f91ff);
  box-shadow: 0px 8px 7px 0px rgba(23, 58, 234, 0.13);
  border-radius: 21px;
  display: flex;
  justify-content: center;
}
.circles >>> .el-tabs__item:hover {
  color: #303133;
}
.circles >>> .el-tag.el-tag--info {
  color: #333333;
  border-radius: 30px;
  height: 26px;
  line-height: 26px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.circles >>> .el-carousel__container {
  height: 280px;
}
.demo1 {
  height: 30px;
}
.circles >>> .el-tabs--left .el-tabs__item.is-left {
  /* margin: 15px auto; */
  margin-bottom: 15px;
}
.circles >>> .el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}
.circles >>> .el-carousel__arrow {
  top: 50%;
}
/* .authen {
  width: 20px;
  height: 23px;
  margin: -18px 0 0 3px;
} */
.circleShow{
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0861ff;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  top: -30px;
  right: -799px;
  width: 75px;
  background-color: #ffffff;
  text-align: center;
}
.circleClose {
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0861ff;
  cursor: pointer;
}
.giveLike {
  color: #fda829 !important;
}
/* .circleTip {
  width: 0;
  height: 0;
  border: 100px solid #ffffff;
  border-right: 0;
  border-left-color: #999999;
} */
.comments {
  width: 896px;
  background: #f3f3f3;
  border-radius: 20px;
  margin: 0 66px 50px 95px;
  display: grid;
}
/* .triangle {
  width: 20px;
  height: 20px;
  position: relative;
  left: 85%;
  top: 10px;
  transform: rotate(45deg);
  background: #f3f3f3;
} */
.commentsLogo {
  width: 42px;
  height: 42px;
  margin: 0 10px 0 27px;
  border-radius: 50%;
}
.contacts {
  display: flex;
  flex-flow: column;
  width: 731px;
  /* height: 40px; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* line-height: 25px; */
}
.comments-contact {
  display: flex;
  padding-top: 15px;
}
.commentsName {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0861ff;
  line-height: 25px;
}
.commentsSub {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
}
.commentTime {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  /* line-height: 30px; */
}
.commentMore {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0861ff;
  margin: 37px 0 21px 0;
  cursor: pointer;
  user-select: none;
}
.commentMore span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.commentMore i {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 900;
}
.m-top {
  margin-top: -10px;
}
.circleBox >>> .el-loading-mask {
  border-radius: 20px;
}
.listEndStyle {
  width: 200px;
  margin: 30px auto;
  font-size: 18px;
  color: #757575;
}
.goTopouter {
  position: relative;
}
.goTop {
  position: fixed;
  right: 80px;
  bottom: 140px;
  width: 50px;
  height: 50px;
  padding: 4px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
}
.goTop:hover {
  background-color: rgb(15, 111, 237);
}
.goTop > img {
  width: 42px;
  height: 42px;
}
.contentLittle {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  line-height: 30px;
}
.contentBig {
  color: #333333;
  font-size: 14px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.contentBigOpen {
  color: #333333;
  font-size: 14px;
  line-height: 30px;
}
</style>