<template>
  <div>
    <div class="tabs">

       <myTabs :list="tabsList" @update_tabs_index="tabsClick($event)"  :tabs_index='tabs_active'></myTabs>
    </div>
    <div class="tabs_info" v-loading="loading">
    <!-- 绑定邮箱 -->
      <div class="bangding_email" v-if="!current_email">
        <div class="bangding_email_text">可用邮箱找回密码</div>
        <div class="" >
          <el-form label-width="80px">
            <el-form-item label="邮箱: ">
              <el-input placeholder="请输入邮箱地址" v-model="bind_email.email">
              </el-input>
            </el-form-item>
            <el-form-item label="验证码: ">
              <el-input placeholder="请输入验证码" v-model="bind_email.code">
                 <template #suffix>
                  <!-- <span style="color:#007DFC">获取验证码</span> -->
                  <button type="button" :class="{ no_email_color: !is_sendcode }" :disabled="disabled" style="color:#007DFC" @click="sendcode">{{ btntxt }}</button>
                 </template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="confirm_save_btn" @click="confirm_save_btn">   
          确认保存
        </div>
      </div>

      <!-- 解绑/换绑 -->
      <div class="email_operation" v-if="!!current_email">
        <div>
          <div class="email_operation_ID">你的ID: {{ current_ID }}</div>
          <div class=" current_email">当前绑定的邮箱账号: {{ current_email }}</div>
        </div>

        <div class="email_operation_btn_box">
          <button class="email_operation_btn" @click="change_email">更换邮箱</button>
          <button class="email_operation_btn" @click="unbind">解除绑定</button>
        </div>
      </div>

      <!-- 验证码弹出框 -->
      <div class="code_dialog" v-if="code_dialog">验证码发送成功, 请前往邮箱查看</div>
    </div>

    <!-- 解绑dialog -->
      <el-dialog 
        :visible.sync="unbind_email_dialog" 
        class="unbind_email_css" 
        width="361px" 
        :show-close="false" 
      >
        <div class="unbind_email_dialog_text">
          确定解绑邮箱吗？
        </div>
        <div class="unbind_email_dialog_btn">
          <button class="unbind_email_btn" @click="ensure_unbind_email">确定</button>
          <button class="unbind_email_btn unbind_email_cancel_btn" @click="unbind_email_dialog = false">取消</button>
        </div>
      </el-dialog>
  </div>
  <!-- var reg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/; 邮箱正则
  return reg.test(email); -->
</template>

<script>
export default {
  data() {
    return {
      loading:false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      tabsList: [
        {
          tabs_title: '绑定邮箱'
        }
      ],
      tabs_active: 0,
      disabled: false,
      time: 60,
      btntxt: "发送验证码",
      code_dialog: false,  // 发送验证码成功的弹框

      // 绑定邮箱参数
      bind_email:{
        email:'',
        code:''
      },

      unbind_email_dialog: false,

      // is_email_form: true,
      current_email: '',    // 当前绑定的邮箱
      current_ID: ''
    }
  },
  methods: {
    // tabs切换
    tabsClick(index) {
      console.log(index);
      this.tabs_active = index
    },
    // 发送验证码
    sendcode() {
      if (!this.bind_email.email) {
        this.$message.error('请输入邮箱账号')
        return
      }
      this.loading = true
     
      this.$axios({
        method:'POST',
        url: 'api/officialWebsite/computer/yuan_cheng/send_bind_email',
        data:{
          email: this.bind_email.email,
        }
      }).then(res => {
        if (res.code === 200) {
          this.time = 60;
          this.code_dialog = true 
          setTimeout(() => {
            this.code_dialog = false
          },2000)
          this.timer();
        } else{
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
        }
        this.loading = false
      })
   },
   // 验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "秒";
      setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "重新获取";
        this.disabled = false;
      }
    },

    // 确定保存邮箱
   confirm_save_btn() {
    this.loading = true
    if (this.bind_email.code) {
        this.$message.warning('请输入验证码')
      }
      this.$axios({
        method:'POST',
        url:'api/officialWebsite/computer/yuan_cheng/binding_email',
        data:{
          ...this.bind_email
        }
      }).then(res => {
         if(res.code === 200) {
          this.look_binding_email()
          this.$notify({
            title: '成功',
            message: '绑定邮箱成功',
            type: 'success'
          });
         } else{
          this.$notify.error({
            title: '提示',
            message: `${res.msg}`
          });
         }
         this.loading = false
      })
   },

   // 查看绑定邮箱
   look_binding_email() {
     let _this = this 
     _this.loading = true
     _this.$axios({
      method: 'POST',
      url: 'api/officialWebsite/computer/yuan_cheng/show_bind_email'
     }).then(res => {
      if (res.code === 200) {
        _this.current_ID = res.data.id
        this.current_email = res.data.email
      }else {
        this.$notify.error({
          title: '提示',
          message: `${res.msg}`
        });
      }
      _this.loading = false 
     })
   },

   // 确定解除绑定邮箱
   ensure_unbind_email() {
    let _this = this 
    _this.$axios({
      method: 'POST',
      url: 'api/officialWebsite/computer/yuan_cheng/remove_bind_email'
    }).then(res => {
      if (res.code === 200) {
         _this.current_email = ''
         _this.$notify({
          title: '成功',
          message: '解绑邮箱成功',
          type: 'success'
        });
      }else {
        _this.$notify.error({
          title: '提示',
          message: `${res.msg}`
        });
      }
      _this.unbind_email_dialog = false
      _this.loading = false
    })
   },

   // change_email  更换邮箱
   change_email() {
    console.log('change_email');
    this.current_email = ''
   },

   // 解绑显示dialog
   unbind() {
    this.unbind_email_dialog = true
   }
  },
  mounted () {
    // 防止点两次才会触发
    // this.sendcode()
  },
  computed: {
    // 未输入邮箱前 判断 发送验证码颜色
    is_sendcode() {
      return !!this.bind_email.email
    }
  },
  created () {
    this.look_binding_email()
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

.tabs_info {
  min-height: 578px;
  border-radius: 0px 21px 21px 21px;
  // background: rgba(255, 255, 255, 1)
  padding: 88px 81px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
  filter: drop-shadow(0px -1px 1px #F5F5F5);
}
.bangding_email_text{
  font-size: 13px;
  color: #9B9B9B;
}
.bangding_email{
   width: 380px;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.confirm_save_btn{
  width: 150px;
  height: 41px;
  color: #FFFFFF;
  text-align: center;
  line-height: 41px;
  border-radius: 20px 20.8392px 20.8392px 20.8392px;
  background: rgba(0,125,252,1);
}

.code_dialog{
  position: absolute;
  left: 35%;
  top: 40%;
  width: 278px;
  height: 57px;
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  line-height: 57px;
  color: #FFFFFF;
  background:rgba(0,0,0,0.6);
}
.no_email_color{
  color: rgba(192, 196, 204) !important;
}

.email_operation{
  height: 253px;
  min-width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email_operation_ID{
  font-size: 20px;
  color: #999999;
  margin-bottom: 20px;
}

.current_email{
  font-size: 20px;
  color: #2C2C2C;
}

.email_operation_btn{
  width: 150px;
  height: 41px;
  border-radius: 20px;
  color: #FFFFFF;
  font-size: 16px;
  background: rgba(0,125,252,1);
}

.email_operation_btn_box{
  width: 370px;
  display: flex;
  justify-content: space-between;
}
</style>

<style lang="less" scoped>
/deep/ .el-input__inner {
  width: 276px;
  height: 40px;
  border: none;
  border-radius: 20px 20px 20px 20px;
  background: rgba(231,231,231,1);
}

/deep/ .el-input__suffix{
  right: 45px
}

/deep/ .el-form-item__label{
  width: 60px;
  height: 42px;
  line-height: 42px;
  padding-right: 10px;
  text-align: justify;

  font-size: 16px;
  font-family: 'Microsoft YaHei';
  color: #2C2C2C;
}
/deep/ .el-form-item__label::after{
  display: inline-block;
  width: 100%;
  content: ""
}
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 150px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}



 /deep/ .unbind_email_css .el-dialog {
    height: 252px;
    margin-top: 40vh !important;
    border-radius: 22px;
    background: rgba(255,255,255,1);
  }
 /deep/ .unbind_email_dialog_text  {
    font-size: 22px;
    color: #5A5A5A;
    text-align: center;
    padding: 0 0 75px 0;

 }
 .unbind_email_dialog_btn{
  display: flex;
  justify-content: space-between;
 }
 .unbind_email_btn{
  width: 98px;
  height: 43px;
  border-radius: 21px;
  text-align: center;
  line-height: 43px;
  font-size: 20px;
  color: #FFFFFF;
  background: rgba(0,125,252,1);
 }
 
 .unbind_email_cancel_btn{
  background: rgba(164,164,164,1);
 }
 /deep/ .el-dialog__body {
  padding: 30px 40px;
 }
</style>