<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "APP",
  created() {
    this.getSelectListS();
  },
  methods: {
    // 封装一个查询所有下拉框数据的方法
    getSelectListS() {
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/get_post_search",
        // data: {
        //     type: 1,
        //     page: 1,
        //     list_rows: 10
        // },
      }).then((res) => {
        localStorage.setItem(
          "educationalOptions",
          JSON.stringify(res.data.search_education)
        );
        localStorage.setItem(
          "employerOptions",
          JSON.stringify(res.data.search_employer_name)
        );
        localStorage.setItem(
          "majorOptions",
          JSON.stringify(res.data.search_major)
        );
        localStorage.setItem(
          "positionNameOptions",
          JSON.stringify(res.data.search_post_name)
        );
        localStorage.setItem(
          "workLocationOptions",
          res.data.search_work_address
        );
      });
    },
  },
};
</script>

<style>
@import url("assets/css/index.css");
</style>
