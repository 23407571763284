import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../axios"
import request from "../axios/request"
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:null,
    myHonor_img: {},
	tim: null,
	imConfig: {}
  },
  mutations: {
    updateUserInfo(state,userInfo){
        state.userInfo = userInfo;
    },
    setMyHonor_img(state, ima_obj) {
      state.myHonor_img = ima_obj;
      localStorage.setItem("ima_obj", JSON.stringify(ima_obj));
    },
	setTim(state, tim) {
		console.log('storeTim:', tim)
		state.tim = tim
	},
	setImConfig(state, data) {
		state.imConfig = data
	},
  },
  actions: {
    //拉取最新的用户信息
    pullUserInfoByToken(context){
      return new Promise((resolve, reject) => {
        request({
          method: 'POST',
          url: '/api/officialWebsite/computer/yuan_cheng/the_personal_data',
        }).then(res => {
          if(res.code != 200){
            //清空已保存的用户信息
            context.commit("updateUserInfo", null);
            //清除登陆token
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            // router.push('/')
            resolve(res);
          }else{
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            context.commit("updateUserInfo", res.data)
            resolve(res);
            //更新用户最新的cookie和session
            // axios({
            //   method: "POST",
            //   url: process.env.VUE_APP_BASE_URL_YUANCHENG + "/index/login/setSessionAndCookie",
            //   data:{
            //     uid:res.data.id,
            //     token:localStorage.getItem("token")
            //   }
            // }).then(() => {
            //   resolve(res);
            // })
          }
        })
      })
    },
	getSiteConfig(context) {
		return new Promise((resolve, reject) => {
			if(context.state.imConfig && context.state.imConfig.sdk_app_id) {
				resolve(context.state.imConfig)
				return
			}
			axios({
				method: "POST",
				url: "api/officialWebsite/site_config",
				data: {
					url: "www.yuanchengedu.net",
					key: "im"
				}
			}).then(res => {
				console.log('获取IM配置:', res)
				if(res.code == 200) {
					context.commit('setImConfig', res.data)
					resolve(res.data)
				} else {
					reject(res)
				}
			}).catch(error => {
				reject(error)
			})
		}) 
	}
	
  },
  modules: {
  }
})
