<template>
  <div>
    <!-- 人才中心 -->
    <!-- <empty-page></empty-page> -->
    <img
      style="width: 100%; height: auto; padding: 0; margin: 0"
      src="../../assets/image/personnel.png"
    />
    <div class="search">
      <div class="search_left">
        <div class="region">
          <el-select v-model="value" @change="seradd"   placeholder="全国">
            <el-option
              v-for="item in regionList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              
            >
            </el-option>
          </el-select>
        </div>
        <div></div>
        <div>
          <el-input
            placeholder="请输入院校、专业"
            style="width: 800px"
            v-model="searchData"
            clearable
          >
          </el-input>
        </div>
      </div>

      <div>
        <el-button type="primary" size="default" @click="searchUser()"
          >搜索</el-button
        >
      </div>
    </div>
    <div
      style="
        background-color: #f5f5f5;
        width: 100%;
        height: ayto;
        margin-top: -70px;
      "
    >
      <div id="content"></div>

      <!-- 职业类别 -->
      <div
        style="
          display: flex;
          align-items: flex-start;
          flex-flow: column;
          width: 1200px;
          padding-top: 90px;
          margin: 0 auto;
        "
      >
        <div class="title">
          <span>职位类别</span>
        </div>
        <div style="display: flex; flex-flow: row; position: relative">
          <div class="occupation_tag_view">
            <div
              class="tagNmae pointer"
              @click="getAllUserList"
              :class="active_categoryTag_index == 202 ? 'active_tag' : ''"
            >
              全部
            </div>
            <div
              class="tagNmae pointer"
              :class="[
                active_categoryTag_index == item.id &&
                active_categoryTag_index != 202
                  ? 'active_tag'
                  : '',
                index > 8 ? 'position_none' : 'position_block',
                show ? 'position_none' : 'position_block',
              ]"
              v-for="(item, index) in categoryList"
              :key="index"
              @click="clickCategoryTag(item, index)"
            >
              {{ item.post_name }}
            </div>
          </div>
          <div class="icon">
            <el-button
              size="default"
              :icon="icons"
              @click="openSchool"
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 学历 -->
      <div
        style="
          display: flex;
          align-items: flex-start;
          flex-flow: column;
          width: 1200px;
          padding-top: 40px;
          margin: 0 auto;
        "
      >
        <div class="title">
          <span>学历</span>
        </div>
        <div>
          <div class="occupation_tag_view">
            <div
              class="tagNmae pointer"
              @click="getAlleduList"
              :class="active_educationTag_index == 202 ? 'active_tag' : ''"
            >
              全部
            </div>
            <div
              class="tagNmae pointer"
              :class="
                active_educationTag_index == item.id &&
                active_educationTag_index != 202
                  ? 'active_tag'
                  : ''
              "
              v-for="(item, index) in educationList"
              :key="index"
              @click="clickEducationTag(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 展示列表 -->
      <div class="userList" v-loading="loading">
        <!-- <div class="users" v-for="item in search(searchData)" :key="item.id"> -->
        <div class="users" v-for="item in userList" :key="item.id">
          <div class="userImg" v-if="item.gender == '男'">
            <img :src="man" alt="" />
          </div>
          <div class="userImg"  v-if="item.gender == '女'">
            <img :src="woman" alt="" />
          </div>
          <!-- <div class="userImg"  v-if="item.id %5== 1">
            <img :src="womanImages" alt="" />
          </div> -->
          <div class="userInfo">
            <div class="userTitle">
              <span>{{ item.name }}</span>
              <p>{{ item.postName }}</p>
            </div>  
            <div>
              <el-descriptions :column="column">
                <el-descriptions-item label="年龄">{{
                  item.age
                }}</el-descriptions-item>
                <el-descriptions-item label="地区">{{
                  item.region
                }}</el-descriptions-item>
                <el-descriptions-item label="专业">{{
                  item.major
                }}</el-descriptions-item>
                <el-descriptions-item label="院校">{{
                  item.colleges
                }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div class="userBtn">
            <el-button type="info" size="default" @click="details"
              >查看详情</el-button
            >
          </div>
          <div class="userEdu">
            <span>{{ item.education_name }}</span>
          </div>
        </div>
      </div>

      <div class="ossImgBox" v-if="this.userList.length == 0">
        <el-image
          class="noword"
          src="https://static.shanchuangjiaoyu.com/yc_baidu/noword.png"
        ></el-image>
        <div class="ossImgText">暂无内容哦~</div>
      </div>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          v-if="this.userList.length > 0"
          background
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next"
          :total="page.total"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rencaizhongxin",
  data() {
    return {
      regionList: [],
      value: "",
      searchData: "",
      page: {
        total: 1, //总条目数
        pageSize: 8, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      column: 1,
      active_categoryTag_index: 202,
      active_educationTag_index: 202,
      userList: [],
      // categoryList: [],
      educationList: [
        {
          id: 1,
          name: "高中",
        },
        {
          id: 2,
          name: "中专",
        },
        {
          id: 3,
          name: "大专",
        },
        {
          id: 4,
          name: "本科",
        },
        {
          id: 5,
          name: "硕士研究生",
        },
        {
          id: 6,
          name: "博士研究生",
        },
        {
          id: 7,
          name: "博士以上",
        },
      ],
      education: 0,
      category: 0,
      educations: 0,
      categorys: 0,
      searchsValue: 0,
      province: "",
      categoryList: [], //数据集合
      search_type: 4,
      show: true,
      icons: "el-icon-caret-bottom",
      loading: false,
      manAvatar: [
        "https://static.shanchuangjiaoyu.com/uploads/20210729/aedab0b2e3af93d88c845e96b6098568.jpg",
        "https://static.shanchuangjiaoyu.com/uploads/20210729/5a2d882fa773df464424e945f2659d59.jpg",
        "https://static.shanchuangjiaoyu.com/uploads/20210729/37adf01b0fa415851fc2a4ddb9e9f745.jpg",
      ],
      womanAvatar: [
        "https://static.shanchuangjiaoyu.com/uploads/20210810/8c21ea1532eec6c60d575a443cfbe4d1.jpg",
        "https://static.shanchuangjiaoyu.com/uploads/20210810/eea1c219082cfab240aee990346d65d5.jpg",
        "https://static.shanchuangjiaoyu.com/uploads/20210907/75b520b015f9a1c133bdaadeeba7320b.jpg",
      ],
      man:'https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/TQoQTWWSHZy4mPhXulFI9UI5wTNizEsKAegHYqjo.png',
      woman:'https://devstatic.yc-edu.online/api/upload/crm/2022/03/14/PH320lRuVdvdMqI6C0ySLpjehA1WuqfL3hkp7zYp.png',
      manImages: [],
      womanImages: [],
    };
  },

  created() {
    this.getRegion();
    this.getCategoryList();
    this.getUserList();
    this.randomAvatar();
        window.addEventListener("popstate", function(e) { 
      location.reload();
      // router.go(0)
      // alert(123123)
        //根据自己的需求实现自己的功能 
}, false);
    // this.getEducationList();
  },
  mounted() {
    window.addEventListener("mousewheel", this.handleScroll);
  },
  methods: {
    seradd(e){
    
    },
    openSchool() {
      this.show = !this.show;
      if (this.show) {
        this.icons = "el-icon-caret-bottom";
      } else {
        this.icons = "el-icon-caret-top";
      }
    },
    handleScroll(m) {
      var direction = m.deltaY > 0 ? "down" : "up";
      // console.log(direction);
      if (direction == "down") {
        // console.log("下");
      }
      if (direction == "up") {
        // console.log("上");
      }
    },
    // search(searchData) {
    //   return this.userList.filter((item) => {
    //     if (
    //       item.post_name.includes(searchData) ||
    //       item.colleges.includes(searchData) ||
    //       item.major.includes(searchData)
    //     ) {
    //       return item;
    //     }
    //   });
    // },
    //搜索
    searchUser() {
      this.province = this.value.toString();
      this.loading=true
      // console.log(this.province,'地区');
      // if (this.searchData) {
      this.search_type = 4;
      this.getUserList1();
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading=false
      }, 800);
    
      this.searchData = "";
      // this.value = "";
      // this.province='';
    },
    //获取学历 列表
    // getEducationList() {
    //   this.$axios({
    //     method: "GET",
    //     url: "/api/part/getEducationList",
    //   })
    //     .then((res) => {
    //       // console.log(res,333);
    //       this.educationList = res;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    //获取全部
    getAllUserList() {
      this.active_categoryTag_index = 202;
      this.category = 0;
      this.loading = true;
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.page.currentPage = 1;
      this.getUserList();
    },
    //获取全部学历
    getAlleduList() {
      console.log(this.category,'312312321');
      this.active_educationTag_index = 202;
      this.education = 0;
      this.loading = true;
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.getUserList();
      this.page.currentPage = 1;
      // this.getUserList1();
    },
    //获取用户列表
    getUserList() {
      let data={}
      
      if(this.categorys=this.category){
        data.post=this.categorys
      }
      if(this.educations=this.education){
        data.education=this.educations
      }
      if (this.province) {
        data.region_code= this.province;
      }
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/talent_center/personnel_list",
        data:data,
      })
        .then((res) => {
          
         
               for (var i = 0; i < res.data.data.length; i++) {
            
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
         if (res) {
            // this.loading=false;
          
            this.page.total = res.data.total;
            this.page.pageSize = res.data.per_page;
            this.page.currentPage = res.data.current_page;
            this.userList = res.data.data;
          }
          
          //   res.data.data[i].region.forEach(e=> {
          //     console.log(e)
          //  });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 随机头像
    randomAvatar() {
      // var index =
      //   this.manAvatar[Math.floor(Math.random() < this.manAvatar.length)];
      // this.manImages = this.manAvatar[index];

      // var indexs =
      //   this.womanAvatar[Math.floor(Math.random() < this.womanAvatar.length)];
      // this.womamImages = this.womanAvatar[indexs];
        // this.manImages = this.manAvatar[Math.floor(Math.random() < this.manAvatar.length)];
        // this.womamImages =  this.womanAvatar[Math.floor(Math.random() < this.womanAvatar.length)]; 
        this.manImages=this.manAvatar[Math.floor(Math.random() * this.manAvatar.length)]
        this.womanImages=this.womanAvatar[Math.floor(Math.random() * this.womanAvatar.length)]
        // console.log(this.manImages,'this.manImages');
        // this.womamImages=Math.floor(Math.random(this.womanAvatar.length-1))
    },
    //点击定位
    changePage(data) {
      console.log(data, "dara");
      this.page.currentPage = data;
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = true;
      }, 100);
      this.paging();
    },
    //分页  
    paging() {
      let data={}
      if(this.page.currentPage){
        data.page=this.page.currentPage
      }
      if(this.categorys){
        data.post=this.categorys
      }
      if(this.education){
        data.education=this.education
      }
      if (this.province) {
        data.region_code= this.province;
      }
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/talent_center/personnel_list",
        data:data,
      })
        .then((res) => {
          console.log(res,'ressss');
          setTimeout(() => {
            this.loading = false;
          }, 200);
              for (var i = 0; i < res.data.data.length; i++) {
           console.log(res.data.data[i].region)
            
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
        
          
          //   res.data.data[i].region.forEach(e=> {
          //     console.log(e)
          //  });
          }
          this.userList = res.data.data;
          // this.page.total = res.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //搜索获取用户列表
    getUserList1() {
      let data = {};
      if (this.searchData) {
        data.keywords = this.searchData;
        data.search_type = 4;
        
       
      }
      if(this.category){
       data.post=this.category
      }
      if(this.education){
         data.education=this.education
      }
      // if(this.searchData){
      //   data.search_type=4
      // }
      if (this.province) {
        data.region_code= this.province;
      }
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/talent_center/personnel_list",
        data: data,
      })
        .then((res) => {
       
               for (var i = 0; i < res.data.data.length; i++) {                 
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
                
        
          }
          this.userList = res.data.data;
          this.page.total = res.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取地区
    getRegion() {
      this.$axios({
        method: "GET",
        // url: "/api/part/getProvinceList",
        url: "api/admin/gettecarealist",
      })
        .then((res) => {
        res.data.unshift({
          code:"",
          name:"全国"
        })
       
          this.regionList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取职业类别
    getCategoryList() {
      this.$axios({
        method: "POST",
        url: "/api/admin/talent/postManage/get_all_job_type_data",
      })
        .then((res) => {
     

          this.categoryList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //点击选中职位分类
    clickCategoryTag(item, index) {
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = true;
      }, 100);
      this.category = item.id;
      this.categorys = item.id;
      this.active_categoryTag_index = item.id;
      this.page.currentPage = 1;
      let data = {};
      console.log(this.province)
     if (this.province) {
        data.region_code= this.province;
      }
      if (this.category) {
        data.post = this.category;
      }
      if (this.education == 0) {
        this.$axios({
          method: "POST",
          url: "/api/officialWebsite/talent_center/personnel_list",
          data: data,
        })
          .then((res) => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
               for (var i = 0; i < res.data.data.length; i++) {                 
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
                
        
          }
            this.userList = res.data.data;
            this.page.total = res.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
          if(this.province){
          data={
            post: this.category,
            education: this.education,
            region_code:this.province
          }
        }else{
            data={
            post: this.category,
            education: this.education,
          }
        }
        this.$axios({
          method: "POST",
          url: "/api/officialWebsite/talent_center/personnel_list",
          data
        })
          .then((res) => {
            console.log(res, "res");
            setTimeout(() => {
              this.loading = false;
            }, 500);
               for (var i = 0; i < res.data.data.length; i++) {                 
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
                
        
          }
            this.userList = res.data.data;
            this.page.total = res.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    //点击选中学历
    clickEducationTag(item, index) {
    
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = true;
      }, 100);
      var data={}
      this.education = item.id;
      this.educations = item.id;
      console.log(this.category, "this.category");
      console.log(item.id, "123123");
      this.active_educationTag_index = item.id;
      this.page.currentPage = 1;
      
       if (this.education) {
        data.education= this.education;
      }
      if (this.province) {
        data.region_code= this.province;
      }
      // setTimeout(() => {
      document.querySelector("#content").scrollIntoView(true);
      // this.loading=true;
      // }, 800);
      if (this.category == 0) {
        this.$axios({
          method: "POST",
          url: "/api/officialWebsite/talent_center/personnel_list",
          data
        })
          .then((res) => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
            console.log(res, "res");
               for (var i = 0; i < res.data.data.length; i++) {                 
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
                
        
          }
            this.userList = res.data.data;
            this.page.total = res.data.total;
            // this.loading=false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if(this.province){
          data={
            post: this.category,
            education: this.education,
            region_code:this.province
          }
        }else{
            data={
            post: this.category,
            education: this.education,
          }
        }
        this.$axios({
          method: "POST",
          url: "/api/officialWebsite/talent_center/personnel_list",
          data
        })
          .then((res) => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
            console.log(res, "res");
               for (var i = 0; i < res.data.data.length; i++) {                 
            if(res.data.data[i].region){
               res.data.data[i].region =res.data.data[i].region[res.data.data[i].region.length-1].title
              //  res.data.data[i].aid=res.data.data[i].region[res.data.data[i].region.length-1].id
            }
                
        
          }
            this.userList = res.data.data;
            this.page.total = res.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // console.log(this.education, 111);
    },
    //查看详情
    details() {
      this.$confirm(
        "只有企业账号才能查看简历, 您可以联系我们注册企业账号",
        "查看简历",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // type: 'warning'
        }
      )
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   // message: '删除成功!'
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
    },
  },
  components: {},
};
</script>

<style  scoped>
.search {
  width: 1200px;
  height: 70px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  margin: 0 auto;
  z-index: 10;
  top: -36px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
}
.search_left {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.region {
  padding: 12px;
}
.title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.occupation_tag_view {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 1145px;
  /* overflow: auto; */
}
.tagNmae {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  background: #fff;
  padding: 6px 28px;
  margin: 15px 15px 0 0;
  flex-shrink: 0;
  border-radius: 8px;
  background: #ffffff;
  transition: .3s;
}
.tagNmae:hover{ background: #3c71ff;color: #fff;}
.active_tag {
  background: #3c71ff;
  color: #fff;
}
.userList {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 1200px;
  margin: 60px auto;
  column-gap: 20px;
}
.users {
  width: 590px;
  height: 240px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-flow: row;
  margin-top: 34px;
  transition: .3s;
  cursor: pointer;
}
.users:hover{
      transform: translateY(-10px);
    box-shadow: #888 -1px 7px 11px 0px;
}
/* .users:hover .el-button--info {
    color: #ffffff;
    background: #0f6fec;
    border-color: #0f6fec;
} */
.userImg img {
  width: 76px;
  height: 76px;
  /* border-radius: 50%; */
  margin-top: 8px;
}
.userInfo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 28px;
}
.userTitle {
  display: flex;
  flex-flow: row;
  color: #333333;
  /* align-items: flex-end;
  margin-bottom: 18px; */
  align-items: baseline;
  margin-bottom: 16px;
}
.userTitle span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.userTitle p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0f6fec;
  margin-left: 20px;
}
.userBtn {
  display: flex;
  align-items: flex-end;
}
.userEdu {
  flex: 0 0 100px;
  margin-top: 18px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: end;
}
.paging {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  padding-bottom: 109px;
}
.ossImgBox {
  margin: 100px auto;
  width: 40%;
  text-align: center;
}
.color {
  background-color: #f1522a;
  color: #ffffff;
}
.position_none {
  display: none;
}
.position_block {
  display: block;
}
.icon {
  position: absolute;
  left: 100%;
  margin-top: 10px;
}
.el-button{transition: .3s;}
/deep/ .el-button:focus {
  background: none;
  color: none;
  border-color: none;
}
/deep/ .el-button:hover {
  /* background: none; */
  /* color: none; */
  /* border-color: none; */
}
/deep/ .el-select {
  width: 100px;
}
/deep/ .el-input__inner {
  border: none;
}
/deep/ .el-button--primary {
  width: 132px;
  height: 50px;
  background: #0f6fec !important;
  border-radius: 8px;
  border-color: none !important;
}
div/deep/ .el-button--info {
  width: 128px;
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  position: relative;
  left: 80px;
  font-size: 16px;
}
div/deep/
.el-button--info:hover {
  color: #ffffff;
  background: #0f6fec;
  border-color: #0f6fec;
}
/deep/ .el-descriptions-item__container {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #333333;
}

/deep/ .el-pagination.is-background .el-pager li {
  background-color: #ffffff;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  transition: .5s;
}
/deep/ .el-pagination span:not([class*="suffix"]) {
  min-width: 85.5px;
  height: 36px;
  line-height: 36px;
  background-color: #ffffff;
  border-radius: 4px;
}
/deep/ .el-button {
  background: none;
  border: none;
  font-size: 20px;
}
</style>
<style>
.el-message-box__headerbtn .el-message-box__close {
  color: #ffffff !important;
}
.el-message-box {
  overflow: initial !important;
  border: none !important;
}
.el-message-box__headerbtn {
  top: -10px !important;
  right: -10px !important;
  background: #c82114  !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
  border: solid 2px #ffffff !important;
}
.el-message-box__header {
  background: #c82114 !important;
}
.el-message-box__title {
  display: flex;
  justify-content: center;
  font-size: 16px !important;
  font-weight: 700;
  color: #ffffff !important;
}
.el-message-box__content {
  padding: 35px 15px !important;
  text-align: center;
}
.el-message-box__btns {
  text-align: center !important;
}
.el-button--small {
  background-color: #c82114 !important;
  color: #ffffff !important;
  border: none !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{color: #FFF !important;background-color: #074DFE !important;}
/* .el-pagination span:hover{color: #FFF !important;background-color: #074DFE !important;} */
.el-message-box__btns button:nth-child(1){background-color: #ccc !important;}
</style>