<template>
  <!-- 登录页面 -->
  <!-- 外层div -->
  <div class="b_g" v-loading = 'loading'>
    <!-- 右上图片 -->
    <span>
      <img class="you_shang" src="../assets/image/loginTop.png" alt="" />
    </span>
    <!-- 左下图片 -->
    <span>
      <img class="zhuo_xia" src="../assets/image/loginLower.png" alt="" />
    </span>
    <!-- 登录区 -->
    <div class="deng_lu">
      <!-- 先行渲染登录选择区域 -->
      <div class="deng-lu-xuan-zhe" v-if="ZhangHaoKuaiGuan == 1">
        <div class="yuan-cheng-jiao-yu">
          <img class="logo" src="../assets/image/logo_top.svg" alt="" />
        </div>
        <button
            @click="login(3)"
            class="zhang-hao-deng-lu xiao-shou"
            type="primary"
        >
          <span class="shouji xiao-shou"> </span>
          账号登录
        </button>
        <button @click="login(2)" class="wei-xin-deng-lu xiao-shou" type="primary" round>
          <img src="../assets/image/wx_logo.png" alt="">
          <span style="margin-left: 9px">微信登录</span>
        </button>
      </div>
      <!-- 账号登录框 -->
      <div class="deng_lu_kuang" v-if="ZhangHaoKuaiGuan == 3">
        <span class="login-pass-title">账号登录</span>
        <el-form
          :model="Denglu"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <!-- 账号 -->
          <el-form-item class="zhang-hao" prop="account" label-width="0px">
            <el-input
              class="shu_ru_kuang shu-ru-kuang-top1"
              placeholder="请输入账号/手机号"
              v-model="Denglu.account"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password" label-width="0px">
            <span class="shu-ru-kuang-wai-kuang2"></span>
            <el-input
              :type="this.kaiguan"
              class="shu_ru_kuang shu-ru-kuang-top2"
              placeholder="请输入密码"
              v-model="Denglu.password"
            >
            </el-input>
            <!-- 控制小眼睛开关的盒子 -->
            <span @click="KaiGuanXiaoYanJing">
              <span v-if="yan" class="yan-jing-guan"></span>
              <span v-else class="yan-jing-kai"></span>
            </span>
          </el-form-item>
          <!-- 登录按钮 -->
          <el-form-item prop="password" label-width="0px">
            <button class="an_niu" @click="zhanghaodenglu" type="button" round>登录</button>
          </el-form-item>
          <div class="JiZhu">
            <el-checkbox class="fu-xuan-kuang" v-model="JiZhuMiMa"
              >记住密码</el-checkbox
            >
            <span class="zhao-hui-mi-ma" @click="zhaohuimima">找回密码</span>
          </div>
          <div class="tong-yi">
            <el-checkbox class="fu-xuan-kuang" v-model="TongYiXieYi"></el-checkbox>
            <span style="margin-left: 12px">同意</span>
            <span class="xue-yi" @click="login_xieyi(1)">《注册协议》</span>
            <span> 及 </span>
            <span class="xue-yi" @click="login_xieyi(2)">《隐私政策》</span>
          </div>
        </el-form>
      </div>
      <!--微信二维码-->
      <div style="height: 100%" v-if="ZhangHaoKuaiGuan == 2">
        <div id="login_container" style="width: 100%; height: 100%; text-align: center; padding-top: 16%">
        </div>
      </div>
      <!--找回密码-->
      <div v-if="ZhangHaoKuaiGuan == 4" style=" width: 86%; margin: auto; margin-top: 35px;">
        <div>
          <div style="display: flex; justify-content: space-between">
            <div style="font-size: 26px; font-weight: bold">找回密码</div>
            <div style="cursor: pointer" @click="fanhuidenglu">
              <img style="width: 26px; height: 26px" src="../assets/image/fanhui.png" alt="">
              <span style="font-size: 16px; font-weight: bold; color: #888888">返回登录</span>
            </div>
          </div>
          <div style="margin-top: 26px">
            <!-- 账号 -->
            <el-form :model="ruleForm" :rules="rules_zhuanghuimima" ref="ruleForm" class="demo-ruleForm">
              <el-form-item prop="tel">
                <div>
                  <el-input class="zhanghao_shuru" placeholder="请输入手机号" type="text"  v-model="ruleForm.tel"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="code">
                <div style="margin-top: 16px; display: flex; justify-content: space-between; align-items: center">
                  <el-input class="zhanghao_yanzhnegma" placeholder="请输入验证码" type="text" v-model="ruleForm.code" auto-complete="new-password"></el-input>
                  <div v-show="code_show" style="width: 266px; margin-left: 26px; text-align: right; font-size: 26px; color: #FF7C06; cursor: pointer" @click="huoquyanzhengma">获取验证码</div>
                  <div v-show="!code_show" style="width: 266px; margin-left: 26px; text-align: right; font-size: 26px; color: #FF7C06; cursor: pointer" >{{count}} s后重发</div>
                </div>
              </el-form-item>
              <el-form-item prop="pass1">
                <div style="margin-top: 16px;">
                  <el-input class="zhanghao_mima" placeholder="请输入密码" :type="kaiguan" v-model="ruleForm.pass1" auto-complete="new-password"></el-input>
                  <div @click="KaiGuanXiaoYanJing">
                    <span v-if="yan" class="zhaohuimima_yan-jing-guan"></span>
                    <span v-else class="zhaohuimima_yan-jing-kai"></span>
                  </div>
                  <div @click="zhaohuimima_qingkong">
                    <span class="zhaohuimima_guanbi"></span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="pass2">
                <div style="margin-top: 16px;">
                  <el-input class="zhanghao_mima_queren" placeholder="请输入确认密码" :type="kaiguan2" v-model="ruleForm.pass2"></el-input>
                  <div @click="KaiGuanXiaoYanJing_querenmima">
                    <span v-if="yan2" class="zhaohuimima_yan-jing-guan"></span>
                    <span v-else class="zhaohuimima_yan-jing-kai"></span>
                  </div>
                  <div @click="zhaohuimima_qingkong_queren">
                    <span class="zhaohuimima_guanbi"></span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <div style="text-align: center">
                  <el-button style="background-color: #ff7c06; border: 0; color: #FFFFFF" @click="chongzhimima_queding">确定</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 微信绑定二维码 -->
      <div class="wei-xin-qu">
        <h6 class="wei-xin-bang-ding">微信绑定</h6>
        <!-- 弄完可以封装成组件 -->
        <img
          src="https://open.weixin.qq.com/connect/qrcode/091kAQt52SvW000J"
          alt=""
        />
        <!-- 图片下边文字提示 -->
        <div class="wen-zhi-ti-shi">
          <p class="wen-zhi-shang">使用微信扫一扫绑定</p>
          <p>「精研学堂教育」</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "emptyMap",
  data() {
    return {
      loading: false,
      // 登录
      Denglu: {
        account: "",
        password: "",
      },
      // 复选框
      JiZhuMiMa: true,
      TongYiXieYi: true,
      // 账号开关
      ZhangHaoKuaiGuan: 1,
      //眼睛开关
      yan: true,

      kaiguan: "password",

      //找回密码的眼睛的开关
      yan2: true,
      kaiguan2: "password",
      // 微信绑定对象
      wxData: {},

      // 表单验证
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur",},
        ],
      },

      weixinerweima: "",     //微信二维码数据

      union_id: '',        //union_id

      state: '',         //学员id

      ruleForm: {
        tel: '',
        code: '',
        pass1: '',
        pass2: '',
      },
      rules_zhuanghuimima: {
        tel: [
          { required: true, message: "请输入账号", trigger: "blur" }
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ],
        pass1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur",},
        ],
        pass2: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur",},
        ]
      },
      ercidenglu_type: 0,      //当微信号绑定过账号的时候，跳转到登录首页的再次点击微信登录的索引值，  1：从授权流程过来的     0：直接点击的微信登录

      login_token: '',

      //记住密码的相关参数
      code_show:true,
      count:'',
      timer:null,
      backUrl:this.$route.query.backurl,//登陆成功后跳转地址
      backMode:this.$route.query.backmode,//登陆成功后跳转模式 [1:location.href, 0:router.push(默认模式)]
    };
  },
  created() {
  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      if(!vm.backUrl){
        vm.backUrl = from.fullPath
      }
    })
  },
  watch:{
    $route(to,from) {
      let _this = this;
      console.log('256');
      if (this.$route.query.code) {
        this.$axios({
          method: "POST",
          url: "/api/officialWebsite/auth/authorized_wechat_college_official_website",
          data: {
            code: this.$route.query.code,
            state: this.$route.query.state
          }
        }).then((res) => {
          //已绑定微信，成功登陆
          if (res.code == 200) {
            return this.loginCallback(res.data.token);
          }
          //未绑定微信，需要登陆绑定账号
          if (res.code == 40051) {
            this.union_id = res.data.union_id
            console.log("this.$route.query.pwd", this.$route.query)
            if (this.$route.query.index == "1" && this.$route.query.pwd !== '' && this.$route.query.tel !== '' && this.$route.query.state !== '') {
              this.$message.success('微信绑定成功');
              this.zhanghaodenglu()
              return;
            }
            this.ZhangHaoKuaiGuan = 3

            this.$message('微信未绑定，请用账号密码登录');
          }

          if (res.code == 500) {
            this.$message.error("该微信已绑定，请用微信扫码登陆");
            this.ZhangHaoKuaiGuan = 1
            this.state = null;
            // this.$router.push('/Index');
            this.ercidenglu_type = 1      //当微信号绑定过账号的时候，跳转到登录首页的再次点击微信登录的索引值，  1：从授权流程过来的     0：直接点击的微信登录
          }

        })
      }
    }
  },
  mounted() {

  },
  methods: {
    ZhangHaoDengLu() {
      this.ZhangHaoKuaiGuan = true;
    },
    KaiGuanXiaoYanJing() {
      this.yan = !this.yan;
      if (this.yan) {
        this.kaiguan = "password";
      }
      if (!this.yan) {
        this.kaiguan = "text";
      }
    },

    //密码输入框的清空按钮的点击事件
    zhaohuimima_qingkong() {
      this.ruleForm.pass1 = ''
    },

    //找回密码的确认密码
    KaiGuanXiaoYanJing_querenmima() {
      this.yan2 = !this.yan2;
      if (this.yan2) {
        this.kaiguan2 = "password";
      }
      if (!this.yan2) {
        this.kaiguan2 = "text";
      }
    },
    //确认密码的清空按钮
    zhaohuimima_qingkong_queren() {
      this.ruleForm.pass2 = ''
    },
    login(type) {
      let _this = this;
      this.ZhangHaoKuaiGuan = type
      setTimeout((res) => {
        if(this.ZhangHaoKuaiGuan == 2) {
          var obj = new WxLogin({
            self_redirect:false,
            id:"login_container",
            appid: "wxfb97eb437aa4e005",
            scope: "snsapi_login",
            redirect_uri: encodeURIComponent(window.origin+'/new/#/Login?index=1&tel=' + this.Denglu.account + '&pwd=' + this.Denglu.password),
            state: _this.state ? _this.state : '',
            style: "black",
            href: ""
          });
        }
      },100)
    },

    //账号登录按钮的点击事件
    zhanghaodenglu(type) {
      const self = this;
      this.loading = true
      //判断复选框是否被勾选 勾选则调用配置cookie方法
      if(!this.TongYiXieYi) {
        console.log('356');
        this.$message.error('请阅读并勾选协议');
        return;
      }
      let data = {
        isjson: 1,
        project: 3
      }
      if(this.$route.query.index && this.$route.query.tel !== '' && this.$route.query.pwd !== '' && this.ercidenglu_type !== 1) {
        data.tel = this.$route.query.tel
        data.pwd = this.$route.query.pwd
      } else {
        data.tel = this.Denglu.account
        data.pwd = this.Denglu.password
      }
      if(this.union_id !== null) {
      //  data.union_id = this.union_id
      }
      this.$axios({
        method: "post",
        url: "api/officialWebsite/computer/yuan_cheng/login",
        data: {
          ...this.Denglu
        },
      }).then((res) => {
        if(res.code == 200) {
            localStorage.setItem("unionid", JSON.stringify(res.data.unionid));
          if(res.data.union_id !== null) {
            this.loginCallback(res.data.id,res.data.token)
          } else {
            self.state = res.data.student_id
            //更新绑定unionid不会返回token 这里需要单独进行记录
            localStorage.setItem("token",res.data.token);
            this.login(2)
          }
        } else {
          this.$message.error(res.msg);
        }
      this.loading = false
      })
    },
    //找回密码
    zhaohuimima() {
      this.ZhangHaoKuaiGuan = 4
    },
    //注册协议和隐私政策的点击事件
    login_xieyi(type) {
      if(type == 1) {
        // window.location.href = 'https://yuanchengedu.net/static/index/fwxy.html'
        window.open('https://yuanchengedu.net/static/index/fwxy.html', '_blank');
      } else {
        // window.location.href = 'https://yuanchengedu.net/static/index/yszc.html'
        window.open('https://yuanchengedu.net/static/index/yszc.html', '_blank');
      }
    },
    //返回登录
    fanhuidenglu() {
      this.ZhangHaoKuaiGuan = 1
    },
    //获取验证码
    huoquyanzhengma() {
      if(this.ruleForm.tel == '') {
        this.$message.error("请输入手机号");
        return;
      }
      this.$axios({
        method: 'POST',
        url: "api/officialWebsite/send_code",
        data: {
          tel: this.ruleForm.tel,
          type: 2,
        }
      }).then((res) => {
        console.log("res",res)
        if(res.code == 200) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.code_show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.code_show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    //重置密码的确认按钮
    chongzhimima_queding() {
      if(this.ruleForm.tel == '') {
        this.$message.error("请输入手机号");
        return;
      }
      if(this.ruleForm.code == '') {
        this.$message.error("请输入验证码");
        return;
      }
      if(this.ruleForm.pass1 == '') {
        this.$message.error("请输入密码");
        return;
      }
      if(this.ruleForm.pass2 == '') {
        this.$message.error("请输入确认密码");
        return;
      }
      if(this.ruleForm.pass1 !== this.ruleForm.pass2) {
        this.$message.error("输入的两次密码不一致");
        return;
      }
      this.$axios({
        method: 'POST',
        url: "api/officialWebsite/auth/reset_password",
        data: {
          tel: this.ruleForm.tel,
          code: this.ruleForm.code,
          password: this.ruleForm.pass1,
        }
      }).then((res) => {
        console.log("重置密码",res)
        if(res.code == 200) {
          if(res.data){
            this.$message.error("修改成功");
            this.ZhangHaoKuaiGuan = 1
          }else{
            this.$message.error("修改失败");
          }
        }
        if(res.code == 500) {
          this.$message.error(res.msg);
        }
      })
    },
    //登陆成功后的回调方法
    loginCallback(id,token){
      let _this = this;
      if(token) localStorage.setItem("token",token);
      _this.$store.dispatch("pullUserInfoByToken").then(function (res) {
        if(res.code == 200){
          _this.$router.push(_this.backUrl);
          _this.$message({
            type:"success",
            message:"登录成功",
            duration:1000,
            // onClose(){
            //   if(_this.backMode){
            //     location.href = _this.backUrl
            //   }else{
            //     _this.$router.push(_this.backUrl);
            //   }
            // }
          })
        }else{
          localStorage.removeItem("token");
          _this.$message.error(res.msg);
        }
      })
      //  this.$axios({
      //   method: "post",
      //   url: process.env.VUE_APP_BASE_URL_YUANCHENG + "index/login/setSessionAndCookie",
      //   data: {
      //     id: id,
      //     token: token
      //   },
      // }).then((res) => {
      //   console.log(res);
      // })
    }
  },
  components: {},
};
</script>

<style scoped>
.b_g {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, #f88e42, #f89084);
}
.zhuo_xia {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 240px;
  height: 135px;
}
.you_shang {
  position: absolute;
  right: 0;
  top: 0;
  width: 270px;
  height: 155px;
}
.deng_lu {
  width: 1200px;
  height: 614px;
  background: url(../assets/image/login.png) no-repeat center;
  background-size: 100% 100%;
  padding: 0 39px 0 574px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.lian-jie {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-left: 38px;
  line-height: 70px;
  color: #ffff;
  z-index: 999;
}
.deng-lu-xuan-zhe {
  position: relative;
  margin: 110px 100px;
}
.yuan-cheng-jiao-yu {
  width: 200px;
  margin: auto;
  margin-bottom: 96px;
}
.logo {
  width: 100%;
  height: 100%;
}
.wei-xin-deng-lu {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 55px;
  width: 390px;
  height: 70px;
  color: #fff;
  font-size: 23px;
  background-color: #83d326;
  border-radius: 35px;
}
.zhang-hao-deng-lu {
  padding-left: 38px;
  width: 390px;
  height: 70px;
  color: #fff;
  font-size: 23px;
  background-color: #f6660b;
  border-radius: 35px;
}
.deng_lu_kuang {
  width: 100%;
  height: 100%;
  padding: 0 100px;
  padding-top: 80px;
  box-sizing: border-box;
}
.login-pass-title {
  font-size: 28px;
  color: #555556;
  font-weight: 500;
}
.el-button {
  margin: 30px 0 22px 0;
  width: 390px;
  height: 60px;
  font-size: 24px;
  border-radius: 35px !important;
  background-color: #f6660b;
  border: 0;
}
.el-button:hover {
  background-color: #f6660b;
}
.zhao-hui-mi-ma {
  margin-left: 240px;
  color: #97999f;
  cursor: pointer;
}
.JiZhu {
  margin-top: -23px;
}
.tong-yi {
  margin-top: 24px;
}
.xue-yi {
  color: #f77b28;
  cursor: pointer;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2064a8;
  border-color: #2064a8;
}
.shu-ru-kuang-wai-kuang1 {
  position: absolute;
  top: 32px;
  width: 390px;
  height: 60px;
  border-radius: 35px;
  border: 1px solid #ccc;
}
.shu-ru-kuang-wai-kuang2 {
  position: absolute;
  width: 390px;
  height: 60px;
  border-radius: 35px;
  border: 1px solid #ccc;
}
.shouji {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 184px;
  right: 155px;
  background: url("../assets/image/shouji.png") no-repeat;
  background-size: 28px;
}
.weixin {
  position: absolute;
  width: 35px;
  height: 29px;
  top: 139px;
  right: 220px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAlFJREFUeNrs109IFHEUwHFnU0hM0UgKKqhDgWBCtBAoBkVBV1MLIujSQahLl8gOSh4NCstT0a2uIhFEYUEGJgRJh4gSy0KN6A+lFWar4/cHb2F6/GZ35je77KUffJjx9+bPW2d+b996vu+XlXKkyko8Sp5AeY7YGhzCMTRiPWqQwTe8wxBG8ME5A/MOWHRj2o82VnAL20OulZOeaMKE7zb+4FSSBNLI+MnHOZcEKuUT6PE6T1KTIfMn4iZw13KRixJrwKwlfl7iezBjiddFTWC35eSMOrBXxZdVvN9yjWtREkjJMrPVh5OyX4UOFffQKftmeR4NxJbwEtuirEKPLJ6xTYfEzRpvwOaQ+BiaZb8fU6jEMmoxiTd4gZWwBD6z3ZCgmF3FsBSrA/JhtqhjZvAQVySZfwrRtONy+4G9aMerGOfd0C/hsMPNF7ALZx2TH0VF9iW87/BvP459uKzmv4Ycv6D+bsXj7COoi5n9EzRb5vukoOkl+xS1aMQXFevKPoueGAl0YNAyXyPXKs9RmsdVbC5YFEYj3HwR+/HWEruHI7ij5mcl6TP4q0/SlWkkTwLv0YaffmHGvO6IDuJ2nibFL3ZL9j2w/0vFTEVcJ91QIUa1bslMBTuNj7I1S6UFO7ATW6X9ei6VL+mYCj5/T77HB1CV51ustUDvQFvwoilUx+hmLiW8+ZCtJ4zruuPNH4Q1pS5MoZmLcfMLubpiV2ulRXuEJUu3PC5FzIzDwXO9Ivw23IR6aUx+w/Qbn6RDuomNaMo2KMVI4P+P01hjVYABAEeQ2FQGHz5eAAAAAElFTkSuQmCC)
    no-repeat;
  background-size: 30px;
}
.xiao-shou:hover {
  cursor: pointer;
}
.wei-xin-qu {
  display: none;
  margin: 70px 120px;
}
.wei-xin-bang-ding {
  margin-left: 130px;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 866;
}
.wen-zhi-ti-shi {
  font-weight: 866;
  text-align: center;
}
.wen-zhi-shang {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
<style>
.shu_ru_kuang .el-input__inner {
  padding-left: 40px;
  width: 390px;
  height: 60px;
  box-sizing: border-box;
  font-size: 16px;
  color: #97999f;
  outline: none;
  border: 1;
  border-radius: 35px;
}
.shu-ru-kuang-top1 .el-input__inner {
  background-color: #e2ecfe;
  border: 0;
  margin: 32px 0 22px 0px;
}
.shu-ru-kuang-top2 .el-input__inner {
  width: 390px;
  background-color: #e2ecfe;
  border: 0;
}
.fu-xuan-kuang .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #97999f;
}
.fu-xuan-kuang .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1e5bff;
  border-color: #1e5bff;
}
.yan-jing-guan {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 22px;
  right: -52px;
  background: url("../assets/image/biyan.png") no-repeat;
  background-size: 20px;
}
.yan-jing-kai {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 22px;
  right: -52px;
  background: url("../assets/image/kaiyan.png") no-repeat;
  background-size: 20px;
}
#tu {
  height: 30px;
}
.an_niu {
  margin: 30px 0 22px 0;
  width: 390px;
  height: 60px;
  font-size: 24px;
  border-radius: 35px !important;
  background-color: #f6660b;
  color: #fff;
  border: 0;
}

.demo-ruleForm input{
  border-radius: 35px;
  height: 66px;
  font-size: 26px;
}

.zhanghao_shuru .el-input__inner {
  background: url(../assets/image/shouji2.png) no-repeat 10px center;
  padding: 0px 50px;
}
.zhanghao_yanzhnegma .el-input__inner {
  background: url(../assets/image/code1.png) no-repeat 10px center;
  padding: 0px 50px;
}
.zhanghao_mima .el-input__inner {
  background: url(../assets/image/mima.png) no-repeat 10px center;
  padding: 0px 112px 0 50px;
}

.zhaohuimima_yan-jing-guan {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 39px;
  right: 76px;
  background: url("../assets/image/biyan.png") no-repeat;
  background-size: 20px;
}
.zhaohuimima_yan-jing-kai {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 39px;
  right: 76px;
  background: url("../assets/image/kaiyan.png") no-repeat;
  background-size: 20px;
}

.zhaohuimima_guanbi {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 39px;
  right: 16px;
  background: url("../assets/image/guanbi1.png") no-repeat;
  background-size: 20px;
}

.zhanghao_mima_queren .el-input__inner {
  background: url(../assets/image/querenmima.png) no-repeat 10px center;
  padding: 0px 112px 0 50px;
}

</style>
<style lang="less">
.qrcode {
  display: inline-block;
  img {
    width: 350px;
    height: 350px;
    border: 1px solid #ccc;
    background-color: #fff; //设置白色背景色
    padding: 10px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
</style>

<style lang="less" scoped>
/deep/.el-loading-spinner {
  // background-image:url('https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif'); 
  background-repeat: no-repeat; 
  height:100%;
  background-size: 300px;
  background-position:center; 
  top:0; 
}
/deep/ .el-loading-spinner .circular {
  display: none;
}
</style>
