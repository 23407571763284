<template>
  <div>
    <div style="display:none">
     <audio controls="controls" ref="audio" :src="url">
     </audio>
      <audio controls="controls" ref="audio">
        <!-- <source :src="url || ''" type="audio/mpeg" /> -->
        <source :src="url" type="audio/mpeg" />
      </audio>
    </div>
    <div class="voice-bg">
      <img v-if="isPlaying" src="../../../../assets/image/播放.gif" alt="" class="img_css" style="height: 20px;" />
      <img v-else src="../../../../assets/image/椭圆 857.png" alt="" class="img_css" style="height: 20px;" />
      <div class="play_time">{{ duration + 's' || 0 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url:{
      type: String
    },
    duration:{
      type: Number,
      default: 5
    },
    // index:{
    //   type: Number
    // },
    // audioData:{
    //   type:HTMLCollection 
    // },
    // playing_audio:{
    //   type:Number
    // }
  },
  data() {
    return {
      timer: null,
      isPlaying: false,
      currentTime: 5,
    }
  },
  methods: {
    handlePlay() {
      let audio = this.$refs.audio;
      console.log(this.url);
      // console.log(this.isPlaying);
      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
        this.watchEnd();
      } else {
        audio.pause();
        this.isPlaying = false;
        clearTimeout(this.timer);
        audio.currentTime = 0;
      }
    },
    watchEnd() {
      let that = this;
      this.timer = setTimeout(() => {
        that.isPlaying = false
      }, (that.duration + 1) * 1000);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
</script>

<style lang="scss" scoped>
.voice-bg {
  width: 109px;
  height: 30px;
  border-radius: 6px;
  margin-left: 10px;
  background-image: linear-gradient(90deg, #338BFF, #074DFE);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 5px;
}

.play_time{
  height: 30px;
  text-align: center;
  line-height: 32px;
  color: #FFFFFF;
}

.img_css{
}
</style>