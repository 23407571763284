<template>
	<div class="zb-wrap" @click="emojiShow=false">
		<div v-if="currentType == 2 || currentType == 5" class="content_all">
			<img src="../../assets/image/live/lan_bj.png" alt="" class="bj">
			<div class="text">{{currentType == 2 ? '直播已结束' : '请联系班主任开通课程'}}</div>
			<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/logo.png" alt="" class="logo">
		</div>
		
		<div v-else class="">
			<div class="zb-header">
				<div class="zb-teacher flex-middle">
					<div class="zb-teacher-head">
						<img :src="chapter_details.lecturer_pic" alt="" class="auto-size">
					</div>
					<div class="zb-teacher-info">
						<div class="zb-teacher-name flex-middle">
							<span>{{chapter_details.lecturer_name}}</span>
							<div>{{chapter_details.lecturer_specialty}}</div>
						</div>
						<div class="zb-teacher-tips flex-middle">
							<div class="zb-teacher-tip flex_center">
								<img src="../../assets/image/live/renqi.png" alt="">
								<span>人气 {{chapter_details.lecturer_praise}}</span>
							</div>
							<div class="zb-teacher-tip gift flex_center">
								<img src="../../assets/image/live/liwu.png" alt="">
								<span>礼物 {{chapter_details.lecturer_gift}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="zb-sign flex_center" :style="signType ? 'background: #999999;' : 'background: linear-gradient(64deg, #3E68FF 0%, #7694FF 100%);'" @click="signIn">
					<img src="../../assets/image/live/qiandao.png" alt="">
					<span>{{signType ? '已' : ''}}签到</span>
				</div>
			</div>
			
			
			<div class="zb-cont flex-top">
				<div class="zb-player">
					<div v-if="currentType == 3 || livePullType == 3" class="yishushu_box">
						<img src="../../assets/image/live/lan_bj2.png" alt="" class="bj">
						<div class="text">{{livePullType == 3 ? '当前暂无直播' : '直播已结束'}}</div>
						<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/logo.png" alt="" class="logo">
					</div>
					<div v-else-if="currentType == 4" class="weikaishi_box">
						<img src="../../assets/image/live/weikaishi_bg.png" alt="" class="bj">
						<div class="logoBox">
							<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/logo.png" alt="">
							<p class="title_no">{{chapter_details.dname}}</p>
						</div>
						<div class="centerCon">
							<img src="../../assets/image/live/weikaishi_test.png" alt="">
							<div class="contentBox">
								<img src="../../assets/image/live/weikaishi_lanse.png" alt="">
								<div class="showTimes">
									<div class="livestart_tim">
										<span class="livestart_tim_color">{{countdown.h}}</span>时<span class="livestart_tim_color">{{countdown.m}}</span>分<span class="livestart_tim_color">{{countdown.s}}</span>秒
									</div>
									<div class="zhujiangBox">
										<span>开播时间：{{chapter_details.startHmm}}</span>
										<span style="margin-left: 20px;">主讲老师：{{chapter_details.lecturer_name}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else class="" style="width:100%;height:100%;">
						<div v-if="livePullType == 2 && warm_up_video" style="width:100%;height:100%;">
							<video :src="warm_up_video" playsinline="true" webkit-playsinline="true" x5-playsinline="true"
								autoplay controls loop style="width: 100%;height:100%;"></video>
						</div>
						<div v-if="livePullType == 1" id="player" style="width:100%;height:100%;"></div>
						<div v-else style="background-color: #fff;width:100%;height:100%;display: flex; justify-content: center;align-items: center;">
							<!-- <img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/backStageIcon/jrzsloDing.gif" style="width: 30%;"></image> -->
						</div>
					</div>
				</div>
				<div class="zb-chatroom">
					<div class="roomBox">
						<div class="room_header">
							<div class="room_header_ketang">
								<img src="../../assets/image/live/sanjiaoxing.png" alt="">
								课堂交流
							</div>
							<div class="room_header_zaixian">
								在线同学 <span v-if="onlineUserNumber">{{onlineUserNumber}}</span>
							</div>
						</div>
						<div class="messageBox" id="messageBox" :style="emojiShow ? 'height: 235px' : 'height: 435px'">
							<div class="">
								<div class="messageItem" v-for="(message, index) in currentMessageList" :key="index">
									<div class='message_view' v-if="message.senderId == user.timid + '_cid' + id">
										<div class="message_right">
											<div class="rightBoxText">
												<div class="message_name" style="justify-content: end;">
													<span>{{message.senderName}}</span>
													<div v-if="message.extension && message.extension.role">
														<img style="height: 20px;" v-if="message.extension.role == 'teacher'" src="https://static.shanchuangjiaoyu.com/yc_mobile/new_mobile/static/images/jiangshi_pc.png" alt="">
														<img style="height: 20px;" v-if="message.extension.role == 'assistant'" src="https://static.shanchuangjiaoyu.com/yc_mobile/new_mobile/static/images/zhujiao_pc.png" alt="">
													</div>
													
												</div>
												<div class="message_right_text">
													<el-image style="width: 100px;" v-if="message.type == 'TIMImageElem'" :src="message.img" :preview-src-list="message.imgArr">
													 </el-image>
													<span v-else class="message_right_text_span" :data-value="message.senderContent" v-html="$common.parseMessage(message.senderContent, bannedWords)"></span>
												</div>
											</div>
											<img :src="message.senderAvatar" mode="aspectFill" class="touxiang_right">
										</div>
									</div>
									<div class='message_view' v-else>
										<div class="message_left">
											<img :src="message.senderAvatar" mode="aspectFill" class="touxiang_left">
											<div class="shoudao_message">
												<div class="message_name">
													<span>{{message.senderName}}</span>
													<div v-if="message.extension && message.extension.role">
														<img style="height: 20px; margin-left: 5px" v-if="message.extension.role == 'teacher'" src="https://static.shanchuangjiaoyu.com/yc_mobile/new_mobile/static/images/jiangshi_pc.png" alt="">
														<img style="height: 20px; margin-left: 5px" v-if="message.extension.role == 'assistant'" src="https://static.shanchuangjiaoyu.com/yc_mobile/new_mobile/static/images/zhujiao_pc.png" alt="">
													</div>
												</div>
												<div class="message_left_text">
													<div class="message_left_text_span">
														<el-image style="width: 100px;" v-if="message.type == 'TIMImageElem'" :src="message.img" :preview-src-list="message.imgArr">
														 </el-image>
														<p v-else class="" :data-value="message.senderContent" v-html="$common.parseMessage(message.senderContent, bannedWords)"></p>
														<div class="huifuxiaoxi" v-if="message.extension && message.extension.optionMessage && message.extension.optionMessage.senderName">
															<span style="margin-right: 10px">{{message.extension.optionMessage.senderName}}:</span>
															<img v-if="message.extension.optionMessage.type == 'TIMImageElem'" :src="message.extension.optionMessage.img" alt="" style="width: 50%;">
															<span v-else v-html="$common.parseMessage(message.extension.optionMessage.senderContent, bannedWords)"></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="room_bottom" @click.stop="">
							<div class="emojis" v-if="emojiShow">
								<div v-for="(emojiItem, keyNmae) in emojiMap" class="emoji" :key="keyNmae" @click="chooseEmoji(emojiItem, keyNmae)">
									<img :src="emojiItem" style="width:100%;height:100%" />
								</div>
							</div>
							<div class="room_input">
								<el-input resize="none" @keyup.enter.native="submit" type="textarea" :rows="3" maxlength="50" v-model.lazy:value="messageContent"
								:placeholder="banned ? '管理员已设置全员禁言！' : (oneBanned ? '你已被禁言！' : '快来和老师同学交流下吧~')" 
								:disabled="banned || oneBanned"></el-input>
								<div class="message_btn">
									<div style="display: flex;align-items: center;">
										<img @click="showEmojoBox" src="../../assets/image/live/xiaolian.png" alt="">
									</div>
									<div class="message_btn_right">
										<span>{{messageContent.length}}/50</span>
										<div @click="submit">发送</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="imgDialogVisible" :append-to-body="true">
		  <img :src="imgUrl" width="100%">
		</el-dialog>
		
	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	import Hls from 'hls.js'
	import flvjs from 'flv.js'
	import Player from 'nplayer'
	import Danmaku from '@nplayer/danmaku'
	import TIM from 'tim-js-sdk';
	import IM from '../../util/IM.js'
	
	import {
			emojiMap
		} from '../../util/lib/emojiMap.js'
	export default {
		name: 'live',
		data() {
			return {
				id: '',
				from: '', 
				currentType: 1,
				liveType: 1,
				chapter_details: {},
				countdown: {},
				timer_down: null, 
				user: {},
				
				PolyvLive: {
					channelId: '',
					appSecret: '04c6fcf00a9940399513675cb20038d0', 
					timestamp: new Date().getTime(), // 毫秒级时间戳
					appId: 'f9o0vg087x', // polyv 后台的appId
				},
				
				chat: null,
				liveSdk: null,   //保利威直播
				thePlayer: null,   //nPlayer播放器
				tim: null,
				groupID: null,
				nextReqMessageID: '', //用于分页续拉的消息 ID
				isCompleted: false, //是否已经拉完所有消息
				currentMessageList: [],
				emojiMap,
				messageContent: '',
				emojiShow: false,
				onlineUserNumber: '',
				onlineUserNumber_shiji: '',
				signType: false,
				colors: ['#FE0302', '#FF7204', '#FFAA02', '#FFD302', '#FFFF00', '#A0EE00', '#00CD00', '#019899', '#4266BE', '#CC0273', '#222222', '#FFFFFF'],
				livePullType: 2,    // 1 有流   2 一直没有流  3 有流后断流
				warm_up_video: null,
				tx_play_address_hls: null,  //腾讯云推流地址
				tx_play_address_flv: null,  //腾讯云推流地址
				banned: false,
				oneBanned: false,
				bannedWords: [],
				imgDialogVisible: false,
				imgUrl: '',
				isPush: null,   // 1 收到推流消息 
				currentConsultant: ''
			}
		},
		created() {
			let that = this;
			that.id = that.$route.query.id;
			that.from = that.$route.query.from;
			that.user = JSON.parse(localStorage.getItem("userInfo"))
			if(!that.user) {
				that.$router.push('/')
				return
			}
			that.checkPermisi();
			
			Bus.$emit('scrollToTop')
			
			Bus.$off('createMessage').$on("createMessage", (data) => {
				let message = data.message;
				if(data.type == 1) {  
					that.setMessage(data)
					return
				}
				
				if (message.to == that.groupID) {
					if (message.type === 'TIMImageElem') {
						that.addImgMessageInList(message)
						return
					}
					if (message.type === 'TIMCustomElem') {
						if(message.payload.extension == 'field_control') {
							let robotMessageData = JSON.parse(message.payload.data)
							that.setRobotMessageData(robotMessageData, message.ID)
							if(that.livePullType == 1) {
								that.setDanMa(robotMessageData.msg)
							}
							return
						}
						
						let extension = {}
						extension = message.payload.extension ? JSON.parse(message.payload.extension) : {};
						
						if(extension.system) {
							if(extension.system == 1) {
								that.banned = extension.banned
								that.emojiShow = false;
								return
							}
							if(extension.system == 2) {
								if(extension.messageOption.userId == that.user.timid + '_cid' + that.id || extension.messageOption.userId == that.user.id) {
									that.oneBanned = !that.oneBanned
									that.emojiShow = false;
								}
								return
							}
							if(extension.system == 3) {
								that.deteleMessage(extension)
								return
							}
							if(extension.system == 4) {
								if(extension.messageOption.userId == that.user.timid + '_cid' + that.id) {
									that.$notify.error('您已被移出直播间')
									that.$router.push('/Index')
								}
								return
							}
							
							return
						}
						
						that.setMessage(data)
						if(that.currentType == 1 && that.livePullType == 1) {
							if(extension && extension.color) {
								if(message.from != (that.user.timid + '_cid' + that.id)) {
									that.setDanMa(message.payload.text || message.payload.data, extension.color)
								}
							} else {
								that.setDanMa(message.payload.text || message.payload.data)
							}
						}
						return
					}
					if (message.type === 'TIMTextElem') {
						if(message.payload.text == 'start streaming') {
							that.isPush = 1;
							that.nplayerReader();
						}
						if(message.payload.text == 'end live') {
							if(that.thePlayer) {
								that.hls && that.hls.destroy();
								that.thePlayer.pause()
								that.thePlayer.dispose();
								that.thePlayer = null;
							}
							that.livePullType = 3;
						}
						return
					}
					if((message.payload.operationType == 2 || message.payload.operationType == 3) && message.nick) {
						that.setOnlineUserNunber(that.onlineUserNumber_shiji--);
						return
					}
					if((message.payload.operationType == 1) && message.nick) {
						if(message.payload.memberList[0].userID == that.user.timid + '_cid' + that.id) {
							that.setOnlineUserNunber(message.payload.memberNum);
						} else {
							that.setOnlineUserNunber(that.onlineUserNumber_shiji++);
						}
						
						return
					}
				}
			})
			
			Bus.$off('kickOut').$on("kickOut", (event) => {
				if(that.thePlayer) {
					that.thePlayer.pause()
				}
				
				that.$alert('您已被迫下线', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						that.$router.push('/Index')
					}
				});
			})
			
		},
		methods: {
			checkSign() {
				this.$axios({
					method: 'POST',
					url: "/api/officialWebsite/computer/query_check_in_records",
					data: {
						cid: this.id,
						type: this.from == 'tiYanKe' ? 2 : 3
					}
				}).then(res => {
					this.signType = res.data
				})
			},
			getCurrentConsultant() {
				this.$axios({
					method: 'POST',
					url: "/api/tencentcloud_im/obtain_the_consultant_designated_by_the_student",
					data: {
						student_id: this.user.id
					}
				}).then(res => {
					this.currentConsultant = res.data
				})
			},
			getStudentBrowser() {
				this.$axios({
					method: 'POST',
					url: "/api/tencentcloud_im/save_student_browser_type",
					data: {
						student_id: this.user.id
					}
				})
			},
			checkPermisi() {
				this.$axios({
					method: 'POST',
					url: "/api/officialWebsite/mobile/personal_center/check_experience_permissions"
				}).then(res => {
					if (res.code == 200) {
						if(!res.data && this.from == 'tiYanKe') {
							this.currentType = 5
							return
						}
						this.checkSign();
						this.getdetail();
						this.getCurrentConsultant();
						this.getStudentBrowser();
					}
				})
			},
			getdetail() {
				let that = this;
				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/live_classroom_details",
					data: {
						syllabus_id: this.id,
						student_id: this.user.id
					}
				}).then((res) => {
					if (res.code == 10011) {   //直播结束
						this.currentType = 2;
						return
					} 
					if (res.code == 40010) {   //权限不足
						this.currentType = 5;
						return
					} 
					if (res.code !== 200) {
						return
					} 
					
					if(res.data.punishment_status == 1) {
						that.$notify.warning('你已被移出直播间')
						that.$router.push('/Index')
						return
					}
					that.oneBanned = res.data.mute_sign == 2 ? false : true;
					
					this.liveType = res.data.live_platform;
					this.chapter_details = res.data;
					
					
					let starts = (parseInt(res.data.starttm) < 10000000000) ? res.data.starttm * 1000 : res.data.starttm
					
					if (res.data.endtm * 1000 - new Date().getTime() < 0) {
						this.currentType = 2;
						return
					}
					if (starts - new Date().getTime() > 0) {
						this.currentType = 4;
						this.daojishiFun(res.data.starttm);
						let stattime = this.$common.format(res.data.starttm);
						
						this.chapter_details.startHmm = stattime.h + ':' + stattime.mm
					} else {
						this.currentType = 1;
					}
					this.getProhibited();
					this.$nextTick(() => {
						if(that.liveType == 1) {
							that.livePullType = 1;
							that.PolyvLive.channelId = res.data.channelno
							that.getChatToken();
						} else {
							that.timReary();
						}
					})
					
				})
			},
			getChatToken() {
				let that = this;
				var apiUrl = "https://api.polyv.net/live/v3/channel/common/get-chat-token";
				var params = {
					appId: that.PolyvLive.appId,
					channelId: that.PolyvLive.channelId,
					role: "viewer", // 角色,viewer：观看者
					timestamp: new Date().getTime(),
					userId: that.user.timid + '_cid' + that.id, // 观看者用户Id
				};
				params.sign = that.getSign(params);
				$.ajax({
					url: apiUrl,
					type: "POST",
					async: false,
					data: params,
					success: (data) => {
					  that.polyvChatRoomReady(data.data);
					},
				});
			},
			polyvChatRoomReady(chatData) {
				let that = this;
				const chatroom = new PolyvChatRoom({
				     roomId: that.PolyvLive.channelId,
				     userId: that.user.timid + '_cid' + that.id,
				     nick: that.user.name,
				     pic: that.user.headico,
					 accountId: that.user.timid + '_cid' + that.id,
				     token: chatData.token,
				     mediaChannelKey: chatData.mediaChannelKey,
				     version: '2.0',
				     enableSetNickname: false, // 开启设置昵称功能
				     width: '100%',
					 height: '100%',
					 tabData: [{
					 	name: '课堂交流', 
					 	type: 'chat' 
					 }],
					 showUserList: true,
					 enableAsk: false,
					 role: 'viewer', // 角色, 用于获取授权和连麦token http://api.polyv.net/live/v3/channel/common/get-chat-token
					 userType: 'viewer',
					 enableWelcome: true, // 是否开启欢迎语，默认为true
					 enableFlower: false, // 是否开启送花功能，默认为true
					 enableOnlyTeacher: true, // 是否开启只看讲师功能，默认为true
					 showOwnInfo: true,
					 enableBulletin: false, //公告
					 enableLike: false,
				     roomMessage: function (data) {
						if(data.EVENT == 'LOGIN') {
							that.setOnlineUserNunber(data.onlineUserNumber)
						}
						if(data.EVENT == 'LOGOUT') {
							that.setOnlineUserNunber(data.onlineUserNumber)
						}
						if(data.EVENT == 'sendMessage') {
							that.addMessage(data, 'viewer')
							that.sendBarrage(data.content);
						}
						if(data.EVENT == 'SPEAK') {
							that.addMessage(data)
							that.sendBarrage(data.content);
						}
						
				     },
					 customChatColor: {
						selfBgColor: '#FF7E28',
						selfColor: '#fff',
						otherBgColor: '#1819218f',
						otherColor: '#BFBFBF',
						specialBgColor: '#1819218f',
						specialColor: '#fff'

					}
				 });
				 
				 that.chat = chatroom.chat;
				 
				 that.chat.getHistoryMessage(0, 100).then(function (data) {
					 if(data && data.length > 0) {
						 data.reverse();
						 data.forEach(item => {
							 that.addMessage(item)
						 })
					 }
				 });
				 if(this.currentType != 1) return
				 that.liveSdkReader(that.chat.socket);
			},
			liveSdkReader(socket) {
				if(this.liveSdk) {
					return
				}
				var sign = this.getSign({
					appId: this.PolyvLive.appId,
					channelId: this.PolyvLive.channelId,
					timestamp: this.PolyvLive.timestamp
				});
			
				let liveSdk = new PolyvLiveSdk({
				  channelId: this.PolyvLive.channelId,// 频道Id
				  sign: sign, // 频道验证签名
				  timestamp: this.PolyvLive.timestamp, // 毫秒级时间戳
				  appId: this.PolyvLive.appId, // polyv 后台的appId
				  socket: socket,
				  user: {
					   userId: this.user.timid + '_cid' + this.id, 
					   userName: this.user.name,
					   pic: this.user.headico
				  }
				});
				liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
				  liveSdk.setupPlayer({
				    el: '#player',
				    type: 'auto',
					barrage: true,
					controller: true,
					defaultBarrageStatus: true,
					seH5Page: true, // 开启同层播放
					autoplay: true, // 设置自动播放
				  });
				}); 
				
				this.liveSdk = liveSdk
				// this.openDanmu();
			},
			sendBarrage(text, color) {
				var barrageColor = color || '#ffffff'; // 弹幕默认颜色 #000
				if (this.liveSdk && this.liveSdk.player) {
					try {
						this.liveSdk.player.sendBarrage(text, barrageColor);
					} catch(error) {}
				}
			},
			getSign(params) {
				var paramsStr = "";
				$.each(params, function(key, val) {
					paramsStr += key + "" + val;
				});
				var finalStr = this.PolyvLive.appSecret + paramsStr + this.PolyvLive.appSecret;
				return md5(finalStr).toUpperCase();
			},
			setOnlineUserNunber(onlineUserNumber) {
				if(!onlineUserNumber) return
				if(onlineUserNumber <= 1) {
					onlineUserNumber = 1;
				}
				this.onlineUserNumber_shiji= onlineUserNumber;
				this.onlineUserNumber = this.from == 'tiYanKe' ? parseInt(onlineUserNumber * 11.6) : parseInt(onlineUserNumber * 4)
			},
			addMessage(message, role) {
				Bus.$emit('createMessage', {message: message, type: 1, role: role})
			},
			setMessage(data) {
				let theMessage = data.message
				let message = {}
				if(data.type == 2) {
					message = {
						senderId: theMessage.from,
						senderName: theMessage.nameCard || theMessage.nick,
						senderContent: theMessage.payload.data,
						senderAvatar: theMessage.avatar,
						extension: theMessage.payload.extension ? JSON.parse(theMessage.payload.extension) : {},
						messageId: theMessage.ID
					}
				} else {
					if(typeof(theMessage.content) != 'string') return
					message = {
						senderId: theMessage.user.userId,
						senderName: theMessage.user.nick,
						senderContent: theMessage.content,
						senderAvatar: theMessage.user.pic,
						extension: {
							role: theMessage.user.userType || data.role
						}
					}
				}
				this.messageAfterOption(message);
			},
			setRobotMessageData(data, messageId) {
				let message = {
					senderId: 'administrator',
					senderName: data.nickName,
					senderContent: data.msg,
					senderAvatar: data.pic,
					extension: {},
					messageId: messageId
				}
				this.messageAfterOption(message);
			},
			
			messageAfterOption(message) {
				this.currentMessageList.push(message);
				this.$nextTick(() => {
					var ele = document.getElementById("messageBox");
					if(ele.scrollHeight > ele.clientHeight) {
					  ele.scrollTop = ele.scrollHeight;
					}
				})
			},
			
			daojishiFun(startTime) {
				startTime = startTime.length == 13 ? startTime : startTime * 1000
				let currentTime = new Date().getTime();
				let time = (startTime - currentTime) / 1000;
				let that = this;
				if(time <= 0) {
					this.countdown = {
						h: '00',
						m: '00',
						s: '00',
					}
					if(that.timer_down) {
						clearInterval(that.timer_down)
					}
				} else {
					if(that.timer_down) {
						clearInterval(that.timer_down)
					}
					that.timer_down = setInterval(function() {
						time -= 1;
						if(time <= 0) {
							that.getdetail();
							clearInterval(that.timer_down)
						}else {
							that.countdownTime(time)
						}
					}, 1000)
				}
			},
			countdownTime(time) {
				this.countdown = {
					h: parseInt(time / 60 / 60 % 24) < 10 ? '0' + parseInt(time / 60 / 60 % 24) : parseInt(time / 60 / 60 % 24),
					m: parseInt(time / 60 % 60) < 10 ? '0' + parseInt(time / 60 % 60) : parseInt(time / 60 % 60),
					s: parseInt(time % 60) < 10 ? '0' + parseInt(time % 60) : parseInt(time % 60),
				}
			},
			getLiveAddress() {
				this.$axios({
					method: "POST",
					url: "/api/tencentcloud_live/get_the_live_streaming_url",
					data: {
						syllabus_id: this.id
					}
				}).then((res) => {
					this.livePullType = res.data.status;
					this.warm_up_video = res.data.warm_up_video;
					this.tx_play_address_hls = res.data.play_address.hls;
					this.tx_play_address_flv = res.data.play_address.flv;
					if(this.livePullType == 1) {
						this.nplayerReader();
					}
				})
			},
			getOnlineMemberNum() {
				let that = this;
				setInterval(() => {
					that.tim.getGroupOnlineMemberCount(that.groupID).then((imResponse) => {
						that.setOnlineUserNunber(imResponse.data.memberCount);
					}).catch(function(imError) {
					  console.warn('getGroupOnlineMemberCount error:', imError); // 获取直播群在线人数失败的相关信息
					})
				}, 30000)
			},
			nplayerReader() {
				let that = this;
				that.livePullType = 1
				if(this.thePlayer) {
					this.thePlayer.toggle()
					try {
						that.thePlayer.play();
					} catch(e){
						console.log('自动播放失败')
					}
					return
				}
				
				that.thePlayer = new Player({
					plugins: [
						new Danmaku({
							colors: that.colors,
							speed: 0.5,
							discard(opts) { 
								 return false; 
							},
						})
					],
					controls:  [
					    ['volume', 'time', 'spacer', 'airplay', 'web-fullscreen', 'fullscreen'],
					    ['progress']
					]
				});
				
				if (flvjs.isSupported()) {
					var flvPlayer = flvjs.createPlayer({
						type: 'flv',
						url: that.tx_play_address_flv
					});
					flvPlayer.attachMediaElement(that.thePlayer.video);
					flvPlayer.load();
					try {
						flvPlayer.play();
					} catch(e){
						console.log(e)
					}
					
					that.hls = flvPlayer
				} else {
					const hls = new Hls()
					that.hls = hls;
					hls.attachMedia(that.thePlayer.video)
					hls.on(Hls.Events.MEDIA_ATTACHED, function () {
						hls.loadSource(that.tx_play_address_hls)
					})
				}
				that.$nextTick(() => {
					setTimeout(() => {
						that.thePlayer.mount('#player')
						try {
							that.thePlayer.play();
						} catch(e){
							console.log('自动播放失败')
						}
					}, 2000)
				})
				
				that.thePlayer.on('DanmakuSend', (opts) => {
					if(that.banned || that.oneBanned) {
						return
					}
					that.messageContent = opts.text;
					that.submit(opts);
				})
				that.thePlayer.on(that.thePlayer.EVENT.LOADING_SHOW, () => {
					if(that.isPush == 1) {
						setTimeout(() => {
							if(that.isPush != 2) {
								that.nplayerReader();
								that.isPush = 2;
							}
						}, 5000)
					}
				})
				
				that.thePlayer.on(that.thePlayer.EVENT.LOADING_HIDE, () => {
					that.thePlayer.danmaku.resetItems([])
					that.isPush = 2;
				})
				
			},
		
			timReary() {
				let that = this;
				if(that.currentType == 1) {
					that.getLiveAddress();
				}
				IM.timLogin(that.id).then(res => {
					that.tim = that.$store.state.tim;
					that.$axios({
						method: "POST",
						url: "api/tencentcloud_im/create_chat_room",
						data: {
							syllabus_id: that.id
						}
					}).then((res) => {
						console.log(res.data.group_id,"res.data.group_idres.data.group_idres.data.group_id");
						that.groupID = res.data.group_id;
						that.banned = res.data.mute_sign
						that.joinGroup();
					})
				}).catch(error => {
					console.log(error)
				})
			},
			
			joinGroup() {
				let that = this;
				if(!that.groupID) {
					that.$notify.error('groupID不存在')
					return
				}
				that.tim.joinGroup({
					groupID: that.groupID,
					type: TIM.TYPES.GRP_AVCHATROOM
				}).then(function(imResponse) {
					try{
						that.getOnlineMemberNum()
						that.getHistoryChatRoomData();
					}catch(e){console.log(e)}
				}).catch(function(imError) {
					console.warn('joinGroup error:', imError)
				});
			},
			parseMessageEmoji(message) {
				if (!message || typeof(message) != 'string') return
				message = message.replace(/\[.*?\]/g, function(keyword) {
					if (emojiMap[keyword]) {
						return "<img style='width: 30px;max-height: 30px' src='" + emojiMap[keyword] + "' />"
					} else {
						return keyword
					}
				});
				return message;
			},
			showEmojoBox() {
				if(this.banned || this.oneBanned) return
				this.emojiShow = !this.emojiShow;
			},
			chooseEmoji(value, key) {
				this.messageContent += key
			},
			uploadImg(file) {
				this.createImageMessage(file.raw) 
			},
			pasteImage(e) {
				if(this.banned || this.oneBanned) return
				let that = this;
				let clipboardData = e.clipboardData;
				let file;
				let fileCopy;
				if (clipboardData && clipboardData.files && clipboardData.files.length > 0) {
				  file = clipboardData.files[0];
				  fileCopy = file.slice();
				}
					 
				if (typeof file === 'undefined') {
				  return;
				}
				that.createImageMessage(file) 
			},
			createImageMessage(file) {
				let that = this;
				if(!file) {
					that.$notify.error('file不存在，请检查代码或浏览器兼容性！')
					return;
				}
				let message = that.tim.createImageMessage({
				 to: that.groupID,
				 conversationType: TIM.TYPES.CONV_GROUP,
				  payload: {
				    file: file
				  },
				  onProgress: function(event) { console.log('file uploading:', event) }
				});
					 
				let promise = that.tim.sendMessage(message);
				promise.then(function(imResponse) {
				  Bus.$emit('createMessage', {message: imResponse.data.message, type: 2})
				}).catch(function(imError) {
				  console.warn('sendMessage error:', imError);
				  that.$notify.error(imError.message || '消息发送失败')
				});
			},
			addImgMessageInList(addMessage) {
				let that = this;
				let imgs = [];
				imgs.push(addMessage.payload.imageInfoArray[0].imageUrl)
				let message = {
					senderId: addMessage.from,
					senderName: addMessage.nick,
					senderAvatar: addMessage.avatar,
					type: 'TIMImageElem',
					img: addMessage.payload.imageInfoArray[0].imageUrl,
					imgArr: imgs,
					messageId: addMessage.ID,
					extension: {
						role: 'viewer'
					}
				}
				that.currentMessageList.push(message);
				that.$nextTick(() => {
					var ele = document.getElementById("messageBox");
					if(ele.scrollHeight > ele.clientHeight) {
					  ele.scrollTop = ele.scrollHeight;
					}
				})
			},
			
			submit(opts) {
				if(this.banned || this.oneBanned) return
				let that = this;
				that.messageContent = that.messageContent.replace(/\s*/g,"")
				if(!that.messageContent || that.messageContent == '\n') {
					that.messageContent = '';
					return that.$notify.error('消息不能为空')
				}
				let noBanned = false
				noBanned = that.$common.booleanBanned(that.messageContent, that.bannedWords)
				if(noBanned) {
					return that.$notify.error('消息含有违禁词')
				}
				if(that.liveType == 1) {
					that.chat.send(that.messageContent)
				} else {
					// 1. 创建消息实例
					let message = that.tim.createCustomMessage({
						to: that.groupID,
						conversationType: TIM.TYPES.CONV_GROUP,
						priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
						payload: {
							data: that.messageContent,
							extension: JSON.stringify({role: 'viewer', color: opts? opts.color: '', currentConsultant: that.currentConsultant, currentStudentId: that.user.id})  //消息扩展
						},
					});
					
					let promise = that.tim.sendMessage(message);
					promise.then(function(imResponse) {
						Bus.$emit('createMessage', {message: imResponse.data.message, type: 2})
					}).catch(function(imError) {
						console.warn('sendMessage error:', imError);
						that.$notify.error(imError.message || '消息发送失败')
					});
				}
				that.messageContent = '';
				that.emojiShow = false;
			},
			setDanMa(message, color) {
				if(!message) return
				message = this.$common.parseMessage(message, this.bannedWords, 'isImg')
				this.thePlayer.danmaku.appendItems({ text: message, color: color || '#ffffff' })
			},
			signIn() {
				if(this.signType) {
					return
				}
				this.$axios({
					method: "POST",
					url: "/api/officialWebsite/computer/sign_in_for_live_class",
					data: {
						cid: this.id,
						type: this.from == 'tiYanKe' ? 2 : 3
					}
				}).then(res => {
					if(res.code == 200) {
						this.$notify.success('签到成功')
						this.signType = true;
					}
				})
			},
			
			deteleMessage(extension) {
				try{
					this.currentMessageList = this.currentMessageList.filter(item => item.messageId != extension.messageOption.deleID)
				}catch(e){
					console.log(e)
				}
			},
			getProhibited() {
				this.$axios({
					method: "POST",
					url: "/api/common/list_of_prohibited_words"
				}).then(res => {
					if(res.code == 200) {
						let list = res.data.value.split(',')
						this.bannedWords = list
					}
				})
			},
			pigImg(img) {
				this.emojiShow = false
				this.imgUrl = img;
				this.imgDialogVisible = true;
			},
			getHistoryChatRoomData() {
				this.$axios({
					method: "POST",
					url: "/api/tencentcloud_im/chat_room_history",
					data: {
						syllabus_id: this.id,
						list_rows: 100,
						page: 1
					}
				}).then(res => {
					if(res.code == 200) {
						let data = res.data.list
						if(data && data.length > 0) {
							data.reverse();
							data.forEach(item => {
								let message = {};
								if(item.msg_type == 'TIMImageElem') {
									let imgs = [];
									if(item.msg_content.ImageInfoArray && item.msg_content.ImageInfoArray.length > 0) {
										imgs.push(item.msg_content.ImageInfoArray[0].URL)
										message = {
											senderId: item.from_account,
											senderName: item.user_name,
											senderContent: item.msg_content.Data,
											senderAvatar: item.user_headico,
											type: 'TIMImageElem',
											img: item.msg_content.ImageInfoArray[0].URL,
											imgArr: imgs,
											data: {},
											extension: typeof(item.msg_content.Ext) == 'string' ? JSON.parse(item.msg_content.Ext) : item.msg_content.Ext
										}
									}
								}
								
								if(item.msg_type == 'TIMCustomElem') {
									if(item.msg_content.Ext == 'field_control') {
										let dataValue = JSON.parse(item.msg_content.Data)
										message = {
											senderId: 'administrator',
											senderName: dataValue.nickName,
											senderContent: dataValue.msg,
											senderAvatar: dataValue.pic,
											extension: {}
										}
									} else {
										let ExtValue = typeof(item.msg_content.Ext) == 'string' ? JSON.parse(item.msg_content.Ext) : item.msg_content.Ext
										if(ExtValue.system) return
										if(item.msg_content.Data) {
											message = {
												senderId: item.from_account,
												senderName: item.user_name,
												senderContent: item.msg_content.Data,
												senderAvatar: item.user_headico,
												extension: ExtValue
											}
										}
									}
									message.senderId ? this.messageAfterOption(message) : '';
								}
							})
						}
					}
				})
			},
		},
		beforeDestroy() {
			if(this.liveSdk) {
				this.liveSdk.destroy(true)
			}
			if(this.thePlayer) {
				this.hls && this.hls.destroy();
				this.thePlayer.pause()
				this.thePlayer.dispose();
			}
			if(this.tim) {
				try {
					this.tim.quitGroup(this.groupID).catch(function(res){});
					this.tim.logout().catch(function(res){
					});
					this.tim.destroy();
				} catch (error) {}
				this.$store.commit('setTim', null)
			}
		},

	}
</script>

<style scoped lang="scss">
	.flex-middle {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
	}
	.flex-top {
	    display: -webkit-flex;
	    display: flex;
	    -webkit-justify-content: space-between;
	    justify-content: space-between;
	    -webkit-align-items: flex-start;
	    align-items: flex-start;
	}
	.flex_center {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.zb-wrap {
		width: 100%;
		height: auto;
		background-color: #212121;
		padding: 40px 0 80px;
	}
	.zb-header {
		width: 1366px;
		margin: 0 auto 20px;
		display: -webkit-flex;
		display: flex;
		justify-content: space-between;
		-webkit-align-items: flex-end;
		align-items: flex-end;
	}
	.zb-teacher-head {
		width: 50px;
		height: 50px;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		overflow: hidden;
	}
	.auto-size {
		width: 100%;
		height: 100%;
		display: block;
	}
	.zb-teacher-info {
	    margin-left: 10px;
	}
	.zb-teacher-name {
		span {
			color: #fff;
			font-size: 20px;
			opacity: 0.7;
		}
		div {
			height: 20px;
			line-height: 20px;
			padding: 0px 6px;
			-webkit-border-radius: 3px;
			border-radius: 10px;
			font-size: 12px;
			color: #000;
			background-color: #fff;
			margin-left: 10px;
		}
	}
	.zb-teacher-tips {
	    margin-top: 10px;
		.gift {
		    margin-left: 10px;
		}
		.zb-teacher-tip {
			font-size: 14px;
			color: #fff;
			img {
			   width: 14px;
			   height: 14px;
			   object-fit: cover;
			   margin-right: 4px;
			}
		}
	}
	.zb-sign {
	    width: 100px;
	    height: 30px;
	    -webkit-border-radius: 16px;
	    border-radius: 16px;
	    cursor: pointer;
		span {
			font-size: 16px;
			color: #fff;
		}
		img {
			width: 14px;
			height: 14px;
			object-fit: cover;
			margin-right: 4px;
		}
	}
	.zb-cont {
	    width: 1366px;
	    height: 610.5px;
	    overflow: hidden;
	    margin: 0 auto;
	    border-radius: 8px;
	}
	.zb-player {
	    width: 100%;
	    height: 100%;
	    flex: 1;
	}
	.zb-chatroom {
	    width: 280px;
	    height: 100%;
	    box-sizing: border-box;
	    position: relative;
	    background-color: #353842;
	}
	.content_all {
		width: 1366px;
		height: 610.5px;
		margin: 80px auto;
		position: relative;
	}
	.bj {
		width: 100%;
		height: 100%;
		object-fit: initial;
	}
	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-weight: Bold;
		color: #fff;
		font-size: 50px;
	}
	.logo {
		position: absolute;
		top: 50px;
		left: 40px;
		width: 161px;
	}
	.yishushu_box, .weikaishi_box {
		width: 100%;
		height: 100%;
		position: relative;
		.logoBox {
			position: absolute;
			top: 50px;
			left: 40px;
			img {
				width: 161px;
			}
			.title_no {
				font-size: 26px;
				color: #00FFBA;
				margin-top: 23px;
			}
		}
		.centerCon {
			position: absolute;
			left: 50%;
			bottom: 140px;
			transform: translateX(-50%);
			width: 707px;
			img {
				width: 100%;
			}
			.contentBox {
				position: absolute;
				height: 220px;
				width: 512px;
				top: -16px;
				left: 71px;
				.showTimes {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0px;
					left: 0px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					.zhujiangBox {
						color: #CCD8FF;
						font-size: 24px;
					}
				}
				.livestart_tim {
					font-size: 28px;
					display: flex;
					align-items: center;
					color: #9AB1FF;
				}
				.livestart_tim_color {
					margin: 0 5px 0 26px;
					font-weight: 900;
					font-size: 75px;
					color: #FFFEA0;
				}
			}
		}
	}
	.roomBox {
		position: relative;
		height: 100%;
		.room_header {
			height: 49px;
			background: rgba(61,65,80,1);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 22px 0 11px;
			.room_header_ketang {
				opacity: 0.9;
				font-size: 17px;
				font-weight: 400;
				color: #fff;
				display: flex;
				align-items: center;
				img {
					width: 9px;
					margin-right: 5px;
				}
			}
			.room_header_zaixian {
				opacity: 0.9;
				font-size: 17px;
				font-weight: 400;
				color: #F1F1F2;
				span {
					font-size: 19px;
					color: #84A4FF;
				}
			}
		}
		.messageBox {
			position: relative;
			width: 100%;
			overflow-y: auto;
			padding: 0 15px;
			box-sizing: border-box;
			.messageItem {
				display: flex;
				flex-direction: column;
			}
		}
		.messageBox::-webkit-scrollbar {
			width: 0;
		}
		
		.message_view {
			margin: 15px 0;
		}
		
		.message_right {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
		
		.rightBoxText {
			margin-right: 13px;
			.message_name {
				font-size: 12px;
				color: #A2A2A2;
				margin-bottom: 6px;
			}
		}
		
		.message_right_text {
			display: flex;
			justify-content: end;
			margin-top: 8px;
		}
		.message_right_text_span {
			background-image: linear-gradient(to right, #3E68FF, #7694FF);
			border-radius: 20px 4px 20px 20px;
			padding: 12px;
			box-sizing: border-box;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 18px;
		}
		
		.touxiang_right, .touxiang_left {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}
		.touxiang_left {
			margin-right: 13px;
		}
		
		.message_left {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: flex-start;
		
		}
		
		.message_left_text {
			display: flex;
			margin-top: 4px;
		}
		.message_left_text_span {
			color: #BFBFBF;
			background: rgba(0, 0, 0, 0.8);
			border-radius: 4px 20px 20px 20px;
			padding: 12px;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 18px;
		}
		.message_name {
			color: #A4A4A4;
			font-size: 14px;
			display: flex;
			align-items: center;
		}
		.room_bottom {
			position: absolute;
			bottom: 0px;
			width: 100%;
			.emojis {
				height: 200px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				overflow-y: scroll;
				box-sizing: border-box;
				background-color: #fff;
			}
			
			.emoji {
				height: 40px;
				width: 40px;
				padding: 8px;
				box-sizing: border-box;
			}
		}
		.room_input {
			position: relative;
			background: #3D4150;
			
			.message_btn {
				position: absolute;
				bottom: 0px;
				width: 100%;
				padding: 10px 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				img {
					width: 21px;
					cursor: pointer;
				}
				.message_btn_right {
					color: rgba(255, 255, 255, 0.3);
					display: flex;
					align-items: center;
					div {
						width: 48px;
						height: 26px;
						border-radius: 13px 13px 13px 13px;
						font-size: 14.61px;
						color: #fff;
						background-image: linear-gradient(to right, #3E68FF, #7694FF);
						line-height: 26px;
						text-align: center;
						margin-left: 10px;
						cursor: pointer;
					}
				}
				
			}
			
		}
		.huifuxiaoxi {
			border-top: 1px solid rgba(255,255,255,0.7);
			margin-top: 10px;
			padding-top: 10px;
			font-size: 14px;
		}
		.huifuxiaoxi span {
			opacity: 0.8;
		}
	}
	
</style>
<style lang="scss">
	.room_input {
		.el-textarea__inner {
			background-color: #3D4150;
			border: 1px solid #3D4150;
			color: #fff;
			height: 75px;
			margin-bottom: 46px;
		}
		.el-textarea__inner:focus, .el-textarea__inner:hover {
			border: 1px solid #3D4150;
		}
		.el-input__count {
			background-color: #3D4150 !important;
		}
		.el-textarea.is-disabled .el-textarea__inner {
		    background-color: #3D4150;
		    border-color: #3D4150;
		}
	}
	.uploadBox {
		line-height: 1;
		.el-upload {
			line-height: 1;
			img {
				height: 21px;
				vertical-align: middle;
			}
		}
	}
</style>