<template>
	<div class="zb-wrap">
		<!-- 回放 -->
		<div class="vipWrap">
			<div class="zb-header" id="payTitle" v-if="currentPlay">当前播放：{{currentPlay.dname}}</div>
			<div class="vip-video-content">
				<div class="videoBox">
					<div ref="player" id="player"></div>
				</div>
				
				<div class="listBox" :style="isShowList ? 'width: 200px' : 'width: 0'">
					<div class="vip-video-list-btn" @click="isShowList = !isShowList">
						<img v-if="isShowList" src="../../assets/image/live/youjiantou.png" alt="">
						<img v-else src="../../assets/image/live/zuojiantou.png" alt="">
						<span>目录</span>
					</div>
					<div v-if="isShowList && currentPlay" :style="isShowList ? 'width: 200px' : 'width: 0'">
						<div class="vip-video-list-title">{{currentPlay.dname}}</div>
						<div class="vip-course-list">
							<div class="vip-course-item" @click="changeVideo(item, index)" :style="item.id == currentPlay.id ? 'background-color: #1F232E' : ''" v-for="(item, index) in playbackList" :key="index">
								<span class="title" :style="item.id == currentPlay.id ? 'color: #84A4FF' : ''">{{item.dname}}</span>
								<img v-if="item.id == currentPlay.id" class="zb-player" src="../../assets/image/live/bofangzhong.gif" alt="">
							</div>
						</div>
						<div class="pagesBox">
							<div @click="goPage(1)" :class="page == 1 ? 'noBtn' : 'btn'">上一页</div>
							<div @click="goPage(2)" :class="page*pageSize >=  total ? 'noBtn' : 'btn'">下一页</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Bus from '../../assets/js/Bus.js'
	export default {
		name: 'Playback',
		data() {
			return {
				playbackList: [],
				page: 1,
				pageSize: 10,
				total: 0,
				query: {},
				currentPlay: null,  //当前播放
				isShowList: true,
				thePlayer : null,
				theTCPlayer: null,
				liveType: 1,   //使用直播平台  1 保利威播放器   2 腾讯云  nPlayer播放器
			}
		},
		created() {
			this.query = this.$route.query;
			console.log('传参：', this.currentPlay)
			this.getPlaybackList('in')
			Bus.$emit('scrollToTop')
		},
		
		methods: {
			// 获取回放列表
			getPlaybackList(type) {
				let that = this;
				that.$axios({
					method: "POST",
					url: "api/officialWebsite/mobile/chapter_course_list",
					data: {
						classmat_id: that.query.classId,
						list_rows: that.pageSize,
						page: that.page
					}
				}).then((res) => {
					if (res.code === 200) {
						that.total = res.data.total;
						that.playbackList = res.data.list;
						
						if (type) {
							let findId = null;
							findId = that.playbackList.findIndex(playback => {
								return playback.id == that.query.id
							})
							if(findId > -1) {
								that.changeVideo(that.query)
							} else {
								if(that.page * that.pageSize >=  that.total) {
									that.$alert('视频资源未找到！', '提示', {
									  confirmButtonText: '确定',
									  callback: action => {}
									});
									return
								}
								that.page++
								that.getPlaybackList(type);
							}
						}
					}
				});
			},
			// 切换播放视频
			changeVideo(item, index) {
				index = index || this.playbackList.findIndex(paly => paly.id == item.id)
				index = Math.max(index, 0)
				this.currentPlay = this.playbackList[index];
				this.liveType = this.currentPlay.platform
				// console.log('切换播放视频:', this.currentPlay, this.liveType)
				this.ended();
				
				if(this.currentPlay.platform == 1) {  // 保利威
					this.playBack(this.currentPlay);
				} else if (this.currentPlay.platform == 3) {  //未知
					this.$alert('视频资源未上传！', '提示', {
					  confirmButtonText: '确定',
					  callback: action => {}
					});
				} else {  //腾讯云
					this.getVideoLink(this.currentPlay);
				}
			},
			// 保利威点播视频封装
			playBack(item) {
				console.log(item,"itemitemitemitem");
				let vid = item.video_sign;
				var _this = this;	
				
				if(_this.theTCPlayer) {
					_this.theTCPlayer.dispose();
					_this.theTCPlayer = null;
				}
				
				if(vid.indexOf("http") == 0) {
					console.log(vid,"https://playvideo.qcloud.com/https://playvideo.qcloud.com/https://playvideo.qcloud.com/https://playvideo.qcloud.com/");
					_this.$refs['player'].innerHTML = "<video height=\"100%\" width=\"100%\" src='"+vid+"' controls></video>";
					 return ;
			     }
				
				if(!vid){
					this.$alert('视频资源未上传！', '提示', {
					  confirmButtonText: '确定',
					  callback: action => {}
					});
					return
				}
				
				_this.$axios({
					method: "POST",
					url: "api/officialWebsite/mobile/live_verify_sign",
					data: {
						vid: vid
					}
				}).then(res => {
					if(_this.thePlayer) {
						_this.thePlayer.changeVid({
							wrap: '#player',
							width: '100%',
							height: '100%',
							vid: vid,
							playsafe: res.data.playsafe,   //PC端播放加密视频所需的授权凭证
							ts: res.data.ts,
							sign: res.data.hash,
							useH5Page: true // 开启同层播放
						})
						return
					}
					let el = _this.$refs.player;
					el.innerHTML = ''
					_this.thePlayer = polyvPlayer({
						wrap: '#player',
						width: '100%',
						height: '100%',
						vid: vid,
						playsafe: res.data.playsafe,   //PC端播放加密视频所需的授权凭证s
						ts: res.data.ts,
						sign: res.data.hash,
						useH5Page: true // 开启同层播放
					});
				})
			},
			//腾讯云 生成视频播放链接
			getVideoLink(liveItem) {
				this.$axios({
					method: "POST",
					url: "api/tencentcloud_live/generate_video_playback_link",
					data: {
						syllabus_id: liveItem.id,
						type: 3,
						scene_type: 2
					}
				}).then(res => {
					this.TCPinit(res.data)
				})
			},
			
			TCPinit(data) {
				// console.log('TCPlayer播放器的数据:', data)
				// console.log('TCPlayer播放器', this.theTCPlayer)
				let that = this;
				if(this.thePlayer) {
					this.thePlayer.destroy();
					this.thePlayer = null
				}
				// 切换 fileID 播放
				if(this.theTCPlayer) {
					this.theTCPlayer.loadVideoByID({
						fileID: data.file_id,
						appID: data.app_id,
						psign: data.path, 
					})
					return
				}
				
				let video = `<video v-show="liveType == 2" id="player-container-id" preload="auto" :width="isShowList ? '920' : '1200'" height="600"></video>`;
				that.$refs.player.innerHTML = video;
				
				// 点播
				that.$nextTick(() => {
					that.theTCPlayer = TCPlayer('player-container-id', { // player-container-id 为播放器容器 ID，必须与 html 中一致
						fileID: data.file_id, 
						appID: data.app_id,
						psign: data.path, 
						plugins:{
						  ContinuePlay: { // 开启续播功能
							// auto: true, //[可选] 是否在视频播放后自动续播
							// text:'上次播放至 ', //[可选] 提示文案
							// btnText: '恢复播放' //[可选] 按钮文案
						  },
						}
					});
				})
			},
			goPage(type) {
				if(type == 1) {  //上一页
					if(this.page == 1) {
						return
					}
					this.page--
				} else {
					if(this.page * this.pageSize >=  this.total) {
						return
					}
					this.page++
				}
				this.getPlaybackList()
			},
			// 获取当前时间戳 10位
			getCurrentTime() {
				return parseInt(new Date().getTime()/1000)
			},
			onPlay() {
				this.start_time = this.getCurrentTime();
			},
			ended() {
				console.log('结束记录', this.start_time)
				if(!this.start_time) {
					this.onPlay();
					return
				}
				this.end_time = this.getCurrentTime();
				this.$axios({
					method: "POST",
					url: "api/officialWebsite/computer/create_video_lesson_learning_record",
					data: {
						syllabus_id: this.currentPlay.id,
						start_time: this.start_time,
						end_time: this.end_time,
						course_type: 2
					}
				}).then((res) => {
					this.start_time = null;
					this.end_time = null;
					this.onPlay();
				});
			},

		},
		beforeDestroy() {
			console.log('销毁')
			if(this.theTCPlayer) {
				try {
					this.thePlayer.pause()
				} catch(err){}
				
				this.theTCPlayer.dispose();
			}
			if(this.thePlayer) {
				this.thePlayer.destroy();
				this.thePlayer = null
			}
			this.ended();
		},
	}
</script>

<style scoped lang="scss">
	.zb-wrap {
		width: 100%;
		height: auto;
		background-color: #212121;
		padding: 40px 0 80px;
	}

	.vipWrap {
		width: 1200px;
		margin: 0 auto;

		.zb-header {
			font-size: 18px;
			color: #fff;
			margin-bottom: 14px;
		}

		.vip-video-content {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.videoBox {
				background-color: #000;
				height: 600px;
				flex: 1;
				#player {
					width: 100%;
					height: 600px;
				}
			}
		}

		.listBox {
			height: 600px;
			background-color: #2E2E2E;
			box-sizing: border-box;
			position: relative;

			.vip-video-list-btn {
				width: 26px;
				height: auto;
				font-size: 14px;
				color: #fff;
				padding: 5px 0;
				text-align: center;
				-webkit-border-radius: 4px;
				border-radius: 4px;
				background-color: #2E2E2E;
				box-sizing: border-box;
				position: absolute;
				left: -26px;
				top: 50%;
				z-index: 200;
				transform: translateY(-50%);
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				img {
					width: 10px;
					margin-bottom: 5px;
				}
			}

			.vip-video-list-title {
				width: 100%;
				max-height: 60px;
				font-size: 18px;
				color: #fff;
				padding: 0 15px;
				margin: 15px 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				box-sizing: border-box;
				text-align: center;
			}

			.vip-video-list-title {
				width: 100%;
				max-height: 60px;
				font-size: 16px;
				color: #fff;
				padding: 0 15px;
				margin: 15px 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				box-sizing: border-box;
			}

			.vip-course-list {
				width: 100%;
				height: calc(600px - 82px);
				padding: 20px 0 10px;
				overflow-y: auto;
				box-sizing: border-box;
				.vip-course-item {
					width: 100%;
					height: 48px;
					padding: 0 15px;
					box-sizing: border-box;
					cursor: pointer;
					display: flex;
					-webkit-justify-content: space-between;
					justify-content: space-between;
					-webkit-align-items: center;
					align-items: center;
					font-size: 14px;
					.title {
						color: #fff;
						width: calc(100% - 42px);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.zb-player {
						width: 26px;
					}
				}
				.vip-course-item:hover {
					background-color: rgba(255, 255, 255, 0.2);
					.title {
						color: #84A4FF;
					}
				}
			}
			.vip-course-list::-webkit-scrollbar {
				width: 0;
			}
			.pagesBox {
				width: 100%;
				height: 39px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: absolute;
				bottom: 0;
				div {
					width: calc(50% - 1px);
					text-align: center;
					color: #fff;
					background-color: #3D4150;
					height: 39px;
					line-height: 39px;
					border-radius: 6px;
					font-size: 12px;
				}
				.noBtn {
					color: rgba(255, 255, 255, 0.1);
				}
				.btn {
					cursor: pointer;
				}
			}
			
		}
	
	}
	
</style>
<style>
	.vjs-time-tooltip {
		width: 50px;
	}
</style>
