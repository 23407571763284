<template>
  <div style="background-color: #f5f5f5" >
    <!-- 千校互联 -->
    <!-- <empty-page></empty-page> -->
    <img
      style="width: 100%; height: auto; padding: 0; margin: 0"
      src="../../assets/image/schoolls.png"
    />
    <div class="board">
      <div class="boardTitle">
        <span>我们能提供</span>
        <img src="../../assets/image/icon.png" alt="">
      </div>
      <div class="boardIco">
        <div class="boardTip">
          <img src="../../assets/image/post.png" alt="" />
          <div class="boardSlogan">
            <span>校企合作</span>
            <p>千校互联-融合生态</p>
          </div>
        </div>
        <div class="boardTip">
          <img src="../../assets/image/enterprise.png" alt="" />
          <div class="boardSlogan">
            <span>产教融合</span>
            <p>扎根产业-赋能企业</p>
          </div>
        </div>
        <div class="boardTip">
          <img src="../../assets/image/business.png" alt="" />
          <div class="boardSlogan">
            <span>促进发展</span>
            <p>促活经济-科技攻关</p>
          </div>
        </div>
      </div>
    </div>
    <div id="content" style="margin-bottom:20px"></div>
    <div class="boardTitle">
      <span>我们拥有众多合作院校</span>
    </div>
    <div class="shcoolList">
      <!-- 所在地 -->
      <div class="schoolOption">
        <span class="schoolOption_title">院校所在地：</span>
        <div>
          <div class="occupation_tag_view">
            <div
              class="tagNmae pointer"
              :class="[active_regionTag_index == 202 ? 'active_tag' : '']"
              @click="clickAllRegion()"
            >
              全部
            </div>
            <div
              class="tagNmae pointer"
              :class="[
                active_regionTag_index == index && active_regionTag_index != 202
                  ? 'active_tag'
                  : '',
                index > 7 ? 'position_none' : 'position_block',
                show ? 'position_none' : 'position_block',
              ]"
              v-for="(item, index) in regionList"
              :key="index"
              @click="clickRegionTag(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="icon">
          <el-button
            size="default"
            :icon="icons"
            @click="openSchool"
          ></el-button>
        </div>
      </div>
      <!-- 类型 -->
      <div>
        <div class="schoolOption">
          <span class="schoolOption_title">院校类型：</span>
          <div>
            <div class="occupation_tag_view">
              <div
                class="tagNmae pointer"
                :class="active_typeTag_index == 202 ? 'active_tag' : ''"
                @click="clickAllType()"
              >
                全部
              </div>
              <div
                class="tagNmae pointer"
                :class="
                  active_typeTag_index == index && active_typeTag_index != 202
                    ? 'active_tag'
                    : ''
                "
                v-for="(item, index) in typeList"
                :key="index"
                @click="clickTypeTag(item, index)"
              >
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- 列表 -->
      <div class="cardsList" v-loading="loading">
        <div
          class="schoolCards"
          v-for="(item, index) in schoolList"
          :key="index"
        >
          <div class="schoolInfo">
            <img class="" :src="item.school_img" alt="" />
            <div class="schoolTitle">
              <span>{{ item.title }}</span>
              <p v-html="item.school_details"></p>
            </div>
          </div>
          <div class="line"></div>
          <div class="schoolBtn">
            <span>{{ item.school_type }}</span>
            <div class="detailsBtn">
              <el-button type="info" size="default" @click="details(item.id)"
                >院校详情</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="ossImgBox" v-if="this.schoolList.length == 0">
        <el-image
          class="noword"
          src="https://static.shanchuangjiaoyu.com/yc_baidu/noword.png"
        ></el-image>
        <div class="ossImgText">暂无内容哦~</div>
      </div>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          v-if="this.schoolList.length > 0"
          background
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next"
          :total="page.total"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import pages from "../components/pages.vue";
import emptyPage from "../emptyPage.vue";
export default {
  name: "qianxiaohulian",
  data() {
    return {
      show: true,
      loading: false,
      active_regionTag_index: 202,
      active_typeTag_index: 202,
      regionList: [],
      typeList: [],
      schoolList: [],
      page: {
        total: 1, //总条目数
        pageSize: 12, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      type: "",
      position: "",
      types: "",
      positions: "",
      icons: "el-icon-caret-bottom",
      // showPage:false,
    };
  },
  created() {
    this.getRegion();
    this.getSchoolType();
    this.getSchoolList();
    
    window.addEventListener("popstate", function(e) { 
      location.reload();

}, false);
  },
  methods: {
    // 选择全部类型
    clickAllType() {
      this.loading = true;
      this.type=0
      this.active_typeTag_index = 202;
      this.getAllList();
      this.page.currentPage = 1;
    },
    // 选择全部所在地
    clickAllRegion() {
      this.loading = true;
      this.position=0;
      this.active_regionTag_index = 202;
      this.getAllList();
      this.page.currentPage = 1;
      // this.openSchool();
    },
    // 获取全部列表
    getAllList() {
      let data = {list_rows:12};
        if(this.types=this.type){
        data.school_type_id=this.types
      }
      if(this.positions=this.position){
        data.p_id=this.positions
      }
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/campus_cooperation/college_list",
        data:data,
      })
        .then((res) => {
          // console.log(res, 999);
          this.schoolList = res.data.data;
          this.pages = res.data.current_page;
          this.page.total = res.data.total;
          this.page.pageSize = res.data.per_page;
          this.page.currentPage = res.data.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = false;
      }, 500);
    },
    // 选择院校所在地
    clickRegionTag(item, index) {
      
      this.position = item.code;
      this.positions = item.code;
      this.active_regionTag_index = index;
      this.page.currentPage = 1;
      this.loading = true;
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.getSchoolList1();
      // console.log(this.positions,'this.positionssss');
    },
    // 选择院校类型
    clickTypeTag(item, index) {
      this.type = item.key;
      this.types = item.key;
      this.active_typeTag_index = index;
      this.page.currentPage = 1;
       this.loading = true;
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.getSchoolList1();
      // console.log(this.types,'key');
    },
    // 所在地指示图标
    openSchool() {
      this.show = !this.show;
      if (this.show) {
        this.icons = "el-icon-caret-bottom";
      } else {
        this.icons = "el-icon-caret-top";
      }
    },
    // 查看详情
    details(id) {
      // console.log(id, "111");
      let schoolId = id;
      this.$router.push({ name: "details", query: { college_id: schoolId } });
    },
    // 获取地区列表
    getRegion() {
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/talent_center/get_province_list",
      })
        .then((res) => {
          // console.log(res);
          res.data.forEach(el=>{
            if(el.name=="黑龙江省")el.name="黑龙江"
            if(el.name=="内蒙古自治区")el.name="内蒙古"
            if(el.name=="广西壮族自治区")el.name="广西"
            if(el.name=="西藏自治区")el.name="西藏"
            if(el.name=="宁夏回族自治区")el.name="宁夏"
            if(el.name=="新疆维吾尔自治区")el.name="新疆"
            if(el.name=="香港特别行政区")el.name="香港 "
            if(el.name=="澳门特别行政区")el.name="澳门"
          })
          this.regionList = res.data;
          // this.typeList=res
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 分页
    changePage(data) {
      this.loading = true;
      this.page.currentPage = data;
      setTimeout(() => {
        document.querySelector("#content").scrollIntoView(true);
        this.loading = false;
      }, 500);
      this.getSchoolList1();
    },
    // 获取学校列表
    getSchoolList() {
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/campus_cooperation/college_list",
        data:{list_rows:12}
      })
        .then((res) => {
          console.log(res, 999);
          this.schoolList = res.data.data;
          this.pages = res.data.current_page;
          this.page.total = res.data.total;
          this.page.pageSize = res.data.per_page;
          this.page.currentPage = res.data.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSchoolList1() {
      let data = {

      };
      if(this.page.pageSize){
        data.list_rows=this.page.pageSize
      }
      if(this.position){
        data.p_id=this.position
      }
      if(this.type){
        data.school_type_id=this.type
      }
      if(this.page.currentPage){
        data.page=this.page.currentPage
      }
      this.$axios({
        method: "POST",
        url: "/api/officialWebsite/campus_cooperation/college_list",
        data: data,
      })
        .then((res) => {
          // console.log(res, 999);
          this.schoolList = res.data.data;
          this.pages = res.data.current_page;
          this.page.total = res.data.total;
          this.page.pageSize = res.data.per_page;
          this.page.currentPage = res.data.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取学校类型
    getSchoolType() {
      this.$axios({
        method: "GET",
        url: "/api/officialWebsite/talent_center/get_school_type",
      })
        .then((res) => {
          // this.showPage = true;
          // console.log(res.data)
          this.typeList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    emptyPage,
    pages,
  },
};
</script>

<style  scoped>

.board {
  width: 1200px;
  height: 479px;
  background: #ffffff;
  border-radius: 23px;
  margin: 0 auto;
  position: relative;
  top: -60px;
  box-shadow: -1px -7px 20px 1px rgba(74, 76, 126, 0.09);
}
.boardIco {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}
.boardTip {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
}
.boardTip img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}
.boardSlogan {
  margin-top: 41px;
}
.boardSlogan span {
  width: 118px;
height: 29px;
font-size: 30px;

font-weight: 800;
color: #0C0C0C;
line-height: 27px;
}
.boardSlogan p {
  width: 183px;
height: 21px;
font-size: 21px;

font-weight: 400;
color: #3D3D3D;
line-height: 30px;
margin-top: 18px;
}
.boardTitle {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 62px;
}
.boardTitle img {
 width: 34px;
 height: 20px;
 margin-top: 5px;
}
.boardTitle span {
  margin-top: 58px;
  /* width: 177px; */
height: 34px;
font-size: 36px;

font-weight: 800;
color: #3D3D3D;
line-height: 35px;
}
.boardTitle p {
  margin-top: 19px;
  width: 39px;
  height: 4px;
  border-radius: 10px;
  background-color: #fca45b;
}
.occupation_tag_view {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* overflow: auto; */
}
.tagNmae {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 6px 28px;
  margin: 15px 15px 0 0;
  flex-shrink: 0;
  width: 100px;
  height: 34px;
  background: #ffffff;
  border-radius: 17px;
  text-align: center;
}
.active_tag {
  background: #3c71ff;
  color: #fff;
}
.shcoolList {
  width: 1200px;
  margin: 0 auto;
}
.schoolOption {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  position: relative;
}
.schoolOption_title {
  flex: 0 0 100px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.icon {
  /* color: #999999;
  width: 14px;
  height: 8px;
  flex: 0 0 50px; */
  position: absolute;
  left: 94%;
  margin-top: 8px;
}
.cardsList {
  margin-top: 61px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  column-gap: 30px;
  row-gap: 37px;
}
.schoolCards {
  display: flex;
  flex-flow: column;
  width: 380px;
  height: 190px;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
}
.schoolInfo {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.schoolInfo img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  object-fit: cover;
}
.schoolTitle {
  margin-left: 11px;
}
.schoolTitle span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.schoolTitle p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.position_none {
  display: none;
}
.position_block {
  display: block;
}
.line {
  width: 340px;
  height: 1px;
  background: #f5f5f5;
  border-radius: 1px;
  margin-top: 27px;
  margin-bottom: 13px;
}
.schoolBtn {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
}
.schoolBtn span {
  width: 150px;
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.detailsBtn {
  width: 104px;
  height: 32px;
}
.paging {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  padding-bottom: 128px;
}
.ossImgBox {
  margin: 30px auto;
  width: 40%;
  text-align: center;
}
/deep/ .el-button {
  background: none;
  border: none;
  font-size: 20px;
}
/deep/ .el-button:focus,
.el-button:hover {
  background: none;
  color: none;
  border-color: none;
}
/deep/ .el-button--info {
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
}
/deep/ .el-button--info:focus,
.el-button--info:hover {
  background: #0f6fec;
  border-color: #0f6fec;
}
/deep/ .el-descriptions-item__container {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #333333;
}
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #ffffff;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
}
/deep/ .el-pagination span:not([class*="suffix"]) {
  min-width: 85.5px;
  height: 36px;
  line-height: 36px;
  background-color: #ffffff;
  border-radius: 4px;
}
div /deep/ .el-loading-mask{
  width: 1240px;
}
</style>
