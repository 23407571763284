<template>
  <!-- <div class="box">
    <div class="nav" :class="{ xuefen_up_top: isxuefen }" v-if="isxuefen">
      <slot></slot>
    </div>
    <div class="nav" :class="{ scale_up_top: active, tabs_bgc:ishonor }" v-else>
      <slot></slot>
    </div>
  </div> -->

  <div class="tabs">
    <svg width="0" height="0">
    <defs>
      <clipPath id="first" clipPathUnits="objectBoundingBox">
        <path d="M 0 1 L 0 0 L 0.8 0 C 0.9 0 0.9 1 1 1 L 1 1 Z" />
      </clipPath>
      <clipPath id="last" clipPathUnits="objectBoundingBox">
        <path d="M 0 1 C 0.1 1 0.1 0 0.2 0 L 1 0 L 1 1 Z" />
      </clipPath>
      <clipPath id="middle" clipPathUnits="objectBoundingBox">
        <path d="M 0 1 C 0.1 1 0.1 0 0.2 0 L 0.8 0 C 0.9 0 0.9 1 1 1 L 1 1 Z" />
      </clipPath>
    </defs>
  </svg>
  <ul class="menu">
    <li 
      class="menu-item"
      v-for="(item, index) in list"
      :key="index"
      :style="{ 'z-index': 9 - index, left: left(index) }"
      :class='{ 
          active: tabs_index === index, 
          xuefen_text: isxuefen,
          left_first: index === 0,
        }'
      @click="btn(index)"
    >
      <div class="menu-item__bg"  :class="{ no_first: index != 0, aa: index === 0 }"></div>
      <div class="menu-item__text">{{ item.tabs_title }}</div>
    </li>
  </ul>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    tabs_index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array
    },
    isxuefen: {
      type: Boolean,
      default: false
    },
    ishonor: {
      type: Boolean,
      default: false
    }
    // isxuefen: {
    //   type: Boolean,
    //   default: false
    // },
    // ishonor: {
    //   type: Boolean,
    //   default: false
    // }
  },
   methods: {
    btn(i) {
      // this.index_active = i
      this.$emit('update_tabs_index', i)
    },
    left(i) {
      if (i === 0) {
        return 0 + 'px'
      }
      if (i === 1) {
        return -10 + 'px'
      }
      if (i === 2) {
        return -30 + 'px'
      }
      if (i === 3) {
        return -50 + 'px'
      }
      if (i === 4) {
        return -70 + 'px'
      }
    }
  }
}
</script>

<style scope>
/* .tabs {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-flow: col;
  justify-content: center;
  align-items: center;
  background: #7589e0;
} */
.left_first{
  left: 0px
}
.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
}
/* box-shadow: 2px -2px 5px 4px #ccc */
.menu-item {
  position: relative;
  width: 150px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  filter: drop-shadow(0px -1px 1px #eee);
}

.menu-item__bg {
  width: 100%;
  height: 100%;
  padding: 12px 0;
  background: #FDFDFD;
}

.menu-item__text {
  transition: transform ease-out .2s;
}

.menu-item__text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.menu-item .menu-item__bg {
  transform-origin: bottom;
}

.menu-item.active {
  color: #007DFC;
  z-index: 12 !important;
}

.menu-item.active .menu-item__bg {
  transform: scaleX(1.15) scaleY(1.15);
  clip-path: url(#middle);
  background: #FFFFFF;
  opacity: 1;
}

.no_first {
  clip-path: url(#middle);
}

.menu-item.active .menu-item__text {
  /* font-weight: bold; */
  transform: translate(-50%, -50%) scale(1.15);
}

.menu-item:first-child .menu-item__bg {
  transform-origin: left bottom;
  border-top-left-radius: 8px;
}

.menu-item:first-child.active .menu-item__bg {
  clip-path: url(#first);
  transform: scaleX(1.15) scaleY(1.15);
}

.aa{
  clip-path: url(#first);
}

.xuefen_text{
   color: #E08F8D !important;
}

.honor{
  background-color: #2A2A46 !important;
 
}
.bai{
   color: #FFFFFF !important;
}
</style>