<template>
	<div class="home">
		<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/qita/one.jpg">
		<el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm"
			style="margin-top: 30px;padding: 0 10px;width: 100%;" label-position="top">
			<el-form-item label="手机号:" prop="tel" :rules="[{ required: true, message: '手机号不能为空' },
			{ required: true, pattern: /^((13|14|15|16|17|18)[0-9]{1}\d{8})|((166|199|198)[0-9]{1}\d{7})$/, message: '请输入正确的电话号码', trigger: 'blur' },
			{ type: 'number', message: '手机号必须为数字值' }]">
				<el-input placeholder="请输入手机号" class="textarea" v-model.number="numberValidateForm.tel"
					autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="验证码:" prop="code" style="position: relative;"
				:rules="[{ required: true, message: '验证码不能为空' }, { type: 'number', message: '验证码必须为数字值' }]">
				<el-input placeholder="验证码" class="textarea" v-model.number="numberValidateForm.code"
					autocomplete="off"></el-input>
				<div style="margin-left: 20%;margin-bottom: 10px;position: absolute;top: -5px;right: 20px;" @click="getcode"
					v-if="!content">获取验证码</div>
				<div style="margin-left: 20%;margin-bottom: 10px;position: absolute;top: -5px;right: 20px;" v-if="content">
					{{ content }}</div>
			</el-form-item>
			<el-form-item>
				<el-button type="danger" @click="submitForm('numberValidateForm')"
					style="margin-left: 35%;">立即领取</el-button>
			</el-form-item>
		</el-form>
		<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/qita/two.jpg">
		<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/qita/therr.png">
		<img src="https://juntongoss.oss-cn-beijing.aliyuncs.com/qita/four.png">
		<button @click="goTO"
			style="background: rgb(193, 22, 25);border-radius: 25px;width: 80%;padding: 5%;height: 50px;margin-left: 10%;position:fixed;bottom:0;color: #fff;font-size: 24px;display: flex;justify-content: center;align-items: center;">立即预约</button>
	</div>
</template>

<script>
import Bus from "../assets/js/Bus";
export default {
	name: 'Home',
	data() {
		return {
			numberValidateForm: {
				tel: '',
				code: "",
			},
			content: "",
			totalTime: 60,
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$axios({
						method: "POST",
						url: "/api/officialWebsite/make_submit",
						params: {
							phone: this.numberValidateForm.tel,
							code: this.numberValidateForm.code
						}
					}).then((res) => {
						console.log(res, "numberValidateForm");
						if (res.code == 200) {
							this.$message({
								message: '领取成功',
								type: 'success'
							});
							window.location.href = "https://work.weixin.qq.com/ca/cawcdeae72a9b56031"
						} else {
							this.$message.error(res.msg);
							if (res.msg == '无需重复预约') {
								window.location.href = "https://work.weixin.qq.com/ca/cawcdeae72a9b56031"
							}
						}
					})
				} else {
					this.$message.error('领取失败');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 获取验证码
		getcode() {
			if (this.numberValidateForm.tel == '') {
				this.$message.error("请输入手机号");
				return;
			}
			let clock = window.setInterval(() => {
				this.content = this.totalTime + 's后重新发送';
				console.log();
				this.totalTime--;
				if (this.totalTime < 0) {
					this.totalTime = 60;
					this.content = "";
					window.clearInterval(clock);
				}
			}, 1000);
			this.$axios({
				method: "POST",
				url: "/api/officialWebsite/get_code",
				params: {
					phone: this.numberValidateForm.tel
				}
			}).then((res) => {
				console.log(res, "numberValidateForm");
				if (res.code == 200) {

				}
			})
		},
		goTO() {
			this.$axios({
				method: "POST",
				url: "/api/officialWebsite/make_submit",
				params: {
					phone: this.numberValidateForm.tel,
					code: this.numberValidateForm.code
				}
			}).then((res) => {
				console.log(res, "numberValidateForm11111111");
				if (res.code == 200) {
					window.location.href = "https://work.weixin.qq.com/ca/cawcdeae72a9b56031"
				} else {
					if (res.msg == '无需重复预约') {
						window.location.href = "https://work.weixin.qq.com/ca/cawcdeae72a9b56031"
					} else {
						document.getElementsByClassName("index")[0].scrollTop = 200
					}

				}
			})
		},
	},
}
</script>

<style scoped>
/* body{overflow:hidden;}	 */
.home {
	width: 100vw;
	/* height: auto; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	/* text-align: center; */
	/* overflow-y:scroll; */
}

.home img {
	width: 100vw;
	height: auto;
}

.home>>>.el-form-item__label {
	font-weight: 800;
	font-size: 18px;
}

input::-webkit-input-placeholder {
	font-size: 12px;
}

.textarea>>>.el-input__inner {
	font-size: 16px !important;
}

div/deep/.demo-ruleForm input {
	height: 30px;
	border-radius: 0;
	border-top: none;
	border-left: none;
	border-right: none;
}
</style>
